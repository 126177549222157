import { PADDING_HORIZONTAL } from '../../styles'
import { Props } from './index'

const styles = (theme: App.Theme) => ({
  container: {
    display: 'flex !important',
    alignItems: 'center'
  },
  icon: {
    display: 'inline-flex',
    flexShrink: 0,
    width: '1rem',
    height: '1rem',
    marginRight: PADDING_HORIZONTAL,
    lineHeight: 0,
    fill: theme.colors.grey
  },
  text: {
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
})

export default styles

import { Props } from './index'

const styles = (theme: App.Theme) => ({
  container: {
    position: 'relative',
    fontSize: '1rem',
    opacity: ({ disabled }: Props) => (disabled ? 0.5 : 1),
    transition: 'opacity 0.3s ease-out'
  },
  content: {
    height: '56px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: ({ label }: Props) =>
      `${label ? '0.3125em' : '0.875em'} 0.625em ${label ? '0.3125em' : '0.625em'} 0.625em`,
    fontSize: '1rem',
    lineHeight: 0,
    boxSizing: 'border-box',
    border: ({ meta }: Props) => {

      const error = meta && meta.error
      const touched = meta && meta.touched
      return `1px solid ${error && touched ? theme.colors.red : theme.colors.input.border}`
    },
    borderRadius: '0.5rem'
  },
  input: {
    width: '100%',
    padding: 0,
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: 1.2,
    color: '#000',
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    '&::placeholder': {
      color: theme.colors.grey
    },
    '&[type=number]::-webkit-inner-spin-button, &[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  label: {
    position: 'absolute',
    display: 'inline-block',
    color: theme.colors.text.default,
    fontSize: '16px',
    lineHeight: 1,
    fontWeight: 'normal',
    textTransform: 'initial',
    letterSpacing: 0.5,
    cursor: 'text',
    userSelect: 'none',
    pointerEvents: 'none',
    transform: 'translateY(-16px) scale(0.7)',
    transformOrigin: '0 0',
    transition: 'transform 0.15s ease-out'
  },
  error: {
    display: 'inline-block',
    padding: '0.325em 1.25em',
    color: theme.colors.red,
    fontSize: '0.75rem'
  }
})

export default styles

const styles = (theme: App.Theme) => ({
  modal: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '48rem',
    width: '100%',
    maxHeight: '80vh',
    overflowY: 'auto',
    backgroundColor: '#fff',
    boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.15)'
  },
  modalHeader: {
    display: 'flex',
    padding: '1.25rem',
    backgroundColor: theme.colors.page.contentBackground
  },
  modalTitle: {
    margin: 0,
    fontSize: '1.5rem',
    fontWeight: 'normal',
    lineHeight: 1.2
  },
  modalContent: {
    padding: '1.25rem'
  },
  modalField: {
    '& + &': {
      marginTop: '1.25rem'
    }
  },
  modalFieldRow: {
    composes: '$modalField',
    ...theme.utils.flexContainer({
      direction: 'row',
      alignItems: 'center',
      justify: 'space-between'
    })
  },
  fileName: {
    display: 'flex',
    flex: 1,
    marginRight: '2.5rem'
  },
  select: {
    width: '140px',
    marginRight: '0.625rem'
  },
  tooltip: {
    zIndex: 10000
  },
  signatureTextarea: {
    marginTop: '0.5rem',
    '& textarea': {
      height: '30vh',
      resize: 'none'
    }
  },
  modalFieldLabel: {
    fontSize: '0.875rem',
    lineHeight: 1.2
  },
  modalFieldInput: {
    width: '100%',
    marginTop: '0.625rem'
  },
  modalFooter: {
    ...theme.utils.flexContainer({ direction: 'row', justify: 'center', alignItems: 'center' }),
    padding: '1.25rem',
    '& > *:nth-last-child(n+2)': {
      marginRight: '0.625rem'
    }
  },
  modalFooterButton: {
    '&:not(:last-child)': {
      marginRight: '0.5rem'
    }
  }
})

export default styles

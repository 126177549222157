import React, { Component } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import { compose } from 'recompose'
import { WrappedFieldArrayProps } from 'redux-form'
import { isNil } from 'lodash'
import {Collapse} from '@mui/material'

import FiltersRow from './components/Row'
import FiltersMenu from './components/Menu'
import { TYPE as SET_TYPE } from 'constants/set'
import { SetWithType } from 'components/Aggregation/components/FiltersDropZone'
import styles from './styles'

interface OuterProps {
  withConditionSeparator?: boolean
  withDateRange?: boolean
  withDateSelect?: boolean
  stringAsMulti?: boolean
  options?: SetWithType[]
  open: boolean
  toggleFilters: () => void
  change: (fieldName: string, value: any) => void
  component?: any
}
interface Props
  extends JSSProps<typeof styles>,
    OuterProps,
    WrappedFieldArrayProps<Filter.Expression> {}
interface State {
  menu: {
    fieldIndex?: number
    anchorEl?: HTMLDivElement
  }
}

class Filters extends Component<Props, State> {
  static defaultProps = {
    withConditionSeparator: false,
    withDateRange: false,
    withDateSelect: false,
    stringAsMulti: false
  }

  state: Readonly<State> = {
    menu: {
      anchorEl: null
    }
  }

  removeRow = (index: number) => {
    this.props.fields.remove(index)
  }

  convertSetToField = (item: SetWithType): Filter.MainField => {
    const { options } = this.props

    const itemInSets = options.find(set => set.id === item.id && set.cube_id === item.cube_id)

    return {
      id: item.id,
      name: item.name,
      cube_id: item.cube_id,
      filter_type: item.filter_type,
      type: itemInSets.type === SET_TYPE.MEASURE ? 'having' : 'where',
      agg: undefined,
      entity_type: itemInSets.type === SET_TYPE.DIMENSION ? 'dimensions' : 'measures'
    }
  }

  get options() {
    return this.props.options.map(item => this.convertSetToField(item))
  }

  openMenu = (anchorEl: HTMLDivElement, index: number) =>
    this.setState(prev => ({ menu: { ...prev.menu, anchorEl, fieldIndex: index } }))
  closeMenu = () =>
    this.setState(prev => ({
      menu: { ...prev.menu, fieldIndex: null, anchorEl: null }
    }))
  onChangeValue = (newValue: Filter.MainField, index: number) => {
    const { change } = this.props

    change(`filters[${index}].field1`, newValue)
  }

  render() {
    const {
      classes,
      fields,
      withConditionSeparator,
      withDateRange,
      withDateSelect,
      stringAsMulti,
      toggleFilters,
      change,
      open
    } = this.props

    return (
      <div className={classes.container}>
        {open && fields.length > 0 && (
          <div className={classes.header}>
            <button
              className={classes.hideButton}
              onClick={event => {
                event.preventDefault()
                toggleFilters()
              }}
            >
              Скрыть фильтры
            </button>
          </div>
        )}
        <Collapse in={open} style={{ width: '100%' }}>
          {fields.map((name, index, fields) => (
            <FiltersRow
              key={`${index}-${name}`}
              name={name}
              index={index}
              fields={fields}
              withConditionSeparator={withConditionSeparator}
              withDateRange={withDateRange}
              withDateSelect={withDateSelect}
              stringAsMulti={stringAsMulti}
              options={this.options}
              menuIsOpen={!!this.state.menu.anchorEl}
              menuIndex={this.state.menu.fieldIndex}
              removeRow={this.removeRow}
              openMenu={this.openMenu}
            />
          ))}
        </Collapse>
        <FiltersMenu
          anchorEl={this.state.menu.anchorEl}
          onChangeTarget={this.onChangeValue}
          targetIndex={this.state.menu.fieldIndex}
          target={
            !isNil(this.state.menu.fieldIndex)
              ? fields.get(this.state.menu.fieldIndex).field1
              : null
          }
          closeMenu={this.closeMenu}
        />
      </div>
    )
  }
}

export default compose<Props, OuterProps>(injectStyles(styles))(Filters)

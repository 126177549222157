const styles = (theme: App.Theme) => ({
  cell: {
    ...theme.utils.flexContainer({ direction: 'column' }),
    width: '100%',
    margin: '0.25rem 0'
  },
  name: {
    maxWidth: '100%',
    margin: '0.25rem 0',
    fontFamily: 'Helvetica',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    color: '#B8B8B8'
  },
  value: {
    maxWidth: '100%',
    margin: '0.25rem 0',
    fontFamily: 'Helvetica',
    fontSize: '0.875rem',
    fontWeight: 'regular',
    wordWrap: 'break-word',
    color: '#000000'
  }
})

export default styles

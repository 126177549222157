import { colors } from './colors'
import { sizes } from './sizes'
import { utils } from './utils'

export const common = {
  fontFamily: 'Open Sans, sans-serif',
  sortingDate: {
    '& span': {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '17px'
    },

    '& > span': {
      top: '50%',
      left: '47%',
      transform: 'translateY(-50%)'
    },

    '& .Select-control': {
      border: 'none',
      backgroundColor: 'transparent',

      '&:hover': {
        boxShadow: 'none'
      }
    }
  },
  page: {
    contentElementBorder: `1px solid ${colors.page.background}`,
    contentBoxShadow: '0 3px 5px 0 rgba(0,0,0,0.10)',
    containerStyle: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 0 auto',
      width: '100%',
      minHeight: `calc(100vh - ${sizes.header.height})`,
      padding: '0 1.25rem',
      boxSizing: 'border-box'
    },
    header: {
      ...utils.flexContainer({
        direction: 'row',
        justify: 'space-between',
        alignItems: 'center'
      }),
      width: '100%',
      padding: '1.25rem',
      paddingLeft: 0,
      boxSizing: 'border-box'
    },
    headerTitle: {
      fontSize: '1.125rem',
      fontWeight: 'normal',
      color: '#000000',
      margin: 0
    },
    content: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      margin: '0.5rem 1.25rem 0.5rem 0',
      width: 'calc(100% - 1.25rem)',
      height: '87.5vh',
      boxShadow: '0 3px 5px 0 rgba(0,0,0,0.10)',
      backgroundColor: colors.page.contentBackground,
      boxSizing: 'border-box'
    },
    loadingWrapper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 1,
      flexGrow: 1,
      // width: 'calc(100% - 2.5rem)',
      width: '100%',
      height: 0,
      margin: 0,
      marginTop: 0
    },
    listScrollWrapper: {
      width: '100%',
      overflow: 'auto'
    },
    loadingButton: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      width: '100%'
    }
  },
  text: {
    header: {
      fontSize: 18,
      fontWeight: 600,
      color: colors.text.header
    }
  },
  paper: {
    background: 'white',
    borderRadius: '14px',
    padding: '20px'
  },
  customCard: {
    borderRadius: '14px',
    padding: '20px',
    overflow: 'hidden',
    boxShadow: 'none',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0px 0px 4px 3px rgb(0 0 0 / 16%)'
    }
  },
  charts: {
    echartsLoadingConfig: {
      text: 'Загрузка...',
      color: colors.blue,
      textColor: '#000',
      maskColor: 'rgba(255, 255, 255, 0.8)',
      zlevel: 0
    }
  },
  input: {
    paddingLeft: '0.625rem',
    borderRadius: '8px'
  }
}

import { Props } from './index'

const WIDTH = 3
const HEIGHT = 1.5

const styles = (theme: OpenApp.Theme) => ({
  container: {
    ...theme.utils.flexContainer({ direction: 'row', alignItems: 'center' }),
    backgroundColor: theme.colors.page.contentBackground,
    borderRadius: '0.5rem'
  },
  text: {
    fontFamily: 'Helvetica',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    color: '#000000',
    cursor: 'pointer',
    '&:first-child': {
      marginRight: '0.5rem'
    },
    '&:last-child': {
      marginLeft: '0.5rem'
    }
  },
  toggler: {
    position: 'relative',
    width: `${WIDTH}rem`,
    height: `${HEIGHT}rem`,
    cursor: 'pointer',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: `${HEIGHT / 2}rem`,
      backgroundColor: theme.colors.blue,
      transition: 'background-color 0.3s ease'
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '2px',
      left: '2px',
      width: `calc(${HEIGHT}rem - 4px)`,
      height: `calc(${HEIGHT}rem - 4px)`,
      backgroundColor: '#fff',
      border: `3px solid ${theme.colors.input.border}`,
      borderRadius: '100%',
      boxShadow: '-1px 0 1px 0 rgba(0, 0, 0, 0.2)',
      boxSizing: 'border-box',
      transform: ({ value }: Props) =>
        value === 'true'
          ? `translateX(${WIDTH}rem) translateX(-4px) translateX(-100%)`
          : `translateX(0) translateX(0) translateX(0)`,
      transition: 'transform 0.3s ease'
    },
    '&:focus, &:hover': {
      outline: 'none'
    }
  },
  label: {
    margin: '0 0.3125rem',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    lineHeight: 1.2,
    color: theme.colors.grey,
    transition: 'color 0.3s ease-out'
  },
  labelWorking: {
    extend: 'label',
    marginLeft: '0.625rem',
    color: ({ value }: Props) => (value === 'false' ? theme.colors.blue : theme.colors.grey)
  },
  labelCalendar: {
    extend: 'label',
    marginRight: '0.625rem',
    color: ({ value }: Props) => (value === 'true' ? theme.colors.blue : theme.colors.grey)
  }
})

export default styles

const styles = (theme: App.Theme) => ({
  container: {
    display: 'flex',
    marginBottom: '15px',
    paddingRight: '15px'
  },
  grip: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px'
  },
  gripIcon: {
    width: '1rem',
    height: '1rem'
  },
  iconField: {
    display: 'flex',
    width: '64px',
    height: '64px',
    marginRight: '15px',
    borderRadius: '5px',
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23333' stroke-width='1' stroke-dasharray='8' stroke-dashoffset='5' stroke-linecap='butt'/%3e%3c/svg%3e")`
  },
  icon: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  inputs: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minWidth: '270px',
    marginRight: '15px'
  },
  inputField: {
    height: '30px'
  },
  inputContent: {
    width: '100%',
    height: '100%',
    margin: 0,
    padding: '0 7px',
    background: theme.colors.border,
    borderRadius: '5px',
    border: `1px solid ${theme.colors.hr}`,
    boxSizing: 'border-box'
  },
  input: {
    width: '100%',
    height: '100%'
  },
  systemButtons: {
    display: 'flex',
    alignItems: 'center'
  },
  systemButton: {},
  addButtonContainer: {
    display: 'flex',
    width: '100%',
    padding: '0 15px'
  },
  addButton: {},
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    padding: '15px'
  },
  footerButton: {
    '&:not(:last-child)': {
      marginRight: '15px'
    }
  }
})

export { styles as default }

import React from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import { Transition, animated, config, SpringConfig } from 'react-spring'
import { compose } from 'recompose'
import classNames from 'classnames'

import Loading from 'components/Loading'
import styles from './styles'

interface OuterProps {
  show: boolean
  minSize?: number
  isTransparent?: boolean
  zIndex?: number
  loaderSize?: number
}
export interface Props extends OuterProps, JSSProps<typeof styles> { }

const defaultConfig: SpringConfig = {
  ...config.slow,
  clamp: true
}
const LoadingOverlay: Props = ({
  classes,
  show,
  loaderSize,
  isTransparent = false
}) => (
  <Transition
    items={show}
    config={defaultConfig}
    native
    from={{ opacity: 1, backgroundColor: isTransparent ? 'transparent' : 'rgba(255, 255, 255, 1)' }}
    enter={{
      opacity: 1,
      backgroundColor: isTransparent ? 'transparent' : 'rgba(255, 255, 255, 1)'
    }}
    leave={{
      opacity: 1,
      backgroundColor: isTransparent ? 'transparent' : 'rgba(255, 255, 255, 0)'
    }}
  >
    {show =>
      !show ? null :
        (style => (
          <animated.div className={classes.overlay} style={style}>
            <Loading
              className={classNames(classes.loading, 'loading-overlay')}
              delay={0}
              size={loaderSize ? `${loaderSize}px` : undefined}
            />
          </animated.div>
        ))
    }
  </Transition>
)

export default compose<Props, OuterProps>(injectStyles(styles))(LoadingOverlay)

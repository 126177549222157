import React, { StatelessComponent } from 'react'
import ArrowRendererProps from 'react-select'
import injectStyles, { JSSProps } from 'react-jss'

import styles from './styles'
import { SelectorExpand } from 'components/Icons'

export interface Props extends ArrowRendererProps, JSSProps<typeof styles> {}

const Arrow: StatelessComponent<Props> = ({ classes }: Props) => (
  <SelectorExpand className={classes.arrow} />
)

export default injectStyles<ArrowRendererProps>(styles)(Arrow)

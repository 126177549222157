import React, {
  memo,
  DetailedHTMLProps,
  HTMLAttributes,
  useMemo
} from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import { useDrag } from 'react-dnd'
import { compose } from 'recompose'
import cn from 'classnames'

import AggregationService from 'lib/services/aggregation'
import { ExpandMore, ExpandLess } from '@mui/icons-material'
import { Value as ValueIcon, Text as TextIcon, Date as DateIcon } from 'components/Icons'
import { TYPE } from 'constants/set'
import { FORM } from 'constants/aggregation'
import styles from './styles'

export interface OuterProps {
  field: Filter.MainField
  type: TYPE
  draggable?: boolean
  index: number
  icon?: 'value' | 'date' | 'text'
  classNames?: { icon?: string; container?: string; set?: string }
  containerProps?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  menuOpened?: boolean
  menuOpenedIndex?: number
  openMenu?: (anchorEl: HTMLDivElement, field: Filter.MainField) => void
}
interface DragCollectProps {}
interface Props extends JSSProps<typeof styles>, Partial<DragCollectProps>, OuterProps { }
export interface ItemProps extends JSSProps<typeof styles>, OuterProps { }

export const DRAG_SOURCE = {
  name: 'FILTER_FIELD_ITEM',
  source: {
    beginDrag(props: ItemProps, monitor, component) {
      return props
    },
    endDrag(props: ItemProps, monitor, component) {
      if (!monitor.didDrop()) {
        return
      }
    }
  },
}

const FilterFieldItemHOC = memo(({ draggable = true, ...props }: Props) =>
  <AggregationFilterField draggable={draggable} {...props} />
)

const AggregationFilterField = ({
  field,
  openMenu,
  icon = 'value',
  classNames = {},
  classes,
  theme,
  type,
  containerProps = {},
  menuOpened,
  menuOpenedIndex,
  index,
  draggable,
  ...props
}: Props) => {  
  const [{ }, drag] = useDrag(() => ({
    type: DRAG_SOURCE.name,
    end: DRAG_SOURCE.source.endDrag,
    item: { props }
  }))

  const isMenuTarget = useMemo(() => {
    return field.entity_type === 'measures'
  }, [field])

  const iconComponent = useMemo(() => {
    if (field.entity_type === 'measures')
      return (
        <span className={classes.iconContainer}>
          {AggregationService.getAggregationSymbol(field.agg as FORM)}
        </span>
      )
    else {
      const IconComponent = icon === 'date' ? DateIcon : icon === 'text' ? TextIcon : ValueIcon

      return <IconComponent className={cn(classes?.icon, classNames?.icon)} />
    }
  }, [])

  const onOpenMenu = () => {
    openMenu(drag, field)
  }

  return (
    <div
      {...containerProps}
      className={cn(classes.container, classNames.container, containerProps.className)}
      {...props}
      ref={drag}
    >
      {iconComponent}
      <div className={classes.sets}>
        <span className={cn(classes.set, classNames.set)}>{field.name}</span>
      </div>
      {isMenuTarget && (
        <button className={classes.arrowButton} type="button" onClick={onOpenMenu}>
          {menuOpened && menuOpenedIndex === index ? (
            <ExpandLess className={classes.arrow} />
          ) : (
            <ExpandMore className={classes.arrow} />
          )}
        </button>
      )}
    </div>
  )
}

export default compose<Props, OuterProps>(injectStyles(styles))(FilterFieldItemHOC)

import React, { useRef, useState, useEffect, MouseEventHandler } from 'react'
import { Badge, Button } from '@mui/material'
import cn from 'classnames'
import { Logout, Notifications, Settings } from 'components/Icons'
import NotificationsList from 'components/NotificationsList'
import { STATES } from 'constants/states'
import UserSettings from 'containers/UserSettings'
import { getNotifications, readNotification } from 'lib/modules/notifications/actions'
import { getAllNotifications, getNotificationsStatus } from 'lib/modules/notifications/selectors'
import { readResourcesCount } from 'lib/modules/sidemenu/actions'
import { authResetRequest } from 'lib/modules/user/actions'
import { ACLMap } from 'lib/utils/aclMask'

import injectStyles, { JSSProps } from 'react-jss'
import { MapDispatchToProps, MapStateToProps, connect } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Tooltip } from '@mui/material'
import { compose } from 'recompose'
import { setSystem } from 'src/lib/modules/infoSystems/actions'
import styles from './styles'

interface OuterProps {
  openSettings: () => void
}

interface StateProps {
  items: App.State['sidemenu']['items']
  resourcesCount: App.State['sidemenu']['resourcesCount']
  notifications: Array<Data.Notification>
  notifications_status: keyof typeof STATES
  breadcrumbs: Array<Data.Breadcrumbs>
  aclMap: ACLMap
  viewportHeight: number
  username: App.State['user']['message_name']
  email: App.State['user']['email'] | undefined
  user_type: App.State['user']['user_type'] | undefined
}

interface DispatchProps {
  authResetRequest: () => void
  getNotifications: () => void
  readNotification: (id: number) => void
  readResourcesCount: () => void
  setSystem: (system: Data.InfoSystem) => void
}

export interface Props
  extends StateProps,
  DispatchProps,
  OuterProps,
  JSSProps<typeof styles> { }

interface State {
  openModal: boolean
}

const adminRoutes = [
  '/journal',
  '/scdata',
  '/news',
  '/users',
  '/construction_objects',
  '/catalog_items'
]

const defaultRoutes = [
  '/desktop',
  '/messages',
  '/forms',
  '/widgets',
  '/spk_building',
  '/other_services',
  '/info_references',
  '/search',
  '/map',
  '/incidents',
  '/messages',
  '/elements',
  '/panels'
]

const SideMenuContainer = (props) => {
  const [openedModal, openModal] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const { notifications_status, getNotifications, readResourcesCount } = props

    if (notifications_status !== STATES.SUCCEEDED && notifications_status !== STATES.LOADING)
      getNotifications()

    readResourcesCount()
  }, [])

  const isUserAdmin = props.user_type === 'superadmin'


  const onChangeRoutes: MouseEventHandler<HTMLButtonElement> = () => {
    navigate(isAdminRoutes ? defaultRoutes[0] : adminRoutes[0])
  }

  const onLogout: MouseEventHandler<HTMLAnchorElement> = () => {
    props.authResetRequest()
  }

  const hasNotifications = (id: string) => props.items.find(i => i.id === id).notification

  const getNotificationsCount = (id: string) =>
    props.items.find(i => i.id === id).notificationsCount || 0

  const hideNotificationsTooltip = () => {

  }

  const isAdminRoutes = adminRoutes.some(adminRoute => location.pathname.includes(adminRoute))

  const {
    notifications, classes, readNotification, user_type, breadcrumbs, username
  } = props

  const isHead = user_type === 'head'

  return (
    <div className={classes.menuWrap}>
      <div style={{ ...styles }} className={classes.menuContainer}>
        <div className={classes.menuBody}>
          {isUserAdmin && (
            <Button
              className={classes.changeRoutes}
              variant="contained"
              onClick={onChangeRoutes}
            >
              {isAdminRoutes ? 'Ситуационный центр' : 'Администрирование'}
            </Button>
          )}
          <div className={classes.breadcrumbs}>
            {breadcrumbs.map(({ text, link, onClick }) => {
              if (!link)
                return (
                  <p key={text} className={cn(classes.menuLabel, { [classes.clickableLabel]: Boolean(onClick) })} onClick={onClick}>
                    {text}
                  </p>
                )

              return (
                <Link className={classes.breadcrumbLink} to={link} key={text}>
                  {text}
                </Link>
              )
            })}
          </div>
        </div>
        <div className={classes.menuFooter}>
          {!isHead && (
            <Tooltip
              id="notifications-tooltip"
              title={(
                <NotificationsList
                  notifications={notifications}
                  readNotification={readNotification}
                />
              )}
              placement="bottom"
              className={classes.notificationsTooltip}
            >
              <Badge
                classes={{ badge: classes.notificationsButtonBadge }}
                color="secondary"
                badgeContent={getNotificationsCount('notifications')}
                invisible={!hasNotifications('notifications')}
              >
                <Button
                  classes={{ label: classes.notificationsButtonLabel }}
                  variant="text"
                >
                  <Notifications />
                </Button>
              </Badge>
            </Tooltip>
          )}
          <Button
            classes={{ label: classes.buttonUserName }}
            onClick={() => openModal(true)}
          >
            <Settings width="14px" height="14px" style={{ marginRight: '8px' }} />
            {username}
          </Button>
          <Button
            classes={{ label: classes.buttonLogOut }}
            variant="text"
            onClick={onLogout}
          >
            Выход
            <Logout width="14px" height="14px" style={{ marginLeft: '8px' }} />
          </Button>
        </div>
      </div>
      <UserSettings
        isOpen={openedModal}
        closeModal={() => openModal(false)}
      />
    </div>
  )
}

const mapStateToProps: MapStateToProps<StateProps, Props, App.State> = state => ({
  items: state.sidemenu.items,
  resourcesCount: state.sidemenu.resourcesCount,
  breadcrumbs: state.sidemenu.breadcrumbs,
  notifications: getAllNotifications(state),
  notifications_status: getNotificationsStatus(state),
  aclMap: state.user.aclMap,
  viewportHeight: state.viewport.height,
  username: state.user.message_name,
  email: state.user.email,
  user_type: state.user.user_type
})

const mapDispatchToProps: MapDispatchToProps<DispatchProps, Props> = dispatch => ({
  authResetRequest: () => dispatch(authResetRequest()),
  getNotifications: () => dispatch(getNotifications()),
  readNotification: id => dispatch(readNotification(id)),
  readResourcesCount: () => dispatch(readResourcesCount()),
  setSystem: (system: Data.InfoSystem) => dispatch(setSystem(system))
})

export default compose<Props, OuterProps>(

  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectStyles<OuterProps>(styles)
)(SideMenuContainer)

import React, { PureComponent, ChangeEvent } from 'react'

import { compose } from 'recompose'
import InputRange, { Range as RangeProps, InputRangeProps } from 'react-input-range'
import Mask from 'react-input-mask'
import injectStyles, { JSSProps } from 'react-jss'
import classNames from 'classnames'
import isEqual from 'lodash/isEqual'
import format from 'date-fns/format'

import { MASK } from 'constants/date'
import styles from './styles'

interface OuterProps extends Partial<InputRangeProps> {
  id: string
  className?: string
  value: RangeProps
  onChange: (value: RangeProps) => void
}
export interface Props extends OuterProps, JSSProps<typeof styles> {}
interface State {
  value: {
    min: string
    max: string
  }
}

class Range extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)

    const { min, max } = props.value

    this.state = {
      value: {
        min: format(min || props.minValue, MASK),
        max: format(max || props.maxValue, MASK)
      }
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (isEqual(nextProps.value, this.props.value)) {
      this.updateValues(nextProps.value)
    }
  }

  changeRangeValue = (value: RangeProps) => {
    const { minValue, maxValue } = this.props
    let { min, max } = value

    if (min < minValue) {
      min = minValue
    }

    if (max > maxValue) {
      max = maxValue
    }

    this.setState({ value: { min: format(min, MASK), max: format(max, MASK) } })
  }

  changeRangeValueComplete = (value: RangeProps) => {
    const { minValue, maxValue, onChange } = this.props

    let { min, max } = value

    if (min < minValue) {
      min = minValue
    }

    if (max > maxValue) {
      max = maxValue
    }

    onChange({ min, max })
  }

  changeMinValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { minValue, value, onChange } = this.props
    const minCurrent = this.parse(event.currentTarget.value).getTime()
    const maxCurrentValue = value.max

    this.setState({ value: { ...this.state.value, min: event.currentTarget.value } })

    if (minCurrent >= minValue && minCurrent < maxCurrentValue) {
      onChange({
        ...value,
        min: minCurrent
      })
    }
  }

  changeMaxValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { maxValue, value, onChange } = this.props
    const maxCurrent = this.parse(event.currentTarget.value).getTime()
    const minCurrentValue = value.min

    this.setState({ value: { ...this.state.value, max: event.currentTarget.value } })

    if (maxCurrent <= maxValue && maxCurrent > minCurrentValue) {
      onChange({
        ...value,
        max: maxCurrent
      })
    }
  }

  updateValues(value: RangeProps) {
    this.setState({ value: { min: format(value.min, MASK), max: format(value.max, MASK) } })
  }

  formatLabel = value => format(value, MASK)

  parse = (value: string) => {
    const splitted = value.split('/')

    return new Date(`${splitted[2]}/${splitted[1]}/${splitted[0]}`)
  }

  render() {
    const {
      classes,
      theme,
      className,
      minValue,
      maxValue,
      value: inputValue,
      onChange,
      ...props
    } = this.props
    const { value } = this.state
    const inputMinValue = this.parse(value.min).getTime()
    const inputMaxValue = this.parse(value.max).getTime()

    let min = inputMinValue
    let max = inputMaxValue

    if (isNaN(inputMinValue) || inputMinValue > inputValue.max) {
      min = inputValue.min
    } else if (inputMinValue < minValue) {
      min = minValue
    }

    if (isNaN(inputMaxValue) || inputMaxValue < inputValue.min) {
      max = inputValue.max
    } else if (inputMaxValue > maxValue) {
      max = maxValue
    }

    const rangeValue = { min, max }

    return (
      <div className={classNames(classes.container, className)}>
        <Mask
          className={classes.input}
          mask="99/99/9999"
          value={value.min}
          onChange={this.changeMinValue}
        />
        <InputRange
          minValue={minValue}
          maxValue={maxValue}
          step={3600 * 1000 * 24}
          value={rangeValue}
          formatLabel={this.formatLabel}
          onChange={this.changeRangeValue}
          onChangeComplete={this.changeRangeValueComplete}
          {...props}
        />
        <Mask
          className={classes.input}
          mask="99/99/9999"
          value={value.max}
          onChange={this.changeMaxValue}
        />
      </div>
    )
  }
}

export default compose<Props, OuterProps>(
  
  injectStyles<OuterProps>(styles)
)(Range)

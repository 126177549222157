import { Reducer } from 'redux'

import { Actions } from './actions'
import TYPES from './actionTypes'

export interface State {
  menu: {
    anchor?: HTMLDivElement
    targetAggregation?: Data.AggregationEntity
    targetGroup?: Data.AggregationGroup
    sourceItem?: Data.AggregationEntity | Data.Dimension | Data.Measure
    sourceItemIsAggregation?: boolean
  }
}

const initialState: State = {
  menu: {}
}

export const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.OPEN_AGGREGATION_MENU:
      return {
        ...state,
        menu: action.payload
      }
    case TYPES.CLOSE_AGGREGATION_MENU:
      return {
        ...state,
        menu: {
          ...state.menu,
          anchor: undefined
        }
      }
    case TYPES.CHANGE_TARGET_MENU: {
      const newState = { ...state, menu: { ...state.menu } }

      switch (action.payload.type) {
        case 'aggregation':
          newState.menu.targetAggregation = action.payload.target as Data.AggregationEntity
          break
        case 'group':
          newState.menu.targetGroup = action.payload.target as Data.AggregationGroup
          break
      }
      return newState
    }
    default:
      return state
  }
}

import { takeEvery, put, select, all, delay, call } from 'redux-saga/effects'
import { success, error } from 'react-notification-system-redux'
import {
  getFormValues,
  startSubmit,
  setSubmitSucceeded,
  setSubmitFailed,
  stopSubmit
} from 'redux-form'
import { push } from 'connected-react-router'

import fetchAPI from 'lib/services/fetchAPI'
import APIAction from 'lib/utils/APIAction'
import getResourceActions from 'lib/utils/getResourceActions'
import { tokenSelector } from 'lib/modules/user/selectors'
import ActionCableService from 'lib/services/actionCable'
import { BASE_CHANNEL } from 'constants/actionCable'
import { FORMS_EDITOR_FORM } from 'constants/forms'
import { FORMS } from 'constants/resources'
import { METHOD, ENDPOINT } from 'constants/api'
import { GetForms, CreateForm, UpdateForm, DeleteForm, AnswerForm } from './actions'
import types from './actionTypes'

function* getForms(action: GetForms) {
  const { requestKey, resourceType, requestProperties = {} } = action.payload
  const list = requestProperties.full_info
    ? FORMS.LISTS.FULL_DATA_FORMS
    : FORMS.LISTS.PARTIAL_DATA_FORMS
  const token = yield select(tokenSelector)
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'READ',
    resourceType,
    requestKey,
    requestProperties,
    list
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        endpoint: ENDPOINT.FORMS,
        token,
        params: requestProperties
      })
    },
    *success(data) {
      yield put(onSuccessAction(data))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось получить данные.'
        })
      )
    }
  })
}

function* createForm(action: CreateForm) {
  const { requestKey, resourceType, list, redirect } = action.payload
  const values = yield select(getFormValues(FORMS_EDITOR_FORM.form))
  const token = yield select(tokenSelector)
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'CREATE',
    resourceType,
    requestKey,
    list
  })

  yield APIAction({
    *request() {
      yield put(startSubmit(FORMS_EDITOR_FORM.form))
      yield put(onRequestAction())

      const { data }: { data: Data.FormFull } = yield call(fetchAPI, {
        endpoint: ENDPOINT.FORMS,
        method: METHOD.POST,
        token,
        body: {
          form: values
        }
      })

      return { data: [data] }
    },
    *success(data) {
      yield put(onSuccessAction(data))
      yield put(stopSubmit(FORMS_EDITOR_FORM.form))
      yield put(setSubmitSucceeded(FORMS_EDITOR_FORM.form))

      yield put(
        success({
          title: 'Форма успешно создана.'
        })
      )

      if (redirect) {
        yield call(delay, 1000)
        yield put(push({ pathname: redirect }))
      }
    },
    *fail(errors) {
      yield put(onFailAction(errors))
      yield put(stopSubmit(FORMS_EDITOR_FORM.form, errors))
      yield put(setSubmitFailed(FORMS_EDITOR_FORM.form))

      yield put(
        error({
          title: 'Не удалось создать форму.'
        })
      )
    }
  })
}

function* updateForm(action: UpdateForm) {
  const { requestKey, resourceType, list, redirect } = action.payload
  const values = yield select(getFormValues(FORMS_EDITOR_FORM.form))
  const token = yield select(tokenSelector)

  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'UPDATE',
    resourceType,
    requestKey,
    list,
    ids: [values.id]
  })

  yield APIAction({
    *request() {
      yield put(startSubmit(FORMS_EDITOR_FORM.form))

      yield put(onRequestAction())

      const { data }: { data: Data.FormFull } = yield call(fetchAPI, {
        endpoint: ENDPOINT.FORMS,
        method: METHOD.PUT,
        path: values.id,
        token,
        body: {
          form: values
        }
      })

      return { data: [data] }
    },
    *success(data) {
      yield put(onSuccessAction(data))
      yield put(stopSubmit(FORMS_EDITOR_FORM.form))
      yield put(setSubmitSucceeded(FORMS_EDITOR_FORM.form))

      if (redirect) yield put(push(redirect))
    },
    *fail(errors) {
      yield put(onFailAction(errors))
      yield put(stopSubmit(FORMS_EDITOR_FORM.form, errors))
      yield put(setSubmitFailed(FORMS_EDITOR_FORM.form))

      yield put(
        error({
          title: 'Не удалось обновить форму.'
        })
      )
    }
  })
}

function* deleteForm(action: DeleteForm) {
  const { requestKey, resourceType, requestProperties } = action.payload
  const token = yield select(tokenSelector)
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'DELETE',
    resourceType,
    requestKey,
    ids: [requestProperties.id],
    requestProperties
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        endpoint: ENDPOINT.FORMS,
        method: METHOD.DELETE,
        path: requestProperties.id.toString(),
        token
      })
    },
    *success(data) {
      yield put(onSuccessAction([requestProperties.id]))
      yield put(push('/forms'))

      yield put(
        success({
          title: 'Форма успешно удалена.'
        })
      )
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось удалить форму.'
        })
      )
    }
  })
}

function* answerForm(action: AnswerForm) {
  const { requestKey, resourceType, requestProperties } = action.payload
  const token = yield select(tokenSelector)

  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'UPDATE',
    resourceType,
    requestKey,
    requestProperties,
    ids: [requestProperties.form_id]
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      yield ActionCableService.sendMessage({
        channelName: BASE_CHANNEL,
        type: requestKey,
        token,
        payload: {
          ...requestProperties
        }
      })

      const { payload } = yield ActionCableService.sendMessage<Data.FormFull>({
        channelName: BASE_CHANNEL,
        type: FORMS.REQUESTS.GET_FORM,
        token,
        payload: {
          id: requestProperties.form_id
        }
      })

      return { data: [payload] }
    },
    *success(data) {
      yield put(onSuccessAction(data))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось отправить ответ.'
        })
      )
    }
  })
}

export function* watcher() {
  yield all([
    takeEvery(types.GET_FORMS, getForms),
    takeEvery(types.CREATE_FORM, createForm),
    takeEvery(types.UPDATE_FORM, updateForm),
    takeEvery(types.DELETE_FORM, deleteForm),
    takeEvery(types.ANSWER_FORM, answerForm)
  ])
}

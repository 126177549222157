import { FILTERS_LINE_HEIGHT } from 'constants/filter'

const styles = (theme: App.Theme) => ({
  container: {
    display: 'flex',
    ...theme.utils.flexContainer({ direction: 'column' }),
    flexShrink: 0,
    width: '100%',
    boxSizing: 'border-box'
  },
  header: {
    width: '100%',
    padding: '0.5rem',
    boxSizing: 'border-box',
    backgroundColor: 'white',
    ...theme.utils.flexContainer({ direction: 'row', alignItems: 'center', justify: 'flex-start' })
  },
  hideButton: {
    fontSize: '1rem',
    lineHeight: `calc(${FILTERS_LINE_HEIGHT} + 2px)`,
    color: theme.colors.grey,
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  title: {
    margin: 0,
    marginBottom: '0.625rem',
    fontFamily: 'Helvetica',
    fontSize: '0.625rem',
    fontWeight: 'bold',
    letterSpacing: 0.5,
    color: theme.colors.grey,
    textTransform: 'uppercase'
  },
  collapse: {
    width: '100%'
  },
  addButton: {
    alignSelf: 'center',
    marginTop: '0.5rem'
  }
})

export default styles

import { FILTERS_LINE_HEIGHT } from 'constants/filter'

const styles = (theme: App.Theme) => ({
  box: {
    flexShrink: 1,
    flexGrow: 1,
    width: 100,
    height: `calc(${FILTERS_LINE_HEIGHT} + 2px)`,
    lineHeight: '1.2',
    fontSize: '0.75rem',
    color: '#000',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    backgroundColor: '#FFFFFF',
    border: `1px solid ${theme.colors.input.border}`,
    borderRadius: 5,
    outline: 'none',
    boxShadow: 'none',
    boxSizing: 'border-box',
    overflow: 'hidden',
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.colors.blue
    },
    transition: 'background-color 0.3s ease-out, border-color 0.3s ease-out'
  },
  error: {
    borderColor: theme.colors.red
  },
  tooltip: {
    width: '18.75rem',
    padding: '0 !important',
    opacity: '1 !important',
    filter: 'drop-shadow(0 0 3px rgba(0,0,0,0.15))',
    pointerEvents: 'auto !important',
    '&:after': {
      borderBottomColor: '#FAFAFA !important'
    }
  },
  tooltipContent: {
    ...theme.utils.flexContainer({ direction: 'column' }),
    maxHeight: '50vh',
    overflow: 'auto',
    pointerEvents: 'auto !important'
  },
  title: {
    width: '100%',
    margin: 0,
    padding: '1.25rem',
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontSize: '1.5rem',
    color: '#000000',
    backgroundColor: theme.colors.page.contentBackground,
    zIndex: 'inherit',
    boxSizing: 'border-box',
    pointerEvents: 'auto'
  },
  block: {
    ...theme.utils.flexContainer({ direction: 'column' }),
    flexShrink: 0,
    width: '100%',
    zIndex: 'inherit',
    boxSizing: 'border-box',
    pointerEvents: 'auto',
    '& > *:not($titleSmall)': {
      marginBottom: '0.625rem'
    }
  },
  titleSmall: {
    width: '100%',
    margin: '0.625rem 0',
    padding: '0 calc(1.25rem + 2px)',
    fontSize: '0.625rem',
    lineHeight: 1.2,
    fontWeight: 'bold',
    letterSpacing: 0.5,
    textTransform: 'uppercase',
    color: theme.colors.grey,
    boxSizing: 'border-box'
  },
  calendar: {
    fontFamily: 'inherit !important',
    backgroundColor: 'transparent !important',
    borderTop: `1px solid ${theme.colors.input.border} !important`,
    borderBottom: `none !important`,
    borderLeft: 'none !important',
    borderRight: 'none !important'
  },
  dynamic: {
    extend: 'expanded',
    position: 'relative',
    display: 'flex',
    padding: '0.625rem'
  },
  dynamicCheckbox: {
    extend: 'expandedCheckbox',
    '&:checked + $dynamicCheckboxLabel::after': {
      transform: 'scale(1)'
    }
  },
  dynamicCheckboxLabel: {
    extend: 'expandedCheckboxLabel',
    '&::before': {
      top: '0.625rem',
      left: '0.625rem'
    },
    '&::after': {
      top: '1rem',
      left: '1rem'
    }
  },
  dateSelect: {
    width: 'calc(100% - 1.25rem)',
    margin: '0 0.625rem',
    boxSizing: 'border-box'
  },
  expanded: {
    width: 'calc(100% - 1.25rem)',
    margin: '0 0.625rem',
    backgroundColor: theme.colors.page.contentBackground,
    border: `1px solid ${theme.colors.input.border}`,
    borderRadius: '0.5rem',
    boxSizing: 'border-box'
  },
  expandedHeader: {
    position: 'relative',
    display: 'flex',
    width: 'calc(100% - 1.25rem)',
    margin: '0.625rem',
    boxSizing: 'border-box'
  },
  expandedCheckbox: {
    flexShrink: 0,
    width: '1.5rem',
    height: '1.5rem',
    marginRight: '0.5rem',
    visibility: 'hidden',
    '&:checked + $expandedCheckboxLabel::after': {
      transform: 'scale(1)'
    }
  },
  expandedCheckboxLabel: {
    marginTop: '0.3125rem',
    fontSize: '0.875rem',
    color: theme.colors.grey,
    cursor: 'pointer',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '1.5rem',
      height: '1.5rem',
      backgroundColor: 'transparent',
      border: `2px solid ${theme.colors.input.border}`,
      borderRadius: '50%',
      boxSizing: 'border-box'
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '0.375rem',
      left: '0.375rem',
      width: '0.75rem',
      height: '0.75rem',
      backgroundColor: theme.colors.blue,
      borderRadius: '50%',
      boxSizing: 'border-box',
      transform: 'scale(0)',
      transition: 'transform 0.15s ease-out'
    }
  }
})

export default styles

export const USERS = {
  NAME: 'users',
  REQUESTS: {
    GET_USERS: 'GET_USERS',
    GET_GRBS_USERS: 'GET_GRBS_USERS',
    GET_GRBS_ORGANISATIONS_USERS: 'GET_GRBS_ORGANISATIONS_USERS',
    GET_HEAD_USERS: 'GET_HEAD_USERS',
    GET_NORMAL_USERS: 'GET_NORMAL_USERS',
    GET_ORGANISATIONS_USERS: 'GET_ORGANISATIONS_USERS',
    GET_ADMIN_USERS: 'GET_ADMIN_USERS',
    GET_DEVELOPER_USERS: 'GET_DEVELOPER_USERS',
    GET_SPECIAL_USERS: 'GET_SPECIAL_USERS',
    SEARCH_USERS: 'SEARCH_USERS',
    CREATE_USER: 'CREATE_USER',
    UPDATE_USER: 'UPDATE_USER',
    RESET_USER_PASSWORD: 'RESET_USER_PASSWORD',
    BLOCK_USER: 'BLOCK_USER',
    DROP_USER: 'DROP_USER'
  },
  LISTS: {
    ALL_USERS: 'ALL_USERS',
    GRBS_USERS: 'GRBS_USERS',
    HEAD_USERS: 'HEAD_USERS',
    NORMAL_USERS: 'NORMAL_USERS',
    ADMIN_USERS: 'ADMIN_USERS',
    DEVELOPER_USERS: 'DEVELOPER_USERS',
    SPECIAL_USERS: 'SPECIAL_USERS',
    ORGANISATIONS_USERS: 'ORGANISATIONS_USERS',
    SEARCH: 'SEARCH'
  }
}

export const ORGANISATIONS = {
  NAME: 'organisations',
  REQUESTS: {
    GET_ALL_GRBS: 'GET_ALL_GRBS',
    GET_GRBS_ORGANISATIONS: 'GET_GRBS_ORGANISATIONS',
    SEARCH_ORGANISATIONS: 'SEARCH_ORGANISATIONS',
    GET_ORGANISATIONS_BY_IDS: 'GET_ORGANISATIONS_BY_IDS',
    GET_ORGANISATIONS: 'GET_ORGANISATIONS'
  },
  LISTS: {
    ALL_GRBS: 'ALL_GRBS',
    SEARCH: 'SEARCH'
  }
}

export const MSP = {
  NAME: 'msp',
  REQUESTS: {
    GET_MSP_CUBE: 'GET_MSP_CUBE'
  },
  LISTS: {
    ALL: 'ALL_MSP'
  }
}

export const CHART_CATEGORIES = {
  NAME: 'chartCategories',
  REQUESTS: {
    GET_CHART_CATEGORIES: 'GET_CHART_CATEGORIES',
    GET_DIMENSION_VALUES: 'GET_FILTER_VALUES'
  }
}

export const REPORT_CATEGORIES = {
  NAME: 'reportCategories',
  REQUESTS: {
    GET_REPORT_CATEGORIES: 'GET_REPORT_CATEGORIES'
  }
}

export const PUBLICATIONS = {
  NAME: 'publications',
  REQUESTS: {
    GET_PUBLICATIONS: 'GET_PUBLICATIONS',
    CREATE_PUBLICATION: 'CREATE_PUBLICATION',
    UPDATE_PUBLICATION: 'UPDATE_PUBLICATION',
    DELETE_PUBLICATION: 'DELETE_PUBLICATION'
  },
  LISTS: {
    ALL: 'all'
  }
}

export const CATALOG_ITEMS = {
  NAME: 'catalog_items',
  REQUESTS: {
    GET_CATALOG_ITEMS: 'GET_CATALOG_ITEMS',
    CREATE_CATALOG_ITEM: 'CREATE_CATALOG_ITEM',
    UPDATE_CATALOG_ITEM: 'UPDATE_CATALOG_ITEM',
    DELETE_CATALOG_ITEM: 'DELETE_CATALOG_ITEM'
  },
  LISTS: {
    STATE_PROGRAMS: 'state_programs',
    INDUSTRIES: 'industries',
    CUSTOMERS: 'customers',
    PERFORMERS: 'performers',
    DISTRICTS: 'districts',
    EXPERTISE_STATUSES: 'expertise_statuses',
    CATEGORIES: 'categories',
    HIERARCHIES: 'hierarchies'
  }
}

export const MESSAGES = {
  NAME: 'messages',
  REQUESTS: {
    GET_RECEIVED_MESSAGES: 'GET_RECEIVED_MESSAGES',
    GET_SENT_MESSAGES: 'GET_SENT_MESSAGES',
    GET_MESSAGE: 'GET_MESSAGE',
    SEND_MESSAGES: 'SEND_MESSAGES'
  },
  LISTS: {
    RECEIVED: 'RECEIVED',
    SENT: 'SENT'
  }
}

export const MESSAGES_RECEIVERS = {
  NAME: 'messageReceivers',
  REQUESTS: {
    GET_AVAILABLE_MESSAGE_RECEIVERS: 'GET_AVAILABLE_MESSAGE_RECEIVERS'
  }
}

export const CHARTS = {
  NAME: 'charts',
  REQUESTS: {
    GET_CHARTS: 'GET_CHARTS',
    CREATE_CHART: 'CREATE_CHART',
    UPDATE_CHART: 'UPDATE_CHART',
    DELETE_CHART: 'DELETE_CHART'
  },
  LISTS: {
    ALL: 'all'
  }
}

export const INFO_REFERENCES = {
  NAME: 'info_references',
  REQUESTS: {
    GET_INFO_REFERENCES: 'GET_INFO_REFERENCES',
    CREATE_INFO_REFERENCES: 'CREATE_INFO_REFERENCES',
    UPDATE_INFO_REFERENCES: 'UPDATE_INFO_REFERENCES',
    DELETE_INFO_REFERENCES: 'DELETE_INFO_REFERENCES'
  },
  LISTS: {
    ALL: 'all'
  }
}

export const INFO_SYSTEMS = {
  NAME: 'info_systems',
  REQUESTS: {
    GET_INFO_SYSTEMS: 'GET_INFO_SYSTEMS',
    UPDATE_INFO_SYSTEMS: 'UPDATE_INFO_SYSTEMS'
  },
  LISTS: {
    ALL: 'all'
  }
}

export const WIDGETS = {
  NAME: 'widgets',
  REQUESTS: {
    GET_WIDGETS: 'GET_WIDGETS',
    CREATE_WIDGETS: 'CREATE_WIDGETS',
    UPDATE_WIDGETS: 'UPDATE_WIDGETS',
    DELETE_WIDGETS: 'DELETE_WIDGETS'
  },
  LISTS: {
    ALL: 'all'
  }
}

export const REPORTS = {
  NAME: 'reports',
  REQUESTS: {
    GET_REPORTS: 'GET_REPORTS',
    CREATE_REPORT: 'CREATE_REPORT',
    UPDATE_REPORT: 'UPDATE_REPORT',
    DELETE_REPORT: 'DELETE_REPORT'
  },
  LISTS: {
    ALL: 'all'
  }
}

export const INCIDENTS = {
  NAME: 'incidents',
  REQUESTS: {
    GET_INCIDENTS: 'GET_INCIDENTS',
    CREATE_INCIDENT: 'CREATE_INCIDENT',
    UPDATE_INCIDENT: 'UPDATE_INCIDENT',
    DELETE_INCIDENT: 'DELETE_INCIDENT'
  },
  LISTS: {
    ALL: 'all'
  }
}

export const CONSTRUCTION_OBJECTS = {
  NAME: 'aip_objects',
  REQUESTS: {
    GET_CONSTRUCTION_OBJECTS: 'GET_CONSTRUCTION_OBJECTS',
    CREATE_CONSTRUCTION_OBJECT: 'CREATE_CONSTRUCTION_OBJECT',
    UPDATE_CONSTRUCTION_OBJECT: 'UPDATE_CONSTRUCTION_OBJECT',
    DELETE_CONSTRUCTION_OBJECT: 'DELETE_CONSTRUCTION_OBJECT'
  },
  LISTS: {
    ACTIVE: 'active',
    ARCHIVE: 'archive'
  }
}

export const INCIDENT_TOPICS = {
  NAME: 'incidentTopics',
  REQUESTS: {
    GET_AVAILABLE_INCIDENT_TOPICS: 'GET_AVAILABLE_INCIDENT_TOPICS'
  }
}

export const PANELS = {
  NAME: 'panels',
  REQUESTS: {
    GET_PANELS: 'GET_PANELS',
    CREATE_PANEL: 'CREATE_PANEL',
    UPDATE_PANEL: 'UPDATE_PANEL',
    DELETE_PANEL: 'DELETE_PANEL',
    GET_PANEL_BY_LINK_KEY: 'GET_PANEL_BY_LINK_KEY',
    GET_EMBED_INFO: 'GET_EMBED_INFO',
    ADD_FAVORITE: 'ADD_FAVORITE',
    REMOVE_FAVORITE: 'REMOVE_FAVORITE'
  },
  LISTS: {
    DESKTOP: 'DESKTOP'
  }
}

export const DESKTOP_BUTTONS = {
  NAME: 'desktopButtons',
  REQUESTS: {
    GET_DESKTOP_BUTTON_BY_ID: 'GET_DESKTOP_BUTTON_BY_ID'
  }
}

export const SCANNER_CATEGORIES = {
  NAME: 'scannerCategories',
  REQUESTS: {
    GET_SCANNER_CATEGORIES: 'GET_SCANNER_CATEGORIES',
    GET_SCANNER_CATEGORY_FIELDS: 'GET_SCANNER_CATEGORY_FIELDS',
    GET_SCANNER_CATEGORY_FIELDS_ALL: 'GET_SCANNER_CATEGORY_FIELDS_ALL'
  }
}

export const SCANNER_ENTITIES = {
  NAME: 'scanner',
  REQUESTS: {
    GET_SCANNER_DATA: 'GET_SCANNER_DATA',
    GET_SCANNER_DATA_BY_ID: 'GET_SCANNER_DATA_BY_ID',
    GET_CAREGORY_SCANNERS: 'GET_CAREGORY_SCANNERS',
    GET_ALL_SCANNERS: 'GET_ALL_SCANNERS',
    GET_LAST_SCANNER_UPDATE_TIME: 'GET_LAST_SCANNER_UPDATE_TIME',
    UPDATE_ALL_SCANNERS_DATA: 'UPDATE_ALL_SCANNERS_DATA',
    CREATE_SCANNER: 'CREATE_SCANNER',
    UPDATE_SCANNER: 'UPDATE_SCANNER',
    DELETE_SCANNER: 'DELETE_SCANNER',
    GET_SCANNER_DETECTED_OBJECT: 'GET_SCANNER_DETECTED_OBJECT'
  }
}

export const FORMS = {
  NAME: 'forms',
  REQUESTS: {
    GET_FORMS: 'GET_FORMS',
    GET_FORM: 'GET_FORM',
    CREATE_FORM: 'CREATE_FORM',
    UPDATE_FORM: 'UPDATE_FORM',
    DELETE_FORM: 'DELETE_FORM',
    ANSWER_FORM: 'ANSWER_FORM'
  },
  LISTS: {
    PARTIAL_DATA_FORMS: 'PARTIAL_DATA_FORMS',
    FULL_DATA_FORMS: 'FULL_DATA_FORMS'
  }
}

export const NOTIFICATIONS = {
  NAME: 'notifications',
  REQUESTS: {
    GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
    READ_NOTIFICATION: 'READ_NOTIFICATION'
  },
  LISTS: {}
}

// All resources from this list will be reset on logout
export const RESOURCES_CLEARED_ON_LOGOUT = [
  USERS.NAME,
  ORGANISATIONS.NAME,
  NOTIFICATIONS.NAME,
  FORMS.NAME,
  SCANNER_CATEGORIES.NAME,
  SCANNER_ENTITIES.NAME,
  REPORTS.NAME,
  REPORT_CATEGORIES.NAME,
  CHARTS.NAME,
  CHART_CATEGORIES.NAME,
  MESSAGES.NAME,
  MESSAGES_RECEIVERS.NAME,
  PANELS.NAME,
  PUBLICATIONS.NAME,
  CATALOG_ITEMS.NAME,
  MSP.NAME
]
/* open app resources */
export const ZONES = {
  NAME: 'zones',
  REQUESTS: {
    GET_ZONES_LIST: 'GET_ZONES_LIST',
    GET_SUB_ZONES_LIST: 'GET_SUB_ZONES_LIST'
  },
  LISTS: {
    ZONES_LIST: 'ZONES_LIST',
    SUB_ZONES_LIST: 'SUB_ZONES_LIST'
  }
}

export const SUB_ZONES = {
  NAME: 'sub_zones',
  REQUESTS: {
    GET_SUB_ZONES_LIST: 'GET_SUB_ZONES_LIST'
  },
  LISTS: {
    SUB_ZONES_LIST: 'SUB_ZONES_LIST'
  }
}

export const BRANCHES = {
  NAME: 'branches',
  REQUESTS: {
    GET_BRANCHES_LIST: 'GET_BRANCHES_LIST',
    UPDATE_BRANCH: 'UPDATE_BRANCH'
  },
  LISTS: {
    ALL_LIST: 'ALL_LIST'
  }
}

export const GRBS = {
  NAME: 'grbs',
  REQUESTS: {
    GET_GRBS_LIST: 'GET_GRBS_LIST'
  },
  LISTS: {
    ALL_LIST: 'ALL_LIST'
  }
}

export const ORDERS = {
  NAME: 'orders',
  REQUESTS: {
    SEARCH_ORDERS_PUBLIC: 'SEARCH_ORDERS_PUBLIC',
    GET_FULL_ORDER_INFO: 'GET_FULL_ORDER_INFO'
  }
}

export const ZONE_ORGANISATIONS = {
  NAME: 'zone_organisations',
  REQUESTS: {
    GET_ZONE_ORGANISATIONS: 'GET_ZONE_ORGANISATIONS',
    GET_GRBS_ORGANISATIONS: 'GET_GRBS_ORGANISATIONS',
    GET_BRANCH_ORGANISATIONS: 'GET_BRANCH_ORGANISATIONS',
    SEARCH_ORGANISATIONS_PUBLIC: 'SEARCH_ORGANISATIONS_PUBLIC',
    GET_FULL_ORGANISATION_INFO: 'GET_FULL_ORGANISATION_INFO'
  }
}

export const NEWS = {
  NAME: 'news',
  REQUESTS: {
    GET_PUBLICATIONS: 'GET_PUBLICATIONS',
    GET_AVAILABLE_PUBLICATIONS_COUNT: 'GET_AVAILABLE_PUBLICATIONS_COUNT'
  },
  LISTS: {
    NEWS_LIST: 'NEWS_LIST'
  }
}

export const JOURNAL_LOGS = {
  NAME: 'journalLogs',
  REQUESTS: {
    GET_JOURNAL_LOGS: 'GET_JOURNAL_LOGS'
  }
}

export const COMMON_MANAGMENT = {
  REQUESTS: {
    GET_RESOURCES: 'GET_RESOURCES'
  },
  LISTS: {
    ALL: 'ALL',
    SEARCH: 'SEARCH'
  }
}

export const STATES = {
  IDLE: 'IDLE',
  PENDING: 'PENDING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED'
}

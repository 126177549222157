const styles = (theme: App.Theme) => ({
  container: {},
  imgContainer: {
    position: 'relative',
    width: '6rem',
    height: '10rem',
    margin: '1rem',
    flex: '0 0 calc(20% - 2rem)',
    '& > img': {
      borderRadius: '0.5rem',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
      fontFamily: 'object-fit: cover; object-position: center;',
      filter: 'contrast(40%)',
      transition: 'filter 0.25s linear',

      '&:hover': {
        filter: 'contrast(80%)'
      }
    }
  },
  imgRemoveIcon: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    right: 0,
    opacity: 0.75,
    backgroundColor: theme.colors.button.danger.color,
    borderColor: theme.colors.button.danger.color,
    '& > svg': {
      fill: theme.colors.button.danger.backgroundColor,
      stroke: theme.colors.button.danger.backgroundColor
    },

    '&:hover, &:focus': {
      backgroundColor: theme.colors.button.danger.backgroundColor,
      borderColor: theme.colors.button.danger.backgroundColor,
      '& > svg': {
        stroke: `${theme.colors.button.danger.color} !important`
      }
    }
  },
  imagesRow: {
    ...theme.utils.flexContainer({
      direction: 'row',
      justify: 'flex-start',
      alignItems: 'flex-start',
      wrap: 'wrap'
    }),
    width: '100%'
  }
})

export default styles

import React, { FunctionComponent, ComponentType, DetailedHTMLProps, HTMLAttributes } from 'react'
import injectStyles, { JSSProps, withTheme } from 'react-jss'
import { compose, defaultProps } from 'recompose'
import classnames from 'classnames'

import styles from './styles'

export interface OuterProps extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
  className?: string
  icon?: ComponentType<{ className: string }>
  iconPosition?: 'left' | 'right'
  color?: string
  value: string
  showText?: boolean
}
interface Props extends JSSProps<typeof styles>, OuterProps {}

const Tag: FunctionComponent<Props> = ({
  classes,
  className,
  value,
  icon: Icon,
  iconPosition,
  showText,
  theme,
  ...props
}) => (
  <small {...props} className={classnames(classes.tag, className)}>
    {!!Icon && iconPosition === 'left' && <Icon className={classes.icon} />}
    {value && showText ? value || '-' : null}
    {!!Icon && iconPosition === 'right' && <Icon className={classes.icon} />}
  </small>
)

export default compose<Props, OuterProps>(
  defaultProps<Partial<OuterProps>>({ iconPosition: 'left', color: '#B8B8B8', showText: true }),
  injectStyles(styles),
  withTheme
)(Tag)

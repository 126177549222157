import { FILTERS_LINE_HEIGHT } from 'constants/filter'
import { Props } from './index'

const styles = (theme: App.Theme) => ({
  box: {
    ...theme.utils.flexContainer({ justify: 'center', alignItems: 'center' }),
    flexShrink: 0,
    flexGrow: 0,
    width: `calc(${FILTERS_LINE_HEIGHT} + 2px)`,
    height: `calc(${FILTERS_LINE_HEIGHT} + 2px)`,
    backgroundColor: '#FFFFFF',
    border: `1px solid ${theme.colors.input.border}`,
    borderRadius: 5,
    outline: 'none',
    boxShadow: 'none',
    boxSizing: 'border-box',
    cursor: 'pointer',
    '&:hover': {
      '& > $symbol': { color: '#FFFFFF', '& > svg': { fill: '#FFFFFF' } },
      backgroundColor: theme.colors.blue,
      borderColor: theme.colors.blue
    },
    transition: 'background-color 0.3s ease-out, border-color 0.3s ease-out'
  },
  boxDisabled: {
    backgroundColor: theme.colors.input.border,
    cursor: 'not-allowed',
    '&:hover': {
      '& > $symbol': { color: theme.colors.blue, '& > svg': { fill: theme.colors.blue } },
      backgroundColor: theme.colors.input.border,
      borderColor: theme.colors.input.border
    }
  },
  error: {
    borderColor: theme.colors.red
  },
  symbol: {
    fontFamily: 'Helvetica',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    lineHeight: 0,
    color: theme.colors.blue,
    '& > svg': {
      width: '0.75rem',
      height: '0.75rem',
      fill: theme.colors.blue
    }
  },
  tooltip: {
    padding: '0 !important',
    opacity: '1 !important',
    filter: 'drop-shadow(0 0 3px rgba(0,0,0,0.15))',
    pointerEvents: 'auto !important',
    '&:after': {
      borderBottomColor: '#FAFAFA !important'
    }
  },
  tooltipContent: {
    ...theme.utils.flexContainer({ direction: 'column' }),
    pointerEvents: 'auto !important'
  },
  title: {
    width: '100%',
    margin: 0,
    padding: '1.25rem',
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontSize: '1.5rem',
    color: '#000000',
    backgroundColor: '#FAFAFA',
    zIndex: 'inherit',
    boxSizing: 'border-box',
    pointerEvents: 'auto'
  },
  optionsList: {
    ...theme.utils.flexContainer({ direction: 'column' }),
    width: '100%',
    padding: '1.25rem',
    zIndex: 'inherit',
    boxSizing: 'border-box',
    pointerEvents: 'auto'
  },
  optionRow: {
    flex: '0 0 auto',
    ...theme.utils.flexContainer({ direction: 'row', alignItems: 'center' }),
    width: '100%',
    padding: '0.5rem 0',
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontSize: '0.875rem',
    color: '#000000',
    borderBottom: '1px solid #F3F3F3',
    cursor: 'pointer',
    zIndex: 'inherit',
    pointerEvents: 'auto',
    '&:hover': {
      fontWeight: 'bold',
      color: theme.colors.blue
    },
    '& > $symbol': {
      marginRight: '0.5rem'
    }
  }
})

export default styles

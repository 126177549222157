export const keys = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl'
}
export type Breakpoint =
  | typeof keys['XS']
  | typeof keys['SM']
  | typeof keys['MD']
  | typeof keys['LG']
  | typeof keys['XL']
export type BreakpointValues = { [key in Breakpoint]: number }

export const values: BreakpointValues = {
  xs: 0,
  sm: 600 / 16,
  md: 960 / 16,
  lg: 1280 / 16,
  xl: 1920 / 16
}

export const unit = 'em'
export const step = 5

export const isMobile = !!navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)
export const isDesktop = !isMobile

import { createSelector } from 'reselect'
import { ResourceRequest } from 'redux-resource'

import getResourceState from 'lib/utils/getResourceState'
import { CHARTS } from 'constants/resources'

const getChartsResources = (state: App.State): { [id: string]: Data.Chart } =>
  state.charts.resources
const getChartsRequest = (state: App.State): ResourceRequest =>
  state.charts.requests[CHARTS.REQUESTS.GET_CHARTS]

export const deleteChartStatusSelector = (state: App.State) =>
  getResourceState(state, `${CHARTS.NAME}.requests.${CHARTS.REQUESTS.DELETE_CHART}.status`)

export const getAllCharts = createSelector(
  [getChartsResources],
  function(charts) {
    return Object.values(charts)
  }
)

import { LatLngExpression, latLngBounds } from 'leaflet'
import { Feature, MultiPolygon } from 'geojson'

import { colors } from 'lib/theme/colors'

export const CENTER: LatLngExpression = [59.9, 31.45]
export const DEFAULT_ZOOM = 10
export const MAP_BOUNDS = latLngBounds([59.556, 29.168], [60.345, 31.173])
export const MAX_BOUNDS = latLngBounds([58, 23], [61.5, 42])
export const ESPD_MAX_BOUNDS = latLngBounds([58, 23], [63.5, 42])

export const MAP_URL = 'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'
export const TILE_ATTRIBUTION =
  '<a href="https://www.openstreetmap.org/copyright">Openstreetmap</a>'
// export const MAP_URL = 'https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}{r}.png'
// export const TILE_ATTRIBUTION =
//   '<a href="https://wikimediafoundation.org/wiki/Maps_Terms_of_Use">Wikimedia</a>'

export const getIndex = (indices: Data.Organisation['indices']) =>
  indices && indices.length ? indices[indices.length - 1].value : -1

export const RATINGS = [
  {
    label: 'Все',
    value: 'all',
    test: (indices: Data.Organisation['indices']) =>
      getIndex(indices) >= 0 && getIndex(indices) <= 17,
    color: colors.grey
  },
  {
    label: 'Максимальная',
    value: 'maximum',
    test: (indices: Data.Organisation['indices']) =>
      getIndex(indices) >= 16 && getIndex(indices) <= 17,
    color: colors.green
  },
  {
    label: 'Высокий',
    value: 'high',
    test: (indices: Data.Organisation['indices']) =>
      getIndex(indices) >= 15 && getIndex(indices) < 16,
    color: colors.lightGreen
  },
  {
    label: 'Средний',
    value: 'medium',
    test: (indices: Data.Organisation['indices']) =>
      getIndex(indices) >= 13 && getIndex(indices) < 15,
    color: colors.orange
  },
  {
    label: 'Низкий',
    value: 'low',
    test: (indices: Data.Organisation['indices']) =>
      getIndex(indices) >= 8.5 && getIndex(indices) < 13,
    color: colors.red
  },
  {
    label: 'Неэффективная',
    value: 'inefficient',
    test: (indices: Data.Organisation['indices']) =>
      getIndex(indices) >= 0 && getIndex(indices) < 8.5,
    color: colors.darkRed
  }
]

export const LO_FEATURE: Feature<MultiPolygon> = {
  type: 'Feature',
  properties: {
    strokeColor: '#7b7b7b',
    fillColor: '#404040',
    fillOpacity: 0.1,
    strokeWeight: 3,
    border: true
  },
  geometry: {
    type: 'MultiPolygon',
    coordinates: [
      [
        [
          [28.028772557726214, 59.74923434383345],
          [28.003280451356883, 59.69500223782299],
          [28.064705406197902, 59.62191388412242],
          [28.023078999999996, 59.48335],
          [28.042750000000005, 59.47161],
          [28.043753, 59.47007400000001],
          [28.044758000000005, 59.46797600000002],
          [28.045149, 59.46711300000001],
          [28.045883, 59.46549599999997],
          [28.049833, 59.46394699999996],
          [28.054457, 59.462170000000015],
          [28.059042, 59.46188000000001],
          [28.065784, 59.461543999999975],
          [28.071878, 59.46116599999999],
          [28.07794, 59.460803999999996],
          [28.086586, 59.45866799999999],
          [28.091884999999998, 59.455749999999995],
          [28.100626, 59.450923999999986],
          [28.110678, 59.44533000000001],
          [28.119041, 59.44077300000001],
          [28.125658000000005, 59.43703500000001],
          [28.127789, 59.43451999999999],
          [28.129927000000002, 59.43179700000002],
          [28.133436, 59.427536],
          [28.135157, 59.424046000000004],
          [28.137125, 59.421143],
          [28.139050999999995, 59.419388],
          [28.141620000000003, 59.418087000000014],
          [28.147943000000005, 59.41601],
          [28.159487, 59.413062999999994],
          [28.165085000000005, 59.41158999999996],
          [28.167944, 59.40946600000001],
          [28.171093, 59.407359999999954],
          [28.175611, 59.405235000000005],
          [28.185700000000004, 59.40228300000001],
          [28.191225, 59.400645999999966],
          [28.195574, 59.397636000000006],
          [28.19779, 59.394318],
          [28.199383000000005, 59.392357000000004],
          [28.201748, 59.390502999999995],
          [28.203599999999998, 59.38883200000001],
          [28.204525, 59.387816999999984],
          [28.208569, 59.38374300000001],
          [28.209175, 59.381416],
          [28.208532, 59.38009600000001],
          [28.207571, 59.379074],
          [28.205563, 59.37803999999997],
          [28.204096, 59.37684200000004],
          [28.204006000000003, 59.376723999999996],
          [28.203968, 59.376649999999984],
          [28.202985999999996, 59.374896999999976],
          [28.203300000000002, 59.37432000000001],
          [28.205118000000002, 59.37359599999999],
          [28.208532, 59.37228999999999],
          [28.209823999999998, 59.37138699999997],
          [28.210018000000005, 59.37037000000001],
          [28.209015, 59.36933499999998],
          [28.208387, 59.368889999999965],
          [28.207563, 59.36828600000001],
          [28.20592, 59.367065],
          [28.205896, 59.367050000000006],
          [28.205618, 59.366547],
          [28.206032, 59.364586],
          [28.2058, 59.364136],
          [28.204937, 59.36394999999999],
          [28.202066000000002, 59.362803999999954],
          [28.19914, 59.36115600000002],
          [28.19918, 59.360720000000015],
          [28.199501, 59.360073],
          [28.200438000000002, 59.358306999999996],
          [28.200328999999996, 59.35751299999998],
          [28.198786, 59.35598999999999],
          [28.196724000000003, 59.35488000000001],
          [28.194999999999997, 59.353928000000025],
          [28.191039999999997, 59.352836999999994],
          [28.190733000000005, 59.35235199999997],
          [28.191905999999996, 59.347183],
          [28.189261999999996, 59.34462400000001],
          [28.18528, 59.34232700000001],
          [28.177698, 59.339020000000005],
          [28.174273, 59.337536],
          [28.169916, 59.335228],
          [28.167837, 59.33379400000001],
          [28.164312, 59.327240000000046],
          [28.157983999999995, 59.318190000000016],
          [28.155965999999996, 59.315380000000005],
          [28.153763, 59.312084],
          [28.150728, 59.308580000000006],
          [28.148375000000005, 59.30641600000004],
          [28.14606, 59.30426399999999],
          [28.141678, 59.30056800000003],
          [28.137003, 59.29704699999999],
          [28.134700000000002, 59.29415],
          [28.132677, 59.29237699999999],
          [28.129532, 59.290940000000006],
          [28.123251, 59.29068799999999],
          [28.119963, 59.29045500000004],
          [28.114595, 59.29106999999999],
          [28.108395000000005, 59.29248000000001],
          [28.099855, 59.292282],
          [28.090213999999996, 59.292225],
          [28.084526, 59.291675999999995],
          [28.078682, 59.29157599999999],
          [28.069977, 59.29112000000001],
          [28.065326999999996, 59.290610000000015],
          [28.060331, 59.29070300000001],
          [28.053716999999995, 59.290839999999974],
          [28.047428000000004, 59.29051200000001],
          [28.042024999999995, 59.289772],
          [28.03291, 59.28858600000001],
          [28.026407, 59.28748300000001],
          [28.019539999999996, 59.28569399999998],
          [28.016682000000003, 59.284114999999986],
          [28.012962, 59.28211999999999],
          [28.009799999999995, 59.280315],
          [28.0049, 59.277874],
          [27.997524, 59.276610000000005],
          [27.990029999999997, 59.275199999999984],
          [27.98292, 59.27408600000001],
          [27.977179, 59.273697],
          [27.966416999999996, 59.27371600000001],
          [27.957829, 59.271975999999995],
          [27.95419, 59.270370000000014],
          [27.949348, 59.2672],
          [27.944622000000003, 59.263885000000016],
          [27.940048, 59.262066000000004],
          [27.934322, 59.25914799999998],
          [27.930004000000004, 59.257023000000004],
          [27.92618, 59.25536700000001],
          [27.924819999999997, 59.254779999999954],
          [27.924604000000002, 59.254684],
          [27.921247, 59.252399999999994],
          [27.916563000000004, 59.249504],
          [27.913685, 59.24738999999997],
          [27.910348999999997, 59.24522999999999],
          [27.90787, 59.24342999999999],
          [27.904331, 59.241264],
          [27.901360000000004, 59.23858000000001],
          [27.900000000000002, 59.235855000000015],
          [27.899164, 59.231983000000014],
          [27.899378000000002, 59.226624000000015],
          [27.897909999999996, 59.22172499999999],
          [27.896107, 59.21793699999998],
          [27.896456, 59.217205000000035],
          [27.899338000000004, 59.21115499999999],
          [27.900425, 59.20765699999998],
          [27.899073, 59.20508000000001],
          [27.895004, 59.20009999999999],
          [27.890978, 59.19297],
          [27.889250000000004, 59.18651599999998],
          [27.887660999999998, 59.18386000000001],
          [27.885338, 59.18178600000002],
          [27.882817999999997, 59.17989299999999],
          [27.879768, 59.17743999999999],
          [27.876917000000002, 59.17569399999999],
          [27.874125000000003, 59.17371700000001],
          [27.871154999999998, 59.171516],
          [27.870555999999997, 59.170450000000045],
          [27.869690000000002, 59.168319999999966],
          [27.868816, 59.16629799999998],
          [27.867594, 59.1636],
          [27.864855, 59.160889999999995],
          [27.861968999999995, 59.15917200000001],
          [27.858675, 59.157610000000005],
          [27.857029, 59.156563000000006],
          [27.852002999999996, 59.15393399999999],
          [27.847599, 59.15144699999999],
          [27.842964, 59.148922],
          [27.841106, 59.14836500000001],
          [27.839043, 59.147944999999964],
          [27.837128000000003, 59.14732699999999],
          [27.831068, 59.144893999999994],
          [27.827267000000003, 59.14227700000001],
          [27.825204999999997, 59.14022],
          [27.819366, 59.13439600000001],
          [27.811439999999997, 59.130750000000006],
          [27.808706, 59.129729999999995],
          [27.805278999999995, 59.12264299999998],
          [27.804403000000004, 59.119797000000005],
          [27.804058, 59.116974],
          [27.803489999999996, 59.113617000000005],
          [27.803753, 59.112408000000016],
          [27.804861000000002, 59.11100999999999],
          [27.809444000000003, 59.108776000000006],
          [27.809810000000002, 59.107906000000014],
          [27.809765, 59.107215999999994],
          [27.807803999999997, 59.105343000000005],
          [27.801266000000002, 59.103549999999984],
          [27.799205999999998, 59.10201299999997],
          [27.797753999999998, 59.100562999999994],
          [27.792307, 59.095753],
          [27.789713, 59.09333799999999],
          [27.78808, 59.09120000000004],
          [27.788576, 59.088764000000026],
          [27.789584999999995, 59.08493999999999],
          [27.789004999999996, 59.08378999999999],
          [27.788914, 59.08179000000001],
          [27.790294999999997, 59.07991799999999],
          [27.79239, 59.07836499999999],
          [27.792702, 59.076885000000004],
          [27.791426, 59.074122999999986],
          [27.791486999999996, 59.07297999999997],
          [27.791800000000002, 59.071939999999984],
          [27.792152, 59.070040000000006],
          [27.790464, 59.06867],
          [27.786776, 59.06715999999997],
          [27.783312, 59.066310000000016],
          [27.78047, 59.065327000000025],
          [27.773144, 59.05839499999999],
          [27.771455999999997, 59.051147000000014],
          [27.773395999999998, 59.04920200000004],
          [27.774607, 59.046684],
          [27.774708, 59.044703999999996],
          [27.772638000000004, 59.041225],
          [27.770771, 59.037345999999985],
          [27.768082, 59.03312],
          [27.765402, 59.03237999999999],
          [27.762017999999998, 59.032129999999995],
          [27.751106, 59.03132199999999],
          [27.746277, 59.028846999999985],
          [27.74483, 59.02680599999999],
          [27.744501, 59.022891999999985],
          [27.744514, 59.01984999999999],
          [27.74452, 59.018730000000005],
          [27.742645, 59.01458699999998],
          [27.740427000000004, 59.01031499999999],
          [27.740287999999996, 59.00817999999998],
          [27.741411, 59.004520000000014],
          [27.74157, 59.004009999999994],
          [27.742073, 59.003117],
          [27.742788000000004, 58.99978999999999],
          [27.74658, 58.992233],
          [27.746687, 58.99202],
          [27.746921999999998, 58.99155400000001],
          [27.746195, 58.99079499999999],
          [27.74567, 58.98958999999999],
          [27.745317, 58.987502999999975],
          [27.745707, 58.98653999999999],
          [27.745771000000005, 58.98609999999999],
          [27.745909, 58.98515299999997],
          [27.74564, 58.98456199999998],
          [27.744164, 58.98322999999999],
          [27.74134, 58.981266000000005],
          [27.7397, 58.979945999999984],
          [27.738636000000003, 58.978455],
          [27.737763999999995, 58.97789],
          [27.748047000000003, 58.98034000000001],
          [27.749418, 58.980666999999954],
          [27.783554000000002, 58.988796000000036],
          [27.850819999999995, 59.00549299999997],
          [27.885742, 59.014556999999996],
          [27.918000000000003, 59.02116000000004],
          [27.927387, 59.01923400000001],
          [27.930346, 59.018129999999985],
          [27.92952, 59.016482999999994],
          [27.93124, 59.014556999999996],
          [27.954487, 59.01490000000004],
          [27.990717, 59.015522000000004],
          [27.992849999999997, 59.01435000000001],
          [27.999796000000003, 59.012560000000036],
          [27.997183, 59.00995],
          [27.996597000000005, 59.00423999999998],
          [28.002547999999997, 59.003690000000006],
          [28.023473999999997, 59.00279599999999],
          [28.029596000000005, 59.002039999999994],
          [28.032621, 59.00114400000001],
          [28.033997999999997, 59.000249999999994],
          [28.035116, 58.99683000000002],
          [28.036903, 58.993600000000015],
          [28.035185, 58.99015800000001],
          [28.033810000000003, 58.987064000000004],
          [28.0361, 58.98687000000001],
          [28.046395999999998, 58.98692700000001],
          [28.046680000000002, 58.991389999999996],
          [28.04692, 58.99135000000001],
          [28.047283, 58.991289999999964],
          [28.047572999999996, 58.99126000000001],
          [28.048021000000002, 58.99119999999999],
          [28.048338000000005, 58.991157999999984],
          [28.048509999999997, 58.991173],
          [28.048819, 58.99123800000001],
          [28.049064999999995, 58.99132500000002],
          [28.049322, 58.99146999999999],
          [28.049505, 58.991585000000015],
          [28.049725, 58.99168399999999],
          [28.050009, 58.991802000000035],
          [28.050313999999997, 58.99188000000001],
          [28.050472, 58.99191699999997],
          [28.050816, 58.99195499999999],
          [28.050983, 58.99194299999999],
          [28.051092, 58.991885999999994],
          [28.051172000000005, 58.991817],
          [28.051416000000003, 58.99170000000001],
          [28.05164, 58.991634000000005],
          [28.051763999999995, 58.99159199999997],
          [28.052074, 58.991623000000004],
          [28.052185, 58.99168800000001],
          [28.052242000000003, 58.99180999999999],
          [28.052397, 58.99197800000002],
          [28.0525, 58.99202299999999],
          [28.05294, 58.99213800000001],
          [28.053150000000002, 58.992153],
          [28.053345, 58.99211500000001],
          [28.053611999999998, 58.992053999999996],
          [28.053753, 58.992009999999965],
          [28.053990000000002, 58.99198000000001],
          [28.054361, 58.991905],
          [28.054516, 58.99186300000002],
          [28.054813, 58.99180999999999],
          [28.05506, 58.99178699999999],
          [28.055386999999996, 58.99171000000001],
          [28.055489, 58.991726],
          [28.055590000000002, 58.991832999999986],
          [28.055582, 58.99192400000001],
          [28.05546, 58.99213],
          [28.055493999999996, 58.99222600000002],
          [28.055635, 58.99234000000001],
          [28.055803, 58.99239],
          [28.05617, 58.99249600000002],
          [28.056477, 58.992549999999994],
          [28.056691999999998, 58.99261000000001],
          [28.0568, 58.992583999999994],
          [28.05686, 58.99251000000004],
          [28.056919999999998, 58.99234799999999],
          [28.057035, 58.99220699999998],
          [28.057203, 58.99214599999999],
          [28.057362, 58.99215000000001],
          [28.057475999999998, 58.99220699999998],
          [28.057547, 58.992305999999985],
          [28.057607999999995, 58.99236300000001],
          [28.057753, 58.992435],
          [28.058015999999995, 58.99250799999999],
          [28.058192999999996, 58.992553999999984],
          [28.058353, 58.99257299999999],
          [28.058771, 58.99257],
          [28.059155, 58.99258800000001],
          [28.059589999999996, 58.992650000000026],
          [28.059893, 58.99270600000003],
          [28.060242000000002, 58.992850000000004],
          [28.060396000000004, 58.992880000000014],
          [28.060552999999995, 58.992880000000014],
          [28.06071, 58.99284],
          [28.060911000000004, 58.992755999999986],
          [28.061255, 58.99270200000001],
          [28.061445, 58.99264500000004],
          [28.061567, 58.992549999999994],
          [28.06161, 58.992435],
          [28.061759999999996, 58.99236700000003],
          [28.061941000000004, 58.992344],
          [28.062099999999997, 58.992369999999994],
          [28.062316999999997, 58.99249600000002],
          [28.062428000000004, 58.99257699999998],
          [28.062491999999995, 58.99270000000001],
          [28.062607, 58.99272500000001],
          [28.062843, 58.99270000000001],
          [28.063015, 58.99268000000001],
          [28.063175000000005, 58.99267600000002],
          [28.063373999999996, 58.99271400000001],
          [28.063515, 58.99271999999999],
          [28.06365, 58.992759999999976],
          [28.063752999999995, 58.99274],
          [28.06379, 58.992717999999996],
          [28.06379, 58.992626],
          [28.063812, 58.992535000000004],
          [28.063925, 58.99244999999999],
          [28.06402, 58.99241000000001],
          [28.064209, 58.99239],
          [28.064611000000003, 58.99243000000001],
          [28.064727999999995, 58.99242800000002],
          [28.064868999999998, 58.992335999999995],
          [28.065083, 58.99226999999999],
          [28.065210000000004, 58.99227500000001],
          [28.065302000000003, 58.992369999999994],
          [28.065409999999996, 58.992435],
          [28.065634, 58.99247],
          [28.065836, 58.99247700000001],
          [28.066016999999995, 58.99244999999999],
          [28.06608, 58.99239],
          [28.066034000000002, 58.992301999999995],
          [28.065771, 58.992245],
          [28.065674, 58.99221399999999],
          [28.065681, 58.99213399999999],
          [28.065844000000002, 58.992042999999995],
          [28.066154, 58.99186300000002],
          [28.066319999999997, 58.991832999999986],
          [28.06647, 58.991794999999996],
          [28.06668, 58.99162000000001],
          [28.066908, 58.99149700000001],
          [28.067133, 58.991386000000006],
          [28.067383, 58.991382999999985],
          [28.067589, 58.9914],
          [28.067678000000004, 58.991479999999996],
          [28.06776, 58.99157999999997],
          [28.067996999999995, 58.991626999999994],
          [28.068235000000005, 58.991659999999996],
          [28.068433999999996, 58.99165299999996],
          [28.068592, 58.99171999999996],
          [28.068748000000003, 58.99181399999998],
          [28.068842000000004, 58.99191300000004],
          [28.068961999999996, 58.991929999999996],
          [28.069172, 58.991894],
          [28.069290000000002, 58.991832999999986],
          [28.069298, 58.99174499999998],
          [28.069048, 58.991547],
          [28.0689, 58.991444],
          [28.068886, 58.991344],
          [28.068941, 58.99127200000001],
          [28.069101000000003, 58.99125299999997],
          [28.06935, 58.99124499999999],
          [28.069536, 58.99124499999999],
          [28.069689, 58.99117699999999],
          [28.06988, 58.991173],
          [28.070090999999998, 58.991209999999995],
          [28.070467, 58.99126000000001],
          [28.070890000000002, 58.991289999999964],
          [28.071165, 58.991287],
          [28.071781, 58.99131399999996],
          [28.07197, 58.991287],
          [28.072189999999996, 58.99123800000001],
          [28.072260000000004, 58.99110000000002],
          [28.072339999999997, 58.99101000000002],
          [28.072504000000002, 58.990948],
          [28.072761999999997, 58.99092999999999],
          [28.073092, 58.990962999999965],
          [28.073317000000003, 58.99099699999999],
          [28.073673, 58.99103500000001],
          [28.073930000000004, 58.991119999999995],
          [28.074017, 58.99122],
          [28.073979999999995, 58.99130199999996],
          [28.07388, 58.991359999999986],
          [28.073735999999997, 58.991424999999964],
          [28.073772, 58.99146999999999],
          [28.073900000000002, 58.99150499999999],
          [28.074076, 58.991547],
          [28.074247, 58.99168399999999],
          [28.074481999999996, 58.99193199999999],
          [28.074713000000003, 58.99212],
          [28.074849999999998, 58.99225999999999],
          [28.074963, 58.992335999999995],
          [28.075125, 58.99235500000003],
          [28.075245, 58.992317000000014],
          [28.075226, 58.99221800000001],
          [28.075142, 58.99210399999998],
          [28.075241000000002, 58.99202],
          [28.075410000000005, 58.99198999999996],
          [28.075642, 58.99202],
          [28.07595, 58.99207000000001],
          [28.076204, 58.992065],
          [28.076426000000005, 58.992096000000004],
          [28.076586000000002, 58.992140000000006],
          [28.076826, 58.99212],
          [28.077297, 58.992065],
          [28.077637, 58.992016000000035],
          [28.080544999999997, 58.99188000000001],
          [28.083227, 58.99345999999997],
          [28.08529, 58.99587],
          [28.089487, 58.99738300000001],
          [28.091825, 58.99861999999999],
          [28.093287, 59.000065000000035],
          [28.096588, 59.00095700000003],
          [28.099958000000004, 59.000065000000035],
          [28.101265, 58.99352999999999],
          [28.111993999999996, 58.99304999999998],
          [28.120901, 58.99265299999999],
          [28.121245999999996, 58.97484],
          [28.13624, 58.977450000000005],
          [28.144217999999995, 58.97841600000001],
          [28.148346000000004, 58.97263699999999],
          [28.155930000000005, 58.973119999999994],
          [28.164388999999996, 58.97456399999999],
          [28.17161, 58.97649000000001],
          [28.174018999999998, 58.971333000000016],
          [28.16948, 58.97064599999999],
          [28.146439, 58.96356],
          [28.18745, 58.94117399999999],
          [28.189066, 58.94174000000001],
          [28.192368000000002, 58.94222300000001],
          [28.199795, 58.942497],
          [28.202547, 58.94188],
          [28.204266000000004, 58.94070999999997],
          [28.204060000000002, 58.93968000000001],
          [28.202135, 58.938987999999995],
          [28.19842, 58.93864400000001],
          [28.196838, 58.93802600000001],
          [28.196012, 58.93678700000001],
          [28.200002999999995, 58.93478999999999],
          [28.202272000000004, 58.93478999999999],
          [28.205160000000003, 58.93115000000003],
          [28.210388, 58.92605999999998],
          [28.212383, 58.924544999999995],
          [28.209839, 58.92340999999999],
          [28.206537, 58.922860000000014],
          [28.206811999999996, 58.92099999999999],
          [28.214928, 58.920383000000015],
          [28.217955, 58.913917999999995],
          [28.221188, 58.913850000000025],
          [28.222357, 58.91329999999999],
          [28.222494000000005, 58.912270000000035],
          [28.219675, 58.90993],
          [28.21603, 58.911922000000004],
          [28.214515999999996, 58.911167000000006],
          [28.212729, 58.90903500000002],
          [28.211834, 58.90642],
          [28.216873, 58.90231299999999],
          [28.222376, 58.89763599999998],
          [28.225609000000002, 58.895640000000014],
          [28.228703000000003, 58.895092000000005],
          [28.234411, 58.895092000000005],
          [28.244694000000003, 58.89614],
          [28.257968999999996, 58.898617],
          [28.257143000000003, 58.89648399999999],
          [28.257488, 58.894558000000046],
          [28.258863, 58.89297500000001],
          [28.26244, 58.89090999999999],
          [28.264984, 58.88995],
          [28.266085, 58.888435000000015],
          [28.266293, 58.88541000000001],
          [28.256594, 58.88196999999997],
          [28.244833, 58.88066499999999],
          [28.239743999999998, 58.87983700000001],
          [28.243457999999997, 58.87468000000001],
          [28.260927000000002, 58.874750000000006],
          [28.266035, 58.86860999999996],
          [28.273327, 58.85987499999999],
          [28.277591999999995, 58.85251600000001],
          [28.284538, 58.840137],
          [28.287634000000004, 58.84007],
          [28.288889, 58.836783999999994],
          [28.289783, 58.83540700000003],
          [28.29061, 58.83272600000004],
          [28.293222000000004, 58.83073000000002],
          [28.294323, 58.82619],
          [28.300308, 58.82391999999999],
          [28.311450000000004, 58.83080000000001],
          [28.311311999999997, 58.840565],
          [28.344894000000004, 58.82495499999999],
          [28.349262000000003, 58.827156],
          [28.341173, 58.83091999999999],
          [28.340251999999996, 58.83135200000001],
          [28.335024, 58.835544999999996],
          [28.329176000000004, 58.84063699999999],
          [28.326287999999998, 58.844143],
          [28.321128999999996, 58.84441799999999],
          [28.32126, 58.84466599999999],
          [28.324705, 58.85115999999999],
          [28.3441, 58.85405],
          [28.345133, 58.859825],
          [28.348778, 58.86243999999999],
          [28.358889, 58.86141000000001],
          [28.363428, 58.86716999999999],
          [28.358543, 58.869643999999994],
          [28.373537000000002, 58.87844999999999],
          [28.378902, 58.874390000000005],
          [28.386744, 58.873222],
          [28.390734000000002, 58.87170800000001],
          [28.388550000000002, 58.86785499999999],
          [28.411401999999995, 58.855132999999995],
          [28.413740000000004, 58.855199999999996],
          [28.415136000000004, 58.85579000000001],
          [28.41099, 58.857609999999994],
          [28.412365, 58.860839999999996],
          [28.414152, 58.862149999999986],
          [28.416628, 58.86469299999999],
          [28.418210999999996, 58.86593199999999],
          [28.420412, 58.86661999999998],
          [28.422129999999996, 58.866825000000006],
          [28.424125999999998, 58.868202],
          [28.424125999999998, 58.87116],
          [28.425192, 58.87189999999998],
          [28.429731, 58.87279000000001],
          [28.434546, 58.87450999999999],
          [28.434958, 58.8785],
          [28.436470000000003, 58.881046],
          [28.439428, 58.87953000000002],
          [28.44335, 58.879256999999996],
          [28.44658, 58.878296000000006],
          [28.448095, 58.87760500000002],
          [28.450228, 58.87760500000002],
          [28.452084, 58.878708000000046],
          [28.454559999999997, 58.87891400000001],
          [28.45931, 58.878376],
          [28.466224999999998, 58.87552299999999],
          [28.476296999999995, 58.872142999999994],
          [28.477178999999996, 58.87015500000004],
          [28.482481, 58.86852999999999],
          [28.486301, 58.86867000000001],
          [28.490877, 58.86531400000001],
          [28.491338999999996, 58.863358000000005],
          [28.49334, 58.862415],
          [28.501359999999995, 58.86230499999999],
          [28.501389999999997, 58.86230499999999],
          [28.501781, 58.86230499999999],
          [28.510767000000005, 58.86726999999999],
          [28.515456999999998, 58.86525299999997],
          [28.522867, 58.867580000000004],
          [28.524857000000004, 58.866659999999996],
          [28.529530999999995, 58.86166800000001],
          [28.534483, 58.86077499999999],
          [28.536410000000004, 58.862840000000006],
          [28.541979999999995, 58.86414300000004],
          [28.538954, 58.866344],
          [28.539433999999996, 58.867447],
          [28.540949, 58.86786000000001],
          [28.552761, 58.86627600000003],
          [28.556062999999998, 58.867515999999966],
          [28.55737, 58.86614],
          [28.563630000000003, 58.868683000000004],
          [28.568237, 58.86586399999999],
          [28.574978, 58.86710399999998],
          [28.576765, 58.86717200000001],
          [28.579792, 58.866623000000004],
          [28.586945000000004, 58.86641700000001],
          [28.588182, 58.864146999999974],
          [28.58667, 58.86269999999999],
          [28.589560000000002, 58.86036300000001],
          [28.599876, 58.86497],
          [28.59685, 58.86724000000001],
          [28.597881, 58.868202],
          [28.611740000000005, 58.86655400000001],
          [28.621988, 58.86455999999998],
          [28.621850999999996, 58.86263300000002],
          [28.627833999999996, 58.861603],
          [28.63382, 58.86036300000001],
          [28.636363999999997, 58.85507000000001],
          [28.640629, 58.855343000000005],
          [28.644789000000003, 58.85231799999997],
          [28.656414000000005, 58.85561799999999],
          [28.659233000000004, 58.865868000000006],
          [28.662395, 58.86628000000002],
          [28.667486, 58.86641700000001],
          [28.669412999999995, 58.866141999999996],
          [28.671337, 58.863940000000014],
          [28.668175, 58.859399999999994],
          [28.668175, 58.856030000000004],
          [28.661297000000005, 58.851901999999995],
          [28.663498, 58.85101],
          [28.666661999999995, 58.85231799999997],
          [28.720412999999997, 58.83802800000001],
          [28.742407, 58.83199299999998],
          [28.745914, 58.830273000000034],
          [28.746946, 58.82827800000001],
          [28.765793000000002, 58.824839999999995],
          [28.769438, 58.82676699999996],
          [28.766684999999995, 58.827934],
          [28.765242, 58.830273000000034],
          [28.767854999999997, 58.831305999999984],
          [28.772045000000002, 58.83006299999997],
          [28.775902, 58.829586000000006],
          [28.779755, 58.82814399999998],
          [28.78254, 58.829655],
          [28.797327000000003, 58.825942999999995],
          [28.800148000000004, 58.82738499999999],
          [28.843307, 58.825255999999996],
          [28.888187, 58.81660499999998],
          [28.909698, 58.803004999999985],
          [28.973492000000004, 58.805138],
          [28.984220000000004, 58.81662399999999],
          [29.016857, 58.82054499999998],
          [29.0161, 58.828250000000025],
          [29.028479, 58.832977],
          [29.033775000000002, 58.83276999999998],
          [29.03553, 58.82630499999996],
          [29.043922, 58.79317],
          [29.047225999999995, 58.78056699999999],
          [29.048325999999996, 58.77190000000002],
          [29.049015, 58.763992],
          [29.055895000000003, 58.7398],
          [29.068979999999996, 58.740436999999986],
          [29.073656, 58.73637800000003],
          [29.069942, 58.73479499999999],
          [29.062033, 58.73176999999998],
          [29.054743, 58.72894999999997],
          [29.049377, 58.72867200000002],
          [29.04422, 58.7295],
          [29.034314999999996, 58.726887000000005],
          [29.035965, 58.725303999999994],
          [29.05825, 58.72537200000002],
          [29.068016, 58.731150000000014],
          [29.070906, 58.732113],
          [29.085917, 58.73164700000001],
          [29.087912, 58.73013700000001],
          [29.088806, 58.72869],
          [29.092039999999997, 58.72820999999996],
          [29.091764, 58.725048000000044],
          [29.092934000000003, 58.72339600000001],
          [29.096441, 58.721333000000016],
          [29.098494000000002, 58.72083000000001],
          [29.101187, 58.721194999999994],
          [29.104487999999996, 58.72015999999999],
          [29.107515000000003, 58.71899400000001],
          [29.123764000000005, 58.71775400000001],
          [29.124727000000004, 58.71576300000004],
          [29.123798, 58.71510699999999],
          [29.12531, 58.713869999999986],
          [29.124003999999996, 58.71208200000001],
          [29.126482, 58.71091000000001],
          [29.126824999999997, 58.708920000000035],
          [29.129921000000003, 58.70636999999999],
          [29.137622999999998, 58.70658],
          [29.139275, 58.70534000000001],
          [29.133428999999996, 58.70306999999997],
          [29.139826, 58.70039],
          [29.152823999999995, 58.700733000000014],
          [29.156607, 58.705546999999996],
          [29.16701, 58.70541],
          [29.170105000000003, 58.70382999999998],
          [29.174301, 58.704655],
          [29.180971, 58.70994999999999],
          [29.198116, 58.70760999999999],
          [29.202724000000003, 58.704380000000015],
          [29.201555000000003, 58.70217999999997],
          [29.217255, 58.69963500000003],
          [29.224268, 58.71056999999999],
          [29.234827, 58.70176699999996],
          [29.249477, 58.70555000000002],
          [29.256766999999996, 58.70011499999998],
          [29.268427, 58.69848300000001],
          [29.271383, 58.701233],
          [29.278399, 58.70102700000001],
          [29.283282999999997, 58.696144000000004],
          [29.268015, 58.68481400000002],
          [29.261825999999996, 58.67513300000002],
          [29.257286000000004, 58.669764999999956],
          [29.265608, 58.666054],
          [29.264920999999998, 58.66288800000001],
          [29.269186, 58.65732],
          [29.272762000000004, 58.65697499999999],
          [29.275307, 58.65765999999999],
          [29.282460000000004, 58.65380999999999],
          [29.281979, 58.65147000000002],
          [29.291676999999996, 58.648685],
          [29.290165, 58.64745000000002],
          [29.281979, 58.646004000000005],
          [29.276958, 58.64442],
          [29.266709999999996, 58.639606000000015],
          [29.267948000000004, 58.634518000000014],
          [29.256393, 58.63458600000001],
          [29.248534999999997, 58.631440000000026],
          [29.245715999999998, 58.631849999999986],
          [29.243652, 58.632885000000016],
          [29.230928, 58.63260700000001],
          [29.231134, 58.626487999999995],
          [29.243034000000005, 58.626487999999995],
          [29.248192, 58.62105],
          [29.247849, 58.61166399999999],
          [29.251839, 58.61049700000001],
          [29.255277999999997, 58.59990299999998],
          [29.266146, 58.60464999999999],
          [29.264908, 58.59990299999998],
          [29.264288, 58.59467699999999],
          [29.27385, 58.597633],
          [29.282308999999998, 58.602036],
          [29.282446000000004, 58.60347999999999],
          [29.288292, 58.606712000000016],
          [29.288567, 58.611526],
          [29.292677, 58.61201],
          [29.302237, 58.61139],
          [29.312761, 58.60664400000002],
          [29.316612, 58.60705999999999],
          [29.318813, 58.60981000000001],
          [29.312552999999998, 58.61056500000001],
          [29.313105, 58.61372800000004],
          [29.322458, 58.61358999999999],
          [29.330917000000003, 58.61283500000002],
          [29.337382999999996, 58.60994699999998],
          [29.341854, 58.609054999999984],
          [29.343506000000005, 58.609466999999995],
          [29.342955, 58.611323999999996],
          [29.340548, 58.613110000000006],
          [29.342817, 58.61386999999999],
          [29.347803, 58.611874],
          [29.350417999999998, 58.6138],
          [29.354612, 58.61614],
          [29.356813, 58.61345699999998],
          [29.351242, 58.609260000000006],
          [29.352413, 58.606922000000026],
          [29.373252999999995, 58.601833],
          [29.384051999999997, 58.598736],
          [29.388332, 58.59861799999999],
          [29.390327, 58.59586300000001],
          [29.396587000000004, 58.594764999999995],
          [29.403052999999996, 58.59194600000001],
          [29.400437999999998, 58.589676000000026],
          [29.39473, 58.591323999999986],
          [29.392459999999996, 58.59139299999998],
          [29.390673000000003, 58.59070600000001],
          [29.391636, 58.58871000000002],
          [29.386477, 58.58555000000001],
          [29.379461, 58.58506800000001],
          [29.378016999999996, 58.586715999999996],
          [29.370932, 58.589053999999976],
          [29.367012, 58.586989999999986],
          [29.3677, 58.58368999999999],
          [29.383589, 58.579837999999995],
          [29.37341, 58.568591999999995],
          [29.37169, 58.57038],
          [29.366668999999998, 58.56989999999999],
          [29.36949, 58.57423399999999],
          [29.359653, 58.57849999999999],
          [29.362404, 58.580629999999985],
          [29.358276, 58.58186699999996],
          [29.344642999999998, 58.57760200000004],
          [29.34189, 58.57760200000004],
          [29.337693999999995, 58.57966600000003],
          [29.331917, 58.579049999999995],
          [29.328600000000005, 58.576088],
          [29.322751999999998, 58.57698400000001],
          [29.319933, 58.57670999999999],
          [29.318352, 58.576224999999994],
          [29.318283, 58.57519500000001],
          [29.318831999999997, 58.574028],
          [29.32523, 58.57312999999996],
          [29.332175999999997, 58.571342000000016],
          [29.329563, 58.56879799999999],
          [29.323235, 58.57058699999999],
          [29.317389, 58.5677],
          [29.327500000000004, 58.56020000000001],
          [29.344380999999995, 58.55772000000002],
          [29.35558, 58.55607600000002],
          [29.351658, 58.572925999999995],
          [29.354821999999995, 58.573406000000006],
          [29.358124000000004, 58.565292],
          [29.361288, 58.56501800000004],
          [29.362114, 58.56302299999999],
          [29.359087, 58.56254200000001],
          [29.359705, 58.560272],
          [29.362389, 58.560753000000005],
          [29.363214, 58.55855],
          [29.367271, 58.558482999999995],
          [29.368097, 58.55587],
          [29.369747000000004, 58.55442399999998],
          [29.373257000000002, 58.55408],
          [29.376350000000002, 58.55319],
          [29.393839, 58.55449300000001],
          [29.398481, 58.56571199999999],
          [29.399874, 58.56907699999999],
          [29.40161, 58.57327300000003],
          [29.400509, 58.574510000000004],
          [29.406303, 58.57925399999999],
          [29.411943, 58.582490000000035],
          [29.423152999999996, 58.590329999999994],
          [29.428415, 58.587784],
          [29.434192999999997, 58.58345],
          [29.438457, 58.58179999999999],
          [29.440864999999995, 58.579184999999995],
          [29.449599999999997, 58.577950000000016],
          [29.45297, 58.57375300000004],
          [29.466194, 58.570659999999975],
          [29.470459, 58.567837],
          [29.47204, 58.56687500000001],
          [29.475135999999996, 58.567425000000014],
          [29.47885, 58.567977999999954],
          [29.481617, 58.567170000000004],
          [29.480793000000002, 58.56593000000001],
          [29.481688000000002, 58.56400300000004],
          [29.478867, 58.56263000000001],
          [29.478729999999995, 58.56152700000001],
          [29.479761, 58.56008499999999],
          [29.481274, 58.558914000000016],
          [29.481307999999995, 58.556454],
          [29.483302999999996, 58.55418800000001],
          [29.48805, 58.55143400000003],
          [29.490595, 58.54923200000002],
          [29.500223, 58.54868299999998],
          [29.510885, 58.541393],
          [29.512259999999998, 58.542422999999985],
          [29.523951999999998, 58.54180500000001],
          [29.527737, 58.54290799999998],
          [29.54041, 58.54236000000003],
          [29.54309, 58.54400599999997],
          [29.556847, 58.540156999999965],
          [29.560373, 58.53719699999999],
          [29.565804999999997, 58.536099999999976],
          [29.575573, 58.53355400000001],
          [29.579563, 58.53355400000001],
          [29.581505, 58.531025],
          [29.583982, 58.52634999999998],
          [29.584124, 58.523075000000006],
          [29.57346, 58.52001999999999],
          [29.575797999999995, 58.51747499999999],
          [29.551156999999996, 58.508760000000024],
          [29.552603, 58.50576399999997],
          [29.552876999999995, 58.50191499999997],
          [29.550333, 58.499233000000004],
          [29.545107, 58.49661600000002],
          [29.541253999999995, 58.494552999999996],
          [29.539742, 58.493042],
          [29.542631, 58.48747],
          [29.545795, 58.48383999999999],
          [29.551022000000003, 58.47978599999999],
          [29.556867999999998, 58.478751999999986],
          [29.562096, 58.47737999999998],
          [29.568424000000004, 58.475178],
          [29.582797999999997, 58.47552000000002],
          [29.585742999999997, 58.473907000000025],
          [29.59542, 58.46861000000001],
          [29.589711999999995, 58.468197],
          [29.59714, 58.45821999999998],
          [29.605257, 58.46063000000001],
          [29.607938999999995, 58.45994000000002],
          [29.612204, 58.45196500000003],
          [29.625908, 58.45237700000001],
          [29.628332, 58.451828000000006],
          [29.639475, 58.446939999999955],
          [29.650274000000003, 58.44811200000001],
          [29.652336, 58.44666699999999],
          [29.658407, 58.448868000000004],
          [29.665284999999997, 58.44701399999997],
          [29.665422000000003, 58.44625500000001],
          [29.670580000000005, 58.44618600000001],
          [29.673744, 58.44274999999999],
          [29.681036, 58.44715000000002],
          [29.67849, 58.45155299999999],
          [29.690699999999996, 58.45971700000004],
          [29.699226, 58.460679999999996],
          [29.708015, 58.45926299999999],
          [29.708857, 58.457603000000034],
          [29.711912, 58.44859700000001],
          [29.713253, 58.44441599999999],
          [29.71519, 58.443813000000034],
          [29.723297, 58.442432],
          [29.725409000000003, 58.44069999999999],
          [29.734842, 58.439503],
          [29.736307, 58.440364999999986],
          [29.738136, 58.440650000000005],
          [29.742771000000005, 58.440394999999995],
          [29.747161999999996, 58.43876599999999],
          [29.747345, 58.437107],
          [29.747814, 58.43697999999998],
          [29.748074, 58.43691000000001],
          [29.751308, 58.436024],
          [29.752831999999998, 58.434776],
          [29.752771, 58.434235],
          [29.746552, 58.43414000000001],
          [29.742466, 58.429959999999966],
          [29.746795999999996, 58.42733799999999],
          [29.747345, 58.42558300000002],
          [29.747406, 58.42366799999999],
          [29.752466000000002, 58.42293000000001],
          [29.759724, 58.42437000000001],
          [29.762040999999996, 58.424274],
          [29.762468, 58.423411999999985],
          [29.760700000000003, 58.42181399999998],
          [29.759298, 58.42114000000001],
          [29.777689999999996, 58.417336000000006],
          [29.786442000000005, 58.421189999999996],
          [29.807661, 58.42244299999999],
          [29.865537999999997, 58.427257999999995],
          [29.86008, 58.433580000000006],
          [29.850791999999995, 58.433715999999976],
          [29.8513, 58.439423000000005],
          [29.863557999999998, 58.43822499999999],
          [29.866491, 58.44731999999999],
          [29.879424999999998, 58.45170999999999],
          [29.883504999999996, 58.455985999999996],
          [29.895706, 58.449990000000014],
          [29.906635000000005, 58.45306399999998],
          [29.918958999999997, 58.44823500000001],
          [29.936400999999996, 58.44294400000001],
          [29.939358000000002, 58.44517999999999],
          [29.95473, 58.44254999999998],
          [29.955173, 58.43869000000001],
          [29.959932000000002, 58.43651],
          [29.980965, 58.43744000000001],
          [30.001296999999997, 58.44422500000002],
          [30.008223999999995, 58.447227],
          [30.02113, 58.44797],
          [30.028555, 58.44497000000001],
          [30.038982, 58.442139999999995],
          [30.050764000000004, 58.44331],
          [30.052519, 58.44382500000003],
          [30.056469999999997, 58.44389699999999],
          [30.062874000000004, 58.44991699999997],
          [30.063934, 58.45450600000001],
          [30.060421000000005, 58.45648200000002],
          [30.063494000000002, 58.45926299999999],
          [30.065322999999996, 58.46204399999999],
          [30.060787000000005, 58.463142000000005],
          [30.062212, 58.474007],
          [30.072823, 58.477592000000016],
          [30.080725, 58.47905700000001],
          [30.091412, 58.47946999999999],
          [30.097775, 58.479713000000004],
          [30.09953, 58.48352],
          [30.104286, 58.48418000000001],
          [30.108894, 58.489300000000014],
          [30.116798, 58.488789999999995],
          [30.120310000000003, 58.48981499999999],
          [30.12353, 58.488056],
          [30.132894999999998, 58.49200999999999],
          [30.099455, 58.501319999999964],
          [30.099163, 58.502635999999995],
          [30.093381999999995, 58.50271000000001],
          [30.096674, 58.506880000000024],
          [30.100844999999996, 58.51032000000001],
          [30.097113, 58.511562],
          [30.099746999999997, 58.514489999999995],
          [30.110458, 58.515804],
          [30.116474, 58.521754999999985],
          [30.128912, 58.52739700000001],
          [30.14116, 58.528167999999994],
          [30.150503, 58.525702999999965],
          [30.154448, 58.52499799999998],
          [30.167986, 58.53142500000001],
          [30.16648, 58.53234499999999],
          [30.163918, 58.53565600000002],
          [30.158722000000004, 58.53975299999999],
          [30.153526, 58.542023],
          [30.153087999999997, 58.54487599999999],
          [30.141525000000005, 58.55358000000004],
          [30.136403999999995, 58.554096000000015],
          [30.135525, 58.55519000000001],
          [30.136330000000005, 58.557827],
          [30.12616, 58.56280000000001],
          [30.122354999999995, 58.563680000000005],
          [30.119792999999998, 58.565803999999986],
          [30.12067, 58.568141999999995],
          [30.114195, 58.57848000000001],
          [30.111853, 58.580017000000026],
          [30.109804, 58.580746000000005],
          [30.107095999999995, 58.58681999999999],
          [30.105122000000005, 58.58923300000001],
          [30.106290000000005, 58.591724],
          [30.104828, 58.595305999999965],
          [30.102049, 58.599537],
          [30.097584, 58.606503000000004],
          [30.097803, 58.60804000000002],
          [30.093119000000005, 58.609722000000005],
          [30.089607000000004, 58.61330800000002],
          [30.084291, 58.614104999999995],
          [30.085901000000003, 58.615790000000004],
          [30.08261, 58.61623],
          [30.08261, 58.61813000000001],
          [30.081009000000005, 58.618595],
          [30.078667, 58.61808400000001],
          [30.077422999999996, 58.618300000000005],
          [30.076017, 58.62019699999999],
          [30.076676999999997, 58.62137000000001],
          [30.07275, 58.624877999999995],
          [30.072529000000003, 58.62832],
          [30.093813, 58.631035],
          [30.108707, 58.63320999999999],
          [30.117224, 58.63546400000004],
          [30.116920000000004, 58.637493000000006],
          [30.113389999999995, 58.64035799999999],
          [30.104925, 58.639816000000025],
          [30.100029, 58.64174299999999],
          [30.102796999999995, 58.64335000000003],
          [30.103523, 58.644035],
          [30.105438, 58.644589999999994],
          [30.107325, 58.646090000000015],
          [30.108427000000002, 58.644187999999986],
          [30.120218000000005, 58.64523299999999],
          [30.1252, 58.64686000000003],
          [30.099121, 58.65702999999999],
          [30.095009, 58.65915999999996],
          [30.090032999999995, 58.659797999999995],
          [30.087070000000004, 58.65969000000001],
          [30.06853, 58.66067000000001],
          [30.060478, 58.66138000000001],
          [30.060740999999997, 58.663917999999995],
          [30.068058, 58.66369999999998],
          [30.068773000000004, 58.666945999999996],
          [30.067638, 58.672030000000035],
          [30.068056, 58.67233700000003],
          [30.070479999999996, 58.672470000000004],
          [30.070608, 58.67283599999999],
          [30.072838, 58.6729],
          [30.080392999999997, 58.672943000000004],
          [30.080885, 58.67290500000004],
          [30.088567999999995, 58.67302999999998],
          [30.098673, 58.67295999999999],
          [30.10742, 58.676440000000014],
          [30.111479000000003, 58.67558],
          [30.111025, 58.677998],
          [30.114557, 58.67848000000001],
          [30.118767, 58.682357999999994],
          [30.124283, 58.68276000000003],
          [30.132706000000002, 58.68728999999999],
          [30.137798, 58.689606],
          [30.148972, 58.69394299999999],
          [30.144129, 58.697475],
          [30.140503000000002, 58.696380000000005],
          [30.136837, 58.698555],
          [30.141231999999995, 58.70025599999997],
          [30.132025000000002, 58.70058399999999],
          [30.131166, 58.70015000000001],
          [30.125818, 58.70017200000001],
          [30.122100000000003, 58.70054999999999],
          [30.119524, 58.70227],
          [30.1179, 58.70568],
          [30.122170000000004, 58.707960000000014],
          [30.119238000000003, 58.709232000000014],
          [30.111336, 58.70871700000001],
          [30.102909999999998, 58.72435999999999],
          [30.107323, 58.72811500000003],
          [30.112534, 58.72887399999999],
          [30.116488, 58.73114000000001],
          [30.117287000000005, 58.728565],
          [30.121103, 58.724728],
          [30.129093, 58.72283999999999],
          [30.134387999999998, 58.72371000000001],
          [30.131369, 58.732765],
          [30.12448, 58.735614999999996],
          [30.134423999999996, 58.73809399999999],
          [30.132904, 58.746677000000005],
          [30.135006, 58.74909600000001],
          [30.146166, 58.748249999999985],
          [30.15475, 58.74738300000001],
          [30.155685, 58.746005999999994],
          [30.169000000000004, 58.746129999999994],
          [30.172499, 58.74696399999999],
          [30.17203, 58.74759700000001],
          [30.183303999999996, 58.75438],
          [30.188267, 58.75990999999999],
          [30.193127000000004, 58.75961699999996],
          [30.202637, 58.75198399999999],
          [30.199333, 58.75137000000001],
          [30.199465, 58.74884399999999],
          [30.200783, 58.74884399999999],
          [30.20305, 58.747889999999984],
          [30.205173, 58.748549999999966],
          [30.205318000000002, 58.749206999999984],
          [30.204149999999995, 58.74950000000004],
          [30.203856, 58.75045399999999],
          [30.20488, 58.75081599999996],
          [30.206270000000004, 58.750670000000014],
          [30.206928, 58.75023300000001],
          [30.208245999999995, 58.750305],
          [30.21066, 58.754111999999964],
          [30.217850000000002, 58.75735],
          [30.224795999999998, 58.76083],
          [30.23108, 58.75968],
          [30.229510999999995, 58.757071999999994],
          [30.226892, 58.749695],
          [30.225324999999998, 58.743476999999956],
          [30.229868000000003, 58.74362999999997],
          [30.246498, 58.744410000000016],
          [30.247477, 58.744213],
          [30.279258999999996, 58.75018299999999],
          [30.285328, 58.74842000000001],
          [30.28727, 58.75024400000001],
          [30.289698, 58.749233000000004],
          [30.289362, 58.747401999999994],
          [30.295563, 58.74486999999999],
          [30.295553000000005, 58.73960499999998],
          [30.302304999999997, 58.740005000000025],
          [30.30536, 58.74098000000001],
          [30.307228000000002, 58.74225999999999],
          [30.308746000000003, 58.74235500000003],
          [30.310410999999995, 58.743187000000006],
          [30.315098, 58.744545000000016],
          [30.318108, 58.74618000000001],
          [30.322699, 58.745056000000005],
          [30.325967999999996, 58.74519299999997],
          [30.327116, 58.75429],
          [30.328, 58.75461999999999],
          [30.3407, 58.75932],
          [30.342640000000003, 58.75937999999999],
          [30.348862, 58.752902999999975],
          [30.348969, 58.74744799999999],
          [30.363682, 58.749570000000006],
          [30.364477000000004, 58.748107999999974],
          [30.372389, 58.74626999999998],
          [30.372323999999995, 58.743854999999996],
          [30.384134, 58.741894],
          [30.375793, 58.73295000000002],
          [30.381010000000003, 58.731989999999996],
          [30.378209999999996, 58.728324999999984],
          [30.373709, 58.72649000000001],
          [30.370127, 58.725930000000005],
          [30.372124000000003, 58.72430399999999],
          [30.371017, 58.71854999999999],
          [30.373566, 58.71544],
          [30.391068, 58.718704],
          [30.406440000000003, 58.702877],
          [30.424068000000002, 58.70388],
          [30.437166, 58.70285799999999],
          [30.446898000000004, 58.70190999999997],
          [30.444996000000003, 58.70446799999999],
          [30.484104, 58.71617500000002],
          [30.488129, 58.71851700000002],
          [30.487617, 58.72064],
          [30.49603, 58.72371300000003],
          [30.500984, 58.724132999999995],
          [30.51147, 58.725104999999985],
          [30.520397, 58.72480999999999],
          [30.52669, 58.72422399999999],
          [30.554056, 58.72422799999998],
          [30.561832, 58.72657000000001],
          [30.577473, 58.71003000000002],
          [30.573154, 58.70439499999998],
          [30.586032999999997, 58.70447000000004],
          [30.598692, 58.71573999999998],
          [30.619839, 58.71455],
          [30.634472, 58.726406],
          [30.643105, 58.73188999999999],
          [30.6483, 58.73430600000003],
          [30.653862, 58.73577],
          [30.657227, 58.732770000000016],
          [30.664178999999997, 58.736576000000014],
          [30.678009, 58.728306],
          [30.66835, 58.721059999999994],
          [30.671570000000003, 58.719600000000014],
          [30.676764999999996, 58.71835699999997],
          [30.6824, 58.71374500000002],
          [30.691398999999997, 58.716892],
          [30.701057, 58.72472400000001],
          [30.706326, 58.728049999999996],
          [30.710203, 58.72746699999999],
          [30.711373999999996, 58.728710000000035],
          [30.712765, 58.729003999999975],
          [30.713203, 58.73053999999996],
          [30.708227, 58.732590000000016],
          [30.708446999999996, 58.73368500000001],
          [30.709032000000004, 58.734127],
          [30.709326, 58.73492999999999],
          [30.71057, 58.735810000000015],
          [30.713789000000002, 58.73639299999999],
          [30.712545, 58.73924600000001],
          [30.710642, 58.74034499999999],
          [30.712765, 58.74283199999999],
          [30.710936, 58.743492],
          [30.708593, 58.74319999999997],
          [30.708227, 58.743492],
          [30.709837, 58.74444199999999],
          [30.703178, 58.74444199999999],
          [30.700691000000003, 58.74517399999999],
          [30.708080000000002, 58.756077000000005],
          [30.702374, 58.757540000000006],
          [30.713967999999998, 58.778193999999985],
          [30.715278999999995, 58.80931000000001],
          [30.728271, 58.813919999999996],
          [30.726881, 58.81509],
          [30.72659, 58.81823700000001],
          [30.716124999999998, 58.82116300000001],
          [30.715246000000004, 58.82569999999998],
          [30.706831, 58.82576999999998],
          [30.695856, 58.828552],
          [30.694649, 58.831974],
          [30.693222, 58.83601799999997],
          [30.678879999999996, 58.833527000000004],
          [30.68166, 58.83909],
          [30.676783, 58.84219699999997],
          [30.676316999999997, 58.84955200000002],
          [30.682720000000003, 58.858720000000005],
          [30.700573, 58.85769299999998],
          [30.70555, 58.853522999999996],
          [30.710087, 58.853816999999964],
          [30.719524, 58.858720000000005],
          [30.72538, 58.85637700000001],
          [30.736282, 58.85681499999998],
          [30.740232, 58.866839999999996],
          [30.749598, 58.870059999999995],
          [30.754793, 58.85637700000001],
          [30.762183999999998, 58.856525000000005],
          [30.763977, 58.862599999999986],
          [30.767416, 58.867500000000035],
          [30.772318, 58.87283999999997],
          [30.780512, 58.875404],
          [30.79105, 58.877819999999986],
          [30.79427, 58.87628000000001],
          [30.799171000000005, 58.877014],
          [30.81139, 58.87716],
          [30.813168000000005, 58.876559999999984],
          [30.823765, 58.881237],
          [30.862206999999998, 58.89819700000004],
          [30.873475999999997, 58.903392999999994],
          [30.922426000000005, 58.921504999999996],
          [30.946058, 58.939539999999965],
          [30.952352999999995, 58.94275999999999],
          [30.965082, 58.96390500000001],
          [30.954105000000002, 58.97314499999999],
          [30.978619, 58.98075499999999],
          [30.979055, 58.989315000000005],
          [30.988913, 58.991837000000004],
          [30.996471, 58.99553700000001],
          [31.003275, 59.000294],
          [30.98666, 59.012496999999996],
          [30.964268, 59.016808],
          [30.956004999999998, 59.03325699999999],
          [30.966322, 59.035064999999975],
          [30.970418999999996, 59.033604],
          [30.97115, 59.043845999999974],
          [30.98337, 59.043994999999995],
          [30.990028, 59.040553999999986],
          [30.99537, 59.04435999999998],
          [30.9972, 59.04435999999998],
          [31.010149, 59.05666999999997],
          [31.01732, 59.059673000000004],
          [31.02193, 59.065889999999996],
          [31.0291, 59.069477000000006],
          [31.034954, 59.06954999999999],
          [31.038465000000002, 59.07086599999997],
          [31.046515, 59.07547800000003],
          [31.056392999999996, 59.07489000000001],
          [31.050979999999996, 59.070942],
          [31.054856999999995, 59.07013699999999],
          [31.055077, 59.069332],
          [31.059760000000004, 59.06793999999999],
          [31.069857, 59.07386800000003],
          [31.076587999999997, 59.083290000000005],
          [31.080904000000004, 59.09067999999999],
          [31.131370000000004, 59.08932999999999],
          [31.135338, 59.08628999999999],
          [31.148660000000003, 59.082874000000004],
          [31.151138, 59.08092500000001],
          [31.154129000000005, 59.08039500000001],
          [31.162323, 59.076629999999994],
          [31.16606, 59.075526999999994],
          [31.163736, 59.07130999999998],
          [31.164038, 59.06842],
          [31.166302, 59.06578400000001],
          [31.169586000000002, 59.06321],
          [31.1564, 59.05538999999999],
          [31.163619999999998, 59.05453499999999],
          [31.168933999999997, 59.05404300000001],
          [31.185137, 59.052463999999986],
          [31.184707999999997, 59.047380000000004],
          [31.190359999999995, 59.04712999999998],
          [31.218678, 59.00825499999999],
          [31.221376, 59.008872999999994],
          [31.287733, 59.007835],
          [31.288004000000004, 59.009724000000034],
          [31.301966, 59.009555999999975],
          [31.300455, 59.01241700000003],
          [31.318472, 59.017269999999996],
          [31.319813, 59.018764000000004],
          [31.310041, 59.02282300000002],
          [31.318609999999996, 59.02709999999999],
          [31.322866, 59.024215999999996],
          [31.333715, 59.03126499999996],
          [31.33344, 59.02808999999999],
          [31.339716000000003, 59.02488299999999],
          [31.344473, 59.02575999999999],
          [31.348059, 59.02575999999999],
          [31.352228, 59.026275999999996],
          [31.355814000000002, 59.02591000000001],
          [31.361862000000002, 59.02644699999999],
          [31.36583, 59.026849999999996],
          [31.371145, 59.02647000000002],
          [31.373769999999997, 59.030304],
          [31.374533, 59.03317300000003],
          [31.37367, 59.03585000000001],
          [31.372997, 59.038666000000006],
          [31.374086, 59.039706999999964],
          [31.372501, 59.04317500000002],
          [31.376827, 59.05051800000001],
          [31.362288, 59.053139999999985],
          [31.356476, 59.05136999999999],
          [31.352133, 59.05343199999999],
          [31.364125999999995, 59.05671300000003],
          [31.371277, 59.05479999999997],
          [31.377673999999995, 59.056039999999996],
          [31.378283, 59.05575999999999],
          [31.384027, 59.05668300000002],
          [31.385002000000004, 59.061882],
          [31.385117, 59.06503699999999],
          [31.404174999999995, 59.06499000000002],
          [31.420755, 59.074104000000005],
          [31.428075999999997, 59.07166699999999],
          [31.446015999999997, 59.079643000000004],
          [31.453746999999996, 59.081608000000045],
          [31.463018, 59.0934],
          [31.468529, 59.09261999999998],
          [31.47468, 59.09861999999998],
          [31.481156999999996, 59.101562],
          [31.471653000000003, 59.10970299999997],
          [31.473576, 59.113372999999996],
          [31.469667, 59.120712],
          [31.467352, 59.124573],
          [31.487060000000003, 59.138476999999995],
          [31.48757, 59.13928200000001],
          [31.490454, 59.142075000000034],
          [31.491863, 59.149643],
          [31.489905999999998, 59.15099699999999],
          [31.493876, 59.154049999999955],
          [31.493116, 59.158264],
          [31.490889, 59.16040799999999],
          [31.491894, 59.163895],
          [31.49318, 59.164253],
          [31.495711999999997, 59.16589699999997],
          [31.496271, 59.16691],
          [31.475235000000005, 59.16793000000001],
          [31.48732, 59.179924],
          [31.488571, 59.180218000000025],
          [31.492792, 59.18505999999999],
          [31.496294000000002, 59.187636999999995],
          [31.510674000000005, 59.20114499999997],
          [31.499712000000002, 59.206954999999965],
          [31.49992, 59.20701199999999],
          [31.500080000000004, 59.206947000000014],
          [31.500171999999996, 59.20693600000001],
          [31.500277, 59.20698999999999],
          [31.500416, 59.207108000000005],
          [31.500622000000003, 59.20713999999998],
          [31.501116, 59.20724000000001],
          [31.50152, 59.20732000000004],
          [31.501682, 59.20735500000001],
          [31.501913, 59.20734999999999],
          [31.501962999999996, 59.20732000000004],
          [31.502003000000002, 59.207269999999994],
          [31.502070999999997, 59.20726400000001],
          [31.502174, 59.20733000000001],
          [31.502370000000003, 59.207393999999994],
          [31.502445, 59.20744000000002],
          [31.502555999999995, 59.20742999999999],
          [31.502687000000005, 59.20744300000004],
          [31.502752000000005, 59.207527],
          [31.502687000000005, 59.207569999999976],
          [31.502726, 59.20761999999999],
          [31.502737000000003, 59.20774],
          [31.502691, 59.207764],
          [31.502495000000003, 59.207786999999996],
          [31.502354, 59.207817000000006],
          [31.502342000000002, 59.20788200000001],
          [31.502361, 59.20792],
          [31.502435999999996, 59.20796600000003],
          [31.502531, 59.20803999999998],
          [31.502639999999996, 59.208065000000005],
          [31.502774999999996, 59.208065000000005],
          [31.502843999999996, 59.20803000000001],
          [31.502953, 59.20796600000003],
          [31.50322, 59.20790499999998],
          [31.503385999999995, 59.20788200000001],
          [31.503477, 59.20785000000001],
          [31.503577999999997, 59.20779400000001],
          [31.50361, 59.20772600000001],
          [31.503569, 59.20767599999999],
          [31.503588, 59.20764500000004],
          [31.503786000000005, 59.207663999999994],
          [31.50395, 59.207695],
          [31.504038, 59.207775],
          [31.504087, 59.207874000000004],
          [31.504092999999997, 59.208023],
          [31.503983, 59.20807300000001],
          [31.504005, 59.20815000000002],
          [31.504019, 59.208237],
          [31.503970000000002, 59.208267000000006],
          [31.503849000000002, 59.208259999999996],
          [31.503647000000004, 59.208200000000005],
          [31.50352, 59.208214],
          [31.50352, 59.20833200000001],
          [31.503542, 59.20839699999999],
          [31.503632999999997, 59.208484999999996],
          [31.503724999999996, 59.208560000000006],
          [31.503897, 59.20866799999999],
          [31.503914, 59.20873999999998],
          [31.503992, 59.208766999999995],
          [31.504064999999997, 59.208759999999984],
          [31.5042, 59.20864499999999],
          [31.504244000000003, 59.208484999999996],
          [31.504300000000004, 59.208429999999964],
          [31.504557, 59.20839000000001],
          [31.504712999999995, 59.20844299999999],
          [31.504765, 59.20855],
          [31.504786000000003, 59.20864499999999],
          [31.504656, 59.208763000000005],
          [31.504631, 59.20889700000001],
          [31.506754000000004, 59.208324000000005],
          [31.507738, 59.209282],
          [31.506237, 59.209698],
          [31.512062, 59.212784],
          [31.530659, 59.20278999999999],
          [31.530708, 59.20289600000001],
          [31.53072, 59.20302600000002],
          [31.5306, 59.203093999999965],
          [31.530462, 59.20312999999999],
          [31.530304, 59.203239999999994],
          [31.530048, 59.20334600000001],
          [31.530023999999997, 59.203389999999985],
          [31.530036999999997, 59.20350300000001],
          [31.530040000000003, 59.203599999999994],
          [31.529981999999997, 59.203659999999985],
          [31.529858, 59.20369699999998],
          [31.529829, 59.20374000000001],
          [31.529926, 59.20379600000004],
          [31.529915000000003, 59.203895999999986],
          [31.529958999999998, 59.20393999999999],
          [31.530058, 59.203934000000004],
          [31.530171999999997, 59.203907000000015],
          [31.530273000000005, 59.20391999999998],
          [31.530378000000002, 59.203982999999994],
          [31.530582, 59.204002],
          [31.530676000000003, 59.204025],
          [31.530730000000002, 59.20405199999999],
          [31.530735, 59.20410500000003],
          [31.530663000000004, 59.204205],
          [31.530690000000003, 59.20425800000004],
          [31.530769999999997, 59.20423499999998],
          [31.530901, 59.20422000000002],
          [31.530983, 59.204200000000014],
          [31.531078, 59.204184999999995],
          [31.531199999999995, 59.20411300000001],
          [31.531237, 59.20405199999999],
          [31.531155, 59.20391999999998],
          [31.53123, 59.20389],
          [31.531382000000004, 59.203884000000016],
          [31.531516999999997, 59.20391000000001],
          [31.531693999999998, 59.20402999999999],
          [31.531756999999995, 59.20411300000001],
          [31.531834, 59.20424999999997],
          [31.531794, 59.204372000000006],
          [31.531675, 59.204432999999995],
          [31.531548, 59.20454799999999],
          [31.531417999999995, 59.20456999999999],
          [31.531345000000005, 59.204612999999966],
          [31.531147, 59.204710000000006],
          [31.530867000000004, 59.204740000000015],
          [31.530782999999996, 59.204729999999984],
          [31.530739, 59.20475400000001],
          [31.530733000000005, 59.20482999999999],
          [31.530716, 59.20489499999999],
          [31.530602, 59.20491799999999],
          [31.530362999999998, 59.20488400000002],
          [31.530205, 59.204864999999984],
          [31.530098, 59.204680000000025],
          [31.530065999999998, 59.204655],
          [31.52996, 59.204642999999976],
          [31.529902999999997, 59.204703999999964],
          [31.529814, 59.204886999999985],
          [31.529887999999996, 59.20499799999999],
          [31.529852000000005, 59.205089999999984],
          [31.529780000000002, 59.20516200000003],
          [31.529764, 59.205467],
          [31.529758, 59.205689999999976],
          [31.529767999999997, 59.205814000000004],
          [31.529901999999996, 59.205836999999974],
          [31.530134000000004, 59.20580000000001],
          [31.533958000000002, 59.20582000000002],
          [31.537745000000005, 59.206875000000025],
          [31.540754000000003, 59.20652799999999],
          [31.563349, 59.20997599999998],
          [31.571262, 59.21119999999999],
          [31.574888, 59.222183],
          [31.575163000000003, 59.225876],
          [31.579588, 59.22882999999999],
          [31.585654999999996, 59.235634000000005],
          [31.583338, 59.23953599999999],
          [31.576109000000002, 59.24029999999999],
          [31.56723, 59.24385000000001],
          [31.567312000000005, 59.24741399999999],
          [31.565044000000004, 59.24772999999999],
          [31.562153000000002, 59.25101999999998],
          [31.561554, 59.253975],
          [31.564146, 59.25718699999999],
          [31.563871, 59.25898000000001],
          [31.559960000000004, 59.258827],
          [31.555433, 59.259090000000015],
          [31.550194000000005, 59.26270699999998],
          [31.550028, 59.26383999999999],
          [31.547206999999997, 59.265563999999955],
          [31.545582000000003, 59.26594499999999],
          [31.543053, 59.26817300000002],
          [31.547543, 59.27018000000001],
          [31.547439999999998, 59.27227400000001],
          [31.540481999999997, 59.27432999999999],
          [31.540299999999995, 59.27600499999997],
          [31.541668, 59.27668800000001],
          [31.541798000000004, 59.27736300000001],
          [31.539656000000004, 59.27741600000002],
          [31.534650000000003, 59.27964],
          [31.532044999999997, 59.283240000000006],
          [31.529239999999998, 59.284794000000005],
          [31.531607, 59.28588999999997],
          [31.534006, 59.28642700000003],
          [31.537178, 59.28826000000004],
          [31.538233000000005, 59.289860000000004],
          [31.536576999999998, 59.291484999999994],
          [31.536224000000004, 59.29327799999999],
          [31.539656000000004, 59.29464999999999],
          [31.537746, 59.296627],
          [31.543320000000005, 59.297034999999994],
          [31.549639, 59.296738000000005],
          [31.552169999999997, 59.299217],
          [31.55344, 59.299305000000004],
          [31.564478, 59.29934700000001],
          [31.561745000000002, 59.302340000000015],
          [31.552015, 59.30084999999997],
          [31.549679000000005, 59.30223000000004],
          [31.549055, 59.30498],
          [31.545977000000004, 59.30536000000001],
          [31.544005999999996, 59.30768599999999],
          [31.546826999999997, 59.31134800000001],
          [31.544456000000004, 59.31533399999998],
          [31.53822, 59.31706],
          [31.534185, 59.31725],
          [31.534075, 59.31950999999998],
          [31.549276, 59.33416399999999],
          [31.549305, 59.347946000000036],
          [31.548195, 59.348895999999996],
          [31.549253, 59.349999999999994],
          [31.548893, 59.352390000000014],
          [31.546389, 59.353470000000016],
          [31.542745999999998, 59.35639599999999],
          [31.552404000000003, 59.36907000000002],
          [31.561865, 59.37036499999999],
          [31.571481999999996, 59.37071],
          [31.579142, 59.371376],
          [31.596274999999995, 59.374527],
          [31.599174, 59.37402000000003],
          [31.599415, 59.37544600000004],
          [31.608988000000004, 59.37734599999999],
          [31.621954, 59.37338599999998],
          [31.6292, 59.371666000000005],
          [31.634519999999995, 59.36849000000001],
          [31.64514, 59.364689999999996],
          [31.646050999999996, 59.365973999999994],
          [31.665039999999998, 59.36614600000004],
          [31.666794, 59.36513499999998],
          [31.668780999999996, 59.365200000000016],
          [31.672056000000005, 59.367515999999995],
          [31.674559000000002, 59.36682999999999],
          [31.674842999999996, 59.368120000000005],
          [31.67198, 59.36835500000001],
          [31.67185, 59.36909],
          [31.675307999999998, 59.36910599999996],
          [31.676882, 59.368446000000006],
          [31.678455000000003, 59.36872500000001],
          [31.680286, 59.370247000000006],
          [31.683073, 59.369118000000014],
          [31.68426, 59.366737],
          [31.687563, 59.367065],
          [31.691948000000004, 59.36590000000004],
          [31.692335, 59.360600000000005],
          [31.695065999999997, 59.359646],
          [31.69227, 59.357699999999966],
          [31.700217999999996, 59.35568599999999],
          [31.699951, 59.354232999999994],
          [31.705996999999996, 59.353714],
          [31.707102, 59.352318000000025],
          [31.704712000000004, 59.35089499999998],
          [31.710123000000003, 59.34884600000001],
          [31.734801999999995, 59.35153600000001],
          [31.736432999999998, 59.34443300000001],
          [31.733944, 59.342967999999956],
          [31.729479000000005, 59.34207000000001],
          [31.7117, 59.342895999999996],
          [31.707182, 59.34326599999997],
          [31.710924, 59.336997999999994],
          [31.721746000000003, 59.33724999999998],
          [31.73756, 59.338066000000026],
          [31.740243999999997, 59.34402],
          [31.747683, 59.34241500000002],
          [31.753853, 59.343975],
          [31.764143000000004, 59.33919],
          [31.761615999999997, 59.33685],
          [31.756847000000004, 59.332719999999995],
          [31.748179999999998, 59.32897600000001],
          [31.74413, 59.330073999999996],
          [31.73062, 59.32621799999998],
          [31.730005, 59.32446300000001],
          [31.725701999999995, 59.32346000000001],
          [31.72818, 59.32218],
          [31.741468000000005, 59.315315],
          [31.745853, 59.319102999999984],
          [31.751647999999996, 59.32446999999999],
          [31.758934000000004, 59.32190299999999],
          [31.761978, 59.31776400000001],
          [31.766695, 59.31809200000001],
          [31.769936000000005, 59.31706200000002],
          [31.775242, 59.31540000000001],
          [31.786152, 59.31775699999997],
          [31.792916999999996, 59.316708000000006],
          [31.791864000000004, 59.313860000000034],
          [31.798676, 59.312805],
          [31.800627, 59.31546800000001],
          [31.802647000000004, 59.317665000000005],
          [31.806448, 59.322205],
          [31.810265, 59.322517000000005],
          [31.811384, 59.32410999999999],
          [31.812327999999997, 59.32459299999999],
          [31.81802, 59.32486],
          [31.822643, 59.325080000000014],
          [31.822990000000004, 59.326042],
          [31.822482999999995, 59.32844499999999],
          [31.821235999999995, 59.32840300000001],
          [31.811737, 59.32830000000001],
          [31.811380000000003, 59.325638],
          [31.807976000000004, 59.32589699999997],
          [31.803709, 59.326719999999995],
          [31.793507000000005, 59.333088000000004],
          [31.792027000000004, 59.33292800000001],
          [31.787214, 59.33528000000001],
          [31.788862, 59.33730700000001],
          [31.791138, 59.336826],
          [31.79225, 59.34030999999999],
          [31.796467, 59.341293000000036],
          [31.800386, 59.342873000000026],
          [31.802590000000002, 59.34475299999997],
          [31.803891999999998, 59.346187999999984],
          [31.803457, 59.34770000000003],
          [31.804922, 59.34956399999999],
          [31.807287, 59.35275999999999],
          [31.809101, 59.353905],
          [31.833457999999997, 59.35367600000001],
          [31.837763, 59.35909000000001],
          [31.847132, 59.358363999999995],
          [31.859785, 59.36041],
          [31.864483, 59.360637999999994],
          [31.866049999999998, 59.364307],
          [31.869170000000004, 59.364810000000006],
          [31.870459000000004, 59.36651599999999],
          [31.876204999999995, 59.366085],
          [31.880465, 59.367639999999994],
          [31.884512000000004, 59.36684999999997],
          [31.886236, 59.36761000000001],
          [31.886636999999997, 59.368538],
          [31.884201, 59.36864000000003],
          [31.88156, 59.36854600000001],
          [31.8786, 59.36908299999999],
          [31.877289, 59.370056000000005],
          [31.876888000000005, 59.37360799999999],
          [31.875132, 59.37495999999999],
          [31.872722999999997, 59.375136999999995],
          [31.87137, 59.375640000000004],
          [31.871757999999996, 59.377262],
          [31.872381, 59.38126399999999],
          [31.873785000000005, 59.38076000000001],
          [31.891773, 59.371219999999965],
          [31.893744, 59.37146000000001],
          [31.901733000000004, 59.373339999999985],
          [31.906583999999995, 59.376029999999986],
          [31.91114, 59.38199],
          [31.913551, 59.38627199999999],
          [31.913889000000005, 59.388750000000016],
          [31.915125, 59.389842999999985],
          [31.915684, 59.39243999999999],
          [31.913887, 59.39640000000003],
          [31.913637, 59.39863600000001],
          [31.917853999999995, 59.39870499999998],
          [31.919810000000002, 59.39895200000001],
          [31.918021999999997, 59.402739999999994],
          [31.92093, 59.406048],
          [31.923166, 59.41040400000003],
          [31.934960999999998, 59.418800000000005],
          [31.943127000000004, 59.424217],
          [31.941765, 59.42470600000004],
          [31.94939, 59.42971400000002],
          [31.950520000000004, 59.429520000000025],
          [31.953724, 59.430946000000034],
          [31.95854, 59.431858000000005],
          [31.963514, 59.43236999999996],
          [31.964321, 59.432762],
          [31.976946, 59.41926999999998],
          [31.981024000000005, 59.42002500000001],
          [31.982172000000002, 59.41983999999999],
          [31.984783, 59.419963999999965],
          [31.98595, 59.420529999999985],
          [31.987247000000004, 59.42018999999996],
          [31.98495, 59.41728999999998],
          [31.982540000000004, 59.41517300000001],
          [31.981379, 59.41382999999999],
          [31.97993, 59.412632],
          [31.977577000000004, 59.412784999999985],
          [31.975378000000003, 59.41250200000002],
          [31.971618999999997, 59.408874999999995],
          [31.966496, 59.40723799999998],
          [31.972628, 59.405486999999994],
          [31.977140000000002, 59.407444],
          [31.978745, 59.411427],
          [31.981152, 59.411175000000014],
          [31.982038000000003, 59.41179299999999],
          [31.989334000000003, 59.412983],
          [32.004642, 59.418728000000016],
          [32.004417, 59.41631699999999],
          [32.003914, 59.41243399999999],
          [32.005043, 59.406925],
          [32.00892, 59.40356],
          [32.01532, 59.40099000000001],
          [32.01308, 59.400723],
          [32.022854, 59.39386400000001],
          [32.032375, 59.39107999999999],
          [32.034966, 59.39062000000004],
          [32.041634, 59.393733999999995],
          [32.03957, 59.39946000000003],
          [32.038498, 59.40328199999999],
          [32.067017, 59.39470699999998],
          [32.07514, 59.39385200000001],
          [32.08003, 59.386191999999966],
          [32.09485, 59.38233600000001],
          [32.100784, 59.38511299999999],
          [32.118347, 59.384007],
          [32.117073, 59.37159299999999],
          [32.121647, 59.37081500000002],
          [32.16812, 59.335308],
          [32.171165, 59.334213000000005],
          [32.16687, 59.32859400000001],
          [32.175556, 59.324196],
          [32.1851, 59.321939999999984],
          [32.190624, 59.31979799999999],
          [32.220737, 59.33279400000001],
          [32.225636, 59.34121999999999],
          [32.225597, 59.345860000000016],
          [32.223225, 59.348465000000004],
          [32.224476, 59.35064],
          [32.223217, 59.35273699999999],
          [32.223114, 59.35538],
          [32.22637, 59.35515200000003],
          [32.22735, 59.35094000000004],
          [32.228745, 59.346554],
          [32.229, 59.342262000000005],
          [32.226315, 59.33679000000001],
          [32.22292, 59.33237500000001],
          [32.221745, 59.33173399999998],
          [32.216526, 59.32319000000001],
          [32.212147, 59.322304],
          [32.21261, 59.32142300000001],
          [32.18606, 59.313544999999976],
          [32.1851, 59.311949999999996],
          [32.187283, 59.30953199999999],
          [32.188633, 59.306539999999984],
          [32.190704, 59.30484799999999],
          [32.19278, 59.30528000000001],
          [32.195457, 59.303252999999984],
          [32.20136, 59.303425000000004],
          [32.20686, 59.305440000000004],
          [32.205265, 59.30740399999999],
          [32.20542, 59.30932200000001],
          [32.208355, 59.309770000000015],
          [32.21129, 59.308018000000004],
          [32.218307, 59.310337000000004],
          [32.229275, 59.31272000000001],
          [32.23282, 59.318123000000014],
          [32.241585, 59.32243699999998],
          [32.244305, 59.322067000000004],
          [32.25685, 59.32579799999999],
          [32.24809, 59.32946799999996],
          [32.24817, 59.32999799999999],
          [32.249725, 59.33250000000001],
          [32.24863, 59.33382399999999],
          [32.24323, 59.34011000000001],
          [32.240303, 59.342472000000015],
          [32.240463, 59.34440000000001],
          [32.24254, 59.34509299999999],
          [32.24521, 59.344044],
          [32.257282, 59.334675000000004],
          [32.259647, 59.333200000000005],
          [32.263027, 59.32763700000001],
          [32.262054, 59.32646600000001],
          [32.261276, 59.32585499999999],
          [32.26027, 59.325267999999994],
          [32.258076, 59.32493600000001],
          [32.255455, 59.323902000000004],
          [32.2538, 59.32316],
          [32.252457, 59.32119800000001],
          [32.251534, 59.32107500000001],
          [32.249657, 59.321156],
          [32.248444, 59.32137],
          [32.247047, 59.321365000000014],
          [32.244007, 59.320965],
          [32.241535, 59.320156000000026],
          [32.239193, 59.319046000000014],
          [32.23811, 59.31822],
          [32.236557, 59.317497],
          [32.234665, 59.31663],
          [32.233967, 59.31594000000001],
          [32.233837, 59.31524000000002],
          [32.234882, 59.31351000000001],
          [32.235012, 59.31276],
          [32.23475, 59.31183199999998],
          [32.232487, 59.310839999999985],
          [32.229763, 59.310490000000016],
          [32.22341, 59.30379000000002],
          [32.214443, 59.300983],
          [32.206898, 59.29874000000001],
          [32.204113, 59.295197],
          [32.199722, 59.290302],
          [32.20245, 59.289222999999964],
          [32.205067, 59.288257999999985],
          [32.206116, 59.28686999999999],
          [32.220184, 59.277180000000016],
          [32.22181, 59.27799599999997],
          [32.23426, 59.28429399999999],
          [32.248455, 59.29043999999999],
          [32.25182, 59.29043999999999],
          [32.252224, 59.28858600000001],
          [32.275185, 59.28879499999999],
          [32.287296, 59.28527],
          [32.297874, 59.27877000000001],
          [32.29862, 59.275948],
          [32.300884, 59.269485],
          [32.295593, 59.26680999999999],
          [32.292057, 59.26409499999997],
          [32.293808, 59.262460000000004],
          [32.297222, 59.261696],
          [32.313652, 59.263317],
          [32.315487, 59.26008999999999],
          [32.27992, 59.258865000000014],
          [32.285057, 59.251086999999984],
          [32.286606, 59.24955700000001],
          [32.28895, 59.245384],
          [32.29081, 59.24327499999998],
          [32.313473, 59.24678800000001],
          [32.317272, 59.238506],
          [32.310375, 59.232169999999996],
          [32.29919, 59.23203000000004],
          [32.286785, 59.22501399999999],
          [32.29214, 59.221194999999994],
          [32.302994, 59.221990000000034],
          [32.30345, 59.215473],
          [32.28729, 59.21660600000001],
          [32.280205, 59.21213499999999],
          [32.28191, 59.21070499999999],
          [32.282364, 59.20598000000001],
          [32.28249, 59.20072999999999],
          [32.284634, 59.200042999999994],
          [32.288998, 59.20014599999999],
          [32.290554, 59.200672],
          [32.294823, 59.19977],
          [32.29813, 59.19922600000001],
          [32.300133, 59.198195999999996],
          [32.306133, 59.196353999999985],
          [32.30675, 59.194733000000014],
          [32.30697, 59.18799200000001],
          [32.31885, 59.18267],
          [32.31844, 59.180767],
          [32.322815, 59.17968400000001],
          [32.329758, 59.18033600000001],
          [32.3333, 59.176860000000005],
          [32.345413, 59.174683000000016],
          [32.34974, 59.17151999999999],
          [32.352814, 59.17217299999999],
          [32.358185, 59.173122000000006],
          [32.360306, 59.17228000000003],
          [32.364914, 59.17141000000001],
          [32.368645, 59.17249699999999],
          [32.37011, 59.17090000000002],
          [32.37355, 59.170822000000015],
          [32.376842, 59.16928999999999],
          [32.382988, 59.16884999999999],
          [32.38619, 59.167236],
          [32.38423, 59.165775],
          [32.38511, 59.165115000000014],
          [32.388184, 59.164387000000005],
          [32.391037, 59.16380000000001],
          [32.392353, 59.16306700000001],
          [32.39733, 59.162777000000006],
          [32.400696, 59.16197199999999],
          [32.402966, 59.161456999999984],
          [32.407135, 59.16123999999999],
          [32.408306, 59.16065199999997],
          [32.416866, 59.16160599999998],
          [32.422695, 59.162193],
          [32.42414, 59.160560000000004],
          [32.434097, 59.158714],
          [32.44895, 59.15717699999999],
          [32.469044, 59.156207999999964],
          [32.482803, 59.15025299999999],
          [32.482933, 59.138949999999966],
          [32.474262, 59.14085399999999],
          [32.466736, 59.138206000000025],
          [32.435585, 59.15245999999999],
          [32.42547, 59.15203500000001],
          [32.42229, 59.153767000000016],
          [32.410797, 59.15222499999999],
          [32.412716, 59.150833000000006],
          [32.41064, 59.14961200000002],
          [32.40761, 59.151253],
          [32.40059, 59.14610000000002],
          [32.39276, 59.14192],
          [32.39485, 59.141335],
          [32.395813, 59.13966999999997],
          [32.400673, 59.13524000000004],
          [32.407467, 59.132120000000015],
          [32.410168, 59.13258999999999],
          [32.41315, 59.130066],
          [32.417564, 59.130510000000015],
          [32.424545, 59.127354],
          [32.427002, 59.122330000000005],
          [32.4251, 59.12096],
          [32.429913, 59.117755999999986],
          [32.435005, 59.11669499999999],
          [32.447895, 59.117924000000016],
          [32.44804, 59.11580000000001],
          [32.443798, 59.11485000000002],
          [32.449883, 59.11284599999999],
          [32.451595, 59.108135000000004],
          [32.45902, 59.10863000000003],
          [32.492897, 59.11265599999999],
          [32.4997, 59.112506999999965],
          [32.53777, 59.12058999999999],
          [32.56935, 59.131755999999996],
          [32.58207, 59.13977399999999],
          [32.57877, 59.115227000000004],
          [32.61952, 59.110287],
          [32.6256, 59.110000000000014],
          [32.628353, 59.11476999999999],
          [32.653793, 59.11809499999998],
          [32.658318, 59.12047000000001],
          [32.658302, 59.12057999999999],
          [32.658375, 59.120769999999965],
          [32.658424, 59.121025],
          [32.658382, 59.12134600000002],
          [32.65835, 59.121610000000004],
          [32.65835, 59.121999999999986],
          [32.658466, 59.122344999999996],
          [32.658516, 59.12264299999998],
          [32.658417, 59.122894],
          [32.658375, 59.123013000000014],
          [32.658443, 59.12314000000001],
          [32.658524, 59.123306000000014],
          [32.65853, 59.12344999999999],
          [32.658432, 59.123609999999985],
          [32.658302, 59.123737000000006],
          [32.658245, 59.123850000000004],
          [32.658302, 59.123940000000005],
          [32.65845, 59.12404599999999],
          [32.658756, 59.124134],
          [32.658913, 59.12423000000001],
          [32.658985, 59.12438],
          [32.658947, 59.124527],
          [32.65869, 59.12470200000001],
          [32.658592, 59.124817000000036],
          [32.658623, 59.12492],
          [32.659046, 59.12523300000001],
          [32.65987, 59.12583999999998],
          [32.660664, 59.12640999999999],
          [32.66143, 59.12698],
          [32.66211, 59.127624999999966],
          [32.66243, 59.127857000000006],
          [32.662785, 59.12802500000001],
          [32.663208, 59.128179999999986],
          [32.663883, 59.128365],
          [32.66411, 59.12845200000004],
          [32.664272, 59.128585999999984],
          [32.664288, 59.128754000000015],
          [32.66409, 59.128883],
          [32.66379, 59.12911199999999],
          [32.66325, 59.129433000000006],
          [32.662952, 59.129673000000025],
          [32.66267, 59.13000499999998],
          [32.66258, 59.130206999999984],
          [32.66257, 59.13051200000001],
          [32.662704, 59.130750000000006],
          [32.662704, 59.13092800000001],
          [32.662563, 59.13104200000001],
          [32.66223, 59.13114500000003],
          [32.661343, 59.13127],
          [32.66049, 59.13143500000001],
          [32.65994, 59.13159999999999],
          [32.65954, 59.131759999999986],
          [32.6594, 59.13189299999999],
          [32.659416, 59.132034000000004],
          [32.65953, 59.13214499999998],
          [32.65991, 59.13231999999999],
          [32.666645, 59.13246000000004],
          [32.67206, 59.138912000000005],
          [32.6693, 59.14096000000001],
          [32.679935, 59.14480999999998],
          [32.713287, 59.144543],
          [32.718845, 59.147835000000015],
          [32.725906, 59.14845699999998],
          [32.728832, 59.17392000000001],
          [32.73776, 59.174507000000034],
          [32.739445, 59.177066999999994],
          [32.74676, 59.17516699999999],
          [32.757736, 59.180139999999994],
          [32.759346, 59.183944999999994],
          [32.764175, 59.188045999999986],
          [32.75115, 59.193916],
          [32.74164, 59.193039999999996],
          [32.73842, 59.19567000000001],
          [32.74764, 59.197869999999995],
          [32.748882, 59.200649999999996],
          [32.757004, 59.20130499999996],
          [32.75649, 59.205479999999994],
          [32.765858, 59.206356],
          [32.768856, 59.21082000000001],
          [32.762417, 59.213234],
          [32.76849, 59.21740299999999],
          [32.770172, 59.218647000000004],
          [32.769367, 59.22091699999996],
          [32.76783, 59.222087999999985],
          [32.766003, 59.2226],
          [32.765564, 59.22574600000004],
          [32.76198, 59.226788],
          [32.7619, 59.228252],
          [32.75583, 59.228545999999994],
          [32.75173, 59.230373000000014],
          [32.74573, 59.23096000000001],
          [32.745586, 59.234398],
          [32.74193, 59.235862999999966],
          [32.736805, 59.240252999999996],
          [32.744705, 59.248080000000016],
          [32.740536, 59.250496],
          [32.73647, 59.25214399999999],
          [32.733536, 59.25465],
          [32.730453, 59.25650799999997],
          [32.72777, 59.25669499999998],
          [32.726295, 59.257712999999995],
          [32.729973, 59.257457999999986],
          [32.732048, 59.260593],
          [32.73417, 59.26227599999996],
          [32.737095, 59.263300000000044],
          [32.740314, 59.26352],
          [32.74295, 59.26271400000002],
          [32.744633, 59.26271400000002],
          [32.745, 59.26519999999999],
          [32.74295, 59.2663],
          [32.739582, 59.267105000000015],
          [32.73757, 59.26961],
          [32.73245, 59.27144200000001],
          [32.738304, 59.277443000000005],
          [32.745472, 59.279420000000044],
          [32.748325, 59.28095200000001],
          [32.750595, 59.28314999999998],
          [32.752937, 59.28432000000001],
          [32.7544, 59.28607600000001],
          [32.76318, 59.28607600000001],
          [32.773205, 59.30166200000002],
          [32.837593, 59.342510000000004],
          [32.84692, 59.34861999999998],
          [32.92141, 59.34861999999998],
          [32.92946, 59.358025],
          [32.937798, 59.373099999999994],
          [32.94475, 59.37616999999997],
          [32.9536, 59.38019600000001],
          [32.96209, 59.38561200000001],
          [32.970726, 59.39219700000001],
          [32.966625, 59.39438999999999],
          [32.967796, 59.399677],
          [32.9686, 59.40897000000001],
          [32.966106, 59.42020400000001],
          [32.9926, 59.42572799999999],
          [32.99699, 59.42514399999999],
          [33.005188, 59.426533000000006],
          [33.020992, 59.426388],
          [33.035625, 59.422436000000005],
          [33.038773, 59.421775999999994],
          [33.043015, 59.42206999999999],
          [33.048065, 59.42309599999999],
          [33.056957, 59.41850299999999],
          [33.062298, 59.395309999999995],
          [33.060837, 59.38894299999998],
          [33.071594, 59.38469700000002],
          [33.06369, 59.38243000000003],
          [33.066544, 59.37950000000001],
          [33.07723, 59.38118700000001],
          [33.08198, 59.382137],
          [33.094715, 59.384772999999996],
          [33.101665, 59.386382999999995],
          [33.112934, 59.38850400000001],
          [33.138435, 59.37134599999999],
          [33.141727, 59.37551500000001],
          [33.148388, 59.37470999999999],
          [33.15519, 59.383858000000004],
          [33.177437, 59.375957],
          [33.212852, 59.363020000000006],
          [33.23473, 59.36243999999999],
          [33.239193, 59.35570500000003],
          [33.249657, 59.34480300000001],
          [33.261585, 59.337196000000006],
          [33.262062, 59.320933999999994],
          [33.326015, 59.31617700000001],
          [33.34277, 59.315155000000004],
          [33.3484, 59.385249999999985],
          [33.416306, 59.38101000000003],
          [33.444584, 59.379253000000006],
          [33.44949, 59.37727699999999],
          [33.459, 59.377937],
          [33.47854, 59.379692000000006],
          [33.48311, 59.379986],
          [33.49372, 59.379546999999974],
          [33.523792, 59.37801000000002],
          [33.53682, 59.387084999999985],
          [33.581978, 59.38493],
          [33.610394, 59.38356999999999],
          [33.630295, 59.382496],
          [33.624516, 59.33683400000001],
          [33.62203, 59.327796999999975],
          [33.655834, 59.326186999999976],
          [33.663372, 59.327286000000015],
          [33.670544, 59.32099500000001],
          [33.66074, 59.308773],
          [33.668266, 59.308809999999994],
          [33.67583, 59.30955],
          [33.690445, 59.31060400000001],
          [33.697067, 59.304276000000016],
          [33.698753, 59.30471399999999],
          [33.706364, 59.298203],
          [33.733765, 59.29765299999997],
          [33.743935, 59.287777000000006],
          [33.756668, 59.293262],
          [33.76479, 59.287555999999995],
          [33.753155, 59.28141000000002],
          [33.75762, 59.278774],
          [33.77357, 59.28302000000002],
          [33.783524, 59.27409399999999],
          [33.77562, 59.271529999999984],
          [33.78206, 59.264729999999986],
          [33.783596, 59.264362000000006],
          [33.79684, 59.263317],
          [33.804306, 59.25878],
          [33.805405, 59.249194999999986],
          [33.793697, 59.248099999999994],
          [33.79977, 59.23931999999999],
          [33.819527, 59.234489999999994],
          [33.819305, 59.22717299999999],
          [33.812138, 59.230320000000006],
          [33.808037, 59.230244],
          [33.801086, 59.22248999999999],
          [33.795235, 59.22073400000002],
          [33.791393, 59.21618000000004],
          [33.800102, 59.200665000000015],
          [33.81649, 59.201324],
          [33.83398, 59.202496],
          [33.852932, 59.203666999999996],
          [33.907845, 59.207325000000026],
          [33.911137, 59.21442400000001],
          [33.9137, 59.222620000000006],
          [33.91399, 59.22583800000001],
          [33.922733, 59.23211299999997],
          [33.922077, 59.23671999999999],
          [33.927418, 59.238113],
          [33.93254, 59.23467299999999],
          [33.93598, 59.21184499999998],
          [33.933712, 59.205771999999996],
          [33.931736, 59.20262500000001],
          [33.929836, 59.199990000000014],
          [33.933273, 59.19977],
          [33.934372, 59.19889499999999],
          [33.941544, 59.19809000000001],
          [33.946846, 59.18729399999998],
          [33.96602, 59.186049999999966],
          [33.973885, 59.17311499999997],
          [33.982437, 59.17268999999999],
          [33.991272, 59.166027000000014],
          [34.004288, 59.163185],
          [34.01498, 59.16108299999999],
          [34.03174, 59.14843400000001],
          [34.03586, 59.14876000000001],
          [34.042442, 59.149677],
          [34.051655, 59.150289999999984],
          [34.053204, 59.150547000000046],
          [34.06718, 59.150180000000006],
          [34.076767, 59.140522000000004],
          [34.093815, 59.14528000000004],
          [34.099377, 59.143010000000004],
          [34.09901, 59.13745],
          [34.104866, 59.13752399999996],
          [34.116425, 59.141914000000014],
          [34.117443, 59.149437000000006],
          [34.11789, 59.15683999999999],
          [34.103252, 59.158962],
          [34.103107, 59.165257],
          [34.10574, 59.16678999999999],
          [34.10574, 59.17016000000001],
          [34.158974, 59.183018000000004],
          [34.176537, 59.17328599999999],
          [34.203278, 59.173835999999994],
          [34.21162, 59.17873800000001],
          [34.215202, 59.20522700000001],
          [34.228447, 59.206469999999996],
          [34.248352, 59.20713000000001],
          [34.251827, 59.202740000000006],
          [34.25512, 59.198498],
          [34.25695, 59.18868999999998],
          [34.28417, 59.178154000000006],
          [34.29149, 59.18247199999999],
          [34.33012, 59.18378999999999],
          [34.31944, 59.19051999999999],
          [34.33129, 59.19747000000001],
          [34.33605, 59.196888],
          [34.335243, 59.20091200000002],
          [34.340878, 59.20091200000002],
          [34.34146, 59.204132000000016],
          [34.350098, 59.20405999999997],
          [34.34973, 59.2004],
          [34.36217, 59.20033000000001],
          [34.362095, 59.19645],
          [34.356827, 59.193523],
          [34.358658, 59.191326000000004],
          [34.36005, 59.19067000000001],
          [34.36261, 59.189865000000026],
          [34.36422, 59.18890999999999],
          [34.36693, 59.187374000000005],
          [34.370293, 59.185913],
          [34.37388, 59.18371600000003],
          [34.38083, 59.177715000000006],
          [34.381783, 59.181377],
          [34.38295, 59.18290999999999],
          [34.391953, 59.182766000000015],
          [34.39349, 59.17804699999999],
          [34.394585, 59.17263],
          [34.396416, 59.167730000000006],
          [34.39744, 59.16524000000001],
          [34.397953, 59.16063299999999],
          [34.412075, 59.158585000000016],
          [34.421368, 59.157486000000006],
          [34.423344, 59.156679999999994],
          [34.423126, 59.144679999999994],
          [34.450966, 59.16006500000003],
          [34.45872, 59.153040000000004],
          [34.464577, 59.14945599999999],
          [34.479576, 59.14162999999999],
          [34.498383, 59.14426],
          [34.511955, 59.144645999999995],
          [34.528492, 59.13213299999998],
          [34.53603, 59.12613300000001],
          [34.549713, 59.12056999999999],
          [34.556885, 59.11800999999997],
          [34.559006, 59.11918299999999],
          [34.58019, 59.12161600000002],
          [34.627167, 59.11415499999998],
          [34.638214, 59.118179999999995],
          [34.658413, 59.121464],
          [34.67363, 59.11461299999999],
          [34.692215, 59.11088000000001],
          [34.706924, 59.09785500000001],
          [34.71746, 59.100490000000036],
          [34.725365, 59.098003000000006],
          [34.725693, 59.09672000000003],
          [34.72423, 59.09533299999998],
          [34.7235, 59.093063],
          [34.722622, 59.092112999999955],
          [34.722256, 59.08962199999999],
          [34.723278, 59.087939999999975],
          [34.72167, 59.086624],
          [34.721962, 59.085453],
          [34.722363, 59.08485000000002],
          [34.724358, 59.08498399999999],
          [34.730976, 59.088393999999994],
          [34.734867, 59.08873700000001],
          [34.752136, 59.09343000000001],
          [34.76224, 59.08926800000003],
          [34.783195, 59.10066599999996],
          [34.7849, 59.11452],
          [34.787632, 59.11506700000004],
          [34.790634, 59.11766],
          [34.79596, 59.120632],
          [34.801826, 59.126022000000006],
          [34.803673, 59.12732],
          [34.81118, 59.129166],
          [34.817734, 59.12936800000003],
          [34.821827, 59.12862000000001],
          [34.82558, 59.129166],
          [34.83459, 59.12725399999999],
          [34.84343, 59.146946000000014],
          [34.834488, 59.14768000000001],
          [34.83626, 59.15818999999999],
          [34.83913, 59.16754499999999],
          [34.837288, 59.16911300000001],
          [34.84254, 59.17300399999996],
          [34.849983, 59.17187999999999],
          [34.84926, 59.20109600000001],
          [34.855774, 59.206199999999995],
          [34.85894, 59.20945699999996],
          [34.862587, 59.211174],
          [34.861897, 59.212886999999995],
          [34.863304, 59.215630000000004],
          [34.896946, 59.21870000000001],
          [34.898144, 59.21808200000001],
          [34.899677, 59.218376000000006],
          [34.90051, 59.21830700000001],
          [34.9014, 59.217995],
          [34.90237, 59.217330000000004],
          [34.902946, 59.21647999999999],
          [34.903816, 59.21599599999999],
          [34.905445, 59.215385],
          [34.906876, 59.21503000000004],
          [34.90794, 59.21491],
          [34.909313, 59.214995999999985],
          [34.91027, 59.21488600000001],
          [34.910717, 59.21464499999999],
          [34.911007, 59.21415300000001],
          [34.911552, 59.21341700000002],
          [34.91223, 59.212886999999995],
          [34.913147, 59.21209999999999],
          [34.913776, 59.21166000000002],
          [34.91451, 59.21152000000001],
          [34.915295, 59.211517000000015],
          [34.91629, 59.211803],
          [34.91728, 59.21212800000001],
          [34.918068, 59.21236000000002],
          [34.919666, 59.21257],
          [34.920406, 59.212559999999996],
          [34.921368, 59.21235299999998],
          [34.922337, 59.21231499999999],
          [34.92313, 59.21232599999999],
          [34.924133, 59.21257],
          [34.925358, 59.21281400000001],
          [34.926895, 59.21297500000003],
          [34.927673, 59.213314000000025],
          [34.9281, 59.213689999999986],
          [34.928265, 59.21403000000001],
          [34.928135, 59.214493000000004],
          [34.927628, 59.214946999999995],
          [34.927414, 59.21523300000001],
          [34.927124, 59.21716700000002],
          [34.926697, 59.21814699999999],
          [34.92567, 59.219390000000004],
          [34.924133, 59.220234000000005],
          [34.92241, 59.221412999999984],
          [34.9207, 59.22265199999998],
          [34.92057, 59.22308000000001],
          [34.91889, 59.22427400000001],
          [34.917027, 59.226665],
          [34.91523, 59.22937400000001],
          [34.914562, 59.23427000000001],
          [34.915, 59.239777000000004],
          [34.91601, 59.24039500000001],
          [34.917812, 59.240654000000035],
          [34.918686, 59.240498],
          [34.919186, 59.240129999999965],
          [34.91954, 59.2381],
          [34.918785, 59.238020000000006],
          [34.918312, 59.23774700000004],
          [34.918224, 59.237404],
          [34.918774, 59.237232000000006],
          [34.919888, 59.23716000000002],
          [34.920864, 59.23692299999999],
          [34.921463, 59.236710000000016],
          [34.921867, 59.23639299999999],
          [34.92185, 59.23604],
          [34.921555, 59.23569499999999],
          [34.920605, 59.235252],
          [34.920467, 59.23486300000002],
          [34.920662, 59.23437000000001],
          [34.92121, 59.233859999999964],
          [34.921303, 59.23340200000001],
          [34.921066, 59.23303200000001],
          [34.920265, 59.23245],
          [34.919586, 59.23169999999999],
          [34.91945, 59.231228000000016],
          [34.920074, 59.23005299999997],
          [34.919987, 59.22955700000003],
          [34.919594, 59.229150000000004],
          [34.922333, 59.22698199999999],
          [34.934246, 59.223827],
          [34.93689, 59.223263],
          [34.938087, 59.22271999999998],
          [34.938965, 59.22192799999999],
          [34.968674, 59.22769499999998],
          [34.97167, 59.22538399999999],
          [34.97687, 59.224249999999984],
          [35.002117, 59.24700999999999],
          [35.012566, 59.24928299999999],
          [35.013786, 59.25039000000001],
          [35.027462, 59.25260500000002],
          [35.038597, 59.25403],
          [35.050205, 59.249089999999995],
          [35.061512, 59.25000399999999],
          [35.07776, 59.250140000000016],
          [35.088543, 59.248367],
          [35.100452, 59.24609800000002],
          [35.130764, 59.248954999999995],
          [35.142128, 59.253829999999994],
          [35.1482, 59.256287000000015],
          [35.178207, 59.25783000000001],
          [35.17746, 59.25938400000001],
          [35.176956, 59.26121999999998],
          [35.18245, 59.26634999999999],
          [35.18679, 59.27062999999998],
          [35.19404, 59.27571499999996],
          [35.195084, 59.277462000000014],
          [35.19378, 59.279296999999985],
          [35.188168, 59.28601499999999],
          [35.19086, 59.28697],
          [35.24689, 59.30187999999998],
          [35.35703, 59.307345999999995],
          [35.39622, 59.31756200000001],
          [35.38253, 59.32110199999997],
          [35.39236, 59.32062500000001],
          [35.390587, 59.32745],
          [35.38758, 59.32704000000001],
          [35.38144, 59.32581299999998],
          [35.377617, 59.32403600000001],
          [35.372154, 59.32321999999999],
          [35.370518, 59.32321999999999],
          [35.373383, 59.32704000000001],
          [35.36806, 59.328540000000004],
          [35.363693, 59.325676000000016],
          [35.357548, 59.327312000000006],
          [35.354195, 59.32260500000001],
          [35.299507, 59.34098399999999],
          [35.297207, 59.350930000000005],
          [35.311813, 59.363215999999994],
          [35.31147, 59.369293],
          [35.3127, 59.37174999999999],
          [35.31434, 59.372433],
          [35.318977, 59.37311600000004],
          [35.323895, 59.372569999999996],
          [35.325943, 59.36560399999999],
          [35.332222, 59.366153999999995],
          [35.33891, 59.366153999999995],
          [35.345192, 59.36533399999999],
          [35.34792, 59.36588000000003],
          [35.36485, 59.372840000000025],
          [35.36867, 59.37298000000001],
          [35.39297, 59.380898],
          [35.395702, 59.389633],
          [35.407578, 59.394960000000026],
          [35.41372, 59.39847600000002],
          [35.434334, 59.403797],
          [35.433243, 59.40188599999999],
          [35.435974, 59.40188599999999],
          [35.44239, 59.404346000000004],
          [35.44662, 59.41185399999998],
          [35.440067, 59.409943],
          [35.43092, 59.40966800000001],
          [35.426144, 59.40884799999998],
          [35.428463, 59.411310000000014],
          [35.4286, 59.41444799999999],
          [35.43065, 59.41731300000001],
          [35.43679, 59.423595000000006],
          [35.44116, 59.42331999999999],
          [35.440613, 59.42550700000001],
          [35.434196, 59.42550700000001],
          [35.431602, 59.427279999999996],
          [35.428738, 59.427279999999996],
          [35.427372, 59.42577699999998],
          [35.424232, 59.42618999999999],
          [35.418907, 59.42113499999999],
          [35.413036, 59.424004],
          [35.415222, 59.42645999999999],
          [35.411945, 59.426597999999984],
          [35.411945, 59.42891700000001],
          [35.3987, 59.43042],
          [35.399246, 59.429462],
          [35.393925, 59.42864600000004],
          [35.390373, 59.42905400000001],
          [35.39256, 59.43055700000002],
          [35.396927, 59.435333000000014],
          [35.401703, 59.437656000000004],
          [35.4071, 59.441372],
          [35.37306, 59.47272000000001],
          [35.31825, 59.48647299999999],
          [35.31632, 59.520275],
          [35.36671, 59.520606999999984],
          [35.417065, 59.517033],
          [35.437534, 59.51289700000001],
          [35.463413, 59.507664000000005],
          [35.495148, 59.50146999999998],
          [35.49815, 59.502909999999986],
          [35.507435, 59.50195700000003],
          [35.512623, 59.50345999999996],
          [35.51317, 59.50591700000001],
          [35.511665, 59.50810000000001],
          [35.503475, 59.507416000000035],
          [35.49733, 59.50905599999996],
          [35.493645, 59.51192],
          [35.493576, 59.51543800000002],
          [35.492348, 59.51898600000001],
          [35.48907, 59.52131],
          [35.485794, 59.52131],
          [35.48299, 59.520553999999976],
          [35.481426, 59.51980600000002],
          [35.47692, 59.51666599999999],
          [35.469685, 59.519397999999995],
          [35.469685, 59.521717000000024],
          [35.466953, 59.52349000000001],
          [35.46514, 59.523803999999984],
          [35.46409, 59.522808],
          [35.45658, 59.51884999999996],
          [35.45303, 59.518302999999975],
          [35.449345, 59.51871499999996],
          [35.435284, 59.518032000000005],
          [35.433643, 59.51884999999996],
          [35.43064, 59.51912300000001],
          [35.425453, 59.518302999999975],
          [35.421494, 59.51926],
          [35.420948, 59.521169999999984],
          [35.4189, 59.52363],
          [35.40948, 59.530045],
          [35.41808, 59.53537],
          [35.4219, 59.53537],
          [35.42668, 59.53591499999999],
          [35.434464, 59.538647],
          [35.43998, 59.53742],
          [35.441463, 59.534756000000016],
          [35.454395, 59.543285],
          [35.466133, 59.54669999999999],
          [35.474873, 59.546527999999995],
          [35.474873, 59.54393400000001],
          [35.493687, 59.53569999999999],
          [35.49466, 59.65190000000001],
          [35.605576, 59.61438000000001],
          [35.607662, 59.646705999999995],
          [35.61524, 59.71240599999996],
          [35.584816, 59.720882000000046],
          [35.56524, 59.71089999999998],
          [35.442657, 59.705430000000035],
          [35.407352, 59.719425],
          [35.405685, 59.728053999999986],
          [35.40298, 59.74205799999996],
          [35.39759, 59.771027000000004],
          [35.375027, 59.8579],
          [35.4222, 59.90556699999999],
          [35.4471, 59.916775],
          [35.446384, 59.977592000000016],
          [35.32139, 59.98079999999999],
          [35.322887, 59.995254999999986],
          [35.28665, 60.00038000000001],
          [35.28271, 60.014189999999985],
          [35.145554, 60.02078000000003],
          [35.140137, 60.042846999999995],
          [35.213955, 60.07401300000001],
          [35.212856, 60.09294499999996],
          [35.207348, 60.114180000000005],
          [35.175518, 60.128017],
          [35.155502, 60.151928],
          [35.18184, 60.201359999999994],
          [35.174713, 60.21190999999999],
          [35.185005, 60.245506000000006],
          [35.16263, 60.25603000000001],
          [35.177338, 60.261979999999994],
          [35.237984, 60.29138999999998],
          [35.252712, 60.313908],
          [35.253704, 60.33279999999999],
          [35.274426, 60.33626600000002],
          [35.260853, 60.35911999999999],
          [35.27811, 60.47898000000001],
          [35.29876, 60.601440000000025],
          [35.293163, 60.604442999999975],
          [35.27255, 60.610175999999996],
          [35.280056, 60.61699999999999],
          [35.281693, 60.637820000000005],
          [35.278282, 60.643279999999976],
          [35.272137, 60.648335],
          [35.261078, 60.66949499999998],
          [35.26811, 60.677752999999996],
          [35.26497, 60.68129999999999],
          [35.25705, 60.683075],
          [35.258415, 60.689490000000006],
          [35.250496, 60.69208499999999],
          [35.227425, 60.693449999999984],
          [35.20872, 60.698093],
          [35.208584, 60.70314400000001],
          [35.227833, 60.70956000000001],
          [35.23725, 60.714473999999996],
          [35.237522, 60.731335],
          [35.22046, 60.73351999999997],
          [35.184143, 60.75109499999999],
          [35.178413, 60.76373699999999],
          [35.17554, 60.770073],
          [35.18155, 60.77853799999997],
          [35.18851, 60.783042999999964],
          [35.19506, 60.78577000000004],
          [35.196224, 60.78874200000001],
          [35.200043, 60.79925],
          [35.223663, 60.80061699999999],
          [35.230213, 60.80785399999999],
          [35.241955, 60.83434],
          [35.259018, 60.83393000000001],
          [35.253967, 60.841984],
          [35.261612, 60.846419999999995],
          [35.276627, 60.843826000000035],
          [35.286865, 60.84424000000001],
          [35.328846, 60.856933999999995],
          [35.36352, 60.86062199999998],
          [35.38386, 60.85911999999999],
          [35.393147, 60.854070000000036],
          [35.409393, 60.85379399999999],
          [35.412804, 60.85679999999999],
          [35.42618, 60.85543400000003],
          [35.42714, 60.856663],
          [35.461678, 60.856937000000016],
          [35.469734, 60.86048499999998],
          [35.468502, 60.864990000000006],
          [35.476147, 60.864720000000005],
          [35.47789, 60.86627199999998],
          [35.48434, 60.872019999999964],
          [35.480927, 60.87529799999999],
          [35.48748, 60.88266999999999],
          [35.491573, 60.885264999999976],
          [35.50905, 60.88949600000001],
          [35.511097, 60.89127300000001],
          [35.51874, 60.89359300000001],
          [35.51942, 60.898098000000005],
          [35.518875, 60.90601699999999],
          [35.51922, 60.911580000000015],
          [35.52304, 60.916492000000005],
          [35.526165, 60.92063000000002],
          [35.696686, 61.00345999999999],
          [35.701313, 61.21434399999998],
          [35.604984, 61.179516000000035],
          [35.602825, 61.178619999999995],
          [35.602676, 61.178623000000016],
          [35.602222, 61.178623000000016],
          [35.60128, 61.17887000000002],
          [35.600327, 61.17957000000001],
          [35.600025, 61.17964599999999],
          [35.599556, 61.17966000000001],
          [35.599277, 61.179779999999994],
          [35.599186, 61.17991599999999],
          [35.59932, 61.18036699999999],
          [35.599205, 61.18061],
          [35.598713, 61.180781999999994],
          [35.597622, 61.180912000000006],
          [35.59746, 61.18083200000004],
          [35.597458, 61.180733000000004],
          [35.59788, 61.180470000000014],
          [35.597935, 61.18026400000002],
          [35.59783, 61.180126],
          [35.597622, 61.18006],
          [35.59733, 61.18009599999996],
          [35.596893, 61.180380000000014],
          [35.596493, 61.18042],
          [35.596077, 61.18037399999997],
          [35.595768, 61.18020000000001],
          [35.595516, 61.18021999999999],
          [35.595146, 61.180049999999994],
          [35.59493, 61.18010000000001],
          [35.594517, 61.18043],
          [35.594315, 61.18043],
          [35.59407, 61.18051],
          [35.594, 61.18060700000001],
          [35.594223, 61.18069],
          [35.594276, 61.18080499999999],
          [35.5941, 61.181004],
          [35.593792, 61.18125499999999],
          [35.593857, 61.18131299999999],
          [35.59407, 61.181286],
          [35.594433, 61.18158700000001],
          [35.594795, 61.182000000000045],
          [35.5948, 61.182106000000005],
          [35.59473, 61.182162999999974],
          [35.594574, 61.18215000000001],
          [35.59443, 61.18200999999999],
          [35.59417, 61.181927],
          [35.594, 61.181927],
          [35.593853, 61.182007],
          [35.59389, 61.182230000000004],
          [35.593784, 61.182259999999985],
          [35.593563, 61.182053000000025],
          [35.593357, 61.182075999999995],
          [35.59319, 61.181984],
          [35.593067, 61.181989999999985],
          [35.593014, 61.182075999999995],
          [35.593197, 61.18222800000001],
          [35.593132, 61.182297000000005],
          [35.592583, 61.182365000000004],
          [35.59252, 61.18248399999996],
          [35.593002, 61.182762],
          [35.593014, 61.18284],
          [35.592827, 61.183018000000004],
          [35.592438, 61.18291999999997],
          [35.5922, 61.182889999999986],
          [35.59211, 61.18284],
          [35.592144, 61.182770000000005],
          [35.59233, 61.18270000000004],
          [35.592255, 61.18254999999999],
          [35.59196, 61.182556000000005],
          [35.591595, 61.18288799999996],
          [35.591286, 61.18284600000001],
          [35.59069, 61.18263000000002],
          [35.59056, 61.182439999999986],
          [35.590134, 61.182320000000004],
          [35.58976, 61.182372999999984],
          [35.589573, 61.182514],
          [35.5896, 61.182590000000005],
          [35.589962, 61.18263999999999],
          [35.590027, 61.18268599999996],
          [35.59028, 61.18271300000001],
          [35.590286, 61.18275],
          [35.59002, 61.18277699999996],
          [35.590195, 61.18302499999999],
          [35.589935, 61.183266],
          [35.590088, 61.183468000000005],
          [35.59033, 61.183918000000006],
          [35.59065, 61.184079999999966],
          [35.59082, 61.18426000000002],
          [35.590782, 61.18434500000001],
          [35.59059, 61.18444400000001],
          [35.59035, 61.18446],
          [35.58986, 61.18436999999997],
          [35.58979, 61.184169999999995],
          [35.58967, 61.184067],
          [35.58952, 61.184032],
          [35.589405, 61.18409999999997],
          [35.589184, 61.184166000000005],
          [35.588863, 61.184177000000005],
          [35.588497, 61.18411600000002],
          [35.588257, 61.18386000000001],
          [35.588108, 61.183773],
          [35.58773, 61.18375399999999],
          [35.587162, 61.183784],
          [35.58721, 61.18361300000001],
          [35.587154, 61.183525],
          [35.58675, 61.18344999999999],
          [35.58648, 61.183445000000006],
          [35.585876, 61.18364299999999],
          [35.585537, 61.183876],
          [35.58554, 61.18403000000001],
          [35.585545, 61.18428800000001],
          [35.585407, 61.184425000000005],
          [35.584995, 61.18440199999998],
          [35.584568, 61.18424200000001],
          [35.5842, 61.184146999999996],
          [35.58405, 61.183865],
          [35.58413, 61.18363999999997],
          [35.58399, 61.18356299999999],
          [35.583702, 61.183487000000014],
          [35.58322, 61.18348],
          [35.583145, 61.183539999999994],
          [35.583168, 61.183742999999964],
          [35.583088, 61.18382],
          [35.583076, 61.18403000000001],
          [35.58286, 61.184166000000005],
          [35.58256, 61.184166000000005],
          [35.5822, 61.18408199999999],
          [35.58197, 61.18407400000001],
          [35.5818, 61.183955999999995],
          [35.581768, 61.18375399999999],
          [35.581524, 61.183716000000004],
          [35.58155, 61.183840000000004],
          [35.581505, 61.183932999999996],
          [35.581287, 61.183975000000004],
          [35.58118, 61.183918000000006],
          [35.581234, 61.18382600000001],
          [35.58102, 61.183712000000014],
          [35.58098, 61.18357],
          [35.580765, 61.18357],
          [35.58052, 61.183689999999984],
          [35.580276, 61.183677999999986],
          [35.58019, 61.183605],
          [35.58028, 61.183272999999986],
          [35.58025, 61.18308000000002],
          [35.58033, 61.182987000000026],
          [35.580738, 61.18296999999998],
          [35.581135, 61.182559999999995],
          [35.58106, 61.182423],
          [35.58061, 61.18239200000002],
          [35.58036, 61.182230000000004],
          [35.58016, 61.182379999999995],
          [35.57924, 61.18239],
          [35.578983, 61.182143999999994],
          [35.57879, 61.182106000000005],
          [35.57855, 61.182102000000015],
          [35.578327, 61.18201400000001],
          [35.577827, 61.18202200000002],
          [35.57739, 61.18194200000002],
          [35.576992, 61.182000000000045],
          [35.576275, 61.181927],
          [35.5758, 61.182075999999995],
          [35.5755, 61.182095000000004],
          [35.5748, 61.181989999999985],
          [35.574535, 61.18185],
          [35.57463, 61.181374000000005],
          [35.573967, 61.18126699999999],
          [35.572872, 61.180893000000026],
          [35.572227, 61.18060700000001],
          [35.572342, 61.180206],
          [35.57265, 61.180126],
          [35.5735, 61.180115],
          [35.573555, 61.17994300000001],
          [35.5731, 61.179869999999966],
          [35.572605, 61.179596000000004],
          [35.57135, 61.17974000000001],
          [35.57106, 61.17972999999998],
          [35.57076, 61.179114999999996],
          [35.57079, 61.178894000000014],
          [35.571037, 61.178687999999994],
          [35.570995, 61.178528],
          [35.570637, 61.178314],
          [35.57042, 61.178169999999994],
          [35.57044, 61.17807400000001],
          [35.57061, 61.177899999999994],
          [35.570663, 61.177779999999984],
          [35.570477, 61.177554999999984],
          [35.57044, 61.177402],
          [35.569454, 61.17696999999998],
          [35.56909, 61.17667800000001],
          [35.568783, 61.17672999999999],
          [35.568623, 61.17693299999999],
          [35.56828, 61.17713500000002],
          [35.568222, 61.177482999999995],
          [35.568104, 61.177520000000015],
          [35.56796, 61.17750000000001],
          [35.567852, 61.177279999999996],
          [35.56768, 61.17712],
          [35.567493, 61.176907],
          [35.567524, 61.17676],
          [35.566967, 61.17641000000003],
          [35.566746, 61.176327000000015],
          [35.56627, 61.176323],
          [35.566093, 61.176383999999985],
          [35.566105, 61.176556000000005],
          [35.565693, 61.17666199999999],
          [35.565475, 61.17655000000002],
          [35.565315, 61.17656299999999],
          [35.565178, 61.176647],
          [35.564922, 61.17669699999996],
          [35.5648, 61.176635999999974],
          [35.564438, 61.176679999999976],
          [35.564312, 61.17675400000002],
          [35.56412, 61.17707400000003],
          [35.563847, 61.17719299999999],
          [35.563595, 61.17723000000001],
          [35.563442, 61.17731499999999],
          [35.563183, 61.17735299999998],
          [35.562954, 61.17734999999999],
          [35.56286, 61.177283999999986],
          [35.56294, 61.17716200000001],
          [35.563057, 61.17712399999999],
          [35.563038, 61.17684000000003],
          [35.562946, 61.17672999999999],
          [35.562847, 61.176716],
          [35.562637, 61.17680999999999],
          [35.56252, 61.176777000000016],
          [35.562496, 61.17672300000001],
          [35.562603, 61.176624000000004],
          [35.562553, 61.17659400000002],
          [35.561943, 61.17662999999999],
          [35.561584, 61.176590000000004],
          [35.561302, 61.176716],
          [35.561176, 61.176826000000005],
          [35.560154, 61.17705000000001],
          [35.55958, 61.17711299999996],
          [35.558784, 61.17714999999998],
          [35.55804, 61.17701299999999],
          [35.55717, 61.176704],
          [35.55654, 61.176556000000005],
          [35.555763, 61.17663999999999],
          [35.555523, 61.176829999999995],
          [35.555515, 61.17696000000001],
          [35.55501, 61.177099999999996],
          [35.554924, 61.17698699999997],
          [35.55441, 61.177032000000025],
          [35.554535, 61.177155],
          [35.55416, 61.177400000000006],
          [35.553963, 61.17765],
          [35.553524, 61.17783700000001],
          [35.55343, 61.17802000000003],
          [35.552578, 61.177955999999995],
          [35.552505, 61.17763500000001],
          [35.55161, 61.177599999999984],
          [35.551075, 61.17737600000001],
          [35.551094, 61.17709400000004],
          [35.55077, 61.17690999999999],
          [35.550285, 61.17731499999999],
          [35.55021, 61.17775000000003],
          [35.5504, 61.17813499999997],
          [35.54971, 61.17867699999999],
          [35.54948, 61.17927],
          [35.541893, 61.179585],
          [35.525497, 61.180958000000004],
          [35.51159, 61.173163999999986],
          [35.49966, 61.16915000000003],
          [35.50014, 61.166416],
          [35.46434, 61.156868],
          [35.452496, 61.16032000000004],
          [35.430157, 61.14742999999996],
          [35.423645, 61.14582999999999],
          [35.420975, 61.14253200000002],
          [35.401913, 61.138732999999974],
          [35.396626, 61.140263000000004],
          [35.39842, 61.16570999999999],
          [35.390633, 61.16497799999999],
          [35.39078, 61.16512],
          [35.39085, 61.16528299999999],
          [35.390934, 61.165413],
          [35.391144, 61.165497000000016],
          [35.392193, 61.165752],
          [35.39313, 61.165966],
          [35.393703, 61.165997000000004],
          [35.394405, 61.166026999999985],
          [35.39468, 61.16608400000001],
          [35.395035, 61.166245],
          [35.395176, 61.16645],
          [35.39526, 61.16663399999999],
          [35.39552, 61.16675600000002],
          [35.39588, 61.16682000000003],
          [35.396698, 61.16687399999998],
          [35.398586, 61.16681299999999],
          [35.401962, 61.16860600000001],
          [35.402817, 61.16959000000003],
          [35.405285, 61.17123400000003],
          [35.403286, 61.17554999999999],
          [35.352142, 61.17645999999999],
          [35.35977, 61.22575400000002],
          [35.37073, 61.23201399999999],
          [35.367264, 61.241398000000004],
          [35.378586, 61.253937000000036],
          [35.37746, 61.25862999999998],
          [35.377514, 61.26128800000001],
          [35.381966, 61.26911999999999],
          [35.395123, 61.27759600000002],
          [35.39357, 61.282444],
          [35.38377, 61.27960999999999],
          [35.37761, 61.27795800000001],
          [35.37362, 61.277466000000004],
          [35.36981, 61.27776299999999],
          [35.365578, 61.278666999999984],
          [35.361847, 61.280212000000006],
          [35.35806, 61.282523999999995],
          [35.353516, 61.28568000000001],
          [35.33116, 61.277739999999994],
          [35.333675, 61.27064999999999],
          [35.29826, 61.25206800000001],
          [35.270744, 61.250282],
          [35.226517, 61.24801599999998],
          [35.21673, 61.25596999999999],
          [35.215397, 61.255707],
          [35.208683, 61.25783000000004],
          [35.20062, 61.25948699999998],
          [35.197903, 61.25873999999999],
          [35.198288, 61.25819800000002],
          [35.198345, 61.257720000000006],
          [35.19804, 61.25756000000004],
          [35.197495, 61.257385],
          [35.19705, 61.257126],
          [35.19653, 61.25639000000001],
          [35.19612, 61.25613400000003],
          [35.195, 61.25572199999999],
          [35.194054, 61.25493],
          [35.19353, 61.25434999999999],
          [35.19266, 61.25315499999999],
          [35.19177, 61.25238999999999],
          [35.19136, 61.25217000000001],
          [35.191177, 61.25197600000004],
          [35.190998, 61.25164799999999],
          [35.1909, 61.251229999999964],
          [35.190544, 61.250910000000005],
          [35.189972, 61.25070600000001],
          [35.189552, 61.250373999999994],
          [35.189407, 61.24992399999999],
          [35.189323, 61.249645000000015],
          [35.189144, 61.24942999999999],
          [35.18878, 61.249054],
          [35.18821, 61.24882500000004],
          [35.187916, 61.248639999999995],
          [35.18752, 61.248013000000014],
          [35.187317, 61.247337000000016],
          [35.187088, 61.24705499999999],
          [35.186234, 61.2466],
          [35.185623, 61.246219999999994],
          [35.185177, 61.24579600000001],
          [35.184143, 61.245056000000005],
          [35.18241, 61.24368300000003],
          [35.180202, 61.24218799999997],
          [35.178535, 61.24095],
          [35.17678, 61.23998],
          [35.175293, 61.239326000000005],
          [35.17241, 61.238895000000014],
          [35.17151, 61.238550000000004],
          [35.16998, 61.23824300000001],
          [35.168674, 61.23841999999999],
          [35.167908, 61.238766],
          [35.16705, 61.23945599999999],
          [35.16606, 61.23998],
          [35.16507, 61.24049800000003],
          [35.164017, 61.24090000000001],
          [35.16288, 61.24116000000001],
          [35.1624, 61.24141700000001],
          [35.161835, 61.24171399999997],
          [35.161327, 61.24185600000001],
          [35.159527, 61.242125999999956],
          [35.15879, 61.24248],
          [35.1583, 61.24253999999999],
          [35.156387, 61.24245000000005],
          [35.15596, 61.242553999999984],
          [35.15541, 61.24287799999999],
          [35.15482, 61.243030000000005],
          [35.15418, 61.24337400000002],
          [35.153015, 61.24322999999998],
          [35.15171, 61.24330499999999],
          [35.1508, 61.243713000000014],
          [35.14879, 61.24468000000002],
          [35.148293, 61.245018000000044],
          [35.148026, 61.245407],
          [35.14769, 61.24558999999999],
          [35.1471, 61.245667],
          [35.14688, 61.245766],
          [35.146797, 61.24592599999997],
          [35.147053, 61.24619700000002],
          [35.14882, 61.24719999999999],
          [35.14903, 61.247359999999986],
          [35.148987, 61.24748600000001],
          [35.148235, 61.24807999999999],
          [35.147797, 61.248356],
          [35.147373, 61.24841699999999],
          [35.1471, 61.248400000000004],
          [35.14676, 61.24829],
          [35.14628, 61.24821499999999],
          [35.145847, 61.248287000000005],
          [35.145386, 61.24857299999999],
          [35.144947, 61.248729999999995],
          [35.14451, 61.24874],
          [35.14411, 61.248774999999995],
          [35.143932, 61.24886699999999],
          [35.14382, 61.24916999999999],
          [35.143726, 61.24931299999997],
          [35.143517, 61.249386000000015],
          [35.143265, 61.249423999999976],
          [35.143192, 61.24953499999998],
          [35.143257, 61.249615000000006],
          [35.14356, 61.24970999999999],
          [35.143627, 61.249798],
          [35.143578, 61.24988999999999],
          [35.14311, 61.250102999999996],
          [35.142525, 61.250369999999975],
          [35.141705, 61.250670000000014],
          [35.14057, 61.250854000000004],
          [35.140083, 61.25086999999999],
          [35.139496, 61.250797000000006],
          [35.139107, 61.250797000000006],
          [35.13856, 61.25092000000001],
          [35.138206, 61.2509],
          [35.137794, 61.25072],
          [35.13745, 61.25053],
          [35.136883, 61.25043500000001],
          [35.13632, 61.250416],
          [35.135838, 61.250545999999986],
          [35.135677, 61.25072499999999],
          [35.135647, 61.25085999999999],
          [35.135605, 61.251045000000005],
          [35.135418, 61.25115600000001],
          [35.134983, 61.251217],
          [35.13415, 61.25116700000004],
          [35.13355, 61.25097299999999],
          [35.133026, 61.250805000000014],
          [35.13262, 61.250819999999976],
          [35.132504, 61.250910000000005],
          [35.132633, 61.251057],
          [35.132977, 61.25119799999999],
          [35.132977, 61.25130999999999],
          [35.132877, 61.25139999999999],
          [35.132538, 61.251513999999986],
          [35.132065, 61.251705000000015],
          [35.13133, 61.251743000000005],
          [35.13081, 61.251778],
          [35.13054, 61.251930000000016],
          [35.130413, 61.252200000000016],
          [35.13029, 61.25241],
          [35.130085, 61.252556],
          [35.12977, 61.252624999999995],
          [35.129208, 61.252655000000004],
          [35.12868, 61.252846000000005],
          [35.127884, 61.25328400000001],
          [35.12752, 61.25344999999999],
          [35.12726, 61.25346400000001],
          [35.12701, 61.25335999999999],
          [35.12684, 61.253142999999994],
          [35.126667, 61.25302500000001],
          [35.12624, 61.253017],
          [35.125668, 61.25308000000001],
          [35.125137, 61.25322299999996],
          [35.12483, 61.253254],
          [35.124268, 61.253074999999995],
          [35.12377, 61.25300200000001],
          [35.12345, 61.25286],
          [35.12316, 61.25274999999999],
          [35.122673, 61.25274999999999],
          [35.122406, 61.25287000000003],
          [35.122044, 61.253089999999986],
          [35.121323, 61.25326000000001],
          [35.120014, 61.25328999999999],
          [35.119263, 61.253174],
          [35.11903, 61.25300999999999],
          [35.118908, 61.252887999999984],
          [35.1187, 61.252846000000005],
          [35.118233, 61.25289000000001],
          [35.11749, 61.253185],
          [35.116756, 61.25335000000001],
          [35.116184, 61.25345199999998],
          [35.115902, 61.253647],
          [35.11561, 61.25398999999999],
          [35.115128, 61.25413999999998],
          [35.11483, 61.25412399999999],
          [35.11405, 61.253918],
          [35.113506, 61.25388000000001],
          [35.11299, 61.253919999999994],
          [35.1123, 61.25379000000001],
          [35.111805, 61.25372699999997],
          [35.111313, 61.25376000000003],
          [35.110355, 61.25408999999999],
          [35.10935, 61.25446299999999],
          [35.10809, 61.255043],
          [35.10741, 61.255527],
          [35.106808, 61.25594000000001],
          [35.106113, 61.25612000000001],
          [35.10588, 61.25612600000002],
          [35.105705, 61.256046],
          [35.10572, 61.255679999999984],
          [35.105587, 61.255139999999955],
          [35.105114, 61.254692000000006],
          [35.103878, 61.25347500000001],
          [35.10286, 61.25322700000001],
          [35.10236, 61.253197],
          [35.102222, 61.25328400000001],
          [35.102238, 61.25347500000001],
          [35.102234, 61.25361000000001],
          [35.101994, 61.25365399999998],
          [35.101116, 61.25351699999999],
          [35.100754, 61.25353200000001],
          [35.100533, 61.25362799999999],
          [35.100426, 61.25386399999999],
          [35.100296, 61.254047000000014],
          [35.099876, 61.254220000000004],
          [35.099667, 61.25419600000001],
          [35.099537, 61.25413],
          [35.09958, 61.254047000000014],
          [35.09975, 61.25392500000001],
          [35.09977, 61.25376499999999],
          [35.099583, 61.253613],
          [35.09957, 61.25342599999999],
          [35.09972, 61.253269999999986],
          [35.099983, 61.253029999999995],
          [35.10009, 61.25280000000001],
          [35.100307, 61.25253000000001],
          [35.100933, 61.25211699999997],
          [35.10121, 61.25187],
          [35.101273, 61.251587],
          [35.101456, 61.25127399999997],
          [35.101795, 61.25100000000003],
          [35.10203, 61.25079299999999],
          [35.10218, 61.25050400000001],
          [35.102425, 61.25023999999999],
          [35.10254, 61.25000399999999],
          [35.10253, 61.249629999999996],
          [35.102364, 61.249435000000005],
          [35.10212, 61.249349999999964],
          [35.101704, 61.249325],
          [35.101437, 61.24922599999999],
          [35.10129, 61.24909200000002],
          [35.101406, 61.248928000000035],
          [35.101665, 61.248650000000026],
          [35.101738, 61.24838],
          [35.10161, 61.24812],
          [35.100803, 61.247566000000006],
          [35.1005, 61.247467],
          [35.10024, 61.24752399999997],
          [35.099815, 61.24791699999997],
          [35.099537, 61.24818400000001],
          [35.09921, 61.248294999999985],
          [35.098923, 61.248294999999985],
          [35.09862, 61.24813499999999],
          [35.098175, 61.24790200000001],
          [35.0978, 61.24776],
          [35.09728, 61.24770699999999],
          [35.09648, 61.24754999999999],
          [35.095848, 61.24741],
          [35.095436, 61.24727200000001],
          [35.09524, 61.247105000000005],
          [35.095314, 61.246914000000004],
          [35.095146, 61.24675400000001],
          [35.09441, 61.24642],
          [35.093983, 61.24622700000003],
          [35.093933, 61.24609400000003],
          [35.094063, 61.24591399999997],
          [35.094063, 61.24580399999999],
          [35.09382, 61.245720000000006],
          [35.093346, 61.245709999999974],
          [35.0931, 61.24561700000001],
          [35.09294, 61.245479999999986],
          [35.092617, 61.24540300000001],
          [35.092503, 61.245247000000006],
          [35.092625, 61.24510000000001],
          [35.093113, 61.24494600000003],
          [35.093327, 61.244823],
          [35.093388, 61.244601999999986],
          [35.092903, 61.24429700000002],
          [35.092728, 61.24407599999998],
          [35.09261, 61.24382800000001],
          [35.09232, 61.24360999999999],
          [35.091503, 61.24335500000001],
          [35.090885, 61.243179999999995],
          [35.09026, 61.24281300000001],
          [35.089596, 61.242670000000004],
          [35.089252, 61.242615],
          [35.088985, 61.242508000000015],
          [35.0888, 61.24219500000001],
          [35.088062, 61.242092000000014],
          [35.087467, 61.24193199999999],
          [35.08653, 61.24197800000002],
          [35.08606, 61.242092000000014],
          [35.08575, 61.242317000000014],
          [35.085785, 61.24256500000004],
          [35.08609, 61.242725000000036],
          [35.086697, 61.242831999999964],
          [35.087254, 61.24295999999998],
          [35.08787, 61.24333200000004],
          [35.08803, 61.24363299999999],
          [35.087894, 61.24394600000002],
          [35.08749, 61.244110000000006],
          [35.087086, 61.24413699999997],
          [35.0869, 61.244095000000016],
          [35.086647, 61.24403000000001],
          [35.08626, 61.24405300000001],
          [35.0859, 61.244102],
          [35.0855, 61.244376999999986],
          [35.085007, 61.24459000000002],
          [35.084427, 61.24471299999999],
          [35.083908, 61.24471700000001],
          [35.082554, 61.2448],
          [35.08208, 61.24494600000003],
          [35.08198, 61.24506400000001],
          [35.081966, 61.245149999999995],
          [35.08194, 61.245273999999995],
          [35.08186, 61.245384],
          [35.081627, 61.245586],
          [35.08126, 61.24579],
          [35.081047, 61.245945000000006],
          [35.08104, 61.246185000000025],
          [35.081223, 61.24633399999999],
          [35.081367, 61.2466],
          [35.081623, 61.24698599999999],
          [35.08189, 61.24733999999998],
          [35.082413, 61.247692],
          [35.082798, 61.24802],
          [35.08313, 61.24839],
          [35.08323, 61.24862000000002],
          [35.083847, 61.24901199999999],
          [35.084095, 61.249089999999995],
          [35.08441, 61.24915300000001],
          [35.084736, 61.24916000000002],
          [35.0851, 61.249138000000016],
          [35.085724, 61.24904000000001],
          [35.086426, 61.24891999999997],
          [35.08668, 61.24891999999997],
          [35.086906, 61.248974000000004],
          [35.086937, 61.249054],
          [35.086872, 61.24914000000001],
          [35.086685, 61.24925999999999],
          [35.086617, 61.249348],
          [35.08667, 61.249454000000014],
          [35.086807, 61.24956],
          [35.0868, 61.249652999999995],
          [35.08673, 61.249752],
          [35.0865, 61.24992],
          [35.08641, 61.250088000000005],
          [35.086468, 61.250393],
          [35.08663, 61.25071700000004],
          [35.08684, 61.25092299999997],
          [35.08695, 61.25098800000001],
          [35.08716, 61.251059999999995],
          [35.087345, 61.251059999999995],
          [35.087494, 61.251007000000016],
          [35.087654, 61.250946],
          [35.087906, 61.25092299999997],
          [35.0882, 61.25095000000002],
          [35.088478, 61.25103999999999],
          [35.088634, 61.25107],
          [35.08878, 61.251063999999985],
          [35.08892, 61.25101000000001],
          [35.08909, 61.250940000000014],
          [35.089233, 61.25092299999997],
          [35.08947, 61.250977000000006],
          [35.089672, 61.251082999999994],
          [35.089787, 61.25117],
          [35.089886, 61.25134299999999],
          [35.089886, 61.25154500000002],
          [35.08977, 61.25184200000001],
          [35.089615, 61.25208000000001],
          [35.089367, 61.25224299999999],
          [35.089108, 61.252399999999966],
          [35.08894, 61.25255200000001],
          [35.088768, 61.25272799999999],
          [35.08865, 61.252759999999995],
          [35.08853, 61.252747],
          [35.088398, 61.252692999999994],
          [35.088245, 61.252646999999996],
          [35.088127, 61.252646999999996],
          [35.087944, 61.25269700000001],
          [35.087555, 61.252853000000016],
          [35.087128, 61.253006],
          [35.086784, 61.253082000000006],
          [35.08645, 61.25310000000002],
          [35.08599, 61.253174],
          [35.08534, 61.253376],
          [35.084976, 61.253506000000016],
          [35.084206, 61.25379000000001],
          [35.083767, 61.254024999999984],
          [35.08317, 61.25438700000004],
          [35.082874, 61.254689999999954],
          [35.082664, 61.25502999999998],
          [35.082375, 61.25542999999996],
          [35.082287, 61.255679999999984],
          [35.082027, 61.25599700000001],
          [35.08155, 61.256298000000015],
          [35.08097, 61.256564999999995],
          [35.08051, 61.25679400000001],
          [35.080032, 61.25708800000001],
          [35.079758, 61.257374],
          [35.07937, 61.25766999999999],
          [35.0789, 61.25806],
          [35.078808, 61.25819999999999],
          [35.078793, 61.25837999999999],
          [35.07905, 61.258533],
          [35.079697, 61.25895700000001],
          [35.07986, 61.25915499999999],
          [35.07963, 61.25941000000003],
          [35.079403, 61.259597999999954],
          [35.079094, 61.25969700000002],
          [35.078777, 61.25974700000003],
          [35.0785, 61.25985299999999],
          [35.078186, 61.26006699999999],
          [35.077873, 61.26030699999998],
          [35.077595, 61.26051000000001],
          [35.0774, 61.260639999999995],
          [35.07737, 61.260727],
          [35.077446, 61.260784],
          [35.077724, 61.260853],
          [35.077896, 61.26093],
          [35.078056, 61.261059999999986],
          [35.078247, 61.26115999999999],
          [35.078487, 61.26122299999997],
          [35.078823, 61.26126500000001],
          [35.079018, 61.261337],
          [35.079227, 61.26142999999999],
          [35.07936, 61.26146299999999],
          [35.079514, 61.261585000000025],
          [35.07956, 61.26169199999998],
          [35.079502, 61.26177000000004],
          [35.07936, 61.261787],
          [35.0791, 61.26176000000004],
          [35.07894, 61.261795000000006],
          [35.078743, 61.261893999999984],
          [35.078568, 61.26192500000002],
          [35.078335, 61.26191299999999],
          [35.078224, 61.26194799999996],
          [35.078182, 61.26202000000001],
          [35.078243, 61.26210800000001],
          [35.07827, 61.26218800000001],
          [35.078068, 61.262527000000034],
          [35.077732, 61.26304999999999],
          [35.070923, 61.26314500000001],
          [35.051517, 61.26357999999999],
          [35.03226, 61.26403399999998],
          [34.974754, 61.265559999999994],
          [34.95251, 61.26612499999999],
          [34.941547, 61.266434000000004],
          [34.904263, 61.282332999999994],
          [34.873806, 61.287758],
          [34.873505, 61.287037],
          [34.79351, 61.28933699999996],
          [34.793274, 61.28958],
          [34.79195, 61.2912],
          [34.78986, 61.293003],
          [34.789547, 61.29486000000003],
          [34.78931, 61.296402],
          [34.788334, 61.29693],
          [34.786617, 61.29706199999998],
          [34.785072, 61.296944000000025],
          [34.784065, 61.2971],
          [34.783188, 61.29786300000001],
          [34.782837, 61.299350000000004],
          [34.78045, 61.29902299999998],
          [34.76983, 61.308907000000005],
          [34.76896, 61.31227999999999],
          [34.736454, 61.31258399999999],
          [34.73626, 61.30491000000001],
          [34.744865, 61.301860000000005],
          [34.7489, 61.29715299999998],
          [34.728916, 61.29580300000001],
          [34.656296, 61.27465999999998],
          [34.58342, 61.275300000000016],
          [34.545723, 61.275634999999994],
          [34.541016, 61.261420000000015],
          [34.533268, 61.26033000000001],
          [34.51374, 61.244583000000006],
          [34.506203, 61.24271400000001],
          [34.502064, 61.242729999999995],
          [34.50197, 61.242603],
          [34.501823, 61.24252000000001],
          [34.501457, 61.242424],
          [34.50097, 61.24233000000001],
          [34.500427, 61.242267999999996],
          [34.499527, 61.242249999999984],
          [34.49911, 61.24221399999999],
          [34.498005, 61.242050000000006],
          [34.49786, 61.241997000000026],
          [34.497772, 61.24192999999997],
          [34.497795, 61.241836999999975],
          [34.49788, 61.241646],
          [34.49782, 61.241559999999964],
          [34.497707, 61.24150500000002],
          [34.49721, 61.24141700000001],
          [34.4969, 61.241386000000006],
          [34.496304, 61.241344],
          [34.495567, 61.24130199999999],
          [34.495438, 61.24128300000001],
          [34.495396, 61.24125000000001],
          [34.495426, 61.241202999999985],
          [34.495598, 61.241074000000026],
          [34.49563, 61.24101999999999],
          [34.4956, 61.24093199999996],
          [34.495537, 61.24087500000002],
          [34.49543, 61.24083300000001],
          [34.49529, 61.240803],
          [34.49507, 61.24072600000002],
          [34.49488, 61.2406],
          [34.49476, 61.240463000000005],
          [34.49459, 61.24024600000001],
          [34.49434, 61.240047000000004],
          [34.49412, 61.23988300000002],
          [34.4939, 61.239780000000025],
          [34.493614, 61.23970800000001],
          [34.493256, 61.23967999999999],
          [34.4928, 61.23971],
          [34.49251, 61.23972299999997],
          [34.492306, 61.239720000000005],
          [34.49185, 61.23961299999999],
          [34.491253, 61.23947000000001],
          [34.491123, 61.23940300000001],
          [34.49108, 61.239304000000004],
          [34.49118, 61.23921999999999],
          [34.4914, 61.23918499999999],
          [34.491756, 61.239154999999954],
          [34.492096, 61.239056000000005],
          [34.492443, 61.23886999999999],
          [34.492588, 61.238686],
          [34.49256, 61.23850300000001],
          [34.4924, 61.238358000000005],
          [34.492035, 61.23815499999998],
          [34.491768, 61.23799500000001],
          [34.491676, 61.237904000000015],
          [34.491512, 61.23779999999999],
          [34.49134, 61.23777999999999],
          [34.491135, 61.23782700000001],
          [34.490955, 61.23783499999996],
          [34.49086, 61.23779300000001],
          [34.49065, 61.23774],
          [34.49045, 61.237750000000034],
          [34.490284, 61.23782700000001],
          [34.49015, 61.237854],
          [34.489902, 61.237840000000006],
          [34.48949, 61.237719999999996],
          [34.489143, 61.23753399999998],
          [34.489006, 61.23748399999997],
          [34.488888, 61.23748000000003],
          [34.48879, 61.23752999999999],
          [34.48876, 61.237599999999986],
          [34.48866, 61.237629999999996],
          [34.48854, 61.237606],
          [34.488422, 61.23753399999998],
          [34.48822, 61.23748399999997],
          [34.487934, 61.23744199999999],
          [34.48775, 61.23746000000003],
          [34.487648, 61.23752000000002],
          [34.487568, 61.23762500000001],
          [34.487514, 61.237656000000015],
          [34.48739, 61.237663],
          [34.48646, 61.23748000000003],
          [34.48602, 61.23738],
          [34.48536, 61.23725999999999],
          [34.485146, 61.237246999999996],
          [34.484974, 61.23729700000001],
          [34.48482, 61.23734999999999],
          [34.484646, 61.23734999999999],
          [34.484467, 61.23732799999999],
          [34.484356, 61.23726999999997],
          [34.484215, 61.23718600000001],
          [34.484062, 61.23716400000001],
          [34.483932, 61.23719000000003],
          [34.48386, 61.23725999999999],
          [34.483772, 61.23752200000004],
          [34.483807, 61.23777999999999],
          [34.483803, 61.23801399999999],
          [34.483757, 61.238125],
          [34.483658, 61.238167000000004],
          [34.483475, 61.238167000000004],
          [34.482822, 61.23805200000001],
          [34.482655, 61.237956999999994],
          [34.4825, 61.237724000000014],
          [34.482395, 61.237415],
          [34.482277, 61.23711],
          [34.482296, 61.236954],
          [34.482273, 61.23681600000003],
          [34.482117, 61.236740000000026],
          [34.48182, 61.236706],
          [34.481327, 61.23671999999999],
          [34.48056, 61.23673200000002],
          [34.48006, 61.23677000000001],
          [34.479794, 61.23684299999999],
          [34.47957, 61.23692700000004],
          [34.47941, 61.236973000000006],
          [34.47918, 61.236976999999996],
          [34.478977, 61.23695000000001],
          [34.47891, 61.236908],
          [34.478825, 61.236717],
          [34.478638, 61.236492],
          [34.47838, 61.236320000000006],
          [34.478092, 61.236244],
          [34.477753, 61.236164],
          [34.477554, 61.236103000000014],
          [34.477345, 61.235979999999984],
          [34.477207, 61.235839999999996],
          [34.477062, 61.235569999999996],
          [34.476845, 61.23531700000001],
          [34.476593, 61.234955000000014],
          [34.476173, 61.23455999999999],
          [34.47588, 61.234398],
          [34.47556, 61.23430300000001],
          [34.475063, 61.234219999999965],
          [34.47496, 61.234104],
          [34.474915, 61.233990000000006],
          [34.474834, 61.23390199999997],
          [34.47475, 61.23383999999999],
          [34.47449, 61.23370700000001],
          [34.474434, 61.233660000000015],
          [34.474426, 61.233611999999994],
          [34.47447, 61.233540000000005],
          [34.474525, 61.23333400000001],
          [34.474552, 61.23328999999998],
          [34.474594, 61.233273],
          [34.474663, 61.233270000000005],
          [34.474747, 61.233295],
          [34.474873, 61.23333400000001],
          [34.47498, 61.233337000000006],
          [34.47508, 61.23331000000002],
          [34.47516, 61.23325700000001],
          [34.47518, 61.233204],
          [34.475147, 61.233149999999995],
          [34.4751, 61.233124000000004],
          [34.475014, 61.233119999999985],
          [34.474876, 61.233147],
          [34.474808, 61.23315400000001],
          [34.474754, 61.23313999999999],
          [34.47472, 61.233124000000004],
          [34.474724, 61.23309000000003],
          [34.474735, 61.23304999999996],
          [34.474705, 61.233000000000004],
          [34.47462, 61.232939999999985],
          [34.474636, 61.232887000000005],
          [34.474678, 61.23286000000002],
          [34.47476, 61.23282599999999],
          [34.47478, 61.23274599999999],
          [34.474747, 61.232708],
          [34.4746, 61.232662000000005],
          [34.474384, 61.232642999999996],
          [34.473644, 61.22696300000001],
          [34.47351, 61.226826000000045],
          [34.473522, 61.22673800000001],
          [34.473473, 61.22661600000001],
          [34.473396, 61.226432999999986],
          [34.473366, 61.22632599999997],
          [34.47322, 61.226005999999984],
          [34.4732, 61.22592],
          [34.473225, 61.22582000000003],
          [34.47335, 61.225719999999995],
          [34.473724, 61.225480000000005],
          [34.473877, 61.22537],
          [34.47407, 61.22520399999999],
          [34.474094, 61.22513599999999],
          [34.474033, 61.225066999999996],
          [34.473965, 61.225036999999986],
          [34.47392, 61.22500600000001],
          [34.473907, 61.22496799999999],
          [34.47393, 61.224940000000004],
          [34.47401, 61.22491500000001],
          [34.47407, 61.224884],
          [34.4741, 61.224826999999976],
          [34.47407, 61.22477000000001],
          [34.474087, 61.22472400000001],
          [34.474117, 61.22466700000001],
          [34.474094, 61.224616999999995],
          [34.474022, 61.224575000000016],
          [34.47392, 61.224519999999984],
          [34.473858, 61.224457],
          [34.47383, 61.224391999999995],
          [34.47382, 61.224358000000024],
          [34.473778, 61.22434000000001],
          [34.473717, 61.22433000000001],
          [34.473656, 61.22433000000001],
          [34.4736, 61.22432700000002],
          [34.473553, 61.22430800000001],
          [34.47353, 61.224277],
          [34.47355, 61.22424000000001],
          [34.47361, 61.22421299999999],
          [34.473698, 61.224197000000004],
          [34.4738, 61.224199999999996],
          [34.47386, 61.224181999999985],
          [34.4739, 61.224159999999955],
          [34.473938, 61.22409999999999],
          [34.473953, 61.22404],
          [34.473938, 61.22401399999998],
          [34.473885, 61.22398799999999],
          [34.47378, 61.223960000000034],
          [34.473747, 61.223907],
          [34.47374, 61.22383500000001],
          [34.473717, 61.22377399999999],
          [34.47361, 61.223713000000004],
          [34.473457, 61.22364999999999],
          [34.473366, 61.22360599999999],
          [34.473354, 61.22356400000001],
          [34.473373, 61.22354999999999],
          [34.473503, 61.223454000000004],
          [34.473568, 61.223389999999995],
          [34.473587, 61.223360000000014],
          [34.473576, 61.22332800000001],
          [34.47348, 61.22326699999999],
          [34.473415, 61.22324000000003],
          [34.473186, 61.22318000000001],
          [34.473145, 61.223164],
          [34.47309, 61.223119999999994],
          [34.473103, 61.22309999999999],
          [34.473152, 61.22307599999999],
          [34.47324, 61.223072],
          [34.473305, 61.223083],
          [34.47334, 61.22307999999998],
          [34.473366, 61.22307000000001],
          [34.473434, 61.22302600000003],
          [34.47351, 61.223003000000006],
          [34.473595, 61.22301999999999],
          [34.473663, 61.22304],
          [34.47372, 61.22304500000001],
          [34.473774, 61.22302600000003],
          [34.473812, 61.22298000000001],
          [34.473812, 61.222920000000016],
          [34.47386, 61.22287799999998],
          [34.473946, 61.22285500000001],
          [34.47399, 61.222836],
          [34.47402, 61.222804999999994],
          [34.474277, 61.22272000000001],
          [34.478264, 61.21888999999999],
          [34.47035, 61.214045999999996],
          [34.464718, 61.208065000000005],
          [34.48297, 61.20751000000001],
          [34.49123, 61.199592999999965],
          [34.473026, 61.184470000000005],
          [34.468956, 61.16127999999998],
          [34.458145, 61.15896599999999],
          [34.457886, 61.15902],
          [34.457714, 61.15899300000001],
          [34.457558, 61.15893599999998],
          [34.457317, 61.15885000000003],
          [34.457092, 61.15882000000002],
          [34.456867, 61.15880599999997],
          [34.45649, 61.15872000000002],
          [34.45605, 61.15859599999999],
          [34.455524, 61.158416999999986],
          [34.454857, 61.158165],
          [34.454594, 61.15807000000001],
          [34.454403, 61.15796000000003],
          [34.454075, 61.15782500000003],
          [34.453873, 61.157786999999985],
          [34.45368, 61.157779999999974],
          [34.453445, 61.15782999999999],
          [34.453285, 61.15786],
          [34.453262, 61.15783999999999],
          [34.453186, 61.15782999999999],
          [34.45307, 61.157814],
          [34.452892, 61.157803],
          [34.45276, 61.157771999999994],
          [34.45269, 61.157734000000005],
          [34.452656, 61.157670000000024],
          [34.452614, 61.15762000000001],
          [34.452538, 61.15759299999999],
          [34.45247, 61.15757400000001],
          [34.452427, 61.15753000000004],
          [34.452457, 61.157460000000015],
          [34.45251, 61.15742499999999],
          [34.452564, 61.15735999999998],
          [34.452583, 61.15727599999997],
          [34.452564, 61.15720400000001],
          [34.452503, 61.15715399999999],
          [34.452408, 61.15711999999999],
          [34.452362, 61.15712400000001],
          [34.452335, 61.15715],
          [34.45229, 61.15717999999998],
          [34.452217, 61.15717999999998],
          [34.452057, 61.15717000000001],
          [34.45192, 61.15714299999999],
          [34.45182, 61.157116],
          [34.4516, 61.157055000000014],
          [34.451477, 61.157013000000006],
          [34.45144, 61.15697499999999],
          [34.451385, 61.15693300000004],
          [34.451298, 61.156902],
          [34.451237, 61.156863999999985],
          [34.451183, 61.156814999999995],
          [34.451157, 61.15676500000001],
          [34.451168, 61.15672299999997],
          [34.451214, 61.15669],
          [34.45122, 61.156649999999985],
          [34.45119, 61.15662800000001],
          [34.45118, 61.15660500000001],
          [34.451195, 61.156586000000004],
          [34.451183, 61.15656999999999],
          [34.451218, 61.15654399999997],
          [34.45127, 61.15651],
          [34.451275, 61.15646699999999],
          [34.45122, 61.15642],
          [34.451202, 61.156352999999996],
          [34.451225, 61.156284],
          [34.451275, 61.15617800000001],
          [34.451267, 61.15610000000001],
          [34.451225, 61.156036],
          [34.451145, 61.155956],
          [34.450985, 61.15581499999999],
          [34.45091, 61.155730000000005],
          [34.450806, 61.155647000000016],
          [34.450836, 61.155643],
          [34.450855, 61.155632],
          [34.45086, 61.15561300000002],
          [34.450832, 61.15555599999999],
          [34.450813, 61.155500000000046],
          [34.450825, 61.15539000000004],
          [34.450928, 61.155242999999984],
          [34.45095, 61.155144000000035],
          [34.450966, 61.155024999999966],
          [34.45095, 61.15491499999999],
          [34.450905, 61.15482],
          [34.450825, 61.154740000000004],
          [34.45069, 61.15463600000001],
          [34.45063, 61.154551999999995],
          [34.4506, 61.15445299999999],
          [34.450565, 61.154324],
          [34.450558, 61.154210000000006],
          [34.450565, 61.15410600000001],
          [34.450512, 61.15398800000003],
          [34.450394, 61.153885],
          [34.45022, 61.15377999999998],
          [34.450096, 61.153666999999984],
          [34.450012, 61.15355299999999],
          [34.449955, 61.153430000000014],
          [34.44994, 61.153392999999994],
          [34.449905, 61.153377999999975],
          [34.449986, 61.153186999999974],
          [34.45001, 61.152980000000014],
          [34.449966, 61.15268999999998],
          [34.449894, 61.15248],
          [34.44974, 61.152266999999995],
          [34.44954, 61.152084],
          [34.449364, 61.151966000000044],
          [34.448944, 61.151756000000006],
          [34.444347, 61.14373999999998],
          [34.42507, 61.14326],
          [34.409107, 61.147925999999984],
          [34.409138, 61.14887999999996],
          [34.409225, 61.15093000000002],
          [34.40906, 61.15190999999999],
          [34.408726, 61.15273000000002],
          [34.40841, 61.153340000000014],
          [34.40807, 61.15409],
          [34.40799, 61.15517],
          [34.408405, 61.159490000000005],
          [34.40585, 61.160908000000006],
          [34.390957, 61.17712],
          [34.38856, 61.179782999999986],
          [34.38636, 61.18211400000001],
          [34.381607, 61.187219999999996],
          [34.374752, 61.19397699999999],
          [34.359776, 61.194782000000004],
          [34.33669, 61.22017699999998],
          [34.30724, 61.222668],
          [34.282654, 61.22867200000002],
          [34.257442, 61.23504],
          [34.25412, 61.23832699999997],
          [34.244507, 61.237267],
          [34.237984, 61.243954],
          [34.21423, 61.243942000000004],
          [34.212646, 61.243926999999985],
          [34.20395, 61.243859999999984],
          [34.187508, 61.24381299999999],
          [34.188908, 61.235405000000014],
          [34.16158, 61.234905],
          [34.140747, 61.23478],
          [34.12464, 61.23470000000003],
          [34.1045, 61.23453500000002],
          [34.096073, 61.234474000000034],
          [34.0906, 61.23437000000001],
          [34.08185, 61.23424],
          [34.076366, 61.23424],
          [34.05231, 61.23407],
          [34.04248, 61.23398000000003],
          [34.03223, 61.233959999999996],
          [34.00589, 61.23383000000001],
          [33.97717, 61.23374000000001],
          [33.948288, 61.23367300000001],
          [33.881245, 61.23353],
          [33.872215, 61.233499999999964],
          [33.821354, 61.23324600000001],
          [33.74453, 61.23328999999998],
          [33.669456, 61.23320799999999],
          [33.67001, 61.21538000000001],
          [33.60915, 61.22017699999998],
          [33.608944, 61.219982000000044],
          [33.608784, 61.219707],
          [33.60877, 61.219480000000004],
          [33.608654, 61.21920800000001],
          [33.608418, 61.218964],
          [33.607925, 61.21856699999998],
          [33.607674, 61.218320000000006],
          [33.607517, 61.218132],
          [33.607525, 61.217963999999995],
          [33.607365, 61.21753000000001],
          [33.607025, 61.216904],
          [33.60689, 61.21678499999999],
          [33.606636, 61.216680000000025],
          [33.60647, 61.21663000000001],
          [33.606224, 61.216470000000015],
          [33.60582, 61.216110000000015],
          [33.60564, 61.21600000000001],
          [33.605526, 61.215903999999995],
          [33.605476, 61.21578199999996],
          [33.605484, 61.21570600000001],
          [33.605324, 61.215576],
          [33.605106, 61.215453999999994],
          [33.604923, 61.215396999999996],
          [33.604733, 61.21536999999998],
          [33.604576, 61.21533600000001],
          [33.604404, 61.21528599999999],
          [33.604088, 61.21517],
          [33.603775, 61.21509600000002],
          [33.603622, 61.21507600000001],
          [33.60335, 61.21508399999999],
          [33.603012, 61.215100000000035],
          [33.602787, 61.215126],
          [33.602642, 61.21519000000001],
          [33.602486, 61.21525],
          [33.602352, 61.21525600000001],
          [33.60229, 61.21522999999999],
          [33.60224, 61.215175999999985],
          [33.60211, 61.21508800000004],
          [33.60152, 61.21498500000001],
          [33.60128, 61.21498500000001],
          [33.60105, 61.21500399999999],
          [33.600895, 61.21505000000002],
          [33.60075, 61.21511000000001],
          [33.60058, 61.21526],
          [33.60044, 61.215442999999965],
          [33.600346, 61.21550400000001],
          [33.600067, 61.215588],
          [33.59995, 61.215621999999996],
          [33.589046, 61.217100000000016],
          [33.575756, 61.218895],
          [33.55178, 61.21368999999996],
          [33.514027, 61.187748],
          [33.50313, 61.18517699999998],
          [33.485043, 61.16657000000001],
          [33.484634, 61.165065999999996],
          [33.457634, 61.16015999999999],
          [33.449905, 61.15460200000001],
          [33.473442, 61.131348],
          [33.483112, 61.12177299999999],
          [33.490112, 61.11497],
          [33.503544, 61.11341999999996],
          [33.507256, 61.11067600000001],
          [33.523643, 61.10960400000002],
          [33.54497, 61.095462999999995],
          [33.545547, 61.091819999999984],
          [33.54755, 61.079684999999955],
          [33.56546, 61.07235299999999],
          [33.565563, 61.072314999999975],
          [33.576218, 61.06824500000002],
          [33.58911, 61.065326999999996],
          [33.591236, 61.063793000000004],
          [33.60024, 61.06593300000003],
          [33.634304, 61.067127],
          [33.643024, 61.07126600000001],
          [33.65269, 61.07178999999999],
          [33.66236, 61.07451600000002],
          [33.66091, 61.096209999999985],
          [33.664547, 61.09686300000001],
          [33.683514, 61.11234300000001],
          [33.685448, 61.113754],
          [33.72537, 61.11336000000003],
          [33.728184, 61.126986999999986],
          [33.73819, 61.124977],
          [33.738037, 61.13102299999997],
          [33.775547, 61.12129199999998],
          [33.835, 61.105164],
          [33.843544, 61.09640999999999],
          [33.86813, 61.09947600000001],
          [33.881054, 61.10156199999997],
          [33.884995, 61.09936000000002],
          [33.888683, 61.09588600000001],
          [33.912254, 61.094569999999976],
          [33.92016, 61.088959999999986],
          [33.919563, 61.073215000000005],
          [33.908302, 61.063113999999985],
          [33.905033, 61.05554599999999],
          [33.8857, 61.047416999999996],
          [33.878777, 61.04129000000003],
          [33.8635, 61.01602],
          [33.865524, 61.012485999999996],
          [33.859207, 61.006416],
          [33.861584, 61.00319999999999],
          [33.855324, 60.99922599999999],
          [33.852222, 60.995532999999995],
          [33.838547, 60.99141700000001],
          [33.83721, 60.99007999999998],
          [33.830112, 60.978043000000014],
          [33.819794, 60.97144700000001],
          [33.81452, 60.96119999999999],
          [33.812088, 60.957897],
          [33.81284, 60.94706000000002],
          [33.78386, 60.94688400000001],
          [33.785423, 60.93703000000002],
          [33.746094, 60.93963600000001],
          [33.743603, 60.94103000000001],
          [33.719547, 60.93975399999999],
          [33.69103, 60.956489999999974],
          [33.607876, 60.958575999999994],
          [33.587967, 60.973804],
          [33.545475, 60.984093],
          [33.542927, 60.99504999999999],
          [33.54243, 61.00432199999997],
          [33.523014, 61.00721999999999],
          [33.5211, 61.00687399999998],
          [33.518143, 61.00542400000003],
          [33.507885, 61.002815],
          [33.505394, 61.002815],
          [33.50122, 61.003918],
          [33.486618, 61.00374000000002],
          [33.44905, 61.003277],
          [33.42169, 60.983135000000004],
          [33.43165, 60.97606999999999],
          [33.466385, 60.951415999999995],
          [33.466503, 60.92759000000001],
          [33.450214, 60.924777999999975],
          [33.44413, 60.923866000000004],
          [33.42622, 60.91389499999997],
          [33.385616, 60.909794000000005],
          [33.3633, 60.90052],
          [33.350403, 60.89790999999997],
          [33.330696, 60.899418],
          [33.30209, 60.88733300000001],
          [33.294003, 60.888026999999994],
          [33.251015, 60.87430599999999],
          [33.259666, 60.86849999999998],
          [33.238506, 60.85415999999998],
          [33.240185, 60.84969700000002],
          [33.23932, 60.847958000000006],
          [33.23659, 60.84697299999999],
          [33.236362, 60.84546700000001],
          [33.234447, 60.84419],
          [33.229523, 60.844074000000006],
          [33.22726, 60.843379999999996],
          [33.22552, 60.84354999999999],
          [33.22419, 60.844539999999995],
          [33.22019, 60.843379999999996],
          [33.21213, 60.84969700000002],
          [33.213116, 60.85201599999999],
          [33.211407, 60.853984999999994],
          [33.2059, 60.854682999999994],
          [33.204624, 60.846855000000005],
          [33.1977, 60.833843],
          [33.209526, 60.83084499999998],
          [33.209118, 60.828407],
          [33.20761, 60.82695999999996],
          [33.204426, 60.82562599999997],
          [33.20083, 60.825222],
          [33.179558, 60.82088999999999],
          [33.184776, 60.81363999999999],
          [33.21109, 60.80618000000001],
          [33.244682, 60.806700000000006],
          [33.25912, 60.798580000000015],
          [33.255024, 60.799499999999995],
          [33.233208, 60.797188000000006],
          [33.189964, 60.792553],
          [33.181656, 60.792429999999996],
          [33.173096, 60.79226299999999],
          [33.165245, 60.78313399999999],
          [33.156086, 60.76626999999999],
          [33.1542, 60.76055999999997],
          [33.157394, 60.748276000000004],
          [33.157684, 60.74547000000001],
          [33.073887, 60.727394000000004],
          [33.07365, 60.726867999999996],
          [33.07285, 60.72630000000001],
          [33.071396, 60.725403],
          [33.070236, 60.72466],
          [33.06872, 60.72426999999999],
          [33.06731, 60.724064],
          [33.026627, 60.72704700000003],
          [32.969906, 60.72161],
          [32.9231, 60.717089999999985],
          [32.915977, 60.68684400000001],
          [32.914696, 60.67957000000001],
          [32.93027725170445, 60.59584992136823],
          [32.85839532073329, 60.5406441326046],
          [32.776117785907864, 60.519755922190114],
          [32.61774092550234, 60.547716985417225],
          [32.57176584712897, 60.501313954603205],
          [32.65757065744552, 60.415696649274],
          [32.623875317660804, 60.34162336705219],
          [32.56946545006955, 60.33623497002384],
          [32.615046174661295, 60.25227484332038],
          [32.51406969802427, 60.17644066717645],
          [32.24231612540424, 60.13296495251859],
          [32.086130119341014, 60.25531304221744],
          [32.038939116807676, 60.21440144155389],
          [31.782258622062734, 60.244338324982095],
          [31.68303482889955, 60.23502405348842],
          [31.530321326968892, 60.142831758819],
          [31.493563687198343, 60.07996319906076],
          [31.545764745366807, 59.97425157227903],
          [31.4698619721726, 59.91764033510009],
          [31.375241288683146, 59.91395758099779],
          [31.2764101494731, 59.95029343002858],
          [31.189838024197822, 59.92549264089806],
          [31.048687508612524, 59.9506591692103],
          [31.111915950811092, 60.019539694539304],
          [31.065328833603406, 60.17369294606317],
          [30.9826377746556, 60.20449974305913],
          [30.79951159336611, 60.48865399802742],
          [30.60853735175389, 60.607503196159485],
          [30.535112159646967, 60.61996976385987],
          [30.540468317604443, 60.71470969966646],
          [30.54311775402726, 60.80922701131206],
          [30.35848783855185, 60.970446783105245],
          [30.18634607745857, 61.07253128767445],
          [29.834829999999997, 61.166949999999986],
          [29.833618, 61.16693499999997],
          [29.8276, 61.16631699999999],
          [29.822900000000004, 61.163532000000004],
          [29.820353, 61.16272000000001],
          [29.814114, 61.16272699999996],
          [29.813212999999998, 61.162323000000015],
          [29.812496, 61.162330000000026],
          [29.811356000000004, 61.16290700000002],
          [29.809371999999996, 61.163917999999995],
          [29.801907, 61.167664],
          [29.79513, 61.171069999999986],
          [29.778260999999997, 61.17088000000001],
          [29.775188, 61.1703],
          [29.767769000000005, 61.170180000000016],
          [29.763945, 61.17093700000001],
          [29.760815, 61.17192000000003],
          [29.754728, 61.17238200000003],
          [29.751423000000003, 61.17238200000003],
          [29.751196, 61.172349999999994],
          [29.74667, 61.17168800000002],
          [29.73715, 61.17241000000001],
          [29.736444, 61.17272],
          [29.733038, 61.172752],
          [29.729948, 61.172912999999994],
          [29.720548999999995, 61.172912999999994],
          [29.717314, 61.17407199999997],
          [29.714228, 61.17518000000001],
          [29.707642000000003, 61.177482999999995],
          [29.696798, 61.17756299999999],
          [29.696175, 61.17735999999999],
          [29.687899000000005, 61.17465200000001],
          [29.676249, 61.174824],
          [29.670881, 61.176323],
          [29.659231, 61.17916499999998],
          [29.650015000000003, 61.17574300000001],
          [29.647116, 61.17742500000003],
          [29.641783000000004, 61.179625999999985],
          [29.63593, 61.18148400000001],
          [29.62996, 61.182410000000004],
          [29.624046, 61.18270000000004],
          [29.618366, 61.182410000000004],
          [29.61883, 61.178177000000005],
          [29.611208, 61.178450000000026],
          [29.59463, 61.17857000000001],
          [29.58292, 61.17799000000002],
          [29.577124, 61.18042399999999],
          [29.573647000000005, 61.181118],
          [29.572197, 61.18169799999998],
          [29.570488000000005, 61.182053000000025],
          [29.569763000000002, 61.18285800000001],
          [29.564198, 61.183670000000035],
          [29.562167999999996, 61.191869999999994],
          [29.570572, 61.19569799999999],
          [29.567385, 61.198245999999955],
          [29.561182, 61.20010400000001],
          [29.561008000000005, 61.202304999999996],
          [29.556950000000004, 61.205956000000015],
          [29.556718999999998, 61.20647999999997],
          [29.556950000000004, 61.207233],
          [29.558168000000002, 61.20845],
          [29.558226000000005, 61.210826999999995],
          [29.558804999999996, 61.211926000000005],
          [29.558342, 61.21248],
          [29.551676000000004, 61.215202000000005],
          [29.551443000000003, 61.21595400000001],
          [29.551676000000004, 61.216305000000006],
          [29.553413, 61.21665200000001],
          [29.557356000000002, 61.21925999999996],
          [29.557703, 61.220534999999984],
          [29.558746, 61.22128699999999],
          [29.563326, 61.221461999999974],
          [29.564716, 61.22192799999999],
          [29.565063, 61.22291000000001],
          [29.563616, 61.224884],
          [29.560612, 61.22696300000001],
          [29.55066, 61.233851999999985],
          [29.550599999999996, 61.23657600000004],
          [29.551412999999997, 61.239532000000025],
          [29.551239000000002, 61.240635],
          [29.549499999999995, 61.241153999999995],
          [29.54753, 61.240925000000004],
          [29.546543, 61.23976499999998],
          [29.546196, 61.23849000000001],
          [29.54469, 61.238550000000004],
          [29.542486, 61.23970800000001],
          [29.540049999999997, 61.240635],
          [29.537037, 61.240925000000004],
          [29.530892999999995, 61.23860499999998],
          [29.523879999999995, 61.238823],
          [29.521214000000004, 61.23893699999999],
          [29.51901, 61.23969299999999],
          [29.517734999999995, 61.24062000000001],
          [29.517214, 61.24339999999998],
          [29.516865, 61.246532],
          [29.515299000000002, 61.24937000000003],
          [29.506924, 61.253179999999986],
          [29.50101, 61.25434000000001],
          [29.489069999999998, 61.255499999999984],
          [29.460000999999995, 61.25462999999999],
          [29.454194999999995, 61.254795],
          [29.453798, 61.25480300000001],
          [29.446321000000005, 61.25596200000004],
          [29.436642, 61.256485],
          [29.424469000000002, 61.256428],
          [29.417976, 61.260166],
          [29.416527, 61.261559999999974],
          [29.414614, 61.26207700000003],
          [29.41131, 61.26190600000001],
          [29.409746, 61.262485999999996],
          [29.407949999999996, 61.262775000000005],
          [29.406036, 61.263701999999995],
          [29.404587000000003, 61.26572999999999],
          [29.388065, 61.27319300000002],
          [29.372126, 61.27869799999999],
          [29.370328999999998, 61.27893],
          [29.36859, 61.27817999999999],
          [29.367140000000003, 61.27789000000001],
          [29.358126, 61.28697199999999],
          [29.344735999999997, 61.297465999999986],
          [29.34143, 61.30151999999998],
          [29.338243, 61.30407300000002],
          [29.335026000000003, 61.30887000000001],
          [29.328592, 61.310608],
          [29.32523, 61.312636999999995],
          [29.323723, 61.314434000000006],
          [29.321025999999996, 61.31805800000001],
          [29.315954, 61.32288],
          [29.305927, 61.32929200000001],
          [29.291037, 61.31533400000001],
          [29.273449999999997, 61.298846999999995],
          [29.265432, 61.291325],
          [29.241812, 61.27624500000002],
          [29.245606999999996, 61.27359999999999],
          [29.244564000000004, 61.27071799999999],
          [29.239989999999995, 61.268010000000004],
          [29.23305, 61.26817],
          [29.228159, 61.264927],
          [29.225485, 61.264842999999956],
          [29.222144999999998, 61.26413299999999],
          [29.220823, 61.26299],
          [29.218311, 61.26238600000002],
          [29.217914999999998, 61.26143300000004],
          [29.214746, 61.26101],
          [29.21256, 61.260211999999996],
          [29.211662000000004, 61.259270000000015],
          [29.210552, 61.25810200000001],
          [29.208887, 61.25737000000001],
          [29.207155, 61.25742300000002],
          [29.204151, 61.256669999999986],
          [29.19916, 61.25608],
          [29.19827, 61.255750000000006],
          [29.197557000000003, 61.25456],
          [29.182943, 61.247253],
          [29.177409999999995, 61.244500000000016],
          [29.177873999999996, 61.243954],
          [29.17771, 61.243483999999995],
          [29.177547, 61.243053],
          [29.177682999999995, 61.24246599999998],
          [29.177331999999996, 61.24182999999999],
          [29.176654999999997, 61.24130600000001],
          [29.175924, 61.24110999999999],
          [29.175030000000003, 61.24104700000001],
          [29.174028, 61.241359999999986],
          [29.17272, 61.242165],
          [29.164096999999998, 61.237464999999986],
          [29.08426, 61.21043800000001],
          [29.029888, 61.191813999999994],
          [29.023085000000002, 61.18858],
          [29.011997000000004, 61.18457399999997],
          [29.011536, 61.18448999999998],
          [29.0111, 61.18433400000001],
          [29.010872, 61.18411600000002],
          [29.0091, 61.183388000000036],
          [29.008495000000003, 61.183243000000004],
          [29.007938000000003, 61.18292600000004],
          [28.985569999999996, 61.17374800000002],
          [28.956774, 61.15162000000001],
          [28.951534, 61.15181999999999],
          [28.944311, 61.148167],
          [28.92666, 61.14591999999999],
          [28.914934, 61.143287999999984],
          [28.902445, 61.14452399999999],
          [28.883473999999996, 61.14237600000004],
          [28.87266, 61.13553600000003],
          [28.849104, 61.12941699999999],
          [28.847942000000003, 61.129253000000006],
          [28.836819000000002, 61.12621999999996],
          [28.836676, 61.126180000000005],
          [28.836426000000003, 61.126114],
          [28.831830000000004, 61.124934999999994],
          [28.828489999999995, 61.12407999999999],
          [28.821087000000002, 61.122185],
          [28.818982999999996, 61.121647000000024],
          [28.81795, 61.11913300000003],
          [28.817670000000003, 61.11865599999999],
          [28.817535000000003, 61.11834999999999],
          [28.816163999999997, 61.115230000000025],
          [28.813704999999995, 61.10964000000001],
          [28.811955999999995, 61.105650000000026],
          [28.81012, 61.10147499999999],
          [28.808579999999996, 61.097970000000004],
          [28.807976, 61.096596000000005],
          [28.804287, 61.093384000000015],
          [28.800909000000004, 61.09259800000001],
          [28.793493, 61.08836699999998],
          [28.777168, 61.07954799999999],
          [28.713799000000005, 61.04459399999999],
          [28.713075999999997, 61.04136],
          [28.712103, 61.03667400000003],
          [28.711184, 61.03223800000001],
          [28.71095, 61.03111599999997],
          [28.71007, 61.02686700000001],
          [28.707146, 61.02146499999998],
          [28.703705000000003, 61.01511399999998],
          [28.701921, 61.011818000000005],
          [28.70016, 61.00855999999999],
          [28.698477, 61.005449999999996],
          [28.696402, 61.00161700000001],
          [28.69408, 60.99732599999999],
          [28.692032, 60.993539999999996],
          [28.690909999999995, 60.991467],
          [28.690964, 60.99049400000001],
          [28.689966, 60.989723],
          [28.687236999999996, 60.98477],
          [28.685172999999995, 60.981025999999986],
          [28.683893000000005, 60.978703000000024],
          [28.680834, 60.97555000000003],
          [28.678135, 60.972767000000005],
          [28.67486, 60.96939499999996],
          [28.67283, 60.967299999999994],
          [28.671345, 60.965689999999995],
          [28.66835, 60.962440000000015],
          [28.665156999999997, 60.958973000000015],
          [28.660337, 60.95374300000003],
          [28.657896, 60.95109600000001],
          [28.655474, 60.94952999999998],
          [28.649204, 60.94812399999998],
          [28.636314, 60.95083600000001],
          [28.621737, 60.953900000000004],
          [28.61482, 60.955353],
          [28.608719, 60.954823000000005],
          [28.575261999999995, 60.95191600000001],
          [28.572853, 60.951706],
          [28.55626, 60.95433],
          [28.552426999999998, 60.954937],
          [28.545324000000004, 60.955273000000005],
          [28.542465, 60.95603],
          [28.5421, 60.95603],
          [28.537323, 60.956455000000005],
          [28.529957, 60.95475999999999],
          [28.527426, 60.954926],
          [28.52467, 60.957137999999986],
          [28.521385, 60.954905999999994],
          [28.521242, 60.95481000000001],
          [28.515171000000002, 60.950683999999995],
          [28.511633000000003, 60.94828000000001],
          [28.505606, 60.94418700000003],
          [28.495424000000003, 60.93726699999999],
          [28.492951999999995, 60.93710300000001],
          [28.492264000000002, 60.935123000000004],
          [28.483822, 60.929332999999986],
          [28.483592999999995, 60.92881800000001],
          [28.482412, 60.927756999999986],
          [28.483624000000002, 60.92680999999996],
          [28.483994, 60.924953000000016],
          [28.471422, 60.923940000000016],
          [28.467012000000004, 60.92284999999998],
          [28.464521, 60.922874000000036],
          [28.397629, 60.887383],
          [28.400089999999995, 60.88538700000001],
          [28.397446000000002, 60.88345699999999],
          [28.392181, 60.88443399999997],
          [28.342688, 60.857917999999984],
          [28.33778, 60.85529],
          [28.337666, 60.85522499999999],
          [28.336253999999997, 60.85446999999999],
          [28.335651, 60.85414500000002],
          [28.334826, 60.85497699999999],
          [28.333694000000005, 60.855255],
          [28.33216, 60.85552999999999],
          [28.330717, 60.85622999999998],
          [28.329824000000002, 60.856322999999975],
          [28.328781000000003, 60.85610200000002],
          [28.327713, 60.85610200000002],
          [28.326967, 60.85644500000001],
          [28.326372, 60.85691],
          [28.325091999999998, 60.85769299999998],
          [28.324226, 60.857913999999994],
          [28.324015, 60.85751699999997],
          [28.323778000000004, 60.857224],
          [28.3224, 60.855354000000005],
          [28.319959999999995, 60.85208499999999],
          [28.319247999999998, 60.85112999999998],
          [28.318080000000002, 60.84956699999998],
          [28.316046000000004, 60.84684400000003],
          [28.311907, 60.84127000000004],
          [28.305334, 60.83758500000002],
          [28.293527999999995, 60.830974999999995],
          [28.280474000000005, 60.8237],
          [28.27893, 60.82283799999999],
          [28.27641, 60.821426],
          [28.273106000000002, 60.81958800000001],
          [28.268461, 60.816993999999966],
          [28.261184999999998, 60.81294],
          [28.258807999999995, 60.81161000000003],
          [28.255844000000003, 60.809950000000015],
          [28.253286, 60.807995000000034],
          [28.249952, 60.805428000000006],
          [28.245796, 60.80224200000001],
          [28.235613, 60.79442],
          [28.231375000000003, 60.79116400000001],
          [28.227625, 60.78828400000003],
          [28.220493, 60.782790000000006],
          [28.211117000000005, 60.78194400000001],
          [28.206968, 60.78156999999999],
          [28.201384, 60.78107],
          [28.191701999999996, 60.780193],
          [28.185263000000003, 60.77960999999999],
          [28.174282, 60.778617999999994],
          [28.171444000000005, 60.775867000000005],
          [28.163382999999996, 60.768032000000034],
          [28.158163000000002, 60.76294999999999],
          [28.153577999999996, 60.75850300000002],
          [28.150023, 60.75503999999998],
          [28.146654000000005, 60.751766],
          [28.143818, 60.74900400000001],
          [28.136886999999998, 60.742256],
          [28.135462000000004, 60.740868000000006],
          [28.13265, 60.73963999999998],
          [28.120366999999998, 60.73428000000001],
          [28.113333, 60.73121600000002],
          [28.111013, 60.73021],
          [28.102549, 60.72650999999999],
          [28.094352999999998, 60.72293499999998],
          [28.08899, 60.720596],
          [28.0869, 60.719516999999996],
          [28.078524, 60.715180000000004],
          [28.067408, 60.70931200000001],
          [28.06296, 60.706965999999994],
          [28.06029, 60.705577999999974],
          [28.056238, 60.70348000000001],
          [28.050411000000004, 60.700455000000005],
          [28.045286000000004, 60.697790000000026],
          [28.04201, 60.6961],
          [28.038040000000002, 60.69402699999998],
          [28.025133, 60.687490000000025],
          [28.015740999999995, 60.682728],
          [28.015167, 60.68243799999999],
          [28.017828, 60.681179999999955],
          [28.005009, 60.67419000000001],
          [27.998077, 60.670413999999994],
          [27.995365, 60.67121499999999],
          [27.991293, 60.66899000000001],
          [27.982891, 60.664401999999995],
          [27.971619, 60.65827999999999],
          [27.956325999999997, 60.649967000000004],
          [27.922222000000005, 60.63126],
          [27.911621, 60.62547000000001],
          [27.910848999999995, 60.62504200000001],
          [27.883886, 60.610287],
          [27.883162, 60.60988600000002],
          [27.873508, 60.60461000000001],
          [27.873413, 60.604557],
          [27.847935000000003, 60.582397000000014],
          [27.84801, 60.58206999999999],
          [27.847753999999995, 60.58178699999999],
          [27.847076, 60.58153500000003],
          [27.846804, 60.581305999999984],
          [27.846064, 60.58114600000002],
          [27.845825, 60.58098000000004],
          [27.844614000000004, 60.58073999999996],
          [27.844290000000004, 60.58045200000001],
          [27.844168, 60.58015],
          [27.843727, 60.579905999999994],
          [27.842472000000004, 60.57999799999999],
          [27.84242, 60.58008000000001],
          [27.841691999999995, 60.580134999999984],
          [27.841364, 60.580048000000005],
          [27.841377, 60.57988],
          [27.841014999999995, 60.579864999999955],
          [27.840514999999996, 60.579560000000015],
          [27.840583999999996, 60.57951699999998],
          [27.840494, 60.579384000000005],
          [27.840303, 60.579425999999984],
          [27.840073, 60.579285],
          [27.840141, 60.579117],
          [27.84003, 60.57884200000001],
          [27.839265999999995, 60.57862499999999],
          [27.838444, 60.578457000000014],
          [27.83849, 60.57789],
          [27.839502, 60.57767000000001],
          [27.84008, 60.57758000000001],
          [27.840867999999997, 60.577327999999994],
          [27.841703, 60.57685999999998],
          [27.842707, 60.57610999999997],
          [27.843666, 60.57554000000002],
          [27.84409, 60.575293999999985],
          [27.844658, 60.57486699999998],
          [27.844651999999996, 60.574569999999994],
          [27.844377999999995, 60.57338699999997],
          [27.844046, 60.572506000000004],
          [27.843626, 60.57172],
          [27.842812, 60.57060000000001],
          [27.842197000000002, 60.56936300000001],
          [27.841735999999997, 60.569087999999965],
          [27.842133000000004, 60.568479999999994],
          [27.842913, 60.56704300000001],
          [27.842852000000004, 60.56674200000003],
          [27.84245, 60.56638000000001],
          [27.842081, 60.56617399999999],
          [27.841749999999998, 60.5659],
          [27.842565999999998, 60.565364999999986],
          [27.774118742919068, 60.53549773356076],
          [27.76817534625148, 60.50244768582101],
          [27.748407951569924, 60.45384263788114],
          [27.801964412893817, 60.442286204640425],
          [27.914447477763417, 60.487679064312545],
          [28.23548314565497, 60.51252646498091],
          [28.255118757705315, 60.55796041196905],
          [28.347353421289444, 60.58653055129437],
          [28.42254859065807, 60.56173344109297],
          [28.482002324728626, 60.48464078134262],
          [28.44562372938301, 60.470799360139154],
          [28.429407876612572, 60.453576197256496],
          [28.49379028109039, 60.36738839909711],
          [28.410437766849842, 60.37256806176208],
          [28.410101721140254, 60.35682128961395],
          [28.597714064062878, 60.27206626707007],
          [28.689613624916394, 60.257247090121496],
          [28.73483422919242, 60.290301650845066],
          [28.64270282880212, 60.34296908365167],
          [28.839870616322514, 60.294063041056205],
          [29.0196474991425, 60.17854844024603],
          [29.154086846175627, 60.16121519726428],
          [29.460277322331414, 60.16646160980338],
          [29.52245299567662, 60.04539626746086],
          [29.629976834946014, 59.934655443956785],
          [29.547754697989117, 59.9587123871724],
          [29.23783787706626, 59.99427576567717],
          [29.11900538849137, 59.993265106278955],
          [29.03684698478596, 59.93738314973788],
          [28.983490252990457, 59.91091172500663],
          [29.05075579493094, 59.87831143301199],
          [28.91418395970391, 59.81550203415222],
          [28.68056085900655, 59.8109790877503],
          [28.51979720442478, 59.854764749486236],
          [28.430829691859213, 59.80484456675981],
          [28.402483721706187, 59.68310706458806],
          [28.232596026443485, 59.68743019362887],
          [28.192505957012393, 59.7826377876024],
          [28.09934355197103, 59.79043551631847],
          [28.028772557726214, 59.74923434383345]
        ]
      ],
      [
        [
          [29.438899999999997, 60.17643000000001],
          [29.439594000000003, 60.17672300000001],
          [29.441014999999997, 60.17733799999999],
          [29.442719, 60.178079999999994],
          [29.444403, 60.178709999999995],
          [29.445114000000004, 60.178978],
          [29.447245, 60.17956000000001],
          [29.447376000000002, 60.179592000000014],
          [29.449111999999996, 60.18002299999998],
          [29.449315999999996, 60.17999600000002],
          [29.453086999999996, 60.180702],
          [29.454151, 60.18083200000001],
          [29.454996, 60.180935000000005],
          [29.456995, 60.18107999999998],
          [29.457281, 60.18108699999999],
          [29.463173, 60.18123600000001],
          [29.463476000000004, 60.18135000000001],
          [29.466759000000003, 60.18138000000002],
          [29.469156, 60.18153799999999],
          [29.468318999999997, 60.18339499999999],
          [29.469963, 60.18360999999999],
          [29.468653, 60.18523999999999],
          [29.460024, 60.18373500000001],
          [29.458975, 60.184837000000016],
          [29.456661, 60.184967],
          [29.454458, 60.18493699999999],
          [29.45117, 60.18586299999998],
          [29.450937, 60.186993],
          [29.450394, 60.1874],
          [29.449863, 60.188045999999986],
          [29.449557999999996, 60.188590000000005],
          [29.448637, 60.188743999999986],
          [29.447723, 60.188984000000005],
          [29.42981, 60.189537],
          [29.451954, 60.20536799999999],
          [29.455538, 60.20754600000001],
          [29.481403, 60.22060000000002],
          [29.4822, 60.221012],
          [29.489172, 60.224391999999966],
          [29.489628, 60.22427400000001],
          [29.489865999999996, 60.224346000000025],
          [29.490027999999995, 60.224530000000016],
          [29.48993, 60.22501],
          [29.490068, 60.22508000000002],
          [29.490302999999997, 60.225089999999994],
          [29.490522, 60.22495000000001],
          [29.490616000000003, 60.224429999999984],
          [29.491465, 60.22429299999999],
          [29.491646000000003, 60.224132999999995],
          [29.49177, 60.22413000000003],
          [29.491764, 60.22383499999998],
          [29.49169, 60.22358299999999],
          [29.492617, 60.223206000000005],
          [29.492704000000003, 60.22291000000001],
          [29.492546, 60.222713],
          [29.492663999999998, 60.22259500000001],
          [29.493255999999995, 60.222553000000005],
          [29.493910000000003, 60.222409999999996],
          [29.494095, 60.22218699999999],
          [29.494173, 60.221873999999985],
          [29.494452000000003, 60.221810000000005],
          [29.494601999999997, 60.221885999999984],
          [29.494774, 60.222004],
          [29.495131999999998, 60.222034000000036],
          [29.496133999999998, 60.22177999999997],
          [29.496494, 60.22162600000004],
          [29.49664, 60.22140999999999],
          [29.497083999999997, 60.221239999999995],
          [29.499676000000004, 60.22255000000004],
          [29.505798, 60.22574000000003],
          [29.511849999999995, 60.22790999999998],
          [29.514042, 60.229153],
          [29.521152, 60.233288000000016],
          [29.522776, 60.23026999999999],
          [29.522851999999997, 60.228443],
          [29.521526000000005, 60.22583799999998],
          [29.521235, 60.225716000000006],
          [29.579271000000002, 60.219813999999985],
          [29.579837999999995, 60.22014200000001],
          [29.580435, 60.220448000000005],
          [29.581163, 60.22078299999998],
          [29.582422000000005, 60.22130200000001],
          [29.583588, 60.22169500000004],
          [29.583843, 60.221767],
          [29.584466999999997, 60.22192799999999],
          [29.584475999999995, 60.221929999999986],
          [29.584749999999996, 60.22200000000001],
          [29.58549, 60.22290000000001],
          [29.586058000000005, 60.22343399999997],
          [29.586918, 60.22450299999997],
          [29.587818, 60.226],
          [29.588344999999997, 60.226673000000005],
          [29.588390000000004, 60.22679500000001],
          [29.588541, 60.22711000000001],
          [29.589191, 60.227608000000004],
          [29.592128999999996, 60.22883200000001],
          [29.593163000000004, 60.22912199999999],
          [29.594758999999996, 60.229206000000005],
          [29.595911, 60.22910999999999],
          [29.596520999999996, 60.228999999999985],
          [29.597415999999996, 60.228966000000014],
          [29.597972999999996, 60.22908799999999],
          [29.59827, 60.229194999999976],
          [29.598392, 60.22970600000002],
          [29.598915000000005, 60.230232],
          [29.599325, 60.230999999999995],
          [29.599905, 60.232033],
          [29.600386, 60.23237599999999],
          [29.600285, 60.233479999999986],
          [29.600527000000003, 60.23412000000002],
          [29.600595, 60.23417699999999],
          [29.600605, 60.234207],
          [29.600686999999997, 60.234318],
          [29.600849999999998, 60.234399999999994],
          [29.601217000000002, 60.234234000000015],
          [29.602669, 60.233788000000004],
          [29.607723, 60.231030000000004],
          [29.611129999999996, 60.23214300000001],
          [29.61317, 60.233086000000014],
          [29.615267000000003, 60.23383999999999],
          [29.619891999999997, 60.23441700000001],
          [29.621328000000002, 60.23716400000001],
          [29.623627, 60.237465000000014],
          [29.624815, 60.23621399999999],
          [29.631319, 60.23445000000001],
          [29.63563, 60.23337000000001],
          [29.64008, 60.236312999999996],
          [29.641428, 60.237087],
          [29.642965000000004, 60.23698400000001],
          [29.643381, 60.237082999999984],
          [29.6437, 60.237408000000016],
          [29.643486, 60.237503000000004],
          [29.641708, 60.238125],
          [29.639845000000005, 60.23901699999999],
          [29.640537, 60.23944],
          [29.642126, 60.24070000000003],
          [29.643433, 60.24175600000001],
          [29.643965, 60.242189999999994],
          [29.648015999999995, 60.241479999999996],
          [29.65348, 60.24101000000002],
          [29.657003, 60.240481999999986],
          [29.663204, 60.239326000000005],
          [29.669085000000003, 60.237930000000006],
          [29.673903, 60.23705300000003],
          [29.675707, 60.236969999999985],
          [29.676404999999995, 60.23694599999996],
          [29.677560000000003, 60.23692],
          [29.677787999999996, 60.23694200000003],
          [29.678137, 60.23703999999998],
          [29.678408, 60.23715600000003],
          [29.678677, 60.237300000000005],
          [29.678839, 60.23746],
          [29.678920000000005, 60.23760200000001],
          [29.679037, 60.237697999999995],
          [29.67915, 60.23774700000001],
          [29.67933, 60.23777799999999],
          [29.679471999999997, 60.237774],
          [29.680001999999998, 60.237731999999994],
          [29.6802, 60.238020000000006],
          [29.680259999999997, 60.238110000000006],
          [29.680838, 60.238249999999994],
          [29.681858000000002, 60.239360000000005],
          [29.682428, 60.239982999999995],
          [29.683167, 60.240470000000016],
          [29.69265, 60.24008599999999],
          [29.694342, 60.240020000000015],
          [29.695152000000004, 60.23998599999999],
          [29.69586, 60.24071000000001],
          [29.699897999999997, 60.244834999999995],
          [29.702663, 60.244575999999995],
          [29.702726, 60.24430000000001],
          [29.70651, 60.24393499999999],
          [29.70655, 60.24263399999998],
          [29.707869999999996, 60.241558],
          [29.708302, 60.241207],
          [29.708326, 60.23980700000001],
          [29.706826999999997, 60.239184999999964],
          [29.705997, 60.23899],
          [29.70587, 60.238842000000034],
          [29.705555000000004, 60.238472],
          [29.703033000000005, 60.23719399999999],
          [29.70123, 60.23716999999999],
          [29.699473999999995, 60.2363],
          [29.69758, 60.23664500000001],
          [29.696674000000005, 60.235929999999996],
          [29.695250000000005, 60.23473999999999],
          [29.697762, 60.23487499999999],
          [29.698658, 60.23404700000003],
          [29.699873000000004, 60.23318499999999],
          [29.700886, 60.23237599999999],
          [29.705294000000002, 60.23038],
          [29.705044, 60.22978599999999],
          [29.708466, 60.228072999999995],
          [29.711853, 60.22682600000002],
          [29.719901999999998, 60.22592499999999],
          [29.719982, 60.22744],
          [29.720456999999996, 60.22829999999999],
          [29.717974, 60.22969400000002],
          [29.715502, 60.230774],
          [29.715042, 60.232033],
          [29.714153000000003, 60.232609999999994],
          [29.7175, 60.23392999999999],
          [29.716406, 60.23477600000001],
          [29.717514, 60.235239999999976],
          [29.715043999999995, 60.23622499999999],
          [29.714115, 60.236515],
          [29.714024, 60.23674999999997],
          [29.71412, 60.23738],
          [29.713383, 60.23854],
          [29.712763, 60.239540000000005],
          [29.709702999999998, 60.23979600000004],
          [29.711856999999995, 60.24077599999998],
          [29.713393999999997, 60.24147399999998],
          [29.714860000000005, 60.24162999999996],
          [29.719384999999996, 60.241394000000014],
          [29.721605, 60.24170700000002],
          [29.724697, 60.24092000000002],
          [29.725946, 60.24039000000002],
          [29.729599999999998, 60.239787999999976],
          [29.734625, 60.23967999999999],
          [29.745638, 60.24195499999999],
          [29.747837, 60.24208999999999],
          [29.754045, 60.24246200000002],
          [29.758015000000004, 60.24270200000001],
          [29.759039999999995, 60.239389999999986],
          [29.759163, 60.239000000000004],
          [29.759233000000002, 60.23877299999998],
          [29.759597999999997, 60.237595],
          [29.759786999999996, 60.236988],
          [29.771060000000002, 60.23304999999999],
          [29.770735000000002, 60.23107999999999],
          [29.77046, 60.23069399999997],
          [29.77042, 60.23066000000003],
          [29.770575000000004, 60.23035999999999],
          [29.770792000000004, 60.230175],
          [29.771152, 60.229870000000005],
          [29.771608, 60.229479999999995],
          [29.772598, 60.228774999999985],
          [29.77344, 60.22832],
          [29.774248000000004, 60.22767999999999],
          [29.774965, 60.22739400000003],
          [29.775492, 60.22706600000001],
          [29.776022, 60.22649799999999],
          [29.776840000000004, 60.22606999999999],
          [29.777742, 60.225750000000005],
          [29.778744, 60.22547500000002],
          [29.779743, 60.22518500000001],
          [29.779844, 60.22516999999999],
          [29.780457000000002, 60.224987],
          [29.781352999999996, 60.22493],
          [29.782648, 60.224846000000014],
          [29.783314, 60.22466300000002],
          [29.783476, 60.224525],
          [29.783699999999996, 60.22433000000001],
          [29.784973, 60.223235999999986],
          [29.785065, 60.222927],
          [29.78505, 60.222556999999995],
          [29.785212, 60.222297999999995],
          [29.785675, 60.222076000000044],
          [29.786255, 60.222004],
          [29.786509, 60.22200000000001],
          [29.788501999999998, 60.22197699999998],
          [29.789783, 60.222095000000024],
          [29.79079, 60.22220200000001],
          [29.791050000000002, 60.22223700000001],
          [29.792136999999997, 60.22242700000004],
          [29.792946, 60.222533999999996],
          [29.793768, 60.22251499999999],
          [29.794483000000003, 60.22239999999999],
          [29.79567, 60.22194300000004],
          [29.796661, 60.221810000000005],
          [29.797844, 60.221442999999994],
          [29.79895, 60.221367000000015],
          [29.800080999999995, 60.22138199999998],
          [29.800286999999997, 60.221367000000015],
          [29.800567999999995, 60.22138199999998],
          [29.801882, 60.22125200000002],
          [29.849117, 60.197295999999994],
          [29.852589, 60.196372999999994],
          [29.854746, 60.19595000000001],
          [29.86553, 60.19533000000001],
          [29.866354000000005, 60.19540000000001],
          [29.871235, 60.19397000000001],
          [29.871593, 60.19368],
          [29.88461, 60.195255],
          [29.892666, 60.19508700000003],
          [29.894089000000005, 60.192284],
          [29.894129, 60.192206999999996],
          [29.892632999999996, 60.18909000000002],
          [29.905308, 60.18663000000001],
          [29.905472, 60.185832999999974],
          [29.909139999999997, 60.185092999999966],
          [29.909271, 60.185066000000006],
          [29.923854999999996, 60.182410000000004],
          [29.925692, 60.181927],
          [29.926905, 60.183310000000006],
          [29.927527999999995, 60.183186000000006],
          [29.932360000000003, 60.18218999999999],
          [29.933989000000004, 60.181595000000016],
          [29.936617000000002, 60.18195700000001],
          [29.941824, 60.179557999999986],
          [29.948017, 60.17835600000001],
          [29.951227, 60.17705999999998],
          [29.951368, 60.177002000000016],
          [29.951546, 60.176837999999975],
          [29.95222, 60.176800000000014],
          [29.953144, 60.176800000000014],
          [29.95366, 60.17701299999999],
          [29.95411, 60.176969999999955],
          [29.954376, 60.17687599999999],
          [29.95485, 60.17690300000001],
          [29.954935, 60.17672000000002],
          [29.955067, 60.17644500000003],
          [29.955393, 60.176064000000025],
          [29.955361999999997, 60.17594],
          [29.955171999999997, 60.17585999999997],
          [29.955017, 60.175887999999986],
          [29.954908, 60.17596],
          [29.954596, 60.17596],
          [29.954565, 60.17584600000001],
          [29.955517, 60.17549500000001],
          [29.955930000000002, 60.175433999999996],
          [29.956157999999995, 60.17547999999999],
          [29.956177000000004, 60.17557000000002],
          [29.955845, 60.17594],
          [29.955902000000002, 60.176083000000006],
          [29.956308, 60.17600300000004],
          [29.956642, 60.17579699999999],
          [29.956726, 60.17538999999999],
          [29.956955, 60.17524700000001],
          [29.957299999999996, 60.17523],
          [29.958025, 60.17518200000001],
          [29.958796, 60.17535000000001],
          [29.95915, 60.17547999999999],
          [29.959255, 60.17563999999999],
          [29.959326, 60.175956999999954],
          [29.959232, 60.176075],
          [29.958807000000004, 60.17608000000001],
          [29.958463999999996, 60.17616000000001],
          [29.958329999999997, 60.17623499999999],
          [29.958271, 60.1764],
          [29.958496000000004, 60.17650600000002],
          [29.959145999999997, 60.17648299999999],
          [29.959901999999996, 60.176410000000004],
          [29.960947, 60.17649],
          [29.961378, 60.176636],
          [29.961712, 60.17675400000002],
          [29.961970000000004, 60.176800000000014],
          [29.962240000000005, 60.176795999999996],
          [29.962493999999996, 60.17672699999997],
          [29.962646000000003, 60.17654799999997],
          [29.963339000000005, 60.176254],
          [29.963621000000003, 60.17626000000001],
          [29.963911, 60.17626600000003],
          [29.964164999999998, 60.176243],
          [29.964361, 60.17617799999999],
          [29.964388, 60.17602000000002],
          [29.964561, 60.175704999999994],
          [29.964424000000005, 60.175556],
          [29.964218000000002, 60.175343],
          [29.964284999999997, 60.17526000000001],
          [29.964459999999995, 60.175217],
          [29.965685000000004, 60.17577699999998],
          [29.965940000000003, 60.17576200000002],
          [29.96604, 60.175663000000014],
          [29.965939000000002, 60.17512500000001],
          [29.966349000000005, 60.17478],
          [29.966454000000002, 60.174553],
          [29.966717, 60.174507000000006],
          [29.967226, 60.17467500000001],
          [29.967689999999997, 60.17487699999998],
          [29.968028999999998, 60.17489599999999],
          [29.968412000000004, 60.17470600000004],
          [29.968563, 60.174473000000006],
          [29.968819, 60.174454],
          [29.969124, 60.174533999999994],
          [29.969334, 60.17451],
          [29.969895999999995, 60.174057000000005],
          [29.969881, 60.17392699999999],
          [29.969274999999996, 60.173733],
          [29.969175, 60.173587999999995],
          [29.969343000000002, 60.173479999999955],
          [29.969576000000004, 60.17345399999999],
          [29.969877, 60.173644999999965],
          [29.970188000000004, 60.173670000000016],
          [29.970535, 60.173595000000006],
          [29.971542000000003, 60.17345],
          [29.971770999999997, 60.17347000000001],
          [29.971802000000004, 60.173607000000004],
          [29.971935000000002, 60.17379399999999],
          [29.972341999999998, 60.173878],
          [29.972814999999997, 60.173744],
          [29.97307, 60.173595000000006],
          [29.972988, 60.173405],
          [29.972665999999997, 60.173294],
          [29.972531999999998, 60.173125999999996],
          [29.972691999999995, 60.172920000000005],
          [29.973249999999997, 60.17297400000001],
          [29.973745, 60.17353399999999],
          [29.974046999999995, 60.173760000000016],
          [29.974354000000005, 60.17378599999998],
          [29.974511999999997, 60.17372],
          [29.974348000000003, 60.173485],
          [29.974412999999995, 60.173336000000006],
          [29.974709, 60.173225],
          [29.97566, 60.173145000000005],
          [29.975973, 60.17304200000001],
          [29.975976999999997, 60.172912999999994],
          [29.975676999999997, 60.17280199999996],
          [29.975468, 60.172684000000004],
          [29.975258000000004, 60.172356000000036],
          [29.975225, 60.172200000000004],
          [29.975409, 60.17210399999999],
          [29.976122, 60.17217599999998],
          [29.97718, 60.17184399999999],
          [29.977661000000005, 60.171818],
          [29.978067, 60.171955],
          [29.979034, 60.17227],
          [29.980154000000002, 60.17231000000001],
          [29.980364, 60.17231799999996],
          [29.980474, 60.172432000000015],
          [29.980326, 60.172560000000004],
          [29.980290000000004, 60.17272],
          [29.980536000000004, 60.17274499999999],
          [29.980715, 60.17262599999998],
          [29.98084, 60.17254600000001],
          [29.98096, 60.172447000000005],
          [29.981108, 60.17209200000002],
          [29.981120000000004, 60.171966999999995],
          [29.981417000000004, 60.171707],
          [29.981405, 60.17148],
          [29.980894, 60.171310000000005],
          [29.980870999999997, 60.17123000000001],
          [29.981138, 60.171143],
          [29.98149, 60.171179999999964],
          [29.981972000000003, 60.17138299999999],
          [29.982243999999998, 60.171535000000006],
          [29.982447000000004, 60.171513000000004],
          [29.982584, 60.17142999999999],
          [29.982477, 60.17112],
          [29.98219, 60.17083400000001],
          [29.981862999999997, 60.170624000000004],
          [29.981467999999996, 60.170444],
          [29.981318, 60.17028400000004],
          [29.981434, 60.170165999999995],
          [29.981653, 60.170090000000044],
          [29.9818, 60.17003299999999],
          [29.982006, 60.17003299999999],
          [29.982454, 60.17003299999999],
          [29.982658, 60.17002999999997],
          [29.982782, 60.169914000000006],
          [29.982990000000004, 60.16951000000003],
          [29.983416, 60.16944999999998],
          [29.983765, 60.16961999999998],
          [29.983778000000004, 60.169827],
          [29.983574000000004, 60.17003299999999],
          [29.983638999999997, 60.170154999999966],
          [29.983745999999996, 60.17025000000001],
          [29.983925000000003, 60.170265],
          [29.98403, 60.170208],
          [29.98408, 60.17012399999999],
          [29.984285, 60.17001299999998],
          [29.984617000000004, 60.16964999999999],
          [29.984801999999995, 60.16950200000002],
          [29.984827, 60.16919300000001],
          [29.984442000000005, 60.16894500000001],
          [29.984495000000003, 60.16856999999999],
          [29.984438, 60.16843800000001],
          [29.984251000000004, 60.16834299999999],
          [29.984007, 60.16828499999997],
          [29.98385, 60.168304000000006],
          [29.983856000000003, 60.16851],
          [29.983618, 60.16869700000001],
          [29.982983, 60.16866300000001],
          [29.982557, 60.168350000000004],
          [29.982302, 60.167850000000016],
          [29.982168, 60.16750300000001],
          [29.982197, 60.167354999999986],
          [29.982393, 60.16728599999999],
          [29.983303, 60.167416],
          [29.983606, 60.16755699999999],
          [29.983849, 60.16775000000001],
          [29.984061999999998, 60.16779700000001],
          [29.984419, 60.16777999999999],
          [29.984774, 60.16759999999999],
          [29.985106, 60.167473],
          [29.985477, 60.16748999999999],
          [29.985661999999998, 60.16802200000001],
          [29.98596, 60.168319999999994],
          [29.986300000000004, 60.16834599999996],
          [29.986606999999996, 60.16829000000001],
          [29.986841, 60.16821999999999],
          [29.986876, 60.16805600000001],
          [29.986511, 60.167854000000005],
          [29.986239999999995, 60.16755699999999],
          [29.986246000000005, 60.167484],
          [29.985887999999996, 60.167305],
          [29.985338, 60.167145000000005],
          [29.984997000000003, 60.16700700000001],
          [29.984942999999998, 60.166799999999995],
          [29.985083, 60.16660999999999],
          [29.985775, 60.166424000000006],
          [29.986195, 60.16636],
          [29.986574, 60.166427999999996],
          [29.986948, 60.16653400000001],
          [29.987118, 60.166686999999996],
          [29.98743, 60.16667000000001],
          [29.98747, 60.16654599999998],
          [29.987395999999997, 60.166380000000004],
          [29.986729, 60.16608999999997],
          [29.986286000000003, 60.165869999999984],
          [29.986261, 60.165695],
          [29.986423, 60.165659999999974],
          [29.986835, 60.16561999999999],
          [29.987557999999996, 60.16551200000001],
          [29.988017999999997, 60.16541000000001],
          [29.988155000000003, 60.165156999999994],
          [29.988152, 60.164886000000024],
          [29.988889999999998, 60.164524],
          [29.989374, 60.16437499999998],
          [29.989969999999996, 60.164276],
          [29.990166, 60.16412700000001],
          [29.990046, 60.16399799999999],
          [29.98972, 60.16387],
          [29.989659999999997, 60.16378999999998],
          [29.98975, 60.163700000000034],
          [29.990084, 60.16363000000001],
          [29.990662, 60.16368499999999],
          [29.990997, 60.16376500000001],
          [29.991637999999995, 60.163610000000006],
          [29.992251999999997, 60.16359700000001],
          [29.992498000000005, 60.163464000000005],
          [29.992174, 60.16320400000001],
          [29.992125, 60.16304800000003],
          [29.992711999999997, 60.162994],
          [29.993204, 60.16306700000001],
          [29.993761, 60.16323],
          [29.994385000000005, 60.163296],
          [29.994682000000005, 60.163279999999986],
          [29.994953, 60.163216000000006],
          [29.995094, 60.163116],
          [29.995039, 60.16295199999999],
          [29.994823, 60.162739999999985],
          [29.994848, 60.16250600000001],
          [29.995014, 60.16243],
          [29.99545, 60.162440000000004],
          [29.99583, 60.162390000000016],
          [29.996333999999997, 60.16238799999999],
          [29.996801, 60.16235],
          [29.997878999999998, 60.162082999999996],
          [29.998102, 60.16197],
          [29.99825, 60.16175000000001],
          [29.998308, 60.16149999999999],
          [29.998604, 60.161310000000014],
          [29.998987, 60.16119800000004],
          [29.999343999999997, 60.161204999999995],
          [29.999489, 60.16127800000001],
          [29.999510000000004, 60.16136599999996],
          [29.999142000000003, 60.16172799999998],
          [29.999155, 60.161850000000015],
          [29.999320000000004, 60.16198],
          [29.999495000000003, 60.16200599999999],
          [29.999699000000003, 60.16197600000001],
          [30.000355, 60.161575],
          [30.000565, 60.161407],
          [30.000732, 60.16133500000001],
          [30.000915999999997, 60.16138999999998],
          [30.001152000000005, 60.161552],
          [30.00121, 60.161743],
          [30.001308, 60.16184200000001],
          [30.001456999999995, 60.16185999999996],
          [30.002914000000004, 60.16567999999998],
          [30.003609, 60.165558000000004],
          [30.006829999999997, 60.16500500000001],
          [30.007324, 60.16499700000003],
          [30.00871, 60.16431],
          [30.00734, 60.16252000000003],
          [30.010125999999996, 60.159554000000014],
          [30.011925, 60.158770000000004],
          [30.012418999999998, 60.15646699999999],
          [30.015594000000004, 60.155227999999994],
          [30.01865, 60.15440000000001],
          [30.021112000000002, 60.152214000000015],
          [30.021100000000004, 60.15172000000001],
          [30.02062, 60.151009999999985],
          [30.018023, 60.14428699999999],
          [30.059551, 60.13601700000001],
          [30.067887999999996, 60.13586799999999],
          [30.103958, 60.13531499999999],
          [30.131886999999995, 60.134889999999984],
          [30.147387, 60.13437300000001],
          [30.154058, 60.13205999999997],
          [30.160774, 60.12970999999999],
          [30.160927, 60.129729999999995],
          [30.161203000000004, 60.129706999999996],
          [30.161388000000002, 60.12967699999999],
          [30.16153, 60.129658000000035],
          [30.161606000000003, 60.129706999999996],
          [30.161612000000005, 60.12979999999999],
          [30.161687999999998, 60.129819999999995],
          [30.161810000000003, 60.12978699999999],
          [30.161951, 60.129726000000005],
          [30.162039999999998, 60.12967000000003],
          [30.162144, 60.12966499999999],
          [30.162231000000002, 60.129733999999985],
          [30.162296, 60.12983],
          [30.162457, 60.12989999999999],
          [30.162737, 60.129906000000005],
          [30.162961999999997, 60.129880000000014],
          [30.163147, 60.129850000000005],
          [30.16343, 60.12986799999999],
          [30.163639999999997, 60.129898000000026],
          [30.163774, 60.129954999999995],
          [30.163946, 60.130004999999954],
          [30.163973, 60.130120000000005],
          [30.16412, 60.13013999999998],
          [30.164446000000005, 60.13006999999999],
          [30.164612, 60.13004000000001],
          [30.16461, 60.130054],
          [30.165716000000003, 60.13052400000001],
          [30.166150000000002, 60.13072600000001],
          [30.166943, 60.131145000000004],
          [30.166068999999997, 60.131564999999995],
          [30.165986999999998, 60.131602999999956],
          [30.166253999999995, 60.13181700000001],
          [30.166862, 60.132214000000005],
          [30.167355, 60.13210000000001],
          [30.169085, 60.132705999999985],
          [30.169242999999998, 60.13255300000003],
          [30.169375999999996, 60.13242700000001],
          [30.169498, 60.13230999999999],
          [30.169483, 60.132071999999994],
          [30.171612, 60.13298],
          [30.171503, 60.13380999999998],
          [30.171489999999995, 60.133872999999994],
          [30.172855, 60.135069999999985],
          [30.174332, 60.13632200000001],
          [30.175789, 60.135998],
          [30.175838, 60.136044],
          [30.175877000000003, 60.13606999999999],
          [30.175941, 60.13613000000001],
          [30.176561, 60.13589000000002],
          [30.176289, 60.135635000000036],
          [30.17599, 60.135069999999985],
          [30.175775999999995, 60.134630000000016],
          [30.175125, 60.134285000000006],
          [30.175146000000005, 60.13426200000001],
          [30.174932, 60.13421199999999],
          [30.17488, 60.134274000000005],
          [30.174858000000004, 60.13431000000003],
          [30.173733, 60.134623999999974],
          [30.173725, 60.13459999999998],
          [30.173683, 60.134544000000005],
          [30.173592, 60.1345],
          [30.173457999999997, 60.13438400000001],
          [30.173101, 60.133970000000005],
          [30.172733, 60.133440000000036],
          [30.172793999999996, 60.13292000000001],
          [30.172903, 60.131687],
          [30.173002000000004, 60.13162],
          [30.173084, 60.13157000000004],
          [30.173084, 60.13150000000002],
          [30.173061, 60.131462],
          [30.172977, 60.13145399999999],
          [30.173094000000003, 60.13029],
          [30.172535, 60.12991999999997],
          [30.172026, 60.12925999999999],
          [30.171588999999997, 60.128772999999995],
          [30.171883, 60.128787999999986],
          [30.171925999999996, 60.128787999999986],
          [30.172201000000005, 60.12888300000003],
          [30.172606000000002, 60.128787999999986],
          [30.172827000000005, 60.12885299999999],
          [30.17307, 60.12884],
          [30.173185, 60.12886],
          [30.173311, 60.12892499999998],
          [30.173811, 60.12907799999999],
          [30.17409, 60.129154],
          [30.174555, 60.12932000000001],
          [30.1747, 60.12936400000001],
          [30.174829999999996, 60.12938299999999],
          [30.174982000000004, 60.12939499999999],
          [30.175062000000004, 60.12938699999998],
          [30.175104000000005, 60.129368],
          [30.175153999999996, 60.129265000000004],
          [30.175218999999995, 60.129242000000005],
          [30.175299, 60.12923000000001],
          [30.175413000000002, 60.12924000000004],
          [30.175539000000004, 60.12926999999999],
          [30.175634, 60.129275999999976],
          [30.175860999999998, 60.129253000000006],
          [30.176033, 60.129227000000014],
          [30.17614, 60.129222999999996],
          [30.176262, 60.129245999999995],
          [30.176353, 60.12929500000001],
          [30.176437, 60.129310000000004],
          [30.176651, 60.12932000000001],
          [30.176902999999996, 60.129345],
          [30.177113, 60.129345],
          [30.177600000000005, 60.12933000000001],
          [30.177790000000005, 60.129345],
          [30.177906, 60.129356],
          [30.17824, 60.12935000000002],
          [30.1784, 60.12935999999999],
          [30.178583, 60.129356],
          [30.178846000000004, 60.12933300000003],
          [30.17904, 60.12934000000001],
          [30.179247, 60.12937500000004],
          [30.179749, 60.12935000000002],
          [30.180102999999995, 60.129345],
          [30.180164000000005, 60.12935300000001],
          [30.180305, 60.129402],
          [30.180404999999997, 60.12940600000002],
          [30.180631999999996, 60.129369999999994],
          [30.180835999999996, 60.12939],
          [30.181067999999996, 60.12938699999998],
          [30.181236, 60.129400000000004],
          [30.181469, 60.12941699999999],
          [30.181947999999995, 60.129425],
          [30.182516, 60.12942000000001],
          [30.182700000000004, 60.12941699999999],
          [30.183316999999995, 60.12940600000002],
          [30.183397, 60.12888000000001],
          [30.18356, 60.12742],
          [30.193264, 60.12777000000003],
          [30.194738, 60.12154000000001],
          [30.192062, 60.120479999999986],
          [30.191513000000004, 60.12023500000001],
          [30.191002, 60.11997199999999],
          [30.190397, 60.11952200000002],
          [30.189932, 60.1191],
          [30.189617, 60.118674999999996],
          [30.189497, 60.11843999999999],
          [30.18943, 60.11822999999998],
          [30.189610999999996, 60.118224999999995],
          [30.194668, 60.118187000000006],
          [30.195334999999996, 60.117794],
          [30.195312000000005, 60.11727499999998],
          [30.206978, 60.117085],
          [30.208616000000003, 60.11697800000002],
          [30.213709, 60.116085],
          [30.218956, 60.11512999999999],
          [30.220120000000005, 60.11553000000001],
          [30.219924999999996, 60.115623],
          [30.219244, 60.11649700000001],
          [30.219654000000002, 60.11667300000002],
          [30.221575, 60.116776000000016],
          [30.223148, 60.11704],
          [30.223997000000004, 60.11774],
          [30.224878000000004, 60.11807999999999],
          [30.225726999999996, 60.11822999999998],
          [30.229315, 60.118476999999984],
          [30.230766, 60.118570000000005],
          [30.233637, 60.11849999999998],
          [30.233807, 60.11852300000001],
          [30.234178999999997, 60.118572],
          [30.234255, 60.11836199999996],
          [30.235197000000003, 60.118442999999985],
          [30.235443000000004, 60.11846],
          [30.235842, 60.11849999999998],
          [30.237751, 60.119189999999975],
          [30.251784999999998, 60.12282000000002],
          [30.259033, 60.125607],
          [30.259240999999996, 60.125652],
          [30.259521, 60.12545399999999],
          [30.26005, 60.125159999999994],
          [30.261421, 60.12471399999998],
          [30.262913, 60.124336],
          [30.264503, 60.12372199999999],
          [30.265125, 60.123333],
          [30.265574, 60.12284],
          [30.265772, 60.122574000000014],
          [30.265923, 60.12230299999996],
          [30.265919000000004, 60.12171599999999],
          [30.265943999999998, 60.12133],
          [30.266033, 60.12099499999999],
          [30.266309999999997, 60.12056999999999],
          [30.266397, 60.120419999999996],
          [30.266386, 60.120036999999996],
          [30.266033, 60.119586999999996],
          [30.266693, 60.11935],
          [30.267254, 60.119129999999956],
          [30.267894999999996, 60.11869999999996],
          [30.268932, 60.117954],
          [30.269949, 60.11720300000002],
          [30.270748000000005, 60.116596000000015],
          [30.271094999999995, 60.116389999999996],
          [30.270765, 60.116352000000006],
          [30.271076, 60.11354399999999],
          [30.271183, 60.11194599999999],
          [30.271242, 60.111850000000004],
          [30.271564, 60.111534000000006],
          [30.271662, 60.11139299999999],
          [30.271625999999998, 60.11118699999997],
          [30.271486, 60.11112],
          [30.271256999999995, 60.11111],
          [30.271431, 60.110287],
          [30.273003, 60.109669999999994],
          [30.27329, 60.108086000000014],
          [30.274878, 60.10813999999999],
          [30.275602, 60.10289],
          [30.275745, 60.102215],
          [30.276940999999997, 60.10198199999999],
          [30.282323999999996, 60.098938000000004],
          [30.287436, 60.096180000000004],
          [30.28842, 60.096134000000006],
          [30.289385, 60.09638000000001],
          [30.29183, 60.09596299999998],
          [30.291836000000004, 60.09389999999999],
          [30.303103999999998, 60.09110000000001],
          [30.308182, 60.09568999999999],
          [30.308830000000004, 60.095714999999984],
          [30.331014999999997, 60.090374],
          [30.332973, 60.08980600000004],
          [30.34077, 60.090847],
          [30.359419, 60.091965000000016],
          [30.361474999999995, 60.092119999999966],
          [30.363234, 60.092014000000006],
          [30.36698, 60.09073599999999],
          [30.36831, 60.090282],
          [30.370665, 60.08771999999999],
          [30.37353, 60.080512999999996],
          [30.373852, 60.080254],
          [30.375174, 60.079830000000015],
          [30.376524, 60.07863600000002],
          [30.376877, 60.07858300000001],
          [30.377192999999995, 60.07738499999999],
          [30.378815, 60.07409000000001],
          [30.382536, 60.06671499999999],
          [30.38557, 60.06072],
          [30.385403000000004, 60.059742],
          [30.387393999999997, 60.059177000000005],
          [30.388766999999998, 60.05880400000001],
          [30.388044, 60.05808999999999],
          [30.388899999999996, 60.057822999999985],
          [30.388110999999995, 60.057075999999995],
          [30.396572, 60.05500799999999],
          [30.406759999999995, 60.05259699999999],
          [30.418561999999998, 60.04968299999999],
          [30.41565, 60.04670999999999],
          [30.418883999999995, 60.04608499999998],
          [30.424931999999995, 60.044827],
          [30.425113999999997, 60.044353],
          [30.430616, 60.04317499999999],
          [30.434032, 60.042441999999994],
          [30.434814, 60.04226299999999],
          [30.435567999999996, 60.04203799999999],
          [30.436259999999997, 60.04175000000001],
          [30.436834000000005, 60.04137800000001],
          [30.437263, 60.040997000000004],
          [30.437607, 60.040604],
          [30.437711999999998, 60.04014000000004],
          [30.437613, 60.03913],
          [30.437849000000003, 60.03923399999999],
          [30.441930000000003, 60.04094700000002],
          [30.449146000000002, 60.039420000000035],
          [30.450766, 60.03907799999999],
          [30.457668000000005, 60.03761],
          [30.457852999999997, 60.03757000000002],
          [30.458336, 60.037540000000035],
          [30.459290999999997, 60.037120000000016],
          [30.459858, 60.03661700000001],
          [30.460538999999997, 60.03617999999997],
          [30.461095999999998, 60.03563],
          [30.461197, 60.035324],
          [30.461229999999997, 60.034996000000035],
          [30.461293999999995, 60.034683],
          [30.461487, 60.03453999999999],
          [30.462498, 60.03421800000001],
          [30.464052, 60.033962],
          [30.465261000000005, 60.033455000000004],
          [30.466976, 60.033755999999954],
          [30.46779, 60.033732999999984],
          [30.468231, 60.033558],
          [30.468412, 60.03339399999999],
          [30.468390999999997, 60.033169999999984],
          [30.468296000000002, 60.03303],
          [30.468245, 60.03277],
          [30.468277, 60.032523999999995],
          [30.468082, 60.032280000000014],
          [30.467976, 60.032204000000036],
          [30.467564000000003, 60.03191000000001],
          [30.467193999999996, 60.03165000000001],
          [30.466797, 60.03154799999999],
          [30.466236, 60.03151],
          [30.46592, 60.03146400000003],
          [30.465149999999998, 60.03135299999997],
          [30.464544, 60.03126499999999],
          [30.464513999999998, 60.03124],
          [30.464071, 60.030834],
          [30.463856, 60.03036500000002],
          [30.463772, 60.03017800000001],
          [30.462977999999996, 60.02966999999998],
          [30.462680000000002, 60.029285000000016],
          [30.462162, 60.028610000000015],
          [30.462180000000004, 60.02835999999999],
          [30.462349, 60.028126000000015],
          [30.462758999999995, 60.02802299999999],
          [30.463438, 60.027930000000026],
          [30.464133999999998, 60.027916000000005],
          [30.46467, 60.02787799999999],
          [30.46529, 60.02782399999998],
          [30.466152, 60.02771799999999],
          [30.467994999999995, 60.02749],
          [30.468239, 60.02744299999998],
          [30.4684, 60.027367],
          [30.468428, 60.02726999999996],
          [30.468365, 60.02714499999999],
          [30.468088, 60.026966000000016],
          [30.467682, 60.02684399999998],
          [30.467101999999997, 60.02683300000001],
          [30.466173, 60.02690999999999],
          [30.465658, 60.02690999999999],
          [30.465248, 60.02680599999999],
          [30.464914, 60.026579999999996],
          [30.464455, 60.026173],
          [30.464376, 60.02599000000001],
          [30.464105999999997, 60.025836999999996],
          [30.464203000000005, 60.025757],
          [30.46415, 60.02554299999997],
          [30.464285, 60.02537000000001],
          [30.46482, 60.02506299999996],
          [30.465719999999997, 60.02489],
          [30.466162, 60.02478400000001],
          [30.466757, 60.02454399999999],
          [30.466908, 60.02430699999999],
          [30.466884999999998, 60.02393699999999],
          [30.467101999999997, 60.02325000000002],
          [30.467213000000005, 60.02305200000001],
          [30.467321, 60.022907000000004],
          [30.467577000000002, 60.02274],
          [30.46788, 60.022636000000034],
          [30.468458000000002, 60.02260000000001],
          [30.468919999999997, 60.022694],
          [30.469618, 60.02306999999996],
          [30.470137, 60.02337300000002],
          [30.470396, 60.02385000000001],
          [30.470621, 60.02405999999999],
          [30.471043, 60.02445000000003],
          [30.471800000000005, 60.024726999999984],
          [30.472805, 60.024951999999956],
          [30.474321, 60.025176999999985],
          [30.475166, 60.025150000000025],
          [30.475641, 60.025019999999984],
          [30.475853000000004, 60.02478400000001],
          [30.475833999999995, 60.024586],
          [30.47561, 60.02396400000001],
          [30.475517, 60.02361300000001],
          [30.475655, 60.022976],
          [30.475868, 60.022699999999986],
          [30.476581999999997, 60.022437999999994],
          [30.476908, 60.022414999999995],
          [30.478262, 60.022636000000034],
          [30.479154999999995, 60.02264999999997],
          [30.479523, 60.02258699999999],
          [30.479864000000003, 60.022391999999996],
          [30.47998, 60.021975999999995],
          [30.480433, 60.021169999999955],
          [30.48135, 60.02061999999998],
          [30.482178, 60.020283000000006],
          [30.483063, 60.02019499999997],
          [30.483803000000005, 60.02030600000003],
          [30.484339000000002, 60.0205],
          [30.486391000000005, 60.02178599999999],
          [30.487158, 60.02258699999999],
          [30.487572, 60.022896],
          [30.488045000000003, 60.02318199999999],
          [30.488386, 60.02327699999998],
          [30.488611000000002, 60.02327300000002],
          [30.488768, 60.02320499999999],
          [30.489109, 60.023056],
          [30.489423999999996, 60.022850000000005],
          [30.489669999999997, 60.022433999999976],
          [30.489475, 60.021435],
          [30.489359999999998, 60.02132],
          [30.489423999999996, 60.02116800000002],
          [30.489347, 60.020565000000005],
          [30.489682999999996, 60.019270000000006],
          [30.489807000000003, 60.01883699999999],
          [30.489864000000004, 60.01869199999999],
          [30.489637, 60.018658000000016],
          [30.489496, 60.018482000000006],
          [30.489347, 60.01778399999998],
          [30.489154999999997, 60.017525000000006],
          [30.48905, 60.01754],
          [30.488865, 60.01774],
          [30.48866, 60.01771500000001],
          [30.488565, 60.017673],
          [30.488450999999998, 60.01764],
          [30.487955, 60.017178],
          [30.487764, 60.016857000000016],
          [30.487297, 60.01643999999999],
          [30.487007, 60.01633000000004],
          [30.486639, 60.01630399999999],
          [30.486668, 60.016246999999964],
          [30.486866, 60.01616999999999],
          [30.486774, 60.01610600000001],
          [30.486724999999996, 60.01601400000001],
          [30.486366, 60.01577399999999],
          [30.486261, 60.01568199999997],
          [30.486162, 60.01536999999999],
          [30.486224999999997, 60.014973],
          [30.486240000000002, 60.01487],
          [30.486246, 60.01483500000003],
          [30.486092, 60.01475500000001],
          [30.485794, 60.014748],
          [30.485794, 60.01470000000003],
          [30.486240000000002, 60.014534],
          [30.486317, 60.01436999999996],
          [30.486444, 60.01405700000001],
          [30.486915999999997, 60.01364499999997],
          [30.487072, 60.013251999999994],
          [30.487057, 60.013149999999996],
          [30.486626, 60.013030000000015],
          [30.48661, 60.01299],
          [30.486683, 60.01295999999999],
          [30.486973, 60.013000000000005],
          [30.487099, 60.012996999999984],
          [30.487555999999998, 60.012337],
          [30.487709, 60.01199],
          [30.487846, 60.01137499999999],
          [30.487938, 60.01117000000002],
          [30.488348, 60.01075399999999],
          [30.488533000000004, 60.010474999999985],
          [30.488462000000002, 60.01039500000002],
          [30.488272000000002, 60.01030700000001],
          [30.487976000000003, 60.01029599999998],
          [30.487959, 60.010257999999965],
          [30.488052, 60.010220000000004],
          [30.488327, 60.01021],
          [30.488596, 60.010272999999984],
          [30.488806, 60.010265000000004],
          [30.489193, 60.009859999999975],
          [30.489422, 60.009479999999996],
          [30.489273, 60.00941499999999],
          [30.489350000000005, 60.00933499999999],
          [30.489456000000004, 60.00930800000003],
          [30.489592, 60.009074999999996],
          [30.489767000000004, 60.008820000000014],
          [30.48981, 60.008700000000005],
          [30.489725000000004, 60.00855999999999],
          [30.489315, 60.008324000000016],
          [30.489449, 60.00829999999999],
          [30.489612999999995, 60.00833],
          [30.489742, 60.008430000000004],
          [30.489878000000004, 60.008442],
          [30.489998000000003, 60.008392000000015],
          [30.490026000000004, 60.00826300000003],
          [30.490103, 60.008205000000004],
          [30.490562, 60.008385000000004],
          [30.49251, 60.00907999999998],
          [30.492898999999998, 60.00948299999993],
          [30.493899999999996, 60.00966],
          [30.494368000000005, 60.009086999999994],
          [30.494764, 60.00837000000001],
          [30.495462000000003, 60.00787],
          [30.499270999999997, 60.008399999999995],
          [30.499315000000003, 60.007942000000014],
          [30.499443, 60.007743999999974],
          [30.499699, 60.007360000000006],
          [30.503964999999997, 60.006485],
          [30.50399, 60.006429999999995],
          [30.503735000000002, 60.005600000000015],
          [30.503392999999996, 60.00449800000001],
          [30.50315, 60.00416999999999],
          [30.502916, 60.003592999999995],
          [30.501912999999995, 60.00276000000002],
          [30.500246, 60.001373],
          [30.500189, 60.001247000000006],
          [30.499996, 60.001063999999985],
          [30.499489999999998, 60.000427],
          [30.4997, 60.00017500000001],
          [30.500233, 60.00020200000003],
          [30.501709, 60.00018],
          [30.502354000000004, 60.000046],
          [30.502934999999997, 59.999672000000004],
          [30.502052000000003, 59.998222],
          [30.504033999999997, 59.99637200000001],
          [30.503841, 59.995934000000005],
          [30.503962999999995, 59.99580000000003],
          [30.503796, 59.995689999999996],
          [30.503553, 59.995082999999994],
          [30.503466, 59.99468999999999],
          [30.503440000000005, 59.99457600000002],
          [30.503612999999998, 59.99444199999999],
          [30.505414999999996, 59.99399600000001],
          [30.506563, 59.99370999999999],
          [30.507260000000002, 59.992790000000014],
          [30.506172, 59.990504999999956],
          [30.507477, 59.989630000000005],
          [30.508707, 59.98881000000003],
          [30.508812, 59.98865500000002],
          [30.508713000000004, 59.98844500000001],
          [30.508257, 59.98787999999996],
          [30.507742, 59.98749000000001],
          [30.508091000000004, 59.987133],
          [30.508757, 59.987262999999984],
          [30.510397000000005, 59.98663699999997],
          [30.511673, 59.986384999999984],
          [30.512148, 59.986279999999994],
          [30.51257, 59.985839999999996],
          [30.514456, 59.985806],
          [30.517323, 59.98531700000001],
          [30.520049999999998, 59.986412],
          [30.524317, 59.988249999999994],
          [30.53453, 59.992549999999994],
          [30.53466, 59.992603],
          [30.535737999999995, 59.992290000000025],
          [30.535923, 59.99220700000001],
          [30.53606, 59.99212300000002],
          [30.536121, 59.99194700000001],
          [30.536012999999997, 59.991817],
          [30.536142, 59.991512],
          [30.53651, 59.99109600000003],
          [30.536530000000003, 59.99094400000004],
          [30.53648, 59.99080000000001],
          [30.536343, 59.990691999999996],
          [30.536180000000005, 59.990646],
          [30.535999999999998, 59.990623],
          [30.535868000000004, 59.990579999999994],
          [30.535593000000002, 59.99037000000001],
          [30.535507, 59.99025999999998],
          [30.534971, 59.98983799999999],
          [30.534811000000005, 59.989810000000006],
          [30.534666, 59.98985999999999],
          [30.534636, 59.990128],
          [30.534722999999996, 59.99032199999999],
          [30.534523000000004, 59.990485999999976],
          [30.534346000000003, 59.990546999999964],
          [30.534332000000003, 59.990635],
          [30.534191, 59.990657999999996],
          [30.534027, 59.99062000000001],
          [30.533794, 59.990128],
          [30.533830000000002, 59.990020000000015],
          [30.533781, 59.989773000000014],
          [30.534277000000003, 59.989452],
          [30.534314999999996, 59.989337999999975],
          [30.534184000000003, 59.98922300000001],
          [30.533705, 59.98890299999999],
          [30.533703, 59.9888],
          [30.533957, 59.98869300000001],
          [30.534689, 59.98866000000001],
          [30.534882, 59.98871199999999],
          [30.534891, 59.98876600000003],
          [30.534817, 59.988956],
          [30.534966, 59.98913999999999],
          [30.535308999999998, 59.989193],
          [30.535658, 59.98919699999999],
          [30.53626, 59.989089999999976],
          [30.536632999999995, 59.98899],
          [30.536947, 59.988853000000006],
          [30.537567, 59.988754],
          [30.537865, 59.98867799999999],
          [30.537945, 59.98858599999997],
          [30.537806000000003, 59.988358000000005],
          [30.537779000000004, 59.988106000000016],
          [30.537699, 59.98801399999999],
          [30.537528999999996, 59.987926000000044],
          [30.53703, 59.98782299999999],
          [30.536503000000003, 59.987419999999986],
          [30.536272000000004, 59.98709500000004],
          [30.536272000000004, 59.98693499999999],
          [30.536419999999996, 59.986785999999995],
          [30.536415, 59.986683],
          [30.53629, 59.98663699999997],
          [30.535956999999996, 59.98664499999998],
          [30.535347, 59.98665600000001],
          [30.534926999999996, 59.986606999999964],
          [30.53473, 59.98665],
          [30.534609, 59.986816000000005],
          [30.534072999999996, 59.9871],
          [30.533863, 59.98711800000001],
          [30.533657000000005, 59.98711399999999],
          [30.533164999999997, 59.98701],
          [30.533062, 59.98693499999999],
          [30.533234000000004, 59.98663699999997],
          [30.53319, 59.986435],
          [30.532972, 59.98624000000004],
          [30.532691999999997, 59.986095000000006],
          [30.532472999999996, 59.985996],
          [30.532358, 59.98588999999998],
          [30.532341, 59.98576000000003],
          [30.532372, 59.985671999999994],
          [30.532585000000005, 59.98532499999999],
          [30.532523999999995, 59.985184000000004],
          [30.532778, 59.984849999999966],
          [30.533135999999995, 59.984642000000036],
          [30.533127000000004, 59.98451600000001],
          [30.532927, 59.984398],
          [30.532383, 59.98410999999996],
          [30.53211, 59.98412300000001],
          [30.531492, 59.98431400000001],
          [30.531402999999997, 59.98452399999999],
          [30.531141, 59.984669999999994],
          [30.530815, 59.98460800000001],
          [30.53065, 59.984238000000005],
          [30.530834, 59.98390599999999],
          [30.531198999999997, 59.983532],
          [30.531246, 59.98329500000003],
          [30.53092, 59.98313000000002],
          [30.530132, 59.983047],
          [30.529959, 59.98295600000003],
          [30.529675999999995, 59.982770000000016],
          [30.528674999999996, 59.98240300000003],
          [30.528065, 59.98210499999999],
          [30.528212, 59.982044],
          [30.528781999999996, 59.981888],
          [30.529335, 59.982020000000006],
          [30.530289999999997, 59.981930000000006],
          [30.530739000000004, 59.98205999999999],
          [30.530867, 59.981922],
          [30.530954, 59.98185999999998],
          [30.531073, 59.98192],
          [30.531355, 59.981762],
          [30.531479, 59.981780000000015],
          [30.531525, 59.981829999999974],
          [30.531548, 59.981960000000015],
          [30.531981999999996, 59.98227299999999],
          [30.532068000000002, 59.98230399999997],
          [30.532149999999998, 59.982444999999984],
          [30.532352000000003, 59.982489999999984],
          [30.532515999999998, 59.98258199999998],
          [30.532421000000003, 59.98266000000001],
          [30.53271, 59.982788],
          [30.532918999999996, 59.982770000000016],
          [30.534371999999998, 59.982770000000016],
          [30.534464, 59.98273999999998],
          [30.534428, 59.982665999999995],
          [30.534453999999997, 59.982613000000015],
          [30.534536000000003, 59.982597],
          [30.534925, 59.98257000000004],
          [30.53519, 59.98261600000001],
          [30.535368, 59.982613000000015],
          [30.53541, 59.98265000000001],
          [30.535423, 59.98275000000001],
          [30.535566000000003, 59.98285999999999],
          [30.53568, 59.982924999999994],
          [30.535793, 59.982906000000014],
          [30.535862, 59.98279599999998],
          [30.536045000000005, 59.98277300000001],
          [30.536145999999995, 59.982815000000016],
          [30.536032, 59.982876000000005],
          [30.536018, 59.98295000000002],
          [30.536110000000004, 59.98297499999995],
          [30.53626, 59.982939999999985],
          [30.536558000000003, 59.98275799999999],
          [30.53652, 59.98268999999999],
          [30.536365999999997, 59.98265000000001],
          [30.536370000000005, 59.98258199999998],
          [30.53647, 59.98252500000001],
          [30.536594, 59.98251300000001],
          [30.536915, 59.982635000000016],
          [30.537046000000004, 59.98264],
          [30.537138000000002, 59.98259999999999],
          [30.537266, 59.98263],
          [30.537407, 59.982635000000016],
          [30.537407, 59.98259999999999],
          [30.537266, 59.982574],
          [30.53723, 59.982489999999984],
          [30.537366999999996, 59.982467999999955],
          [30.537376, 59.98241999999999],
          [30.537321, 59.982417999999996],
          [30.537361, 59.98235300000002],
          [30.537672000000004, 59.982372],
          [30.537806000000003, 59.982309999999956],
          [30.537943, 59.982320000000016],
          [30.53796, 59.98240999999999],
          [30.538189, 59.98235700000001],
          [30.538109, 59.982258],
          [30.538386999999997, 59.982219999999984],
          [30.538502, 59.98229599999999],
          [30.538662, 59.98227],
          [30.538822000000003, 59.98219299999997],
          [30.538948000000005, 59.98216200000002],
          [30.539190000000005, 59.98213999999999],
          [30.539212999999997, 59.98204799999999],
          [30.539291, 59.982],
          [30.539457000000002, 59.98196999999999],
          [30.539570000000005, 59.98201799999998],
          [30.539717000000003, 59.982],
          [30.53963, 59.981937000000016],
          [30.54004, 59.981853],
          [30.540194, 59.981814999999955],
          [30.540394000000003, 59.981809999999996],
          [30.540461999999998, 59.981780000000015],
          [30.540458999999995, 59.981674],
          [30.540573, 59.98163600000001],
          [30.540619, 59.981539999999995],
          [30.540777, 59.98146399999999],
          [30.540848, 59.981415],
          [30.540704999999996, 59.98131000000001],
          [30.540719999999997, 59.98127699999998],
          [30.541100000000004, 59.981213],
          [30.541221999999998, 59.98112000000003],
          [30.541409, 59.981100000000026],
          [30.541597, 59.98115000000004],
          [30.541876, 59.98110199999999],
          [30.542036000000003, 59.98112499999999],
          [30.542137, 59.98115999999999],
          [30.542310000000004, 59.981064],
          [30.542483999999998, 59.981064],
          [30.542552999999998, 59.98111],
          [30.542976, 59.981060000000014],
          [30.54379, 59.98098400000001],
          [30.543910000000004, 59.98102],
          [30.544060000000005, 59.98093399999999],
          [30.543960000000002, 59.980819999999994],
          [30.543983, 59.98078000000001],
          [30.544075, 59.98073600000001],
          [30.543997, 59.980617999999964],
          [30.544413, 59.98061999999999],
          [30.544455, 59.98049499999996],
          [30.54453, 59.98048],
          [30.544628, 59.98052000000001],
          [30.545780000000004, 59.980515000000025],
          [30.546576999999996, 59.980545000000006],
          [30.54721, 59.980605999999995],
          [30.547567, 59.98035999999999],
          [30.547745000000003, 59.980335],
          [30.547955, 59.98036999999999],
          [30.54817, 59.98045300000001],
          [30.548187, 59.98050999999998],
          [30.548416, 59.98052200000001],
          [30.548674, 59.980617999999964],
          [30.548677, 59.98068199999997],
          [30.548695000000002, 59.98077000000001],
          [30.548804999999998, 59.980774],
          [30.548860000000005, 59.980705],
          [30.548918, 59.980729999999994],
          [30.548938999999997, 59.98078499999997],
          [30.549192, 59.98084599999996],
          [30.549368, 59.98097200000001],
          [30.549551000000005, 59.980969999999985],
          [30.549738, 59.98097999999999],
          [30.54983, 59.981037000000015],
          [30.549665, 59.981093999999985],
          [30.549627, 59.981155],
          [30.549706, 59.98120499999999],
          [30.549889000000004, 59.98117399999998],
          [30.55004, 59.98122000000001],
          [30.550003, 59.98127699999998],
          [30.55004, 59.981323],
          [30.550135000000004, 59.98131999999998],
          [30.550291, 59.981273999999985],
          [30.550491, 59.98131599999999],
          [30.550697, 59.98137299999999],
          [30.550854000000005, 59.98137299999999],
          [30.551283, 59.98122000000001],
          [30.551976999999997, 59.98115999999999],
          [30.552290000000003, 59.981093999999985],
          [30.552383000000003, 59.98101],
          [30.548399, 59.97919000000002],
          [30.548433, 59.97870600000002],
          [30.551458, 59.97838999999999],
          [30.551729999999996, 59.97943000000001],
          [30.554061999999995, 59.97921800000003],
          [30.554, 59.97815299999999],
          [30.554003, 59.978115],
          [30.554007999999996, 59.978072999999995],
          [30.554087, 59.977180000000004],
          [30.554358, 59.97687000000002],
          [30.554618999999995, 59.97659300000004],
          [30.55489, 59.97630699999999],
          [30.555843000000003, 59.97528],
          [30.557521999999995, 59.974230000000006],
          [30.558208, 59.97381200000001],
          [30.556166, 59.97327999999999],
          [30.55695, 59.97271699999999],
          [30.559410000000003, 59.97336999999996],
          [30.566029000000004, 59.970966000000004],
          [30.565325000000005, 59.97092000000001],
          [30.564768000000004, 59.97068399999998],
          [30.564277999999998, 59.97052400000001],
          [30.563738000000004, 59.970534999999984],
          [30.562536, 59.970290000000006],
          [30.561935, 59.970263999999986],
          [30.560991, 59.970169999999996],
          [30.560621, 59.96986800000002],
          [30.559275000000003, 59.96962000000002],
          [30.555815000000003, 59.96990199999996],
          [30.554314000000005, 59.969604000000004],
          [30.552494000000003, 59.96898999999999],
          [30.545092, 59.97573],
          [30.544485, 59.97628399999999],
          [30.542915, 59.97583],
          [30.542532, 59.97576000000001],
          [30.542130999999998, 59.975708000000026],
          [30.541506000000002, 59.975689999999986],
          [30.539959999999997, 59.97570000000002],
          [30.532927, 59.97394],
          [30.531717000000004, 59.97366999999997],
          [30.529040999999996, 59.973106],
          [30.52877, 59.97305000000003],
          [30.528454, 59.973015000000004],
          [30.528166, 59.97301999999999],
          [30.527969999999996, 59.97305000000003],
          [30.527851, 59.97309499999997],
          [30.526846000000003, 59.97408999999996],
          [30.524899, 59.973600000000005],
          [30.517204000000003, 59.97157300000001],
          [30.51729, 59.97149000000002],
          [30.516895000000005, 59.971385999999995],
          [30.516794, 59.97149000000002],
          [30.514140000000005, 59.97080200000002],
          [30.513739999999995, 59.970699999999994],
          [30.513189999999998, 59.97057699999999],
          [30.513102, 59.970560000000006],
          [30.51244, 59.96785399999999],
          [30.51231, 59.96753000000001],
          [30.512006999999997, 59.96582799999999],
          [30.511347000000004, 59.96238299999999],
          [30.507933, 59.946500000000015],
          [30.507906, 59.94635800000003],
          [30.507751, 59.94562499999998],
          [30.507742, 59.94558000000001],
          [30.507719, 59.94548999999998],
          [30.507705999999995, 59.945435],
          [30.507283999999995, 59.943599999999975],
          [30.50625, 59.93909499999998],
          [30.505526000000003, 59.935944000000006],
          [30.503187, 59.925254999999964],
          [30.500021000000004, 59.91078999999999],
          [30.495537, 59.89201400000002],
          [30.495419000000002, 59.89148],
          [30.497409999999995, 59.89135999999999],
          [30.497599999999995, 59.89053999999999],
          [30.499690999999995, 59.88905299999999],
          [30.501093000000004, 59.88682],
          [30.501059, 59.88626500000001],
          [30.507982, 59.877489999999995],
          [30.511671000000003, 59.872851999999995],
          [30.519999, 59.86322799999999],
          [30.516374999999996, 59.86259000000001],
          [30.508684000000002, 59.861233],
          [30.508, 59.85969],
          [30.5054, 59.85920999999999],
          [30.503870000000003, 59.859492999999986],
          [30.498846, 59.85888699999998],
          [30.498625000000004, 59.85907700000001],
          [30.49763, 59.858990000000006],
          [30.49725, 59.85895500000001],
          [30.497038, 59.85893599999997],
          [30.493492, 59.85820400000003],
          [30.493551000000004, 59.856524999999976],
          [30.493234999999995, 59.85455000000002],
          [30.492409000000002, 59.851729999999975],
          [30.492168000000003, 59.849969999999956],
          [30.492443, 59.84854499999997],
          [30.493492, 59.847083999999995],
          [30.495746999999998, 59.84596299999998],
          [30.500168000000002, 59.84503599999999],
          [30.505743000000002, 59.844166],
          [30.509644, 59.84308599999997],
          [30.513563, 59.84162499999999],
          [30.519342, 59.83874],
          [30.522884, 59.83704],
          [30.528006, 59.83526599999999],
          [30.532698, 59.834059999999994],
          [30.534856999999995, 59.833755],
          [30.53703, 59.833549999999974],
          [30.540821, 59.83362600000001],
          [30.552094, 59.836389999999994],
          [30.555935, 59.83673999999996],
          [30.558380000000003, 59.83654999999999],
          [30.560357999999997, 59.83608000000001],
          [30.561476, 59.83544999999998],
          [30.562551, 59.83455699999996],
          [30.562847, 59.83362199999999],
          [30.562227, 59.83181000000002],
          [30.56142, 59.829803],
          [30.561201000000004, 59.828772999999984],
          [30.561255000000003, 59.82761000000002],
          [30.562017000000004, 59.82645400000001],
          [30.563143, 59.825455000000034],
          [30.565788000000005, 59.824276],
          [30.567164999999996, 59.823925],
          [30.573184999999995, 59.822395],
          [30.578758, 59.820662999999996],
          [30.589201, 59.81728000000001],
          [30.593794, 59.815192999999994],
          [30.595884000000005, 59.813590000000005],
          [30.597645, 59.81200000000001],
          [30.604326, 59.80830800000004],
          [30.614983, 59.802580000000006],
          [30.622835, 59.79732999999996],
          [30.625599, 59.79547999999997],
          [30.630154000000005, 59.79368599999998],
          [30.635147, 59.79253800000001],
          [30.644349999999996, 59.791424000000006],
          [30.653404, 59.79063400000001],
          [30.66014, 59.78963999999996],
          [30.670101, 59.78647599999999],
          [30.673926999999996, 59.78569799999997],
          [30.676407, 59.785472999999996],
          [30.678581, 59.78527500000001],
          [30.683598, 59.785079999999994],
          [30.686974, 59.78464500000001],
          [30.694664, 59.782753000000014],
          [30.701107, 59.78164000000001],
          [30.705738000000004, 59.781319999999994],
          [30.710405000000005, 59.781292000000036],
          [30.717694999999996, 59.78171499999999],
          [30.72491, 59.78152],
          [30.72998, 59.78101699999999],
          [30.735294, 59.779300000000006],
          [30.733192, 59.777202999999986],
          [30.727905, 59.772580000000005],
          [30.726716999999997, 59.770849999999996],
          [30.728070999999996, 59.77057300000001],
          [30.729897, 59.770186999999964],
          [30.734419000000003, 59.76921500000003],
          [30.740002, 59.765209999999996],
          [30.737358, 59.75967],
          [30.744135, 59.75624500000001],
          [30.746304, 59.75576000000001],
          [30.748764, 59.755449999999996],
          [30.750359, 59.75546299999999],
          [30.752762, 59.75555399999996],
          [30.75766, 59.756294],
          [30.758934, 59.75524999999999],
          [30.759492999999996, 59.754726000000005],
          [30.759466, 59.75421499999999],
          [30.759417000000003, 59.75375700000001],
          [30.758890000000005, 59.75336999999999],
          [30.757954000000005, 59.753056000000015],
          [30.75681, 59.752903],
          [30.752832, 59.75293400000001],
          [30.750875, 59.752583000000016],
          [30.749306, 59.751937999999996],
          [30.748713000000002, 59.751120000000014],
          [30.748913, 59.749570000000006],
          [30.749966000000004, 59.748515999999995],
          [30.750792000000004, 59.748013000000014],
          [30.753277, 59.746703999999994],
          [30.75469, 59.74566999999999],
          [30.756472000000002, 59.74452200000002],
          [30.757010999999995, 59.74374999999998],
          [30.75492, 59.74384299999997],
          [30.737604, 59.74489],
          [30.703054000000005, 59.745284999999996],
          [30.695739999999997, 59.74374799999998],
          [30.680134, 59.72998399999997],
          [30.682983, 59.72492],
          [30.650354, 59.721867],
          [30.64817, 59.721270000000004],
          [30.647762, 59.722027],
          [30.645744, 59.72375499999998],
          [30.643475, 59.72547],
          [30.64326, 59.725414],
          [30.642884999999996, 59.72559699999999],
          [30.640635999999997, 59.72744],
          [30.640617, 59.72753499999999],
          [30.640694, 59.727720000000005],
          [30.629978000000005, 59.73581000000004],
          [30.626919999999995, 59.73574999999997],
          [30.62505, 59.735714],
          [30.62224, 59.735614999999996],
          [30.621880000000004, 59.73577000000003],
          [30.621683, 59.73569499999999],
          [30.621315, 59.735878000000014],
          [30.620112999999996, 59.73552000000001],
          [30.619854, 59.73546200000001],
          [30.619560000000003, 59.735393999999985],
          [30.61824, 59.7354],
          [30.617792, 59.73579399999997],
          [30.616669, 59.735412999999966],
          [30.616967999999996, 59.73349399999998],
          [30.617338, 59.73086000000001],
          [30.612180000000002, 59.73073600000001],
          [30.609259, 59.73065600000004],
          [30.60704, 59.73058],
          [30.605898, 59.73045300000001],
          [30.600916, 59.729550000000046],
          [30.598394, 59.729275],
          [30.59135, 59.72912199999999],
          [30.588458999999997, 59.72979999999998],
          [30.584208, 59.729645000000005],
          [30.583875999999997, 59.72956500000001],
          [30.582226, 59.72958],
          [30.576506, 59.729973],
          [30.572618000000002, 59.72194299999998],
          [30.572609, 59.72127499999999],
          [30.567446, 59.71290600000003],
          [30.564104000000004, 59.705070000000006],
          [30.552096999999996, 59.705929999999995],
          [30.53069, 59.707325],
          [30.53004, 59.70444499999999],
          [30.524495999999996, 59.70427699999999],
          [30.524725, 59.70278499999998],
          [30.524479, 59.702248],
          [30.524433, 59.70038199999999],
          [30.524128, 59.700085],
          [30.522098999999997, 59.699467],
          [30.520811, 59.69776000000002],
          [30.520088, 59.697216],
          [30.519589999999997, 59.696500000000015],
          [30.520245, 59.69612999999998],
          [30.522266, 59.69588999999999],
          [30.522859999999998, 59.69514000000001],
          [30.523275, 59.693577000000005],
          [30.522299, 59.692173],
          [30.520540000000004, 59.690945],
          [30.512093, 59.690300000000036],
          [30.498722, 59.68924000000001],
          [30.488560000000003, 59.68854999999999],
          [30.48834, 59.688564000000014],
          [30.488132, 59.688582999999994],
          [30.488013999999996, 59.688590000000005],
          [30.487883000000004, 59.68839299999999],
          [30.487585, 59.687827999999996],
          [30.48722, 59.687175999999994],
          [30.48683, 59.686477999999994],
          [30.486632999999998, 59.686127],
          [30.486023000000003, 59.685036],
          [30.486, 59.684901999999994],
          [30.484990999999997, 59.684387000000015],
          [30.477121, 59.68070000000003],
          [30.477972, 59.677959999999985],
          [30.479065000000002, 59.677670000000006],
          [30.480795, 59.67779999999999],
          [30.486843, 59.679024],
          [30.487478, 59.677536],
          [30.490637, 59.677628],
          [30.490903999999997, 59.67392000000001],
          [30.489983000000002, 59.669974999999994],
          [30.490969, 59.669994],
          [30.492167, 59.67002500000001],
          [30.493404000000005, 59.67006999999998],
          [30.494587, 59.670094000000006],
          [30.498323000000003, 59.670162000000005],
          [30.501904000000003, 59.67024599999999],
          [30.502417, 59.66480299999998],
          [30.505539000000002, 59.646169999999984],
          [30.505414999999996, 59.646152],
          [30.505148, 59.64613700000004],
          [30.504976, 59.64616000000001],
          [30.504852, 59.646119999999996],
          [30.504548999999997, 59.64604],
          [30.50379, 59.64594700000001],
          [30.503143000000005, 59.645836],
          [30.502153, 59.645606999999984],
          [30.501347000000003, 59.645363],
          [30.50042, 59.645138],
          [30.499792, 59.64496600000001],
          [30.498847999999995, 59.64469500000001],
          [30.498322, 59.64454699999999],
          [30.497874999999997, 59.64447799999999],
          [30.497275999999996, 59.644454999999994],
          [30.496756, 59.64450000000002],
          [30.496261999999998, 59.64452399999996],
          [30.495633999999995, 59.644465999999994],
          [30.494720000000004, 59.644356000000016],
          [30.493983999999998, 59.64423400000001],
          [30.49323, 59.64405399999998],
          [30.492529999999995, 59.643939999999986],
          [30.492283, 59.64385600000003],
          [30.491830000000004, 59.643756999999965],
          [30.491375, 59.643610000000024],
          [30.491085000000005, 59.643496999999996],
          [30.490498000000002, 59.64332000000002],
          [30.489933, 59.643276000000014],
          [30.489618000000004, 59.643253000000016],
          [30.489316999999996, 59.643215],
          [30.489056, 59.643223000000006],
          [30.488771, 59.643215],
          [30.488585999999998, 59.64312000000001],
          [30.488499, 59.64296999999999],
          [30.488527, 59.64272],
          [30.488628, 59.64245199999996],
          [30.488766, 59.64230700000002],
          [30.488842, 59.642105000000015],
          [30.488852999999995, 59.641953],
          [30.488824999999995, 59.64190300000001],
          [30.48873, 59.641872000000006],
          [30.488440000000004, 59.64184],
          [30.488111000000004, 59.64183],
          [30.487425, 59.64171000000002],
          [30.486890000000002, 59.64161999999999],
          [30.486599, 59.64156299999999],
          [30.486416, 59.64149499999999],
          [30.48618, 59.641414999999995],
          [30.485865, 59.64129],
          [30.485794, 59.64116999999999],
          [30.485639999999997, 59.64105599999996],
          [30.485527, 59.64092299999999],
          [30.485489999999995, 59.64081999999999],
          [30.485532999999997, 59.64070000000001],
          [30.485639999999997, 59.64055299999998],
          [30.485746, 59.64041499999999],
          [30.485806000000004, 59.64030500000001],
          [30.485828, 59.640198],
          [30.4858, 59.640052999999995],
          [30.485615000000003, 59.639729999999986],
          [30.485454999999998, 59.63950700000001],
          [30.485325, 59.63940400000001],
          [30.4852, 59.63932399999999],
          [30.484980000000004, 59.639263],
          [30.484797, 59.63923299999999],
          [30.484524, 59.63924],
          [30.484037, 59.63929000000002],
          [30.483759, 59.639309999999995],
          [30.483503, 59.63929999999999],
          [30.483236, 59.639275000000026],
          [30.483017, 59.63922999999997],
          [30.482851000000004, 59.639160000000004],
          [30.482733, 59.63905700000001],
          [30.482662000000005, 59.638915999999995],
          [30.482626, 59.63871000000003],
          [30.482620000000004, 59.638570000000044],
          [30.482590000000002, 59.638423999999986],
          [30.482572999999995, 59.63824499999998],
          [30.482471000000004, 59.63817599999999],
          [30.482216, 59.638023000000004],
          [30.481932, 59.63789700000001],
          [30.481653, 59.63780000000003],
          [30.481249, 59.63771399999999],
          [30.480698, 59.63763],
          [30.480323999999996, 59.637585],
          [30.479939, 59.637560000000036],
          [30.479683, 59.63755800000001],
          [30.479362000000002, 59.637595999999974],
          [30.479209999999995, 59.637639999999976],
          [30.479067000000004, 59.63771],
          [30.478942999999997, 59.63780600000001],
          [30.478770000000004, 59.63789],
          [30.478313, 59.63809999999998],
          [30.478052000000005, 59.63823000000002],
          [30.47781, 59.63830999999999],
          [30.477144, 59.638615000000016],
          [30.476742, 59.638799999999975],
          [30.476545000000005, 59.638926999999995],
          [30.476416, 59.639070000000004],
          [30.476332, 59.63919400000003],
          [30.476149000000003, 59.63930500000001],
          [30.475821999999997, 59.63945000000001],
          [30.475437, 59.639576000000005],
          [30.475144999999998, 59.63962000000001],
          [30.474883999999996, 59.639633],
          [30.474629999999998, 59.63956500000003],
          [30.474352, 59.63949599999998],
          [30.47408, 59.6395],
          [30.473858000000003, 59.639534],
          [30.47368, 59.639587000000006],
          [30.47342, 59.639645],
          [30.473218999999997, 59.639669999999995],
          [30.473022, 59.63971700000002],
          [30.472857000000005, 59.63982800000002],
          [30.472702, 59.63989599999999],
          [30.472572, 59.639984],
          [30.472488, 59.64004],
          [30.472305, 59.640060000000005],
          [30.472033, 59.640068000000014],
          [30.471765999999995, 59.640044999999986],
          [30.471510000000002, 59.63998000000001],
          [30.471225999999998, 59.63987],
          [30.471005999999996, 59.639842999999985],
          [30.470804, 59.63978],
          [30.47072, 59.63974999999999],
          [30.47065, 59.63978599999999],
          [30.470549, 59.63988499999999],
          [30.470366, 59.63999000000001],
          [30.470139999999997, 59.64010600000003],
          [30.469907999999997, 59.64017999999999],
          [30.469719, 59.640186],
          [30.469593, 59.64013700000001],
          [30.469475000000003, 59.64005700000004],
          [30.469392999999997, 59.63996999999998],
          [30.469362, 59.63984000000002],
          [30.469349999999995, 59.63973200000001],
          [30.469297, 59.639669999999995],
          [30.469041999999998, 59.63962000000001],
          [30.468727, 59.639602999999994],
          [30.468372, 59.639579999999995],
          [30.46807, 59.639534],
          [30.467779999999998, 59.63948399999998],
          [30.467567, 59.63944200000003],
          [30.455894, 59.64823999999999],
          [30.446921999999997, 59.65496999999999],
          [30.444360000000003, 59.65688699999998],
          [30.439542999999997, 59.66057000000001],
          [30.43818, 59.661602000000016],
          [30.436388, 59.662980000000005],
          [30.429792000000003, 59.662710000000004],
          [30.423894999999998, 59.662510000000026],
          [30.4241, 59.662464],
          [30.424053, 59.66240299999998],
          [30.423983, 59.66237000000001],
          [30.423857000000005, 59.66237000000001],
          [30.423817, 59.662323000000015],
          [30.423887, 59.66226],
          [30.42399, 59.66220000000001],
          [30.424053, 59.662082999999996],
          [30.42406, 59.66198299999999],
          [30.424038, 59.66188],
          [30.423935000000004, 59.661790000000025],
          [30.423943, 59.661670000000015],
          [30.423957999999995, 59.661587],
          [30.423998, 59.661456999999956],
          [30.42405, 59.66139599999997],
          [30.423964000000005, 59.66136000000003],
          [30.423979, 59.661304],
          [30.42405, 59.66124300000001],
          [30.424056999999998, 59.661167000000006],
          [30.424064999999995, 59.66109],
          [30.424223, 59.66105999999999],
          [30.424278, 59.661],
          [30.424119999999995, 59.660923],
          [30.424056999999998, 59.66084000000001],
          [30.423971, 59.66074800000001],
          [30.423945999999997, 59.66059999999999],
          [30.423837999999996, 59.66050000000004],
          [30.423655, 59.66036],
          [30.423655, 59.66027500000001],
          [30.423529, 59.660247999999996],
          [30.423521, 59.660169999999994],
          [30.423456, 59.66008399999998],
          [30.423310999999998, 59.660019999999975],
          [30.423168, 59.65993],
          [30.423117, 59.65989300000001],
          [30.423101, 59.65984],
          [30.423091999999997, 59.659767000000016],
          [30.423052, 59.65971400000001],
          [30.42302, 59.659670000000006],
          [30.422976, 59.65959000000001],
          [30.423, 59.65952300000001],
          [30.422853, 59.659519999999986],
          [30.422795999999998, 59.65947700000001],
          [30.422860999999997, 59.65939],
          [30.422885999999995, 59.65929800000001],
          [30.422894, 59.65921400000002],
          [30.422853, 59.659159999999986],
          [30.422812, 59.65912],
          [30.422853, 59.65906000000004],
          [30.422836000000004, 59.65899000000002],
          [30.422747, 59.658962],
          [30.422665, 59.65893600000001],
          [30.422665, 59.65886999999998],
          [30.422663, 59.65878699999999],
          [30.422558, 59.658755999999954],
          [30.422539000000004, 59.658726],
          [30.42257, 59.658694999999966],
          [30.422561999999996, 59.65863999999999],
          [30.42257, 59.658585000000016],
          [30.422521999999997, 59.658524],
          [30.42253, 59.65841999999998],
          [30.422525000000004, 59.65834000000001],
          [30.422575000000002, 59.65829500000001],
          [30.42253, 59.65823],
          [30.422441000000003, 59.658169999999984],
          [30.422411, 59.658085],
          [30.422411, 59.657985999999994],
          [30.422407, 59.657883],
          [30.422384, 59.657825],
          [30.422258, 59.657790000000006],
          [30.422280999999998, 59.657707000000016],
          [30.422277, 59.657623],
          [30.422232, 59.657523999999995],
          [30.422234, 59.65748599999998],
          [30.422134, 59.65738999999999],
          [30.422165, 59.65728999999999],
          [30.422183999999998, 59.657185],
          [30.422125, 59.657066000000015],
          [30.421997000000005, 59.65699000000001],
          [30.421837, 59.65701999999999],
          [30.421793000000005, 59.65696300000002],
          [30.421864, 59.65688299999999],
          [30.42178, 59.656807000000015],
          [30.421713, 59.65675399999998],
          [30.421700000000005, 59.656654],
          [30.421666999999996, 59.656597000000005],
          [30.42153, 59.65651700000001],
          [30.421379999999996, 59.656406000000004],
          [30.421307, 59.65633],
          [30.421228, 59.656216],
          [30.421081999999995, 59.656120000000016],
          [30.420952, 59.65604400000001],
          [30.420517000000004, 59.65579600000004],
          [30.420243999999997, 59.655674000000005],
          [30.42001, 59.655598],
          [30.41974, 59.65551399999998],
          [30.419636000000004, 59.65546800000001],
          [30.419594000000004, 59.655426000000006],
          [30.419566999999997, 59.655384],
          [30.419596, 59.65535399999999],
          [30.419634000000002, 59.65533400000004],
          [30.419602999999995, 59.65530799999996],
          [30.419533, 59.65528499999999],
          [30.419329, 59.65523000000002],
          [30.419216, 59.655212000000006],
          [30.419165, 59.655227999999994],
          [30.41911, 59.65520499999997],
          [30.419048, 59.65520499999997],
          [30.418995000000002, 59.655163000000016],
          [30.418839, 59.65511000000001],
          [30.418674, 59.65503699999999],
          [30.418554, 59.65495699999997],
          [30.418488, 59.654865],
          [30.418364, 59.654815999999954],
          [30.418319999999998, 59.654765999999995],
          [30.418213000000005, 59.654690000000045],
          [30.418093, 59.654600000000016],
          [30.417967000000004, 59.65451999999999],
          [30.417830000000002, 59.65446],
          [30.417651999999997, 59.65443400000001],
          [30.417558999999997, 59.65444600000001],
          [30.417482, 59.654465000000016],
          [30.417429, 59.654439999999994],
          [30.41738, 59.65440399999997],
          [30.417318000000005, 59.65435400000001],
          [30.417215, 59.654330000000016],
          [30.41718, 59.654300000000006],
          [30.417288000000003, 59.65427399999999],
          [30.417339999999996, 59.65417500000001],
          [30.417292, 59.654007000000036],
          [30.417189, 59.653959999999984],
          [30.417141000000004, 59.65392700000001],
          [30.417108999999996, 59.65389299999998],
          [30.416961999999998, 59.653846999999985],
          [30.416854999999998, 59.653774],
          [30.416512, 59.653610000000015],
          [30.416384, 59.653492],
          [30.416187, 59.65340800000001],
          [30.416079999999997, 59.653313],
          [30.415993, 59.65323000000001],
          [30.41584, 59.653156000000024],
          [30.415722, 59.653126000000015],
          [30.415739999999996, 59.652953999999966],
          [30.415482, 59.652985],
          [30.415342, 59.65285],
          [30.415276999999996, 59.65278000000001],
          [30.415174000000004, 59.652702000000005],
          [30.415034999999996, 59.652702000000005],
          [30.41496, 59.652668000000006],
          [30.414948, 59.65262000000001],
          [30.414950999999995, 59.65259600000002],
          [30.414830999999996, 59.65258399999999],
          [30.414783, 59.65253000000004],
          [30.414707, 59.65244000000001],
          [30.414683999999998, 59.65241],
          [30.414259, 59.65175199999999],
          [30.413832, 59.65185500000001],
          [30.413243999999995, 59.65118000000001],
          [30.411900999999997, 59.651764000000014],
          [30.410595, 59.65069],
          [30.407562000000002, 59.64952499999998],
          [30.406370000000003, 59.64945000000003],
          [30.405043, 59.65025700000001],
          [30.404324, 59.65052399999999],
          [30.403267, 59.65091000000001],
          [30.402883999999997, 59.650653999999975],
          [30.401585000000004, 59.65113999999997],
          [30.400648000000004, 59.65043299999999],
          [30.399710000000002, 59.65078700000001],
          [30.39641, 59.651634],
          [30.395214, 59.651634],
          [30.393770000000004, 59.650856000000005],
          [30.393476, 59.65084000000002],
          [30.39156, 59.649670000000015],
          [30.387655, 59.648439999999994],
          [30.387669, 59.648323000000005],
          [30.386893999999998, 59.648033],
          [30.385602999999996, 59.647550000000024],
          [30.382300000000004, 59.64877999999999],
          [30.38208, 59.64865],
          [30.381730000000005, 59.64877999999999],
          [30.382013000000004, 59.64892600000002],
          [30.380343999999997, 59.649531999999994],
          [30.378931, 59.64970800000003],
          [30.366669, 59.650192000000004],
          [30.36665, 59.650295],
          [30.366636, 59.650368000000014],
          [30.357705999999997, 59.653187],
          [30.351858, 59.655550000000034],
          [30.351921, 59.65823],
          [30.345567999999997, 59.65789000000001],
          [30.345425, 59.657809999999955],
          [30.345211, 59.657707000000016],
          [30.326694, 59.65684999999999],
          [30.326498, 59.656859999999995],
          [30.306505, 59.662093999999996],
          [30.303429, 59.659763],
          [30.303023999999997, 59.659683],
          [30.300973999999997, 59.658626999999996],
          [30.30053, 59.658344],
          [30.258701000000002, 59.63624999999999],
          [30.254171000000003, 59.63378],
          [30.246617999999998, 59.63727],
          [30.246277000000003, 59.637103999999965],
          [30.245933999999995, 59.637135],
          [30.242922, 59.638630000000006],
          [30.2525, 59.644394000000005],
          [30.255306, 59.645579999999995],
          [30.261343, 59.648567000000014],
          [30.261541, 59.64865499999996],
          [30.253157000000005, 59.65263999999999],
          [30.252512, 59.65275600000001],
          [30.24917, 59.65302300000002],
          [30.247803, 59.65320199999999],
          [30.24668, 59.65329],
          [30.245358, 59.65339],
          [30.244074, 59.65421700000002],
          [30.243979, 59.65511000000001],
          [30.242452999999998, 59.65814599999999],
          [30.242373000000004, 59.658794],
          [30.242388, 59.660675],
          [30.24304, 59.66087999999999],
          [30.243042, 59.660995000000014],
          [30.243052, 59.66102599999999],
          [30.242743, 59.66105699999997],
          [30.242803999999996, 59.66129999999998],
          [30.24182, 59.661490000000015],
          [30.241756, 59.661407],
          [30.241665, 59.661280000000005],
          [30.238630000000004, 59.66178500000001],
          [30.239890000000003, 59.66299000000001],
          [30.242512000000005, 59.66590500000001],
          [30.243930000000002, 59.66708399999999],
          [30.24641, 59.66891500000003],
          [30.249575000000004, 59.67102],
          [30.248787000000004, 59.67123000000001],
          [30.242400000000004, 59.67078999999998],
          [30.235838000000005, 59.672439999999995],
          [30.235280000000003, 59.67287399999998],
          [30.22959, 59.673396999999966],
          [30.228439999999996, 59.67118500000001],
          [30.228203, 59.67072999999999],
          [30.227528, 59.669430000000006],
          [30.222569999999997, 59.67063999999999],
          [30.218224, 59.67306999999997],
          [30.21995, 59.67430999999999],
          [30.226498, 59.68146999999999],
          [30.228989, 59.68414000000001],
          [30.232859999999995, 59.685055000000034],
          [30.236456, 59.68602799999999],
          [30.231665, 59.68938399999999],
          [30.237057000000004, 59.691772000000014],
          [30.235596, 59.692665000000005],
          [30.240503000000004, 59.69491000000002],
          [30.240446000000002, 59.695312],
          [30.239904000000003, 59.69547299999999],
          [30.247736, 59.69936000000001],
          [30.251844, 59.700905000000034],
          [30.25562, 59.70172500000001],
          [30.258614, 59.704853000000014],
          [30.260014, 59.70754199999996],
          [30.261908, 59.70850999999999],
          [30.267486999999996, 59.713700000000046],
          [30.267904, 59.713963000000035],
          [30.268242, 59.714077],
          [30.269523999999997, 59.71665600000003],
          [30.275871000000002, 59.71521000000001],
          [30.279440000000005, 59.71867],
          [30.277527000000003, 59.7191],
          [30.275742, 59.71949999999998],
          [30.271322, 59.720500000000015],
          [30.271368000000002, 59.72055400000002],
          [30.271633, 59.720879999999994],
          [30.272732, 59.72220600000003],
          [30.272375000000004, 59.722282000000035],
          [30.272506999999997, 59.723243999999994],
          [30.280510000000003, 59.727665],
          [30.28358, 59.729206000000005],
          [30.282658, 59.731502999999975],
          [30.282387, 59.732173999999986],
          [30.279504999999997, 59.739329999999995],
          [30.292072, 59.73614499999999],
          [30.292068000000004, 59.736828],
          [30.293433999999998, 59.740170000000006],
          [30.293907, 59.74085600000001],
          [30.30072, 59.747387],
          [30.258558, 59.76267999999999],
          [30.258589, 59.762722],
          [30.264406, 59.77095400000002],
          [30.270899, 59.774040000000014],
          [30.268789999999996, 59.774726999999984],
          [30.273845999999995, 59.777615000000026],
          [30.271156, 59.778594999999996],
          [30.28985, 59.790400000000005],
          [30.289791, 59.79145],
          [30.289776, 59.79170000000002],
          [30.289764000000005, 59.791911999999996],
          [30.286530000000003, 59.793728000000016],
          [30.285872, 59.79409799999999],
          [30.285408000000004, 59.79435999999998],
          [30.28433, 59.79496400000002],
          [30.283842, 59.79524000000001],
          [30.281397, 59.79514299999997],
          [30.277147000000003, 59.79498000000001],
          [30.27011, 59.794460000000015],
          [30.270496, 59.78979000000001],
          [30.266860999999995, 59.790324999999996],
          [30.260626000000002, 59.791240000000016],
          [30.257017, 59.791780000000045],
          [30.245360000000005, 59.79348999999999],
          [30.244244, 59.793655],
          [30.23972, 59.79433399999999],
          [30.238602000000004, 59.7945],
          [30.236917000000002, 59.79474999999999],
          [30.231236000000003, 59.795563000000016],
          [30.228946999999998, 59.79590000000002],
          [30.228123, 59.79602],
          [30.225378000000003, 59.79642000000001],
          [30.223877000000005, 59.79664199999996],
          [30.221325000000004, 59.79702800000001],
          [30.218586, 59.79718399999999],
          [30.215197, 59.79741999999999],
          [30.211315, 59.79768999999999],
          [30.211393, 59.797540000000026],
          [30.210888, 59.797382],
          [30.210466, 59.79748000000001],
          [30.210098, 59.79748000000001],
          [30.209162, 59.79731000000001],
          [30.207935000000003, 59.79702400000002],
          [30.206493, 59.79672600000001],
          [30.205183, 59.79650000000001],
          [30.204308, 59.79619199999999],
          [30.203697, 59.796097],
          [30.201234999999997, 59.79549399999999],
          [30.19921, 59.79489000000001],
          [30.198814, 59.79475400000001],
          [30.198084, 59.79455999999999],
          [30.19696, 59.794185999999996],
          [30.195432999999998, 59.793728000000016],
          [30.194008000000004, 59.79324],
          [30.192703, 59.79278600000001],
          [30.192343000000005, 59.79273199999997],
          [30.190926, 59.792249999999996],
          [30.189837, 59.791866],
          [30.188917, 59.79148000000001],
          [30.188395000000003, 59.79140499999997],
          [30.187819, 59.791330000000016],
          [30.185408000000002, 59.791134],
          [30.184505000000005, 59.791026999999985],
          [30.183502, 59.790970000000016],
          [30.182755, 59.79095499999997],
          [30.182060000000003, 59.79106999999999],
          [30.181356000000005, 59.79113000000001],
          [30.180756000000002, 59.79104200000003],
          [30.179922, 59.790886],
          [30.179616999999997, 59.790783000000005],
          [30.179363, 59.790874],
          [30.179072999999995, 59.790974000000006],
          [30.177529999999997, 59.79151999999996],
          [30.179820999999997, 59.79348400000001],
          [30.176563, 59.79605000000001],
          [30.15932, 59.80117000000001],
          [30.156425000000002, 59.79892699999999],
          [30.155783, 59.79915600000001],
          [30.149933000000004, 59.800926000000004],
          [30.129504999999998, 59.7825],
          [30.12976, 59.782467],
          [30.133259999999996, 59.78200500000003],
          [30.137835, 59.786006999999984],
          [30.139946000000002, 59.78537],
          [30.14393, 59.788723000000005],
          [30.146510999999997, 59.79082500000001],
          [30.14657, 59.790869999999984],
          [30.149885, 59.793544999999995],
          [30.151344000000005, 59.794724],
          [30.154016, 59.79687999999999],
          [30.155428, 59.79678299999998],
          [30.157038000000004, 59.796850000000006],
          [30.15898, 59.79687999999999],
          [30.160185, 59.79687999999999],
          [30.161123, 59.79674],
          [30.16206, 59.79653999999999],
          [30.162812999999996, 59.79626999999999],
          [30.160085999999996, 59.793620000000004],
          [30.160028, 59.79356800000002],
          [30.159734999999998, 59.793293000000034],
          [30.158855, 59.79257200000001],
          [30.159405, 59.79237699999999],
          [30.159363, 59.79230000000001],
          [30.159327, 59.79220000000001],
          [30.159310999999995, 59.79203799999999],
          [30.159327, 59.79182399999999],
          [30.159323, 59.791750000000036],
          [30.159378, 59.79164],
          [30.15942, 59.79146000000003],
          [30.159408999999997, 59.791236999999995],
          [30.15939, 59.79104000000001],
          [30.159363, 59.79092399999999],
          [30.159286, 59.79082500000001],
          [30.159155, 59.79070300000001],
          [30.158990000000003, 59.790565000000015],
          [30.158863, 59.790436],
          [30.158772000000003, 59.7903],
          [30.15871, 59.790092000000016],
          [30.158686, 59.78996999999998],
          [30.15871, 59.78984],
          [30.158844, 59.789649999999966],
          [30.158884000000004, 59.78952799999999],
          [30.158915, 59.78934000000001],
          [30.158955, 59.788981999999976],
          [30.158981, 59.788498000000004],
          [30.158993, 59.78832],
          [30.158993, 59.788185],
          [30.159013999999996, 59.788116],
          [30.15901, 59.788036000000034],
          [30.1589, 59.78797499999999],
          [30.158712, 59.78784999999999],
          [30.158567, 59.78778],
          [30.158392000000003, 59.78767400000001],
          [30.158102, 59.78752000000003],
          [30.157997, 59.78743399999999],
          [30.157707, 59.78740299999998],
          [30.157385, 59.78736500000002],
          [30.15668, 59.78724],
          [30.156287999999996, 59.78716],
          [30.154848, 59.786929999999984],
          [30.154714999999996, 59.786873000000014],
          [30.154465, 59.78672799999998],
          [30.154236, 59.78663999999998],
          [30.154078000000002, 59.786569999999955],
          [30.154036999999995, 59.78621700000002],
          [30.155573000000004, 59.78585000000001],
          [30.155321, 59.78489300000001],
          [30.155100000000004, 59.784885],
          [30.153067, 59.78329000000002],
          [30.149931, 59.78053299999999],
          [30.147433999999997, 59.777980000000014],
          [30.148634, 59.777664000000016],
          [30.148338, 59.77734000000001],
          [30.148505999999998, 59.77729399999998],
          [30.14618, 59.774760000000015],
          [30.146270000000005, 59.77474000000001],
          [30.14599, 59.774384],
          [30.152776999999997, 59.771754999999985],
          [30.143782, 59.76317],
          [30.143017, 59.76244],
          [30.141685, 59.76116999999999],
          [30.122156000000004, 59.76204000000001],
          [30.12017, 59.76209000000003],
          [30.11663, 59.76217700000001],
          [30.116655, 59.761837000000014],
          [30.117986999999996, 59.76177999999999],
          [30.118001999999997, 59.761616000000004],
          [30.118140000000004, 59.76012800000001],
          [30.117016, 59.75719000000001],
          [30.117619, 59.75415000000001],
          [30.119259, 59.74886699999999],
          [30.119907, 59.747355999999996],
          [30.11952, 59.74581499999999],
          [30.115857999999996, 59.741398000000004],
          [30.114921999999996, 59.74125000000001],
          [30.111016999999997, 59.73872],
          [30.109612, 59.73871],
          [30.108686000000002, 59.73781600000001],
          [30.109712999999996, 59.737629999999996],
          [30.109623000000003, 59.737183000000016],
          [30.109309999999997, 59.73689000000002],
          [30.108979999999995, 59.73640400000002],
          [30.110365, 59.736065000000025],
          [30.111984, 59.735634000000005],
          [30.110950000000003, 59.73420300000001],
          [30.110802000000003, 59.734207000000026],
          [30.108632999999998, 59.731030000000004],
          [30.108635, 59.731007000000034],
          [30.106393999999998, 59.730564000000015],
          [30.105986, 59.730457],
          [30.105717, 59.73034000000001],
          [30.105463, 59.73015200000003],
          [30.104968999999997, 59.72952999999998],
          [30.104806999999997, 59.72926000000004],
          [30.104887, 59.729247999999984],
          [30.106016000000004, 59.72913],
          [30.106569999999998, 59.728992000000005],
          [30.106565, 59.727318],
          [30.106393999999998, 59.72710799999999],
          [30.106657, 59.72618499999999],
          [30.10651, 59.72619],
          [30.106827000000003, 59.72537],
          [30.107025, 59.72455000000002],
          [30.108002, 59.72456700000001],
          [30.108715000000004, 59.72397999999998],
          [30.10966, 59.723663000000016],
          [30.109951, 59.723649999999964],
          [30.111065, 59.71669],
          [30.114052, 59.71374],
          [30.113264, 59.71349000000001],
          [30.115033999999998, 59.71182999999999],
          [30.116518, 59.71062499999999],
          [30.116995, 59.710262],
          [30.118414000000005, 59.70934],
          [30.119177, 59.708855],
          [30.120138, 59.70821400000003],
          [30.120697, 59.70784800000001],
          [30.120945000000003, 59.70787999999999],
          [30.121605, 59.70794699999999],
          [30.121328, 59.70846000000003],
          [30.12208, 59.708687],
          [30.122, 59.708804999999984],
          [30.122635, 59.708965000000006],
          [30.123068, 59.708282],
          [30.123497000000004, 59.70826300000002],
          [30.123611, 59.706905000000006],
          [30.124228000000002, 59.70681400000001],
          [30.12522, 59.70675299999999],
          [30.125364, 59.70694],
          [30.129882999999996, 59.70692],
          [30.129989999999996, 59.706875],
          [30.13003, 59.706593],
          [30.131306000000002, 59.70665400000004],
          [30.131291999999995, 59.706894000000005],
          [30.132328000000005, 59.70679999999999],
          [30.132608, 59.70670000000001],
          [30.132767, 59.70609299999998],
          [30.132744000000002, 59.705837],
          [30.133014999999997, 59.70555999999999],
          [30.133082999999996, 59.70531],
          [30.13751, 59.70040499999996],
          [30.136833, 59.70032000000003],
          [30.135917999999997, 59.700316999999984],
          [30.135405, 59.70036999999999],
          [30.135515000000005, 59.700053999999966],
          [30.135959999999997, 59.69952799999999],
          [30.136324000000002, 59.69905499999999],
          [30.136358000000005, 59.698814],
          [30.13632, 59.69822300000001],
          [30.136339999999997, 59.697712000000024],
          [30.136227, 59.69717399999999],
          [30.13613, 59.69683499999999],
          [30.136023, 59.696574999999996],
          [30.135902, 59.696391999999975],
          [30.135704000000004, 59.696247],
          [30.135169999999995, 59.695933999999994],
          [30.134287, 59.69515999999996],
          [30.133743, 59.694755999999984],
          [30.133173, 59.694469999999995],
          [30.132627, 59.69422000000003],
          [30.132227, 59.694046000000014],
          [30.131779, 59.693905],
          [30.131050000000002, 59.693690000000004],
          [30.130634, 59.69359],
          [30.129793, 59.69420200000002],
          [30.124337999999998, 59.692284],
          [30.123272, 59.69213500000001],
          [30.12272, 59.69207],
          [30.122267, 59.69207],
          [30.122204, 59.69204300000001],
          [30.121565, 59.69201699999999],
          [30.121088, 59.692104],
          [30.120310000000003, 59.69211200000001],
          [30.119925999999996, 59.692154000000016],
          [30.119772, 59.692272],
          [30.119898, 59.692440000000005],
          [30.11998, 59.692677],
          [30.119883, 59.693089999999984],
          [30.119719, 59.69355999999999],
          [30.11913, 59.693546],
          [30.11696, 59.69344000000001],
          [30.117052, 59.693591999999995],
          [30.117182, 59.693786999999986],
          [30.117370000000005, 59.693989999999985],
          [30.117756, 59.694298],
          [30.11779, 59.69436000000002],
          [30.117125000000005, 59.694412],
          [30.116506999999995, 59.69439],
          [30.112749999999995, 59.69407000000001],
          [30.112598, 59.69500399999998],
          [30.114201999999995, 59.695065],
          [30.113983, 59.69551999999999],
          [30.114145, 59.696372999999994],
          [30.114359, 59.697044000000034],
          [30.114048, 59.69713200000001],
          [30.11362, 59.697247000000004],
          [30.115002, 59.698143000000016],
          [30.116056, 59.699122999999986],
          [30.115750000000002, 59.69917699999999],
          [30.114254, 59.69943000000001],
          [30.113840000000003, 59.69943599999999],
          [30.113659999999996, 59.69945000000001],
          [30.113432000000003, 59.69926000000001],
          [30.113424, 59.69919999999999],
          [30.113283000000003, 59.69909000000001],
          [30.112972000000003, 59.69898599999999],
          [30.112821999999998, 59.69903600000001],
          [30.112425, 59.699122999999986],
          [30.111840999999995, 59.69919199999998],
          [30.111525, 59.699250000000006],
          [30.111282, 59.699265],
          [30.111028999999995, 59.699265],
          [30.110819, 59.69933],
          [30.110676, 59.69943999999998],
          [30.110386, 59.69959],
          [30.110218000000003, 59.699603999999994],
          [30.110212, 59.69975299999999],
          [30.11009, 59.69991300000001],
          [30.109800000000003, 59.700016000000005],
          [30.10968, 59.70015699999999],
          [30.109697, 59.70033599999999],
          [30.109499999999997, 59.70049],
          [30.109225999999996, 59.70053999999996],
          [30.109182, 59.70058399999999],
          [30.109021999999996, 59.70060999999998],
          [30.108329999999995, 59.700496999999984],
          [30.107409, 59.699715],
          [30.107143000000004, 59.699690000000004],
          [30.106856999999998, 59.69955999999999],
          [30.106796, 59.69923399999999],
          [30.105677000000004, 59.69931],
          [30.102304, 59.699302999999986],
          [30.101042, 59.699209999999994],
          [30.100899000000002, 59.69919999999999],
          [30.08504, 59.710939999999994],
          [30.083054999999998, 59.713149999999985],
          [30.08262, 59.71367999999998],
          [30.082111, 59.714348],
          [30.081127000000002, 59.71576999999999],
          [30.080678999999996, 59.71573000000001],
          [30.078613, 59.716020000000015],
          [30.078245, 59.71634],
          [30.074404000000005, 59.716072],
          [30.073729, 59.71642299999999],
          [30.071713999999997, 59.71668199999999],
          [30.055550000000004, 59.713074000000006],
          [30.051365, 59.716117999999994],
          [30.05048, 59.716987999999986],
          [30.050018000000005, 59.71732299999999],
          [30.04104, 59.71521999999996],
          [30.040403000000005, 59.71549999999999],
          [30.034966, 59.71785399999999],
          [30.03842, 59.720699999999994],
          [30.045777999999995, 59.719584999999995],
          [30.047600000000003, 59.72449499999999],
          [30.047368999999996, 59.725357],
          [30.050255, 59.728200000000015],
          [30.050049, 59.73000300000001],
          [30.050915, 59.73003800000001],
          [30.051332, 59.730343000000005],
          [30.053576, 59.73188399999998],
          [30.053455, 59.731930000000006],
          [30.053690000000003, 59.73208600000001],
          [30.053810000000002, 59.73204399999997],
          [30.055298, 59.733067000000005],
          [30.054888, 59.73326],
          [30.055805000000003, 59.73377199999999],
          [30.056120000000004, 59.73396300000002],
          [30.055092, 59.735184000000004],
          [30.061727999999995, 59.740868000000034],
          [30.064648000000002, 59.74230000000003],
          [30.065560000000005, 59.742744000000016],
          [30.07614, 59.74791999999999],
          [30.070882999999995, 59.749743999999964],
          [30.06399, 59.749886000000004],
          [30.062838, 59.751063999999985],
          [30.062477, 59.752129999999994],
          [30.06826, 59.752729999999985],
          [30.066533999999997, 59.755269999999996],
          [30.071995, 59.755269999999996],
          [30.071867000000005, 59.75599299999999],
          [30.071860999999995, 59.75603000000001],
          [30.0703, 59.756],
          [30.069866, 59.75684699999999],
          [30.06197, 59.75701000000001],
          [30.062014, 59.757687000000004],
          [30.060328, 59.762069999999994],
          [30.057919000000002, 59.76186000000001],
          [30.05636, 59.76172300000002],
          [30.055964, 59.763283],
          [30.043824999999995, 59.76238599999999],
          [30.043343000000004, 59.764183],
          [30.045350000000003, 59.765525999999994],
          [30.045603, 59.765536999999995],
          [30.052261, 59.76585800000001],
          [30.05638, 59.76605600000002],
          [30.061471999999995, 59.76772299999999],
          [30.062923, 59.76819599999999],
          [30.071838000000003, 59.77111400000001],
          [30.077178999999997, 59.77284],
          [30.078272, 59.77319],
          [30.084562, 59.775750000000016],
          [30.083929999999995, 59.778594999999996],
          [30.083977000000004, 59.778614000000005],
          [30.090736, 59.781420000000026],
          [30.09072, 59.781525000000016],
          [30.091417, 59.782065999999986],
          [30.094486000000003, 59.78243000000003],
          [30.094822, 59.782475000000005],
          [30.095816000000003, 59.78261599999999],
          [30.096050000000005, 59.78242],
          [30.096144, 59.78244999999998],
          [30.097050000000003, 59.78272999999999],
          [30.096665999999995, 59.782989999999984],
          [30.09627, 59.78336999999999],
          [30.096922, 59.78358499999999],
          [30.097952, 59.783928],
          [30.097841, 59.78435000000002],
          [30.09851, 59.785259999999994],
          [30.098804, 59.785877],
          [30.098833, 59.786236],
          [30.098659999999995, 59.78708],
          [30.09826, 59.789394000000016],
          [30.098206000000005, 59.790040000000005],
          [30.098265, 59.79059999999998],
          [30.098674999999997, 59.794629999999984],
          [30.0987, 59.794865000000016],
          [30.098687999999996, 59.79518999999999],
          [30.098783, 59.79549800000001],
          [30.099213, 59.796154],
          [30.09929, 59.79630700000001],
          [30.105199999999996, 59.796940000000006],
          [30.105603999999996, 59.79736],
          [30.104897999999995, 59.80286000000001],
          [30.109516, 59.803169999999966],
          [30.109781, 59.80333000000002],
          [30.110023, 59.803259999999995],
          [30.11222, 59.803455000000014],
          [30.123417000000003, 59.80445499999996],
          [30.125328000000003, 59.806520000000006],
          [30.127685999999997, 59.809067],
          [30.104615999999996, 59.81733299999999],
          [30.090965, 59.81486000000004],
          [30.085892, 59.81613999999999],
          [30.075289, 59.81523499999997],
          [30.074708999999995, 59.81521600000002],
          [30.073096999999997, 59.81561700000003],
          [30.070189, 59.815796000000006],
          [30.06931, 59.815826000000044],
          [30.068504000000004, 59.81579999999997],
          [30.067703000000005, 59.815685],
          [30.067068, 59.815597999999994],
          [30.066689, 59.815612999999956],
          [30.066484, 59.81567000000001],
          [30.066112999999998, 59.815845000000024],
          [30.065603, 59.81598300000002],
          [30.065136000000003, 59.81603000000004],
          [30.063665, 59.81609],
          [30.062946, 59.816193],
          [30.058876, 59.816981999999996],
          [30.058685, 59.817080000000004],
          [30.058410000000002, 59.81718999999998],
          [30.058168, 59.817319999999995],
          [30.057858, 59.817425000000014],
          [30.057634, 59.817447999999985],
          [30.057192000000004, 59.81740999999997],
          [30.056590999999997, 59.81729500000003],
          [30.056031999999995, 59.817170000000004],
          [30.055573999999996, 59.817127],
          [30.055069, 59.817110000000014],
          [30.054836, 59.81705500000004],
          [30.054602, 59.81693599999997],
          [30.054367000000003, 59.816856],
          [30.054203, 59.81683000000001],
          [30.053861999999995, 59.816856],
          [30.053617, 59.81687000000002],
          [30.053251, 59.81687499999998],
          [30.053137, 59.81685000000002],
          [30.052810000000004, 59.81684000000001],
          [30.052637, 59.816900000000004],
          [30.052458000000005, 59.816933000000006],
          [30.051256, 59.81702999999999],
          [30.050613, 59.81708499999999],
          [30.049911000000005, 59.81714199999999],
          [30.049755, 59.817233999999985],
          [30.049187000000003, 59.817350000000005],
          [30.047203, 59.817687999999976],
          [30.046967, 59.817734],
          [30.046570000000003, 59.81819999999999],
          [30.046226999999995, 59.818366999999995],
          [30.045657999999996, 59.8186],
          [30.045639, 59.81891300000004],
          [30.045620000000003, 59.81924000000001],
          [30.045433, 59.819412],
          [30.044863, 59.819552999999985],
          [30.044285, 59.819714000000005],
          [30.043704999999996, 59.81984],
          [30.042812, 59.819964999999996],
          [30.042300000000004, 59.820359999999994],
          [30.041955999999995, 59.82069000000004],
          [30.041555, 59.82103699999999],
          [30.041358999999996, 59.82119],
          [30.041191, 59.82123999999999],
          [30.040787, 59.82126199999999],
          [30.04055, 59.821316000000024],
          [30.040194999999997, 59.82157000000001],
          [30.039993, 59.82166699999999],
          [30.0395, 59.82168999999999],
          [30.03921, 59.821747000000045],
          [30.038834, 59.82168999999999],
          [30.038641, 59.82171600000001],
          [30.038588, 59.821803999999986],
          [30.03863, 59.82191499999996],
          [30.038834, 59.822018000000014],
          [30.038706000000005, 59.82211699999999],
          [30.038769000000002, 59.82226600000001],
          [30.038866000000002, 59.82240300000001],
          [30.038889, 59.822495],
          [30.038872, 59.822586],
          [30.038597, 59.82266000000001],
          [30.038375999999996, 59.82268000000002],
          [30.038261, 59.822777],
          [30.038362999999997, 59.822903],
          [30.038608999999997, 59.82303000000002],
          [30.038898, 59.82310000000001],
          [30.038801, 59.82330300000001],
          [30.03893, 59.82340000000002],
          [30.038706000000005, 59.823466999999994],
          [30.038760000000003, 59.82356999999999],
          [30.039198, 59.823597000000035],
          [30.039349, 59.82365800000002],
          [30.039638999999998, 59.82388000000003],
          [30.039618, 59.823969999999974],
          [30.039433999999996, 59.82405],
          [30.039433999999996, 59.82412000000002],
          [30.039618, 59.82413],
          [30.039744999999996, 59.824104000000005],
          [30.039928, 59.824141999999966],
          [30.039917, 59.82420300000001],
          [30.039842999999998, 59.82425999999998],
          [30.039938000000003, 59.824287],
          [30.040174, 59.82425699999999],
          [30.040174, 59.824153999999965],
          [30.040281, 59.824153999999965],
          [30.040346000000003, 59.82423],
          [30.040337, 59.82425999999998],
          [30.040485, 59.824250000000006],
          [30.040679999999995, 59.82424499999999],
          [30.040796, 59.824332999999996],
          [30.040852, 59.82446300000001],
          [30.040754, 59.82450499999996],
          [30.040820000000004, 59.824832999999984],
          [30.040796, 59.82489000000004],
          [30.040716, 59.82495],
          [30.040462, 59.82490000000004],
          [30.040367, 59.824963],
          [30.040412999999997, 59.82501000000002],
          [30.040596, 59.825042999999994],
          [30.040741000000004, 59.82509999999999],
          [30.040679999999995, 59.82531399999999],
          [30.040379, 59.825526999999994],
          [30.040002999999995, 59.825626],
          [30.040293, 59.82580999999999],
          [30.039938000000003, 59.825965999999994],
          [30.039778, 59.82612],
          [30.03952, 59.826267],
          [30.039541, 59.82646600000001],
          [30.039177000000002, 59.826644999999985],
          [30.039079999999995, 59.82671400000004],
          [30.039381000000002, 59.826835999999986],
          [30.039327999999998, 59.82695400000003],
          [30.039369999999998, 59.82720000000003],
          [30.039455, 59.82746499999999],
          [30.039187999999996, 59.82759999999999],
          [30.039135, 59.82780500000001],
          [30.038898, 59.828013999999996],
          [30.038952, 59.828236000000004],
          [30.038963, 59.82852600000001],
          [30.038876999999996, 59.828720000000004],
          [30.038544000000005, 59.82882000000001],
          [30.038425, 59.82894999999999],
          [30.038523, 59.82903999999999],
          [30.038769000000002, 59.82903999999999],
          [30.038963, 59.829147000000006],
          [30.039059000000005, 59.829436999999984],
          [30.03905, 59.82948999999999],
          [30.039187999999996, 59.829710000000006],
          [30.039103, 59.82994000000002],
          [30.038822, 59.82993000000002],
          [30.038714999999996, 59.82998700000002],
          [30.038887, 59.83016599999999],
          [30.038748, 59.83028999999999],
          [30.039025999999996, 59.83041400000002],
          [30.038908000000003, 59.830690000000004],
          [30.039391999999996, 59.830832999999984],
          [30.039574000000005, 59.83082000000002],
          [30.039594999999995, 59.830726999999996],
          [30.039906999999996, 59.83063499999997],
          [30.040111999999997, 59.83062000000001],
          [30.040164999999995, 59.83069599999996],
          [30.040186, 59.830832999999984],
          [30.040357999999998, 59.830917],
          [30.040399999999998, 59.831245000000024],
          [30.040174, 59.8314],
          [30.040014, 59.83151000000001],
          [30.040153999999998, 59.83166],
          [30.040453000000003, 59.83173399999998],
          [30.04039, 59.83185],
          [30.040313999999995, 59.831999999999994],
          [30.041613, 59.832133999999996],
          [30.041763, 59.832436],
          [30.041891000000003, 59.83244300000001],
          [30.042224999999995, 59.832393999999994],
          [30.042343, 59.832436],
          [30.042427, 59.832554000000016],
          [30.042332, 59.832615000000004],
          [30.042042, 59.83273299999999],
          [30.04203, 59.83287999999999],
          [30.042192, 59.833087999999975],
          [30.042512999999996, 59.83315999999999],
          [30.042622, 59.83327],
          [30.042503000000004, 59.83337399999999],
          [30.042288, 59.833410000000015],
          [30.041975000000004, 59.833416],
          [30.041935, 59.83363299999999],
          [30.041685, 59.83382],
          [30.041601, 59.83408],
          [30.041795999999998, 59.834407999999996],
          [30.042266999999995, 59.834564],
          [30.042685, 59.83471300000002],
          [30.042877, 59.83497199999999],
          [30.042831, 59.835094000000026],
          [30.042957, 59.83524700000004],
          [30.04328, 59.83551399999999],
          [30.043447, 59.835903],
          [30.043566, 59.836081999999976],
          [30.043886000000004, 59.83616300000003],
          [30.04408, 59.83647500000001],
          [30.044465999999996, 59.83681999999999],
          [30.044432, 59.837154],
          [30.044245, 59.8373],
          [30.03079, 59.837519999999984],
          [30.030995999999995, 59.83739],
          [30.028154000000004, 59.83616000000001],
          [30.0276, 59.836543999999975],
          [30.020270999999997, 59.83321999999998],
          [30.019012000000004, 59.83479299999999],
          [30.018826, 59.83478500000001],
          [30.018446, 59.834746999999965],
          [30.018082, 59.83479],
          [30.017805, 59.83488],
          [30.017673, 59.83497],
          [30.017633, 59.835094000000026],
          [30.017714, 59.835229999999996],
          [30.017897, 59.83534000000003],
          [30.018188, 59.83543399999999],
          [30.01845, 59.83550300000002],
          [30.018677, 59.83557999999999],
          [30.018751, 59.83568600000001],
          [30.018732, 59.835770000000025],
          [30.018589000000002, 59.83586500000001],
          [30.018280000000004, 59.83597],
          [30.01781, 59.836123999999984],
          [30.017421999999996, 59.836197],
          [30.017110000000002, 59.83622700000001],
          [30.016879999999997, 59.836209999999994],
          [30.01648, 59.836081999999976],
          [30.016220000000004, 59.83596399999999],
          [30.016033, 59.83567399999998],
          [30.015674999999998, 59.835229999999996],
          [30.015368, 59.83506800000001],
          [30.014755000000005, 59.83507500000002],
          [30.014262999999996, 59.83515],
          [30.013838, 59.835364999999996],
          [30.013720000000003, 59.835530000000006],
          [30.013623999999997, 59.83579],
          [30.013542, 59.83600000000001],
          [30.013332, 59.83617799999999],
          [30.013153, 59.83625000000001],
          [30.013006000000004, 59.83623499999999],
          [30.012815, 59.836224000000016],
          [30.012506, 59.83621600000001],
          [30.012199999999996, 59.836117],
          [30.011673000000002, 59.83597],
          [30.011304999999997, 59.835939999999994],
          [30.010815, 59.836017999999996],
          [30.010274999999996, 59.83618000000001],
          [30.009936999999997, 59.836254],
          [30.009499, 59.83634599999999],
          [30.009207000000004, 59.83636999999999],
          [30.008831, 59.836376],
          [30.00822, 59.83633800000001],
          [30.007303, 59.83633800000001],
          [30.00658, 59.836389999999994],
          [30.005903, 59.83647500000001],
          [30.005553999999997, 59.83661699999996],
          [30.00536, 59.836704000000026],
          [30.005028000000003, 59.83672999999999],
          [30.004795, 59.83678999999998],
          [30.004639, 59.83692000000002],
          [30.004423, 59.837017],
          [30.003973, 59.837079999999986],
          [30.003567, 59.83710500000004],
          [30.003328, 59.83706999999998],
          [30.003090000000004, 59.83698699999999],
          [30.002834, 59.836780000000005],
          [30.002615, 59.83654799999999],
          [30.0023, 59.836276999999995],
          [30.002167000000004, 59.836209999999994],
          [30.001904, 59.83609999999999],
          [30.001623000000002, 59.83609799999999],
          [30.001427, 59.836174],
          [30.0012, 59.836292000000014],
          [30.000896, 59.83641],
          [30.00071, 59.83643299999997],
          [30.00037, 59.8365],
          [30.000166, 59.836555000000004],
          [29.999954000000002, 59.836555000000004],
          [29.999622, 59.836510000000004],
          [29.999320000000004, 59.8364],
          [29.999058, 59.83622],
          [29.998910000000002, 59.836014000000006],
          [29.998293, 59.8357],
          [29.998005, 59.835632000000004],
          [29.99782, 59.83552],
          [29.99736, 59.83528000000001],
          [29.996982999999997, 59.835162999999994],
          [29.996695, 59.835170000000005],
          [29.996449, 59.83525],
          [29.996280000000002, 59.835407000000004],
          [29.996100999999996, 59.83548699999997],
          [29.995853, 59.835476],
          [29.99551, 59.83539200000001],
          [29.99532, 59.83521999999999],
          [29.995132000000005, 59.83500000000001],
          [29.994882999999998, 59.83485400000001],
          [29.99474, 59.834659999999985],
          [29.994743, 59.83459499999998],
          [29.994703, 59.834587],
          [29.994520000000005, 59.83429000000001],
          [29.994371000000005, 59.83413299999998],
          [29.994070000000004, 59.834023],
          [29.993864000000002, 59.83389],
          [29.993526000000003, 59.833770000000015],
          [29.993064999999998, 59.833710000000025],
          [29.993029, 59.83370600000001],
          [29.992890000000003, 59.833669999999955],
          [29.992654999999996, 59.833529999999996],
          [29.992542, 59.833410000000015],
          [29.992455000000003, 59.83337800000001],
          [29.992264000000002, 59.833380000000005],
          [29.992002, 59.83342000000002],
          [29.991882, 59.83345800000001],
          [29.991678, 59.833584],
          [29.991573000000002, 59.83366000000001],
          [29.991453000000003, 59.83369400000001],
          [29.99134, 59.833687],
          [29.991213, 59.833619999999996],
          [29.99112, 59.833579999999984],
          [29.991003000000003, 59.83356499999999],
          [29.990705000000002, 59.833590000000015],
          [29.99043, 59.833575999999994],
          [29.990284000000003, 59.833579999999984],
          [29.990135000000002, 59.833607],
          [29.989925, 59.83368300000001],
          [29.989769000000003, 59.833710000000025],
          [29.989574, 59.83370200000002],
          [29.989365, 59.833710000000025],
          [29.989136, 59.83374800000004],
          [29.988867, 59.83377999999999],
          [29.988465999999995, 59.833759999999984],
          [29.988167, 59.83377999999999],
          [29.987509, 59.833855],
          [29.987140000000004, 59.83388500000001],
          [29.986465, 59.834040000000016],
          [29.985873999999995, 59.834130000000016],
          [29.985437, 59.83414500000001],
          [29.985273, 59.83417],
          [29.985112999999995, 59.83429000000001],
          [29.985040000000005, 59.83431999999999],
          [29.984898, 59.83434299999999],
          [29.984415, 59.83435],
          [29.984180000000002, 59.83433500000001],
          [29.983856000000003, 59.834456999999986],
          [29.983559000000003, 59.83449999999999],
          [29.983044000000003, 59.83458299999998],
          [29.982526999999997, 59.83461],
          [29.982124, 59.83464399999997],
          [29.981623, 59.83471700000001],
          [29.981023999999998, 59.83479700000001],
          [29.980698000000004, 59.834835],
          [29.980158, 59.83488],
          [29.97968, 59.834952999999985],
          [29.979283999999996, 59.835019999999986],
          [29.978935000000003, 59.83510999999996],
          [29.978610000000003, 59.83507],
          [29.978357, 59.835094000000026],
          [29.977740999999998, 59.834186999999986],
          [29.944344999999995, 59.82882699999999],
          [29.94126, 59.830783999999994],
          [29.939395999999995, 59.83090200000001],
          [29.939226000000005, 59.830809999999985],
          [29.938711000000005, 59.8304],
          [29.938557000000003, 59.830307000000005],
          [29.938380000000002, 59.83026000000001],
          [29.938223, 59.83024199999997],
          [29.937971, 59.83026000000001],
          [29.937628, 59.830344999999966],
          [29.937298, 59.830433],
          [29.936787, 59.83059],
          [29.937178, 59.83124999999998],
          [29.937466, 59.83173399999998],
          [29.937998, 59.832615000000004],
          [29.93828, 59.833083999999985],
          [29.938576, 59.833572000000004],
          [29.93881, 59.833965000000006],
          [29.939035000000004, 59.83433500000001],
          [29.939177, 59.83456799999999],
          [29.939684000000003, 59.835409999999996],
          [29.939922, 59.835803999999996],
          [29.940200000000004, 59.836265999999966],
          [29.940735, 59.837154],
          [29.941404000000002, 59.83826400000001],
          [29.9416, 59.838593],
          [29.941646999999996, 59.83866999999998],
          [29.941923, 59.839405],
          [29.942629000000004, 59.84049999999999],
          [29.943321000000005, 59.84159],
          [29.943977, 59.84273000000002],
          [29.944258000000005, 59.843196999999975],
          [29.945208, 59.84480300000001],
          [29.925089999999997, 59.84768300000002],
          [29.924837, 59.848736],
          [29.910233, 59.85125399999998],
          [29.909421999999996, 59.848984],
          [29.902315, 59.85017400000001],
          [29.902195000000003, 59.85019299999999],
          [29.90379, 59.853736999999995],
          [29.903796999999997, 59.85374999999999],
          [29.895557, 59.85465199999999],
          [29.895546, 59.854633000000035],
          [29.895247, 59.854206000000005],
          [29.895230000000005, 59.85415599999999],
          [29.895253999999998, 59.85407999999998],
          [29.895258, 59.854029999999995],
          [29.894974, 59.853470000000016],
          [29.892593, 59.853909999999985],
          [29.891558, 59.85178400000001],
          [29.889965000000004, 59.848927],
          [29.886948, 59.84413000000001],
          [29.88651, 59.84326999999996],
          [29.886267, 59.842746999999974],
          [29.87955, 59.84394499999999],
          [29.879856, 59.84790799999999],
          [29.876467, 59.84816000000001],
          [29.876675, 59.84884299999999],
          [29.877846, 59.84876],
          [29.878721, 59.84936999999999],
          [29.87874, 59.84969699999999],
          [29.879171, 59.85058599999999],
          [29.879683999999997, 59.85146000000003],
          [29.880358, 59.852158],
          [29.874496, 59.856842],
          [29.874215999999997, 59.858645999999965],
          [29.873008999999996, 59.85889],
          [29.871601, 59.858900000000034],
          [29.871485, 59.858900000000034],
          [29.871996, 59.85647],
          [29.870789, 59.85547300000002],
          [29.869429999999998, 59.85475999999997],
          [29.869095000000005, 59.85403400000001],
          [29.867662, 59.85388599999999],
          [29.862536999999996, 59.85544199999998],
          [29.861752000000003, 59.857777],
          [29.861624, 59.857906000000014],
          [29.859924, 59.85847000000004],
          [29.857736999999997, 59.86116000000001],
          [29.859186, 59.861988],
          [29.837494, 59.867626],
          [29.83481, 59.86807300000001],
          [29.834782, 59.86622599999998],
          [29.830702, 59.86565000000002],
          [29.826368000000002, 59.86668399999999],
          [29.825119, 59.86744300000001],
          [29.823409999999996, 59.86903799999999],
          [29.821383, 59.86824400000003],
          [29.800268, 59.85891000000001],
          [29.79987, 59.85865799999996],
          [29.786848000000003, 59.865826],
          [29.771357000000002, 59.87221999999997],
          [29.764408, 59.86925500000001],
          [29.750126, 59.868840000000006],
          [29.750124, 59.871746],
          [29.736894999999997, 59.87244400000003],
          [29.735191, 59.87084200000001],
          [29.732801, 59.87161600000002],
          [29.724500000000003, 59.871105],
          [29.716417000000003, 59.87411500000002],
          [29.714348, 59.88731999999996],
          [29.714945, 59.889279999999985],
          [29.726531999999995, 59.89364599999996],
          [29.727016, 59.89425700000004],
          [29.72208, 59.9015],
          [29.720003, 59.902817],
          [29.719455999999997, 59.902912000000015],
          [29.718557, 59.90307200000001],
          [29.717833, 59.903155999999996],
          [29.717553999999996, 59.90322499999999],
          [29.717422, 59.903392999999994],
          [29.716986000000002, 59.903538],
          [29.71572, 59.903763000000026],
          [29.709614, 59.905598],
          [29.70866, 59.90436600000001],
          [29.706599999999998, 59.90482000000003],
          [29.706385, 59.905],
          [29.706553, 59.90527],
          [29.707243, 59.90575799999996],
          [29.707169000000004, 59.906161999999995],
          [29.706627000000005, 59.906536000000045],
          [29.706234, 59.90673000000001],
          [29.705430999999997, 59.907005],
          [29.703095999999995, 59.90778],
          [29.702666999999998, 59.907883],
          [29.700521000000002, 59.908469999999994],
          [29.698733999999998, 59.90897000000001],
          [29.698526, 59.90903000000003],
          [29.696959, 59.90941599999999],
          [29.69372, 59.91025500000001],
          [29.681937999999995, 59.91250200000002],
          [29.680008, 59.912773000000016],
          [29.676145999999996, 59.91341800000001],
          [29.669193000000003, 59.91390200000001],
          [29.666360000000005, 59.91437500000001],
          [29.663914000000002, 59.914772],
          [29.663765, 59.914837000000006],
          [29.663342, 59.914894000000004],
          [29.659887, 59.91543199999998],
          [29.65892, 59.915324999999996],
          [29.656288, 59.91663700000004],
          [29.65754, 59.918987000000016],
          [29.66133, 59.92481000000001],
          [29.660046, 59.926975],
          [29.655895, 59.92763499999998],
          [29.654042999999998, 59.92793000000003],
          [29.650873000000004, 59.92830000000001],
          [29.647942000000004, 59.928203999999994],
          [29.647922999999995, 59.92828800000001],
          [29.64754, 59.929855],
          [29.56904, 60.00548599999999],
          [29.532113999999996, 60.041798],
          [29.456503, 60.17116999999999],
          [29.456220000000002, 60.171547000000004],
          [29.456309999999995, 60.17162300000001],
          [29.455949999999998, 60.17189399999998],
          [29.45605, 60.17208499999998],
          [29.455847, 60.17226400000001],
          [29.455275, 60.17226400000001],
          [29.454779999999996, 60.17205000000001],
          [29.454460000000005, 60.171809999999994],
          [29.453873, 60.171738000000005],
          [29.453383999999996, 60.171738000000005],
          [29.453144000000005, 60.17186000000001],
          [29.452776000000004, 60.17193600000002],
          [29.452349, 60.17200500000001],
          [29.452051, 60.17197399999998],
          [29.451812999999998, 60.17187999999996],
          [29.451832, 60.171763999999996],
          [29.451399, 60.17160999999996],
          [29.449901999999998, 60.17108500000003],
          [29.449410999999998, 60.171097],
          [29.449018000000002, 60.17119600000004],
          [29.448814000000002, 60.17143200000001],
          [29.449041, 60.171535000000006],
          [29.449362, 60.171549999999996],
          [29.449713, 60.171493999999996],
          [29.449922999999995, 60.171549999999996],
          [29.450083, 60.171795],
          [29.450089000000002, 60.171929999999975],
          [29.450099999999996, 60.172060000000016],
          [29.450119000000004, 60.172268],
          [29.449879, 60.172447000000005],
          [29.449660000000005, 60.17260999999999],
          [29.449402, 60.17266000000001],
          [29.449099999999998, 60.17255800000001],
          [29.448668000000005, 60.172455000000014],
          [29.448230000000002, 60.17251000000002],
          [29.448026999999996, 60.172649999999976],
          [29.447699, 60.17328599999999],
          [29.447655, 60.17354],
          [29.447619999999997, 60.17368299999998],
          [29.447335999999996, 60.173749999999956],
          [29.446909, 60.173687],
          [29.446587, 60.173584000000005],
          [29.446186000000004, 60.173621999999995],
          [29.446068, 60.173576],
          [29.445977999999997, 60.17345],
          [29.445946, 60.17331000000004],
          [29.445719, 60.173237],
          [29.444973, 60.173182999999995],
          [29.443993, 60.17354199999997],
          [29.44399, 60.17363399999999],
          [29.443844, 60.17378599999998],
          [29.443686999999997, 60.17388],
          [29.443453, 60.17396000000002],
          [29.443496999999997, 60.174106999999964],
          [29.443464, 60.17422500000001],
          [29.443111, 60.174328],
          [29.442871, 60.17430500000003],
          [29.44273, 60.17423200000002],
          [29.442753000000003, 60.17406],
          [29.442981999999997, 60.173664],
          [29.442847999999998, 60.17349200000001],
          [29.442596, 60.173434999999984],
          [29.442337, 60.17349999999999],
          [29.442071999999996, 60.173659999999984],
          [29.441912, 60.173835999999994],
          [29.44188, 60.174019999999985],
          [29.441893, 60.17423200000002],
          [29.441795, 60.17437699999999],
          [29.441560000000003, 60.174465],
          [29.44149, 60.17456999999999],
          [29.441663999999996, 60.17466999999999],
          [29.44204, 60.17468000000002],
          [29.442284, 60.174649999999986],
          [29.442437999999996, 60.174750000000046],
          [29.442395999999995, 60.174859999999995],
          [29.442228, 60.174972999999994],
          [29.441895999999996, 60.175009999999986],
          [29.441376, 60.17487699999998],
          [29.440624000000003, 60.17480499999999],
          [29.44052, 60.174983999999995],
          [29.440546000000005, 60.17524],
          [29.440372, 60.17549500000001],
          [29.439993, 60.17572999999999],
          [29.439789, 60.175453000000005],
          [29.43933, 60.175404000000015],
          [29.439106, 60.175471999999985],
          [29.438884999999996, 60.175465],
          [29.438704, 60.17536000000001],
          [29.438476999999995, 60.17538999999999],
          [29.43834, 60.175453000000005],
          [29.438342999999996, 60.175636],
          [29.438515, 60.17582299999998],
          [29.438936, 60.175915],
          [29.439696999999995, 60.17594],
          [29.439528, 60.176064000000025],
          [29.439294999999998, 60.17622800000001],
          [29.438899999999997, 60.17643000000001]
        ]
      ],
      [
        [
          [30.065058000000004, 59.668304000000006],
          [30.07331, 59.667373999999995],
          [30.082712, 59.676083000000006],
          [30.083952, 59.680293999999975],
          [30.072826000000003, 59.679745],
          [30.065168, 59.677981999999986],
          [30.065327000000003, 59.676376000000005],
          [30.065058000000004, 59.668304000000006]
        ]
      ]
    ]
  }
}

import { createSelector } from 'reselect'
import { uniqBy } from 'lodash'

export const getMspItems = (state: App.State) => state.msp.items
export const getMspCubeId = (state: App.State) => state.msp.cubeId

export const getMspByYear = createSelector(
  [getMspItems, (state, year) => year],
  (items, year) =>
    items.filter(
      item => (!year || item.year === year) && item.district_name !== 'Ленинградская область'
    )
)

export const getLoMsp = (state: App.State) =>
  state.msp.items.filter(item => item.district_name === 'Ленинградская область')

export const getMspYearPlanByType = createSelector(
  [getMspItems, (state, year) => year, (state, year, type) => type],
  (items, year, type) =>
    items.find(
      item =>
        item.value_type === 'plan' &&
        (!year || item.year === year) &&
        item.msp_type === type &&
        item.district_name !== 'Ленинградская область'
    )
)

export const getAvailablePeriods = createSelector(
  [getMspItems],
  items => {
    const years = uniqBy(items, item => item.year)
      .map(item => item.year)
      .sort((a, b) => (a < b ? 1 : -1))
    const quarters = uniqBy(items, item => item.quarter)
      .filter(item => item.quarter !== 'год')
      .map(item => item.quarter)

    return {
      years,
      quarters
    }
  }
)

enum Types {
  SET_HISTORY = 'SET_HISTORY',
  ADD_TO_HISTORY = 'ADD_TO_HISTORY',
  SET_CHAT_OPENED = 'SET_CHAT_OPENED',
  CREATE_ATTACHMENT = 'CREATE_ATTACHMENT',
  DELETE_ATTACHMENT = 'DELETE_ATTACHMENT',
  CREATE_ATTACHED_FILE = 'CREATE_ATTACHED_FILE',
  CREATE_CONCLUSION_FILE = 'CREATE_CONCLUSION_FILE'
}

export default Types

import React, { PureComponent, InputHTMLAttributes, ChangeEvent } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import classNames from 'classnames'
import { WrappedFieldProps } from 'redux-form'

import styles from './styles'

interface OuterProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  inputClassName?: string
  placeholder?: string
  type?: string
  // Fix strange ts error
  component?: any
}
export interface Props extends OuterProps, WrappedFieldProps, JSSProps<typeof styles> {}
interface State {
  value: string
}

class NameInput extends PureComponent<Props, State> {
  timeout: NodeJS.Timer

  constructor(props: Props) {
    super(props)

    this.state = {
      value: props.input.value
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.input.value !== this.props.input.value) {
      this.setState({ value: nextProps.input.value })
    }
  }

  onChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist()

    const {
      input: { onChange }
    } = this.props
    const value = event.target.value

    this.setState({ value })

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.timeout = setTimeout(() => onChange(value), 250)
  }

  render() {
    const {
      classes,
      theme,
      inputClassName,
      id,
      className,
      placeholder,
      type = 'text',
      input: { onChange, value, ...input },
      meta: { error, touched },
      disabled,
      ...props
    } = this.props

    return (
      <div className={classNames(classes.container, className)}>
        <div className={classes.content}>
          <input
            id={id || input.name}
            type={type}
            placeholder={placeholder}
            className={classNames(classes.input, inputClassName)}
            value={this.state.value}
            disabled={disabled}
            onChange={this.onChange}
            {...input}
          />
          {touched && error && typeof error === 'string' && (
            <span className={classes.error}>{error}</span>
          )}
        </div>
      </div>
    )
  }
}

export default injectStyles<OuterProps>(styles)(NameInput)

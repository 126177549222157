import React, { Component } from 'react'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import injectStyles, { JSSProps } from 'react-jss'

import { compose } from 'recompose'
import { Document, Page, pdfjs } from 'react-pdf'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import 'react-pdf/dist/esm/Page/TextLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

import { closePdf } from 'src/lib/modules/pdfViewer/actions'
import { Cancel } from 'components/Icons'
import ButtonRounded from 'components/ButtonRounded'

import styles from './styles'

interface OuterProps {}
interface StateProps {
  pdfUrl: string | null
}
interface DispatchProps {
  closePdf: () => void
}

export interface Props extends OuterProps, DispatchProps, StateProps, JSSProps<typeof styles> {}

interface State {
  numPages: number
}

class PdfViewer extends Component<Props, State> {
  state: State = {
    numPages: 0
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages })
  }

  render() {
    const { classes, pdfUrl, closePdf } = this.props
    const { numPages } = this.state

    return (
      !!pdfUrl && (
        <div className={classes.fileViewerWrapper}>
          <ButtonRounded
            className={classes.closeFile}
            icon={Cancel}
            title="Закрыть файл"
            squared
            onClick={() => closePdf()}
          />
          <div className={classes.fileViewer}>
            <Document
              className={classes.document}
              file={pdfUrl}
              onLoadSuccess={this.onDocumentLoadSuccess}
              loading=""
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page-${index + 1}`} pageNumber={index + 1} loading="" />
              ))}
            </Document>
          </div>
        </div>
      )
    )
  }
}

const mapStateToProps: MapStateToProps<StateProps, Props, App.State> = state => ({
  pdfUrl: state.pdfViewer.url
})

const mapDispatchToProps: MapDispatchToProps<DispatchProps, Props> = dispatch => ({
  closePdf: () => dispatch(closePdf())
})

export default compose<Props, OuterProps>(
  
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectStyles(styles)
)(PdfViewer)

import { Action } from 'redux'
import types from './types'

export interface ReadApkbgs extends Action<types.READ_APKBGS> {}
export interface ReadApkbgsLoading extends Action<types.READ_APKBGS_LOADING> {}
export interface ReadApkbgsFailure extends Action<types.READ_APKBGS_FAILURE> {}
export interface ReadApkbgsSuccess extends Action<types.READ_APKBGS_SUCCESS> {
  payload: Data.Apkbg[]
}
export interface ToggleShow extends Action<types.TOGGLE_SHOW> {}

export type ActionTypes =
  | ReadApkbgs
  | ReadApkbgsLoading
  | ReadApkbgsFailure
  | ReadApkbgsSuccess
  | ToggleShow

export const readApkbgs: () => ReadApkbgs = () => ({
  type: types.READ_APKBGS
})

export const readApkbgsLoading: () => ReadApkbgsLoading = () => ({
  type: types.READ_APKBGS_LOADING
})

export const readApkbgsFailure: () => ReadApkbgsFailure = () => ({
  type: types.READ_APKBGS_FAILURE
})

export const readApkbgsSuccess: (data: Data.Apkbg[]) => ReadApkbgsSuccess = data => ({
  type: types.READ_APKBGS_SUCCESS,
  payload: data
})

export const toggleShow: () => ToggleShow = () => ({
  type: types.TOGGLE_SHOW
})

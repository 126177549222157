import { BASE_SIZE } from 'components/Loading/styles'

import { Props } from './'

const styles = (theme: App.Theme) => ({
  overlay: {
    minWidth: ({ minSize }) => (minSize || BASE_SIZE) * 1.25,
    width: '100%',
    minHeight: ({ minSize }) => (minSize || BASE_SIZE) * 1.25,
    height: '100%',
    left: 0,
    top: 0,
    position: 'absolute',
    borderRadius: 'inherit',
    boxSizing: 'border-box',
    margin: 0,
    padding: 0,
    flex: '2 0 100%',
    zIndex: ({ zIndex = 888 }) => zIndex,
    ...theme.utils.flexContainer({ justify: 'center', alignItems: 'center' })
  },
  loading: {
    zIndex: 889
  }
})

export default styles

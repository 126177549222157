import { OuterProps as Props } from './index'

const styles = (theme: App.Theme) => ({
  container: {
    width: '100%',
    // height: '6.875rem',
    backgroundColor: (props: Props) =>
      props.notification.status === 'received' ? '#F5F7FD' : '#FFFFFF',
    boxSizing: 'border-box',
    cursor: (props: Props) => (props.notification.link ? 'pointer' : 'default')
  },
  link: {
    ...theme.utils.flexContainer({ direction: 'column' }),
    height: '100%',
    width: '100%',
    textDecoration: 'none',
    marginBottom: '1rem',
    color: 'black'
  },
  text: {
    width: '100%',
    maxHeight: '100vh',
    fontFamily: 'Open Sans',
    fontSize: '1rem',
    fontWeight: (props: Props) => (props.notification.status === 'received' ? 'bold' : 'normal'),
    color: '#56687A',
    lineHeight: '1.5rem'
  },
  date: {
    fontFamily: 'Open Sans',
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
    fontWeight: (props: Props) => (props.notification.status === 'received' ? 'bold' : 'normal'),
    color: 'rgba(64, 79, 94, 0.6)'
  }
})

export default styles

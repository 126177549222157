import { Props } from 'components/CustomDiv'

const styles = (theme: App.Theme) => ({
  container: {
    position: ({ position }) => position,
    left: ({ left }) => left,
    right: ({ right }) => right,
    bottom: ({ bottom }) => bottom,
    top: ({ top }) => top,
    zIndex: ({ zIndex }) => zIndex,

    margin: ({ margin = 0 }) => margin,
    marginTop: ({ marginTop }) => marginTop,
    marginBottom: ({ marginBottom }) => marginBottom,
    marginLeft: ({ marginLeft }) => marginLeft,
    marginRight: ({ marginRight }) => marginRight,

    boxSizing: ({ boxSizing = 'border-box' }) => boxSizing,
    padding: ({ padding = 0, paddingTop, paddingBottom, paddingLeft, paddingRight }) =>
     (paddingTop || paddingBottom || paddingLeft || paddingRight) ? undefined : padding,
    paddingTop: ({ paddingTop }) => paddingTop,
    paddingBottom: ({ paddingBottom }) => paddingBottom,
    paddingLeft: ({ paddingLeft }) => paddingLeft,
    paddingRight: ({ paddingRight }) => paddingRight,

    fontWeight: ({ fontWeight }) => fontWeight,
    fontSize: ({ fontSize = 16 }) => fontSize,

    whiteSpace: ({ whiteSpace }) => whiteSpace,

    display: ({ display }) => display,
    flexDirection: ({ flexDirection }) => flexDirection,
    justifyContent: ({ justifyContent }) => justifyContent,
    alignItems: ({ alignItems }) => alignItems,
    flexBasis: ({ flexBasis }) => flexBasis,
    flex: ({ flex }) => flex,
    flexWrap: ({ flexWrap }) => flexWrap,
    gap: ({ gap }) => gap,

    overflow: ({ overflow }) => overflow,
    overflowY: ({ overflowY }) => overflowY,
    overflowX: ({ overflowX }) => overflowX,

    border: ({ border }) => border,
    borderRight: ({ borderRight }) => borderRight,
    borderLeft: ({ borderLeft }) => borderLeft,
    borderBottom: ({ borderBottom }) => borderBottom,
    borderTop: ({ borderTop }) => borderTop,
    borderRadius: ({ borderRadius }) => borderRadius,

    width: ({ width }) => width,
    minWidth: ({ minWidth }) => minWidth,
    maxWidth: ({ maxWidth }) => maxWidth,
    height: ({ height }) => height,
    minHeight: ({ minHeight }) => minHeight,
    maxHeight: ({ maxHeight }) => maxHeight,

    background: ({ background }: Props) => theme.colors.background[background] || background,
    backgroundColor: ({ backgroundColor }: Props) => backgroundColor,
    color: ({ color = theme.colors.text.default }: Props) => theme.colors.text[color] || color,

    cursor: ({ cursor }: Props) => cursor
  }
})

export default styles

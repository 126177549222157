import { fork, call, take } from 'redux-saga/effects'

const takeLeading = (patternOrChannel, saga, ...args) =>
  fork(function*() {
    while (true) {
      const action = yield take(patternOrChannel)
      // @ts-ignore
      yield call(saga, ...args.concat(action))
    }
  })

export default takeLeading

const styles = (theme: App.Theme) => ({
  fieldSelect: {
    flex: '1 1 44%',
    maxWidth: '50%',
    '& .Select-value': {
      marginTop: '0 !important',
      marginBottom: '0 !important'
    }
  }
})

export default styles

import { takeEvery, put, select, call, all } from 'redux-saga/effects'
import {
  setSubmitSucceeded,
  setSubmitFailed,
  startSubmit,
  stopSubmit,
  getFormValues
} from 'redux-form'
import { success, error } from 'react-notification-system-redux'
import { AxiosError } from 'axios'

import fetchAPI from 'lib/services/fetchAPI'
import { tokenSelector } from 'lib/modules/user/selectors'
import { AUTH_AC_TYPES, METHOD, ENDPOINT } from 'constants/api'
import { SCHEDULE_FORM } from 'constants/forms'
import { FormValues as UserSettingsFormValues } from 'containers/UserSettings'
import { objectToFormData } from 'lib/utils/objectToFormData'
import {
  UpdateSchedule,
  updateScheduleRequest,
  updateScheduleSuccess,
  updateScheduleFailure
} from './actions'
import types from './types'

function* updateSchedule(action: UpdateSchedule) {
  const token = yield select(tokenSelector)
  const body: UserSettingsFormValues = yield select(getFormValues(SCHEDULE_FORM.form))

  yield put(startSubmit(SCHEDULE_FORM.form))

  try {
    yield put(updateScheduleRequest())
    const { data } = yield call(fetchAPI, {
      token,
      method: METHOD.POST,
      endpoint: ENDPOINT.UPDATE_SCHEDULE,
      body: objectToFormData(body, '')
    })

    yield put(updateScheduleSuccess())
    yield put(stopSubmit(SCHEDULE_FORM.form))
    yield put(setSubmitSucceeded(SCHEDULE_FORM.form))
    yield put(
      success({
        title: 'Расписание успешно обновлено'
      })
    )
  } catch (exception) {
    let errors = {
      _error: exception
    }

    if (exception.response) {
      errors = (exception as AxiosError).response.data.errors
    }

    yield put(updateScheduleFailure())
    yield put(stopSubmit(SCHEDULE_FORM.form, errors))
    yield put(setSubmitFailed(SCHEDULE_FORM.form))
    yield put(
      error({
        title: 'Не удалось обновить расписание'
      })
    )
  }
}

export function* watcher() {
  yield all([takeEvery(types.UPDATE_SCHEDULE, updateSchedule)])
}

import { Reducer } from 'redux'

import types from './types'
import { ActionTypes } from './actions'
import { STATES } from 'constants/states'

export interface State {
  infoStatus: STATES
  infoStatusAll: STATES
  layers: Data.FPDLayer[]
  allLayers: Data.FPDLayer[]
}

const initialState: State = {
  infoStatus: STATES.IDLE,
  infoStatusAll: STATES.IDLE,
  layers: [],
  allLayers: []
}

export const reducer: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case types.READ_FPD:
      return {
        ...state,
        infoStatus: action.payload.loadAll ? state.infoStatus : STATES.IDLE,
        infoStatusAll: action.payload.loadAll ? STATES.IDLE : state.infoStatusAll
      }
    case types.READ_FPD_LOADING:
      return {
        ...state,
        infoStatus: action.payload.loadAll ? state.infoStatus : STATES.LOADING,
        infoStatusAll: action.payload.loadAll ? STATES.LOADING : state.infoStatusAll
      }
    case types.READ_FPD_FAILURE:
      return {
        ...state,
        infoStatus: action.payload.loadAll ? state.infoStatus : STATES.FAILED,
        infoStatusAll: action.payload.loadAll ? STATES.FAILED : state.infoStatusAll
      }
    case types.READ_FPD_SUCCESS:
      const { data, isList } = action.payload
      return {
        ...state,
        layers: isList ? state.layers : data,
        allLayers: isList ? data : state.allLayers,
        infoStatus: isList ? state.infoStatus : STATES.SUCCEEDED,
        infoStatusAll: isList ? STATES.SUCCEEDED : state.infoStatusAll
      }
    default:
      return state
  }
}

import React, { FC, InputHTMLAttributes, useMemo } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import { compose } from 'recompose'
import { WrappedFieldProps } from 'redux-form'
import DateTimePicker from 'react-datetime-picker'
import cn from 'classnames'
import styles from './styles'
import { Calendar } from 'components/Icons'
import { formatDateServer } from "lib/services/dateHelper";
import { DATE_FORMAT_TYPES } from 'src/lib/utils/dateHelper'
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

interface OuterProps extends InputHTMLAttributes<HTMLInputElement> {
  formatString: string
  // Fix strange ts error
  component?: any
  disableClock?: boolean
  hideTextError?: boolean
  label?: string
}
interface Props extends JSSProps<typeof styles>, WrappedFieldProps, OuterProps { }

const DateTimePickerField: FC<Props> = ({
  classes,
  className,
  formatString = DATE_FORMAT_TYPES.dayMonthNameYear,
  input,
  meta,
  label,
  disableClock = true,
  hideTextError = false,
  ...props
}) => {
  const pickerValue = useMemo(() => {
    const { value } = input

    if (!value) return null

    return formatDateServer(value)
  }, [input])

  const { onChange } = input
  const { error, touched } = meta

  return (
    <div>
      <DateTimePicker
        className={cn(classes.input, className)}
        onChange={onChange}
        value={pickerValue}
        locale="ru-RU"
        format={formatString}
        calendarIcon={
          <div className={classes.labelIcon}>
            <Calendar />
            {label && <span>{label}</span>}
          </div>
        }
        disableClock={disableClock}
        {...props}
      />
      {touched && error && !hideTextError && <span className={classes.error}>{error}</span>}
    </div>
  )
}

export default compose<Props, OuterProps>(injectStyles(styles))(DateTimePickerField)

import { Props } from './index'

const styles = (theme: App.Theme) => ({
  menuContainer: {
    ...theme.utils.flexContainer({ direction: 'row', alignItems: 'center' }),
    width: '100%',
    height: theme.sizes.sidemenu.height,
    boxSizing: 'border-box',
    flexGrow: 0,
    flexShrink: 0,
    backgroundColor: '#FFFFFF',
    borderBottom: '1px solid rgba(44, 83, 140, 0.1)'
  },
  menuFooter: {
    ...theme.utils.flexContainer({ direction: 'row', alignItems: 'center', justify: 'center' }),
    flex: '0 0 auto',
    marginRight: '1.25rem',
    marginLeft: '1.25rem',
    boxSizing: 'border-box'
  },
  desktopLink: {
    ...theme.utils.flexContainer({ direction: 'row', alignItems: 'center', justify: 'flex-start' }),
    flex: '2 0 auto',
    height: '100%',
    textDecoration: 'none',
    color: '#000000',
    '&:visited, &:hovered': {
      textDecoration: 'none',
      color: '#000000'
    }
  },
  menuButtonLabel: {
    fontWeight: 'bold',
    textTransform: 'none'
  },
  avatar: {
    height: '60%',
    width: 'auto',
    margin: '0 1rem'
  },
  divider: {
    height: '1.5rem',
    width: 1
  },
  exit: {
    fontWeight: 'bold',
    textTransform: 'none'
  }
})

export default styles

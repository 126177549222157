import { AxiosError } from 'axios'
import { push } from 'connected-react-router'
import { error, success } from 'react-notification-system-redux'
import {
  getFormValues,
  setSubmitFailed,
  setSubmitSucceeded,
  startSubmit,
  stopSubmit
} from 'redux-form'
import { reset } from 'redux-resource-plugins'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { BASE_CHANNEL } from 'constants/actionCable'
import { AUTH_AC_TYPES, ENDPOINT, METHOD } from 'constants/api'
import { USER_SETTINGS_FORM } from 'constants/forms'
import { RESOURCES_CLEARED_ON_LOGOUT } from 'constants/resources'
import { FormValues as UserSettingsFormValues } from 'containers/UserSettings'
import {
  startListeningNotifications,
  stopListeningNotifications
} from 'lib/modules/notifications/actions'
import { tokenSelector } from 'lib/modules/user/selectors'
import ActionCableService from 'lib/services/actionCable'
import fetchAPI from 'lib/services/fetchAPI'
import takeLeading from 'lib/utils/takeLeading'
import {
  AuthRefreshRequest,
  AuthRequest,
  ReadUserInfo,
  UpdateProfileInfo,
  authFailure,
  authRefreshFailure,
  authRefreshSuccess,
  authReset,
  authSuccess,
  readUserInfo,
  readUserInfoRequest,
  readUserInfoRequestFailure,
  readUserInfoRequestSuccess,
  updateProfileInfoFailure,
  updateProfileInfoRequest,
  updateProfileInfoSuccess
} from './actions'
import types from './types'
import authPersistor from 'src/lib/services/authPersistor'

const formName = 'login'

function* authSaga(action: AuthRequest) {
  const { redirect_to } = action.meta
  const { email, password } = yield select(getFormValues(formName))

  yield put(startSubmit(formName))

  try {
    const {
      data: { auth_token }
    } = yield call(fetchAPI, {
      method: METHOD.POST,
      endpoint: ENDPOINT.SESSION,
      withoutVersion: true,
      body: { email, password }
    })

    yield readUserInfoSaga(readUserInfo(auth_token))
    yield put(authSuccess({ token: auth_token }))
    const ssoRedirect = new URLSearchParams(window.location.search).get('return_to')
    if (ssoRedirect) {
      window.location.href = ssoRedirect
      return null
    }
    yield put(stopSubmit(formName))
    yield put(setSubmitSucceeded(formName))
    // listen to notifications
    yield put(startListeningNotifications())

    if (redirect_to) {
      yield put(push({ pathname: redirect_to, state: undefined }))
    }
  } catch (exception) {
    const errors = {
      _error: exception
    }

    if (exception.response) {
      switch ((exception as AxiosError).response.data.error) {
        case 'locked':
          errors._error = 'Доступ к личному кабинету временно приостановлен'
          break
        default:
          errors._error = 'Неправильный логин или пароль'
          break
      }
    }

    yield put(authFailure())
    yield put(stopSubmit(formName, errors))
    yield put(setSubmitFailed(formName))
  }
}

function* authRefreshSaga(action: AuthRefreshRequest) {
  try {
    const {
      payload: { auth_token }
    } = yield ActionCableService.sendMessage<{ auth_token: string; token_ttl: number }>({
      channelName: BASE_CHANNEL,
      payload: {},
      type: AUTH_AC_TYPES.REFRESH_TOKEN
    })

    yield readUserInfoSaga(readUserInfo(auth_token))
    yield put(authRefreshSuccess(auth_token))

    // listen to notifications
    yield put(startListeningNotifications())
  } catch (err) {
    yield put(authRefreshFailure())
  }
}

function* authResetResources() {
  try {
    yield call(fetchAPI, {
      method: METHOD.DELETE,
      endpoint: ENDPOINT.SESSION,
      useToken: true,
      withoutVersion: true
    })
  } catch (e) {
    console.error(e)
  }
  yield put(authReset())
  window.location.href = '/login'
}

function* readUserInfoSaga(action: ReadUserInfo) {
  yield put(readUserInfoRequest())

  try {
    const {
      data
    }: {
      data: {
        id: string
        email: string
        message_name: string
      }
    } = yield call(fetchAPI, {
      method: METHOD.GET,
      endpoint: ENDPOINT.PROFILE,
      token: action.payload.auth_token
    })

    yield put(readUserInfoRequestSuccess(data))
  } catch (error) {
    yield put(readUserInfoRequestFailure(error))
  }
}

function* updateProfileInfo(action: UpdateProfileInfo) {
  const token = yield select(tokenSelector)
  const { password }: UserSettingsFormValues = yield select(getFormValues(USER_SETTINGS_FORM.form))

  yield put(startSubmit(USER_SETTINGS_FORM.form))

  try {
    yield put(updateProfileInfoRequest())
    const { data } = yield call(fetchAPI, {
      token,
      method: METHOD.PUT,
      endpoint: ENDPOINT.PROFILE,
      body: { profile: { password } }
    })

    yield put(updateProfileInfoSuccess())
    yield put(stopSubmit(USER_SETTINGS_FORM.form))
    yield put(setSubmitSucceeded(USER_SETTINGS_FORM.form))
    yield put(
      success({
        title: 'Профиль успешно обновлен'
      })
    )
  } catch (exception) {
    let errors = {
      _error: exception
    }

    if (exception.response) {
      errors = (exception as AxiosError).response.data.errors
    }

    yield put(updateProfileInfoFailure())
    yield put(stopSubmit(USER_SETTINGS_FORM.form, errors))
    yield put(setSubmitFailed(USER_SETTINGS_FORM.form))
    yield put(
      error({
        title: 'Не удалось обновить профиль'
      })
    )
  }
}

export function* watcher() {
  yield all([
    takeLeading(types.AUTH_REFRESH_REQUEST, authRefreshSaga),
    takeEvery(types.AUTH_REQUEST, authSaga),
    takeEvery(types.AUTH_RESET_REQUEST, authResetResources),
    takeEvery(types.GET_USER_INFO, readUserInfoSaga),
    takeEvery(types.UPDATE_PROFILE_INFO, updateProfileInfo)
  ])
}

import { FORM, MATH_FUNCTION, PLACE, SORTING } from 'constants/aggregation'
import { CHART_TYPES } from 'constants/dataView'

class Aggregation {
  public static dimensionTypes = [FORM.NONE]
  public static measureTypes = [
    FORM.AVG,
    FORM.CORR,
    FORM.MEDIAN,
    FORM.SUM,
    FORM.VAR_POP,
    FORM.VAR_SAMP,
    FORM.COUNT,
    FORM.UNIQ_EXACT,
    FORM.NONE,
  ]
  public static withDimensionTypes = [FORM.COUNT, FORM.UNIQ_EXACT, FORM.NONE]
  public static withMeasureTypes = [
    FORM.AVG,
    FORM.CORR,
    FORM.MEDIAN,
    FORM.SUM,
    FORM.VAR_POP,
    FORM.VAR_SAMP,
    FORM.NONE,
  ]
  public static mathFunctions = Object.values(MATH_FUNCTION)

  public static getAggregationWithMeaningfulFields = ({
    color,
    values,
    math_func,
    normalize,
    rounding,
    ...aggregation
  }: Data.AggregationEntity) => aggregation

  public static getAggregationWithoutValues = ({
    values,
    ...aggregation
  }: Data.AggregationEntity) => aggregation

  public static getMathFunctionsByElementType = (
    elementType: Data.Chart['element_type'] | undefined
  ): Array<any> => {
    if (elementType && elementType === CHART_TYPES.TABLE) {
      return [MATH_FUNCTION.ABS, MATH_FUNCTION.NONE]
    }

    return Aggregation.mathFunctions
  }

  public static isPolygon(args: Data.AggregationEntity['args']) {
    return args[0].filter_type === 'geo_poly'
  }

  public static isDimension(agg: Data.AggregationEntity['agg']) {
    if (Aggregation.dimensionTypes.includes(agg)) {
      return true
    } else if (Aggregation.measureTypes.includes(agg)) {
      return false
    } else {
      throw new Error(`Incorrect aggregation: ${agg}`)
    }
  }

  public static withDimension(agg: Data.AggregationEntity['agg']) {
    if (Aggregation.withDimensionTypes.includes(agg)) {
      return true
    } else if (Aggregation.withMeasureTypes.includes(agg)) {
      return false
    } else {
      throw new Error(`Incorrect aggregation: ${agg}`)
    }
  }

  public static splitIntoDimensionsAndMeasures(aggregations: Array<Data.AggregationEntity>) {
    const result: {
      dimensions: Array<Data.Aggregation<Data.AggregationDimension>>
      measures: Array<Data.Aggregation<Data.AggregationMeasure>>
    } = {
      dimensions: [],
      measures: []
    }

    aggregations.forEach(aggregation => {
      if (Aggregation.isDimension(aggregation.agg)) {
        result.dimensions.push(aggregation as Data.Aggregation<Data.AggregationDimension>)
      } else {
        result.measures.push(aggregation as Data.Aggregation<Data.AggregationMeasure>)
      }
    })

    return result
  }

  public static splitIntoColumnsAndRows(aggregations: Array<Data.AggregationEntity>) {
    const result: {
      columns: Array<Data.AggregationEntity>
      rows: Array<Data.AggregationEntity>
    } = {
      columns: [],
      rows: []
    }

    aggregations.forEach(aggregation => {
      if (aggregation.place === PLACE.COLUMN) {
        result.columns.push(aggregation)
      }
      if (aggregation.place === PLACE.ROW) {
        result.rows.push(aggregation)
      }
    })

    return result
  }

  public static splitIntoSankeyAggregationFields(aggregations: Array<Data.AggregationEntity>) {
    const result: {
      source: Array<Data.AggregationEntity>
      target: Array<Data.AggregationEntity>
      linksMatrix: Array<Data.AggregationEntity>
    } = {
      source: [],
      target: [],
      linksMatrix: []
    }

    aggregations.forEach(aggregation => {
      if (aggregation.place === PLACE.SOURCE) {
        result.source.push(aggregation)
      }
      if (aggregation.place === PLACE.TARGET) {
        result.target.push(aggregation)
      }
      if (aggregation.place === PLACE.LINKS_MATRIX) {
        result.linksMatrix.push(aggregation)
      }
    })

    return result
  }

  public static getAggregationSymbol(agg: Data.AggregationEntity['agg']) {
    switch (agg) {
      case FORM.SUM:
        return 'СУММ'
      case FORM.CORR:
        return 'КОРР'
      case FORM.AVG:
        return 'СРЕД'
      case FORM.MEDIAN:
        return 'МЕД'
      case FORM.VAR_SAMP:
        return 'ОТКЛ'
      case FORM.VAR_POP:
        return 'ДИСП'
      case FORM.COUNT:
        return 'КОЛ'
      case FORM.UNIQ_EXACT:
        return 'УНИК'
      case FORM.NONE:
        return 'НЕТ'
      default:
        return '?'
    }
  }

  public static getAggregationName(agg: Data.AggregationEntity['agg']) {
    switch (agg) {
      case FORM.SUM:
        return 'Сумма'
      case FORM.CORR:
        return 'Корелляция'
      case FORM.AVG:
        return 'Среднее'
      case FORM.MEDIAN:
        return 'Медиана'
      case FORM.VAR_POP:
        return 'Дисперсия'
      case FORM.VAR_SAMP:
        return 'Стандартное отклонение'
      case FORM.COUNT:
        return 'Количество значений'
      case FORM.UNIQ_EXACT:
        return 'Количество уникальных значений'
      case FORM.NONE:
        return 'Без агрегации'
      default:
        return '???'
    }
  }

  public static getMathFunctionSymbol(mathFunc: Data.AggregationEntity['math_func']) {
    switch (mathFunc) {
      case MATH_FUNCTION.EXPONENT:
        return 'ЭКСП'
      case MATH_FUNCTION.ABS:
        return 'МОДУЛЬ'
      case MATH_FUNCTION.CUM:
        return 'НАКОП'
      case MATH_FUNCTION.GROWTH_RATE:
        return 'РОСТ'
      case MATH_FUNCTION.INCREASE_RATE:
        return 'ПРИРОСТ'
      case MATH_FUNCTION.MODE:
        return 'МОДА'
      case MATH_FUNCTION.TREND:
        return 'ТРЕНД'
      case MATH_FUNCTION.NONE:
        return 'НЕТ'
      default:
        return '?'
    }
  }

  public static getSortingSymbol(sorting: SORTING) {
    switch (sorting) {
      case SORTING.ASC:
        return 'ВОЗР.'
      case SORTING.DESC:
        return 'УБЫВ.'
      default:
        return 'НЕТ'
    }
  }

  public static getMathFunctionName(mathFunc: Data.AggregationEntity['math_func']) {
    switch (mathFunc) {
      case MATH_FUNCTION.EXPONENT:
        return 'Функция зависимости от экспоненты'
      case MATH_FUNCTION.ABS:
        return 'Функция модуля числа'
      case MATH_FUNCTION.CUM:
        return 'Функция накопления итога'
      case MATH_FUNCTION.GROWTH_RATE:
        return 'Функция темпа роста'
      case MATH_FUNCTION.INCREASE_RATE:
        return 'Функция прироста'
      case MATH_FUNCTION.TREND:
        return 'Функция тренда'
      case MATH_FUNCTION.MODE:
        return 'Функция моды'
      case MATH_FUNCTION.NONE:
        return 'Без функции'
      default:
        return '???'
    }
  }

  public static getSortingName(sorting: SORTING) {
    switch (sorting) {
      case SORTING.ASC:
        return 'По возрастанию'
      case SORTING.DESC:
        return 'По убыванию'
      default:
        return 'Без сортировки'
    }
  }

  public static getFullName({ args, agg, math_func }: Data.AggregationEntity) {
    const prefix =
      agg === FORM.NONE || agg === FORM.SUM ? '' : `(${Aggregation.getAggregationSymbol(agg)}) `
    let result = args.reduce((result, set, index) => {
      if (index) {
        result += ' + '
      }

      return (result += set.name)
    }, prefix)

    if (math_func && math_func !== MATH_FUNCTION.NONE) {
      result += ` (${Aggregation.getMathFunctionName(math_func)})`
    }

    return result
  }
}

export default Aggregation

import React, { SFC, HTMLProps } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import { compose } from 'recompose'
import classnames from 'classnames'

import styles from './styles'

interface OuterProps extends HTMLProps<HTMLDivElement> {
  className?: string
  nameClassName?: string
  valueClassName?: string
  name: string
  value: string | number
  formatValue?: (value: string | number) => string
}
interface Props extends JSSProps<typeof styles>, OuterProps {}

const defaultFormatValue = value => value

const DataCell: SFC<Props> = ({
  classes,
  className,
  nameClassName,
  valueClassName,
  name,
  value,
  formatValue = defaultFormatValue,
  theme,
  ...props
}) => (
  <div className={classnames(classes.cell, className)} {...props}>
    <span className={classnames(classes.name, nameClassName)}>{name}</span>
    <span className={classnames(classes.value, valueClassName)}>
      {value ? formatValue(value) : '-'}
    </span>
  </div>
)

export default compose<Props, OuterProps>(injectStyles(styles))(DataCell)

import { takeEvery, call, put, select, all } from 'redux-saga/effects'
import { actionTypes } from 'redux-resource'
import {
  getFormValues,
  setSubmitSucceeded,
  setSubmitFailed,
  startSubmit,
  stopSubmit
} from 'redux-form'
import { success, error } from 'react-notification-system-redux'
import { AxiosError } from 'axios'

import { toACLMask } from 'lib/utils/aclMask'
import { GetUsers, CreateUser, UpdateUser, BlockUser, DropUser, ResetUserPassword } from './actions'
import { tokenSelector } from 'lib/modules/user/selectors'
import fetchAPI from 'lib/services/fetchAPI'
import { USER_FORM } from 'constants/forms'
import { ENDPOINT, METHOD } from 'constants/api'
import { ROLE } from 'constants/roles'
import { FormData as UserFormData } from 'containers/UserEditModal'
import { Types } from './actionTypes'

function* getUsers(action: GetUsers) {
  const token = yield select(tokenSelector)
  const { resourceType, requestKey, list, options } = action.payload

  try {
    yield put({
      type: actionTypes.READ_RESOURCES_PENDING,
      resourceType,
      requestKey
    })

    const { data } = yield call(fetchAPI, { endpoint: ENDPOINT.USERS, token, params: options })

    yield put({
      type: actionTypes.READ_RESOURCES_SUCCEEDED,
      resourceType,
      requestKey,
      list,
      resources: data,
      mergeListIds: false
    })
  } catch (err) {
    yield put({
      type: actionTypes.READ_RESOURCES_FAILED,
      resourceType,
      requestKey,
      list,
      requestProperties: {
        error: err
      }
    })
  }
}

function* createUser(action: CreateUser) {
  const { requestKey, resourceType, list } = action.payload
  const token = yield select(tokenSelector)
  const { branch_id, user_type, aclMap, ...values }: UserFormData = yield select(
    getFormValues(USER_FORM.form)
  )

  yield put(startSubmit(USER_FORM.form))
  yield put({
    type: actionTypes.CREATE_RESOURCES_PENDING,
    requestKey,
    resourceType,
    list
  })

  const user: UserFormData & { acl_mask_json?: Data.User['acl_mask_json'] } = {
    ...values,
    layers_ids: values.layers_ids.map(item => typeof item === 'string' ? item : item?.value),
    user_type
  }

  switch (user_type) {
    case ROLE.USER:
      user.branch_id = branch_id
      break
    case ROLE.SPECIAL:
      user.acl_mask_json = toACLMask(aclMap)
      break
  }

  try {
    const { data } = yield call(fetchAPI, {
      method: METHOD.POST,
      endpoint: ENDPOINT.USERS,
      token,
      body: { user }
    })

    yield put({
      type: actionTypes.CREATE_RESOURCES_SUCCEEDED,
      requestKey,
      resourceType,
      list,
      resources: [data]
    })

    yield put(stopSubmit(USER_FORM.form))
    yield put(setSubmitSucceeded(USER_FORM.form))
  } catch (exception) {
    let errors = {
      _error: exception
    }

    if (exception.response) {
      errors = (exception as AxiosError).response.data.errors
    }

    yield put({
      type: actionTypes.CREATE_RESOURCES_FAILED,
      requestKey,
      resourceType,
      list,
      requestProperties: {
        error: errors
      }
    })

    yield put(stopSubmit(USER_FORM.form, errors))
    yield put(setSubmitFailed(USER_FORM.form))
  }
}
function* updateUser(action: UpdateUser) {
  const { requestKey, resourceType, list } = action.payload
  const token = yield select(tokenSelector)
  const { branch_id, user_type, aclMap, ...values }: UserFormData = yield select(
    getFormValues(USER_FORM.form)
  )

  yield put(startSubmit(USER_FORM.form))
  yield put({
    type: actionTypes.UPDATE_RESOURCES_PENDING,
    requestKey,
    resourceType,
    list,
    resources: [values.id]
  })

  const user: UserFormData & { acl_mask_json?: Data.User['acl_mask_json'] } = {
    ...values,
    layers_ids: values.layers_ids.map(item => typeof item === 'string' ? item : item?.value),
    user_type
  }

  switch (user_type) {
    case ROLE.USER:
      user.branch_id = branch_id
      break
    case ROLE.SPECIAL:
      user.acl_mask_json = toACLMask(aclMap)
      break
  }

  try {
    const { data } = yield call(fetchAPI, {
      method: METHOD.PUT,
      endpoint: ENDPOINT.USERS,
      token,
      path: `${values.id}`,
      body: { user }
    })

    yield put({
      type: actionTypes.UPDATE_RESOURCES_SUCCEEDED,
      requestKey,
      resourceType,
      list,
      resources: [data]
    })

    yield put(stopSubmit(USER_FORM.form))
    yield put(setSubmitSucceeded(USER_FORM.form))
  } catch (exception) {
    let errors = {
      _error: exception
    }

    if (exception.response) {
      errors = (exception as AxiosError).response.data.errors
    }

    yield put({
      type: actionTypes.CREATE_RESOURCES_FAILED,
      requestKey,
      resourceType,
      list,
      requestProperties: {
        error: errors
      }
    })

    yield put(stopSubmit(USER_FORM.form, errors))
    yield put(setSubmitFailed(USER_FORM.form))
  }
}
function* blockUser(action: BlockUser) {
  const {
    requestKey,
    requestProperties: { id },
    resourceType
  } = action.payload
  const token = yield select(tokenSelector)

  yield put({
    type: actionTypes.UPDATE_RESOURCES_PENDING,
    requestKey,
    resourceType,
    resources: [id]
  })

  try {
    const { data } = yield call(fetchAPI, {
      method: METHOD.POST,
      endpoint: ENDPOINT.USERS,
      path: `${id}/block`,
      token
    })

    yield put({
      type: actionTypes.UPDATE_RESOURCES_SUCCEEDED,
      requestKey,
      resourceType,
      resources: [data]
    })
  } catch (err) {
    yield put({
      type: actionTypes.UPDATE_RESOURCES_FAILED,
      requestKey,
      resourceType,
      resources: [id],
      requestProperties: { error: err }
    })
  }
}
function* dropUser(action: DropUser) {
  const {
    requestKey,
    requestProperties: { id },
    resourceType
  } = action.payload
  const token = yield select(tokenSelector)

  yield put({
    type: actionTypes.DELETE_RESOURCES_PENDING,
    requestKey,
    resourceType,
    resources: [id]
  })

  try {
    const { data } = yield call(fetchAPI, {
      method: METHOD.DELETE,
      endpoint: ENDPOINT.USERS,
      path: id.toString(),
      token
    })

    yield put({
      type: actionTypes.DELETE_RESOURCES_SUCCEEDED,
      requestKey,
      resourceType,
      resources: [id]
    })
  } catch (err) {
    yield put({
      type: actionTypes.DELETE_RESOURCES_FAILED,
      requestKey,
      resourceType,
      resources: [id],
      requestProperties: { error: err }
    })
  }
}
function* resetUserPassword(action: ResetUserPassword) {
  const {
    requestKey,
    requestProperties: { id },
    resourceType
  } = action.payload
  const token = yield select(tokenSelector)

  yield put({
    type: actionTypes.UPDATE_RESOURCES_PENDING,
    requestKey,
    resourceType,
    resources: [id]
  })

  try {
    yield call(fetchAPI, {
      method: METHOD.POST,
      endpoint: ENDPOINT.USERS,
      path: `${id}/reset_password`,
      token
    })

    yield put({
      type: actionTypes.UPDATE_RESOURCES_SUCCEEDED,
      requestKey,
      resourceType,
      resources: [id]
    })
    yield put(
      success({
        title: 'Пароль успешно сброшен',
        message: 'Новый пароль отправлен на email'
      })
    )
  } catch (err) {
    yield put({
      type: actionTypes.UPDATE_RESOURCES_FAILED,
      requestKey,
      resourceType,
      resources: [id],
      requestProperties: { error: err }
    })
    yield put(
      error({
        title: 'Сброс не удался',
        message: ''
      })
    )
  }
}

export function* watcher() {
  yield all([
    takeEvery(Types.GET_USERS, getUsers),
    takeEvery(Types.CREATE_USER, createUser),
    takeEvery(Types.UPDATE_USER, updateUser),
    takeEvery(Types.BLOCK_USER, blockUser),
    takeEvery(Types.DROP_USER, dropUser),
    takeEvery(Types.RESET_USER_PASSWORD, resetUserPassword)
  ])
}

import { PADDING_HORIZONTAL } from '../../styles'
import { Props } from './index'

const styles = (theme: App.Theme) => ({
  container: {
    display: 'flex !important',
    alignItems: 'center'
  },
  icon: {
    display: 'inline-block',
    width: '1rem',
    height: '1rem',
    marginRight: PADDING_HORIZONTAL,
    lineHeight: 0,
    fill: theme.colors.grey,
    flexShrink: 0
  },
  text: {
    marginTop: 1
  }
})

export default styles

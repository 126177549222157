import { createSelector } from 'reselect'
import { getResources, getStatus } from 'redux-resource'

import { BRANCHES } from 'constants/resources'
import getResourceState from 'lib/utils/getResourceState'

export const getState = (state: OpenApp.State | App.State) => state.branches
export const getBranchesStatus = (state: OpenApp.State | App.State) =>
  getResourceState(state, `${BRANCHES.NAME}.requests.${BRANCHES.REQUESTS.GET_BRANCHES_LIST}.status`)
export const getBranchUpdateStates = (state: OpenApp.State | App.State) => (
  id: Data.Branch['id']
) => getStatus(state, `${BRANCHES.NAME}.meta[${id}].updateStatus`)

export const branchesSelector = createSelector(
  [getState],
  state => getResources(state) || []
)

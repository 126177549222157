import { takeEvery, call, put, select, all } from 'redux-saga/effects'
import { success, error } from 'react-notification-system-redux'

import fetchAPI from 'lib/services/fetchAPI'
import APIAction from 'lib/utils/APIAction'
import getResourceActions from 'lib/utils/getResourceActions'
import { tokenSelector } from 'lib/modules/user/selectors'
import { GetDesktopButton } from './actions'
import { METHOD, ENDPOINT } from 'constants/api'
import { PANELS } from 'constants/resources'
import TYPES from './actionTypes'
import { readPanels } from 'lib/modules/panels/actions'

function* getDesktopButton(action: GetDesktopButton) {
  const token = yield select(tokenSelector)
  const { resourceType, requestKey, id } = action.payload

  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'READ',
    resourceType,
    requestKey
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        token,
        endpoint: `${ENDPOINT.DESKTOP}/${id}`
      })
    },
    *success(data: Data.DesktopButton) {
      const desktopButton = data

      if (desktopButton) {
        if (desktopButton.panel_ids && desktopButton.panel_ids.length) {
          yield put(readPanels({ ids: desktopButton.panel_ids, list: PANELS.LISTS.DESKTOP }))
        }

        yield put(onSuccessAction([desktopButton]))
      } else {
        yield put(
          error({
            title: 'Не удалось получить данные.'
          })
        )
      }
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось получить данные.'
        })
      )
    }
  })
}

export function* watcher() {
  yield all([takeEvery(TYPES.GET_DESKTOP_BUTTON_BY_ID, getDesktopButton)])
}

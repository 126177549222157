import { Props } from 'components/FormFields/TextInput'

const styles = ({ colors, common }: App.Theme) => ({
  defaultBorderRadius: {
    borderRadius: common.input.borderRadius
  },
  labelIcon: {
    display: 'flex',
    alignItems: 'center',
    color: colors.text.default,
    fontSize: '14px',
    gap: '10px'
  },
  input: {
    marginTop: 0,
    padding: 0,
    fontWeight: 'normal',
    fontSize: '1rem',
    height: '56px',
    background: 'white',
    lineHeight: 1.2,
    color: '#000',
    borderRadius: '0.5rem',
    outline: 'none',
    backgroundColor: 'white',
    width: '100%',
    border: ({ meta: { error, touched } }: Props) =>
      `1px solid ${error && touched ? colors.red : colors.input.border}`,
    '&::placeholder': {
      color: colors.grey
    },
    '& .react-datetime-picker__calendar': {
      zIndex: 1000
    },
    '& .react-calendar': {
      extend: 'defaultBorderRadius',
      overflow: 'hidden'
    },
    '& .react-datetime-picker__clear-button': {
      display: ({ value }) => (!value ? 'none' : 'initial'),

      '& svg': {
        strokeWidth: 1
      }
    },
    '& .react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus': {
      background: 'red'
    },
    '& .react-calendar__tile--now': {
      background: colors.background.red
    },
    '& .button.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__year-view__months__month': {
      background: colors.text.primary
    },
    '& .button.react-calendar__tile.react-calendar__tile--now.react-calendar__year-view__months__month': {
      background: 'initial'
    },
    '& .react-calendar__tile': {
      extend: 'defaultBorderRadius'
    },
    '& .react-calendar__navigation__label': {
      extend: 'defaultBorderRadius'
    },
    '& .react-datetime-picker__wrapper': {
      padding: '0 15px',
      border: `1px solid ${colors.input.border}`,
      extend: 'defaultBorderRadius'
    },
    '& .react-calendar__navigation__arrow': {
      extend: 'defaultBorderRadius'
    },
    '& .react-datetime-picker__calendar-button': {
      order: -1
    }
  },
  error: {
    display: 'inline-block',
    padding: '0.325em 1.25em',
    color: colors.red,
    fontSize: '0.75rem'
  }
})

export default styles

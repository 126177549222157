import { Action } from 'redux'
import types from './types'

export interface ReadVehicles extends Action<types.READ_VEHICLES> {}
export interface ReadVehiclesLoading extends Action<types.READ_VEHICLES_LOADING> {}
export interface ReadVehiclesFailure extends Action<types.READ_VEHICLES_FAILURE> {}
export interface ReadVehiclesSuccess extends Action<types.READ_VEHICLES_SUCCESS> {
  payload: Data.Vehicle[]
}
export interface ToggleShow extends Action<types.TOGGLE_SHOW_VEHICLES> {}

export type ActionTypes =
  | ReadVehicles
  | ReadVehiclesLoading
  | ReadVehiclesFailure
  | ReadVehiclesSuccess
  | ToggleShow

export const readVehicles: () => ReadVehicles = () => ({
  type: types.READ_VEHICLES
})

export const readVehiclesLoading: () => ReadVehiclesLoading = () => ({
  type: types.READ_VEHICLES_LOADING
})

export const readVehiclesFailure: () => ReadVehiclesFailure = () => ({
  type: types.READ_VEHICLES_FAILURE
})

export const readVehiclesSuccess: (data: Data.Vehicle[]) => ReadVehiclesSuccess = data => ({
  type: types.READ_VEHICLES_SUCCESS,
  payload: data
})

export const toggleShow: () => ToggleShow = () => ({
  type: types.TOGGLE_SHOW_VEHICLES
})

import { Reducer } from 'redux'

import types from './types'
import { ActionTypes } from './actions'
import { STATES } from 'constants/states'

export interface State {
  infoStatus: STATES
  showVehicles: boolean
  items: Data.Vehicle[]
}

const initialState: State = {
  infoStatus: STATES.IDLE,
  showVehicles: false,
  items: []
}

export const reducer: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case types.READ_VEHICLES:
      return { ...state, infoStatus: STATES.IDLE }
    case types.READ_VEHICLES_LOADING:
      return { ...state, infoStatus: STATES.LOADING }
    case types.READ_VEHICLES_FAILURE:
      return { ...state, infoStatus: STATES.FAILED }
    case types.READ_VEHICLES_SUCCESS:
      return { ...state, items: action.payload, infoStatus: STATES.SUCCEEDED }
    case types.TOGGLE_SHOW_VEHICLES:
      return { ...state, showVehicles: !state.showVehicles }
    default:
      return state
  }
}

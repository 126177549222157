import { Action } from 'redux'
import { actionTypes as RESOURCES_TYPES } from 'redux-resource'

import TYPES from './actionTypes'
import { Info } from './reducer'

export interface InitManager extends Action<TYPES.INIT_MANAGER_STATE> {
  payload: {
    resourceName: string
    initialState: Partial<Info>
  }
}
export interface ChangeManager extends Action<TYPES.CHANGE_MANAGER_STATE> {
  payload: {
    resourceName: string
    state: Partial<Info>
  }
}
export interface DestroyManager extends Action<TYPES.DESTROY_MANAGER_STATE> {
  payload: {
    resourceName: string
  }
}
export interface ReadResources extends Action<TYPES.READ_RESOURCES> {
  payload: {
    resourceName: string
    merge: boolean
  }
}
export interface ReadMoreResources extends Action<TYPES.READ_MORE_RESOURCES> {
  payload: {
    resourceName: string
  }
}
export interface ChangeRequestState extends Action<TYPES.CHANGE_REQUEST_STATE> {
  payload: {
    resourceName: string
    state: Info['state']
    isEnded: boolean
    responseData?: Array<{}> | Object
  }
}
export interface ChangeLoadingButton extends Action<TYPES.CHANGE_LOADING_BUTTON> {
  payload: {
    resourceName: string
    loadingButton: Info['loadingButton']
  }
}
export interface ChangeSorting extends Action<TYPES.CHANGE_SORTING> {
  payload: {
    resourceName: string
    sorting: Info['sorting']
  }
}
export interface ChangeFiltering extends Action<TYPES.CHANGE_FILTER> {
  payload: {
    resourceName: string
    tab: Info['tab']
  }
}
export interface ChangeSearch extends Action<TYPES.CHANGE_SEARCH> {
  payload: {
    resourceName: string
    search: Info['search']
  }
}
// Common action interface for all deleting actions
export interface DeleteResource extends Action<typeof RESOURCES_TYPES.DELETE_RESOURCES_SUCCEEDED> {
  resourceType: string
  requestKey: string
  resources: Array<string | number>
}

export type Actions =
  | InitManager
  | ChangeManager
  | DestroyManager
  | ReadResources
  | ChangeRequestState
  | ChangeLoadingButton
  | ChangeSorting
  | ChangeFiltering
  | ChangeSearch
  | ReadMoreResources

export const initManager: (options: {
  resourceName: string
  initialState: Partial<Info>
}) => InitManager = options => ({
  type: TYPES.INIT_MANAGER_STATE,
  payload: options
})

export const changeManager: (options: {
  resourceName: string
  state: Partial<Info>
}) => ChangeManager = options => ({
  type: TYPES.CHANGE_MANAGER_STATE,
  payload: options
})

export const destroyManager: (options: { resourceName: string }) => DestroyManager = options => ({
  type: TYPES.DESTROY_MANAGER_STATE,
  payload: options
})

export const readResources: (resourceName: string, merge?: boolean) => ReadResources = (
  resourceName,
  merge = false
) => ({
  type: TYPES.READ_RESOURCES,
  payload: {
    resourceName,
    merge
  }
})

export const readMoreResources: (resourceName: string) => ReadMoreResources = resourceName => ({
  type: TYPES.READ_MORE_RESOURCES,
  payload: {
    resourceName
  }
})

export const changeRequestState: (options: {
  resourceName: string
  isEnded?: boolean
  state: Info['state']
  responseData?: Array<{}> | Object
}) => ChangeRequestState = options => ({
  type: TYPES.CHANGE_REQUEST_STATE,
  payload: {
    ...options,
    isEnded: options.isEnded || false
  }
})

export const changeLoadingButton: (options: {
  resourceName: string
  loadingButton: Info['loadingButton']
}) => ChangeLoadingButton = options => ({
  type: TYPES.CHANGE_LOADING_BUTTON,
  payload: options
})

export const changeSorting: (options: {
  resourceName: string
  sorting: Info['sorting']
}) => ChangeSorting = options => ({
  type: TYPES.CHANGE_SORTING,
  payload: options
})

export const changeFiltering: (options: {
  resourceName: string
  tab: Info['tab']
}) => ChangeFiltering = options => ({
  type: TYPES.CHANGE_FILTER,
  payload: options
})

export const changeSearch: (options: {
  resourceName: string
  search: Info['search']
}) => ChangeSearch = options => ({
  type: TYPES.CHANGE_SEARCH,
  payload: options
})

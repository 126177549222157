import React, { PureComponent, Fragment, ButtonHTMLAttributes } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import { compose } from 'recompose'
import classnames from 'classnames'
import { Tooltip, Props as TooltipProps } from 'react-tooltip'

import { DATE_PRESETS } from 'constants/filter'
import styles from './styles'

interface Option<OValue = string> {
  value: OValue
  label: string
  icon: React.ReactNode
}

interface OuterProps {
  id: string
  tooltipProps?: Partial<Omit<TooltipProps, 'id'>>
  containerProps?: Partial<ButtonHTMLAttributes<HTMLButtonElement>>
  value: string
  onChange: (value: string) => void
}
export interface Props extends JSSProps<typeof styles>, OuterProps {}

class DateFormatSelect extends PureComponent<Props> {
  buttonRef: HTMLButtonElement

  static defaultProps: Partial<OuterProps> = {
    tooltipProps: { place: 'bottom', effect: 'solid', type: 'light' },
    containerProps: {}
  }

  componentDidMount() {
    const { value, onChange } = this.props

    if (!value) onChange(DATE_PRESETS[0].value)
  }

  onSelect = (option: Option) => {
    const { onChange } = this.props

    this.hideTooltip()
    onChange(option.value)
  }

  hideTooltip = () => {
    if (this.buttonRef) Tooltip.hide(this.buttonRef)
  }

  showTooltip = () => {
    if (this.buttonRef) Tooltip.show(this.buttonRef)
  }

  render() {
    const {
      classes,
      id,
      value,
      tooltipProps,
      containerProps: { disabled, ...containerProps }
    } = this.props

    return (
      <Fragment>
        <Tooltip
          {...tooltipProps}
          id={id}
          events={['click']}
          className={classnames(classes.tooltip, tooltipProps.className)}
        >
          <div className={classes.tooltipContent}>
            <h3 className={classes.title}>Формат даты</h3>
            <div className={classes.optionsList}>
              {DATE_PRESETS.map(option => (
                <div
                  key={`${option.value}`}
                  onClick={() => this.onSelect(option)}
                  className={classes.optionRow}
                >
                  <span className={classes.symbol}>{option.icon}</span>
                  {option.label}
                </div>
              ))}
            </div>
          </div>
        </Tooltip>
        <button
          {...containerProps}
          type="button"
          ref={element => (this.buttonRef = element)}
          data-tooltip-id={id}
          data-tip=""
          data-iscapture="true"
          className={classnames(
            classes.box,
            containerProps.className,
            disabled && classes.boxDisabled
          )}
          disabled={disabled}
        >
          <span className={classes.symbol}>
            {DATE_PRESETS.find(item => item.value === value)
              ? DATE_PRESETS.find(item => item.value === value).icon
              : null}
          </span>
        </button>
      </Fragment>
    )
  }
}

export default compose<Props, OuterProps>(injectStyles(styles))(DateFormatSelect)

import { takeEvery, put, select, call, all } from 'redux-saga/effects'
import { change, getFormValues } from 'redux-form'
import { AxiosError } from 'axios'

import fetchAPI from 'lib/services/fetchAPI'
import { tokenSelector } from 'lib/modules/user/selectors'
import { METHOD, ENDPOINT } from 'constants/api'
import {
  CreateAttachedFile,
  createAttachment,
  CreateAttachment,
  deleteAttachment,
  DeleteAttachment
} from './actions'
import types from './actionTypes'
import objectToFormData from 'src/lib/utils/objectToFormData'
import { INCIDENT_FORM, INCIDENT_MESSAGE_FORM } from 'src/lib/constants/forms'

function* uploadFile(action: CreateAttachment) {
  const token = yield select(tokenSelector)
  const body = objectToFormData(
    { file: action.payload.file, name: action.payload.file.name },
    'attached_file'
  )

  try {
    const { data }: { data: Data.AttachedFile } = yield call(fetchAPI, {
      method: METHOD.POST,
      endpoint: ENDPOINT.ATTACHED_FILES,
      token,
      body
    })
    const splittedUrl = data.file.url.split('/')
    const filename = data.name || splittedUrl[splittedUrl.length - 1]
    const values = yield select(getFormValues(INCIDENT_MESSAGE_FORM.form))
    yield put(
      change(INCIDENT_MESSAGE_FORM.form, 'attachments', [
        ...values.attachments,
        { name: filename, id: data.id }
      ])
    )
  } catch (error) {
    console.error(error)
  }
}

function* uploadAttachedFile(action: CreateAttachedFile) {
  const token = yield select(tokenSelector)
  const body = objectToFormData(
    { file: action.payload.file, name: action.payload.file.name },
    'attached_file'
  )

  try {
    const { data }: { data: Data.AttachedFile } = yield call(fetchAPI, {
      method: METHOD.POST,
      endpoint: ENDPOINT.ATTACHED_FILES,
      token,
      body
    })
    yield put(change(INCIDENT_FORM.form, 'attached_file_id', data.id))
  } catch (error) {
    console.error(error)
  }
}

function* uploadConclusionFile(action: CreateAttachedFile) {
  const token = yield select(tokenSelector)
  const body = objectToFormData({ file: action.payload.file }, 'attached_file')

  try {
    const { data }: { data: Data.AttachedFile } = yield call(fetchAPI, {
      method: METHOD.POST,
      endpoint: ENDPOINT.ATTACHED_FILES,
      token,
      body
    })
    yield put(change(INCIDENT_FORM.form, 'conclusion_file_id', data.id))
  } catch (error) {
    console.error(error)
  }
}

function* deleteFile(action: DeleteAttachment) {
  const token = yield select(tokenSelector)

  try {
    const { data }: { data: Data.AttachedFile } = yield call(fetchAPI, {
      method: METHOD.DELETE,
      endpoint: `${ENDPOINT.ATTACHED_FILES}/${action.payload.id}`,
      token
    })
    const values = yield select(getFormValues(INCIDENT_MESSAGE_FORM.form))
    yield put(
      change(
        INCIDENT_MESSAGE_FORM.form,
        'attachments',
        values.attachments.filter(item => item.id !== action.payload.id)
      )
    )
  } catch (error) {
    console.error(error)
  }
}

export function* watcher() {
  yield all([
    takeEvery(types.CREATE_ATTACHMENT, uploadFile),
    takeEvery(types.DELETE_ATTACHMENT, deleteFile),
    takeEvery(types.CREATE_ATTACHED_FILE, uploadAttachedFile),
    takeEvery(types.CREATE_CONCLUSION_FILE, uploadConclusionFile)
  ])
}

import { Action } from 'redux'
import types from './types'

export interface ReadInfoSystems extends Action<types.READ_INFO_SYSTEMS> {}
export interface ReadInfoSystemsRequest extends Action<types.READ_INFO_SYSTEMS_REQUEST> {}
export interface ReadInfoSystemsSuccess extends Action<types.READ_INFO_SYSTEMS_SUCCESS> {
  payload: {
    list: Data.InfoSystem[]
  }
}
export interface ReadInfoSystemsFailure extends Action<types.READ_INFO_SYSTEMS_FAILURE> {}

export interface UpdateInfoSystems extends Action<types.UPDATE_INFO_SYSTEMS> {
  payload: {
    list: Data.InfoSystem[]
  }
}
export interface UpdateInfoSystemsRequest extends Action<types.UPDATE_INFO_SYSTEMS_REQUEST> {}
export interface UpdateInfoSystemsSuccess extends Action<types.UPDATE_INFO_SYSTEMS_SUCCESS> {
  payload: {
    list: Data.InfoSystem[]
  }
}
export interface UpdateInfoSystemsFailure extends Action<types.UPDATE_INFO_SYSTEMS_FAILURE> {}

export interface ToggleModal extends Action<types.TOGGLE_MODAL> {}
export interface TogglePanel extends Action<types.TOGGLE_PANEL> {}
export interface SetSystem extends Action<types.SET_SYSTEM> {
  payload: {
    system: Data.InfoSystem
  }
}

export type ActionTypes =
  | ReadInfoSystems
  | ReadInfoSystemsRequest
  | ReadInfoSystemsSuccess
  | ReadInfoSystemsFailure
  | UpdateInfoSystems
  | UpdateInfoSystemsRequest
  | UpdateInfoSystemsSuccess
  | UpdateInfoSystemsFailure
  | ToggleModal
  | TogglePanel
  | SetSystem

export const readInfoSystems: () => ReadInfoSystems = () => ({
  type: types.READ_INFO_SYSTEMS
})
export const readInfoSystemsRequest: () => ReadInfoSystemsRequest = () => ({
  type: types.READ_INFO_SYSTEMS_REQUEST
})

export const readInfoSystemsSuccess: (
  list: Data.InfoSystem[]
) => ReadInfoSystemsSuccess = list => ({
  type: types.READ_INFO_SYSTEMS_SUCCESS,
  payload: {
    list
  }
})

export const readInfoSystemsFailure: () => ReadInfoSystemsFailure = () => ({
  type: types.READ_INFO_SYSTEMS_FAILURE
})

export const updateInfoSystems: (list: Data.InfoSystem[]) => UpdateInfoSystems = list => ({
  type: types.UPDATE_INFO_SYSTEMS,
  payload: {
    list
  }
})
export const updateInfoSystemsRequest: () => UpdateInfoSystemsRequest = () => ({
  type: types.UPDATE_INFO_SYSTEMS_REQUEST
})

export const updateInfoSystemsSuccess: (
  list: Data.InfoSystem[]
) => UpdateInfoSystemsSuccess = list => ({
  type: types.UPDATE_INFO_SYSTEMS_SUCCESS,
  payload: {
    list
  }
})

export const updateInfoSystemsFailure: () => UpdateInfoSystemsFailure = () => ({
  type: types.UPDATE_INFO_SYSTEMS_FAILURE
})

export const toggleModal: () => ToggleModal = () => ({
  type: types.TOGGLE_MODAL
})

export const togglePanel: () => TogglePanel = () => ({
  type: types.TOGGLE_PANEL
})

export const setSystem: (system: Data.InfoSystem) => SetSystem = system => ({
  type: types.SET_SYSTEM,
  payload: {
    system
  }
})

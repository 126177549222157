import { Middleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'


const middlewares: Array<Middleware> = []


const sagaMiddleware = createSagaMiddleware()
middlewares.push(sagaMiddleware)

declare var process: any

if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({
    collapsed: true
  })
  middlewares.push(logger)
}

export { middlewares as default, sagaMiddleware }

enum TYPES {
  READ_WIDGETS = 'READ_WIDGETS',
  CREATE_WIDGET = 'CREATE_WIDGET',
  UPDATE_WIDGET = 'UPDATE_WIDGET',
  DELETE_WIDGET = 'DELETE_WIDGET',
  COPY_WIDGET = 'COPY_WIDGET',
  READ_BACKGROUNDS = 'READ_BACKGROUNDS'
}

export default TYPES

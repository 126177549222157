const styles = (theme: App.Theme) => ({
  container: {
    position: 'relative',
    padding: 0
  },
  menuItem: {
    height: 'auto',
    fontSize: '0.875rem',
    lineHeight: 1.2,
    '& + &': {
      marginTop: '1px solid #F7F7F7'
    }
  },
  menuItemGutters: {
    paddingRight: '0.625rem',
    paddingLeft: '0.625rem'
  },
  menuItemIcon: {
    width: '1em',
    height: '1em',
    fontSize: '0.625rem',
    fill: theme.colors.text.secondary,
    stroke: theme.colors.text.secondary
  },
  tooltipContainer: {
    padding: 0,
    fontSize: 'inherit',
    lineHeight: 'inherit',
    color: '#000',
    backgroundColor: '#fff',
    boxShadow: theme.shadows[8],
    overflow: 'hidden'
  },
  tooltipItemText: {
    fontSize: '0.875rem',
    lineHeight: 1.2
  }
})

export default styles

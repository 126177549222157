import { Action, ActionCreator } from 'redux'

import types from './types'

export interface InitializePanel extends Action<types.INITIALIZE_PANEL> {
  payload: Data.Panel
}
export interface DestroyPanel extends Action<types.DESTROY_PANEL> {}

export interface AddTemplate extends Action<types.ADD_TEMPLATE> {
  payload: Data.PanelTemplate
}
export interface DeleteTemplate extends Action<types.DELETE_TEMPLATE> {
  payload: Data.PanelTemplate['id']
}
export interface MoveTemplate extends Action<types.MOVE_TEMPLATE> {
  payload: {
    templateID: Data.PanelTemplate['id']
    direction: 'top' | 'bottom'
  }
}

export interface DeleteElement extends Action<types.DELETE_ELEMENT> {
  payload: {
    templateID: Data.PanelTemplate['id']
    elementID: Data.PanelElement['id']
  }
}
export interface ReplaceElement extends Action<types.REPLACE_ELEMENT> {
  payload: {
    templateID: Data.PanelTemplate['id']
    prevElement: Data.PanelElement
    nextElement: Data.PanelElement
  }
}
export interface DeleteCell extends Action<types.DELETE_CELL> {
  payload: {
    templateID: Data.PanelTemplate['id']
    cellID: Data.PanelCell['id']
  }
}
export interface ReplaceCell extends Action<types.REPLACE_CELL> {
  payload: {
    templateID: Data.PanelTemplate['id']
    cell: Data.PanelCell
  }
}

export interface OpenElementModal extends Action<types.OPEN_ELEMENT_MODAL> {
  payload: {
    templateID: Data.PanelTemplate['id']
    elementID: Data.PanelElement['id']
    elementType: Data.PanelElement['element_type']
    fileExt?: string
  }
}
export interface CloseElementModal extends Action<types.CLOSE_ELEMENT_MODAL> {}

export interface OpenPanelModal extends Action<types.OPEN_PANEL_MODAL> {}
export interface ClosePanelModal extends Action<types.CLOSE_PANEL_MODAL> {}

export interface GetAllElements extends Action<types.GET_ALL_PANELS_ELEMENTS> {
  payload: {
    element_type?: string
    search?: string
    limit?: number
    offset?: number
  }
}
export interface GetAllElementsRequest extends Action<types.GET_ALL_PANELS_ELEMENTS_REQUEST> {
  payload: {}
}
interface GetAllElementsRequestSuccess extends Action<types.GET_ALL_PANELS_ELEMENTS_SUCCESS> {
  payload: Array<{
    id: string
    type_name: string
    elements: Array<Data.Chart | Data.Publication>
  }>
}

interface GetAllElementsRequestFailure extends Action<types.GET_ALL_PANELS_ELEMENTS_FAILURE> {
  payload: {
    error: string
  }
}

export interface GetWidgets extends Action<types.GET_WIDGETS> {
  payload: {
    search?: string
  }
}
export interface GetWidgetsRequest extends Action<types.GET_WIDGETS_REQUEST> {
  payload: {}
}
interface GetWidgetsSuccess extends Action<types.GET_WIDGETS_SUCESS> {
  payload: Data.Widget[]
}

interface GetWidgetsFailure extends Action<types.GET_WIDGETS_FAILURE> {
  payload: {
    error: string
  }
}

// export interface PostElementAttachement extends Action<types.POST_ELEMENT_ATTACHEMENT> {}
// export interface PostElementAttachementRequest extends Action<types.POST_ELEMENT_ATTACHEMENT> {}
// export interface PostElementAttachementSuccess extends Action<types.POST_ELEMENT_ATTACHEMENT> {}
// export interface PostElementAttachementFailure extends Action<types.POST_ELEMENT_ATTACHEMENT> {}

// export interface DeleteElementAttachement extends Action<types.POST_ELEMENT_ATTACHEMENT> {}
// export interface DeleteElementAttachementRequest extends Action<types.POST_ELEMENT_ATTACHEMENT> {}
// export interface DeleteElementAttachementSuccess extends Action<types.POST_ELEMENT_ATTACHEMENT> {}
// export interface DeleteElementAttachementFailure extends Action<types.POST_ELEMENT_ATTACHEMENT> {}

export type ActionTypes =
  | InitializePanel
  | DestroyPanel
  | AddTemplate
  | DeleteTemplate
  | DeleteElement
  | ReplaceElement
  | DeleteCell
  | ReplaceCell
  | MoveTemplate
  | OpenElementModal
  | CloseElementModal
  | OpenPanelModal
  | ClosePanelModal
  | GetAllElements
  | GetAllElementsRequest
  | GetAllElementsRequestSuccess
  | GetAllElementsRequestFailure
  | GetWidgets
  | GetWidgetsRequest
  | GetWidgetsSuccess
  | GetWidgetsFailure
// | PostElementAttachement
// | PostElementAttachementSuccess
// | PostElementAttachementFailure
// | PostElementAttachementRequest
// | DeleteElementAttachement
// | DeleteElementAttachementSuccess
// | DeleteElementAttachementFailure
// | DeleteElementAttachementRequest

export const initializePanel: ActionCreator<InitializePanel> = panel => ({
  type: types.INITIALIZE_PANEL,
  payload: panel
})

export const destroyPanel: ActionCreator<DestroyPanel> = panel => ({
  type: types.DESTROY_PANEL
})

export const addTemplate: ActionCreator<AddTemplate> = template => ({
  type: types.ADD_TEMPLATE,
  payload: template
})

export const deleteTemplate: ActionCreator<DeleteTemplate> = (
  templateID: Data.PanelTemplate['id']
) => ({
  type: types.DELETE_TEMPLATE,
  payload: templateID
})

export const moveTemplate: ActionCreator<MoveTemplate> = (
  templateID: Data.PanelTemplate['id'],
  direction: 'top' | 'bottom'
) => ({
  type: types.MOVE_TEMPLATE,
  payload: {
    templateID,
    direction
  }
})

export const deleteElement: ActionCreator<DeleteElement> = options => ({
  type: types.DELETE_ELEMENT,
  payload: options
})

export const replaceElement: ActionCreator<ReplaceElement> = options => ({
  type: types.REPLACE_ELEMENT,
  payload: options
})

export const deleteCell: ActionCreator<DeleteCell> = options => ({
  type: types.DELETE_CELL,
  payload: options
})

export const replaceCell: ActionCreator<ReplaceCell> = options => ({
  type: types.REPLACE_CELL,
  payload: options
})

export const openElementModal: ActionCreator<OpenElementModal> = options => ({
  type: types.OPEN_ELEMENT_MODAL,
  payload: options
})

export const closeElementModal: ActionCreator<CloseElementModal> = options => ({
  type: types.CLOSE_ELEMENT_MODAL
})

export const openPanelModal: ActionCreator<OpenPanelModal> = () => ({
  type: types.OPEN_PANEL_MODAL
})

export const closePanelModal: ActionCreator<ClosePanelModal> = () => ({
  type: types.CLOSE_PANEL_MODAL
})

export const readAllElements: ActionCreator<GetAllElements> = options => ({
  type: types.GET_ALL_PANELS_ELEMENTS,
  payload: options
})

export const readAllElementsRequest: ActionCreator<GetAllElementsRequest> = () => ({
  type: types.GET_ALL_PANELS_ELEMENTS_REQUEST,
  payload: {}
})

export const readAllElementsRequestSuccess: ActionCreator<GetAllElementsRequestSuccess> = data => ({
  type: types.GET_ALL_PANELS_ELEMENTS_SUCCESS,
  payload: data
})

export const readAllElementsRequestFailure: ActionCreator<
  GetAllElementsRequestFailure
> = error => ({
  type: types.GET_ALL_PANELS_ELEMENTS_FAILURE,
  payload: {
    error
  }
})

export const readWidgets: ActionCreator<GetWidgets> = options => ({
  type: types.GET_WIDGETS,
  payload: options
})

export const readWidgetsRequest: ActionCreator<GetWidgetsRequest> = () => ({
  type: types.GET_WIDGETS_REQUEST,
  payload: {}
})

export const readWidgetsSuccess: ActionCreator<GetWidgetsSuccess> = data => ({
  type: types.GET_WIDGETS_SUCESS,
  payload: data
})

export const readWidgetsFailure: ActionCreator<GetWidgetsFailure> = error => ({
  type: types.GET_WIDGETS_FAILURE,
  payload: {
    error
  }
})

import React, { PureComponent, ComponentClass } from 'react'
import { compose } from 'recompose'
import { Field, FieldArrayFieldsProps, WrappedFieldProps } from 'redux-form'
import { Range } from 'react-input-range'
import injectStyles, { JSSProps } from 'react-jss'

import { notEmpty } from 'lib/utils/validations'
import Value from './components/Value'
import { FIELD_TYPES, COMPARE_OPERATORS, DATE_RANGE, OPTION_TYPES } from 'constants/filter'
import styles from './styles'

interface HOCOuterProps {
  name: string
  index: number
  fields: FieldArrayFieldsProps<Filter.Expression>
  options: Array<Filter.Option>
  selectedOption?: Filter.Option
}
interface OuterProps extends JSSProps<typeof styles> {
  index: number
  fields: FieldArrayFieldsProps<Filter.Expression>
  options: Array<Filter.Option>
  selectedOption?: Filter.Option
  // Fix strange ts error
  component?: any
}
interface HOCProps extends JSSProps<typeof styles>, HOCOuterProps {}
interface Props extends OuterProps, WrappedFieldProps {}

const DateRangeHOC = ({ name, ...props }: HOCProps) => (
  <Field {...props} name={name} component={DateRange as ComponentClass<OuterProps>} />
)

class DateRange extends PureComponent<Props> {
  componentDidMount() {
    const {
      selectedOption,
      input: { value, onChange }
    } = this.props

    let result = {}

    const dateValue = [DATE_RANGE.min.toString(), DATE_RANGE.max.toString()]

    // If we initialize date value or it was a date filter,
    // we must use previous values prefer then the default values
    if (!value.field1) {
      result = {
        ...value,
        condition: COMPARE_OPERATORS.MORE,
        field2: {
          value: dateValue,
          type: FIELD_TYPES.ENTER
        }
      }
    } else {
      result = {
        condition: COMPARE_OPERATORS.MORE,
        field2: {
          value: dateValue,
          type: FIELD_TYPES.ENTER,
          ...value.field2
        },
        ...value
      }
    }

    onChange(result)
  }

  formatDateField = (value: Array<string> | undefined): Range => {
    if (value) {
      return {
        min: Number(value[0]),
        max: Number(value[1])
      }
    }

    return DATE_RANGE
  }

  parseDateField = (value: Range | Array<string> | undefined): Array<string> => {
    if (value && value instanceof Object) {
      return [(value as Range).min.toString(), (value as Range).max.toString()]
    }

    return value as Array<string> | undefined
  }

  render() {
    const {
      classes,
      input: { name },
      index
    } = this.props

    return (
      <Field
        name={`${name}.field2.value`}
        parse={this.parseDateField}
        format={this.formatDateField}
        validate={[notEmpty]}
        id={`${index}-date-range`}
        minValue={DATE_RANGE.min}
        maxValue={DATE_RANGE.max}
        component={Value}
      />
    )
  }
}

export default compose<HOCProps, HOCOuterProps>(injectStyles(styles))(DateRangeHOC)

import { colors } from 'lib/theme/colors'

const styles = (theme: App.Theme) => ({
  container: {
    width: '100%',
    ...theme.utils.flexContainer({
      direction: 'row',
      justify: 'center',
      alignItems: 'initial',
      wrap: 'nowrap'
    })
  },
  tabButton: {
    ...theme.utils.flexContainer({ alignItems: 'center', justify: 'center', direction: 'row' }),
    flexGrow: 1,
    padding: '0.625rem 1.25rem',
    fontSize: '12px',
    lineHeight: '17px',
    fontWeight: 600,
    textAlign: 'center',
    color: theme.colors.grey,
    cursor: 'pointer',
    backgroundColor: 'transparent',
    outline: 'none',
    boxSizing: 'border-box',
    border: 'none',
    transition: 'color 0.3s ease, background-color 0.3s ease',
    '&:first-of-type': {
      paddingLeft: 0
    },
    '& span': {
      whiteSpace: 'nowrap',
      paddingBottom: '4px'
    },
    '&:hover': {
      color: 'black',
      '& span': {
        borderBottom: `2px solid ${colors.blue}`
      }
    },
    '& + &': { borderLeft: 'none' }
  },
  tabButtonActive: {
    color: 'black',
    '& span': {
      borderBottom: `2px solid ${colors.blue}`
    }
  },
  tabIcon: {
    marginLeft: '0.25rem',
    ...theme.utils.flexContainer({ direction: 'row', alignItems: 'center' })
  }
})

export default styles

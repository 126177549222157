import { takeEvery, put, select, all, call } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import { success, error } from 'react-notification-system-redux'

import fetchAPI from 'lib/services/fetchAPI'
import APIAction from 'lib/utils/APIAction'
import getResourceActions from 'lib/utils/getResourceActions'
import { tokenSelector } from 'lib/modules/user/selectors'
import {
  ReadInfoReferences,
  CreateInfoReference,
  UpdateInfoReference,
  DeleteInfoReference,
  CopyInfoReference
} from './actions'
import { METHOD, ENDPOINT } from 'constants/api'
import TYPES from './actionTypes'
import objectToFormData from 'src/lib/utils/objectToFormData'

function* readInfoReferences(action: ReadInfoReferences) {
  const { requestKey, resourceType, requestProperties, list } = action.payload
  const token = yield select(tokenSelector)
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'READ',
    resourceType,
    requestKey,
    requestProperties,
    list
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      const { data } = yield call(fetchAPI, {
        endpoint: ENDPOINT.INFO_REFERENCES,
        method: METHOD.GET,
        token,
        params: requestProperties
      })

      return { data }
    },
    *success(data) {
      yield put(onSuccessAction(data))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось получить данные.'
        })
      )
    }
  })
}

function* createInfoReference(action: CreateInfoReference) {
  const token = yield select(tokenSelector)

  const { requestKey, resourceType, requestProperties } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'CREATE',
    resourceType,
    requestKey,
    requestProperties
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())
      const { info_reference } = requestProperties

      const fd = objectToFormData(info_reference, 'info_reference')

      const { data }: { data: Data.InfoReference } = yield call(fetchAPI, {
        endpoint: ENDPOINT.INFO_REFERENCES,
        method: METHOD.POST,
        token,
        body: fd
      })

      return { data: [data] }
    },
    *success(data: Array<Data.InfoReference>) {
      yield put(onSuccessAction(data))
      yield put(replace(`/info_references/${data[0].id}`))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось создать справку.'
        })
      )
    }
  })
}

function* updateInfoReference(action: UpdateInfoReference) {
  const token = yield select(tokenSelector)

  const { requestKey, resourceType, requestProperties } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'UPDATE',
    resourceType,
    requestKey,
    requestProperties
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())
      const { info_reference } = requestProperties

      const fd = objectToFormData(info_reference, 'info_reference')

      const { data }: { data: Data.InfoReference } = yield call(fetchAPI, {
        endpoint: ENDPOINT.INFO_REFERENCES,
        method: METHOD.PUT,
        path: requestProperties.info_reference.id,
        token,
        body: fd
      })

      return { data: [data] }
    },
    *success(data: Array<Data.Chart>) {
      yield put(onSuccessAction(data))

      yield put(
        success({
          title: 'Справка успешно обновлена.'
        })
      )
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось обновить справку.'
        })
      )
    }
  })
}

function* deleteInfoReference(action: DeleteInfoReference) {
  const token = yield select(tokenSelector)
  const {
    requestKey,
    resourceType,
    requestProperties: { id }
  } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'DELETE',
    resourceType,
    requestKey,
    ids: [id],
    requestProperties: {
      id
    }
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        endpoint: ENDPOINT.INFO_REFERENCES,
        method: METHOD.DELETE,
        path: id.toString(),
        token
      })
    },
    *success(data) {
      yield put(onSuccessAction([id]))
      yield put(replace(`/info_references`))

      yield put(
        success({
          title: 'Справка успешно удалена.'
        })
      )
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось удалить справку.'
        })
      )
    }
  })
}

function* copyInfoReference(action: CopyInfoReference) {
  const token = yield select(tokenSelector)

  const { requestKey, resourceType, requestProperties } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'CREATE',
    resourceType,
    requestKey,
    requestProperties
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      const { data }: { data: Data.InfoReference } = yield call(fetchAPI, {
        endpoint: ENDPOINT.INFO_REFERENCES,
        method: METHOD.POST,
        token,
        body: {
          info_reference: requestProperties.info_reference
        }
      })

      return { data: [data] }
    },
    *success(data: Array<Data.InfoReference>) {
      yield put(onSuccessAction(data))
      yield put(replace(`/info_references/${data[0].id}`))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось скопировать справку.'
        })
      )
    }
  })
}

export function* watcher() {
  yield all([
    takeEvery(TYPES.READ_INFO_REFERENCES, readInfoReferences),
    takeEvery(TYPES.CREATE_INFO_REFERENCE, createInfoReference),
    takeEvery(TYPES.UPDATE_INFO_REFERENCE, updateInfoReference),
    takeEvery(TYPES.COPY_INFO_REFERENCE, copyInfoReference),
    takeEvery(TYPES.DELETE_INFO_REFERENCE, deleteInfoReference)
  ])
}

import { Props } from './index'
import { colors } from 'lib/theme/colors'

const KEYFRAME_ID = 'checkbox_wave'

const styles = (theme: OpenApp.Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    opacity: ({ disabled }: Props) => (disabled ? 0.5 : 1),
    transition: 'opacity 0.3s ease-out'
  },
  text: {
    fontFamily: 'Helvetica',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    color: '#000000',
    cursor: 'pointer',
    '&:first-child': {
      marginRight: '0.5rem'
    },
    '&:last-child': {
      marginLeft: '0.5rem'
    }
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
    cursor: 'pointer'
  },
  input: {
    '&:checked + $label': {
      '& $checkbox': {
        background: theme.colors.text.primary,
        borderColor: theme.colors.text.primary,
        animation: `${KEYFRAME_ID} .4s ease`,
        '& svg': {
          strokeDashoffset: '0'
        },
        '&:before': {
          transform: 'scale(3.5)',
          opacity: 0,
          transition: 'all .6s ease'
        }
      },
      '&:hover > $checkbox': {
        borderColor: theme.colors.text.secondary
      }
    }
  },
  labelText: {
    fontSize: '0.875rem',
    '&:first-child': {
      marginRight: '0.5rem'
    },
    '&:last-child': {
      marginLeft: '0.5rem'
    }
  },
  checkbox: {
    flexShrink: 0,
    position: 'relative',
    width: '24px',
    height: '24px',
    fontSize: 0,
    borderRadius: 3,
    transform: 'scale(1)',
    verticalAlign: 'middle',
    border: `1px solid ${colors.toggle.borderColor}`,
    transition: 'all .2s ease',
    '&:before': {
      content: '',
      width: '100%',
      height: '100%',
      background: '#506EEC',
      display: 'block',
      transform: 'scale(0)',
      opacity: 1,
      borderRadius: '50%'
    }
  },
  checkboxIcon: {
    width: '100%',
    height: '100%',
    fill: 'none',
    stroke: '#FFFFFF',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeDasharray: 16,
    strokeDashoffset: 16,
    transition: 'all .3s ease',
    transitionDelay: '.1s',
    transform: 'translate3d(0, 0, 0)'
  },
  [`@keyframes ${KEYFRAME_ID}`]: {
    '50%': {
      transform: 'scale(0.9)'
    }
  }
})

export default styles

import {
  ChangeColumnParams,
  ChangeDataCellPayload,
  ChangeDataCell,
  AddColumnPayload,
  AddColumn,
  Types,
  ClearColumn,
  RemoveColumn,
  AddCell,
  AddData,
  RemoveCube,
  CleanCube,
  GetNSI,
  GetNSISuccess,
  GetNSIFail,
  SetAccessCube,
  RefreshSettingsPayload,
  SetRefreshSettingsData,
  Cube,
  CreateCube,
  CreateCubeSuccess,
  CreateCubeFail,
  SendFilePayload,
  SendFileSuccess,
  SendFileFail,
  SendFile,
  GetCube,
  GetCubeSuccess,
  GetCubeFail,
  ChangeMode,
  UpdateCubePayload,
  UpdateCube,
  UpdateCubeSuccess,
  UpdateCubeFail,
  DeleteCubeSuccess,
  DeleteCubeFail,
  DeleteCube,
  RemoveRow
} from './types'
import { ColumnParamsType, RESOURCES } from 'constants/dataImport'
import { Mode } from 'lib/modules/data/making/reducer'

export const changeColumnParams: (
  index: number,
  params: Partial<Data.ColumnParams>
) => ChangeColumnParams = (index, params) =>
  ({
    type: Types.CHANGE_COLUMN_PARAMS,
    payload: { index, params }
  } as const)

export const changeDataCell: (opts: ChangeDataCellPayload) => ChangeDataCell = opts =>
  ({
    type: Types.CHANGE_DATA_CELL,
    payload: opts
  } as const)

export const addColumn: (col: AddColumnPayload) => AddColumn = col =>
  ({
    type: Types.ADD_COLUMN,
    payload: {
      pr_key: false,
      type: ColumnParamsType.String,
      nsi: null,
      ...col
    }
  } as const)

export const clearColumn: (index: number) => ClearColumn = index =>
  ({
    type: Types.CLEAR_COLUMN,
    payload: index
  } as const)

export const removeColumn: (index: number) => RemoveColumn = index =>
  ({
    type: Types.REMOVE_COLUMN,
    payload: index
  } as const)

export const removeRow: (index: number) => RemoveRow = index =>
  ({
    type: Types.REMOVE_ROW,
    payload: index
  } as const)

export const addCell: () => AddCell = () => ({ type: Types.ADD_CELL } as const)

export const addData: (id: Data.CubeParams['id'], data: Array<string | number>) => AddData = (
  id,
  data
) =>
  ({
    type: Types.ADD_DATA,
    payload: { id, data }
  } as const)

export const removeCube: () => RemoveCube = () => ({ type: Types.REMOVE_CUBE } as const)

export const cleanCube: () => CleanCube = () => ({ type: Types.CLEAN_CUBE } as const)

export const getNSI: (id: number, key: string) => GetNSI = (id, key) =>
  ({
    type: Types.GET_NSI,
    payload: {
      id,
      key
    }
  } as const)

export const getNSISuccess: (data: {
  [key: string]: {
    headers: Data.ColumnParams[]
    rows: string[][]
  }
}) => GetNSISuccess = data =>
  ({
    type: Types.GET_NSI_SUCCESS,
    payload: data
  } as const)

export const getNSIFail: () => GetNSIFail = () =>
  ({
    type: Types.GET_NSI_FAIL
  } as const)

export const setAccessCube: (data: []) => SetAccessCube = data =>
  ({
    type: Types.ACCESS_CUBE,
    payload: data
  } as const)

export const setRefreshSettingsData: (
  data: RefreshSettingsPayload
) => SetRefreshSettingsData = data =>
  ({
    type: Types.REFRESH_SETTINGS_DATA_CUBE,
    payload: data
  } as const)

export const createCube: (cube: Cube) => CreateCube = cube =>
  ({
    type: Types.CREATE_CUBE,
    payload: {
      resourceType: RESOURCES.MAKING_CUBE.NAME,
      requestKey: RESOURCES.MAKING_CUBE.REQUESTS.CREATE,
      cube
    }
  } as const)

export const createCubeSuccess: (id: number) => CreateCubeSuccess = id =>
  ({
    type: Types.CREATE_CUBE_SUCCESS,
    payload: id
  } as const)

export const createCubeFail: () => CreateCubeFail = () =>
  ({
    type: Types.CREATE_CUBE_FAIL
  } as const)

export const sendFile: (data: SendFilePayload['requestProperties']) => SendFile = data =>
  ({
    type: Types.SEND_FILE,
    payload: {
      resourceType: RESOURCES.MAKING_CUBE.NAME,
      requestKey: RESOURCES.MAKING_CUBE.REQUESTS.UPDATE,
      requestProperties: {
        ...data
      }
    }
  } as const)

export const sendFileSuccess: () => SendFileSuccess = () =>
  ({
    type: Types.SEND_FILE_SUCCESS
  } as const)

export const sendFileFail: () => SendFileFail = () =>
  ({
    type: Types.SEND_FILE_FAIL
  } as const)

export const getCube: (id: number | string) => GetCube = id =>
  ({
    type: Types.GET_CUBE,
    payload: {
      resourceType: RESOURCES.MAKING_CUBE.NAME,
      requestKey: RESOURCES.MAKING_CUBE.REQUESTS.READ,
      id
    }
  } as const)

export const getCubeSuccess: (data: {
  name: string
  description: string
  headers: Data.ColumnParams[]
  rows: string[][]
  nsi: boolean
}) => GetCubeSuccess = data =>
  ({
    type: Types.GET_CUBE_SUCCESS,
    payload: data
  } as const)

export const getCubeFail: () => GetCubeFail = () =>
  ({
    type: Types.GET_CUBE_FAIL
  } as const)

export const changeMode: (mode: Mode) => ChangeMode = mode =>
  ({
    type: Types.CHANGE_MODE,
    payload: mode
  } as const)

export const updateCube: ({ cube, id }: UpdateCubePayload['requestProperties']) => UpdateCube = ({
  cube,
  id
}) =>
  ({
    type: Types.UPDATE_CUBE,
    payload: {
      resourceType: RESOURCES.MAKING_CUBE.NAME,
      requestKey: RESOURCES.MAKING_CUBE.REQUESTS.UPDATE,
      requestProperties: {
        cube,
        id
      }
    }
  } as const)

export const updateCubeSuccess: () => UpdateCubeSuccess = () =>
  ({
    type: Types.UPDATE_CUBE_SUCCESS
  } as const)

export const updateCubeFail: () => UpdateCubeFail = () =>
  ({
    type: Types.UPDATE_CUBE_FAIL
  } as const)

export const deleteCube: (id: number | string) => DeleteCube = id =>
  ({
    type: Types.DELETE_CUBE,
    payload: {
      resourceType: RESOURCES.CUBES.NAME,
      requestKey: RESOURCES.CUBES.REQUESTS.DELETE,
      id
    }
  } as const)

export const deleteCubeSuccess: () => DeleteCubeSuccess = () =>
  ({
    type: Types.DELETE_CUBE_SUCCESS
  } as const)

export const deleteCubeFail: () => DeleteCubeFail = () =>
  ({
    type: Types.DELETE_CUBE_FAIL
  } as const)

import { takeEvery, put, select, call, all } from 'redux-saga/effects'
import { AxiosError } from 'axios'

import fetchAPI from 'lib/services/fetchAPI'
import { tokenSelector } from 'lib/modules/user/selectors'
import { METHOD, ENDPOINT } from 'constants/api'
import {
  ReadImportFiles,
  readImportFilesLoading,
  readImportFilesFailure,
  readImportFilesSuccess,
  UpdateImportFile,
  replaceImportFileData
} from './actions'
import types from './types'

function* readImportFiles(action: ReadImportFiles) {
  const token = yield select(tokenSelector)

  try {
    yield put(readImportFilesLoading())
    const { data }: { data: Data.CovidImportFile[] } = yield call(fetchAPI, {
      // token,
      method: METHOD.GET,
      endpoint: ENDPOINT.IMPORT_FILES
    })
    yield put(readImportFilesSuccess(data))
  } catch (error) {
    console.error(error)

    yield put(readImportFilesFailure())
  }
}

function* updateImportFile(action: UpdateImportFile) {
  const token = yield select(tokenSelector)
  const { period } = action.payload

  try {
    const { data }: { data: Data.CovidImportFile } = yield call(fetchAPI, {
      // token,
      method: METHOD.PUT,
      endpoint: `${ENDPOINT.IMPORT_FILES}/${action.payload.id}`,
      body: { period }
    })
    yield put(replaceImportFileData(data))
  } catch (error) {
    console.error(error)
  }
}

export function* watcher() {
  yield all([
    takeEvery(types.READ_IMPORT_FILES, readImportFiles),
    takeEvery(types.UPDATE_IMPORT_FILE, updateImportFile)
  ])
}

import React, { Component, Fragment } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { compose } from 'recompose'

import Modal from 'react-modal'
import { format } from 'date-fns'

import fetchAPI from 'lib/services/fetchAPI'
import { closeModal } from 'lib/modules/open/modals/actions'
import { getModalItemId, isModalOpen } from 'lib/modules/open/modals/selectors'
import Loading from 'components/Loading'
import Button from 'components/ButtonRounded'
import { Cancel } from 'components/Icons'
import DataCell from 'components/DataCell'
import Tag from 'components/Tag'
import { MODALS_NAMES } from 'constants/modals'
import { ENDPOINT } from 'constants/api'
import styles from './styles'
import { DATE_FORMAT_TYPES, formatDate } from 'src/lib/utils/dateHelper'

interface OuterProps {
  /* react-modal passed props */
  closeTimeoutMS?: number
  contentLabel?: string
}
interface StateProps {
  isOpen: boolean
  itemId?: string
}
interface DispatchProps {
  closeModal: () => void
}
interface Props extends JSSProps<typeof styles>, OuterProps, StateProps, DispatchProps {}
interface State {
  info?: Data.Order
}

Modal.setAppElement(document.getElementById('root'))

class OrderModal extends Component<Props, State> {
  state = {
    info: null
  }

  onAfterOpen = () => {
    const { itemId } = this.props
    this.getInfo(+itemId)
  }

  async getInfo(id: number) {
    const { data } = await fetchAPI<Data.Order[]>({
      endpoint: ENDPOINT.ORDERS,
      params: {
        ids: [id],
        full_info: true
      }
    })

    this.setState({ info: data[0] })
  }

  get isLoading() {
    return !this.state.info || this.state.info.id !== this.props.itemId
  }

  render() {
    const { classes, closeModal, isOpen, contentLabel, closeTimeoutMS, theme } = this.props

    const { info }: { info: Data.Order } = this.state

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={() => closeModal()}
        onAfterOpen={this.onAfterOpen}
        contentLabel={contentLabel}
        closeTimeoutMS={closeTimeoutMS}
        portalClassName={classes.portal}
        className={classes.container}
        overlayClassName={classes.overlay}
      >
        <div className={classes.buttons}>
          <Button
            className={classes.closeButton}
            icon={Cancel}
            title="Закрыть"
            onClick={() => closeModal()}
          />
        </div>
        <div className={classes.content}>
          {this.isLoading ? (
            <Loading delay={0} />
          ) : (
            <Fragment>
              <div className={classes.orderSection}>
                <h1 className={classes.orderTitle}>Информация о закупке</h1>
                <a
                  href={`http://zakupki.gov.ru/epz/order/notice/ea44/view/common-info.html?regNumber=${
                    info.reqnum
                  }`}
                  target="_blank"
                  className={classes.orderEis} rel="noreferrer"
                >
                  Закупка на ЕИС
                </a>
                <div className={classes.orderGrid}>
                  <div className={classes.orderColumn}>
                    <DataCell
                      name="Год плана графика"
                      value={info.schedule_year}
                      valueClassName={classes.orderDataCellValue}
                    />
                    <DataCell
                      name="Плановая дата размещения заказа"
                      value={
                        info.plannedplacementdate
                          ? formatDate({ date: new Date(info.plannedplacementdate * 1000) })
                          : null
                      }
                      valueClassName={classes.orderDataCellValue}
                    />
                    <DataCell
                      name="Реестровый номер ПГ"
                      value={info.pg_n}
                      valueClassName={classes.orderDataCellValue}
                    />
                    <DataCell
                      name="Реестровый номер позиции ПГ"
                      value={info.pg_rn}
                      valueClassName={classes.orderDataCellValue}
                    />
                    <DataCell
                      name="ИКЗ"
                      value={info.pg_ikz}
                      valueClassName={classes.orderDataCellValue}
                    />
                    <DataCell
                      name="Реестровый номер извещения"
                      value={info.reqnum}
                      valueClassName={classes.orderDataCellValue}
                    />
                    <DataCell
                      name="Дата публикации извещения"
                      value={
                        info.publishdate
                          ? formatDate({ date: new Date(info.publishdate * 1000) })
                          : null
                      }
                      valueClassName={classes.orderDataCellValue}
                    />
                    <DataCell
                      name="ОКПД2"
                      value={info.okpd2name}
                      valueClassName={classes.orderDataCellValue}
                    />
                    <DataCell
                      name="Идентификатор"
                      value={info.requestid}
                      valueClassName={classes.orderDataCellValue}
                    />
                  </div>
                  <div className={classes.orderColumn}>
                    <DataCell
                      name="Стадия закупки"
                      value={info.reqstage}
                      valueClassName={classes.orderDataCellValue}
                    />
                    <DataCell
                      name="Номер лота"
                      value={info.lotnum}
                      valueClassName={classes.orderDataCellValue}
                    />
                    <DataCell
                      name="Способ определения поставщика"
                      value={info.fordertypename}
                      valueClassName={classes.orderDataCellValue}
                    />
                    <DataCell
                      name="Основание осуществления закупки у единственного поставщика"
                      value={info.oneexec}
                      valueClassName={classes.orderDataCellValue}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.productSection}>
                <div className={classes.productSectionHeader}>
                  <Tag
                    value={info.contract_stagetitle}
                    color={
                      info.contract_stagetitle === 'Контракт исполнен'
                        ? theme.colors.green
                        : theme.colors.grey
                    }
                  />
                </div>
                <DataCell name="Наименование заказчика" value={info.orgtitle} />
                <DataCell name="Контракт" value={info.subject} />
                <DataCell name="Вид продукции" value={info.producttype} />
                <DataCell
                  name="Цена за единицу товара"
                  value={info.unitcost}
                  formatValue={value => `${value} руб.`}
                />
                <DataCell name="Количество товара" value={info.ammount} />
                <DataCell
                  name="Сумма за товары"
                  value={info.sum}
                  formatValue={value => `${value} руб.`}
                />
                <DataCell
                  name="НМЦК"
                  value={info.f_productprice}
                  formatValue={value => `${value} руб.`}
                />
                <DataCell
                  name="Выплачено"
                  value={info.final_price}
                  formatValue={value => `${value} руб.`}
                />
              </div>
            </Fragment>
          )}
        </div>
      </Modal>
    )
  }
}

const mapStateToProps: MapStateToProps<StateProps, Props, OpenApp.State> = state => ({
  isOpen: isModalOpen(MODALS_NAMES.ORDER)(state),
  itemId: getModalItemId(MODALS_NAMES.ORDER)(state) as string
})
const mapDispatchToProps: MapDispatchToProps<DispatchProps, Props> = dispatch => ({
  closeModal: () => dispatch(closeModal(MODALS_NAMES.ORDER))
})

export default compose<Props, OuterProps>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectStyles(styles),
)(OrderModal)

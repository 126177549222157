import React, { PureComponent } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import { compose } from 'recompose'
import withRouter from '../WithRouter'

import { Notification } from './components'
import styles from './styles'
import { RouterProps } from 'react-router-dom'

export interface OuterProps {
  notifications?: Array<Data.Notification>
  closeTooltip?: () => void
  readNotification: (id: number) => void
}
interface Props extends JSSProps<typeof styles>, RouterProps, OuterProps {}

class NotificationsList extends PureComponent<Props> {
  render() {
    const { notifications, readNotification, classes, closeTooltip } = this.props

    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <h3 className={classes.title}>Уведомления</h3>
        </div>
        <ul className={classes.list}>
          {notifications.length > 0 ? (
            notifications
              .sort((a, b) => b.created_at - a.created_at)
              .map(item => (
                <Notification
                  readNotification={readNotification}
                  closeTooltip={closeTooltip}
                  key={item.id}
                  notification={item}
                />
              ))
          ) : (
            <span className={classes.emptyPlaceholder}>Нет новых уведомлений</span>
          )}
        </ul>
      </div>
    )
  }
}

export default compose<Props, OuterProps>(
  injectStyles(styles),
  withRouter
)(NotificationsList)

import { difference } from 'lodash'
import { show } from 'react-notification-system-redux'
import { actionTypes } from 'redux-resource'
import { END, eventChannel } from 'redux-saga'
import {
  all,
  call,
  cancel,
  cancelled,
  fork,
  put,
  select,
  spawn,
  take,
  takeEvery
} from 'redux-saga/effects'

import { NOTIFICATIONS_CHANNEL } from 'constants/actionCable'
import { ENDPOINT, METHOD } from 'constants/api'
import { NOTIFICATIONS } from 'constants/resources'
import { removeNotification, setNotification } from 'lib/modules/sidemenu/actions'
import { iconsWithActiveNotification } from 'lib/modules/sidemenu/selectors'
import { tokenSelector } from 'lib/modules/user/selectors'
import ActionCableService from 'lib/services/actionCable'
import fetchAPI from 'lib/services/fetchAPI'
import { GetNotifications, ReadNotification } from './actions'

import { Types } from './actionTypes'
import { getUnreadNotifications } from './selectors'

function notificationsChannel(token: string) {
  return eventChannel(emitter => {
    const channel = ActionCableService.createChannel(
      NOTIFICATIONS_CHANNEL,
      {
        connected: () => console.info('notifications channel connected'),
        disconnected: () => emitter(END),
        received: data => emitter(data)
      },
      { token }
    )

    return () => {
      ActionCableService.deleteChannel(NOTIFICATIONS_CHANNEL)
    }
  })
}

function* getNotifications(action: GetNotifications) {
  const { requestKey, resourceType } = action.payload
  const token = yield select(tokenSelector)

  yield put({
    type: actionTypes.READ_RESOURCES_PENDING,
    requestKey,
    resourceType
  })

  try {
    const { data } = yield call(fetchAPI, {
      method: METHOD.GET,
      endpoint: ENDPOINT.NOTIFICATIONS,
      token
    })

    yield put({
      type: actionTypes.READ_RESOURCES_SUCCEEDED,
      requestKey,
      resourceType,
      resources: data
    })

    const unreadNotifications: Array<Data.Notification> = yield select(getUnreadNotifications)
    if (unreadNotifications.length === 0) {
      yield put(removeNotification('notifications'))
    } else {
      yield put(setNotification('notifications', unreadNotifications.length))
    }

    /* set badges to other sidemenu icons  */
    const additionalPaths = []
    if (
      unreadNotifications.filter(
        item => item.notification_type && item.notification_type !== 'other'
      )
    ) {
      unreadNotifications
        .filter(item => item.notification_type && item.notification_type !== 'other')
        .forEach(item => {
          if (!additionalPaths.includes(item.notification_type))
            additionalPaths.push(item.notification_type)
        })
    }
    if (additionalPaths.length > 0)
      yield all(
        additionalPaths.map(path =>
          put(
            setNotification(
              path,
              unreadNotifications.filter(item => item.notification_type === path).length
            )
          )
        )
      )
  } catch (err) {
    yield put({
      type: actionTypes.READ_RESOURCES_PENDING,
      requestKey,
      resourceType,
      requestProperties: {
        error: err
      }
    })
  }
}
function* readNotifications(action: ReadNotification) {
  const { requestKey, resourceType, requestProperties } = action.payload
  const token = yield select(tokenSelector)

  yield put({
    type: actionTypes.UPDATE_RESOURCES_PENDING,
    requestKey,
    resourceType
  })

  try {
    const { data } = yield call(fetchAPI, {
      method: METHOD.POST,
      endpoint: ENDPOINT.NOTIFICATIONS,
      path: `read`,
      body: { ids: [requestProperties.id] },
      token
    })

    yield put({
      type: actionTypes.UPDATE_RESOURCES_SUCCEEDED,
      requestKey,
      resourceType,
      resources: data
    })

    const unreadNotifications: Array<Data.Notification> = yield select(getUnreadNotifications)
    if (unreadNotifications.length === 0) {
      yield put(removeNotification('notifications'))
    } else {
      yield put(setNotification('notifications', unreadNotifications.length))
    }
    /* remove no longer active badges  */
    const activeIcons = yield select(iconsWithActiveNotification)
    const activeIds = activeIcons.map(item => item.id).filter(item => item !== 'notifications')

    const additionalPaths = []
    if (
      unreadNotifications.filter(
        item => item.notification_type && item.notification_type !== 'other'
      )
    ) {
      unreadNotifications
        .filter(item => item.notification_type && item.notification_type !== 'other')
        .forEach(item => {
          if (!additionalPaths.includes(item.notification_type))
            additionalPaths.push(item.notification_type)
        })
    }
    const badgesToRemove = difference(activeIds, additionalPaths)
    if (badgesToRemove.length > 0)
      yield all(badgesToRemove.map(path => put(removeNotification(path))))
  } catch (err) {
    yield put({
      type: actionTypes.UPDATE_RESOURCES_FAILED,
      requestKey,
      resourceType,
      requestProperties: {
        ...requestProperties,
        error: err
      }
    })
  }
}

function* notificationsListener() {
  const token = yield select(tokenSelector)
  const channel = yield call(notificationsChannel, token)

  try {
    while (true) {
      const {
        type,
        payload: notification
      }: { type: string; payload: Data.Notification } = yield take(channel)

      if (type === 'NEW_NOTIFICATION') {
        yield put({
          type: actionTypes.READ_RESOURCES_SUCCEEDED,
          resourceType: NOTIFICATIONS.NAME,
          resources: [notification]
        })
        /* update badges */
        const unreadNotifications: Array<Data.Notification> = yield select(getUnreadNotifications)
        if (unreadNotifications.length === 0) {
          yield put(removeNotification('notifications'))
        } else {
          yield put(setNotification('notifications', unreadNotifications.length))
        }

        /* set badges to other sidemenu icons  */
        const additionalPaths = []
        if (
          unreadNotifications.filter(
            item => item.notification_type && item.notification_type !== 'other'
          )
        ) {
          unreadNotifications
            .filter(item => item.notification_type && item.notification_type !== 'other')
            .forEach(item => {
              if (!additionalPaths.includes(item.notification_type))
                additionalPaths.push(item.notification_type)
            })
        }
        if (additionalPaths.length > 0)
          yield all(
            additionalPaths.map(path =>
              put(
                setNotification(
                  path,
                  unreadNotifications.filter(item => item.notification_type === path).length
                )
              )
            )
          )
        /* */

        yield put(show({ message: notification.text, autoDismiss: 2 }))
      }
    }
  } finally {
    if (yield cancelled()) {
      channel.close()
    }
  }
}

function* notificationsManager() {
  while (true) {
    yield take(Types.START_LISTENING_NOTIFICATIONS)
    const listener = yield fork(notificationsListener)

    yield take(Types.STOP_LISTENING_NOTIFICATIONS)
    yield cancel(listener)
  }
}

export function* watcher() {
  yield all([
    takeEvery(Types.GET_NOTIFICATIONS, getNotifications),
    takeEvery(Types.READ_NOTIFICATIONS, readNotifications),
    spawn(notificationsManager)
  ])
}

import React, { CSSProperties } from 'react'
import classnames from 'classnames'
import injectStyles, { JSSProps } from 'react-jss'

import styles from './styles'
import { CircularProgress } from '@mui/material'

export interface OuterProps {
  color?: string
  background?: string
  size?: string
  margin?: string
  style?: CSSProperties
  className?: string
}
export interface Props extends OuterProps, JSSProps<typeof styles> { }

interface State {
  pastDelay: boolean
}

const Loading = ({ className, size, classes }) => {
  return (
    <div className={classnames(classes.loading, className)}>
      <CircularProgress size={size} />
    </div>
  )
}
const LoadingComponent = injectStyles<OuterProps>(styles)(Loading)


export default LoadingComponent

import { takeEvery, put, select, call, all } from 'redux-saga/effects'
import { AxiosError } from 'axios'

import fetchAPI from 'lib/services/fetchAPI'
import { tokenSelector } from 'lib/modules/user/selectors'
import { METHOD, ENDPOINT } from 'constants/api'
import { ReadFpd, readFpdLoading, readFpdFailure, readFpdSuccess } from './actions'
import types from './types'

function* readFpd(action: ReadFpd) {
  const { loadAll } = action.payload

  try {
    yield put(readFpdLoading(loadAll))
    const { data }: { data: Data.FPDLayer[] } = yield call(fetchAPI, {
      method: METHOD.GET,
      endpoint: ENDPOINT.FPD_LAYERS,
      params: {
        list: loadAll
      }
    })
    yield put(readFpdSuccess({ data, isList: action.payload.loadAll }))
  } catch (error) {
    console.error(error)

    yield put(readFpdFailure(loadAll))
  }
}

export function* watcher() {
  yield all([takeEvery(types.READ_FPD, readFpd)])
}

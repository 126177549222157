import { format } from 'date-fns'
import ruRu from 'date-fns/locale/ru'

export const DATE_FORMAT_TYPES = {
  dayMonthYear: 'dd.MM.yy',
  dayMonthYearSlash: 'dd/MM/yy',
  year: 'yyyy',
  monthYear: 'MMM yyyy',
  yearMonth: 'yyyy-MM',
  yearMonthDay: 'yyyy-MM-dd',
  yearMonthDayHoursMinutes: 'yyyy-MM-dd hh:MM',
  dayMonthNameYear: 'dd MMMM yyyy',
  dayMonthNameYearHourseMinutes: 'dd MMMM yyyy hh:mm',
  dayMonthNameYearHourseMinutesSeconds: 'dd MMMM yyyy hh:mm:ss',
  monthName: 'MMMM',
  hoursMinutesSeconds: 'hh:mm:ss',
}

export function formatDate({ date, formatType = DATE_FORMAT_TYPES.dayMonthYear }) {
  if (!date) return ''

  const mainFormaType = formatBackendDateType(formatType)

  let newValue = format(new Date(date), mainFormaType, { locale: ruRu })
  if (newValue) newValue = `${newValue.slice(0, 1).toUpperCase()}${newValue.slice(1)}`

  return newValue || ''
}

export const formatBackendDateType = (dateType) => {
  let finalType = dateType

  if (dateType.includes('YYYY')) finalType = finalType.replace('YYYY', 'yyyy')
  if (dateType.includes('DD')) finalType = finalType.replace('DD', 'dd')

  return finalType
}
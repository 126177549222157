import { Reducer } from 'redux'

import types from './actionTypes'
import { Actions } from './actions'

export interface State {
  chatOpened: boolean
  messages: Data.IncidentMessage[]
}

const intialState: State = {
  chatOpened: false,
  messages: []
}

export const reducer: Reducer<State, Actions> = (state = intialState, action) => {
  switch (action.type) {
    case types.SET_CHAT_OPENED:
      return { ...state, chatOpened: action.payload.chatOpened }
    case types.SET_HISTORY:
      return { ...state, messages: action.payload.messages }
    case types.ADD_TO_HISTORY:
      return { ...state, messages: [action.payload.message, ...state.messages] }
    default:
      return state
  }
}

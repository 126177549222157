// @ts-nocheck
import React, { Component, Fragment } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import { ColorResult, SketchPicker } from 'react-color'
import injectStyles, { JSSProps, withTheme } from 'react-jss'
import { MapDispatchToProps, MapStateToProps, connect } from 'react-redux'
import { compose } from 'recompose'

import {
  Aggregation as AggregationIcon,
  Cancel as CloseIcon,
  Date as DateIcon,
  Func as FuncIcon,
  Grouping as GroupingIcon,
  Hide,
  Normalize as NormalizeIcon,
  Rounding as RoundingIcon,
  String as TextIcon
} from 'components/Icons'
import { FORM, MATH_FUNCTION, OPERATORS, SORTING } from 'constants/aggregation'
import { CHART_TYPES } from 'constants/dataView'
import { OPTION_TYPES } from 'constants/filter'
import { changeTargetMenu, closeMenu } from 'lib/modules/aggregations/actions'
import AggregationService from 'lib/services/aggregation'
import uniqueID from 'lib/utils/uniqueID'
import DateFormatModal from './components/DateFormatModal'
import FontSizeModal from './components/FontSizeModal'
import RoundingModal from './components/RoundingModal'
import styles from './styles'

export interface OuterProps {
  elementType: Data.Chart['element_type'] | undefined
  change: (fieldName: string, fieldValue: any) => void
  settings?: any
  onChangeAggregation: (
    aggregation: Data.AggregationEntity,
    newOptions: Partial<Data.AggregationEntity>
  ) => void
  onCreateCorrelation: (
    targetAggregation: Data.Aggregation<Data.AggregationMeasure>,
    sourceItem: Data.Measure | Data.Aggregation<Data.AggregationMeasure>
  ) => void
  onRemoveAggregation: (aggregation: Data.AggregationEntity) => void
  onClearAggregationSorting: () => void
  onCreateGroup: (group: Data.AggregationGroup) => void
  onChangeGroup: (group: Data.AggregationGroup) => void
  onRemoveGroup: (group: Data.AggregationGroup) => void
}
interface StateProps {
  menu: App.State['aggregations']['menu']
}
interface DispatchProps {
  changeTargetMenu: (
    target: Data.AggregationEntity | Data.AggregationGroup,
    type: 'aggregation' | 'group'
  ) => void
  closeMenu: () => void
}
interface Props extends JSSProps<typeof styles>, StateProps, DispatchProps, OuterProps {}
interface State {
  menu: Partial<App.State['aggregations']['menu']> | undefined
  dateFormatDialogOpen: boolean
  roundingDialogOpen: boolean
  fontSizeDialogOpen: boolean
}

const DEFAULT_DATE_REG_EXP = '%Y-%m'
enum GROUP_TYPES {
  DEFAULT,
  OUTRUNNING,
  LAGGING
}

class AggregationMenu extends Component<Props, State> {
  timer: NodeJS.Timer
  state: State = {
    menu: {
      anchor: undefined,
      targetAggregation: undefined
    },
    dateFormatDialogOpen: false,
    roundingDialogOpen: false,
    fontSizeDialogOpen: false
  }

  componentWillUnmount() {
    const { menu, closeMenu } = this.props

    if (menu.anchor) {
      closeMenu()
    }
  }

  isDatetimeOption = (props = this.props) => {
    const {
      menu: { targetAggregation }
    } = props

    return (
      !!targetAggregation &&
      targetAggregation.args &&
      targetAggregation.args[0].filter_type === OPTION_TYPES.TIME
    )
  }

  get elementTypeIsTable() {
    const { elementType } = this.props

    return !!elementType && elementType === CHART_TYPES.TABLE
  }

  get elementTypeIsMap() {
    const { elementType } = this.props

    return !!elementType && elementType === CHART_TYPES.MAP_CHART
  }

  get elementTypeIsScanner() {
    const { elementType } = this.props

    return !!elementType && elementType === CHART_TYPES.SCANNER
  }

  get elementTypeIsSankey() {
    const { elementType } = this.props

    return !!elementType && elementType === CHART_TYPES.SANKEY
  }

  get targetColor() {
    const {
      menu: { targetAggregation, targetGroup },
      theme
    } = this.props

    let color = theme.colors.text.secondary

    if (targetAggregation && targetAggregation.color) {
      color = targetAggregation.color
    } else if (targetGroup && targetGroup.color) {
      color = targetGroup.color
    }

    return color
  }

  get targetFontColor() {
    const {
      menu: { targetAggregation, targetGroup },
      theme
    } = this.props

    let color = theme.colors.white

    if (targetAggregation && targetAggregation.fontColor) {
      color = targetAggregation.fontColor
    } else if (targetGroup && targetGroup.fontColor) {
      color = targetGroup.fontColor
    }

    return color
  }

  get renderColorPickerSubMenu() {
    const { classes } = this.props

    return (
      <div className={classes.colorPickerTooltip}>
        <div className={classes.colorPickerRow}>
          <SketchPicker color={this.targetColor} onChangeComplete={this.onChangeColor} />
        </div>
        <div className={classes.colorPickerRow}>
          <button className={classes.colorPickerButton} type="button" onClick={this.onResetColor}>
            По умолчанию
          </button>
        </div>
      </div>
    )
  }

  get renderFontColorPickerSubMenu() {
    const { classes } = this.props

    return (
      <div className={classes.colorPickerTooltip}>
        <div className={classes.colorPickerRow}>
          <SketchPicker color={this.targetFontColor} onChangeComplete={this.onChangeFontColor} />
        </div>
        <div className={classes.colorPickerRow}>
          <button
            className={classes.colorPickerButton}
            type="button"
            onClick={this.onResetFontColor}
          >
            По умолчанию
          </button>
        </div>
      </div>
    )
  }

  get renderAggregationSubMenu() {
    const {
      menu: { targetAggregation, sourceItem, sourceItemIsAggregation },
      classes
    } = this.props

    if (!targetAggregation) {
      return null
    }

    const withDimension = AggregationService.withDimension(targetAggregation.agg)

    let types = []

    if (withDimension) {
      types = AggregationService.withDimensionTypes
    } else if (sourceItem) {
      types = [FORM.CORR]
    } else {
      types = AggregationService.withMeasureTypes.filter(form => form !== FORM.CORR)
    }

    return (
      <List className={classes.container}>
        {types.map(type => (
          <ListItem
            key={type}
            className={classes.menuItem}
            classes={{
              gutters: classes.menuItemGutters
            }}
            button
            onClick={this.onSelectAggregation(type)}
          >
            <ListItemText classes={{ primary: classes.tooltipItemText }}>
              {AggregationService.getAggregationName(type)}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    )
  }

  get renderMathFunctionSubMenu() {
    const {
      elementType,
      menu: { targetAggregation },
      classes
    } = this.props

    if (!targetAggregation || AggregationService.isDimension(targetAggregation.agg)) {
      return null
    }

    const mathFunctions = AggregationService.getMathFunctionsByElementType(elementType)

    return (
      <List className={classes.container}>
        {mathFunctions.map(type => (
          <ListItem
            key={type}
            className={classes.menuItem}
            classes={{
              gutters: classes.menuItemGutters
            }}
            button
            onClick={this.onSelectMathFunction(type)}
          >
            <ListItemText classes={{ primary: classes.tooltipItemText }}>
              {AggregationService.getMathFunctionName(type)}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    )
  }

  get renderSortingSubMenu() {
    const {
      elementType,
      menu: { targetAggregation },
      classes
    } = this.props

    if (!targetAggregation || AggregationService.isDimension(targetAggregation.agg)) {
      return null
    }

    return (
      <List className={classes.container}>
        {Object.values(SORTING).map(sorting => (
          <ListItem
            key={sorting}
            className={classes.menuItem}
            classes={{
              gutters: classes.menuItemGutters
            }}
            button
            onClick={this.onSelectSorting(sorting)}
          >
            <ListItemText classes={{ primary: classes.tooltipItemText }}>
              {AggregationService.getSortingName(sorting)}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    )
  }

  get renderGroupingSubMenu() {
    const {
      menu: { targetAggregation, sourceItem, sourceItemIsAggregation },
      classes
    } = this.props

    const targetIsDimensionAggregation = AggregationService.isDimension(targetAggregation.agg)

    if (!targetIsDimensionAggregation && sourceItem && sourceItemIsAggregation) {
      const items = [
        {
          type: GROUP_TYPES.DEFAULT,
          name: 'Базовая'
        },
        {
          type: GROUP_TYPES.OUTRUNNING,
          name: 'С опережением'
        },
        {
          type: GROUP_TYPES.LAGGING,
          name: 'С запаздыванием'
        }
      ]

      return (
        <List className={classes.container}>
          {items.map(({ type, name }) => (
            <ListItem
              key={type}
              className={classes.menuItem}
              classes={{
                gutters: classes.menuItemGutters
              }}
              button
              onClick={this.onCreateGroup(type)}
            >
              <ListItemText classes={{ primary: classes.tooltipItemText }}>{name}</ListItemText>
            </ListItem>
          ))}
        </List>
      )
    }

    return null
  }

  get renderDateFormattingDialog() {
    const {
      menu: { targetAggregation }
    } = this.props

    if (!targetAggregation || !AggregationService.isDimension(targetAggregation.agg)) {
      return null
    }

    return (
      <DateFormatModal
        open={this.state.dateFormatDialogOpen}
        dateFormatValue={targetAggregation.args[0].date_reg_exp}
        onChange={this.onChangeDateFormatting}
        onClose={() => this.setState({ dateFormatDialogOpen: false })}
      />
    )
  }

  get renderRoundingDialog() {
    const {
      menu: { targetAggregation, targetGroup }
    } = this.props

    if (
      !targetGroup &&
      (!targetAggregation || AggregationService.isDimension(targetAggregation.agg))
    ) {
      return null
    }

    return (
      <RoundingModal
        open={this.state.roundingDialogOpen}
        roundingValue={targetAggregation ? targetAggregation.rounding : targetGroup.rounding}
        onChange={this.onChangeRounding}
        onClose={() => this.setState({ roundingDialogOpen: false })}
      />
    )
  }

  get renderFontSizeDialog() {
    const {
      menu: { targetAggregation, targetGroup }
    } = this.props

    if (
      !targetGroup &&
      (!targetAggregation || AggregationService.isDimension(targetAggregation.agg)) &&
      !this.elementTypeIsMap
    ) {
      return null
    }

    return (
      <FontSizeModal
        open={this.state.fontSizeDialogOpen}
        fontSizeValue={
          targetAggregation ? targetAggregation.fontSize : targetGroup ? targetGroup.fontSize : 12
        }
        onChange={this.onChangeFontSize}
        onClose={() => this.setState({ fontSizeDialogOpen: false })}
      />
    )
  }

  get renderGroupingMenuItem() {
    const {
      menu: { targetAggregation, sourceItem, sourceItemIsAggregation },
      classes
    } = this.props

    if (!targetAggregation) {
      return null
    }

    const targetIsDimensionAggregation = AggregationService.isDimension(targetAggregation.agg)

    if (!targetIsDimensionAggregation && sourceItem && sourceItemIsAggregation) {
      return (
        <Tooltip
          classes={{ tooltip: classes.tooltipContainer }}
          PopperProps={{
            style: {
              opacity: 1
            }
          }}
          placement="right-start"
          title={this.renderGroupingSubMenu}
          disableFocusListener
          disableTouchListener
          interactive='true'
        >
          <MenuItem className={classes.menuItem}>
            <ListItemIcon>
              <GroupingIcon className={classes.menuItemIcon} />
            </ListItemIcon>
            Создать группу
          </MenuItem>
        </Tooltip>
      )
    }

    return null
  }

  get renderAddToGroupMenuItem() {
    const {
      menu: { targetGroup, sourceItem, sourceItemIsAggregation },
      classes
    } = this.props

    if (!targetGroup || !sourceItem || !sourceItemIsAggregation) {
      return null
    }

    return (
      <MenuItem className={classes.menuItem} button onClick={this.onAddToGroup}>
        <ListItemIcon>
          <GroupingIcon className={classes.menuItemIcon} />
        </ListItemIcon>
        Добавить в группу
      </MenuItem>
    )
  }

  get renderColorMenuItem() {
    const {
      menu: { targetAggregation, targetGroup },
      classes
    } = this.props

    if (
      (!targetGroup &&
        (!targetAggregation || AggregationService.isDimension(targetAggregation.agg)) &&
        !this.elementTypeIsMap) ||
      this.elementTypeIsTable
    ) {
      return null
    }

    return (
      <Tooltip
        classes={{ tooltip: classes.tooltipContainer }}
        PopperProps={{
          style: {
            opacity: 1
          }
        }}
        placement="right-start"
        title={this.renderColorPickerSubMenu}
        disableFocusListener
        disableTouchListener
        interactive='true'
      >
        <MenuItem className={classes.menuItem}>
          <ListItemIcon>
            <div
              className={classes.menuColorItemIcon}
              style={{ backgroundColor: this.targetColor }}
            />
          </ListItemIcon>
          Цвет
        </MenuItem>
      </Tooltip>
    )
  }

  get renderFontColorMenuItem() {
    const {
      menu: { targetAggregation, targetGroup },
      classes
    } = this.props

    if (
      !targetAggregation ||
      !this.elementTypeIsMap ||
      (AggregationService.isDimension(targetAggregation.agg) &&
        AggregationService.isPolygon(targetAggregation.args))
    ) {
      return null
    }

    return (
      <Tooltip
        classes={{ tooltip: classes.tooltipContainer }}
        PopperProps={{
          style: {
            opacity: 1
          }
        }}
        placement="right-start"
        title={this.renderFontColorPickerSubMenu}
        disableFocusListener
        disableTouchListener
        interactive='true'
      >
        <MenuItem className={classes.menuItem}>
          <ListItemIcon>
            <div
              className={classes.menuColorItemIcon}
              style={{ backgroundColor: this.targetFontColor }}
            />
          </ListItemIcon>
          Цвет шрифта
        </MenuItem>
      </Tooltip>
    )
  }

  get renderTitleMenuItem() {
    const {
      menu: { targetGroup },
      classes
    } = this.props

    if (!targetGroup) return null

    return (
      <Tooltip
        classes={{ tooltip: classes.tooltipContainer }}
        PopperProps={{
          style: {
            opacity: 1
          }
        }}
        placement="right-start"
        title={null}
        disableFocusListener
        disableTouchListener
        interactive='true'
      >
        <MenuItem className={classes.menuItem}>
          <ListItemIcon>
            <TextIcon className={classes.menuItemIcon} />
          </ListItemIcon>
          <TextField
            className={classes.tooltipItemText}
            name="title"
            type="text"
            value={targetGroup.title || ''}
            onChange={e => this.onChangeTitle(e.target.value)}
            label="Название"
          />
        </MenuItem>
      </Tooltip>
    )
  }

  get renderShowAsTitleMenuItem() {
    const {
      menu: { targetAggregation, targetGroup },
      classes
    } = this.props

    if (
      (!targetGroup &&
        (!targetAggregation ||
          (AggregationService.isDimension(targetAggregation.agg) &&
            ![OPTION_TYPES.GEO_TITLE, OPTION_TYPES.GEO_POLY].includes(
              targetAggregation.args[0].filter_type
            )))) ||
      !this.elementTypeIsMap
    ) {
      return null
    }

    return (
      <MenuItem className={classes.menuItem} onClick={this.onToggleShowAsTitle}>
        <ListItemIcon>
          <TextIcon className={classes.menuItemIcon} />
        </ListItemIcon>
        Отображать на карте&nbsp;
        <b>
          (
          {!!targetAggregation
            ? targetAggregation.as_title
              ? 'ДА'
              : 'НЕТ'
            : targetGroup.as_title
            ? 'ДА'
            : 'НЕТ'}
          )
        </b>
      </MenuItem>
    )
  }

  get renderAggregationMenuItem() {
    const {
      menu: { targetAggregation },
      classes
    } = this.props

    if (!targetAggregation || targetAggregation.agg === FORM.CORR || this.elementTypeIsScanner) {
      return null
    }

    return (
      <Tooltip
        classes={{ tooltip: classes.tooltipContainer }}
        PopperProps={{
          style: {
            opacity: 1
          }
        }}
        placement="right-start"
        title={this.renderAggregationSubMenu}
        disableFocusListener
        disableTouchListener
        interactive='true'
      >
        <MenuItem className={classes.menuItem}>
          <ListItemIcon>
            <AggregationIcon className={classes.menuItemIcon} />
          </ListItemIcon>
          Агрегация&nbsp;
          <b>({AggregationService.getAggregationSymbol(targetAggregation.agg)})</b>
        </MenuItem>
      </Tooltip>
    )
  }

  get renderMathFunctionMenuItem() {
    const {
      menu: { targetAggregation },
      classes
    } = this.props

    if (
      !targetAggregation ||
      AggregationService.isDimension(targetAggregation.agg) ||
      this.elementTypeIsScanner
    ) {
      return null
    }

    return (
      <Tooltip
        classes={{ tooltip: classes.tooltipContainer }}
        PopperProps={{
          style: {
            opacity: 1
          }
        }}
        placement="right-start"
        title={this.renderMathFunctionSubMenu}
        disableFocusListener
        disableTouchListener
        interactive='true'
      >
        <MenuItem className={classes.menuItem}>
          <ListItemIcon>
            <FuncIcon className={classes.menuItemIcon} />
          </ListItemIcon>
          Мат. функция&nbsp;
          <b>({AggregationService.getMathFunctionSymbol(targetAggregation.math_func)})</b>
        </MenuItem>
      </Tooltip>
    )
  }

  get renderSortingMenuItem() {
    const {
      menu: { targetAggregation },
      classes
    } = this.props

    if (
      !targetAggregation ||
      AggregationService.isDimension(targetAggregation.agg) ||
      this.elementTypeIsScanner
    ) {
      return null
    }

    return (
      <Tooltip
        classes={{ tooltip: classes.tooltipContainer }}
        PopperProps={{
          style: {
            opacity: 1
          }
        }}
        placement="right-start"
        title={this.renderSortingSubMenu}
        disableFocusListener
        disableTouchListener
        interactive='true'
      >
        <MenuItem className={classes.menuItem}>
          <ListItemIcon>
            <FuncIcon className={classes.menuItemIcon} />
          </ListItemIcon>
          Сортировка&nbsp;
          <b>({AggregationService.getSortingSymbol(targetAggregation.sorting)})</b>
        </MenuItem>
      </Tooltip>
    )
  }

  get renderNormalizationMenuItem() {
    const {
      menu: { targetAggregation },
      classes
    } = this.props

    if (
      !targetAggregation ||
      AggregationService.isDimension(targetAggregation.agg) ||
      this.elementTypeIsTable ||
      this.elementTypeIsScanner
    ) {
      return null
    }

    return (
      <MenuItem className={classes.menuItem} onClick={this.onToggleNormalization}>
        <ListItemIcon>
          <NormalizeIcon className={classes.menuItemIcon} />
        </ListItemIcon>
        Нормализация&nbsp;
        <b>({targetAggregation.normalize ? 'ВКЛ' : 'ВЫКЛ'})</b>
      </MenuItem>
    )
  }

  get renderDateFormattingMenuItem() {
    const { classes } = this.props

    if (!this.isDatetimeOption() || this.elementTypeIsScanner) {
      return null
    }

    return (
      <MenuItem
        className={classes.menuItem}
        onClick={() => this.setState({ dateFormatDialogOpen: true })}
      >
        <ListItemIcon>
          <DateIcon className={classes.menuItemIcon} />
        </ListItemIcon>
        Формат времени
      </MenuItem>
    )
  }

  get renderRoundingMenuItem() {
    const {
      menu: { targetAggregation, targetGroup },
      classes
    } = this.props

    if (
      (!targetGroup &&
        (!targetAggregation || AggregationService.isDimension(targetAggregation.agg))) ||
      this.elementTypeIsScanner
    ) {
      return null
    }

    return (
      <MenuItem
        className={classes.menuItem}
        onClick={() => this.setState({ roundingDialogOpen: true })}
      >
        <ListItemIcon>
          <RoundingIcon className={classes.menuItemIcon} />
        </ListItemIcon>
        Округление
      </MenuItem>
    )
  }
  
  get renderHideMenuItem() {
    const {
      menu: { targetAggregation, targetGroup },
      change,
      settings,
      classes
    } = this.props
    const hasHiddenArray = settings && Array.isArray(settings.hiddenDataNames)

    const currentDataName = targetAggregation && Array.isArray(targetAggregation.args) && targetAggregation.args[0].name
    const isHiddenData = hasHiddenArray && settings.hiddenDataNames.find(itemName => itemName === currentDataName)

    return (
      <MenuItem
        className={classes.menuItem}
        onClick={() => {
          if (hasHiddenArray && isHiddenData) change('settings.hiddenDataNames', settings.hiddenDataNames.filter(itemName => itemName !== currentDataName))
          if (hasHiddenArray && !isHiddenData) change('settings.hiddenDataNames', [...settings.hiddenDataNames, currentDataName])
          if (!hasHiddenArray && !isHiddenData) change('settings.hiddenDataNames', [currentDataName])
        }}
      >
        <ListItemIcon>
          <Hide className={classes.menuItemIcon} />
        </ListItemIcon>
        {isHiddenData ? 'Показать' : 'Скрыть'} данные
      </MenuItem>
    )
  }

  get renderFontSizeMenuItem() {
    const {
      menu: { targetAggregation, targetGroup },
      classes
    } = this.props

    if (
      !targetAggregation ||
      !this.elementTypeIsMap ||
      (AggregationService.isDimension(targetAggregation.agg) &&
        targetAggregation.args[0].filter_type !== OPTION_TYPES.GEO_TITLE)
    ) {
      return null
    }

    return (
      <MenuItem
        className={classes.menuItem}
        onClick={() => this.setState({ fontSizeDialogOpen: true })}
      >
        <ListItemIcon>
          <RoundingIcon className={classes.menuItemIcon} />
        </ListItemIcon>
        Размер шрифта
      </MenuItem>
    )
  }

  get renderDeleteAggregationMenuItem() {
    const {
      menu: { targetAggregation },
      classes
    } = this.props

    if (!targetAggregation) {
      return null
    }

    return (
      <MenuItem className={classes.menuItem} button onClick={this.onRemoveAggregation}>
        <ListItemIcon>
          <CloseIcon className={classes.menuItemIcon} />
        </ListItemIcon>
        Удалить
      </MenuItem>
    )
  }

  get renderDeleteGroupMenuItem() {
    const {
      menu: { targetGroup },
      classes
    } = this.props

    if (!targetGroup) {
      return null
    }

    return (
      <MenuItem className={classes.menuItem} button onClick={this.onRemoveGroup}>
        <ListItemIcon>
          <CloseIcon className={classes.menuItemIcon} />
        </ListItemIcon>
        Разбить группу
      </MenuItem>
    )
  }

  onSelectAggregation = (form: FORM) => () => {
    const {
      menu: { targetAggregation, sourceItem },
      onChangeAggregation,
      onCreateCorrelation
    } = this.props

    this.onCloseMenu()

    if (form === FORM.CORR) {
      onCreateCorrelation(
        targetAggregation as Data.Aggregation<Data.AggregationMeasure>,
        sourceItem as Data.Measure | Data.Aggregation<Data.AggregationMeasure>
      )
    } else {
      onChangeAggregation(targetAggregation, { agg: form })
    }
  }

  onSelectMathFunction = (math_func: MATH_FUNCTION) => () => {
    const {
      menu: { targetAggregation },
      onChangeAggregation
    } = this.props

    this.onCloseMenu()

    const options: Partial<Data.AggregationEntity> = { math_func }

    // Turn on or off the normalization depending on the type of math function
    if (math_func === MATH_FUNCTION.EXPONENT) {
      options.normalize = true
    } else if (
      math_func === MATH_FUNCTION.NONE &&
      targetAggregation.math_func === MATH_FUNCTION.EXPONENT
    ) {
      options.normalize = false
    }

    onChangeAggregation(targetAggregation, options)
  }

  onSelectSorting = (sorting: SORTING) => () => {
    const {
      menu: { targetAggregation },
      onChangeAggregation,
      onClearAggregationSorting,
      settings,
      change,
    } = this.props

    this.onCloseMenu()

    const options: Partial<Data.AggregationEntity> = { sorting }

    onClearAggregationSorting()

    const currentDataName = targetAggregation && Array.isArray(targetAggregation.args) && targetAggregation.args[0].name

    const hasSortArray = settings && Array.isArray(settings.sortDataNames)

    if (hasSortArray) {
      if (settings.sortDataNames.some(item => item.name === currentDataName)) {
        change('settings.sortDataNames', settings.sortDataNames.filter(item => item.name !== currentDataName))
      }
      else change('settings.sortDataNames', [...settings.sortDataNames, { name: currentDataName, sort: sorting }])
    } else {
      change('settings.sortDataNames', [{ name: currentDataName, sort: sorting }])
    }

    onChangeAggregation(targetAggregation, options)
  }

  onRemoveAggregation = () => {
    const {
      menu: { targetAggregation },
      onRemoveAggregation
    } = this.props

    this.onCloseMenu()

    onRemoveAggregation(targetAggregation)
  }

  onCreateGroup = (type: GROUP_TYPES) => () => {
    const {
      menu: { targetAggregation, sourceItem },
      onCreateGroup
    } = this.props
    const sourceAggregation = sourceItem as Data.Aggregation<Data.AggregationMeasure>
    const targetConfig = {
      factor: 1,
      operator: type === GROUP_TYPES.LAGGING ? OPERATORS.MINUS.sign : OPERATORS.PLUS.sign
    }
    const sourceConfig = {
      factor: 1,
      operator: type === GROUP_TYPES.OUTRUNNING ? OPERATORS.MINUS.sign : OPERATORS.PLUS.sign
    }
    const group = {
      id: uniqueID('aggregation-group-'),
      place: targetAggregation.place,
      rounding: 2,
      aggregations: [
        {
          id: targetAggregation.id,
          name: AggregationService.getFullName(targetAggregation),
          ...targetConfig
        },
        {
          id: sourceAggregation.id,
          name: AggregationService.getFullName(sourceAggregation),
          ...sourceConfig
        }
      ]
    }

    this.onCloseMenu()

    onCreateGroup(group)
  }

  onAddToGroup = () => {
    const {
      menu: { targetGroup, sourceItem },
      onChangeGroup
    } = this.props
    const sourceAggregation = sourceItem as Data.Aggregation<Data.AggregationMeasure>

    this.onCloseMenu()

    onChangeGroup({
      ...targetGroup,
      aggregations: [
        ...targetGroup.aggregations,
        {
          id: sourceAggregation.id,
          name: AggregationService.getFullName(sourceAggregation),
          factor: 1,
          operator: OPERATORS.PLUS.sign
        }
      ]
    })
  }

  onRemoveGroup = () => {
    const {
      menu: { targetGroup },
      onRemoveGroup
    } = this.props

    this.onCloseMenu()

    onRemoveGroup(targetGroup)
  }

  onCloseMenu = () => {
    this.props.closeMenu()
  }

  onChangeTitle = (title?: string) => {
    const {
      menu: { targetGroup },
      onChangeGroup,
      changeTargetMenu
    } = this.props
    if (targetGroup) {
      const group = {
        ...targetGroup,
        title
      }
      onChangeGroup(group)
      changeTargetMenu(group, 'group')
    }
  }

  onChangeColor = (colorObject?: ColorResult) => {
    const {
      menu: { targetAggregation, targetGroup },
      onChangeAggregation,
      onChangeGroup,
      changeTargetMenu
    } = this.props
    const color = colorObject ? colorObject.hex : undefined

    if (targetAggregation) {
      onChangeAggregation(targetAggregation, { color })
      changeTargetMenu(
        {
          ...targetAggregation,
          color
        },
        'aggregation'
      )
    } else if (targetGroup) {
      const group = {
        ...targetGroup,
        color
      }
      onChangeGroup(group)
      changeTargetMenu(group, 'group')
    }
  }

  onChangeFontColor = (colorObject?: ColorResult) => {
    const {
      menu: { targetAggregation, targetGroup },
      onChangeAggregation,
      onChangeGroup,
      changeTargetMenu
    } = this.props
    const fontColor = colorObject ? colorObject.hex : undefined

    if (targetAggregation) {
      onChangeAggregation(targetAggregation, { fontColor })
      changeTargetMenu(
        {
          ...targetAggregation,
          fontColor
        },
        'aggregation'
      )
    } else if (targetGroup) {
      const group = {
        ...targetGroup,
        fontColor
      }
      onChangeGroup(group)
      changeTargetMenu(group, 'group')
    }
  }

  onResetColor = () => this.onChangeColor()

  onResetFontColor = () => this.onChangeFontColor()

  onToggleNormalization = () => {
    const {
      menu: { targetAggregation },
      onChangeAggregation,
      changeTargetMenu
    } = this.props

    if (targetAggregation && targetAggregation.math_func !== MATH_FUNCTION.EXPONENT) {
      const normalize = !targetAggregation.normalize

      onChangeAggregation(targetAggregation, { normalize })
      changeTargetMenu(
        {
          ...targetAggregation,
          normalize
        },
        'aggregation'
      )
    }
  }

  onToggleShowAsTitle = () => {
    const {
      menu: { targetAggregation, targetGroup },
      onChangeAggregation,
      onChangeGroup,
      changeTargetMenu
    } = this.props

    if (targetAggregation) {
      const as_title = !targetAggregation.as_title

      onChangeAggregation(targetAggregation, { as_title })
      changeTargetMenu(
        {
          ...targetAggregation,
          as_title
        },
        'aggregation'
      )
    } else if (targetGroup) {
      const as_title = !targetGroup.as_title
      const group = {
        ...targetGroup,
        as_title
      }
      onChangeGroup(group)
      changeTargetMenu(group, 'group')
    }
  }

  onChangeDateFormatting = (value: string) => {
    const {
      menu: { targetAggregation },
      onChangeAggregation
    } = this.props

    const args = targetAggregation.args.map(item => ({ ...item, date_reg_exp: value }))

    onChangeAggregation(targetAggregation, {
      args
    })
    changeTargetMenu(
      {
        ...targetAggregation,
        args
      },
      'aggregation'
    )
  }

  onChangeRounding = (value: any) => {
    const {
      menu: { targetAggregation, targetGroup },
      onChangeAggregation,
      onChangeGroup
    } = this.props

    if (targetAggregation) {
      onChangeAggregation(targetAggregation, {
        rounding: typeof value === 'number' ? value : 0
      })
      changeTargetMenu(
        {
          ...targetAggregation,
          rounding: typeof value === 'number' ? value : 0
        },
        'aggregation'
      )
    } else if (targetGroup) {
      const group = {
        ...targetGroup,
        rounding: typeof value === 'number' ? value : 0
      }
      onChangeGroup(group)
      changeTargetMenu(group, 'group')
    }
  }

  onChangeFontSize = (value: any) => {
    const {
      menu: { targetAggregation, targetGroup },
      onChangeAggregation,
      onChangeGroup
    } = this.props

    if (targetAggregation) {
      onChangeAggregation(targetAggregation, {
        fontSize: typeof value === 'number' ? value : 0
      })
      changeTargetMenu(
        {
          ...targetAggregation,
          fontSize: typeof value === 'number' ? value : 0
        },
        'aggregation'
      )
    } else if (targetGroup) {
      const group = {
        ...targetGroup,
        fontSize: typeof value === 'number' ? value : 0
      }
      onChangeGroup(group)
      changeTargetMenu(group, 'group')
    }
  }

  render() {
    const { classes, menu, closeMenu } = this.props
    const isOpen = !!menu.anchor

    return (
      <Fragment>
        <Menu
          MenuListProps={{
            className: classes.container
          }}
          anchorEl={isOpen ? menu.anchor : undefined}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={isOpen}
          getContentAnchorEl={undefined}
          onClose={closeMenu}
        >
          {this.elementTypeIsSankey && this.renderDeleteAggregationMenuItem}

          {!this.elementTypeIsSankey && [
            this.renderGroupingMenuItem,
            this.renderAddToGroupMenuItem,
            this.renderColorMenuItem,
            this.renderFontColorMenuItem,
            this.renderFontSizeMenuItem,
            this.renderTitleMenuItem,
            this.renderShowAsTitleMenuItem,
            this.renderAggregationMenuItem,
            this.renderMathFunctionMenuItem,
            this.renderSortingMenuItem,
            this.renderNormalizationMenuItem,
            this.renderDateFormattingMenuItem,
            this.renderRoundingMenuItem,
            this.renderHideMenuItem,
            this.renderDeleteAggregationMenuItem,
            this.renderDeleteGroupMenuItem
          ]}
        </Menu>
        {this.renderDateFormattingDialog}
        {this.renderRoundingDialog}
        {this.renderFontSizeDialog}
      </Fragment>
    )
  }
}

const mapStateToProps: MapStateToProps<StateProps, Props, App.State> = (state, props) => ({
  menu: state.aggregations.menu
})

const mapDispatchToProps: MapDispatchToProps<DispatchProps, Props> = dispatch => ({
  closeMenu: () => dispatch(closeMenu()),
  changeTargetMenu: (target, type) => dispatch(changeTargetMenu(target, type))
})

export default compose<Props, OuterProps>(
  withTheme,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectStyles(styles)
)(AggregationMenu)

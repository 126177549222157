import {
  CHART_RATING,
  FORECASTING,
  TABLE_STYLES,
  VALUES_FONT_SIZES,
  WIDGET_FONT_SIZES
} from 'constants/dataView'
import { DATE_FORMAT_TYPES } from '../utils/dateHelper'

export const ratingOptions = Object.keys(CHART_RATING).map(key => ({
  value: key,
  label: CHART_RATING[key].name
}))

export const forecastingOptions = Object.keys(FORECASTING).map(key => ({
  value: key.toLowerCase(),
  label: FORECASTING[key].name
}))

export const makeOptionsWithSign = (size: number, sign = '') => ({
  value: `${size}${sign}`,
  label: `${size}${sign}`
})

export const makePxOptions = (size: number) => makeOptionsWithSign(size, 'px')

export const makePercentOptions = (size: number) => makeOptionsWithSign(size, '%')

export const valuesFontSizesOptions = VALUES_FONT_SIZES.map(makePxOptions)

export const widgetFontSizesOptions = WIDGET_FONT_SIZES.map(makePxOptions)

export const tableFontSizesOptions = TABLE_STYLES.fontSizes.map(makePxOptions)

export const tablePaddingsOptions = TABLE_STYLES.paddings.map(makePxOptions)

export const dateFormatOptions = [
  {
    value: DATE_FORMAT_TYPES.year,
    label: 'ГГГГ'
  },
  {
    value: DATE_FORMAT_TYPES.yearMonth,
    label: 'ГГГГ-ММ'
  },
  {
    value: DATE_FORMAT_TYPES.yearMonthDay,
    label: 'ГГГГ-ММ-ДД'
  }
]

export const alignOptions = [
  {
    value: 'left',
    label: 'Слева'
  },
  {
    value: 'center',
    label: 'По центру'
  },
  {
    value: 'right',
    label: 'Справа'
  },
  {
    value: 'justify',
    label: 'По ширине'
  }
]

export const fontsOptions = [
  {
    value: 'Open Sans, sans-serif',
    label: 'Open Sans'
  },
  {
    value: 'Roboto, sans-serif',
    label: 'Roboto'
  },
  {
    value: 'Georgia, serif',
    label: 'Georgia'
  },
  {
    value: 'Helvetica',
    label: 'Helvetica'
  }
]

export const importFilePeriodOptions = [
  {
    value: '10',
    label: '10 мин.'
  },
  {
    value: '15',
    label: '15 мин.'
  },
  {
    value: '20',
    label: '20 мин.'
  },
  {
    value: '30',
    label: '30 мин.'
  },
  {
    value: '45',
    label: '45 мин.'
  },
  {
    value: '60',
    label: '1 ч.'
  },
  {
    value: '120',
    label: '2 ч.'
  }
]

import { Action } from 'redux'

import { MODALS_NAMES } from 'constants/modals'
import { Types } from './actionTypes'

interface OpenModal extends Action<Types.OPEN_MODAL> {
  payload: {
    itemId: string | number
  }
  meta: {
    name: MODALS_NAMES
  }
}
interface CloseModal extends Action<Types.CLOSE_MODAL> {
  meta: {
    name: MODALS_NAMES
  }
}

export type Actions = OpenModal | CloseModal

export const openModal = (name: MODALS_NAMES, itemId: string | number) => ({
  type: Types.OPEN_MODAL,
  payload: { itemId },
  meta: { name }
})

export const closeModal: (name: MODALS_NAMES) => CloseModal = name => ({
  type: Types.CLOSE_MODAL,
  meta: { name }
})

import { OuterProps as Props } from './index'

const FONT_SIZE = '0.625rem'

const styles = (theme: App.Theme) => ({
  tag: {
    ...theme.utils.flexContainer({ direction: 'row', alignItems: 'center' }),
    padding: '0 0.5rem',
    fontFamily: 'Helvetica',
    fontSize: FONT_SIZE,
    fontWeight: 'bold',
    lineHeight: '1.25rem',
    color: (props: Props) => props.color,
    backgroundColor: (props: Props) => theme.utils.opacify(props.color, 0.1),
    borderRadius: 8,
    boxSizing: 'border-box'
  },
  icon: {
    height: '0.75rem',
    width: '0.75rem',
    margin: (props: Props) => (props.iconPosition === 'right' ? '0 0 0.5rem 0' : '0 0.5rem 0 0')
  }
})

export default styles

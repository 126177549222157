import React, { PureComponent, InputHTMLAttributes } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import { compose } from 'recompose'
import { WrappedFieldProps } from 'redux-form'
import InputMask from 'react-input-mask'
import cn from 'classnames'

import styles from './styles'

interface OuterProps extends InputHTMLAttributes<HTMLInputElement> {
  mask: string
  // Fix strange ts error
  component?: any
}
interface Props extends JSSProps<typeof styles>, WrappedFieldProps, OuterProps {}

class TimePickerField extends PureComponent<Props> {
  render() {
    const {
      classes,
      className,
      mask,
      input: { onBlur, onChange, onFocus, value },
      meta: { error, touched },
      capture,
      ...props
    } = this.props

    return (
      <InputMask
        className={cn(classes.input, { [classes.error]: touched && error }, className)}
        mask={mask}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
        capture={!!capture}
        {...props}
      />
    )
  }
}

export default compose<Props, OuterProps>(injectStyles(styles))(TimePickerField)

const styles = (theme: App.Theme) => ({
  container: {
    position: 'relative',
    width: '2rem',
    height: '2rem',
    padding: '0.25rem',
    backgroundColor: '#FFFFFF',
    border: `1px solid ${theme.colors.grey}`,
    borderRadius: '50%',
    boxSizing: 'border-box',
    flexShrink: 0,
    zIndex: 1,
  },
  value: {
    width: '100%',
    height: '100%',
    cursor: 'pointer'
  },
  tooltip: {
    padding: '0 !important',
    opacity: '1 !important',
    filter: 'drop-shadow(0 0 3px rgba(0,0,0,0.15))',
    pointerEvents: 'auto !important',
    '&:after': {
      borderBottomColor: '#FAFAFA !important'
    }
  }
})

export default styles

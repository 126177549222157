import { replace } from 'connected-react-router'
import { error, success } from 'react-notification-system-redux'
import { setSubmitFailed, setSubmitSucceeded, startSubmit, stopSubmit } from 'redux-form'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { BASE_CHANNEL } from 'constants/actionCable'
import { ENDPOINT, METHOD } from 'constants/api'
import { CHARTS_DATAS_FORM } from 'constants/forms'
import { tokenSelector } from 'lib/modules/user/selectors'
import ActionCableService from 'lib/services/actionCable'
import fetchAPI from 'lib/services/fetchAPI'
import APIAction from 'lib/utils/APIAction'
import getResourceActions from 'lib/utils/getResourceActions'
import TYPES from './actionTypes'
import { CopyChart, CreateChart, DeleteChart, ReadCharts, UpdateChart } from './actions'
import { isDesktop } from 'src/lib/constants/breakpoints'

function* readCharts(action: ReadCharts) {
  const { requestKey, resourceType, requestProperties } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'READ',
    resourceType,
    requestKey,
    requestProperties
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      const { payload: data } = yield ActionCableService.sendMessage({
        channelName: BASE_CHANNEL,
        type: requestKey,
        payload: {
          panel_id: requestProperties.panelID,
          panel_key: requestProperties.panelKey,
          ids: requestProperties.IDs,
          isDesktop: isDesktop
        }
      })

      return { data }
    },
    *success(data) {
      yield put(onSuccessAction(data))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось получить данные.'
        })
      )
    }
  })
}

function* createChart(action: CreateChart) {
  const token = yield select(tokenSelector)

  const { requestKey, resourceType, requestProperties } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'CREATE',
    resourceType,
    requestKey,
    requestProperties
  })

  yield APIAction({
    *request() {
      yield put(startSubmit(CHARTS_DATAS_FORM.form))
      yield put(onRequestAction())

      const { data }: { data: Data.Chart } = yield call(fetchAPI, {
        endpoint: ENDPOINT.CHARTS,
        method: METHOD.POST,
        token,
        body: {
          info_element: requestProperties.info_element
        }
      })

      return { data: [data] }
    },
    *success(data: Array<Data.Chart>) {
      yield put(onSuccessAction(data))
      yield put(stopSubmit(CHARTS_DATAS_FORM.form))
      yield put(setSubmitSucceeded(CHARTS_DATAS_FORM.form))
      yield put(replace(`/elements/${data[0].id}`))
    },
    *fail(errors) {
      yield put(onFailAction(errors))
      yield put(stopSubmit(CHARTS_DATAS_FORM.form, errors))
      yield put(setSubmitFailed(CHARTS_DATAS_FORM.form))

      yield put(
        error({
          title: 'Не удалось создать элемент.'
        })
      )
    }
  })
}

function* updateChart(action: UpdateChart) {
  const token = yield select(tokenSelector)

  const { requestKey, resourceType, requestProperties } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'UPDATE',
    resourceType,
    requestKey,
    requestProperties
  })

  yield APIAction({
    *request() {
      yield put(startSubmit(CHARTS_DATAS_FORM.form))
      yield put(onRequestAction())

      const { data }: { data: Data.Chart } = yield call(fetchAPI, {
        endpoint: ENDPOINT.CHARTS,
        method: METHOD.PUT,
        path: requestProperties.info_element.id,
        token,
        body: {
          info_element: requestProperties.info_element
        }
      })

      return { data: [data] }
    },
    *success(data: Array<Data.Chart>) {
      yield put(onSuccessAction(data))
      yield put(stopSubmit(CHARTS_DATAS_FORM.form))
      yield put(setSubmitSucceeded(CHARTS_DATAS_FORM.form))

      yield put(
        success({
          title: 'Элемент успешно обновлен.'
        })
      )
    },
    *fail(errors) {
      yield put(onFailAction(errors))
      yield put(stopSubmit(CHARTS_DATAS_FORM.form, errors))
      yield put(setSubmitFailed(CHARTS_DATAS_FORM.form))

      yield put(
        error({
          title: 'Не удалось обновить элемент.'
        })
      )
    }
  })
}

function* deleteChart(action: DeleteChart) {
  const token = yield select(tokenSelector)
  const {
    requestKey,
    resourceType,
    requestProperties: { id }
  } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'DELETE',
    resourceType,
    requestKey,
    ids: [id],
    requestProperties: {
      id
    }
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        endpoint: ENDPOINT.CHARTS,
        method: METHOD.DELETE,
        path: id.toString(),
        token
      })
    },
    *success(data) {
      yield put(onSuccessAction([id]))
      yield put(replace(`/elements`))

      yield put(
        success({
          title: 'Элемент успешно удален.'
        })
      )
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось удалить элемент.'
        })
      )
    }
  })
}

function* copyChart(action: CopyChart) {
  const token = yield select(tokenSelector)

  const { requestKey, resourceType, requestProperties } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'CREATE',
    resourceType,
    requestKey,
    requestProperties
  })

  yield APIAction({
    *request() {
      yield put(startSubmit(CHARTS_DATAS_FORM.form))
      yield put(onRequestAction())

      const { data }: { data: Data.Chart } = yield call(fetchAPI, {
        endpoint: ENDPOINT.CHARTS,
        method: METHOD.POST,
        token,
        body: {
          info_element: requestProperties.info_element
        }
      })

      return { data: [data] }
    },
    *success(data: Array<Data.Chart>) {
      yield put(onSuccessAction(data))
      yield put(stopSubmit(CHARTS_DATAS_FORM.form))
      yield put(setSubmitSucceeded(CHARTS_DATAS_FORM.form))
      yield put(replace(`/elements/${data[0].id}`))
    },
    *fail(errors) {
      yield put(onFailAction(errors))
      yield put(stopSubmit(CHARTS_DATAS_FORM.form, errors))
      yield put(setSubmitFailed(CHARTS_DATAS_FORM.form))

      yield put(
        error({
          title: 'Не удалось скопировать элемент.'
        })
      )
    }
  })
}

export function* watcher() {
  yield all([
    takeEvery(TYPES.READ_CHARTS, readCharts),
    takeEvery(TYPES.CREATE_CHART, createChart),
    takeEvery(TYPES.UPDATE_CHART, updateChart),
    takeEvery(TYPES.COPY_CHART, copyChart),
    takeEvery(TYPES.DELETE_CHART, deleteChart)
  ])
}

import { combineReducers } from 'redux'
import { State as ResourceState, resourceReducer } from 'redux-resource'
import { reset } from 'redux-resource-plugins'

import { RESOURCES } from 'constants/dataImport'
import { CubesActions, SourcesActions, PreviewActions } from './actions'
import { State as PreviewState, reducer as preview } from './preview/reducer'
import { State as MakingState, reducer as making } from './making/reducer'

export interface State {
  cubes: ResourceState<Data.CubeParams>
  sources: ResourceState<Data.SourceParams>
  preview: PreviewState
  making: MakingState
}

export const reducer = combineReducers<State, CubesActions | SourcesActions | PreviewActions>({
  cubes: resourceReducer(RESOURCES.CUBES.NAME, { plugins: [reset] }),
  sources: resourceReducer(RESOURCES.SOURCES.NAME, {
    plugins: [reset]
  }),
  preview,
  making
})

const iconById = {
  1: require('assets/images/sprite/mapBranches/1.svg').default,
  2: require('assets/images/sprite/mapBranches/2.svg').default,
  3: require('assets/images/sprite/mapBranches/3.svg').default,
  4: require('assets/images/sprite/mapBranches/4.svg').default,
  5: require('assets/images/sprite/mapBranches/5.svg').default,
  6: require('assets/images/sprite/mapBranches/6.svg').default,
  7: require('assets/images/sprite/mapBranches/7.svg').default,
  8: require('assets/images/sprite/mapBranches/8.svg').default,
  9: require('assets/images/sprite/mapBranches/9.svg').default,
  10: require('assets/images/sprite/mapBranches/10.svg').default,
  11: require('assets/images/sprite/mapBranches/11.svg').default,
  12: require('assets/images/sprite/mapBranches/12.svg').default,
  13: require('assets/images/sprite/mapBranches/13.svg').default,
  14: require('assets/images/sprite/mapBranches/14.svg').default,
  15: require('assets/images/sprite/mapBranches/15.svg').default,
  16: require('assets/images/sprite/mapBranches/16.svg').default,
  17: require('assets/images/sprite/mapBranches/17.svg').default,
  18: require('assets/images/sprite/mapBranches/18.svg').default
}

export { iconById }

export enum Types {
  GET_USERS = 'GET_USERS',
  CREATE_USER = 'CREATE_USER',
  UPDATE_USER = 'UPDATE_USER',
  RESET_USER_PASSWORD = 'RESET_USER_PASSWORD',
  BLOCK_USER = 'BLOCK_USER',
  DROP_USER = 'DROP_USER'
}

export default Types

import { Action } from 'redux'

import TYPES from './actionTypes'

export interface OpenMenu extends Action<TYPES.OPEN_AGGREGATION_MENU> {
  payload: {
    anchor: HTMLDivElement
    targetAggregation?: Data.AggregationEntity
    targetGroup?: Data.AggregationGroup
    sourceItem?: Data.AggregationEntity | Data.Dimension | Data.Measure
    sourceItemIsAggregation?: boolean
  }
}
export interface CloseMenu extends Action<TYPES.CLOSE_AGGREGATION_MENU> {}
export interface ChangeTargetMenu extends Action<TYPES.CHANGE_TARGET_MENU> {
  payload: {
    type: 'aggregation' | 'group'
    target: Data.AggregationEntity | Data.AggregationGroup
  }
}

export type Actions = OpenMenu | CloseMenu | ChangeTargetMenu

export const openMenu: (payload: {
  anchor: HTMLDivElement
  targetAggregation?: Data.AggregationEntity
  targetGroup?: Data.AggregationGroup
  sourceItem?: Data.AggregationEntity | Data.Dimension | Data.Measure
  sourceItemIsAggregation?: boolean
}) => OpenMenu = payload => ({
  type: TYPES.OPEN_AGGREGATION_MENU,
  payload
})

export const changeTargetMenu: (
  target: Data.AggregationEntity | Data.AggregationGroup,
  type: 'aggregation' | 'group'
) => ChangeTargetMenu = (target, type) => ({
  type: TYPES.CHANGE_TARGET_MENU,
  payload: {
    type,
    target
  }
})

export const closeMenu: () => CloseMenu = () => ({
  type: TYPES.CLOSE_AGGREGATION_MENU
})

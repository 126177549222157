import React, { FC, MouseEventHandler } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import Coat from 'assets/images/coat.png'
import IconBase from 'components/Icons/IconBase'
import { IconButton } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { compose } from 'recompose'
import styles from './styles'

import Tooltip from '@mui/material/Tooltip'
import classNames from 'classnames'

export interface Route {
  key?: string
  path: string
  creationPath?: string
  editorPath?: string
  exact?: boolean
  component?: any
  render?: (props) => React.ReactNode
  sidemenu?: boolean
  additionalSidemenu?: boolean
  subsidemenu?: boolean
  forHead?: boolean
  settingsNavbar?: boolean
  label?: string
  navbarLabel?: string
  icon?: { id: string }
  activeIcon?: { id: string }
  aclKey?: string
  withoutAcl?: boolean
  withAuth?: boolean
}

const adminRoutes = [
  '/journal',
  '/scdata',
  '/news',
  '/users',
  '/construction_objects',
  '/catalog_items'
]

const defaultRoutes = [
  '/desktop',
  '/messages',
  '/forms',
  '/widgets',
  '/spk_building',
  '/other_services',
  '/info_references',
  '/search',
  '/map',
  '/incidents',
  '/messages',
  '/elements',
  '/panels'
]

export interface OuterProps {
  className?: string
  routes?: Array<Route>
  subMenu: boolean
  activeRoutes?: string[]
}

export interface Props extends JSSProps<typeof styles>, OuterProps { }

const Menu: FC<Props> = ({ classes, routes, subMenu, activeRoutes }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const onRouteSelect: (
    routeName: string
  ) => MouseEventHandler<HTMLButtonElement> = routeName => event => {
    event.preventDefault()
    event.stopPropagation()

    navigate(routeName)
  }
  const isAdminRoutes = adminRoutes.some(adminRoute => location.pathname.includes(adminRoute))

  return (
    <div className={subMenu ? classes.navigationMenu : classes.subMenu}>
      {subMenu && <img className={classes.avatar} src={Coat} alt="Герб Ленинградской области" />}
      {routes.map(route => {
        const currentRoutes = isAdminRoutes ? adminRoutes : defaultRoutes
        const isActualRoute = currentRoutes.some(currentRoute => route.path.includes(currentRoute))

        if (!isActualRoute) return null

        let isActiveRoute = location.pathname.includes(route.path)

        const hasAdditionalRoutes = Array.isArray(activeRoutes) && activeRoutes.includes(route.path)

        if (hasAdditionalRoutes) {
          isActiveRoute = activeRoutes.some(activeRoute => location.pathname.includes(activeRoute))
        }

        const IconComponent = IconBase(isActiveRoute ? route.activeIcon : route.icon)

        return (
          <div key={route.path} className={classes.subMenuItem}>
            <Tooltip title={route.label} placement='right' className={classes.toolTipLabel}>
              <IconButton
                className={classNames(isActiveRoute ? 'active-route' : '', classes.menuIconButton)}
                onClick={onRouteSelect(route.path)}
              >
                <IconComponent className={classes.subMenuIcon} />
                {!subMenu && <span className={classes.subMenuText}>{route.label}</span>}
              </IconButton>
            </Tooltip>
          </div>
        )
      })}
    </div>
  )
}

export default compose<Props, OuterProps>(

  injectStyles<OuterProps>(styles)
)(Menu)

import React, { PureComponent, Fragment } from 'react'
import { FieldArrayFieldsProps } from 'redux-form'

import CompareComponent from '../Compare'
import ResultComponent from './components/Result'
import { OPTION_TYPES, COMPARE_OPERATORS } from 'constants/filter'

interface OuterProps {
  name: string
  index: number
  fields: FieldArrayFieldsProps<Filter.Expression>
  selectedOption?: Filter.Option
  stringAsMulti: boolean
}
interface Props extends OuterProps {}

class BaseResult extends PureComponent<Props> {
  render() {
    const { name, fields, index, selectedOption, stringAsMulti } = this.props

    return (
      <Fragment>
        {!!selectedOption && (
          <CompareComponent
            name={name}
            index={index}
            fields={fields}
            selectedOption={selectedOption}
            stringAsMulti={stringAsMulti}
          />
        )}
        <ResultComponent
          name={name}
          index={index}
          fields={fields}
          selectedOption={selectedOption}
          condition={fields.get(index).condition}
        />
      </Fragment>
    )
  }
}

export default BaseResult

import React, { PureComponent } from 'react'
import { WrappedFieldProps } from 'redux-form'
import { compose } from 'recompose'
import injectStyles, { JSSProps } from 'react-jss'
import classNames from 'classnames'
import { Base as BaseSelect } from 'components/Select'
import styles from './styles'
import { OuterProps as SelectOuterProps } from 'components/Select/components/Base'

interface OuterProps extends SelectOuterProps, WrappedFieldProps {
  selectClassName?: string
  disableBlurFocus?: boolean
  label?: string
}
export interface Props extends OuterProps, JSSProps<typeof styles> { }
interface State { }

class Select extends PureComponent<Props, State> {
  static defaultProps = {
    options: [],
    withAddingAllOptions: false
  }

  onFocus = event => {
    if (this.props.disableBlurFocus) return

    this.props.input.onFocus(event)
  }

  onBlur = () => {
    if (this.props.disableBlurFocus) return

    const {
      input: { onBlur, value }
    } = this.props

    onBlur(value)
  }

  onChange = event => this.props.input.onChange(event)

  render() {
    const {
      classes,
      theme,
      className,
      selectClassName,
      label,
      input: { value, name },
      meta: { touched, error },
      id,
      disableBlurFocus,
      options,
      placeholder = '',
      ...props
    } = this.props

    return (
      <div>
        <div className={classNames(classes.container, className)}>
          {!!label && (
            <label className={classes.label} htmlFor={id || name}>
              {label}
            </label>
          )}
          <BaseSelect
            className={selectClassName}
            value={value}
            placeholder={placeholder}
            options={options}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onChange={this.onChange}
            {...props}
          />
        </div>
        {touched && error && typeof error === 'string' && (
          <span className={classes.error}>{error}</span>
        )}
      </div>
    )
  }
}

export default compose<Props, OuterProps>(injectStyles<OuterProps>(styles))(Select)

const BASE_SIZE = 64

const styles = (theme: App.Theme) => ({
  loading: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

export { BASE_SIZE, styles as default }

import { Action, ActionCreator } from 'redux'
import types from './types'
import { CalendarViewType } from './reducer'

export interface UpdateSchedule extends Action<types.UPDATE_SCHEDULE> {}
export interface UpdateScheduleRequest extends Action<types.UPDATE_SCHEDULE_REQUEST> {}
export interface UpdateScheduleSuccess extends Action<types.UPDATE_SCHEDULE_SUCCESS> {}
export interface UpdateScheduleFailure extends Action<types.UPDATE_SCHEDULE_FAILURE> {}

export interface ToggleCalendarView extends Action<types.TOGGLE_CALENDAR_VIEW> {
  payload: CalendarViewType
}

export type ActionTypes =
  | UpdateSchedule
  | UpdateScheduleRequest
  | UpdateScheduleSuccess
  | UpdateScheduleFailure
  | ToggleCalendarView

export const updateSchedule: () => UpdateSchedule = () => ({
  type: types.UPDATE_SCHEDULE
})
export const updateScheduleRequest: () => UpdateScheduleRequest = () => ({
  type: types.UPDATE_SCHEDULE_REQUEST
})

export const updateScheduleSuccess: () => UpdateScheduleSuccess = () => ({
  type: types.UPDATE_SCHEDULE_SUCCESS
})

export const updateScheduleFailure: () => UpdateScheduleFailure = () => ({
  type: types.UPDATE_SCHEDULE_FAILURE
})

export const toggleCalendarView: (data: CalendarViewType) => ToggleCalendarView = data => ({
  type: types.TOGGLE_CALENDAR_VIEW,
  payload: data
})

import Data from '../../../types/data'
import { Action, ActionCreator } from 'redux'

import types from './types'

export interface ItemSetNotificationAction extends Action<types.SIDEMENU_ITEM_SET_NOTIFICATION> {
  payload: {
    id: string
    count?: number
  }
}

export interface ChangeBreadcrumbsAction {
  payload: Array<Data.Breadcrumbs>
}

export interface ItemRemoveNotificationAction
  extends Action<types.SIDEMENU_ITEM_REMOVE_NOTIFICATION> {
  payload: {
    id: string
  }
}

export interface ReadResourcesCount extends Action<types.GET_RESOURCES_COUNT> {}
export interface ReadResourcesCountRequest extends Action<types.GET_RESOURCES_COUNT_REQUEST> {
  payload: {}
}
interface ReadResourcesCountRequestSuccess extends Action<types.GET_RESOURCES_COUNT_SUCCESS> {
  payload: {
    [key: string]: number
  }
}

interface ReadResourcesCountRequestFailure extends Action<types.GET_RESOURCES_COUNT_FAILURE> {
  payload: {
    error: string
  }
}

export type ActionTypes =
  | ItemSetNotificationAction
  | ItemRemoveNotificationAction
  | ReadResourcesCount
  | ChangeBreadcrumbsAction
  | ReadResourcesCountRequest
  | ReadResourcesCountRequestSuccess
  | ReadResourcesCountRequestFailure

export const setNotification: ActionCreator<ItemSetNotificationAction> = (
  id: string,
  count?: number
) => ({
  type: types.SIDEMENU_ITEM_SET_NOTIFICATION,
  payload: {
    id,
    count
  }
})
export const removeNotification: ActionCreator<ItemRemoveNotificationAction> = (id: string) => ({
  type: types.SIDEMENU_ITEM_REMOVE_NOTIFICATION,
  payload: {
    id
  }
})
export const readResourcesCount: ActionCreator<ReadResourcesCount> = () => ({
  type: types.GET_RESOURCES_COUNT
})

export const changeBreadcrumbs = (payload: Array<Data.Breadcrumbs>) => ({
  type: types.CHANGE_BREADCRUMBS,
  payload
})

export const readResourcesCountRequest: ActionCreator<ReadResourcesCountRequest> = () => ({
  type: types.GET_RESOURCES_COUNT_REQUEST,
  payload: {}
})

export const readResourcesCountRequestSuccess: ActionCreator<
  ReadResourcesCountRequestSuccess
> = data => ({
  type: types.GET_RESOURCES_COUNT_SUCCESS,
  payload: data
})

export const readResourcesCountRequestFailure: ActionCreator<
  ReadResourcesCountRequestFailure
> = error => ({
  type: types.GET_RESOURCES_COUNT_FAILURE,
  payload: {
    error
  }
})

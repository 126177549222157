import React, { PureComponent, HTMLAttributes } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import { WrappedFieldProps, BaseFieldProps } from 'redux-form'

import Toggle, { ToggleProps } from 'components/Toggle'
import styles from './styles'

interface OuterProps
  extends ToggleProps,
    Omit<
      HTMLAttributes<HTMLInputElement>,
      'onChange' | 'onBlur' | 'onDrop' | 'onDragStart' | 'onFocus'
    >,
    BaseFieldProps {
  // Fix strange ts error
  component?: any
}
export interface Props extends OuterProps, WrappedFieldProps, JSSProps<typeof styles> {}

class ToggleField extends PureComponent<Props> {
  onBlur = () => {
    const {
      input: { value, onBlur }
    } = this.props

    onBlur(value)
  }

  onToggle = () => {
    const {
      input: { value, onChange }
    } = this.props

    onChange(!value)
  }

  render() {
    const {
      classes,
      input: { onBlur, onChange, onFocus, value, name },
      meta,
      ...props
    } = this.props

    return (
      <Toggle
        value={value}
        onChange={this.onToggle}
        onFocus={onFocus}
        onBlur={this.onBlur}
        name={name}
        {...props}
      />
    )
  }
}

export default injectStyles<OuterProps>(styles)(ToggleField)

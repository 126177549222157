export enum TableTheme {
  onlyHeadersBlue = 'onlyHeadersBlue',
  onlyHeadersBlack = 'onlyHeadersBlack',
  onlyHeadersGreen = 'onlyHeadersGreen',
  onlyHeadersOrange = 'onlyHeadersOrange',
  onlyEvenRowsBlue = 'onlyEvenRowsBlue',
  onlyEvenRowsBlack = 'onlyEvenRowsBlack',
  onlyEvenRowsGreen = 'onlyEvenRowsGreen',
  onlyEvenRowsOrange = 'onlyEvenRowsOrange',
  allBlue = 'allBlue',
  allBlack = 'allBlack',
  allGreen = 'allGreen',
  allOrange = 'allOrange'
}

const styles = (theme: App.Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    width: '100%',
    minHeight: 'calc(3rem + 2px)',
    marginTop: '0.625rem',
    padding: '10px',
    border: '2px dashed #E0E6EB',
    borderRadius: '10px',
    boxSizing: 'border-box',
    '& > *': {
      marginTop: '0.3125rem',
      marginRight: '0.3125rem'
    },
    '& > *:not($placeholder):last-child': {
      marginBottom: '2rem'
    }
  },
  placeholder: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: 'calc(3rem + 2px)',
    padding: '0.625rem',
    boxSizing: 'border-box'
  },
  placeholderText: {
    maxWidth: '100%',
    fontSize: '0.75rem',
    lineHeight: 1.2,
    color: theme.colors.grey,
    textAlign: 'center'
  },
  set: {
    cursor: 'pointer'
  },
  selectedSet: {
    border: `1px solid ${theme.colors.blue}`
  }
})

export default styles

import React, { PureComponent, HTMLAttributes } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import classNames from 'classnames'

import styles from './styles'

interface OuterProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
  name: string
  value: string
  onToggle: (value: string) => void
}
export interface Props extends OuterProps, JSSProps<typeof styles> {}

class ToggleField extends PureComponent<Props> {
  onToggle = () => {
    const { value, onToggle } = this.props

    onToggle(value === 'true' ? 'false' : 'true')
  }

  render() {
    const { classes, className, name, value } = this.props

    return (
      <div className={classNames(classes.container, className)}>
        <label htmlFor={name} className={classes.labelWorking}>
          Рабочие
        </label>
        <input
          id={name}
          name={name}
          className={classes.toggler}
          type="checkbox"
          value={value}
          onChange={this.onToggle}
        />
        <label htmlFor={name} className={classes.labelCalendar}>
          Календарные
        </label>
      </div>
    )
  }
}

export default injectStyles<OuterProps>(styles)(ToggleField)

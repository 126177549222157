const styles = (theme: App.Theme) => ({
  button: {
    display: 'block',
    textDecoration: 'none',
    borderRadius: 8,
    border: '1px solid',
    outline: 'none',
    padding: '8px 12px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    boxShadow: '0 0 0 0px transparent',
    transition:
      'opacity .15s linear, color 0.3s ease-out, background-color 0.3s ease-out, border-color 0.3s ease-out, box-shadow 0.3s ease-out',
    '&:disabled': {
      opacity: 0.8,
      cursor: 'default'
    }
  },
  text: {
    marginTop: 5,
    marginBottom: 5,
    fontSize: '0.875rem',
    lineHeight: 1,
    fontWeight: 'bold',
    '&:first-child': {
      marginLeft: ({ icon }) => (icon ? 10 : 'initial')
    },
    '&:last-child': {
      marginRight: ({ icon }) => (icon ? 10 : 'initial')
    }
  },
  container: {
    position: 'relative',
    display: 'flex',
    ...theme.utils.flexContainer({ direction: 'row', justify: 'center', alignItems: 'center' })
  },
  buttonLoading: {
    position: 'relative',

    '& .div-loading': {
      fontSize: '14px',
      borderWidth: '2px'
    }
  },
  icon: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    width: '14px',
    height: '14px',
    lineHeight: 0,
    boxSizing: 'border-box',
    transition: 'background-color 0.3s ease-out',
    '&:first-child': {
      marginRight: 12
    },
    '&:last-child': {
      marginLeft: 12
    },
    '& svg': {
      width: '14px',
      height: '14px'
    },
    '& > svg': {
      position: 'relative',
      width: '100%',
      height: '100%',
      transition: 'fill 0.3s ease-out, stroke 0.3s ease-out'
    }
  },
  // button types specific
  defaultType: {
    ...theme.colors.button.default,
    '&:not(:disabled)': {
      '&:hover, &:focus': {
        borderColor: theme.colors.button.iconBorder,
        boxShadow: `0 0 0 2px ${theme.colors.button.default.backgroundColor}`
      }
    }
  },
  primaryType: {
    ...theme.colors.button.primary,
    '&:not(:disabled)': {
      '&:hover, &:focus': {
        borderColor: theme.colors.button.primary.color,
        boxShadow: `0 0 0 2px ${theme.colors.button.primary.backgroundColor}`
      }
    }
  },
  secondaryType: {
    ...theme.colors.button.secondary,
    '&:not(:disabled)': {
      '&:hover, &:focus': {
        boxShadow: `0 0 0 2px ${theme.colors.button.secondary.backgroundColor}`
      }
    }
  },
  dangerType: {
    ...theme.colors.button.danger,
    '&:not(:disabled):hover': {
      borderColor: theme.colors.button.danger.borderColor,
      boxShadow: `0 0 0 2px ${theme.colors.button.danger.backgroundColor}`
    }
  },
  transparentType: {
    ...theme.colors.button.transparent,
    '& $icon': {
      '& svg': {
        fill: theme.colors.button.transparent.color,
        stroke: theme.colors.button.transparent.color
      }
    },
    '&:not(:disabled)': {
      '&:hover, &:focus': {
        borderColor: theme.colors.button.transparent.color,
        boxShadow: `0 0 0 2px ${theme.colors.button.transparent.backgroundColor}`
      }
    }
  }
})

export default styles

import { takeEvery, call, put, select, all } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { error } from 'react-notification-system-redux'
import {
  startSubmit,
  setSubmitSucceeded,
  setSubmitFailed,
  stopSubmit,
  getFormValues,
  getFormInitialValues
} from 'redux-form'

import fetchAPI from 'lib/services/fetchAPI'
import APIAction from 'lib/utils/APIAction'
import getResourceActions from 'lib/utils/getResourceActions'
import objectToFormData from 'lib/utils/objectToFormData'
import { tokenSelector } from 'lib/modules/user/selectors'
import {
  ReadPublications,
  CreatePublication,
  UpdatePublication,
  DeletePublication
} from './actions'
import { METHOD, ENDPOINT } from 'constants/api'
import { NEWS_FORM } from 'constants/forms'
import TYPES from './actionTypes'

function* readPublications(action: ReadPublications) {
  const token = yield select(tokenSelector)
  const { resourceType, requestKey, options } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'READ',
    resourceType,
    requestKey
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        endpoint: ENDPOINT.PUBLICATIONS,
        token: token ? token : undefined,
        params: options
      })
    },
    *success(data) {
      yield put(onSuccessAction(data))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось получить данные.'
        })
      )
    }
  })
}

function* createPublication(action: CreatePublication) {
  const token = yield select(tokenSelector)

  const { requestKey, resourceType, redirect } = action.payload
  const { title, text, blocks, published, published_at } = yield select(
    getFormValues(NEWS_FORM.form)
  )
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'CREATE',
    resourceType,
    requestKey
  })

  yield APIAction({
    *request() {
      yield put(startSubmit(NEWS_FORM.form))

      yield put(onRequestAction())

      const fd = objectToFormData(
        {
          title,
          text,
          blocks_attributes: blocks,
          published,
          published_at: published ? published_at || new Date().getTime() / 1000 : undefined
        },
        'publication'
      )

      return yield call(fetchAPI, {
        method: METHOD.POST,
        endpoint: ENDPOINT.PUBLICATIONS,
        body: fd,
        token
      })
    },
    *success(data: Data.Publication) {
      yield put(onSuccessAction([data]))

      yield put(stopSubmit(NEWS_FORM.form))
      yield put(setSubmitSucceeded(NEWS_FORM.form))

      if (redirect) yield put(push(redirect))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(stopSubmit(NEWS_FORM.form, errors))
      yield put(setSubmitFailed(NEWS_FORM.form))

      yield put(
        error({
          title: 'Не удалось создать новость.'
        })
      )

      if (redirect) yield put(push(redirect))
    }
  })
}

function* updatePublication(action: UpdatePublication) {
  const token = yield select(tokenSelector)
  const {
    resourceType,
    requestKey,
    options: { ID, redirect }
  } = action.payload
  const { published_at, published, ...publication }: Data.Publication = yield select(
    getFormValues(NEWS_FORM.form)
  )
  const initialPublication: Data.Publication = yield select(getFormInitialValues(NEWS_FORM.form))
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'UPDATE',
    resourceType,
    requestKey,
    ids: [ID]
  })

  yield APIAction({
    *request() {
      yield put(startSubmit(NEWS_FORM.form))

      yield put(onRequestAction())

      // Add destroyed blocks
      initialPublication.blocks.forEach(block => {
        const block_current = publication.blocks.find(item => item.id === block.id)

        if (!block_current) publication.blocks.push({ ...block, _destroy: true })
      })

      const fd = objectToFormData(
        {
          ...publication,
          published,
          published_at: published ? published_at || new Date().getTime() / 1000 : undefined,
          blocks: undefined,
          blocks_attributes: publication.blocks
        },
        'publication'
      )

      return yield call(fetchAPI, {
        method: METHOD.PUT,
        endpoint: ENDPOINT.PUBLICATIONS,
        path: ID.toString(),
        body: fd,
        token
      })
    },
    *success(data) {
      yield put(onSuccessAction([data]))
      yield put(stopSubmit(NEWS_FORM.form))
      yield put(setSubmitSucceeded(NEWS_FORM.form))

      if (redirect) yield put(push(redirect))
    },
    *fail(errors) {
      yield put(onFailAction(errors))
      yield put(stopSubmit(NEWS_FORM.form, errors))
      yield put(setSubmitFailed(NEWS_FORM.form))

      yield put(
        error({
          title: 'Не удалось обновить новость.'
        })
      )
    }
  })
}

function* deletePublication(action: DeletePublication) {
  const token = yield select(tokenSelector)
  const { resourceType, requestKey, id } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'DELETE',
    resourceType,
    requestKey,
    ids: [id]
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        method: METHOD.DELETE,
        endpoint: ENDPOINT.PUBLICATIONS,
        path: id.toString(),
        token
      })
    },
    *success(data) {
      yield put(onSuccessAction([id]))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось удалить новость.'
        })
      )
    }
  })
}

export function* watcher() {
  yield all([
    takeEvery(TYPES.READ_PUBLICATIONS, readPublications),
    takeEvery(TYPES.CREATE_PUBLICATION, createPublication),
    takeEvery(TYPES.UPDATE_PUBLICATION, updatePublication),
    takeEvery(TYPES.DELETE_PUBLICATION, deletePublication)
  ])
}

import { takeEvery, put, all, select, call, take } from 'redux-saga/effects'
import { success, error } from 'react-notification-system-redux'

import fetchAPI from 'lib/services/fetchAPI'
import APIAction from 'lib/utils/APIAction'
import getResourceActions from 'lib/utils/getResourceActions'
import { objectToFormData } from 'lib/utils/objectToFormData'
import { tokenSelector } from 'lib/modules/user/selectors'
import { ReadSources, CreateDbDataSource } from './actions'
import { Types } from './types'
import { METHOD, ENDPOINT } from 'constants/api'

function* readSources(action: ReadSources) {
  const { requestKey, resourceType } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'READ',
    resourceType,
    requestKey
  })
  const token = yield select(tokenSelector)

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        method: METHOD.GET,
        endpoint: ENDPOINT.DB_SOURCE,
        token
      })
    },
    *success(data) {
      yield put(onSuccessAction(data))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось получить данные.'
        })
      )
    }
  })
}

function* createDbDataSource(action: CreateDbDataSource) {
  const token = yield select(tokenSelector)
  const {
    requestKey,
    resourceType,
    requestProperties: { data, path, method = METHOD.POST, root_key }
  } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'CREATE',
    resourceType,
    requestKey,
    requestProperties: { data, path, method }
  })

  const body = objectToFormData(data, root_key)

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        method,
        endpoint: path,
        token,
        body
      })
    },
    *success(data) {
      yield put(onSuccessAction([data]))

      yield put(success({ title: 'Данные источника успешно загружены' }))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось создать источник'
        })
      )
    }
  })
}

export function* watcher() {
  yield all([takeEvery(Types.READ, readSources), takeEvery(Types.CREATE_DB, createDbDataSource)])
}

const styles = (theme: OpenApp.Theme) => ({
  portal: {},
  container: {
    ...theme.utils.flexContainer({
      direction: 'row',
      justify: 'space-between',
      alignItems: 'flex-start'
    }),
    width: '80%',
    height: '100%',
    backgroundColor: 'transparent',
    outline: 'none'
  },
  overlay: {
    ...theme.utils.flexContainer({ justify: 'center', alignItems: 'flex-end' }),
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 100001,

    '& .ReactModal__Content': {
      transform: 'translateX(100%)',
      transition: 'transform 0.4s',

      '&--after-open': {
        transform: 'translateX(0)'
      }
    }
  },
  content: {
    display: 'flex',
    width: '90%',
    height: '100%',
    backgroundColor: '#FFFFFF',
    boxSizing: 'border-box',
    '-webkit-overflow-scrolling': 'touch'
  },
  buttons: {
    ...theme.utils.flexContainer({ direction: 'column', alignItems: 'center' }),
    width: '10%',
    marginTop: '2.5rem'
  },
  closeButton: {
    backgroundColor: '#FFFFFF',
    '& > svg': {
      fill: 'transparent'
    }
  },
  orderSection: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    height: '100%',
    padding: '3rem 2.5rem',
    backgroundColor: theme.colors.blue,
    boxSizing: 'border-box',
    overflow: 'auto',
    '& > *': { flexShrink: 0 }
  },
  orderTitle: {
    marginTop: 0,
    fontFamily: 'Helvetica',
    fontSize: '2.25rem',
    fontWeight: 'bold',
    color: '#FFFFFF'
  },
  orderEis: {
    paddingBottom: '1.25rem',
    fontFamily: 'Helvetica',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: '#FFFFFF'
  },
  orderGrid: {
    flex: '1 0 auto',
    ...theme.utils.flexContainer({ direction: 'row', justify: 'space-between' }),
    width: '100%'
  },
  orderColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(50% - 1rem)',
    '&:first-child': {
      marginRight: '0.5rem'
    },
    '&:last-child': {
      marginLeft: '0.5rem'
    }
  },
  orderDataCellValue: {
    color: '#FFFFFF'
  },
  productSection: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    height: '100%',
    padding: '3rem 2.5rem',
    backgroundColor: '#FAFAFA',
    boxShadow: '0 3px 5px 0 rgba(0,0,0,0.10)',
    boxSizing: 'border-box',
    overflow: 'auto',
    '& > *': { flexShrink: 0 }
  },
  productSectionHeader: {
    ...theme.utils.flexContainer({ direction: 'row', alignItems: 'center', justify: 'flex-end' }),
    width: '100%',
    height: '2.25rem'
  }
})

export default styles

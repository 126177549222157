import resourceManager from 'lib/modules/resourceManager/sagas'
import { watcher as user } from 'lib/modules/user/sagas'
import { watcher as users } from 'lib/modules/users/sagas'
import { watcher as organisations } from 'lib/modules/organisations/sagas'
import { watcher as chartCategories } from 'lib/modules/chartCategories/sagas'
import { watcher as reportCategories } from 'lib/modules/reportCategories/sagas'
import { watcher as publications } from 'lib/modules/publications/sagas'
import { watcher as messages } from 'lib/modules/messages/sagas'
import { watcher as messagesReceivers } from 'lib/modules/messagesReceivers/sagas'
import { watcher as charts } from 'lib/modules/charts/sagas'
import { watcher as vehicles } from 'lib/modules/vehicles/sagas'
import { watcher as importFiles } from 'lib/modules/importSettings/sagas'
import { watcher as apkbgs } from 'lib/modules/apkbgs/sagas'
import { watcher as info_references } from 'lib/modules/infoReferences/sagas'
import { watcher as widgets } from 'lib/modules/widgets/sagas'
import { watcher as widgetBackgrounds } from 'lib/modules/widgetBackgrounds/sagas'
import { watcher as incidents } from 'lib/modules/incidents/sagas'
import { watcher as incidentTopics } from 'lib/modules/incidentTopics/sagas'
import { watcher as infoSystems } from 'lib/modules/infoSystems/sagas'
import { watcher as panels } from 'lib/modules/panels/sagas'
import { watcher as scanner } from 'lib/modules/scanner/sagas'
import { watcher as constructionObjects } from 'lib/modules/constructionObjects/sagas'
import { watcher as scannerCategories } from 'lib/modules/scannerCategories/sagas'
import { watcher as forms } from 'lib/modules/forms/sagas'
import { watcher as panelEditor } from 'lib/modules/panelEditor/sagas'
import { watcher as notifications } from 'lib/modules/notifications/sagas'
import { watcher as branches } from 'lib/modules/branches/sagas'
import { watcher as sidemenu } from 'lib/modules/sidemenu/sagas'
import { watcher as catalog_items } from 'lib/modules/catalogItems/sagas'
import { watcher as zones } from 'lib/modules/open/zones/sagas'
import { watcher as subZones } from 'lib/modules/open/subZones/sagas'
import { watcher as data_import } from 'lib/modules/data/sagas'
import { watcher as desktop } from 'lib/modules/desktop/sagas'
import { watcher as desktopButtons } from 'lib/modules/desktopButtons/sagas'
import { watcher as schedule } from 'lib/modules/schedule/sagas'
import { watcher as fpd } from 'lib/modules/fpd/sagas'
import { watcher as incident } from 'src/lib/modules/incident/sagas'
import { watcher as msp } from 'src/lib/modules/msp/sagas'
// Array that contains all root-level sagas
// Each will run in saga middleware
const sagas = [
  resourceManager,
  user,
  users,
  catalog_items,
  organisations,
  chartCategories,
  reportCategories,
  publications,
  messages,
  messagesReceivers,
  charts,
  vehicles,
  apkbgs,
  importFiles,
  info_references,
  widgets,
  widgetBackgrounds,
  incidents,
  incidentTopics,
  infoSystems,
  panels,
  scanner,
  scannerCategories,
  forms,
  panelEditor,
  notifications,
  branches,
  sidemenu,
  zones,
  subZones,
  data_import,
  desktop,
  desktopButtons,
  schedule,
  fpd,
  incident,
  constructionObjects,
  msp
]

export default sagas

import React, { Component } from 'react'
import { compose } from 'recompose'
import injectStyles, { JSSProps } from 'react-jss'
import { connect, MapStateToProps } from 'react-redux'
import { Option, OnChangeMultipleHandler } from 'react-select'

import ActionCableService from 'lib/services/actionCable'
import { tokenSelector } from 'lib/modules/user/selectors'
import { BASE_CHANNEL } from 'constants/actionCable'
import {
  OPTION_TYPES,
  FIELD_TYPES,
  BOOLEAN_OPTIONS,
  COMPARE_OPERATORS,
  DATE_FIELD_TYPES,
  FILTERS_LINE_HEIGHT
} from 'constants/filter'
import { Base as Select } from 'components/Select'

import styles from './styles'

interface OuterProps {
  field: Filter.Expression
  value: string[]
  change: (value) => void
}
interface StateProps {
  token: string
}
interface Props extends OuterProps, StateProps, JSSProps<typeof styles> {}
interface State {
  options: Option<string>[]
  optionsKey?: string
  optionsMeta: { loading: boolean; loaded: boolean }
}

class DateSelect extends Component<Props, State> {
  state: Readonly<State> = { options: [], optionsMeta: { loading: false, loaded: false } }

  componentDidMount() {
    const {
      field: {
        field1: { date_reg_exp }
      }
    } = this.props
    if (date_reg_exp) this.loadOptions()
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      field: {
        field1: { date_reg_exp }
      }
    } = this.props
    const {
      field: {
        field1: { date_reg_exp: prev_date_reg_exp }
      }
    } = prevProps

    if (
      date_reg_exp !== prev_date_reg_exp ||
      (date_reg_exp !== this.state.optionsKey && !this.state.optionsMeta.loading)
    )
      this.loadOptions()
  }

  loadOptions = async () => {
    const {
      field: {
        field1: { date_reg_exp, cube_id, id }
      },
      token
    } = this.props

    this.setState(prev => ({
      optionsKey: date_reg_exp,
      optionsMeta: { loaded: false, loading: true }
    }))

    const { payload }: { payload: string[] } = await ActionCableService.sendMessage({
      channelName: BASE_CHANNEL,
      payload: { cube_id, filter: id, date_reg_exp },
      type: 'GET_FILTER_VALUES',
      token
    })

    this.setState(prev => ({
      options: payload.map(item => ({ label: item, value: item })),
      optionsKey: date_reg_exp,
      optionsMeta: { loaded: true, loading: false }
    }))
  }

  onChange: OnChangeMultipleHandler<string> = newValue => {
    const { change } = this.props

    change(newValue ? newValue.map(item => item.value) : [])
  }

  render() {
    const { classes, value } = this.props
    const { options, optionsMeta } = this.state

    return (
      <Select
        multi
        searchable
        clearable
        className={classes.fieldSelect}
        withAddingAllOptions
        placeholder="Выберите значение"
        value={value}
        isLoading={optionsMeta.loading}
        onChange={this.onChange}
        options={options}
        height={`calc(${FILTERS_LINE_HEIGHT} + 2px)`}
      />
    )
  }
}

const mapStateToProps: MapStateToProps<StateProps, Props, App.State> = state => ({
  token: tokenSelector(state)
})

export default compose<Props, OuterProps>(
  connect(mapStateToProps),
  injectStyles(styles)
)(DateSelect)

import { takeEvery, put, select, all, call } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import { success, error } from 'react-notification-system-redux'

import fetchAPI from 'lib/services/fetchAPI'
import APIAction from 'lib/utils/APIAction'
import getResourceActions from 'lib/utils/getResourceActions'
import { tokenSelector } from 'lib/modules/user/selectors'
import {
  ReadConstructionObjects,
  CreateConstructionObject,
  UpdateConstructionObject,
  DeleteConstructionObject
} from './actions'
import { METHOD, ENDPOINT } from 'constants/api'
import TYPES from './actionTypes'

function* readConstructionObjects(action: ReadConstructionObjects) {
  const { requestKey, resourceType, requestProperties, list } = action.payload
  const token = yield select(tokenSelector)
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'READ',
    resourceType,
    requestKey,
    requestProperties,
    list
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      const { data }: { data: Data.AIP_Objects } = yield call(fetchAPI, {
        endpoint: ENDPOINT.CONSTRUCTION_OBJECTS,
        method: METHOD.GET,
        token,
        params: requestProperties
      })

      return { data }
    },
    *success(data) {
      yield put(onSuccessAction(data.objects, data))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось получить данные.'
        })
      )
    }
  })
}

function* createConstructionObject(action: CreateConstructionObject) {
  const token = yield select(tokenSelector)

  const { requestKey, resourceType, requestProperties } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'CREATE',
    resourceType,
    requestKey,
    requestProperties
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())
      const { constructionObject } = requestProperties

      const { data }: { data: Data.ConstructionObject } = yield call(fetchAPI, {
        endpoint: ENDPOINT.CONSTRUCTION_OBJECTS,
        method: METHOD.POST,
        token,
        body: constructionObject
      })

      return { data: [data] }
    },
    *success(data: Array<Data.ConstructionObject>) {
      yield put(onSuccessAction(data))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось создать объект.'
        })
      )
    }
  })
}

function* updateConstructionObject(action: UpdateConstructionObject) {
  const token = yield select(tokenSelector)

  const { requestKey, resourceType, requestProperties } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'UPDATE',
    resourceType,
    requestKey,
    requestProperties
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())
      const { constructionObject } = requestProperties
      const body = {
        ...constructionObject
        // user_ids: incident.user_ids.map(item => item.value || item)
      }

      const { data }: { data: Data.ConstructionObject } = yield call(fetchAPI, {
        endpoint: ENDPOINT.CONSTRUCTION_OBJECTS,
        method: METHOD.PUT,
        path: requestProperties.constructionObject.id,
        token,
        body
      })

      return { data: [data] }
    },
    *success(data: Array<Data.ConstructionObject>) {
      yield put(onSuccessAction(data))

      yield put(
        success({
          title: 'Объект успешно обновлен.'
        })
      )
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось обновить объект.'
        })
      )
    }
  })
}

function* deleteConstructionObject(action: DeleteConstructionObject) {
  const token = yield select(tokenSelector)
  const {
    requestKey,
    resourceType,
    requestProperties: { id }
  } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'DELETE',
    resourceType,
    requestKey,
    ids: [id],
    requestProperties: {
      id
    }
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        endpoint: ENDPOINT.CONSTRUCTION_OBJECTS,
        method: METHOD.DELETE,
        path: id.toString(),
        token
      })
    },
    *success(data) {
      yield put(onSuccessAction([id]))
      yield put(replace(`/aip_objects`))

      yield put(
        success({
          title: 'Объект успешно удален.'
        })
      )
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось удалить объект.'
        })
      )
    }
  })
}

export function* watcher() {
  yield all([
    takeEvery(TYPES.READ_CONSTRUCTION_OBJECTS, readConstructionObjects),
    takeEvery(TYPES.CREATE_CONSTRUCTION_OBJECT, createConstructionObject),
    takeEvery(TYPES.UPDATE_CONSTRUCTION_OBJECT, updateConstructionObject),
    takeEvery(TYPES.DELETE_CONSTRUCTION_OBJECT, deleteConstructionObject)
  ])
}

import { createSelector } from 'reselect'
import { getResources } from 'redux-resource'
import { getFormValues } from 'redux-form'

import { CHARTS, PUBLICATIONS } from 'constants/resources'
import { TYPES } from 'constants/dataView'
import { PANEL_FORM } from 'constants/forms'
import { FormValues } from 'pages/closed/PanelEditor'

export const getAllCharts = (state: App.State) => getResources(state.charts, CHARTS.LISTS.ALL)
export const getAllPublications = (state: App.State) =>
  getResources(state.publications, PUBLICATIONS.LISTS.ALL)
export const getPanel = (state: App.State) => state.panelEditor.panel
export const getValues = (state: App.State) => getFormValues(PANEL_FORM.form)(state)

export const getAllElements = createSelector(
  [getAllCharts, getAllPublications],
  (charts: Array<Data.Chart>, publications: Array<Data.Publication>) => {
    return [
      ...charts,
      ...publications.map(publication => ({
        ...publication,
        element_type: 'publication',
        created_at: publication.published_at
      }))
    ]
  }
)

export const isValid = createSelector(
  [getPanel],
  (panel: Partial<Data.Panel>) =>
    panel.templates &&
    panel.templates.length &&
    panel.templates.every(template =>
      template.cells.some(cell =>
        cell.elements.some(element => element.element_type !== TYPES.EMPTY)
      )
    )
)

export const isSettingsValid = createSelector(
  [getValues],
  (values: FormValues) =>
    !!(values && values.user_access_array
      ? values.user_access_array && (values.user_access_array.length || !values.accessInClosePart)
      : true)
)

const styles = (theme: App.Theme) => ({
  menuContainer: {
    ...theme.utils.flexContainer({ direction: 'row', alignItems: 'center' }),
    width: '100%',
    height: theme.sizes.sidemenu.height,
    boxSizing: 'border-box',
    flexGrow: 0,
    flexShrink: 0
  },
  menuHeader: {
    ...theme.utils.flexContainer({ direction: 'row', alignItems: 'center', justify: 'flex-start' }),
    boxSizing: 'border-box',
    flex: `0 0 calc(${theme.sizes.header.height} + ${theme.sizes.sidemenu.itemIcon} + 20px * 2)`,
    paddingTop: '1.25rem'
  },
  menuBody: {
    ...theme.utils.flexContainer({ direction: 'row', alignItems: 'center', justify: 'flex-start' }),
    flex: '2 0 auto',
    height: '100%'
  },
  menuFooter: {
    ...theme.utils.flexContainer({ direction: 'row', alignItems: 'center', justify: 'center' }),
    flex: '0 0 auto',
    marginRight: '1.25rem',
    marginLeft: '1.25rem',
    boxSizing: 'border-box'
  },
  item: {
    width: '2rem',
    height: '2rem',
    fontSize: 14,
    boxSizing: 'border-box'
  },
  itemLink: {
    extend: 'item',
    ...theme.utils.flexContainer({
      direction: 'row',
      alignItems: 'center'
    }),
    textDecoration: 'none',
    color: '#000000',
    '&:visited, &:hovered': {
      textDecoration: 'none',
      color: '#000000'
    }
  },
  itemButton: {
    extend: 'item',
    padding: 0,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer'
  },
  itemLabel: {},
  itemIcon: {
    flex: '1 0 auto'
  },
  logoutIcon: {
    extend: 'itemIcon'
  },
  notificationsIcon: {
    composes: '$item',
    lineHeight: 0,
    cursor: 'pointer'
  },
  placeholder: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '2rem',
    height: '2rem',
    background: 'transparent',
    boxSizing: 'border-box',
    '& > div': {
      content: '""',
      width: theme.sizes.sidemenu.headerPlaceholderSize,
      height: theme.sizes.sidemenu.headerPlaceholderSize,
      backgroundColor: 'transparent',
      border: 'solid 7px #2f61d5',
      borderRadius: '100%',
      boxSizing: 'border-box'
    }
  },
  tooltip: {
    // !important needed for overwrite default styles
    fontFamily: 'Helvetica !important',
    fontSize: '1rem !important',
    fontWeight: 'bold !important',
    opacity: '1 !important',
    boxShadow: `${theme.common.page.contentBoxShadow} !important`
  },
  menuIconButton: {
    flexDirection: 'column',
  },
  notificationsTooltip: {
    position: `fixed !important`,
    marginLeft: `-8.875rem !important`,
    padding: '0 !important',
    opacity: '1 !important',
    borderRadius: '14px',
    boxShadow: `${theme.common.page.contentBoxShadow} !important`
  },
  avatar: {
    height: '38px',
    width: '30px',
    marginBottom: '40px'
  },
  divider: {
    height: '1.5rem',
    width: 1
  },
  menu: {
    width: '40%',
    maxWidth: '37.5rem',
    boxShadow: '0 1px 0 0 #EEEEEE, 0 5px 20px 0 rgba(0,0,0,0.10)'
  },
  menuButtonLabel: {
    fontWeight: 'bold',
    textTransform: 'none'
  },
  menuButtonCloseLabel: {
    fontWeight: 'bold',
    textTransform: 'none',
    color: '#858E98'
  },
  menuButtonIcon: {
    marginLeft: theme.spacing.unit,
    fontSize: '1rem'
  },
  navigation: {
    display: 'flex'
  },
  navigationMenu: {
    width: 70,
    paddingTop: '1rem',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 900,
    '& svg': {
      width: '18px',
      height: '18px',

      '& .active-route': {
        fill: 'blue'
      }
    }
  },
  subMenu: {
    width: '112px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '26px 10px 10px',
    gap: '10px'
  },
  subMenuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& button': {
      height: ({ subMenu }) => subMenu && '42px',
      marginBottom: '12px'
    }
  },
  subMenuIcon: {
    width: '24px',
    height: '24px'
  },
  subMenuText: {
    fontSize: 10
  },
  iconSettings: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '42px',
  },
  toolTipLabel: {
    borderRadius: '8px',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)'
  },
  menuListHeader: {
    lineHeight: theme.sizes.sidemenu.height
  },
  menuItem: {
    justifyContent: 'space-between',
    cursor: 'default',
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
      '& $menuItemDivider': {
        backgroundColor: theme.colors.red
      }
    }
  },
  menuItemDivider: {
    position: 'absolute',
    bottom: 0,
    right: theme.spacing.unit * 2,
    left: theme.spacing.unit * 2,
    backgroundColor: '#F7F7F7',
    transition: 'background-color 0.3s ease-out'
  },
  menuItemButton: {
    minWidth: 0,
    paddingLeft: 0,
    paddingRight: 0,
    transition: 'color 0.3s ease-out',
    '&:hover, &:focus': {
      color: theme.colors.blue,
      backgroundColor: 'transparent'
    }
  },
  menuItemButtonCount: {
    marginLeft: '0.5rem',
    color: '#858E98'
  },
  menuItemButtonNotifications: {
    extend: 'menuItemButtonCount',
    marginLeft: '1rem'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 2rem)',
    margin: '1rem',
    padding: '1rem',
    backgroundColor: theme.colors.page.contentBackground,
    borderRadius: 3
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  cardEmail: {
    fontSize: '0.625rem',
    fontWeight: 'bold',
    color: theme.colors.grey
  },
  cardContent: {},
  cardName: {
    margin: 0,
    marginTop: '1rem',
    fontSize: '1.125rem',
    fontWeight: 'bold',
    color: '#000'
  },
  cardFooter: {
    extend: 'cardHeader',
    marginTop: '0.5rem'
  },
  buttons: {
    display: 'flex'
  },
  button: {
    '&:not(:last-child)': {
      marginRight: '5px'
    }
  },
  userSettingsButton: {
    extend: 'button',
    margin: -theme.spacing.unit * 1.5
  },
  notificationsButtonLabel: {
    extend: 'menuButtonLabel',
    width: 18,
    height: 18
  },
  notificationsButtonBadge: {
    top: 0
  },
  referenceRow: {
    ...theme.utils.flexContainer({
      direction: 'row',
      alignItems: 'center',
      justify: 'space-between'
    }),
    marginTop: '0.5rem',
    width: '100%',
    padding: '1rem',
    boxSizing: 'border-box'
  },
  reference: {
    color: theme.colors.red,
    fontSize: '0.65rem',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textDecoration: 'none'
  },
  settingsNavbar: {
    margin: 'auto'
  },
  centerButton: {
    margin: 'auto'
  },
  changeRoutes: {
    marginLeft: '1.25rem',
    extend: 'menuButtonLabel',
    marginRight: 20
  }
})

export default styles

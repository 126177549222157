import BuildingIcon from 'assets/images/sprite/sidemenu/building.svg'
import BuildingActiveIcon from 'assets/images/sprite/sidemenu/buildingActive.svg'
import CatalogItemsIcon from 'assets/images/sprite/sidemenu/catalogItems.svg'
import CatalogItemsActiveIcon from 'assets/images/sprite/sidemenu/catalogItemsActive.svg'
import CubsIcon from 'assets/images/sprite/sidemenu/cubs.svg'
import CubsActiveIcon from 'assets/images/sprite/sidemenu/cubsActive.svg'
import DesktopIcon from 'assets/images/sprite/sidemenu/desktop.svg'
import DesktopActiveIcon from 'assets/images/sprite/sidemenu/desktopActive.svg'
import ElementsIcon from 'assets/images/sprite/sidemenu/elementsIcon.svg'
import ElementsIconActive from 'assets/images/sprite/sidemenu/elementsIconActive.svg'
import FormIcon from 'assets/images/sprite/sidemenu/forms.svg'
import FormActiveIcon from 'assets/images/sprite/sidemenu/formsActive.svg'
import IncidentsIcon from 'assets/images/sprite/sidemenu/incidents.svg'
import IncidentsActiveIcon from 'assets/images/sprite/sidemenu/incidentsActive.svg'
import MapIcon from 'assets/images/sprite/sidemenu/map.svg'
import MapActiveIcon from 'assets/images/sprite/sidemenu/mapActive.svg'
import MessagesIcon from 'assets/images/sprite/sidemenu/messages.svg'
import MessagesActiveIcon from 'assets/images/sprite/sidemenu/messagesActive.svg'
import NewsIcon from 'assets/images/sprite/sidemenu/news.svg'
import NewsActiveIcon from 'assets/images/sprite/sidemenu/newsActive.svg'
import OtherServicesIcon from 'assets/images/sprite/sidemenu/other_services.svg'
import OtherServicesActiveIcon from 'assets/images/sprite/sidemenu/other_servicesActive.svg'
import PanelsIcon from 'assets/images/sprite/sidemenu/panels.svg'
import PanelsActiveIcon from 'assets/images/sprite/sidemenu/panelsActive.svg'
import ReferenceIcon from 'assets/images/sprite/sidemenu/reference.svg'
import ReferenceActiveIcon from 'assets/images/sprite/sidemenu/referenceActive.svg'
import SearchSidemenuIcon from 'assets/images/sprite/sidemenu/search_sidemenu.svg'
import SettingsIcon from 'assets/images/sprite/sidemenu/settings.svg'
import SettingsActiveIcon from 'assets/images/sprite/sidemenu/settingsActive.svg'
import UsersIcon from 'assets/images/sprite/sidemenu/users.svg'
import UsersActiveIcon from 'assets/images/sprite/sidemenu/usersActive.svg'
import WidgetsIcon from 'assets/images/sprite/sidemenu/widgetsIcon.svg'
import WidgetsIconActive from 'assets/images/sprite/sidemenu/widgetsIconActive.svg'
import { ACL } from 'lib/utils/aclMask'
import React from 'react'
import {
  DASHBOARD_URBAN_BLOCK_ROUTE,
  DESKTOP_ROUTE,
  URBAN_BLOCK_BUILDING_ROUTE,
  URBAN_BLOCK_COMMITTEE_ROUTE,
  URBAN_BLOCK_CONSTRUCTION_ROUTE,
  URBAN_BLOCK_ROUTE
} from 'src/lib/constants/routing'
import { Navigate, RouterProps } from 'react-router-dom'

export interface Route {
  key?: string
  path: string
  creationPath?: string
  editorPath?: string
  exact?: boolean
  lazy?: any
  element?: any
  render?: (props: RouterProps) => React.ReactNode
  sidemenu?: boolean
  additionalSidemenu?: boolean
  subsidemenu?: boolean
  forHead?: boolean
  settingsNavbar?: boolean
  icon?: { id: string }
  activeIcon?: { id: string }
  aclKey?: string
  withoutAcl?: boolean
  withAuth?: boolean
  label?: string
}

const adminRoutesArray: Array<Route> = [
  {
    path: '/journal',
    lazy: async () => { const c = await import('./pages/closed/SettingsJournal'); return { Component: c.default }},
    sidemenu: true,
    icon: SettingsIcon,
    activeIcon: SettingsActiveIcon,
    aclKey: ACL.JOURNAL_READ,
    withAuth: true,
    label: 'Журнал действий'
  },
  {
    path: '/users',
    lazy: async () => { const c = await import('./pages/closed/UsersList'); return { Component: c.default }},
    icon: UsersIcon,
    activeIcon: UsersActiveIcon,
    aclKey: ACL.USERS_WRITE,
    withAuth: true,
    sidemenu: true,
    label: 'Пользователи'
  },
  {
    path: '/news',
    creationPath: '/news/new',
    lazy: async () => { const c = await import('./pages/closed/NewsList'); return { Component: c.default }},
    icon: NewsIcon,
    activeIcon: NewsActiveIcon,
    aclKey: ACL.NEWS_READ,
    withAuth: true,
    sidemenu: true,
    exact: true,
    label: 'Новости'
  },
  {
    path: '/news/:elementID',
    lazy: async () => { const c = await import('./pages/closed/NewsEditor'); return { Component: c.default }},
    aclKey: ACL.NEWS_WRITE,
    withAuth: true
  },
  {
    path: '/scdata/new',
    lazy: async () => { const c = await import('./pages/closed/DataEdit'); return { Component: c.default }},
    aclKey: ACL.SCHEMA_WRITE,
    withAuth: true
  },
  {
    path: '/scdata/:cubeId',
    lazy: async () => { const c = await import('./pages/closed/DataEdit'); return { Component: c.default }},
    aclKey: ACL.SCHEMA_WRITE,
    withAuth: true
  },
  {
    path: '/scdata',
    lazy: async () => { const c = await import('./pages/closed/Data'); return { Component: c.default }},
    aclKey: ACL.SCHEMA_WRITE,
    withAuth: true,
    sidemenu: true,
    icon: CubsIcon,
    activeIcon: CubsActiveIcon,
    label: 'Данные'
  },
  {
    path: '/catalog_items',
    creationPath: '/catalog_items/new',
    lazy: async () => { const c = await import('./pages/closed/CatalogItems'); return { Component: c.default }},
    aclKey: ACL.CATALOG_ITEMS_READ,
    withAuth: true,
    sidemenu: true,
    icon: CatalogItemsIcon,
    activeIcon: CatalogItemsActiveIcon,
    exact: true,
    label: 'Справочники'
  },
  {
    path: '/catalog_items/:elementID',
    lazy: async () => { const c = await import('./pages/closed/CatalogItemsEdit'); return { Component: c.default }},
    aclKey: ACL.CATALOG_ITEMS_READ,
    withAuth: true
  },
  {
    path: '/construction_objects',
    lazy: async () => { const c = await import('./pages/closed/ConstructionObjects'); return { Component: c.default }},
    sidemenu: true,
    exact: true,
    aclKey: ACL.ELEMENTS_READ,
    icon: BuildingIcon,
    activeIcon: BuildingActiveIcon,
    withAuth: true,
    label: 'Объекты строительства'
  },
  {
    path: '/construction_objects/new',
    lazy: async () => { const c = await import('./pages/closed/ConstructionObjectsEdit'); return { Component: c.default }},
    aclKey: ACL.ELEMENTS_READ,
    withAuth: true
  },
  {
    path: '/construction_objects/:elementID',
    lazy: async () => { const c = await import('./pages/closed/ConstructionObjectsEdit'); return { Component: c.default }},
    aclKey: ACL.ELEMENTS_READ,
    withAuth: true
  }
]
const routesArray: Array<Route> = [
  {
    path: '/desktop/spk_building',
    lazy: async () => { const c = await import('./pages/closed/Spk'); return { Component: c.default }},
    settingsNavbar: false,
    exact: true
  },
  {
    path: '/desktop/zhkh_building',
    lazy: async () => { const c = await import('./pages/closed/Spk'); return { Component: c.default }},
    settingsNavbar: false,
    exact: true
  },
  {
    path: '/desktop/kzr',
    lazy: async () => { const c = await import('./pages/closed/KzrPage'); return { Component: c.default }},
    exact: true,
    withAuth: true
  },
  {
    path: DASHBOARD_URBAN_BLOCK_ROUTE,
    lazy: async () => { const c = await import('./pages/closed/UrbanBlockDashboard'); return { Component: c.default }},
    exact: true,
    withAuth: true
  },
  {
    path: URBAN_BLOCK_COMMITTEE_ROUTE,
    lazy: async () => { const c = await import('./pages/closed/UrbanBlockCommittee'); return { Component: c.default }},
    exact: true,
    withAuth: true
  },
  {
    path: URBAN_BLOCK_ROUTE,
    lazy: async () => { const c = await import('./pages/closed/UrbanBlockPage'); return { Component: c.default }},
    exact: true,
    withAuth: true
  },
  {
    path: `${URBAN_BLOCK_BUILDING_ROUTE}/:id`,
    lazy: async () => { const c = await import('./pages/closed/UrbanBlockBuildingView'); return { Component: c.default }},
    exact: true,
    withAuth: true
  },
  {
    path: URBAN_BLOCK_CONSTRUCTION_ROUTE,
    lazy: async () => { const c = await import('./pages/closed/UrbanBlockConstructionPage'); return { Component: c.default }},
    exact: true,
    withAuth: true
  },
  {
    path: '/msp_performance',
    lazy: async () => { const c = await import('./pages/closed/MspPerformance'); return { Component: c.default }},
    exact: true,
    withAuth: true
  },
  {
    path: '/msp_performance/:mspType',
    lazy: async () => { const c = await import('./pages/closed/MspPerformanceDetails'); return { Component: c.default }},
    exact: true,
    withAuth: true
  },
  {
    path: `${DESKTOP_ROUTE}/editor`,
    lazy: async () => { const c = await import('./pages/closed/DesktopEditor'); return { Component: c.default }},
    aclKey: ACL.DESKTOP_WRITE,
    withAuth: true
  },
  {
    path: DESKTOP_ROUTE,
    editorPath: `${DESKTOP_ROUTE}/editor`,
    lazy: async () => { const c = await import('./pages/closed/DesktopView'); return { Component: c.default }},
    sidemenu: true,
    forHead: true,
    icon: DesktopIcon,
    activeIcon: DesktopActiveIcon,
    aclKey: ACL.DESKTOP_READ,
    withAuth: true,
    exact: true,
    label: 'Рабочий стол'
  },
  {
    path: '/desktop/schedule',
    lazy: async () => { const c = await import('./pages/closed/Schedule'); return { Component: c.default }},
    aclKey: ACL.EVENTS_READ,
    withAuth: true,
    exact: true
  },
  {
    path: '/desktop/staff',
    lazy: async () => { const c = await import('./pages/closed/Staff'); return { Component: c.default }},
    aclKey: ACL.KADRI_READ,
    withAuth: true,
    exact: true
  },
  {
    path: '/desktop/spk_building/:id',
    lazy: async () => { const c = await import('./pages/closed/SpkBuildingView'); return { Component: c.default }},
  },
  {
    path: '/desktop/zhkh_building/:id',
    lazy: async () => { const c = await import('./pages/closed/SpkBuildingView'); return { Component: c.default }},
  },
  {
    path: '/desktop/:elementID',
    lazy: async () => { const c = await import('./pages/closed/DesktopButton'); return { Component: c.default }},
    aclKey: ACL.DESKTOP_READ,
    withAuth: true,
    exact: true
  },
  {
    path: '/panels/new',
    lazy: async () => { const c = await import('./pages/closed/PanelEditor'); return { Component: c.default }},
    aclKey: ACL.PANELS_WRITE,
    withAuth: true
  },
  {
    path: '/panels/:elementID/view',
    lazy: async () => { const c = await import('./pages/closed/PanelView'); return { Component: c.default }},
    aclKey: ACL.PANELS_READ,
    withAuth: true,
    exact: true
  },
  {
    path: '/panels/:elementID',
    lazy: async () => { const c = await import('./pages/closed/PanelEditor'); return { Component: c.default }},
    aclKey: ACL.PANELS_WRITE,
    withAuth: true
  },
  {
    path: '/panels',
    creationPath: '/panels/new',
    lazy: async () => { const c = await import('./pages/closed/PanelsList'); return { Component: c.default }},
    sidemenu: true,
    additionalSidemenu: true,
    icon: PanelsIcon,
    activeIcon: PanelsActiveIcon,
    aclKey: ACL.PANELS_READ,
    withAuth: true,
    label: 'Панели'
  },
  {
    path: '/info_references/new',
    lazy: async () => { const c = await import('./pages/closed/InfoReferenceEditor'); return { Component: c.default }},
    aclKey: ACL.INFO_REFERENCES_WRITE,
    withAuth: true,
    exact: true
  },
  {
    path: '/info_references/:infoReferenceID',
    lazy: async () => { const c = await import('./pages/closed/InfoReferenceEditor'); return { Component: c.default }},
    aclKey: ACL.INFO_REFERENCES_WRITE,
    withAuth: true,
    exact: true
  },
  {
    path: '/info_references',
    creationPath: '/info_references/new',
    lazy: async () => { const c = await import('./pages/closed/InfoReferencesList'); return { Component: c.default }},
    sidemenu: true,
    icon: ReferenceIcon,
    activeIcon: ReferenceActiveIcon,
    aclKey: ACL.INFO_REFERENCES_READ,
    withAuth: true,
    label: 'Справки'
  },
  {
    path: '/forms/new',
    lazy: async () => { const c = await import('./pages/closed/FormsEditor'); return { Component: c.default }},
    aclKey: ACL.FORMS_WRITE,
    withAuth: true
  },
  {
    path: '/forms/editor',
    lazy: async () => { const c = await import('./pages/closed/FormsEditor'); return { Component: c.default }},
    aclKey: ACL.FORMS_WRITE,
    withAuth: true
  },
  {
    path: '/forms/:id',
    lazy: async () => { const c = await import('./pages/closed/FormView'); return { Component: c.default }},
    aclKey: ACL.FORMS_READ,
    withAuth: true
  },
  {
    path: '/forms',
    creationPath: '/forms/new',
    lazy: async () => { const c = await import('./pages/closed/FormsList'); return { Component: c.default }},
    aclKey: ACL.FORMS_READ,
    sidemenu: true,
    icon: FormIcon,
    activeIcon: FormActiveIcon,
    withAuth: true,
    label: 'Формы'
  },
  {
    path: '/elements/new',
    lazy: async () => { const c = await import('./pages/closed/ElementEditor'); return { Component: c.default }},
    aclKey: ACL.ELEMENTS_WRITE,
    withAuth: true,
    exact: true
  },
  {
    path: '/elements/:elementID',
    lazy: async () => { const c = await import('./pages/closed/ElementEditor'); return { Component: c.default }},
    aclKey: ACL.ELEMENTS_WRITE,
    withAuth: true,
    exact: true
  },
  {
    path: '/elements/:elementID/view',
    lazy: async () => { const c = await import('./pages/closed/ElementView'); return { Component: c.default }},
    aclKey: ACL.ELEMENTS_READ,
    withAuth: true,
    exact: true
  },
  {
    path: '/elements',
    lazy: async () => { const c = await import('./pages/closed/ElementsList'); return { Component: c.default }},
    icon: ElementsIcon,
    activeIcon: ElementsIconActive,
    aclKey: ACL.ELEMENTS_READ,
    withAuth: true,
    additionalSidemenu: true,
    label: 'Элементы'
  },
  {
    path: '/messages',
    creationPath: '/messages/new',
    lazy: async () => { const c = await import('./pages/closed/Messages'); return { Component: c.default }},
    sidemenu: true,
    icon: MessagesIcon,
    activeIcon: MessagesActiveIcon,
    aclKey: ACL.MESSAGES_READ,
    withAuth: true,
    exact: true,
    label: 'Сообщения'
  },
  {
    path: '/messages/new',
    lazy: async () => { const c = await import('./pages/closed/MessageEditor'); return { Component: c.default }},
    aclKey: ACL.MESSAGES_WRITE,
    withAuth: true
  },
  {
    path: '/messages/:id',
    lazy: async () => { const c = await import('./pages/closed/Messages'); return { Component: c.default }},
    aclKey: ACL.MESSAGES_READ,
    withAuth: true
  },
  // Incidents
  {
    path: '/incidents/new',
    lazy: async () => { const c = await import('./pages/closed/IncidentsEditor'); return { Component: c.default }},
    aclKey: ACL.INCIDENTS_WRITE,
    icon: IncidentsIcon,
    activeIcon: IncidentsActiveIcon,
    withAuth: true,
    exact: true
  },
  {
    path: '/incidents/:incidentID',
    lazy: async () => { const c = await import('./pages/closed/IncidentsEditor'); return { Component: c.default }},
    aclKey: ACL.INCIDENTS_READ,
    withAuth: true,
    exact: true
  },
  {
    path: '/incidents',
    lazy: async () => { const c = await import('./pages/closed/IncidentsList'); return { Component: c.default }},
    sidemenu: true,
    aclKey: ACL.INCIDENTS_READ,
    icon: IncidentsIcon,
    activeIcon: IncidentsActiveIcon,
    withAuth: true,
    label: 'Инциденты'
  },
  {
    path: '/map',
    lazy: async () => { const c = await import('./pages/closed/Map'); return { Component: c.default }},
    icon: MapIcon,
    activeIcon: MapActiveIcon,
    aclKey: ACL.MAP_READ,
    withAuth: true,
    sidemenu: true,
    forHead: true,
    label: 'Карта'
  },
  {
    path: '/search',
    lazy: async () => { const c = await import('./pages/closed/Search'); return { Component: c.default }},
    icon: SearchSidemenuIcon,
    activeIcon: SearchSidemenuIcon,
    aclKey: ACL.SEARCH_READ,
    withAuth: true
  },
  {
    path: '/other_services',
    lazy: async () => { const c = await import('./pages/closed/OtherServices'); return { Component: c.default }},
    sidemenu: true,
    icon: OtherServicesIcon,
    activeIcon: OtherServicesActiveIcon,
    aclKey: ACL.OTHER_SERVICES_READ,
    forHead: true,
    withAuth: true,
    label: 'Другие сервисы'
  },
  {
    path: '/widgets/new',
    lazy: async () => { const c = await import('./pages/closed/WidgetEditor'); return { Component: c.default }},
    aclKey: ACL.WIDGETS_WRITE,
    withAuth: true,
    exact: true
  },
  {
    path: '/widgets/:widgetID',
    lazy: async () => { const c = await import('./pages/closed/WidgetEditor'); return { Component: c.default }},
    aclKey: ACL.WIDGETS_WRITE,
    withAuth: true,
    exact: true
  },
  {
    path: '/widgets',
    creationPath: '/widgets/new',
    lazy: async () => { const c = await import('./pages/closed/WidgetsList'); return { Component: c.default }},
    icon: WidgetsIcon,
    activeIcon: WidgetsIconActive,
    aclKey: ACL.WIDGETS_READ,
    withAuth: true,
    additionalSidemenu: true,
    label: 'Виджеты'
  },
  {
    path: '/login',
    lazy: async () => { const c = await import('./pages/closed/Login'); return { Component: c.default }},
  },
  {
    path: '',
    element: <Navigate replace to="/login" />,
  },
  {
    path: '*',
    element: <Navigate replace to="/login" />,
  },
  ...adminRoutesArray,
]

const allowedToHeadRoutes = routesArray.filter(entity => !!entity.forHead)
const sidemenuRoutes = routesArray.filter(entity => entity.sidemenu)
const additionalSideMenuRoutes = routesArray.filter(entity => entity.additionalSidemenu)
const settingsNavbarRoutes = routesArray.filter(entity => entity.settingsNavbar)

export {
  routesArray as default,
  sidemenuRoutes,
  additionalSideMenuRoutes,
  settingsNavbarRoutes,
  allowedToHeadRoutes
}


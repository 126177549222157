const styles = (theme: App.Theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.colors.white,
    borderRadius: '4px',
    overflow: 'hidden'
  },
  header: {
    display: 'flex',
    width: '100%',
    padding: '0.9375rem',
    background: theme.colors.border
  },
  title: {
    fontSize: '1rem',
    fontWeight: 600,
    margin: 0
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '15px 0 0 0',
    padding: 0,
    listStyleType: 'none'
  },
  listItem: {
    display: 'flex',
    width: '100%',
    margin: 0
  },
  addButtonContainer: {
    display: 'flex',
    width: '100%',
    padding: '0 15px'
  },
  addButton: {},
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    padding: '15px'
  },
  footerButton: {
    '&:not(:last-child)': {
      marginRight: '15px'
    }
  }
})

export { styles as default }

export enum Types {
  RESET_PREVIEW = 'RESET_PREVIEW',
  // From db or file
  SELECT_PREVIEW_SOURCE_TYPE = 'SELECT_PREVIEW_SOURCE_TYPE',
  // selects expands source in ui
  SELECT_DATA_SOURCE_TABLE = 'SELECT_SOURCE_TABLE',
  CHANGE_COLUMN_PARAMS = 'CHANGE_COLUMN_PARAMS',
  CHANGE_DATA_CELL = 'CHANGE_DATA_CELL',
  TOGGLE_PREVIEW_COLUMN_INCLUDE = 'TOGGLE_PREVIEW_COLUMN_INCLUDE',
  ADD_COLUMN = 'ADD_COLUMN',
  CLEAR_COLUMN = 'CLEAR_COLUMN',
  REMOVE_COLUMN = 'REMOVE_COLUMN',
  ADD_CELL = 'ADD_CELL',
  ADD_DATA = 'ADD_DATA',
  ADD_DATA_SUCCESS = 'ADD_DATA_SUCCESS',
  // reading preview
  READ_PREVIEW = 'READ_PREVIEW',
  READ_PREVIEW_CUBE = 'READ_PREVIEW_CUBE',
  READ_PREVIEW_FILE = 'READ_PREVIEW_FILE',
  READ_PREVIEW_SUCCESS = 'READ_PREVIEW_SUCCESS',
  READ_PREVIEW_FAILURE = 'READ_PREVIEW_FAILURE',
  START_LOADING = 'START_LOADING',
  STOP_LOADING = 'STOP_LOADING'
}

import { takeEvery, put, select, call, all } from 'redux-saga/effects'
import { AxiosError } from 'axios'

import fetchAPI from 'lib/services/fetchAPI'
import { tokenSelector } from 'lib/modules/user/selectors'
import { METHOD, ENDPOINT } from 'constants/api'
import { ReadApkbgs, readApkbgsLoading, readApkbgsFailure, readApkbgsSuccess } from './actions'
import types from './types'

function* readApkbgs(action: ReadApkbgs) {
  const token = yield select(tokenSelector)

  try {
    yield put(readApkbgsLoading())
    const { data }: { data: Data.Apkbg[] } = yield call(fetchAPI, {
      // token,
      method: METHOD.GET,
      endpoint: ENDPOINT.APKBG
    })
    yield put(readApkbgsSuccess(data))
  } catch (error) {
    console.error(error)

    yield put(readApkbgsFailure())
  }
}

export function* watcher() {
  yield all([takeEvery(types.READ_APKBGS, readApkbgs)])
}

import { takeEvery, call, put, all, select } from 'redux-saga/effects'
import { error as notifyError } from 'react-notification-system-redux'

import fetchAPI from 'lib/services/fetchAPI'
import {
  ReadMsp,
  readMsp as readMspAction,
  readMspLoading,
  readMspFailure,
  readMspSuccess,
  UpdateMsp,
  updateMspLoading,
  updateMspSuccess,
  updateMspFailure
} from './actions'
import { Types } from './actionTypes'
import { ENDPOINT, METHOD } from 'constants/api'
import { tokenSelector } from 'lib/modules/user/selectors'
import { getMspCubeId } from 'lib/modules/msp/selectors'

function* readMsp(action: ReadMsp) {
  try {
    const token = yield select(tokenSelector)
    yield put(readMspLoading())
    const {
      data: { data, cube_id }
    }: { data: { data: Data.MspCubeItem[]; cube_id: number } } = yield call(fetchAPI, {
      token,
      method: METHOD.GET,
      endpoint: ENDPOINT.MSP
    })
    yield put(readMspSuccess({ data, cube_id }))
  } catch (error) {
    console.error(error)

    yield put(readMspFailure())
    yield put(
      notifyError({
        title: 'Не удалось получить данные.'
      })
    )
  }
}

function* updateMsp(action: UpdateMsp) {
  const file = action.payload
  const formData = new FormData()
  formData.append('file', file)
  try {
    const token = yield select(tokenSelector)
    const cubeId = yield select(getMspCubeId)
    yield put(updateMspLoading())
    const {
      data: { data }
    }: { data: { data: Data.MspCubeItem[] } } = yield call(fetchAPI, {
      token,
      method: METHOD.POST,
      endpoint: ENDPOINT.CUBES,
      path: `${cubeId}/add_data_from_file`,
      body: formData
    })
    yield put(updateMspSuccess(data))
    yield put(readMspAction())
  } catch (error) {
    console.error(error)

    yield put(updateMspFailure())
    yield put(
      notifyError({
        title: 'Не удалось обновить данные.'
      })
    )
  }
}

export function* watcher() {
  yield all([takeEvery(Types.READ_MSP, readMsp), takeEvery(Types.UPDATE_MSP, updateMsp)])
}

export const colors = {
  blue: '#6FA9DE',
  newPrimaryBlue: '#8BD0F6',
  lightBlue: '#EDF3FF',
  sky: '#DAE8F3',
  deepBlue: '#1659E1',
  red: '#FF3B30',
  redSecondary: '#E35607',
  redBadge: '#DA6363',
  darkRed: '#D60B00',
  yellow: '#D8C309',
  yellowSecondary: '#FCEBD2',
  orange: '#F5A623',
  orangeSecondary: '#EC9B22',
  green: '#009C56',
  greenSecondary: '#0CCF08',
  greenTertiary: '#89C978',
  lightGreen: '#2DC76D',
  purple: '#8738CE',
  grey: '#B2B2B2',
  greySecondary: '#E8ECF2',
  lightGrey: '#C5CED9',
  lightGreySecondary: '#F5F7FA',
  greyBorder: '#EBEBEB',
  darkGrey: '#E0E6EB',
  mainBackground: '#F6F7FA',
  white: '#FFFFFF',
  black: '#000000',
  backgroundBlue: '#EDF8FF',
  salat: '#29C0A5',
  background: {
    main: '#F6F7FA',
    darkGrey: '#E0E6EB',
    white: '#fff',
    red: '#D73C3C',
    h5Red: '#F4E7E7',
    blue: '#6FA9DE',
    darkBlue: '#1B80C4',
    h5Orange: '#FFF5E5',
    h5Green: '#DFFAD8',
    leafletGray: '#5C6D86',
    transparent: 'transparent',
    leafletMarkerGreen: '#88C379',
    gray: '#F6F7FA',
  },
  table: {
    header: '#4395C4',
    evenBody: '#EEF8FF',
    oddBody: '#FDFEFF'
  },
  text: {
    default: '#56687A',
    signature: 'rgba(64, 79, 94, 0.6)',
    primary: '#277EE4',
    secondary: '#858E98',
    signatureBlue: '#1B80C4',
    selectedBlue: '#7DCBFF',
    orange: '#EB9A23',
    orangeLight: '#FFBA6E',
    lightRed: '#D77070',
    lightGreen: '#6AC623',
    h4Green: '#65C41B',
    h5Green: '#89C978',
    leafletMarkerGreen: '#88C379',
    h5Red: '#E26464',
    gray: '#404F5E99',
    h5Orange: '#EB9A23',
    white: '#FFFFFF',
    header: '#404F5E',
    black: '#000'
  },
  toggle: {
    borderColor: '#D8E0E5'
  },
  border: '#F7F7F7',
  hr: '#EEEEEE',
  page: {
    background: '#F3F3F3',
    contentBackground: '#FAFAFA',
    secondaryBackground: '#F3F7FB'
  },
  icon: {
    border: '#E9E9E9'
  },
  leaflet: {
    legend: {
      green: 'rgb(132, 185, 119)',
      orange: 'rgb(255, 162, 94)',
      red: 'rgb(255, 122, 122)'
    },
    zones: {
      orange: '#FFA25E',
      orangeLight: '#ffc04d',
      red: '#FF7A7A',
      redLight: '#ff5252',
      green: '#84B977',
      greenLight: '#88ff5f'
    }
  },
  sidebar: {
    activeItemFlag: '#4d85f7',
    icon: {
      active: {
        circleFill: '#2C538C',
        circleStroke: 'none',
        iconFill: '#FFFFFF',
        iconStroke: '#FFFFFF'
      },
      inactive: {
        circleFill: 'none',
        circleStroke: '#000000',
        iconFill: '#000000',
        iconStroke: '#000000'
      }
    }
  },
  button: {
    iconBorder: 'rgba(184, 184, 184, 0.3)',
    deleteButton: '#F1DFDF',
    downloadButton: '#277EE4',
    default: {
      backgroundColor: '#E8ECF2',
      color: '#404F5E',
      borderColor: '#E8ECF2'
    },
    primary: {
      backgroundColor: '#277EE4',
      color: '#FFFFFF',
      borderColor: '#277EE4'
    },
    secondary: {
      backgroundColor: '#E8ECF2',
      color: '#404F5E',
      borderColor: '#E8ECF2'
    },
    danger: {
      backgroundColor: '#F1DFDF',
      color: '#E26464',
      borderColor: '#F1DFDF',
      iconColor: '#D77070'
    },
    transparent: {
      backgroundColor: 'transparent',
      color: '#000',
      borderColor: 'rgba(184, 184, 184, 0.3)'
    }
  },
  schema: {
    cube: '#D7E0EC',
    background: '#F3F7FB'
  },
  input: {
    border: '#EBEBEB'
  },
  users: {
    rowBorder: '#EBEBEB'
  },
  scanner: {
    chart: {
      positive: '#FF3B30',
      negative: '#00E17E'
    }
  },
  toggleField: {
    active: '#2DC76D',
    inactive: '#B8B8B8'
  },
  aggregation: {
    measure: '#DEF4EA',
    measureActive: '#BEF1D8',
    dimension: '#E6ECF3',
    dimensionActive: '#CEF9F0',
    group: '#F8F2EB',
    groupActive: '#FF9500'
  },
  set: {
    dimension: '#E6ECF3',
    measure: '#DEF4EA'
  },
  journal: {
    levels: {
      info: '#2C538C',
      warning: '#F34723',
      success: '#25AF5F'
    }
  },
  modal: {
    header: {
      background: '#F2F5F6'
    }
  },
  charts: {
    gradient: {
      purple: ['rgba(132, 27, 196, 0.7)', 'rgba(136, 33, 235, 0.28)'],
      purpleLight: ['rgba(94, 28, 235, 0.7)', 'rgba(71, 27, 196, 0.28)'],
      pink: ['rgba(235, 33, 130, 0.7)', 'rgba(196, 27, 149, 0.28)'],
      red: ['rgba(168, 3, 3, 0.7)', 'rgba(196, 27, 27, 0.322)'],
      blue: ['rgba(28, 152, 235, 0.7)', 'rgba(27, 128, 196, 0.28)'],
      green: ['rgba(3, 168, 128, 0.7)', 'rgba(27, 196, 95, 0.32)'],
      greenLight: ['rgba(144, 235, 28, 0.7)', 'rgba(142, 196, 27, 0.28)'],
      greenSalat: ['rgba(145, 168, 3, 0.7)', 'rgba(27, 196, 95, 0.322)'],
      yellow: ['rgba(235, 190, 33, 0.7)', 'rgba(196, 128, 27, 0.28)']
    },
    gray: '#C3C3C3',
    green: '#4D9789',
    legend: {
      purple: '#9747FF',
      blue: '#1B80C4'
    },
    pieCategory: {
      green: '#89C978',
      blue: '#66B1F9',
      purple: '#792BBF',
      orange: '#F9A831',
      red: '#DA3939'
    },
    lineChart: {
      purple: '#841BC4',
      blue: '#66B1F9',
      greenSalat: '#29C0A5',
      orange: '#F9A831',
      red: '#DA3939',
      green: '#89C978'
    },
    columnChart: {
      purple: '#841BC4',
      purpleLight: '#602CCF',
      purpleDark: '#41159F',
      blueDark: '#4151E8',
      blue: '#39B0FF'
    },
    palette: [
      '#1465F9',
      '#FD8D21',
      '#C700F9',
      '#18BAD7',
      '#FB2B3A',
      '#B2B2B2',
      '#0A99AF',
      '#957B8D',
      '#EB5C62',
      '#74154F',
      '#037D90',
      '#0052A1',
      '#93C573',
      '#FAB510',
      '#575756',
      '#EF7B85',
      '#C91782',
      '#6AC4CB',
      '#BDDAA5',
      '#FED06C',
      '#E7393E',
      '#D04592',
      '#026267',
      '#69B42D',
      '#309362',
      '#9D9D9C'
    ]
  }
}

import { AxiosError } from 'axios'

export default function* defaultAPIAction({
  request,
  success,
  fail
}: {
  request: () => Iterator<any>
  success: (data) => void
  fail: (errors) => void
}) {
  try {
    const { data } = yield request()

    yield success(data)
  } catch (exception) {
    let errors = {
      _error: exception
    }

    if (exception.response) {
      errors = (exception as AxiosError).response.data.errors
    }

    yield fail(errors)
  }
}

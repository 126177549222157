import classNames from 'classnames'
import { inRange } from 'lodash'
import React, { ChangeEvent, InputHTMLAttributes, PureComponent } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import { WrappedFieldProps } from 'redux-form'

import styles from './styles'

/**
 * Labeled field
 * @param {boolean} withoutDelay - Remove delay before changing the value
 */
interface OuterProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  contentClassName?: string
  inputClassName?: string
  placeholder?: string
  type?: string
  hideTextError?: boolean
  label?: string
  useTopLabel?: boolean
  withoutDelay?: boolean
  min?: number
  max?: number
  // Fix strange ts error
  component?: any
}
export interface Props extends OuterProps, WrappedFieldProps, JSSProps<typeof styles> {}
interface State {
  value: string
}

class TextInput extends PureComponent<Props, State> {
  prevPropValue: string
  timeout: NodeJS.Timer
  inputRef: HTMLInputElement

  static defaultProps = {
    withoutDelay: false
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      value: props.input.value
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.input.value !== this.props.input.value) {
      this.setState({ value: nextProps.input.value })
    }
  }

  onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { type, min, max } = this.props
    event.persist()

    const {
      input: { onChange },
      withoutDelay
    } = this.props
    const prevValue = this.state.value
    // const value =  event.target.value
    let value
    if (type === 'number') {
      const numberValue = +event.target.value

      if (typeof min !== undefined) {
        if (typeof max !== undefined) {
          if (inRange(numberValue, min, max + 1)) {
            value = numberValue
          } else if (Number(prevValue) > 0 && numberValue === 0) {
            value = undefined
          } else {
            value = this.state.value
          }
        } else {
          if (typeof max !== undefined) {
            value = numberValue <= max ? numberValue : this.state.value
          } else {
            value = numberValue
          }
        }
      }
    } else {
      value = event.target.value
    }

    this.setState({ value })

    if (withoutDelay) {
      onChange(value)
    } else {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      this.timeout = setTimeout(() => onChange(value), 250)
    }
  }

  render() {
    const {
      classes,
      theme,
      inputClassName,
      id,
      className,
      contentClassName,
      label,
      placeholder,
      type = 'text',
      input: { onChange, value, ...input },
      hideTextError = false,
      meta: { error, touched },
      disabled,
      autoComplete,
      min,
      max,
      ...props
    } = this.props

    const numberProps = type === 'number' ? { min, max } : {}

    return (
      <div className={classNames(classes.container, className)}>
        <div className={classNames(classes.content, contentClassName)}>
          {!!label && (
            <label className={classes.label} htmlFor={id || input.name}>
              {label}
            </label>
          )}
          <input
            id={id || input.name}
            ref={el => (this.inputRef = el)}
            type={type}
            placeholder={placeholder}
            className={classNames(classes.input, inputClassName)}
            value={this.state.value}
            disabled={disabled}
            autoComplete={autoComplete}
            onChange={this.onChange}
            onWheel={() => {
              if (type === 'number') {
                this.inputRef.blur()
              }
            }}
            {...numberProps}
            {...input}
          />
        </div>
        {touched && error && !hideTextError && <span className={classes.error}>{error}</span>}
      </div>
    )
  }
}

export default injectStyles<OuterProps>(styles)(TextInput)

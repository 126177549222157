import { takeEvery, call, put, all } from 'redux-saga/effects'
import { error } from 'react-notification-system-redux'

import fetchAPI from 'lib/services/fetchAPI'
import APIAction from 'lib/utils/APIAction'
import getResourceActions from 'lib/utils/getResourceActions'
import { GetZones } from './actions'
import types from './actionTypes'
import { ENDPOINT } from 'constants/api'

function* getZones(action: GetZones) {
  const { requestKey, resourceType, list } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'READ',
    resourceType,
    requestKey,
    list
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        endpoint: ENDPOINT.ZONES
      })
    },
    *success(data) {
      yield put(onSuccessAction(data))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось получить данные.'
        })
      )
    }
  })
}

export function* watcher() {
  yield all([takeEvery(types.GET_ZONES_LIST, getZones)])
}

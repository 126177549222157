import React, {
  PureComponent,
  Fragment,
  ButtonHTMLAttributes,
  MouseEvent,
  ChangeEvent
} from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import { compose } from 'recompose'
import { WrappedFieldProps } from 'redux-form'
import classNames from 'classnames'
import { Props as TooltipProps, Tooltip } from 'react-tooltip'
import Calendar from 'react-calendar'

import decline from 'lib/utils/decline'
import formatFilterDateByType from 'lib/utils/formatFilterDateByType'
import Collapse from 'components/Collapse'
import { Base as BaseSelect } from 'components/Select'
import Range from './components/Range'
import DateMultiSelect from './components/Select'
import DateFormat from './components/DateFormat'
import { DATE_FIELD_TYPES, COMPARE_OPERATORS, DATE_PRESETS } from 'constants/filter'
import styles from './styles'
import { DATE_FORMAT_TYPES, formatDate } from 'src/lib/utils/dateHelper'

interface OuterProps {
  id: string
  dateSelectOptions: Array<Filter.Option>
  withDateSelect: boolean
  field: Filter.Expression
  change: (value: Filter.Expression) => void
  tooltipProps?: Partial<Omit<TooltipProps, 'id'>>
  containerProps?: Partial<ButtonHTMLAttributes<HTMLButtonElement>>
  // Fix strange ts error
  component?: any
}
interface Props extends JSSProps<typeof styles>, OuterProps, WrappedFieldProps {}
interface State {
  activeSection: string
}

class DateValue extends PureComponent<Props, State> {
  buttonRef: HTMLButtonElement

  static defaultProps: Partial<OuterProps> = {
    tooltipProps: { place: 'bottom', effect: 'solid', type: 'light' },
    containerProps: {}
  }

  state = {
    activeSection: DATE_FIELD_TYPES.DYNAMIC
  }

  componentDidMount() {
    const {
      input: { value, onChange },
      field
    } = this.props

    if (!field.field1.date_reg_exp) this.onChangeDateFormat(DATE_PRESETS[0].value)
    if (!value.value) {
      this.onChangeDate(this.state.activeSection)()
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      field,
      input: { onChange },
      change
    } = this.props
    const { field: prevField } = prevProps

    if (
      prevField.condition !== COMPARE_OPERATORS.EQUAL &&
      field.condition === COMPARE_OPERATORS.EQUAL
    ) {
      change({
        ...field,
        field1: { ...field.field1, date_reg_exp: DATE_PRESETS[0].value },
        field2: {
          ...field.field2,
          type: DATE_FIELD_TYPES.MULTI,
          value: formatFilterDateByType({
            type: DATE_FIELD_TYPES.MULTI,
            value: []
          })
        }
      })
      this.setState(prev => ({ activeSection: DATE_FIELD_TYPES.MULTI }))
    } else if (
      prevField.condition === COMPARE_OPERATORS.EQUAL &&
      field.condition !== COMPARE_OPERATORS.EQUAL
    ) {
      change({
        ...field,
        field1: { ...field.field1, date_reg_exp: undefined },
        field2: {
          ...field.field2,
          type: DATE_FIELD_TYPES.DYNAMIC,
          value: formatFilterDateByType({
            type: DATE_FIELD_TYPES.DYNAMIC
          })
        }
      })
      this.setState(prev => ({ activeSection: DATE_FIELD_TYPES.DYNAMIC }))
    }
  }

  formatValue(field_modification: Partial<Filter.DateField['field_modification']> | undefined) {
    const declineMonths = {
      days: ['день', 'дня', 'дней'],
      months: ['месяц', 'месяца', 'месяцев'],
      years: ['год', 'года', 'лет']
    }
    let result = ''

    if (field_modification) {
      const { modification_sym, modification_val, modification_period } = field_modification

      if (modification_sym && modification_val) {
        if (modification_sym === '-') {
          result += modification_sym
        }

        result += modification_val

        if (modification_period) {
          result += ` ${decline(+modification_val, declineMonths[modification_period])}`
        }
      }
    }

    return result
  }

  get formattedPeriodText() {
    const { value } = this.props.input
    const declineMonths = {
      days: ['день', 'дня', 'дней'],
      months: ['месяц', 'месяца', 'месяцев'],
      years: ['год', 'года', 'лет']
    }
    let result = ''

    if (value.field_modification) {
      const { modification_sym, modification_val, modification_period } = value.field_modification

      if (modification_sym && modification_val) {
        if (modification_sym === '-') {
          result += modification_sym
        }

        result += modification_val

        if (modification_period) {
          result += ` ${decline(+modification_val, declineMonths[modification_period])}`
        }
      }
    }

    return result ? `(${result})` : ''
  }

  get formattedValueText() {
    const {
      input: { value },
      dateSelectOptions
    } = this.props

    switch (value.type) {
      case DATE_FIELD_TYPES.DATE:
        return formatDate({ date: value.value, formatType: DATE_FORMAT_TYPES.dayMonthYearSlash })
      case DATE_FIELD_TYPES.FIELD:
        return dateSelectOptions.find(option => option.id === value.value).name
      case DATE_FIELD_TYPES.DYNAMIC:
        return 'Динамическая дата'
    }

    return ''
  }

  get buttonText() {
    return `${this.formattedValueText} ${this.formattedPeriodText}`
  }

  onFocus = event => this.props.input.onFocus(event)

  onBlur = event => {
    const {
      input: { value, onBlur }
    } = this.props

    onBlur(value)
  }

  hideTooltip = () => {
    if (this.buttonRef) Tooltip.hide(this.buttonRef)
  }

  showTooltip = () => {
    if (this.buttonRef) Tooltip.show(this.buttonRef)
  }

  onChangeDate = (type: string) => (
    newValue?: Date | Filter.Option | MouseEvent<HTMLButtonElement> | string[] | void
  ) => {
    this.setState({ activeSection: type })

    const { value, onChange } = this.props.input

    const result: Filter.DateField = {
      ...value,
      type,
      value: formatFilterDateByType({
        type: DATE_FIELD_TYPES.DYNAMIC
      })
    }

    switch (type) {
      case DATE_FIELD_TYPES.DATE:
        result.value = formatFilterDateByType({
          type: DATE_FIELD_TYPES.DATE,
          value: newValue as Date
        })
        break
      case DATE_FIELD_TYPES.FIELD:
        result.value = formatFilterDateByType({
          type: DATE_FIELD_TYPES.FIELD,
          value: (newValue as Filter.Option).id
        })
        break
      case DATE_FIELD_TYPES.MULTI:
        result.value = formatFilterDateByType({
          type: DATE_FIELD_TYPES.MULTI,
          value: newValue ? (newValue as string[]) : []
        })
        break
    }

    onChange(result)
  }

  onChangeSection = (sectionName: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const changeValue = this.onChangeDate(sectionName)

    switch (sectionName) {
      case DATE_FIELD_TYPES.DATE:
        changeValue(new Date())
        break
      case DATE_FIELD_TYPES.DYNAMIC:
        changeValue()
        break
      case DATE_FIELD_TYPES.FIELD:
        changeValue(this.props.dateSelectOptions[0])
        break
    }
  }

  onChangeFieldModification = (field_modification: Filter.DateField['field_modification']) => {
    const {
      input: { value, onChange }
    } = this.props

    onChange({
      ...value,
      field_modification
    })
  }

  onChangeDateFormat = (format?: string) => {
    const {
      input: { value, onChange },
      field,
      change
    } = this.props

    change({ ...field, field1: { ...field.field1, date_reg_exp: format } })
    onChange({ ...value, format })
  }

  render() {
    const {
      classes,
      id,
      input: { value, name },
      meta,
      dateSelectOptions,
      withDateSelect,
      tooltipProps,
      containerProps,
      theme,
      field
    } = this.props
    const { activeSection } = this.state

    return (
      <Fragment>
        {field.condition === COMPARE_OPERATORS.EQUAL ? (
          <>
            <DateFormat
              value={field.field1.date_reg_exp}
              onChange={this.onChangeDateFormat}
              id={`${name}-date-format-tooltip`}
            />
            <DateMultiSelect
              value={value.value}
              field={field}
              change={this.onChangeDate(DATE_FIELD_TYPES.MULTI)}
            />
          </>
        ) : (
          <>
            <Tooltip
              {...tooltipProps}
              id={id}
              events={['click']}
              className={classNames(classes.tooltip, tooltipProps.className)}
            >
              <div className={classes.tooltipContent}>
                <h3 className={classes.title}>Выберите дату</h3>
                <div className={classes.block}>
                  <h4 className={classes.titleSmall}>Дата</h4>
                  <div className={classes.expanded}>
                    <div className={classes.expandedHeader}>
                      <input
                        id={`${name}.date`}
                        name={`${name}.date`}
                        type="radio"
                        className={classes.expandedCheckbox}
                        checked={activeSection === DATE_FIELD_TYPES.DATE}
                        value=""
                        onChange={this.onChangeSection(DATE_FIELD_TYPES.DATE)}
                      />
                      <label htmlFor={`${name}.date`} className={classes.expandedCheckboxLabel}>
                        Точная дата
                      </label>
                    </div>
                    <Collapse isOpen={activeSection === DATE_FIELD_TYPES.DATE}>
                      <Calendar
                        className={classes.calendar}
                        value={
                          value.type === DATE_FIELD_TYPES.DATE ? formatDate({ date: value.value }) : undefined
                        }
                        onClickDay={this.onChangeDate(DATE_FIELD_TYPES.DATE)}
                      />
                    </Collapse>
                  </div>
                  {withDateSelect && (
                    <BaseSelect
                      className={classes.dateSelect}
                      options={dateSelectOptions}
                      labelKey="name"
                      valueKey="id"
                      value={value.type === DATE_FIELD_TYPES.FIELD ? value.value : undefined}
                      placeholder="Выберите дату для сравнения"
                      height="2.875rem"
                      backgroundColor={theme.colors.page.contentBackground}
                      clearable={false}
                      withBackground
                      // @ts-ignore
                      onChange={this.onChangeDate(DATE_FIELD_TYPES.FIELD)}
                    />
                  )}
                  <div className={classes.dynamic}>
                    <input
                      id={`${name}.dynamic`}
                      name={`${name}.dynamic`}
                      type="radio"
                      className={classes.dynamicCheckbox}
                      checked={activeSection === DATE_FIELD_TYPES.DYNAMIC}
                      value=""
                      onChange={this.onChangeSection(DATE_FIELD_TYPES.DYNAMIC)}
                    />
                    <label htmlFor={`${name}.dynamic`} className={classes.dynamicCheckboxLabel}>
                      Динамическая дата
                    </label>
                  </div>
                </div>
                <Range
                  name={`${name}.field_modification`}
                  value={value && (value as Filter.DateField).field_modification}
                  onChangeFieldModification={this.onChangeFieldModification}
                />
              </div>
            </Tooltip>
            <button
              {...containerProps}
              type="button"
              ref={element => (this.buttonRef = element)}
              data-tooltip-id={id}
              data-tip=""
              data-iscapture="true"
              className={classNames(
                classes.box,
                containerProps.className,
                meta.invalid && meta.touched && classes.error
              )}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
            >
              {this.buttonText}
            </button>
          </>
        )}
      </Fragment>
    )
  }
}

export default compose<Props, OuterProps>(injectStyles(styles))(DateValue)

import React, { memo, Component, DetailedHTMLProps, HTMLAttributes } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import { DragSourceHookSpec, useDrag } from 'react-dnd'
import { compose } from 'recompose'
import cn from 'classnames'

import { Value as ValueIcon, Text as TextIcon, Date as DateIcon } from 'components/Icons'
import { TYPE } from 'constants/set'
import { OPTION_TYPES } from 'constants/filter'
import styles from './styles'
import { DragAndDropTypes } from '../../dndTypes'

export interface OuterProps {
  set: Data.Dimension | Data.Measure
  type: TYPE
  getCubeNameById: (cube_id: string) => string
  draggable?: boolean
  classNames?: { icon?: string; container?: string; set?: string }
  background?: string
  containerProps?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
}

interface Props extends JSSProps<typeof styles>, OuterProps {}
export interface ItemProps extends JSSProps<typeof styles>, OuterProps {}
interface State {}

const DRAG_SOURCE = {
  name: DragAndDropTypes.ElementIndicatorItem,
  end(item, monitor) {
    if (!monitor.didDrop()) {
      return
    }
  }
}

const SetItemHOC = memo(({ draggable = true, ...props }: Props) =>
  draggable ? (
    <SetItemWithDND draggable={draggable} {...props} />
  ) : (
    <SetItemWithoutDND draggable={draggable} {...props} />
  )
)

const AggregationSetComponent = (props) => {
  const {
    classes,
    set,
    classNames,
    type,
    containerProps,
    getCubeNameById,
    icon,
  } = props
  const optionalDesc = (set as Data.Dimension).desc

  const [collected, drag, dragPreview] = useDrag({
    type: DRAG_SOURCE.name,
    end: DRAG_SOURCE.end,
    item: { set, type }
  })

  let properies = { ref: null }

  if ((collected as any).isDragging) properies.ref = dragPreview
  else {
    properies.ref = drag
    properies = { ...properies, ...(collected as any) }
  }

  return (
    <div
      {...properies}
      {...containerProps}
      className={cn(classes.container, classNames.container, containerProps.className)}
      title={optionalDesc || undefined}
      {...props}
    >
      {icon}
      <div className={classes.sets}>
        {getCubeNameById && (
          <span className={classes.cubeName}>{getCubeNameById(set.cube_id)}</span>
        )}
        <span className={classNames.set}>{set.name}</span>
      </div>
    </div>
  )
}

class AggregationSet extends Component<Props, State> {
  static defaultProps: Partial<OuterProps> = {
    classNames: {},
    containerProps: {}
  }

  get icon() {
    const { classNames, classes, set } = this.props

    let IconComponent = TextIcon

    if (set.filter_type) {
      switch (set.filter_type) {
        case OPTION_TYPES.TIME:
          IconComponent = DateIcon
          break
        case OPTION_TYPES.NUMBER:
          IconComponent = ValueIcon
          break
      }
    }

    return <IconComponent className={cn(classes.icon, classNames.icon)} />
  }

  render() {
    return <AggregationSetComponent {...this.props} icon={this.icon} />
  }
}

const SetItemWithDND = compose<Props, OuterProps>(
)(AggregationSet)

const SetItemWithoutDND = AggregationSet

export default compose<Props, OuterProps>(injectStyles(styles))(SetItemHOC)

import { Action } from 'redux'

import { Types, SortField, SORT_DIRECTION } from './'

export type Actions = InitIndex | DestroyIndex | ChangeSortField | ChangeSearchValue

export interface InitIndex extends Action<Types.INIT_INDEX> {
  payload: { fields: SortField[]; searchEnabled: boolean; searchFields: string[] }
  meta: { name: string }
}
export interface DestroyIndex extends Action<Types.DESTROY_INDEX> {
  meta: { name: string }
}
export interface ChangeSortField extends Action<Types.CHANGE_SORT_FIELD> {
  payload: { field: SortField; direction: SORT_DIRECTION }
  meta: { name: string }
}
export interface ChangeSearchValue extends Action<Types.CHANGE_SEARCH_VALUE> {
  payload: { value: string }
  meta: { name: string }
}

interface InitIndexActionCreatorOptions {
  fields: SortField[]
  searchEnabled?: boolean
  searchFields?: string[]
}
export const initIndex: (name: string, options: InitIndexActionCreatorOptions) => InitIndex = (
  name,
  options
) => ({
  type: Types.INIT_INDEX,
  payload: {
    fields: options.fields,
    searchEnabled: options.searchEnabled || false,
    searchFields: options.searchEnabled ? options.searchFields : []
  },
  meta: { name }
})

export const destroyIndex: (name: string) => DestroyIndex = name => ({
  type: Types.DESTROY_INDEX,
  meta: { name }
})

export const changeSortField: (
  name: string,
  options: { field: SortField; direction: SORT_DIRECTION }
) => ChangeSortField = (name, options) => ({
  type: Types.CHANGE_SORT_FIELD,
  payload: { field: options.field, direction: options.direction },
  meta: { name }
})

export const changeSearchValue: (name: string, value: string) => ChangeSearchValue = (
  name,
  value
) => ({
  type: Types.CHANGE_SEARCH_VALUE,
  payload: { value },
  meta: { name }
})

export const sortingActionCreators = (name: string) => ({
  initIndex: (options: InitIndexActionCreatorOptions) => initIndex(name, options),
  destroyIndex: () => destroyIndex(name),
  changeSortField: (options: { field: SortField; direction: SORT_DIRECTION }) =>
    changeSortField(name, options),
  changeSearchValue: (value: string) => changeSearchValue(name, value)
})

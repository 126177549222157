import { createSelector } from 'reselect'
import { getResources } from 'redux-resource'

import getResourceState from 'lib/utils/getResourceState'
import { ZONES } from 'constants/resources'
import { STATES } from 'constants/states'

export const getState = (state: AllAppsState) => state.zones

export const zonesSelector = createSelector(
  [getState],
  zonesState => getResources(zonesState) || []
)

export const zonesStatusSelector = createSelector(
  [(state: AllAppsState) => state],
  state => getResourceState(state, `${ZONES.NAME}.requests.${ZONES.REQUESTS.GET_ZONES_LIST}.status`)
)

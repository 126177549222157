const styles = (theme: App.Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.375rem 0.3125rem',
    borderRadius: '0.3125rem',
    transform: 'translate3d(0, 0, 0)',
    userSelect: 'none'
  },
  iconContainer: {
    flexShrink: 0,
    marginRight: '0.3125rem',
    fontSize: '0.75rem',
    lineHeight: 0,
    color: theme.colors.grey,
    textTransform: 'uppercase'
  },
  icon: {
    width: '1em',
    height: '1em',
    fill: theme.colors.grey,
    stroke: theme.colors.grey
  },
  sets: {
    display: 'inline-block',
    fontSize: 0
  },
  set: {
    display: 'inline-block',
    fontSize: '0.75rem',
    lineHeight: 1.2,
    color: '#000'
  },
  cubeName: {
    fontWeight: 'bold'
  },
  sign: {
    display: 'inline-block',
    margin: '0 0.3125rem',
    fontSize: '0.75rem',
    lineHeight: 1.2,
    color: '#000'
  },
  arrowButton: {
    display: 'block',
    flexShrink: 0,
    marginLeft: '0.3125rem',
    padding: 0,
    fontSize: 0,
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer'
  },
  arrow: {
    fontSize: '0.875rem',
    fill: theme.colors.text.secondary
  }
})

export default styles

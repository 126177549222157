import React, { FC, useEffect, useState } from 'react'
import { connect, MapStateToProps } from 'react-redux'
import { compose } from 'recompose'

import injectStyles, { JSSProps } from 'react-jss'
import styles from './components/Menu/styles'
import { ACLMap } from 'lib/utils/aclMask'
import {
  additionalSideMenuRoutes,
  allowedToHeadRoutes,
  Route,
  sidemenuRoutes
} from 'src/routes'
import { useLocation } from 'react-router'
import Menu from 'components/NavigationMenu/components/Menu'

interface OuterProps {
  openSettings: () => void
}

interface StateProps {
  items: App.State['sidemenu']['items']
  aclMap: ACLMap
  user_type: App.State['user']['user_type'] | undefined
}

export interface Props
  extends StateProps,
    OuterProps,
    State,
    JSSProps<typeof styles> {}

interface State {
  sidemenuSections: Array<
    Route & { id: string; notification: boolean; notificationsCount?: number }
  >
}

const additionalMenuRoutes = ['/panels', '/elements', '/widgets']

const NavigationMenu: FC<Props> = ({ user_type, items, classes, aclMap }) => {
  const [navigationMenuRoutes, setNavigationMenuRoutes] = useState([])
  const [additionalSideMenu, setAdditionalSideMenu] = useState([])

  const location = useLocation()

  const filterAclRoutes = routes => {
    const isHead = user_type === 'head'

    const arrayToFilter = isHead ? allowedToHeadRoutes : routes

    return arrayToFilter
      .filter(route => (route.withoutAcl ? true : aclMap && aclMap[route.aclKey]))
      .map(route => {
        const navigationMenuItem = items.find(item => route.path.includes(item.id))

        return {
          ...route,
          ...navigationMenuItem
        }
      })
  }

  useEffect(() => {
    setNavigationMenuRoutes(filterAclRoutes(sidemenuRoutes))
    setAdditionalSideMenu(filterAclRoutes(additionalSideMenuRoutes))
  }, [aclMap])

  return (
    <>
      <Menu subMenu activeRoutes={additionalMenuRoutes} routes={navigationMenuRoutes} />
      {additionalMenuRoutes.includes(location.pathname) && (
        <Menu routes={additionalSideMenu} subMenu={false} />
      )}
    </>
  )
}

const mapStateToProps: MapStateToProps<StateProps, Props, App.State> = state => ({
  items: state.sidemenu.items,
  aclMap: state.user.aclMap,
  user_type: state.user.user_type
})

export default compose<Props, OuterProps>(
  
  connect(mapStateToProps),
  injectStyles<OuterProps>(styles)
)(NavigationMenu)

import { borderRadius } from 'polished';
import { colors } from 'lib/theme/colors'

const styles = (theme: App.Theme) => ({
  layoutContainer: {
    display: 'flex',

    '& .echarts-for-react': {
      borderRadius: '8px',
      overflow: 'hidden',
    }
  },
  layout: {
    ...theme.utils.flexContainer({ direction: 'column', wrap: 'nowrap', alignItems: 'flex-start' }),
    height: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
    boxSizing: 'border-box',
    overflow: 'hidden'
  },
  contentWrapper: {
    ...theme.utils.flexContainer({ direction: 'row', wrap: 'nowrap', alignItems: 'stretch' }),
    height: '100%',
    width: '100%',
    margin: '0',
    padding: '0'
  },
  content: {
    ...theme.utils.flexContainer({ direction: 'column', wrap: 'nowrap', alignItems: 'flex-start' }),
    width: '100%',
    flex: 1,
    margin: '0',
    padding: '0'
  },
  '@global': {
    html: {
      fontSize: 16,
      fontStyle: 'normal',
      width: '100%',
      minHeight: '100%',
      backgroundColor: colors.mainBackground
    },
    'body, #root': {
      width: '100%',
      minHeight: '100%',
      margin: 0,
      backgroundColor: colors.mainBackground
    },
    body: {
      overflow: 'hidden'
    },
    '#root': {
      width: '100%',
      fontFamily: theme.common.fontFamily,
      transform: 'translate3d(0,0,0)'
    }
  },
  '@global .visuallyHidden': {
    position: 'absolute',
    width: '1px',
    height: '1px',
    margin: '-1px',
    padding: 0,
    whiteSpace: 'nowrap',
    border: 0,
    overflow: 'hidden',
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)'
  }
})

export { styles as default }

import { Reducer } from 'redux'
import { MakingActions, Types } from './types'

// TODO TS state
export enum Mode {
  PREVIEW = 'preview',
  EDIT = 'edit',
  CREATE = 'create',
  DELETE = 'delete'
}

export interface State {
  id: number | string | null
  name?: string
  nsi?: boolean
  description?: string
  mode: Mode
  headers?: Data.ColumnParams[]
  rows?: string[][]
  meta: { loading: boolean; loaded: boolean }
  nsiColumn: {
    meta: { loading: boolean; loaded: boolean }
  }
  accessUserIds: []
  refreshData: {}
}

const initialState: State = {
  id: null,
  mode: Mode.CREATE,
  headers: [],
  rows: [],
  meta: { loading: false, loaded: false },
  nsiColumn: {
    meta: { loading: false, loaded: false }
  },
  accessUserIds: [],
  refreshData: {}
}

export const reducer: Reducer<State, MakingActions> = (state = initialState, action) => {
  switch (action.type) {
    case Types.CHANGE_COLUMN_PARAMS: {
      const headers = state.headers
      let rows = [...state.rows]
      headers[action.payload.index] = { ...headers[action.payload.index], ...action.payload.params }

      if (!state.headers[action.payload.index].formula && action.payload.params.formula)
        rows = [
          ...state.rows.map(row => {
            const tmp = [...row]
            tmp[action.payload.index] = ''
            return tmp
          })
        ]

      return {
        ...state,
        headers,
        rows
      }
    }
    case Types.CHANGE_DATA_CELL: {
      const nextRows = [...state.rows]
      nextRows[action.payload.rowIndex][action.payload.columnIndex] = action.payload.value

      return { ...state, rows: nextRows }
    }
    case Types.ADD_COLUMN:
      return {
        ...state,
        headers: [...state.headers, action.payload as Data.ColumnParams],
        rows: [...state.rows.map(row => [...row, ''])]
      }

    case Types.CLEAR_COLUMN: {
      return {
        ...state,
        rows: state.rows.map(row => {
          row[action.payload] = ''
          return row
        })
      }
    }
    case Types.REMOVE_COLUMN: {
      return {
        ...state,
        headers: state.headers.filter((head, idx) => idx !== action.payload),
        rows:
          state.headers.length > 1
            ? state.rows.map(row => {
                return row.filter((el, idx) => idx !== action.payload)
              })
            : [],
        nsiColumn: {
          ...state.nsiColumn,
          ...{
            [action.payload]: {}
          }
        }
      }
    }
    case Types.REMOVE_ROW: {
      return {
        ...state,
        rows: state.rows.filter((row, index) => index !== action.payload)
      }
    }
    case Types.ADD_CELL:
      return {
        ...state,
        rows: [...state.rows, new Array(state.headers.length).fill('')]
      }

    case Types.CLEAN_CUBE: {
      return {
        ...state,
        rows: [],
        nsiColumn: {
          meta: { loading: false, loaded: false }
        }
      }
    }

    case Types.REMOVE_CUBE:
      return initialState

    case Types.GET_NSI:
      return {
        ...state,
        nsiColumn: {
          ...state.nsiColumn,
          meta: { loading: true, loaded: false }
        }
      }

    case Types.GET_NSI_SUCCESS:
      return {
        ...state,
        nsiColumn: {
          ...state.nsiColumn,
          ...action.payload,
          meta: { loading: false, loaded: true }
        }
      }

    case Types.GET_NSI_FAIL:
      return {
        ...state,
        nsiColumn: {
          ...state.nsiColumn,
          meta: { loading: false, loaded: false }
        }
      }

    case Types.ACCESS_CUBE:
      return {
        ...state,
        accessUserIds: action.payload
      }

    case Types.REFRESH_SETTINGS_DATA_CUBE:
      return {
        ...state,
        refreshData: action.payload
      }

    case Types.CREATE_CUBE: {
      return {
        ...state,
        meta: { loading: true, loaded: false }
      }
    }

    case Types.CREATE_CUBE_SUCCESS: {
      return {
        ...state,
        meta: { loading: false, loaded: true },
        id: action.payload
      }
    }

    case Types.CREATE_CUBE_FAIL: {
      return {
        ...state,
        meta: { loading: false, loaded: false }
      }
    }
    case Types.SEND_FILE: {
      return {
        ...state,
        meta: { loading: true, loaded: false }
      }
    }

    case Types.SEND_FILE_SUCCESS: {
      return {
        ...state,
        meta: { loading: false, loaded: true }
      }
    }

    case Types.SEND_FILE_FAIL: {
      return {
        ...state,
        meta: { loading: false, loaded: false }
      }
    }

    case Types.GET_CUBE:
      return {
        ...state,
        meta: { loading: true, loaded: false }
      }

    case Types.GET_CUBE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        meta: { loading: false, loaded: true },
        mode: Mode.PREVIEW
      }

    case Types.GET_CUBE_FAIL:
      return {
        ...state,
        meta: { loading: false, loaded: false }
      }

    case Types.CHANGE_MODE:
      return {
        ...state,
        mode: action.payload
      }

    case Types.UPDATE_CUBE:
      return {
        ...state,
        meta: { loading: true, loaded: false }
      }

    case Types.UPDATE_CUBE_SUCCESS:
      return {
        ...state,
        meta: { loading: false, loaded: true },
        mode: Mode.PREVIEW
      }

    case Types.UPDATE_CUBE_FAIL:
      return {
        ...state,
        meta: { loading: false, loaded: false }
      }

    case Types.DELETE_CUBE:
      return {
        ...state,
        meta: { loading: true, loaded: false }
      }

    case Types.DELETE_CUBE_SUCCESS:
      return {
        ...state,
        meta: { loading: false, loaded: true },
        mode: Mode.DELETE
      }

    case Types.DELETE_CUBE_FAIL:
      return {
        ...state,
        meta: { loading: false, loaded: false }
      }

    default:
      return state
  }
}

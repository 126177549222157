import React, { PureComponent } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import { compose } from 'recompose'
import { Field, FieldArrayFieldsProps } from 'redux-form'

import { notEmpty } from 'lib/utils/validations'
import Value from './components/Value'
import {
  OPTION_TYPES,
  COMPARE_OPERATORS,
  COMPARE_NUMBER_OPTIONS,
  COMPARE_STRING_OPTIONS
} from 'constants/filter'
import styles from './styles'

interface OuterProps {
  name: string
  index: number
  fields: FieldArrayFieldsProps<Filter.Expression>
  optionsCount?: number
  selectedOption?: Filter.Option
  withInitialValue?: boolean
  stringAsMulti?: boolean
}
interface Props extends JSSProps<typeof styles>, OuterProps {}

class Compare extends PureComponent<Props> {
  static defaultProps = {
    withInitialValue: true,
    stringAsMulti: false
  }

  get compareOptions() {
    const { selectedOption, stringAsMulti } = this.props

    switch (selectedOption.filter_type) {
      case OPTION_TYPES.STRING:
        return stringAsMulti
          ? COMPARE_STRING_OPTIONS.filter(operator => operator.value === COMPARE_OPERATORS.EQUAL)
          : COMPARE_STRING_OPTIONS
      case OPTION_TYPES.NUMBER:
        return COMPARE_NUMBER_OPTIONS
      case OPTION_TYPES.MULTI:
      case OPTION_TYPES.BOOLEAN:
        return COMPARE_NUMBER_OPTIONS.filter(operator => operator.value === COMPARE_OPERATORS.EQUAL)
      case OPTION_TYPES.TIME:
        return COMPARE_NUMBER_OPTIONS
      default:
        return []
    }
  }

  render() {
    const { classes, name, index, optionsCount, withInitialValue } = this.props

    return (
      <Field
        name={`${name}.condition`}
        options={this.compareOptions}
        id={`${index}-compare`}
        title="Условие фильтрации"
        withInitialValue={withInitialValue}
        containerProps={{
          className: classes.selectTooltip,
          disabled: this.compareOptions.length === 1
        }}
        validate={[notEmpty]}
        component={Value}
      />
    )
  }
}

export default compose<Props, OuterProps>(injectStyles(styles))(Compare)

import React, { ReactNode, ReactNodeArray, HTMLAttributes } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import Modal from 'react-modal'
import { compose } from 'recompose'
import classNames from 'classnames'

import styles from './styles'

Modal.setAppElement(document.getElementById('root'))

interface OuterProps extends ReactModal.Props {
  modalProps?: HTMLAttributes<HTMLDivElement>
  children: ReactNode | ReactNodeArray
}
interface Props extends OuterProps, JSSProps<typeof styles> {}

const BaseModal: React.StatelessComponent<Props> = ({
  classes,
  theme,
  modalProps: { className },
  children,
  ...props
}) => {
  return (
    <Modal
      overlayClassName={classes.overlay}
      className={classNames(classes.container, className)}
      shouldFocusAfterRender
      {...props}
    >
      {children}
    </Modal>
  )
}

BaseModal.defaultProps = {
  modalProps: {}
}

export default compose<Props, OuterProps>(injectStyles(styles))(BaseModal)

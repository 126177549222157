import { format } from 'date-fns'
import { DATE_FORMAT_TYPES } from '../utils/dateHelper'

export const formatDateField = (timestamp: string | number | null) => {
  if (typeof timestamp === 'number') {
    return format(timestamp * 1000, DATE_FORMAT_TYPES.yearMonthDay)
  }

  return timestamp
}

export const getDayMonthYear = date => date && format(formatDateServer(date, 'date'), DATE_FORMAT_TYPES.dayMonthYear)

export const getDayMonthYearHourMinute = date =>
  date && format(formatDateServer(date, 'date'), DATE_FORMAT_TYPES.dayMonthNameYearHourseMinutes)

export const getHourMinuteSecond = date => date && format(formatDateServer(date), DATE_FORMAT_TYPES.hoursMinutesSeconds)

export const formatDateServer = (
  timestamp: string | number | null,
  dateFormat: 'date' | 'timestamp' = 'date'
): string | null | Date | number => {
  if (typeof timestamp === 'number') {
    const validTimestamp = timestamp * 1000
    if (dateFormat === 'date') return new Date(validTimestamp)
    if (dateFormat === 'timestamp') return validTimestamp
  }

  return timestamp
}

export const formatDateYMDHm = (date: string | number | Date) => format(date, DATE_FORMAT_TYPES.yearMonthDayHoursMinutes)

import { createSelector } from 'reselect'

import { MODALS_NAMES } from 'constants/modals'

export const getState = (state: OpenApp.State | App.State) => state.modals

export const isModalOpen = (name: MODALS_NAMES) =>
  createSelector(
    getState,
    state => state[name].isOpen
  )
export const getModalItemId = (name: MODALS_NAMES) =>
  createSelector(
    getState,
    state => state[name].itemId
  )

// @ts-nocheck

import React, { useMemo, Fragment, ReactInstance, DetailedHTMLProps, HTMLAttributes, useRef } from 'react'
import { findDOMNode } from 'react-dom'
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux'
import {
  DropTargetMonitor, useDrag
} from 'react-dnd'
import injectStyles, { JSSProps, withTheme } from 'react-jss'
import { compose } from 'recompose'
import { ExpandMore, ExpandLess } from '@mui/icons-material'
import cn from 'classnames'

import AggregationService from 'lib/services/aggregation'
import { getTargetAggregationID } from 'lib/modules/aggregations/selectors'
import { openMenu } from 'lib/modules/aggregations/actions'
import { TYPE as SET_TYPE } from 'constants/set'
import { FORM } from 'lib/constants/aggregation'
import { CHART_TYPES } from 'constants/dataView'
import styles from './styles'
import { ItemProps as SetItemProps } from '../Set'
import { DragAndDropTypes } from '../../dndTypes'

interface OuterProps {
  aggregation: Data.AggregationEntity
  draggable?: boolean
  getCubeNameById?: (cube_id: string) => string
  elementType: Data.Chart['element_type'] | undefined
  onRemove: (aggregation: Data.AggregationEntity) => void
  containerProps?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
}
interface StateProps {
  isMenuTarget: boolean
  isMenuOpened: boolean
}
interface DispatchProps {
  openMenu: (menu: {
    anchor: HTMLDivElement
    targetAggregation: Data.AggregationEntity
    sourceItem?: Data.AggregationEntity | Data.Dimension | Data.Measure
    sourceItemIsAggregation?: boolean
  }) => void
}
interface DragCollectProps {
  isOver: boolean
  canDrop: boolean
}
export interface Props
  extends JSSProps<typeof styles>,
  StateProps,
  DispatchProps,
  Partial<DragCollectProps>,
  OuterProps { }
export interface AggregationItemProps extends JSSProps<typeof styles>, OuterProps { }
interface State { }

const DROP_TARGET = {
  name: [DragAndDropTypes.ElementIndicatorItem, DragAndDropTypes.AggregationItem],
  source: {
    canDrop(props: Props, monitor: DropTargetMonitor) {
      const { aggregation, elementType } = props
      const itemType = monitor.getItemType() as string
      const item = monitor.getItem() as SetItemProps | AggregationItemProps
      const targetWithDimension = AggregationService.withDimension(aggregation.agg)

      if (elementType === CHART_TYPES.SCANNER || elementType === CHART_TYPES.SANKEY) {
        return false
      }

      switch (itemType) {
        case DragAndDropTypes.ElementIndicatorItem: {
          const setItem = item as SetItemProps

          return (
            !targetWithDimension &&
            setItem.type === SET_TYPE.MEASURE &&
            !aggregation.args.some(set => set.id === setItem.set.id)
          )
        }
        case DragAndDropTypes.AggregationItem: {
          const aggregationItem = (item as AggregationItemProps).aggregation

          if (aggregationItem.id === aggregation.id) {
            return false
          }

          const sourceWithDimension = AggregationService.withDimension(aggregationItem.agg)

          return (
            !targetWithDimension &&
            aggregation.agg !== FORM.CORR &&
            !sourceWithDimension &&
            aggregationItem.agg !== FORM.CORR
          )
        }
        default:
          return false
      }
    },
    drop(props: Props, monitor: DropTargetMonitor, component: ReactInstance) {
      const { aggregation, openMenu } = props
      const itemType = monitor.getItemType() as string
      const item = monitor.getItem()
      const sourceItemIsAggregation = itemType === ''
      const sourceItem = sourceItemIsAggregation
        ? (item as AggregationItemProps).aggregation
        : (item as SetItemProps).set
      const element = findDOMNode(component) as HTMLDivElement

      openMenu({
        anchor: element,
        targetAggregation: aggregation,
        sourceItem,
        sourceItemIsAggregation
      })

      return props
    }
  },
  collect(monitor) {
    return {
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }
  }
}
const DRAG_SOURCE = {
  name: DragAndDropTypes.AggregationItem,
  end(props: AggregationItemProps, monitor) {
    if (!monitor.didDrop()) {
      return
    }
  },
}

const AggregationItemHOC = ({ draggable = true, ...props }: Props) =>
  draggable ? (
    <AggregationItemWithDND draggable={draggable} {...props} />
  ) : (
    <AggregationItemWithoutDND draggable={draggable} {...props} />
  )

const AggregationItem = (props) => {
  const itemRef = useRef()

  const [collected = {}, drag, dragPreview] = useDrag(() => ({
    type: DRAG_SOURCE.name,
    end: DRAG_SOURCE.end,
    item: { props }
  }))

  const icon = useMemo(() => {
    const symbol = AggregationService.getAggregationSymbol(props.aggregation.agg)

    return symbol
  }, [props.aggregation])

  const backgroundColor = () => {
    const isDimension = AggregationService.isDimension(props.aggregation.agg)

    if (collected.canDrop) {
      return isDimension
        ? props.theme.colors.aggregation.dimensionActive
        : props.theme.colors.aggregation.measureActive
    } else {
      return isDimension ? props.theme.colors.aggregation.dimension : props.theme.colors.aggregation.measure
    }
  }

  const onDoubleClick = () => {
    const { aggregation, onRemove } = props

    onRemove(aggregation)
  }

  const onOpenMenu = () => {
    const { aggregation, openMenu } = props
    const anchor = itemRef.current

    openMenu({
      anchor,
      targetAggregation: aggregation
    })
  }

  const {
    classes,
    aggregation: { agg, args },
    containerProps = {},
    isMenuTarget,
    isMenuOpened,
    getCubeNameById,
  } = props

  let dragOptions = {}

  if (collected.isDragging) dragOptions.ref = dragPreview
  else {
    dragOptions = { ref: drag, ...collected }
  }

  return (
    <div
      {...dragOptions}
      {...containerProps}
      ref={itemRef}
      className={cn(classes.container, containerProps.className)}
      style={{ ...containerProps.style, backgroundColor: backgroundColor }}
    >
      <span className={classes.iconContainer} title={AggregationService.getAggregationName(agg)}>
        {icon}
      </span>
      <div className={classes.sets} onDoubleClick={onDoubleClick}>
        {args.map((set, index) => (
          <Fragment key={set.id}>
            {getCubeNameById && (
              <span className={cn(classes.cubeName, classes.set)}>
                {getCubeNameById(set.cube_id)}
              </span>
            )}
            <span className={classes.set}>{set.name}</span>
            {index < args.length - 1 && args.length > 1 && (
              <span className={classes.sign}>+</span>
            )}
          </Fragment>
        ))}
      </div>
      <button className={classes.arrowButton} type="button" onClick={onOpenMenu}>
        {isMenuTarget && isMenuOpened ? (
          <ExpandLess className={classes.arrow} />
        ) : (
          <ExpandMore className={classes.arrow} />
        )}
      </button>
    </div>
  )
}

const mapStateToProps: MapStateToProps<StateProps, Props, App.State> = (state, props) => ({
  isMenuTarget: getTargetAggregationID(state) === props.aggregation.id,
  isMenuOpened: !!state.aggregations.menu.anchor
})

const mapDispatchToProps: MapDispatchToProps<DispatchProps, Props> = dispatch => ({
  openMenu: options => dispatch(openMenu(options))
})

const AggregationItemWithDND = AggregationItem

const AggregationItemWithoutDND = AggregationItem

export default compose<Props, OuterProps>(
  withTheme,
  injectStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AggregationItemHOC)

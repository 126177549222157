import React, { StatelessComponent, MouseEvent, HTMLAttributes, ReactNode } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import { compose } from 'recompose'
import classNames from 'classnames'

import styles from './styles'

export interface Tab<Value = string | number> {
  value: Value
  label: string
  icon?: ReactNode
}
export type TabChageEvent<Value = string | number> = (
  selected: Tab<Value>,
  event: MouseEvent<HTMLButtonElement>
) => void

export interface OuterProps extends HTMLAttributes<HTMLDivElement> {
  tabs: Tab[]
  onSelectTab: TabChageEvent
  value: string | number
  className?: string
  tabProps?: HTMLAttributes<HTMLButtonElement>
}
export interface Props extends OuterProps, JSSProps<typeof styles> {}

const TabSwitch: StatelessComponent<Props> = ({
  classes,
  className,
  tabs,
  onSelectTab,
  value,
  theme,
  tabProps,
  ...props
}) => (
  <div className={classNames(classes.container, className)} {...props}>
    {tabs.map((tab, i) => (
      <button
        key={`tab-${i}-${tab.value.toString()}`}
        className={classNames(classes.tabButton, tabProps && tabProps.className, {
          [classes.tabButtonActive]: tab.value === value
        })}
        type="button"
        onClick={event => onSelectTab(tab, event)}
      >
        <span>{tab.label}</span>
        {tab.icon && <span className={classes.tabIcon}>{tab.icon}</span>}
      </button>
    ))}
  </div>
)

export default compose<Props, OuterProps>(injectStyles(styles))(TabSwitch)

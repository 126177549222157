
import AddEntityIcon from 'assets/images/sprite/icons/addEntity.svg'
import AddFavorites from 'assets/images/sprite/icons/addFavorites.svg'
import AdditionalInfoIcon from 'assets/images/sprite/icons/additionalInfo.svg'
import AlarmIcon from 'assets/images/sprite/icons/alarm.svg'
import AlignmentCenterIcon from 'assets/images/sprite/icons/align_center.svg'
import AlignmentLeftIcon from 'assets/images/sprite/icons/align_left.svg'
import AlignmentRightIcon from 'assets/images/sprite/icons/align_right.svg'
import ArrowDropdownIcon from 'assets/images/sprite/icons/arrow-dropdown.svg'
import ArrowLeftRightIcon from 'assets/images/sprite/icons/arrow-left-right.svg'
import ArrowLeftIcon from 'assets/images/sprite/icons/arrow-left.svg'
import ArrowRightIcon from 'assets/images/sprite/icons/arrow-right.svg'
import ArrowDownIcon from 'assets/images/sprite/icons/arrowDown.svg'
import ArrowUpIcon from 'assets/images/sprite/icons/arrowUp.svg'
import AssociationIcon from 'assets/images/sprite/icons/association.svg'
import AttachIcon from 'assets/images/sprite/icons/attach.svg'
import AttachWithBgIcon from 'assets/images/sprite/icons/attachWithBg.svg'
import BurgerIcon from 'assets/images/sprite/icons/burger.svg'
import CalendarIcon from 'assets/images/sprite/icons/calendar.svg'
import CameraIcon from 'assets/images/sprite/icons/camera.svg'
import CancelIcon from 'assets/images/sprite/icons/cancel.svg'
import ChatIcon from 'assets/images/sprite/icons/chat.svg'
import CleanIcon from 'assets/images/sprite/icons/clean.svg'
import ClockIcon from 'assets/images/sprite/icons/clock.svg'
import CloseButtonIcon from 'assets/images/sprite/icons/closeButton.svg'
import CollapseIcon from 'assets/images/sprite/icons/collapse.svg'
import CopyIcon from 'assets/images/sprite/icons/copy.svg'
import CrossIcon from 'assets/images/sprite/icons/cross.svg'
import DashboardIcon from 'assets/images/sprite/icons/dashboard.svg'
import DateIcon from 'assets/images/sprite/icons/date.svg'
import DeleteBadgeIcon from 'assets/images/sprite/icons/delete-badge.svg'
import DeleteIcon from 'assets/images/sprite/icons/delete.svg'
import DeleteCardIcon from 'assets/images/sprite/icons/deleteCard.svg'
import DisketteIcon from 'assets/images/sprite/icons/diskette.svg'
import DisplayIcon from 'assets/images/sprite/icons/display.svg'
import DocumentIcon from 'assets/images/sprite/icons/document.svg'
import DotsIcon from 'assets/images/sprite/icons/dots.svg'
import DownloadIcon from 'assets/images/sprite/icons/download.svg'
import DuplicateIcon from 'assets/images/sprite/icons/duplicateEntity.svg'
import EditBadgeIcon from 'assets/images/sprite/icons/edit-badge.svg'
import EditIcon from 'assets/images/sprite/icons/edit.svg'
import EmbedIcon from 'assets/images/sprite/icons/embed.svg'
import ExpandIcon from 'assets/images/sprite/icons/expand.svg'
import ExportIcon from 'assets/images/sprite/icons/export.svg'
import EyeIcon from 'assets/images/sprite/icons/eye.svg'
import FileExcelIcon from 'assets/images/sprite/icons/file-excel.svg'
import FilePdfIcon from 'assets/images/sprite/icons/file-pdf.svg'
import FileWordIcon from 'assets/images/sprite/icons/file-word.svg'
import FileDownloadIcon from 'assets/images/sprite/icons/file.svg'
import FileSaveIcon from 'assets/images/sprite/icons/file_save.svg'
import FinancialStatisticsIcon from 'assets/images/sprite/icons/finStatIcon.svg'
import FormsIcon from 'assets/images/sprite/icons/forms.svg'
import GoBackRouteIcon from 'assets/images/sprite/icons/goBackRouteIcon.svg'
import GoToRouteIcon from 'assets/images/sprite/icons/goToRouteIcon.svg'
import HideIcon from 'assets/images/sprite/icons/hide.svg'
import InfoSecondaryIcon from 'assets/images/sprite/icons/info-2.svg'
import InfoIcon from 'assets/images/sprite/icons/info.svg'
import InfoMainIcon from 'assets/images/sprite/icons/infoIcon.svg'
import LinkIcon from 'assets/images/sprite/icons/link.svg'
import ListIcon from 'assets/images/sprite/icons/listIcon.svg'
import LoginIcon from 'assets/images/sprite/icons/login.svg'
import MapZoomIn from 'assets/images/sprite/icons/mapZoomIn.svg'
import MapZoomOut from 'assets/images/sprite/icons/mapZoomOut.svg'
import MapMarkerBlueIcon from 'assets/images/sprite/icons/marker_blue.svg'
import MapMarkerGreenIcon from 'assets/images/sprite/icons/marker_green.svg'
import MapMarkerRedIcon from 'assets/images/sprite/icons/marker_red.svg'
import MapMarkerYellowIcon from 'assets/images/sprite/icons/marker_yellow.svg'
import MergingIcon from 'assets/images/sprite/icons/mergingIcon.svg'
import MessageIcon from 'assets/images/sprite/icons/message.svg'
import MinusIcon from 'assets/images/sprite/icons/minus.svg'
import PlusIcon from 'assets/images/sprite/icons/plus.svg'
import PolygonIcon from 'assets/images/sprite/icons/polygon.svg'
import QuestionIcon from 'assets/images/sprite/icons/question.svg'
import RefreshIcon from 'assets/images/sprite/icons/refresh.svg'
import RepeatIcon from 'assets/images/sprite/icons/repeat.svg'
import ReplaceIcon from 'assets/images/sprite/icons/replace.svg'
import SaveIcon from 'assets/images/sprite/icons/save.svg'
import SaveButtonIcon from 'assets/images/sprite/icons/saveButton.svg'
import SearchIcon from 'assets/images/sprite/icons/search.svg'
import SelectorExpandIcon from 'assets/images/sprite/icons/selectorExpand.svg'
import SendMessageIcon from 'assets/images/sprite/icons/sendMessage.svg'
import SettingsIcon from 'assets/images/sprite/icons/settings-icon.svg'
import SharedLinkIcon from 'assets/images/sprite/icons/shared-link.svg'
import StarIcon from 'assets/images/sprite/icons/star.svg'
import SwipeIcon from 'assets/images/sprite/icons/swipe.svg'
import UpdateDataIcon from 'assets/images/sprite/icons/updateData.svg'
import UploadIcon from 'assets/images/sprite/icons/upload.svg'
import UserAddIcon from 'assets/images/sprite/icons/user-add.svg'
import UserBlockIcon from 'assets/images/sprite/icons/user-block.svg'
import UserNormalIcon from 'assets/images/sprite/icons/user-normal.svg'
import UserIcon from 'assets/images/sprite/icons/user.svg'
import VideoIcon from 'assets/images/sprite/icons/video.svg'
import WarningIcon from 'assets/images/sprite/icons/warning.svg'
import LogoIcon from 'assets/images/sprite/logo.svg'
import IconBase from './IconBase'

// Data views
import ArchiveIcon from 'assets/images/sprite/icons/archive.svg'
import BarChartIcon from 'assets/images/sprite/icons/bar-chart.svg'
import ImageIcon from 'assets/images/sprite/icons/image_element.svg'
import KeyActiveIcon from 'assets/images/sprite/icons/key_active.svg'
import KeyInactiveIcon from 'assets/images/sprite/icons/key_inactive.svg'
import LineChartIcon from 'assets/images/sprite/icons/line-chart.svg'
import MapChartIcon from 'assets/images/sprite/icons/map-chart.svg'
import MapMarkerConstructionObjectIcon from 'assets/images/sprite/icons/map_construction_object.svg'
import NumberIcon from 'assets/images/sprite/icons/number.svg'
import { default as GraphChartIcon, default as PieChartIcon } from 'assets/images/sprite/icons/pie-chart.svg'
import PinFileIcon from 'assets/images/sprite/icons/pinFile.svg'
import PublicationIcon from 'assets/images/sprite/icons/publication.svg'
import SankeyChartIcon from 'assets/images/sprite/icons/sankey-chart.svg'
import ScannerIcon from 'assets/images/sprite/icons/scanner.svg'
import ScatterChartIcon from 'assets/images/sprite/icons/scatter-chart.svg'
import StringIcon from 'assets/images/sprite/icons/string.svg'
import TableIcon from 'assets/images/sprite/icons/table.svg'
import TextIcon from 'assets/images/sprite/icons/text.svg'
import ValueIcon from 'assets/images/sprite/icons/value.svg'
import MapIcon from 'assets/images/sprite/sidemenu/map.svg'
// Legend position
import LegendBottomIcon from 'assets/images/sprite/legend/bottom.svg'
import LegendLeftIcon from 'assets/images/sprite/legend/left.svg'
import LegendRightIcon from 'assets/images/sprite/legend/right.svg'
import LegendTopIcon from 'assets/images/sprite/legend/top.svg'
// Aggregation menu
import AggregationIcon from 'assets/images/sprite/icons/aggregation.svg'
import FuncIcon from 'assets/images/sprite/icons/func.svg'
import GroupingIcon from 'assets/images/sprite/icons/grouping.svg'
import NormalizeIcon from 'assets/images/sprite/icons/normalize.svg'
import RoundingIcon from 'assets/images/sprite/icons/rounding.svg'
// Aggregation date presets
import AggregationPresetDayIcon from 'assets/images/sprite/aggregation_date_format/day.svg'
import AggregationPresetMonthIcon from 'assets/images/sprite/aggregation_date_format/month.svg'
import AggregationPresetYearIcon from 'assets/images/sprite/aggregation_date_format/year.svg'
// Sidemenu
import LogoutIcon from 'assets/images/sprite/sidemenu/logout.svg'
import NotificationsIcon from 'assets/images/sprite/sidemenu/notifications.svg'
// OtherServices
import FCHintIcon from 'assets/images/sprite/fc_hint.svg'
import { colors } from 'lib/theme/colors'

export const Logo = IconBase(LogoIcon)
export const AddEntity = IconBase(AddEntityIcon)
export const SVGAdditionalInfo = IconBase(AdditionalInfoIcon)
export const SVGInfoMainIcon = IconBase(InfoMainIcon)
export const MapMarkerConstructionObject = IconBase(MapMarkerConstructionObjectIcon)
export const MapMarkerBlue = IconBase(MapMarkerBlueIcon)
export const MapMarkerRed = IconBase(MapMarkerRedIcon)
export const MapMarkerGreen = IconBase(MapMarkerGreenIcon)
export const MapMarkerYellow = IconBase(MapMarkerYellowIcon)
export const Calendar = IconBase(CalendarIcon, { width: 24, height: 24 })
export const PinFile = IconBase(PinFileIcon)
export const SvgCamera = IconBase(CameraIcon, { width: 21, height: 21 })
export const SvgCloseButton = IconBase(CloseButtonIcon, { width: 28, height: 28 })
export const SvgMap = IconBase(MapIcon)
export const SvgArchive = IconBase(ArchiveIcon)
export const SelectorExpand = IconBase(SelectorExpandIcon)
export const SVGUpdateDataIcon = IconBase(UpdateDataIcon, { width: 14, height: 14 })
export const SVGMapZoomIn = IconBase(MapZoomIn, {
  width: 20,
  height: 20,
  color: colors.text.primary
})
export const SVGMapZoomOut = IconBase(MapZoomOut, {
  width: 20,
  height: 20,
  color: colors.text.primary
})
export const Merging = IconBase(MergingIcon)
export const SVGGoToRoute = IconBase(GoToRouteIcon, { width: 14, height: 14 })
export const SVGGoBackRoute = IconBase(GoBackRouteIcon, { width: 14, height: 14 })
export const Association = IconBase(AssociationIcon)
export const SaveButton = IconBase(SaveButtonIcon)
export const Export = IconBase(ExportIcon)
export const AlignmentLeft = IconBase(AlignmentLeftIcon)
export const AlignmentCenter = IconBase(AlignmentCenterIcon)
export const AlignmentRight = IconBase(AlignmentRightIcon)
export const ArrowRight = IconBase(ArrowRightIcon)
export const ArrowLeft = IconBase(ArrowLeftIcon)
export const ArrowDropdown = IconBase(ArrowDropdownIcon)
export const ArrowUp = IconBase(ArrowUpIcon)
export const ArrowDown = IconBase(ArrowDownIcon)
export const Cancel = IconBase(CancelIcon)
export const SVGDocument = IconBase(DocumentIcon, { width: 17, height: 17 })
export const Collapse = IconBase(CollapseIcon)
export const Clock = IconBase(ClockIcon)
export const Plus = IconBase(PlusIcon, { width: 17, height: 17 })
export const Replace = IconBase(ReplaceIcon)
export const User = IconBase(UserIcon)
export const Settings = IconBase(SettingsIcon)
export const Embed = IconBase(EmbedIcon)
export const Eye = IconBase(EyeIcon)
export const Expand = IconBase(ExpandIcon)
export const FileDownload = IconBase(FileDownloadIcon)
export const FileUpload = IconBase(UploadIcon)
export const FileExcel = IconBase(FileExcelIcon)
export const FinancialStatistics = IconBase(FinancialStatisticsIcon, { width: '50px', height: '50px' })
export const FilePdf = IconBase(FilePdfIcon)
export const FileWord = IconBase(FileWordIcon)
export const Download = IconBase(DownloadIcon)
export const Save = IconBase(SaveIcon)
export const Minus = IconBase(MinusIcon)
export const Message = IconBase(MessageIcon)
export const SendMessage = IconBase(SendMessageIcon)
export const Login = IconBase(LoginIcon)
export const Search = IconBase(SearchIcon)
export const Dots = IconBase(DotsIcon)
export const Refresh = IconBase(RefreshIcon)
export const Repeat = IconBase(RepeatIcon)
export const Warning = IconBase(WarningIcon)
export const Alarm = IconBase(AlarmIcon)
export const Edit = IconBase(EditIcon)
export const Link = IconBase(LinkIcon)
export const SVGListIcon = IconBase(ListIcon, { width: 17, height: 17 })
export const SharedLink = IconBase(SharedLinkIcon)
export const Info = IconBase(InfoIcon)
export const InfoSecondary = IconBase(InfoSecondaryIcon)
export const Copy = IconBase(CopyIcon)
export const UserAdd = IconBase(UserAddIcon)
export const UserBlock = IconBase(UserBlockIcon)
export const UserNormal = IconBase(UserNormalIcon)
export const Number = IconBase(NumberIcon)
export const String = IconBase(StringIcon)
export const Date = IconBase(DateIcon)
export const Dashboard = IconBase(DashboardIcon)
export const KeyActive = IconBase(KeyActiveIcon)
export const KeyInactive = IconBase(KeyInactiveIcon)
export const Swipe = IconBase(SwipeIcon)
export const Hide = IconBase(HideIcon)
export const Delete = IconBase(DeleteIcon)
export const DeleteCard = IconBase(DeleteCardIcon)
export const Burger = IconBase(BurgerIcon)
export const Polygon = IconBase(PolygonIcon)
export const Question = IconBase(QuestionIcon)
export const Chat = IconBase(ChatIcon)
export const FileSave = IconBase(FileSaveIcon)
export const FileSaveSecondary = IconBase(DisketteIcon)
export const Attach = IconBase(AttachIcon)
export const AttachWithBg = IconBase(AttachWithBgIcon)
export const Cross = IconBase(CrossIcon)
export const Video = IconBase(VideoIcon)
export const EditBadge = IconBase(EditBadgeIcon)
export const DeleteBadge = IconBase(DeleteBadgeIcon)
export const Star = IconBase(StarIcon)
export const Clean = IconBase(CleanIcon)
export const Duplicate = IconBase(DuplicateIcon)
export const ArrowLeftRight = IconBase(ArrowLeftRightIcon)
export const Display = IconBase(DisplayIcon)
export const Forms = IconBase(FormsIcon)
export const Favorites = IconBase(AddFavorites)

// Charts
export const LineChart = IconBase(LineChartIcon)
export const BarChart = IconBase(BarChartIcon)
export const PieChart = IconBase(PieChartIcon)
export const GraphChart = IconBase(GraphChartIcon)
export const ScatterChart = IconBase(ScatterChartIcon)
export const MapChart = IconBase(MapChartIcon)
export const Table = IconBase(TableIcon)
export const Value = IconBase(ValueIcon)
export const Scanner = IconBase(ScannerIcon)
export const Text = IconBase(TextIcon)
export const Image = IconBase(ImageIcon)
export const Publication = IconBase(PublicationIcon)
export const SankeyChart = IconBase(SankeyChartIcon)

// Legend position
export const LegendTop = IconBase(LegendTopIcon)
export const LegendBottom = IconBase(LegendBottomIcon)
export const LegendLeft = IconBase(LegendLeftIcon)
export const LegendRight = IconBase(LegendRightIcon)

// Aggregation menu
export const Aggregation = IconBase(AggregationIcon)
export const Grouping = IconBase(GroupingIcon)
export const Func = IconBase(FuncIcon)
export const Normalize = IconBase(NormalizeIcon)
export const Rounding = IconBase(RoundingIcon)

// Aggregation date presets
export const AggregationPresetDay = IconBase(AggregationPresetDayIcon)
export const AggregationPresetMonth = IconBase(AggregationPresetMonthIcon)
export const AggregationPresetYear = IconBase(AggregationPresetYearIcon)
// Sidemenu
export const Logout = IconBase(LogoutIcon)
export const Notifications = IconBase(NotificationsIcon)
// OtherServices
export const FCHint = IconBase(FCHintIcon)

import React, { Component } from 'react'
import { connect, MapStateToProps, DispatchProp } from 'react-redux'
import { withTheme } from 'react-jss'
import { compose } from 'recompose'
import { Style } from 'react-notification-system'
import Notifications, { NotificationsState } from 'react-notification-system-redux'

interface StateProps {
  notifications: NotificationsState
}

interface Props extends DispatchProp<any>, StateProps {
  theme: App.Theme
}

class NotificationsContainer extends Component<Props & StateProps> {
  get notificationsStyle() {
    const { theme } = this.props
    // Reference: https://github.com/igorprado/react-notification-system/blob/master/src/styles.js
    const style: Style = {
      Containers: {
        DefaultStyle: {
          zIndex: 99999
        }
      },
      NotificationItem: {
        DefaultStyle: {
          padding: '1.25rem',
          backgroundColor: '#FFFFFF',
          borderTop: 'none',
          boxSizing: 'border-box',
          boxShadow: theme.common.page.contentBoxShadow,
          zIndex: 999
        }
      },
      Title: {
        DefaultStyle: {
          margin: '0 1rem 0 0',
          fontFamily: 'Helvetica',
          fontSize: '0.875rem',
          fontWeight: 'bold'
        },
        success: {
          marginRight: '1rem',
          color: theme.colors.blue
        },
        error: {
          marginRight: '1rem',
          color: theme.colors.red
        }
      },
      MessageWrapper: {
        DefaultStyle: {
          marginTop: '1.25rem',
          fontFamily: 'Helvetica',
          fontSize: '0.875rem',
          fontWeight: 'normal',
          color: '#000000'
        }
      },
      Dismiss: {
        DefaultStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          top: '1.25rem',
          right: '1rem',
          height: '1rem',
          width: '1rem',
          lineHeight: '0.9rem',
          backgroundColor: 'transparent',
          borderStyle: 'solid',
          borderWidth: 2
        },
        success: {
          color: theme.colors.blue,
          border: `2px solid ${theme.colors.blue}`
        },
        error: {
          color: theme.colors.red,
          border: `2px solid ${theme.colors.red}`
        }
      }
    }

    return style
  }

  render() {
    const { notifications } = this.props

    return <Notifications style={this.notificationsStyle} notifications={notifications} />
  }
}

const mapStateToProps: MapStateToProps<StateProps, Props, App.State> = state => ({
  notifications: state.notificationSystem
})

export default compose<Props, {}>(
  connect(mapStateToProps),
  withTheme
)(NotificationsContainer)

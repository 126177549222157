import * as actions from './actions'
import { ColumnParamsType } from 'constants/dataImport'
import { SCDATA_SETTINGS_REFRESH_DATA_FORM } from 'constants/forms'
import { Action } from 'redux'
import { Mode } from 'lib/modules/data/making/reducer'

export enum Types {
  CLEAN_CUBE = 'CLEAN_CUBE',
  REMOVE_CUBE = 'REMOVE_CUBE',
  CHANGE_COLUMN_PARAMS = 'MAKING_CHANGE_COLUMN_PARAMS',
  CHANGE_DATA_CELL = 'MAKING_CHANGE_DATA_CELL',
  ADD_COLUMN = 'MAKING_ADD_COLUMN',
  CLEAR_COLUMN = 'MAKING_CLEAR_COLUMN',
  REMOVE_COLUMN = 'MAKING_REMOVE_COLUMN',
  REMOVE_ROW = 'MAKING_REMOVE_ROW',
  ADD_CELL = 'MAKING_ADD_CELL',
  ADD_DATA = 'MAKING_ADD_DATA',
  GET_NSI = 'MAKING_GET_NSI',
  GET_NSI_SUCCESS = 'MAKING_GET_NSI_SUCCESS',
  GET_NSI_FAIL = 'MAKING_GET_NSI_FAIL',
  REFRESH_SETTINGS_DATA_CUBE = 'MAKING_REFRESH_SETTINGS_DATA_CUBE',
  ACCESS_CUBE = 'MAKING_ACCESS_CUBE',
  CREATE_CUBE = 'MAKING_CREATE_CUBE',
  CREATE_CUBE_SUCCESS = 'MAKING_CREATE_CUBE_SUCCESS',
  CREATE_CUBE_FAIL = 'MAKING_CREATE_CUBE_FAIL',
  SEND_FILE = 'MAKING_SEND_FILE',
  SEND_FILE_FAIL = 'MAKING_SEND_FILE_FAIL',
  SEND_FILE_SUCCESS = 'MAKING_SEND_FILE_SUCCESS',
  GET_CUBE = 'MAKING_GET_CUBE',
  GET_CUBE_SUCCESS = 'MAKING_GET_CUBE_SUCCESS',
  GET_CUBE_FAIL = 'MAKING_GET_CUBE_FAIL',
  CHANGE_MODE = 'MAKING_CHANGE_MODE',
  UPDATE_CUBE = 'MAKING_UPDATE_CUBE',
  UPDATE_CUBE_SUCCESS = 'MAKING_UPDATE_CUBE_SUCCESS',
  UPDATE_CUBE_FAIL = 'MAKING_UPDATE_CUBE_FAIL',
  DELETE_CUBE = 'MAKING_DELETE_CUBE',
  DELETE_CUBE_SUCCESS = 'MAKING_DELETE_CUBE_SUCCESS',
  DELETE_CUBE_FAIL = 'MAKING_DELETE_CUBE_FAIL'
}

export interface Cube {
  name: string
  description: string
  nsi: boolean
  source_type: 'manually_created' | string
  columns: {
    [key: string]: {
      pr_key: boolean
      type: ColumnParamsType
      name: string
      include: boolean
      is_editable: boolean
      data: {
        [key: string]: string
      }
    }
  }
  refresh_data: typeof SCDATA_SETTINGS_REFRESH_DATA_FORM.initialValues
  access_users: any[]
}

export interface ChangeColumnParams extends Action<Types.CHANGE_COLUMN_PARAMS> {
  payload: {
    index: number
    params: Partial<Data.ColumnParams>
  }
}

export type AddColumnPayload = Partial<Omit<Data.ColumnParams, 'nsi'>> & { nsi?: number | null }

export interface AddColumn extends Action<Types.ADD_COLUMN> {
  payload: AddColumnPayload
}

export interface ClearColumn extends Action<Types.CLEAR_COLUMN> {
  payload: number
}

export interface RemoveColumn extends Action<Types.REMOVE_COLUMN> {
  payload: number
}

export interface RemoveRow extends Action<Types.REMOVE_ROW> {
  payload: number
}

export interface AddCell extends Action<Types.ADD_CELL> {}

export interface ChangeDataCellPayload {
  columnIndex: number
  rowIndex: number
  value: string
}
export interface ChangeDataCell extends Action<Types.CHANGE_DATA_CELL> {
  payload: ChangeDataCellPayload
}

export interface GetNSI extends Action<Types.GET_NSI> {
  payload: {
    id: number
    key: string
  }
}

export interface GetNSISuccess extends Action<Types.GET_NSI_SUCCESS> {
  payload: {
    [key: string]: {
      headers: Data.ColumnParams[]
      rows: string[][]
    }
  }
}

export interface GetNSIFail extends Action<Types.GET_NSI_FAIL> {}

export interface AddDataPayload {
  id: Data.CubeParams['id']
  data: Array<string | number>
}
export interface AddData extends Action<Types.ADD_DATA> {
  payload: AddDataPayload
}

export interface RemoveCube extends Action<Types.REMOVE_CUBE> {}

export interface CleanCube extends Action<Types.CLEAN_CUBE> {}

export interface RefreshSettingsPayload {
  user: string | null
  day: string | null
  time: string | null
  period: string | null
  alert: []
}

export interface SetRefreshSettingsData extends Action<Types.REFRESH_SETTINGS_DATA_CUBE> {
  payload: RefreshSettingsPayload
}

export interface SetAccessCube extends Action<Types.ACCESS_CUBE> {
  payload: []
}

export interface CreateCube extends Action<Types.CREATE_CUBE> {
  payload: {
    resourceType: string
    requestKey: string
    cube: Cube
  }
}

export interface CreateCubeSuccess extends Action<Types.CREATE_CUBE_SUCCESS> {
  payload: number
}
export interface CreateCubeFail extends Action<Types.CREATE_CUBE_FAIL> {}

export interface SendFilePayload {
  requestKey: string
  resourceType: string
  requestProperties: {
    file: any
    method: string
    id: number | string
  }
}

export interface SendFile extends Action<Types.SEND_FILE> {
  payload: SendFilePayload
}

export interface SendFileFail extends Action<Types.SEND_FILE_FAIL> {}

export interface SendFileSuccess extends Action<Types.SEND_FILE_SUCCESS> {}

export interface GetCube extends Action<Types.GET_CUBE> {
  payload: {
    resourceType: string
    requestKey: string
    id: number | string
  }
}

export interface GetCubeSuccess extends Action<Types.GET_CUBE_SUCCESS> {
  payload: {
    headers: Data.ColumnParams[]
    rows: string[][]
    source_id?: number
  }
}
export interface GetCubeFail extends Action<Types.GET_CUBE_FAIL> {}

export interface ChangeMode extends Action<Types.CHANGE_MODE> {
  payload: Mode
}

export interface UpdateCubePayload {
  resourceType: string
  requestKey: string
  requestProperties: {
    cube: { cube: Cube }
    id: number | string
  }
}

export interface UpdateCube extends Action<Types.UPDATE_CUBE> {
  payload: UpdateCubePayload
}

export interface UpdateCubeSuccess extends Action<Types.UPDATE_CUBE_SUCCESS> {}

export interface UpdateCubeFail extends Action<Types.UPDATE_CUBE_FAIL> {}

export interface DeleteCube extends Action<Types.DELETE_CUBE> {
  payload: {
    resourceType: string
    requestKey: string
    id: number | string
  }
}

export interface DeleteCubeSuccess extends Action<Types.DELETE_CUBE_SUCCESS> {}

export interface DeleteCubeFail extends Action<Types.DELETE_CUBE_FAIL> {}

type MakingReturnType<T> = T extends {
  [key: string]: (...args: any[]) => infer R
}
  ? R
  : never

export type MakingActions = MakingReturnType<typeof actions>

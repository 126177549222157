import React, { Component, ChangeEventHandler, MouseEventHandler } from 'react'
import { compose } from 'recompose'
import injectStyles, { JSSProps } from 'react-jss'
import { Dialog } from '@mui/material'
import { DialogTitle } from '@mui/material'

import { DATE_PRESETS } from 'constants/filter'
import Button from 'components/Button'
import styles from './styles'

interface OuterProps {
  open: boolean
  dateFormatValue: string
  onChange: (value: string) => void
  onClose: () => void
}

interface Props extends OuterProps, JSSProps<typeof styles> {}
interface State {
  value: string
}

class DateFormatModal extends Component<Props, State> {
  timer: NodeJS.Timeout

  constructor(props: Props) {
    super(props)

    this.state = {
      value: props.dateFormatValue
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { dateFormatValue } = this.props

    if (dateFormatValue !== prevProps.dateFormatValue && dateFormatValue !== this.state.value) {
      this.setState({
        value: dateFormatValue
      })
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer)
      delete this.timer
    }
  }

  onChange: ChangeEventHandler<HTMLInputElement> = event => {
    const { onChange } = this.props

    if (this.timer) {
      clearTimeout(this.timer)
      delete this.timer
    }

    event.persist()

    const { value } = event.target

    this.setState({
      value
    })
    this.timer = setTimeout(() => onChange(value), 400)
  }

  onPresetClick: (
    presetValue: string
  ) => MouseEventHandler<HTMLButtonElement> = presetValue => event => {
    const { onChange } = this.props

    this.setState({
      value: presetValue
    })
    onChange(presetValue)
  }

  render() {
    const { classes, open, onClose } = this.props

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle style={{ padding: '0.625rem' }}>Формат времени</DialogTitle>
        <div className={classes.container}>
          <input
            className={classes.input}
            type="text"
            value={this.state.value}
            onChange={this.onChange}
          />
          <h4 className={classes.presetsTitle}>Пресеты:</h4>
          <div className={classes.presets}>
            {DATE_PRESETS.map(preset => (
              <Button
                key={preset.label}
                icon={preset.icon}
                onClick={this.onPresetClick(preset.value)}
              >
                {preset.label}
              </Button>
            ))}
          </div>
          <div className={classes.line} />
          <a
            className={classes.link}
            href="https://clickhouse.yandex/docs/ru/query_language/functions/date_time_functions/#formatdatetime-time-format-91-timezone-93"
            target="_blank" rel="noreferrer"
          >
            Как работает форматирование времени?
          </a>
        </div>
      </Dialog>
    )
  }
}

export default compose<Props, OuterProps>(injectStyles(styles))(DateFormatModal)

const styles = (theme: App.Theme) => ({
  overlay: {
    ...theme.utils.flexContainer({ justify: 'center', alignItems: 'center' }),
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    zIndex: 9999
  },
  container: {
    flex: '0 0 auto',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none',
    '& > *': {
      maxWidth: '100%',
      maxHeight: '100%'
    }
  }
})

export default styles

import format from 'date-fns/format'

import { DATE_FIELD_TYPES } from 'lib/constants/filter'
import { DATE_FORMAT_TYPES } from './dateHelper';

export default ({ type, value }: { type: string; value?: string | Date | string[] }) => {
  switch (type) {
    case DATE_FIELD_TYPES.DATE:
      return format(value as Date, DATE_FORMAT_TYPES.yearMonthDay)
    case DATE_FIELD_TYPES.FIELD:
      return value as string
    case DATE_FIELD_TYPES.MULTI:
      return value as string[]
    default:
      return 'CURDATE()'
  }
}

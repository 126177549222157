import React, { FC, memo, ReactNode, useEffect, useMemo } from 'react'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import injectStyles, { JSSProps } from 'react-jss'
import { compose } from 'recompose'
import classNames from 'classnames'
import { changeFiltering } from 'lib/modules/resourceManager/actions'
import { getTabState } from 'lib/modules/resourceManager/selectors'
import TabSwitch, { OuterProps as TabsOuterProps, Tab } from 'components/TabSwitch'
import styles from './styles'
import { Info } from 'lib/modules/resourceManager/reducer'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSearchParams } from 'lib/hooks/useSearchParams'

interface FilterOption {
  list: string
  name: string
  icon?: ReactNode
}

interface StateProps {
  tab: Info['tab'] | undefined
}

interface DispatchProps {
  changeFiltering: (options: {
    resourceName: string
    tab: {
      list: string
      name: string
    }
  }) => void
}

interface OuterProps extends Omit<TabsOuterProps, 'value' | 'tabs' | 'onSelectTab'> {
  resourceName: string
  filters: FilterOption[]
  isSearchQueryTab?: boolean
}

interface Props extends StateProps, DispatchProps, OuterProps, JSSProps<typeof styles> {}

export const DEFAULT_TAB = {
  list: 'ALL',
  name: 'Все'
}

const Filtering: FC<Props> = memo(
  ({
    filters,
    resourceName,
    tab,
    changeFiltering,
    className,
    classes,
    theme,
    isSearchQueryTab,
    ...restProps
  }) => {
    const tabName = useSearchParams({ name: 'tabName' })

    const navigate = useNavigate()
    const location = useLocation()

    const tabs: Tab<string>[] = useMemo(
      () =>
        filters.map(filter => ({
          value: filter.list,
          label: filter.name,
          icon: filter.icon
        })),
      [filters]
    )

    const onSelectTab = ({ value }: Tab<string>) => {
      if (!tab || tab.list !== value) {
        const newFilter = tabs.find(tab => tab.value === value)

        if (isSearchQueryTab) {
          navigate(`${location.pathname}?tabName=${newFilter.value}`)
        }

        changeFiltering({
          resourceName,
          tab: {
            list: newFilter.value,
            name: newFilter.label
          }
        })
      }
    }

    useEffect(() => {
      if (isSearchQueryTab && tab && tabName !== tab.list) {
        if (tabName) {
          const newTab = filters.find(({ list }) => list === tabName)
          changeFiltering({
            resourceName,
            tab: newTab
          })
        }
      }
    }, [tabName, tab, isSearchQueryTab])

    const value = useMemo(() => (tab ? tab.list : undefined), [tab])

    return (
      <div className={classNames(classes.wrapper, className)}>
        <TabSwitch
          className={classes.container}
          tabs={tabs}
          value={value}
          onSelectTab={onSelectTab}
          {...restProps}
        />
      </div>
    )
  }
)

const mapStateToProps: MapStateToProps<StateProps, Props, App.State> = (state, props) => ({
  tab: getTabState(props.resourceName)(state)
})

const mapDispatchToProps: MapDispatchToProps<DispatchProps, Props> = dispatch => ({
  changeFiltering: options => dispatch(changeFiltering(options))
})

export default compose<Props, OuterProps>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectStyles(styles)
)(Filtering)

import React from 'react'
import { useLocation } from 'react-router'

export function useSearchParams({ name }) {
  const { search } = useLocation()

  return React.useMemo(() => {
    const searchFunction = new URLSearchParams(search)

    if (searchFunction.get) {
      return searchFunction.get(name)
    }

    return null
  }, [search])
}

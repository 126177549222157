import { takeEvery, put, all, select, call } from 'redux-saga/effects'
import { error, success } from 'react-notification-system-redux'
import fetchAPI from 'lib/services/fetchAPI'
import APIAction from 'lib/utils/APIAction'
import getResourceActions from 'lib/utils/getResourceActions'
import { objectToFormData } from 'lib/utils/objectToFormData'
import { tokenSelector } from 'lib/modules/user/selectors'
import { readPreviewCube, startLoading, stopLoading } from '../preview/actions'
import { ReadCubes, CreateCube, UpdateCube, DeleteCube, MergeCube } from './actions'
import { Types } from './types'
import { METHOD, ENDPOINT } from 'constants/api'
import { reset } from 'redux-resource-plugins'

function* readCubes(action: ReadCubes) {
  const { requestKey, resourceType, limit, query } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'READ',
    resourceType,
    requestKey
  })
  const token = yield select(tokenSelector)

  const params = { limit } as {
    limit: number
    search?: string
  }

  if (query) params.search = query

  yield APIAction({
    *request() {
      yield put(onRequestAction())
      yield put(startLoading())
      return yield call(fetchAPI, {
        method: METHOD.GET,
        endpoint: ENDPOINT.CUBES,
        params,
        token
      })
    },
    *success(data) {
      yield put(stopLoading(''))
      yield put(reset.resetResource('cubes'))
      yield put(onSuccessAction(data))
    },
    *fail(errors) {
      yield put(stopLoading(errors))
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось получить данные.'
        })
      )
    }
  })
}

function* createCube(action: CreateCube) {
  const {
    requestKey,
    resourceType,
    requestProperties: {
      name,
      nsi,
      connections = [],
      columns_formats = [],
      period,
      anomaly_condition = '',
      input_error_condition = ''
    }
  } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'CREATE',
    resourceType,
    requestKey
  })
  const { preview }: App.State['data_import']['preview'] = yield select(
    (state: App.State) => state.data_import.preview
  )
  const token = yield select(tokenSelector)

  // TODO На случай редактирование полей
  // if (preview.headers.find(item => item.is_editable && item.is_new)) {
  //   const editableColIndex = preview.headers.findIndex(item => item.is_editable && item.is_new)
  //   const data = preview.rows.map(row => row[editableColIndex])
  //   preview.headers[editableColIndex] = { ...preview.headers[editableColIndex], data }
  // }
  //

  if (!preview.headers.find(header => header.pr_key)) {
    preview.headers[0].pr_key = true
  }

  const requestBody: Pick<
    Data.CubeParams,
    | 'id'
    | 'db_source_id'
    | 'source_type'
    | 'name_original'
    | 'name'
    | 'nsi'
    | 'period'
    | 'columns'
    | 'columns_formats'
    | 'connections'
    | 'anomaly_condition'
    | 'input_error_condition'
  > = {
    db_source_id: preview.source_type === 'db' ? preview.source_id : undefined,
    name,
    nsi,
    source_type: preview.source_type === 'db' ? 'table' : 'file',
    name_original: preview.source_type === 'db' ? preview.table : undefined,
    id: preview.source_type === 'file' ? preview.source_id : undefined,
    period,
    connections,
    columns: preview.headers,
    columns_formats,
    anomaly_condition,
    input_error_condition
  }

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        method: METHOD.POST,
        endpoint: ENDPOINT.CUBES,
        body: objectToFormData(requestBody, 'cube'),
        token
      })
    },
    *success(data) {
      yield put(onSuccessAction([data]))
      yield put(readPreviewCube(data.id))
      yield put(success({ title: 'Успешно создано' }))
    },
    *fail(errors) {
      yield put(onFailAction(errors))
      yield put(stopLoading(errors))
      yield put(
        error({
          title: 'Не удалось получить данные.'
        })
      )
    }
  })
}

function* mergeCube(action: MergeCube) {
  const {
    requestKey,
    resourceType,
    requestProperties: { cube_id, column, merged_cube_id, merged_cube_column }
  } = action.payload

  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'CREATE',
    resourceType,
    requestKey
  })

  const token = yield select(tokenSelector)

  const requestBody = {
    cube_id,
    column,
    merged_cube_id,
    merged_cube_column
  }

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        method: METHOD.POST,
        endpoint: ENDPOINT.CUBES_MERGE,
        body: objectToFormData(requestBody, 'cube'),
        token
      })
    },
    *success(data) {
      yield put(onSuccessAction([data]))
      yield put(readPreviewCube(data.id))
      yield put(success({ title: 'Успешное объединение' }))
    },
    *fail(errors) {
      yield put(onFailAction(errors))
      yield put(stopLoading(errors))
      yield put(
        error({
          title: 'Не удалось объединить кубы.'
        })
      )
    }
  })
}

function* updateCube(action: UpdateCube) {
  const { requestKey, resourceType, requestProperties } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'UPDATE',
    resourceType,
    requestKey,
    requestProperties
  })
  const { preview }: App.State['data_import']['preview'] = yield select(
    (state: App.State) => state.data_import.preview
  )
  const token = yield select(tokenSelector)

  const {
    name,
    nsi,
    connections = [],
    columns_formats = [],
    anomaly_condition = '',
    input_error_condition = ''
  } = requestProperties

  const requestBody: Pick<
    Data.CubeParams,
    | 'name'
    | 'nsi'
    | 'columns'
    | 'columns_formats'
    | 'connections'
    | 'anomaly_condition'
    | 'input_error_condition'
  > = {
    name,
    nsi,
    columns: preview.headers,
    columns_formats,
    connections,
    anomaly_condition,
    input_error_condition
  }

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        method: METHOD.PUT,
        endpoint: ENDPOINT.CUBES,
        path: requestProperties.id.toString(),
        body: objectToFormData(requestBody, 'cube'),
        token
      })
    },
    *success(data) {
      yield put(onSuccessAction(data))
      yield put(readPreviewCube(data[0].id))
      yield put(success({ title: 'Успешно сохранено' }))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось обновить куб.'
        })
      )
    }
  })
}

function* deleteCube(action: DeleteCube) {
  const { requestKey, resourceType, requestProperties } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'DELETE',
    resourceType,
    requestKey,
    requestProperties
  })
  const token = yield select(tokenSelector)

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        method: METHOD.DELETE,
        endpoint: ENDPOINT.CUBES,
        path: requestProperties.id.toString(),
        token
      })
    },
    *success() {
      yield put(onSuccessAction([requestProperties.id]))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось удалить куб.'
        })
      )
    }
  })
}

export function* watcher() {
  yield all([
    takeEvery(Types.READ, readCubes),
    takeEvery(Types.CREATE, createCube),
    takeEvery(Types.MERGE, mergeCube),
    takeEvery(Types.UPDATE, updateCube),
    takeEvery(Types.DELETE, deleteCube)
  ])
}

export { Types } from './actionTypes'
export { initIndex, destroyIndex, changeSortField, sortingActionCreators } from './actions'

export {
  getCurrentSortingDirection,
  getCurrentSortingField,
  getSortingFields,
  getSortingIndex
} from './selectors'

export enum SORT_DIRECTION {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING'
}

export interface SortField {
  field: string
  label: string
  directions: {
    [SORT_DIRECTION.ASCENDING]: boolean
    [SORT_DIRECTION.DESCENDING]: boolean
  }
}

export interface SearchIndex {
  enabled: boolean
  value?: string
  fields?: string[]
}

export interface SortIndex {
  fields: SortField[]
  currentField: SortField
  currentDirection: SORT_DIRECTION
  search: SearchIndex
}

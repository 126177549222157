import React, { PureComponent, Fragment, ButtonHTMLAttributes } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import { compose } from 'recompose'
import { WrappedFieldProps } from 'redux-form'
import classnames from 'classnames'
import { Props as TooltipProps, Tooltip } from 'react-tooltip'
import isEqual from 'lodash/isEqual'

import styles from './styles'

export interface Option<OValue = string> {
  value: OValue
  label: string
  icon: JSX.Element | string
}

interface OuterProps {
  options: Option[]
  id: string
  title: string
  withInitialValue?: boolean
  tooltipProps?: Partial<Omit<TooltipProps, 'id'>>
  containerProps?: Partial<ButtonHTMLAttributes<HTMLButtonElement>>
  // Fix strange ts error
  component?: any
}
export interface Props extends JSSProps<typeof styles>, OuterProps, WrappedFieldProps {}

class CompareValue extends PureComponent<Props> {
  buttonRef: HTMLButtonElement

  static defaultProps: Partial<OuterProps> = {
    tooltipProps: { place: 'bottom', effect: 'solid', type: 'light' },
    containerProps: {}
  }

  componentDidMount() {
    this.update(this.props)
  }

  componentWillReceiveProps(nextProps: Props) {
    if (!isEqual(this.props.options, nextProps.options)) {
      this.update(nextProps)
    }
  }

  update(props: Props) {
    const {
      options,
      withInitialValue,
      input: { onChange, value }
    } = props

    if (options.length && withInitialValue && !options.some(option => option.value === value)) {
      onChange(options[0].value)
    }
  }

  onSelect = (option: Option) => {
    const {
      input: { onChange, onBlur }
    } = this.props

    this.hideTooltip()
    onChange(option.value)
    onBlur(option.value)
  }

  onFocus = event => this.props.input.onFocus(event)

  onBlur = event => {
    const {
      input: { value, onBlur }
    } = this.props

    onBlur(value)
  }

  hideTooltip = () => {
    if (this.buttonRef) Tooltip.hide(this.buttonRef)
  }

  showTooltip = () => {
    if (this.buttonRef) Tooltip.show(this.buttonRef)
  }

  render() {
    const {
      classes,
      options,
      id,
      title,
      input,
      meta,
      tooltipProps,
      containerProps: { disabled, ...containerProps }
    } = this.props

    return (
      <Fragment>
        <Tooltip
          {...tooltipProps}
          id={id}
          events={['click']}
          className={classnames(classes.tooltip, tooltipProps.className)}
        >
          <div className={classes.tooltipContent}>
            <div className={classes.optionsList}>
              {options.map(option => (
                <div
                  key={`${option.value}`}
                  onClick={() => this.onSelect(option)}
                  className={classes.optionRow}
                >
                  <span className={classes.symbol}>{option.icon}</span>
                  {option.label}
                </div>
              ))}
            </div>
          </div>
        </Tooltip>
        <button
          {...containerProps}
          type="button"
          ref={element => (this.buttonRef = element)}
          data-tooltip-id={id}
          data-tip=""
          data-iscapture="true"
          className={classnames(
            classes.box,
            containerProps.className,
            disabled && classes.boxDisabled,
            meta.invalid && meta.touched && classes.error
          )}
          disabled={disabled}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        >
          <span className={classes.symbol}>
            {options.find(item => item.value === input.value)
              ? options.find(item => item.value === input.value).icon
              : null}
          </span>
        </button>
      </Fragment>
    )
  }
}

export default compose<Props, OuterProps>(injectStyles(styles))(CompareValue)

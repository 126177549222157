const styles = (theme: App.Theme) => ({
  block: {
    ...theme.utils.flexContainer({ direction: 'column' }),
    flexShrink: 0,
    width: '100%',
    zIndex: 'inherit',
    boxSizing: 'border-box',
    pointerEvents: 'auto',
    '& > *:not($titleSmall)': {
      marginBottom: '0.625rem'
    }
  },
  titleSmall: {
    width: '100%',
    margin: '0.625rem 0',
    padding: '0 calc(1.25rem + 2px)',
    fontSize: '0.625rem',
    lineHeight: 1.2,
    fontWeight: 'bold',
    letterSpacing: 0.5,
    textTransform: 'uppercase',
    color: theme.colors.grey,
    boxSizing: 'border-box'
  },
  row: {
    display: 'flex',
    width: '100%',
    padding: '0 0.625rem',
    '& > *:not(:last-child)': {
      marginRight: '0.625rem'
    }
  },
  tabs: {},
  periodInput: {
    flexShrink: 1,
    width: '100%',
    padding: `0.5em 0.625em`,
    fontWeight: 'normal',
    fontSize: '1rem',
    lineHeight: 1.2,
    color: '#000',
    border: `1px solid ${theme.colors.input.border}`,
    borderRadius: '0.5rem',
    outline: 'none',
    backgroundColor: 'transparent',
    '&::placeholder': {
      color: theme.colors.grey
    }
  },
  dayTypeToggler: {
    flexShrink: 0
  }
})

export default styles

import {
  Number as NumberIcon,
  String as StringIcon,
  Date as DateIcon,
  Polygon as PolygonIcon
} from '../../components/Icons'
import { METHOD, ENDPOINT } from '../constants/api'

export const ACTIONS_PREFIX = `DATA`

export const RESOURCES = {
  CUBES: {
    NAME: 'cubes',
    REQUESTS: {
      READ: `READ_${ACTIONS_PREFIX}_CUBES`,
      CREATE: `CREATE_${ACTIONS_PREFIX}_CUBE`,
      MERGE: `MERGE_${ACTIONS_PREFIX}_CUBE`,
      UPDATE: `UPDATE_${ACTIONS_PREFIX}_CUBE`,
      DELETE: `DELETE_${ACTIONS_PREFIX}_CUBE`
    },
    LISTS: {}
  },
  SOURCES: {
    NAME: 'sources',
    REQUESTS: {
      READ: `READ_${ACTIONS_PREFIX}_SOURCES`,
      CREATE_SOURCE: `CREATE_${ACTIONS_PREFIX}_SOURCE`
    },
    LISTS: {}
  },
  MAKING_CUBE: {
    NAME: 'making',
    REQUESTS: {
      READ: `READ_CUBE`,
      CREATE: `CREATE_CUBE`,
      UPDATE: `UPDATE_CUBE`,
      DELETE: `DELETE_CUBE`
    },
    LISTS: {}
  }
}

export type PreviewSource = 'file' | 'db'

export enum DataSource {
  file = 'file',
  db = 'db'
}

export const acceptedFileMimeTypes = [
  'application/xml',
  'text/xml',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/json',
  'text/csv'
]

// Helpers for connectors
export interface DbInput {
  host: string
  port: string
  db_name: string
  login: string
  password: string
  db_type: string
}

export interface FileInput {
  file: File
}

export type ConnectorInput = DbInput | FileInput

export interface DataSourceConnectorSubmitOptions {
  data: ConnectorInput
  root_key: string
  path: string
  method?: METHOD
}
export type DataSourceConnectorSubmit = (options: DataSourceConnectorSubmitOptions) => void

export const parsingHelpers = {
  [DataSource.file]: {
    endpoint: ENDPOINT.DATA_IMPORT,
    method: METHOD.POST,
    root_key: 'import_file',
    parseSubmit: (data: FileInput) => ({ data, root_key: 'import_file' })
  },
  [DataSource.db]: {
    endpoint: ENDPOINT.DB_SOURCE,
    method: METHOD.POST,
    root_key: 'db_source',
    parseSubmit: (data: DbInput) => ({ data, root_key: 'db_source' })
  }
}

export enum ColumnAlgorithmType {
  none = 'none',
  rounding = 'rounding',
  million = 'million',
  hectareToMeter = 'hectareToMeter',
  meterToHectare = 'meterToHectare'
}

export const COLUMN_ALGORITHM: { [K in keyof typeof ColumnAlgorithmType]: { name: string } } = {
  [ColumnAlgorithmType.none]: {
    name: 'Без алгоритма'
  },
  [ColumnAlgorithmType.rounding]: {
    name: 'С округлением до целых'
  },
  [ColumnAlgorithmType.million]: {
    name: 'Перевод в млн.'
  },
  [ColumnAlgorithmType.hectareToMeter]: {
    name: 'Перевод гектаров в квадратные метры'
  },
  [ColumnAlgorithmType.meterToHectare]: {
    name: 'Перевод квадратных метров в гектары'
  }
}

export enum ColumnParamsType {
  String = 'String',
  DateTime = 'DateTime',
  Float64 = 'Float64',
  geo_title = 'geo_title',
  geo_poly = 'geo_poly'
}

export const COLUMN_TYPE: {
  [K in keyof typeof ColumnParamsType]: { name: string; icon: typeof NumberIcon }
} = {
  [ColumnParamsType.Float64]: { name: 'Число', icon: NumberIcon },
  [ColumnParamsType.String]: { name: 'Строка', icon: StringIcon },
  [ColumnParamsType.DateTime]: { name: 'Дата', icon: DateIcon },
  [ColumnParamsType.geo_title]: { name: 'Гео имя', icon: StringIcon },
  [ColumnParamsType.geo_poly]: { name: 'Гео полигоны', icon: PolygonIcon }
}

export enum CubeErrorType {
  anomaly = 'anomaly',
  input_error = 'input_error',
  empty = 'empty',
  format_error = 'format_error',
  duplicate = 'duplicate'
}

export const CUBE_ERRORS: {
  [key: string]: {
    name: string
    description?: string
    withConditions?: boolean
  }
} = {
  [CubeErrorType.anomaly]: {
    name: 'Аномальные значения',
    withConditions: true
  },
  [CubeErrorType.input_error]: {
    name: 'Ошибки ввода данных',
    withConditions: true
  },
  [CubeErrorType.empty]: {
    name: 'Отсутствие данных'
  },
  [CubeErrorType.format_error]: {
    name: 'Ошибки формата'
  },
  [CubeErrorType.duplicate]: {
    name: 'Дублирование',
    description: 'Дублирующиеся данные, удаленные cистемой.'
  }
}

export const DB_TYPE_LABELS = {
  pg: 'PostgreSQL',
  mysql: 'MySQL',
  mssql: 'Microsoft SQL',
  oracle: 'Oracle Database'
}

export const CUBE_IMPORT_PERIOD_LABELS = {
  never: 'Никогда',
  daily: 'Ежедневно',
  weekly: 'Еженедельно',
  monthly: 'Ежемесячно'
}

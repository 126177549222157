import React, { PureComponent, ChangeEvent } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import { compose } from 'recompose'

import Toggler from '../Toggler'
import TabSwitch, { Tab, TabChageEvent } from 'components/TabSwitch'
import { DEFAULT_VALUES, OPTION_TYPES } from 'lib/constants/filter'
import styles from './styles'

interface OuterProps {
  name: string
  value: Filter.DateField['field_modification'] | undefined
  onChangeFieldModification: (fieldModification: Filter.DateField['field_modification']) => void
}
interface Props extends JSSProps<typeof styles>, OuterProps {}
interface State {}

class DateRange extends PureComponent<Props, State> {
  get monthsTabs() {
    return [
      {
        value: 'days',
        label: 'День'
      },
      {
        value: 'months',
        label: 'Месяц'
      },
      {
        value: 'years',
        label: 'Год'
      }
    ]
  }

  onSelectTab: TabChageEvent = (tab: Tab<string>) => {
    const { value, onChangeFieldModification } = this.props
    const result: Filter.DateField['field_modification'] = (value && { ...value }) || {
      ...DEFAULT_VALUES[OPTION_TYPES.TIME].field_modification
    }

    result.modification_period = tab.value

    onChangeFieldModification(result)
  }

  formatFieldModificationField = (
    field_modification: Filter.DateField['field_modification'] | undefined
  ): string => {
    let result = ''

    if (field_modification) {
      const { modification_sym, modification_val } = field_modification

      if (modification_sym && modification_sym === '-') {
        result += modification_sym
      }

      if (modification_val) {
        result += modification_val
      }
    }

    return result
  }

  onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, onChangeFieldModification } = this.props
    const result: Filter.DateField['field_modification'] = (value && { ...value }) || {
      ...DEFAULT_VALUES[OPTION_TYPES.TIME].field_modification
    }
    const newValue = event.currentTarget.value

    if (!!newValue) {
      const valueNumber = +newValue

      if (!isNaN(valueNumber)) {
        result.modification_sym = valueNumber > 0 ? '+' : '-'
        result.modification_val = Math.abs(valueNumber).toString()
      } else if (newValue === '-' || newValue === '+') {
        result.modification_sym = newValue
        result.modification_val = '0'
      }
    } else {
      result.modification_val = '0'
      result.modification_sym = '+'
    }

    onChangeFieldModification(result)
  }

  // onFocus = (event: FocusEvent<HTMLInputElement | HTMLButtonElement>) => {
  //   event.preventDefault()
  //   this.props.input.onFocus(event)
  // }

  // onBlur = (event: FocusEvent<HTMLInputElement | HTMLButtonElement>) => {
  //   event.nativeEvent.stopPropagation()

  //   const {
  //     input: { value, onBlur }
  //   } = this.props

  //   onBlur(value)
  // }

  onToggle = (isCalendarDays: string) => {
    const { value, onChangeFieldModification } = this.props
    const result: Filter.DateField['field_modification'] = (value && { ...value }) || {
      ...DEFAULT_VALUES[OPTION_TYPES.TIME].field_modification
    }

    result.modification_calendar_days = isCalendarDays

    onChangeFieldModification(result)
  }

  render() {
    const { classes, name, value } = this.props

    return (
      <div className={classes.block}>
        <h4 className={classes.titleSmall}>Временной промежуток</h4>
        <div className={classes.row}>
          <TabSwitch
            className={classes.tabs}
            tabs={this.monthsTabs}
            onSelectTab={this.onSelectTab}
            value={value && value.modification_period}
          />
        </div>
        <div className={classes.row}>
          <input
            id={`${name}.period`}
            name={`${name}.period`}
            className={classes.periodInput}
            type="text"
            value={
              value
                ? `${value.modification_sym === '-' ? value.modification_sym : ''}${
                    !Number(value.modification_val) ? '' : value.modification_val
                  }`
                : ''
            }
            placeholder="0"
            onChange={this.onChangeValue}
          />
          <Toggler
            className={classes.dayTypeToggler}
            name={`${name}.dayType`}
            value={value ? value.modification_calendar_days : 'true'}
            onToggle={this.onToggle}
          />
        </div>
      </div>
    )
  }
}

export default compose<Props, OuterProps>(injectStyles(styles))(DateRange)

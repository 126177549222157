import React, { Component } from 'react'

import { compose } from 'recompose'
import injectStyles, { JSSProps } from 'react-jss'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { reduxForm, getFormValues, InjectedFormProps, FieldArray } from 'redux-form'

import styles from './styles'
import { ACL, ACLMap } from 'src/lib/utils/aclMask'
import { authorizedSelector } from 'src/lib/modules/user/selectors'
import { toggleModal, updateInfoSystems } from 'src/lib/modules/infoSystems/actions'
import Button from 'components/Button'
import { Plus, Cancel } from 'components/Icons'
import BaseModal from 'components/Modal/components/Base'
import { INFO_SYSTEMS_FORM } from 'src/lib/constants/forms'
import { STATES } from 'src/lib/constants/states'
import DraggableRow from './components/DraggableRow'

export interface InfoSystemsFormValues {
  items: Data.InfoSystem[]
}

interface OuterProps {}
interface StateProps {
  isAuthorized: boolean
  isSystemsLoaded: boolean
  aclMap: ACLMap
  infoSystems: Data.InfoSystem[]
  modalOpened: boolean
  values?: InfoSystemsFormValues
}
interface DispatchProps {
  toggleModal: () => void
  updateSystems: (systems: Data.InfoSystem[]) => void
}
export interface Props
  extends InjectedFormProps<InfoSystemsFormValues, Props>,
    StateProps,
    DispatchProps,
    JSSProps<typeof styles>,
    OuterProps {}

interface State {
  initialized: boolean
}

const makeEmptySystem = (position: number): Data.InfoSystem => {
  return {
    title: null,
    url: null,
    icon: null,
    iconPreview: null,
    position
  }
}

class InfoSystemModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      initialized: false
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    const { infoSystems, initialize, isSystemsLoaded } = nextProps
    if (isSystemsLoaded && !this.state.initialized) {
      initialize({ items: infoSystems })
      this.setState({ initialized: true })
    }
  }

  get canManage() {
    return this.props.aclMap[ACL.INFO_SYSTEMS_WRITE]
  }

  handleSubmit = () => {
    const { values, updateSystems } = this.props
    updateSystems(values.items)
  }

  renderSystems = ({ fields, meta: { error, submitFailed } }) => {
    const { classes, change, values } = this.props
    return (
      <>
        <ul className={classes.list}>
          {fields.map((member, index) => {
            return (
              <li key={index} className={classes.listItem}>
                <DraggableRow
                  key={index}
                  index={index}
                  system={fields.get(index)}
                  member={member}
                  change={change}
                  remove={() => fields.remove(index)}
                  move={(dragIndex: number, hoverIndex: number) =>
                    fields.move(dragIndex, hoverIndex)
                  }
                />
              </li>
            )
          })}
        </ul>
        <div className={classes.addButtonContainer}>
          <Button
            className={classes.addButton}
            icon={<Plus />}
            iconPosition="left"
            onClick={() => {
              fields.push(makeEmptySystem(fields.length))
            }}
          >
            Добавить ИС
          </Button>
        </div>
      </>
    )
  }

  render() {
    const { classes, modalOpened, toggleModal } = this.props
    return this.canManage ? (
      <BaseModal isOpen={modalOpened} onRequestClose={toggleModal}>
        <div className={classes.wrapper}>
          <div className={classes.container}>
            <div className={classes.header}>
              <h3 className={classes.title}>Настройки ИС</h3>
            </div>
            <div className={classes.content}>
              <FieldArray
                name="items"
                // @ts-ignore
                component={this.renderSystems}
              />
            </div>
            <div className={classes.footer}>
              <Button
                className={classes.footerButton}
                icon={<Cancel />}
                iconPosition="left"
                onClick={toggleModal}
              >
                Отменить
              </Button>
              <Button
                color="primary"
                type="submit"
                className={classes.footerButton}
                icon={<Plus />}
                iconPosition="left"
                onClick={this.handleSubmit}
              >
                Сохранить
              </Button>
            </div>
          </div>
        </div>
      </BaseModal>
    ) : null
  }
}

const mapStateToProps: MapStateToProps<StateProps, Props, App.State> = state => ({
  isAuthorized: authorizedSelector(state),
  aclMap: state.user.aclMap,
  infoSystems: state.info_systems.list,
  modalOpened: state.info_systems.modalOpened,
  values: getFormValues(INFO_SYSTEMS_FORM.form)(state) as InfoSystemsFormValues,
  isSystemsLoaded: state.info_systems.readStatus === STATES.SUCCEEDED
})
const mapDispatchToProps: MapDispatchToProps<DispatchProps, Props> = dispatch => ({
  toggleModal: () => dispatch(toggleModal()),
  updateSystems: (systems: Data.InfoSystem[]) => dispatch(updateInfoSystems(systems))
})

export default compose<Props, OuterProps>(
  
  reduxForm(INFO_SYSTEMS_FORM),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectStyles(styles),
)(InfoSystemModal)

import { Action } from 'redux'
import types from './types'

export interface ReadBackgrounds extends Action<types.READ_BACKGROUNDS> {}
export interface ReadBackgroundsRequest extends Action<types.READ_BACKGROUNDS_REQUEST> {}
export interface ReadBackgroundsSuccess extends Action<types.READ_BACKGROUNDS_SUCCESS> {
  payload: {
    list: { [key: string]: string }
  }
}
export interface ReadBackgroundsFailure extends Action<types.READ_BACKGROUNDS_FAILURE> {}

export type ActionTypes =
  | ReadBackgrounds
  | ReadBackgroundsRequest
  | ReadBackgroundsSuccess
  | ReadBackgroundsFailure

export const readBackgrounds: () => ReadBackgrounds = () => ({
  type: types.READ_BACKGROUNDS
})
export const readBackgroundsRequest: () => ReadBackgroundsRequest = () => ({
  type: types.READ_BACKGROUNDS_REQUEST
})

export const readBackgroundsSuccess: (list: {
  [key: string]: string
}) => ReadBackgroundsSuccess = list => ({
  type: types.READ_BACKGROUNDS_SUCCESS,
  payload: {
    list
  }
})

export const readBackgroundsFailure: () => ReadBackgroundsFailure = () => ({
  type: types.READ_BACKGROUNDS_FAILURE
})

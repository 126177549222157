import { takeEvery, put, select, call, all, take } from 'redux-saga/effects'
import { actionTypes as resourceActionTypes } from 'redux-resource'

import ActionCableService from 'lib/services/actionCable'
import { tokenSelector } from 'lib/modules/user/selectors'
import { getAllCharts } from 'lib/modules/charts/selectors'
import {
  readAllElementsRequest,
  readAllElementsRequestSuccess,
  readAllElementsRequestFailure,
  GetAllElements,
  readWidgetsRequest,
  readWidgetsSuccess,
  readWidgetsFailure,
  GetWidgets
} from './actions'
import { BASE_CHANNEL } from 'constants/actionCable'
import { TYPES as ELEMENT_TYPES, CHART_TYPES } from 'constants/dataView'
import { CHARTS, PUBLICATIONS } from 'constants/resources'
import types from './types'

function* getAllElements(action: GetAllElements) {
  yield put(readAllElementsRequest())

  try {
    const token = yield select(tokenSelector)
    const elements = yield select(getAllCharts)

    const {
      payload: data
    }: {
      payload: Array<{
        id: string
        type_name: string
        elements: Array<Data.Chart | Data.Publication>
      }>
    } = yield ActionCableService.sendMessage({
      channelName: BASE_CHANNEL,
      token,
      type: action.type,
      payload: action.payload
    })

    const updating = {
      type: resourceActionTypes.UPDATE_RESOURCES,
      resources: {},
      lists: {}
    }
    const chartTypesValues = Object.values(CHART_TYPES)

    data.forEach(group => {
      let resourceConfig: {
        NAME: string
        REQUESTS: Object
        LISTS: { [name: string]: string }
      }

      if (chartTypesValues.includes(group.id)) {
        resourceConfig = CHARTS
      } else {
        switch (group.id) {
          case ELEMENT_TYPES.PUBLICATION:
            resourceConfig = PUBLICATIONS
            break
          default:
            throw new Error(`Unexpected resource name: '${group.id}'`)
        }
      }

      const { NAME, LISTS } = resourceConfig

      if (!updating.resources[NAME]) {
        updating.resources[NAME] = {}
      }

      if (!updating.lists[NAME]) {
        updating.lists[NAME] = {
          [LISTS.ALL]: []
        }
      }

      if (action.payload.offset) {
        if (action.payload.offset) {
          elements.forEach(element => {
            updating.resources[NAME][element.id] = element
            if (!updating.lists[NAME][LISTS.ALL].includes(element.id))
              updating.lists[NAME][LISTS.ALL].push(element.id)
          })
        }
      }

      if (group.elements) {
        group.elements.forEach(element => {
          updating.resources[NAME][element.id] = element
          updating.lists[NAME][LISTS.ALL].push(element.id)
        })
      }
    })

    yield put(updating)
    yield put(readAllElementsRequestSuccess(data))
  } catch (error) {
    yield put(readAllElementsRequestFailure(error))
  }
}

function* getWidgets(action: GetWidgets) {
  yield put(readWidgetsRequest())

  try {
    const token = yield select(tokenSelector)

    const {
      payload: data
    }: {
      payload: Data.Widget[]
    } = yield ActionCableService.sendMessage({
      channelName: BASE_CHANNEL,
      token,
      type: action.type,
      payload: action.payload
    })

    yield put(readWidgetsSuccess(data))
  } catch (error) {
    yield put(readWidgetsFailure(error))
  }
}

export function* watcher() {
  yield all([
    takeEvery(types.GET_ALL_PANELS_ELEMENTS, getAllElements),
    takeEvery(types.GET_WIDGETS, getWidgets)
  ])
}

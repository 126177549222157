import React, { PureComponent, ComponentType } from 'react'
import { Option } from 'react-select'
import injectStyles, { JSSProps } from 'react-jss'
import classNames from 'classnames'

import styles from './styles'

interface OuterProps {
  option: Option
  valueKey?: string
  labelKey?: string
  multi?: boolean
}
export interface Props extends OuterProps, JSSProps<typeof styles> {}

class Value extends PureComponent<Props> {
  render() {
    const { classes, option, labelKey, multi } = this.props
    const title = option.title
    const label = labelKey ? option[labelKey] : option.label
    const Icon = option.icon

    return multi ? (
      label || ''
    ) : (
      <div className="Select-value" title={title}>
        <span className={classNames('Select-value-label', classes.container)}>
          {!!Icon && <Icon className={classes.icon} />}
          <span className={classes.text}>{label}</span>
        </span>
      </div>
    )
  }
}

export default injectStyles<OuterProps>(styles)(Value)

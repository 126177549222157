import { AxiosError } from 'axios'
import { actionTypes as RESOURCES_TYPES } from 'redux-resource'
import { all, call, put, race, select, take, takeEvery } from 'redux-saga/effects'

import { RESOURCE_ENDPOINT } from 'constants/api'
import { COMMON_MANAGMENT } from 'constants/resources'
import { STATES } from 'constants/states'
import { DEFAULT_TAB } from 'containers/ResourceManager/components/Filtering'
import { tokenSelector } from 'lib/modules/user/selectors'
import fetchAPI from 'lib/services/fetchAPI'
import TYPES from './actionTypes'
import {
  ChangeFiltering,
  ChangeSearch,
  ChangeSorting,
  DeleteResource,
  InitManager,
  ReadMoreResources,
  ReadResources,
  changeLoadingButton,
  changeManager,
  changeRequestState,
  readResources
} from './actions'
import { Info, initialManagerState } from './reducer'
import { getResourceManagerState } from './selectors'

function* readResourcesSaga(action: ReadResources) {
  const { resourceName, merge } = action.payload
  const token = yield select(tokenSelector)
  const {
    offset,
    limit,
    search,
    sorting,
    tab,
    list,
    isEnded = false,
    responseData,
    searchName,
    additionalParams,
    getParams,
    filterName
  }: Info = yield select(getResourceManagerState(resourceName))

  let state = STATES.IDLE

  try {
    state = STATES.LOADING

    yield put(changeRequestState({ resourceName, state, isEnded, responseData }))

    if (merge) {
      yield put(
        changeLoadingButton({
          resourceName,
          loadingButton: { text: 'Подождите...', state }
        })
      )
    }

    yield put({
      type: RESOURCES_TYPES.READ_RESOURCES_PENDING,
      resourceType: resourceName,
      requestKey: COMMON_MANAGMENT.REQUESTS.GET_RESOURCES
    })

    const initialParams = {
      endpoint: RESOURCE_ENDPOINT[resourceName],
      token,
      params: {
        offset,
        limit: limit + 1,
        [searchName || 'q']: search,
        order_by: sorting && sorting.field,
        order: sorting && sorting.order,
        [filterName || 'filter']: tab && tab.list !== DEFAULT_TAB.list ? tab.list : undefined,
        ...additionalParams,
      }
    }

    const allParams = getParams ? getParams(initialParams) : initialParams

    const {
      response,
      cancel
    }: {
      response: { data: Array<{}> | { materials: Array<{}>; objects: Array<{}> } }
      cancel: {
        type: string
        payload?: {
          resourceName?: string
        }
      }
    } = yield race({
      response: call(fetchAPI, allParams),
      cancel: take((action: { type: string; payload?: { resourceName?: string } }) => {
        // If the action dispatched from current resource manager
        // and it is a destroy action or a new resources request
        return (
          action.payload &&
          action.payload &&
          action.payload.resourceName === resourceName &&
          (action.type === TYPES.DESTROY_MANAGER_STATE || action.type === TYPES.READ_RESOURCES)
        )
      })
    })

    if (!cancel) {
      const resources = Array.isArray(response.data) ? response.data : response.data.objects || []

      yield put({
        type: RESOURCES_TYPES.READ_RESOURCES_SUCCEEDED,
        resourceType: resourceName,
        requestKey: COMMON_MANAGMENT.REQUESTS.GET_RESOURCES,
        list,
        mergeResources: merge,
        mergeListIds: merge,
        mergeMeta: merge,
        resources: resources.slice(0, limit),
        responseData: response.data
      })

      state = STATES.SUCCEEDED

      yield put(
        changeRequestState({
          resourceName,
          state,
          isEnded: resources.length <= limit,
          responseData: response.data
        })
      )

      if (merge) {
        yield put(
          changeLoadingButton({
            resourceName,
            loadingButton: {
              text: initialManagerState.loadingButton.text,
              state
            }
          })
        )
      }
    } else {
      yield put({
        type: RESOURCES_TYPES.READ_RESOURCES_SUCCEEDED,
        resourceType: resourceName,
        requestKey: COMMON_MANAGMENT.REQUESTS.GET_RESOURCES,
        mergeResources: true,
        mergeListIds: true,
        mergeMeta: true,
        resources: [],
        responseData: {}
      })
    }
  } catch (exception) {
    state = STATES.FAILED

    let errors = {
      _error: exception
    }

    if (exception.response) {
      errors = (exception as AxiosError).response.data.errors
    }

    yield put({
      type: RESOURCES_TYPES.READ_RESOURCES_FAILED,
      resourceType: resourceName,
      requestKey: COMMON_MANAGMENT.REQUESTS.GET_RESOURCES,
      requestProperties: { errors }
    })

    yield put(changeRequestState({ resourceName, state, isEnded, responseData: {} }))

    if (merge) {
      yield put(
        changeManager({
          resourceName,
          state: {
            offset: offset - limit >= 0 ? offset - limit : 0
          }
        })
      )
      yield put(
        changeLoadingButton({
          resourceName,
          loadingButton: { text: 'Ошибка. Попробуйте еще раз.', state }
        })
      )
    }
  }
}

export function* initResourceManagerStateSaga(action: InitManager) {
  yield put(readResources(action.payload.resourceName))
}

export function* readMoreResourcesSaga(action: ReadMoreResources) {
  yield put(readResources(action.payload.resourceName, true))
}

export function* changeSortingSaga(action: ChangeSorting) {
  yield put(readResources(action.payload.resourceName))
}

export function* changeFilteringSaga(action: ChangeFiltering) {
  yield put(readResources(action.payload.resourceName))
}

export function* changeSearchingSaga(action: ChangeSearch) {
  yield put(readResources(action.payload.resourceName))
}

export function* deletingResourceSaga(action: DeleteResource) {
  const { resourceType, resources } = action
  const resourceManager: Info | undefined = yield select(getResourceManagerState(resourceType))

  if (resourceManager && resources.length) {
    const { offset = 0, limit } = resourceManager

    yield put(
      changeManager({
        resourceName: resourceType,
        state: {
          offset: 0,
          limit: offset + limit
        }
      })
    )
    yield readResourcesSaga(readResources(resourceType))
    yield put(
      changeManager({
        resourceName: resourceType,
        state: {
          offset,
          limit
        }
      })
    )
  }
}

export default function* watcher() {
  yield all([
    takeEvery(TYPES.INIT_MANAGER_STATE, initResourceManagerStateSaga),
    takeEvery(TYPES.READ_RESOURCES, readResourcesSaga),
    takeEvery(TYPES.READ_MORE_RESOURCES, readMoreResourcesSaga),
    takeEvery(TYPES.CHANGE_SORTING, changeSortingSaga),
    takeEvery(TYPES.CHANGE_FILTER, changeFilteringSaga),
    takeEvery(TYPES.CHANGE_SEARCH, changeSearchingSaga),
    takeEvery(RESOURCES_TYPES.DELETE_RESOURCES_SUCCEEDED, deletingResourceSaga)
  ])
}

import { takeEvery, put, select, all, call } from 'redux-saga/effects'
import { error } from 'react-notification-system-redux'

import fetchAPI from 'lib/services/fetchAPI'
import APIAction from 'lib/utils/APIAction'
import getResourceActions from 'lib/utils/getResourceActions'
import { tokenSelector } from 'lib/modules/user/selectors'
import { GetMessageReceivers } from './actions'
import { ENDPOINT } from 'constants/api'
import TYPES from './actionTypes'

function* getMessageReceivers(action: GetMessageReceivers) {
  const { resourceType, requestKey } = action.payload
  const token = yield select(tokenSelector)
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'READ',
    resourceType,
    requestKey
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        token,
        endpoint: ENDPOINT.MESSAGES,
        path: '/receivers'
      })
    },
    *success(data) {
      yield put(onSuccessAction(data))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось получить данные.'
        })
      )
    }
  })
}

export function* watcher() {
  yield all([takeEvery(TYPES.GET_MESSAGE_RECEIVERS, getMessageReceivers)])
}

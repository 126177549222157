import { Props } from 'components/FormFields/TextInput'

const styles = (theme: App.Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: ({ useTopLabel }) => {
      if (useTopLabel) return 'flex-start'

      return 'center'
    },
    flexDirection: ({ useTopLabel }) => {
      if (useTopLabel) return 'column'

      return 'initial'
    },
    fontSize: '16px',

    '& .Select-control': {
      backgroundColor: 'white',
      borderRadius: '8px',
      borderColor: ({ meta: { error, touched } }: Props) =>
        error && touched ? theme.colors.red : theme.colors.input.border,
      height: 'fit-content'
    }
  },
  label: {
    position: ({ useTopLabel }) => {
      if (useTopLabel) return 'relative'

      return 'absolute'
    },
    zIndex: 1,
    pointerEvents: 'none',
    userSelect: 'none',
    display: 'inline-block',
    color: theme.colors.grey,
    fontSize: '16px',
    lineHeight: 1,
    fontWeight: 'normal',
    marginBottom: ({ useTopLabel }) => {
      if (useTopLabel) return '8px'

      return ''
    },
    left: theme.common.input.paddingLeft,
    textTransform: 'initial',
    letterSpacing: 0.5,
    cursor: 'text',
    transform: 'translateY(-10px) scale(0.7)',
    transformOrigin: '0 0',
    transition: 'transform 0.15s ease-out'
  },
  error: {
    display: 'inline-block',
    padding: '0.325em 1.25em',
    color: theme.colors.red,
    fontSize: '0.75rem'
  }
})

export default styles

import { Action, ActionCreator } from 'redux'

import { RoleType } from 'constants/roles'
import types from './types'

export interface AuthRequest extends Action<types.AUTH_REQUEST> {
  meta: {
    redirect_to?: string | void
  }
}
interface AuthSuccess extends Action<types.AUTH_SUCCESS> {
  payload: {
    token: string
  }
}
interface AuthFailure extends Action<types.AUTH_FAILURE> {
  error: boolean
}

export interface AuthRefreshRequest extends Action<types.AUTH_REFRESH_REQUEST> {}

interface AuthRefreshSuccess extends Action<types.AUTH_REFRESH_SUCCESS> {
  payload: {
    token: string
  }
}
interface AuthRefreshFailure extends Action<types.AUTH_REFRESH_FAILURE> {
  error: boolean
}

interface AuthResetRequest extends Action<types.AUTH_RESET_REQUEST> {}
interface AuthReset extends Action<types.AUTH_RESET> {}
interface AuthSetToken extends Action<types.AUTH_SET_TOKEN> {
  payload: {
    token: string
  }
}

export interface ReadUserInfo extends Action<types.GET_USER_INFO> {
  payload: {
    auth_token: string
  }
}
export interface ReadUserInfoRequest extends Action<types.GET_USER_INFO_REQUEST> {
  payload: {}
}
interface ReadUserInfoRequestSuccess extends Action<types.GET_USER_INFO_SUCCESS> {
  payload: {
    id: number
    email: string
    message_name: string
    user_type: RoleType
    acl_mask: number
    acl_mask_expanded: { [key: string]: boolean }
    auto_return: Data.User['auto_return']
    layers_ids: string[]
  }
}

interface ReadUserInfoRequestFailure extends Action<types.GET_USER_INFO_FAILURE> {
  payload: {
    error: string
  }
}

export interface UpdateProfileInfo extends Action<types.UPDATE_PROFILE_INFO> {}
export interface UpdateProfileInfoRequest extends Action<types.UPDATE_PROFILE_INFO_REQUEST> {}
export interface UpdateProfileInfoSuccess extends Action<types.UPDATE_PROFILE_INFO_SUCCESS> {}
export interface UpdateProfileInfoFailure extends Action<types.UPDATE_PROFILE_INFO_FAILURE> {}

export type ActionTypes =
  | AuthRequest
  | AuthFailure
  | AuthSuccess
  | AuthReset
  | AuthSetToken
  | AuthRefreshRequest
  | AuthRefreshSuccess
  | AuthRefreshFailure
  | ReadUserInfo
  | ReadUserInfoRequest
  | ReadUserInfoRequestSuccess
  | ReadUserInfoRequestFailure
  | UpdateProfileInfo
  | UpdateProfileInfoRequest
  | UpdateProfileInfoSuccess
  | UpdateProfileInfoFailure

export const authRequest: ActionCreator<AuthRequest> = redirect_to => ({
  type: types.AUTH_REQUEST,
  meta: {
    redirect_to
  }
})
export const authSuccess: ActionCreator<AuthSuccess> = ({ token }: { token: string }) => ({
  type: types.AUTH_SUCCESS,
  payload: {
    token
  }
})
export const authFailure: ActionCreator<AuthFailure> = () => ({
  type: types.AUTH_FAILURE,
  error: true
})

export const authRefreshRequest: ActionCreator<AuthRefreshRequest> = () => ({
  type: types.AUTH_REFRESH_REQUEST
})

export const authRefreshSuccess: ActionCreator<AuthRefreshSuccess> = token => ({
  type: types.AUTH_REFRESH_SUCCESS,
  payload: {
    token
  }
})
export const authRefreshFailure: ActionCreator<AuthRefreshFailure> = () => ({
  type: types.AUTH_REFRESH_FAILURE,
  error: true
})

export const authResetRequest: ActionCreator<AuthResetRequest> = () => ({
  type: types.AUTH_RESET_REQUEST
})

export const authReset: ActionCreator<AuthReset> = () => ({
  type: types.AUTH_RESET
})

export const authSetToken: ActionCreator<AuthSetToken> = token => ({
  type: types.AUTH_SET_TOKEN,
  payload: {
    token
  }
})

export const readUserInfo: ActionCreator<ReadUserInfo> = auth_token => ({
  type: types.GET_USER_INFO,
  payload: {
    auth_token
  }
})

export const readUserInfoRequest: ActionCreator<ReadUserInfoRequest> = () => ({
  type: types.GET_USER_INFO_REQUEST,
  payload: {}
})

export const readUserInfoRequestSuccess: ActionCreator<ReadUserInfoRequestSuccess> = data => ({
  type: types.GET_USER_INFO_SUCCESS,
  payload: data
})

export const readUserInfoRequestFailure: ActionCreator<ReadUserInfoRequestFailure> = error => ({
  type: types.GET_USER_INFO_FAILURE,
  payload: {
    error
  }
})

export const updateProfileInfo: () => UpdateProfileInfo = () => ({
  type: types.UPDATE_PROFILE_INFO
})
export const updateProfileInfoRequest: () => UpdateProfileInfoRequest = () => ({
  type: types.UPDATE_PROFILE_INFO_REQUEST
})

export const updateProfileInfoSuccess: () => UpdateProfileInfoSuccess = () => ({
  type: types.UPDATE_PROFILE_INFO_SUCCESS
})

export const updateProfileInfoFailure: () => UpdateProfileInfoFailure = () => ({
  type: types.UPDATE_PROFILE_INFO_FAILURE
})

import React, { PureComponent, MouseEventHandler } from 'react'
import { Link } from 'react-router-dom'

import { connect, MapDispatchToProps } from 'react-redux'
import { compose } from 'recompose'
import injectStyles, { JSSProps } from 'react-jss'
import {Divider} from '@mui/material'

import { setSystem } from 'src/lib/modules/infoSystems/actions'
import { authResetRequest } from 'lib/modules/user/actions'
import Coat from 'assets/images/coat.png'
import styles from './styles'
import withRouter, { WithRouterProps } from '../WithRouter'
import Button from '../Button'

interface OuterProps {}
interface StateProps {}
interface DispatchProps {
  authResetRequest: () => void
  setSystem: (system: Data.InfoSystem) => void
}

export interface Props
  extends StateProps,
    DispatchProps,
    OuterProps,
    WithRouterProps,
    JSSProps<typeof styles> {}

interface State {}

class SideMenuContainer extends PureComponent<Props, State> {
  onLogout: MouseEventHandler<HTMLAnchorElement> = event => {
    event.preventDefault()
    event.stopPropagation()

    this.props.authResetRequest()
  }

  render() {
    const {
      props: { classes, setSystem }
    } = this

    return (
      <div style={{ ...styles }} className={classes.menuContainer}>
        {/* <div className={classes.menuBody}> */}
        <Link to="/desktop" className={classes.desktopLink} onClick={() => setSystem({})}>
          <img className={classes.avatar} src={Coat} alt="Герб Ленинградской области" />
          <Divider classes={{ root: classes.divider }} />
          <Button classes={{ label: classes.menuButtonLabel }} onClick={() => setSystem({})}>
            Рабочий стол
          </Button>
        </Link>
        {/* </div> */}
        <div className={classes.menuFooter}>
          <Button
            classes={{ label: classes.exit }}
            variant="text"
            onClick={this.onLogout}
          >
            Выход
          </Button>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, Props> = dispatch => ({
  authResetRequest: () => dispatch(authResetRequest()),
  setSystem: (system: Data.InfoSystem) => dispatch(setSystem(system))
})

export default compose<Props, OuterProps>(
  connect(
    null,
    mapDispatchToProps
  ),
  withRouter,
  injectStyles<OuterProps>(styles)
)(SideMenuContainer)

import React, { Component, PureComponent } from 'react'

import { connect, MapStateToProps } from 'react-redux'
import { compose } from 'recompose'

interface OuterProps {
  roles?: {
    user_type: App.State['user']['user_type']
    Component: React.ComponentType<any> | null
  }[]
  DefaultComponent: React.ComponentType<any>
  passThroughProps?: any
}
interface StateProps {
  user_type: App.State['user']['user_type'] | undefined
}
interface DispatchProps {}
export interface Props extends StateProps, DispatchProps, OuterProps {}

interface State {}

class RoleContentHOC extends PureComponent<Props, State> {
  render() {
    const {
      props: { DefaultComponent, passThroughProps = {} }
    } = this

    return <DefaultComponent {...passThroughProps} />
  }
}

const mapStateToProps: MapStateToProps<StateProps, Props, App.State> = state => ({
  user_type: state.user.user_type
})

export default compose<Props, OuterProps>(
  
  connect(mapStateToProps)
)(RoleContentHOC)

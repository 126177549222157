import { takeEvery, call, put, select, all } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { error } from 'react-notification-system-redux'
import {
  startSubmit,
  setSubmitSucceeded,
  setSubmitFailed,
  stopSubmit,
  getFormValues
} from 'redux-form'

import fetchAPI from 'lib/services/fetchAPI'
import APIAction from 'lib/utils/APIAction'
import getResourceActions from 'lib/utils/getResourceActions'
import { tokenSelector } from 'lib/modules/user/selectors'
import {
  ReadCatalogItems,
  CreateCatalogItem,
  UpdateCatalogItem,
  DeleteCatalogItem
} from './actions'
import { METHOD, ENDPOINT } from 'constants/api'
import { CATALOG_ITEMS_FORM } from 'constants/forms'
import TYPES from './actionTypes'
import Data from '../../../../types/data'
import { getTabState } from 'lib/modules/resourceManager/selectors'
import { CATALOG_ITEMS } from 'constants/resources'
import { readCatalogItems as readCatalogItemsAction } from 'lib/modules/catalogItems/actions'

function* readCatalogItems(action: ReadCatalogItems) {
  const { resourceType, requestKey, options } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'READ',
    resourceType,
    requestKey
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      const endpoint = window.location.search.includes(ENDPOINT.HIERARCHICAL_INDICATORS.slice(1))
        ? ENDPOINT.HIERARCHICAL_INDICATORS
        : ENDPOINT.CATALOG_ITEMS

      return yield call(fetchAPI, {
        endpoint,
        useToken: true,
        params: options
      })
    },
    *success(data) {
      yield put(onSuccessAction(data))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось получить данные.'
        })
      )
    }
  })
}

function* createCatalogItem(action: CreateCatalogItem) {
  const {
    requestKey,
    resourceType,
    options: { redirect, item_type, optionForm }
  } = action.payload
  const formValues = yield select(getFormValues(CATALOG_ITEMS_FORM.form))

  const { description, inn, name, reader_ids, responsable_ids } = formValues || {}

  const isHierarchy = window.location.search.includes(ENDPOINT.HIERARCHICAL_INDICATORS.slice(1))

  const endpoint = isHierarchy ? ENDPOINT.HIERARCHICAL_INDICATORS : ENDPOINT.CATALOG_ITEMS
  
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'CREATE',
    resourceType,
    requestKey
  })
  
  yield APIAction({
    *request() {
      yield put(startSubmit(CATALOG_ITEMS_FORM.form))

      yield put(onRequestAction())

      let bodyData = { description, name, reader_ids, responsable_ids }

      if (inn) bodyData.inn = inn
      if (!isHierarchy) bodyData.item_type = item_type
      else {
        if (reader_ids && reader_ids.length) bodyData.reader_ids = reader_ids.map(item => item.id)
        if (responsable_ids && responsable_ids.length) bodyData.responsable_ids = responsable_ids.map(item => item.id)
      }

      if (optionForm) {
        bodyData = {
          name: optionForm.name,
        }

        if (optionForm.parent && optionForm.parent.id) {
          bodyData.parent_id = optionForm.parent.id
        }
      }

      return yield call(fetchAPI, {
        useToken: true,
        method: METHOD.POST,
        endpoint,
        body: bodyData,
      })
    },
    *success(data: Data.CatalogItem) {
      yield put(onSuccessAction([data]))

      yield put(stopSubmit(CATALOG_ITEMS_FORM.form))
      yield put(setSubmitSucceeded(CATALOG_ITEMS_FORM.form))

      if (optionForm) {
        yield put(readCatalogItemsAction({ parent_id: 'null' }))
      }

      if (redirect) yield put(push(redirect))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(stopSubmit(CATALOG_ITEMS_FORM.form, errors))
      yield put(setSubmitFailed(CATALOG_ITEMS_FORM.form))

      yield put(
        error({
          title: 'Не удалось создать новость.'
        })
      )

      if (redirect) yield put(push(redirect))
    }
  })
}

function* updateCatalogItem(action: UpdateCatalogItem) {
  const token = yield select(tokenSelector)
  const item_type = yield select(getTabState(CATALOG_ITEMS.NAME))

  const {
    resourceType,
    requestKey,
    options: { ID, redirect, optionForm }
  } = action.payload

  const mainId = ID || optionForm.id  

  const formData = yield select(getFormValues(CATALOG_ITEMS_FORM.form))  

  const { description, name, inn, reader_ids, responsable_ids  } = formData || {}

  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'UPDATE',
    resourceType,
    requestKey,
    ids: [mainId]
  })

  const isHierarchy = window.location.search.includes(ENDPOINT.HIERARCHICAL_INDICATORS.slice(1))

  const endpoint = isHierarchy ? ENDPOINT.HIERARCHICAL_INDICATORS : ENDPOINT.CATALOG_ITEMS

  yield APIAction({
    *request() {
      yield put(startSubmit(CATALOG_ITEMS_FORM.form))

      yield put(onRequestAction())

      let bodyData = { description, name }

      if (!isHierarchy) bodyData.item_type = item_type
      else {
        if (reader_ids && reader_ids.length) bodyData.reader_ids = reader_ids.map(item => item.id)
        if (responsable_ids && responsable_ids.length) bodyData.responsable_ids = responsable_ids.map(item => item.id)
      }

      if (inn) bodyData.inn = inn

      if (optionForm) {
        bodyData = {
          name: optionForm.name,
        }

        const { reader_ids, responsable_ids } = optionForm

        if (optionForm.parent && optionForm.parent.id) {
          bodyData.parent_id = optionForm.parent.id
        }

        if (reader_ids && reader_ids.length) bodyData.reader_ids = reader_ids.map(item => item.id)
        if (responsable_ids && responsable_ids.length) bodyData.responsable_ids = responsable_ids.map(item => item.id)
      }

      return yield call(fetchAPI, {
        method: METHOD.PUT,
        endpoint,
        path: mainId.toString(),
        body: bodyData,
        token
      })
    },
    *success(data) {
      yield put(onSuccessAction([data]))
      yield put(stopSubmit(CATALOG_ITEMS_FORM.form))
      yield put(setSubmitSucceeded(CATALOG_ITEMS_FORM.form))

      if (optionForm) {
        yield put(readCatalogItemsAction({ parent_id: 'null' }))
      }

      if (redirect) yield put(push(redirect))
    },
    *fail(errors) {
      yield put(onFailAction(errors))
      yield put(stopSubmit(CATALOG_ITEMS_FORM.form, errors))
      yield put(setSubmitFailed(CATALOG_ITEMS_FORM.form))

      yield put(
        error({
          title: 'Не удалось обновить справочник.'
        })
      )
    }
  })
}

function* deleteCatalogItem(action: DeleteCatalogItem) {
  const { resourceType, requestKey, id } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'DELETE',
    resourceType,
    requestKey,
    ids: [id]
  })

  const isHierarchy = window.location.search.includes(ENDPOINT.HIERARCHICAL_INDICATORS.slice(1))

  const endpoint = isHierarchy ? ENDPOINT.HIERARCHICAL_INDICATORS : ENDPOINT.CATALOG_ITEMS

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        method: METHOD.DELETE,
        endpoint,
        path: id.toString(),
        useToken: true,
      })
    },
    *success(data) {
      yield put(onSuccessAction([id]))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось удалить справочник.'
        })
      )
    }
  })
}

export function* watcher() {
  yield all([
    takeEvery(TYPES.READ_CATALOG_ITEMS, readCatalogItems),
    takeEvery(TYPES.CREATE_CATALOG_ITEM, createCatalogItem),
    takeEvery(TYPES.UPDATE_CATALOG_ITEM, updateCatalogItem),
    takeEvery(TYPES.DELETE_CATALOG_ITEM, deleteCatalogItem)
  ])
}

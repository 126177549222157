export const LOADING = 'LOADING'
export const SUCCEEDED = 'SUCCEEDED'
export const FAILED = 'FAILED'
export const MISSING = 'MISSING'
export const IDLE = 'IDLE'

export const STATES = {
  LOADING,
  SUCCEEDED,
  FAILED,
  MISSING,
  IDLE
}


export type STATES_TYPES = keyof typeof STATES
import React, { PureComponent, Fragment, InputHTMLAttributes, ChangeEventHandler } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import cn from 'classnames'
import { WrappedFieldArrayProps } from 'redux-form'

import ButtonRounded from 'components/ButtonRounded'
import { Cancel } from 'components/Icons'
import { DOMAIN } from 'constants/api'
import styles from './styles'

interface OuterProps extends InputHTMLAttributes<HTMLInputElement> {
  containerClassName?: string
  imgClassName?: string
  inputRef?: (ref: HTMLInputElement) => void
  // Fix strange ts error
  component?: any
}
export interface Props
  extends OuterProps,
    WrappedFieldArrayProps<Data.ImageBlock>,
    JSSProps<typeof styles> {}

class ImageBlocks extends PureComponent<Props> {
  onFileChange: ChangeEventHandler<HTMLInputElement> = event => {
    const { fields } = this.props
    const { files } = event.target

    for (let i = 0; i < files.length; i++)
      fields.push({ block_type: 'image', image: files.item(i) })
  }

  render() {
    const { fields, classes, containerClassName, imgClassName, inputRef } = this.props

    return (
      <Fragment>
        <input
          ref={inputRef}
          type="file"
          multiple={true}
          onChange={this.onFileChange}
          accept="image/*"
          style={{ display: 'none' }}
        />
        <div className={cn(classes.imagesRow, containerClassName)}>
          {fields.map((member, i, fields) => {
            const block = fields.get(i)
            return (
              <div
                key={`${block.image instanceof File ? (block.image as File).name : block.id}_${i}`}
                className={cn(classes.imgContainer, imgClassName)}
              >
                <ButtonRounded
                  filled
                  type="danger"
                  icon={Cancel}
                  className={classes.imgRemoveIcon}
                  onClick={() => fields.remove(i)}
                  title="Удалить"
                />
                <img
                  src={
                    block.image instanceof File
                      ? URL.createObjectURL(block.image)
                      : `${DOMAIN}${block.image.medium}`
                  }
                />
              </div>
            )
          })}
        </div>
      </Fragment>
    )
  }
}

export default injectStyles<OuterProps>(styles)(ImageBlocks)

import { Reducer } from 'redux'

import { PreviewSource } from 'constants/dataImport'
import { Actions } from './actions'
import { Types } from './types'

export interface State {
  selected_source_id?: number
  selected_table?: string
  preview_source_type?: PreviewSource

  preview: {
    id?: number
    meta: { loading: boolean; loaded: boolean; error?: any }
    source_id?: number
    source_type?: PreviewSource
    table?: string
    headers?: Data.ColumnParams[]
    rows?: string[][]
  }
}

const initialState: State = {
  preview: {
    meta: { loaded: true, loading: false },
    headers: [],
    rows: []
  }
}

export const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case Types.SELECT_DATA_SOURCE_TABLE:
      return {
        ...state,
        selected_table: action.payload.table,
        selected_source_id: action.payload.source_id
      }
    case Types.SELECT_PREVIEW_SOURCE_TYPE:
      return { ...state, preview_source_type: action.payload }
    case Types.TOGGLE_PREVIEW_COLUMN_INCLUDE:
      return {
        ...state,
        preview: {
          ...state.preview,
          headers: [
            ...state.preview.headers.map(item => ({
              ...item,
              include: item.name === action.payload ? !item.include : item.include
            }))
          ]
        }
      }
    case Types.CHANGE_COLUMN_PARAMS: {
      const headers = state.preview.headers
      let rows = [...state.preview.rows]
      headers[action.payload.index] = { ...headers[action.payload.index], ...action.payload.params }

      if (!state.preview.headers[action.payload.index].formula && action.payload.params.formula)
        rows = [
          ...state.preview.rows.map(row => {
            const tmp = [...row]
            tmp[action.payload.index] = ''
            return tmp
          })
        ]

      return {
        ...state,
        preview: {
          ...state.preview,
          headers,
          rows
        }
      }
    }
    case Types.CHANGE_DATA_CELL: {
      const nextRows = [...state.preview.rows]
      nextRows[action.payload.rowIndex][action.payload.columnIndex] = action.payload.value

      return { ...state, preview: { ...state.preview, rows: nextRows } }
    }
    case Types.ADD_COLUMN:
      return {
        ...state,
        preview: {
          ...state.preview,
          headers: [...state.preview.headers, action.payload as Data.ColumnParams],
          rows: [...state.preview.rows.map(row => [...row, ''])]
        }
      }
    case Types.CLEAR_COLUMN: {
      return {
        ...state,
        preview: {
          ...state.preview,
          rows: state.preview.rows.map(row => {
            row[action.payload] = ''
            return row
          })
        }
      }
    }
    case Types.REMOVE_COLUMN: {
      return {
        ...state,
        preview: {
          ...state.preview,
          headers: state.preview.headers.filter((head, idx) => idx !== action.payload),
          rows:
            state.preview.headers.length > 1
              ? state.preview.rows.map(row => {
                  return row.filter((el, idx) => idx !== action.payload)
                })
              : []
        }
      }
    }
    case Types.ADD_CELL:
      return {
        ...state,
        preview: {
          ...state.preview,
          rows: [...state.preview.rows, new Array(state.preview.headers.length).fill('')]
        }
      }
    case Types.ADD_DATA_SUCCESS:
      return {
        ...state,
        preview: {
          ...state.preview,
          rows: [...state.preview.rows, action.payload.data]
        }
      }
    case Types.READ_PREVIEW:
      return {
        ...state,
        file_preview: null,
        preview: {
          ...state.preview,
          meta: { loading: true, loaded: false },
          source_id: action.payload.source_id,
          table: action.payload.table,
          source_type: 'db',
          headers: null,
          rows: null
        }
      }
    case Types.READ_PREVIEW_FILE:
      return {
        ...state,
        preview: {
          ...state.preview,
          meta: { loading: true, loaded: false },
          source_id: null,
          table: null,
          source_type: 'file',
          headers: null,
          rows: null
        }
      }
    case Types.READ_PREVIEW_SUCCESS:
      return {
        ...state,
        preview: {
          ...state.preview,
          ...action.payload,
          headers: action.payload.headers.map(item => ({ ...item }))
        }
      }
    case Types.READ_PREVIEW_FAILURE:
      return {
        ...state,
        preview: {
          ...state.preview,
          meta: { loading: false, loaded: false, error: action.payload.error },
          headers: null,
          rows: null
        }
      }
    case Types.READ_PREVIEW_CUBE: {
      return {
        ...state,
        preview: {
          ...state.preview,
          ...action.payload,
          meta: { loading: false, loaded: true, error: '' }
        }
      }
    }
    case Types.RESET_PREVIEW:
      return { ...initialState }

    case Types.START_LOADING: {
      return {
        ...state,
        preview: {
          meta: { loading: true, loaded: false, error: '' }
        }
      }
    }

    case Types.STOP_LOADING: {
      return {
        ...state,
        preview: {
          meta: { loading: false, loaded: false, error: action.payload }
        }
      }
    }

    default:
      return state
  }
}

import { Action } from 'redux'

import { Types } from './types'
import { ColumnParamsType, PreviewSource } from 'constants/dataImport'

export interface SelectPreviewSourceType extends Action<Types.SELECT_PREVIEW_SOURCE_TYPE> {
  payload: PreviewSource
}
//
export interface SelectDataSourceTableOptions {
  source_id: number
  table: string
}
export interface SelectDataSourceTable extends Action<Types.SELECT_DATA_SOURCE_TABLE> {
  payload: SelectDataSourceTableOptions
}
//
export interface ChangeColumnParams extends Action<Types.CHANGE_COLUMN_PARAMS> {
  payload: {
    index: number
    params: Partial<Data.ColumnParams>
  }
}
export interface TogglePreviewColumnInclude extends Action<Types.TOGGLE_PREVIEW_COLUMN_INCLUDE> {
  payload: string
}

export type AddColumnPayload = Partial<Omit<Data.ColumnParams, 'name'>> & { name: string }

export interface AddColumn extends Action<Types.ADD_COLUMN> {
  payload: AddColumnPayload
}

export interface ClearColumn extends Action<Types.CLEAR_COLUMN> {
  payload: number
}

export interface RemoveColumn extends Action<Types.REMOVE_COLUMN> {
  payload: number
}

// export type AddCellPayload = Array<string | number>

export interface AddCell extends Action<Types.ADD_CELL> {}

export interface ChangeDataCellPayload {
  columnIndex: number
  rowIndex: number
  value: string
}
export interface ChangeDataCell extends Action<Types.CHANGE_DATA_CELL> {
  payload: ChangeDataCellPayload
}

export interface AddDataPayload {
  id: Data.CubeParams['id']
  data: Array<string | number>
}
export interface AddData extends Action<Types.ADD_DATA> {
  payload: AddDataPayload
}

export interface AddDataSuccess extends Action<Types.ADD_DATA_SUCCESS> {
  payload: { data: string[] }
}

export interface ReadPreview extends Action<Types.READ_PREVIEW> {
  payload: {
    source_id: number
    table: string
  }
}
export interface ReadPreviewCube extends Action<Types.READ_PREVIEW_CUBE> {
  payload: {
    id: number
  }
}
export interface ReadPreviewFile extends Action<Types.READ_PREVIEW_FILE> {
  payload: {
    file: File
  }
}
export interface ReadPreviewSuccess extends Action<Types.READ_PREVIEW_SUCCESS> {
  payload: {
    headers: Data.ColumnParams[]
    rows: string[][]
    source_id?: number
  }
}
export interface ReadPreviewFailure extends Action<Types.READ_PREVIEW_FAILURE> {
  payload: {
    error?: any
  }
}
export interface ResetPreview extends Action<Types.RESET_PREVIEW> {}

export interface StartLoading extends Action<Types.START_LOADING> {}

export interface StopLoading extends Action<Types.STOP_LOADING> {
  payload: string
}

export type Actions =
  | SelectPreviewSourceType
  | SelectDataSourceTable
  | TogglePreviewColumnInclude
  | ChangeColumnParams
  | AddColumn
  | ClearColumn
  | RemoveColumn
  | AddCell
  | AddData
  | AddDataSuccess
  | ChangeDataCell
  | ReadPreview
  | ReadPreviewFile
  | ReadPreviewCube
  | ReadPreviewSuccess
  | ReadPreviewFailure
  | ResetPreview
  | StartLoading
  | StopLoading

export { Actions as PreviewActions }

export const selectPreviewSourceType: (
  source: PreviewSource
) => SelectPreviewSourceType = source => ({
  type: Types.SELECT_PREVIEW_SOURCE_TYPE,
  payload: source
})
export const selectDataSourceTable: (
  options: SelectDataSourceTableOptions
) => SelectDataSourceTable = options => ({
  type: Types.SELECT_DATA_SOURCE_TABLE,
  payload: options
})
export const changeColumnParams: (
  index: number,
  params: Partial<Data.ColumnParams>
) => ChangeColumnParams = (index, params) => ({
  type: Types.CHANGE_COLUMN_PARAMS,
  payload: { index, params }
})
export const changeDataCell: (opts: ChangeDataCellPayload) => ChangeDataCell = opts => ({
  type: Types.CHANGE_DATA_CELL,
  payload: opts
})
export const togglePreviewColumnInclude: (name: string) => TogglePreviewColumnInclude = name => ({
  type: Types.TOGGLE_PREVIEW_COLUMN_INCLUDE,
  payload: name
})
export const addColumn: (col: AddColumnPayload) => AddColumn = col => ({
  type: Types.ADD_COLUMN,
  payload: {
    pr_key: false,
    is_new: true,
    type: ColumnParamsType.String,
    include: true,
    nsi: false,
    ...col
  }
})

export const clearColumn: (index: number) => ClearColumn = index => ({
  type: Types.CLEAR_COLUMN,
  payload: index
})

export const removeColumn: (index: number) => RemoveColumn = index => ({
  type: Types.REMOVE_COLUMN,
  payload: index
})

export const addCell: () => AddCell = () => ({ type: Types.ADD_CELL })

export const addData: (id: Data.CubeParams['id'], data: Array<string | number>) => AddData = (
  id,
  data
) => ({
  type: Types.ADD_DATA,
  payload: { id, data }
})

export const addDataSuccess: (data: string[]) => AddDataSuccess = data => ({
  type: Types.ADD_DATA_SUCCESS,
  payload: { data }
})

export const readPreview: (options: {
  source_id: number
  table: string
}) => ReadPreview = options => ({
  type: Types.READ_PREVIEW,
  payload: options
})
export const readPreviewCube: (id: number) => ReadPreviewCube = id => ({
  type: Types.READ_PREVIEW_CUBE,
  payload: { id }
})
export const readPreviewFile: (options: { file: File }) => ReadPreviewFile = ({ file }) => ({
  type: Types.READ_PREVIEW_FILE,
  payload: { file }
})
export const readPreviewSuccess: (data: {
  headers: Data.ColumnParams[]
  rows: string[][]
  source_id?: number
}) => ReadPreviewSuccess = data => ({
  type: Types.READ_PREVIEW_SUCCESS,
  payload: data
})
export const readPreviewFailure: (data: { error?: any }) => ReadPreviewFailure = data => ({
  type: Types.READ_PREVIEW_FAILURE,
  payload: data
})
export const resetPreview: () => ResetPreview = () => ({
  type: Types.RESET_PREVIEW
})

export const startLoading: () => StartLoading = () => ({
  type: Types.START_LOADING
})

export const stopLoading: (error: string) => StopLoading = error => ({
  type: Types.STOP_LOADING,
  payload: error
})

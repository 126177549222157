import React from 'react'
import { put, select, call, all, takeEvery } from 'redux-saga/effects'
import { error, success } from 'react-notification-system-redux'

import fetchAPI from 'lib/services/fetchAPI'
import { tokenSelector } from 'lib/modules/user/selectors'
import {
  getNSISuccess,
  getNSIFail,
  createCubeSuccess,
  createCubeFail,
  sendFileSuccess,
  sendFileFail,
  getCube as actionGetCube,
  getCubeSuccess,
  getCubeFail,
  updateCubeSuccess,
  updateCubeFail,
  deleteCubeSuccess,
  deleteCubeFail
} from './actions'
import { METHOD, ENDPOINT } from 'constants/api'
import { CreateCube, DeleteCube, GetCube, GetNSI, SendFile, Types, UpdateCube } from './types'
import getResourceActions from 'lib/utils/getResourceActions'
import APIAction from 'lib/utils/APIAction'

function* getNSI(action: GetNSI) {
  const token = yield select(tokenSelector)
  const {
    payload: { id, key }
  } = action

  try {
    const {
      data: { data, params }
    }: { data: { data: string[][]; params: Data.ColumnParams[] } } = yield call(fetchAPI, {
      method: METHOD.GET,
      endpoint: ENDPOINT.CUBES,
      path: `${id}`,
      token
    })

    yield put(getNSISuccess({ [key]: { headers: params, rows: data } }))
    yield put(success({ title: 'Справочник успешно загружен' }))
  } catch (err) {
    yield put(getNSIFail())
    yield put(error({ title: 'Не удалось получить справочник' }))
  }
}

function* sendFile(action: SendFile) {
  const { requestKey, resourceType, requestProperties } = action.payload
  const { file, id, method } = requestProperties

  const formData = new FormData()
  formData.append('file', file)

  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'UPDATE',
    resourceType,
    requestKey
  })

  const token = yield select(tokenSelector)

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        method: METHOD.POST,
        endpoint: ENDPOINT.CUBES,
        path: `${id}/add_data_from_file/${method}`,
        body: formData,
        token
      })
    },
    *success(data) {
      yield put(onSuccessAction(data))
      yield put(sendFileSuccess())
      yield put(actionGetCube(id))
    },
    *fail(errors) {
      yield put(onFailAction(errors))
      yield put(sendFileFail())
      yield put(error({ title: 'Не удалось загрузить данные' }))
    }
  })
}

function* getCube(action: GetCube) {
  const { requestKey, resourceType, id: cubeId } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'READ',
    resourceType,
    requestKey
  })
  const token = yield select(tokenSelector)

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      const { data } = yield call(fetchAPI, {
        method: METHOD.GET,
        endpoint: ENDPOINT.CUBES,
        path: cubeId.toString(),
        token
      })

      const { name, nsi, description, params: headers } = data
      const rows = data.data[0][0] === null ? [] : data.data

      return {
        data: {
          id: cubeId,
          name,
          nsi,
          description,
          headers,
          rows
        }
      }
    },
    *success(data) {
      yield put(onSuccessAction(data))
      yield put(getCubeSuccess(data))
      yield put(success({ title: 'Куб успешно загружен' }))
    },
    *fail(errors) {
      yield put(onFailAction(errors))
      yield put(
        error({
          title: 'Не удалось загрузить куб.'
        })
      )
      yield put(getCubeFail())
    }
  })
}

function* createCube(action: CreateCube) {
  const { requestKey, resourceType, cube } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'CREATE',
    resourceType,
    requestKey
  })
  const token = yield select(tokenSelector)

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        method: METHOD.POST,
        endpoint: ENDPOINT.CUBES,
        body: cube,
        token
      })
    },
    *success(data) {
      yield put(onSuccessAction([data]))
      yield put(createCubeSuccess(data.id))
      yield put(success({ title: 'Куб успешно создан' }))
    },
    *fail(errors) {
      yield put(onFailAction(errors))
      yield put(createCubeFail())
      yield put(error({ title: 'Не удалось создать куб' }))
    }
  })
}

function* updateCube(action: UpdateCube) {
  const { requestKey, resourceType, requestProperties } = action.payload
  const { cube, id } = requestProperties
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'UPDATE',
    resourceType,
    requestKey,
    requestProperties
  })
  const token = yield select(tokenSelector)

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        method: METHOD.PUT,
        endpoint: ENDPOINT.CUBES,
        path: id.toString(),
        body: cube,
        token
      })
    },
    *success(data) {
      yield put(onSuccessAction(data))
      yield put(updateCubeSuccess())
      yield put(success({ title: 'Куб успешно обновлен' }))
    },
    *fail(errors) {
      yield put(onFailAction(errors))
      yield put(updateCubeFail())
      yield put(error({ title: 'Не удалось обновить куб' }))
    }
  })
}

function* deleteCube(action: DeleteCube) {
  const { requestKey, resourceType, id } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'DELETE',
    resourceType,
    requestKey
  })
  const token = yield select(tokenSelector)

  yield APIAction({
    *request() {
      yield put(onRequestAction())
      return yield call(fetchAPI, {
        method: METHOD.DELETE,
        endpoint: ENDPOINT.CUBES,
        path: id.toString(),
        token
      })
    },
    *success(data) {
      yield put(onSuccessAction([id]))
      yield put(deleteCubeSuccess())
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось удалить куб.'
        })
      )
      yield put(deleteCubeFail())
    }
  })
}

export function* watcher() {
  yield all([
    takeEvery(Types.CREATE_CUBE, createCube),
    takeEvery(Types.UPDATE_CUBE, updateCube),
    takeEvery(Types.DELETE_CUBE, deleteCube),
    takeEvery(Types.GET_CUBE, getCube),
    takeEvery(Types.GET_NSI, getNSI),
    takeEvery(Types.SEND_FILE, sendFile)
  ])
}

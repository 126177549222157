import { Reducer } from 'redux'

import types from './types'
import { ActionTypes } from './actions'
import { STATES } from 'constants/states'

export type CalendarViewType = 'personal' | 'work'

export interface State {
  infoStatus: STATES
  views: CalendarViewType[]
}

const initialState: State = {
  infoStatus: STATES.IDLE,
  views: ['personal']
}

export const reducer: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_SCHEDULE_REQUEST:
      return { ...state, infoStatus: STATES.LOADING }
    case types.UPDATE_SCHEDULE_SUCCESS:
      return { ...state, infoStatus: STATES.SUCCEEDED }
    case types.UPDATE_SCHEDULE_FAILURE:
      return { ...state, infoStatus: STATES.FAILED }
    case types.TOGGLE_CALENDAR_VIEW:
      let newViews = [...state.views]
      if (newViews.includes(action.payload)) {
        newViews = newViews.filter(e => e !== action.payload)
      } else {
        newViews.push(action.payload)
      }
      return { ...state, views: newViews }
    default:
      return state
  }
}

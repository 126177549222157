import React, { PureComponent } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import { compose } from 'recompose'
import { Field, FieldArrayFieldsProps } from 'redux-form'

import BaseResult from '../BaseResult'
import DateResult from '../DateResult'
import DateRange from '../DateRange'
import CompareValue from '../Compare/components/Value'
import { FilterFieldItem } from 'components/Aggregation'
import { TYPE as SET_TYPE } from 'constants/set'
import { OPTION_TYPES, LOGIC_OPTIONS } from 'constants/filter'
import styles from './styles'

interface OuterProps {
  name: string
  index: number
  fields: FieldArrayFieldsProps<Filter.Expression>
  withConditionSeparator: boolean
  withDateRange: boolean
  withDateSelect: boolean
  stringAsMulti: boolean
  options: Filter.Option[]
  menuIsOpen: boolean
  menuIndex?: number
  removeRow: (index: number) => void
  openMenu: (anchorEl: HTMLDivElement, index: number) => void
}
interface Props extends JSSProps<typeof styles>, OuterProps {}

class FiltersRow extends PureComponent<Props> {
  get resultComponent() {
    const {
      fields,
      index,
      name,
      withDateRange,
      withDateSelect,
      stringAsMulti,
      options
    } = this.props
    const { field1 } = fields.get(index)

    if (field1) {
      switch (field1.filter_type) {
        case OPTION_TYPES.TIME:
          return withDateRange ? (
            <DateRange
              fields={fields}
              options={options}
              name={name}
              index={index}
              selectedOption={field1}
            />
          ) : (
            <DateResult
              fields={fields}
              name={name}
              index={index}
              options={options}
              selectedOption={field1}
              withDateSelect={withDateSelect}
            />
          )
        default:
          return (
            <BaseResult
              fields={fields}
              name={name}
              index={index}
              selectedOption={field1}
              stringAsMulti={stringAsMulti}
            />
          )
      }
    }

    return false
  }

  removeRow = () => {
    const { index, removeRow } = this.props

    removeRow(index)
  }

  openMenu = (anchorEl: HTMLDivElement, field: Filter.MainField) => {
    const { index, openMenu } = this.props

    openMenu(anchorEl, index)
  }

  render() {
    const {
      classes,
      name,
      fields,
      index,
      menuIndex,
      menuIsOpen,
      withConditionSeparator
    } = this.props

    return (
      <div className={classes.container}>
        <div className={classes.fields}>
          {!!index && withConditionSeparator && (
            <Field
              name={`${name}.condition_separator`}
              options={LOGIC_OPTIONS}
              id={`${index}-logic`}
              title="Логическое условие"
              component={CompareValue}
            />
          )}
          <FilterFieldItem
            field={fields.get(index).field1}
            type={
              fields.get(index).field1.entity_type === 'dimensions'
                ? SET_TYPE.DIMENSION
                : SET_TYPE.MEASURE
            }
            menuOpened={menuIsOpen}
            menuOpenedIndex={menuIndex}
            openMenu={this.openMenu}
            index={index}
            draggable={false}
          />
          {this.resultComponent}
        </div>
      </div>
    )
  }
}

export default compose<Props, OuterProps>(injectStyles(styles))(FiltersRow)

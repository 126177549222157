import { Reducer } from 'redux'

import types from './types'
import { ActionTypes } from './actions'
import { STATES } from 'constants/states'

export interface State {
  showActivityModal: boolean
}

const initialState: State = {
  showActivityModal: false
}

export const reducer: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_ACTIVITY_MODAL:
      return { ...state, showActivityModal: !state.showActivityModal }
    default:
      return state
  }
}

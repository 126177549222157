import { takeEvery, put, select, call, all } from 'redux-saga/effects'

import { error } from 'react-notification-system-redux'
import { AxiosError } from 'axios'

import fetchAPI from 'lib/services/fetchAPI'
import { tokenSelector } from 'lib/modules/user/selectors'
import { METHOD, ENDPOINT } from 'constants/api'
import {
  ReadInfoSystems,
  readInfoSystemsRequest,
  readInfoSystemsSuccess,
  readInfoSystemsFailure,
  UpdateInfoSystems,
  updateInfoSystemsRequest,
  updateInfoSystemsSuccess,
  updateInfoSystemsFailure
} from './actions'
import types from './types'
import objectToFormData from 'src/lib/utils/objectToFormData'

function* readInfoSystems(action: ReadInfoSystems) {
  const token = yield select(tokenSelector)

  try {
    yield put(readInfoSystemsRequest())
    const { data } = yield call(fetchAPI, {
      token,
      method: METHOD.GET,
      endpoint: ENDPOINT.INFO_SYSTEMS
    })

    const enchansedData = data.map(el => {
      const newEl = { ...el }
      el.iconPreview = null
      return newEl
    })

    yield put(readInfoSystemsSuccess(enchansedData))
  } catch (exception) {
    let errors = {
      _error: exception
    }

    if (exception.response) {
      errors = (exception as AxiosError).response.data.errors
    }

    yield put(readInfoSystemsFailure())
    yield put(
      error({
        title: 'Не удалось получить информационные системы'
      })
    )
  }
}

function* updateInfoSystems(action: UpdateInfoSystems) {
  const token = yield select(tokenSelector)

  try {
    yield put(updateInfoSystemsRequest())
    const list = action.payload.list.map((el, index) => {
      const newEl = { ...el }
      delete newEl.iconPreview
      newEl.position = index
      return newEl
    })
    const fd = objectToFormData(list, 'info_systems')
    const { data } = yield call(fetchAPI, {
      token,
      method: METHOD.PATCH,
      endpoint: ENDPOINT.INFO_SYSTEMS,
      body: fd
    })

    yield put(updateInfoSystemsSuccess(data))
  } catch (exception) {
    let errors = {
      _error: exception
    }

    if (exception.response) {
      errors = (exception as AxiosError).response.data.errors
    }

    yield put(updateInfoSystemsFailure())
    yield put(
      error({
        title: 'Не удалось обновить информационные системы'
      })
    )
  }
}

export function* watcher() {
  yield all([
    takeEvery(types.READ_INFO_SYSTEMS, readInfoSystems),
    takeEvery(types.UPDATE_INFO_SYSTEMS, updateInfoSystems)
  ])
}

import React, { PureComponent, HTMLProps, ChangeEventHandler } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import classNames from 'classnames'

import uniqueID from 'lib/utils/uniqueID'
import styles from './styles'

export interface ToggleProps {
  className?: string
  labelClassName?: string
  labelRight?: string
  labelLeft?: string
  disabled?: boolean
}

interface OuterProps
  extends ToggleProps,
    Omit<HTMLProps<HTMLInputElement>, 'value' | 'onChange' | 'type'> {
  value: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
}
export interface Props extends OuterProps, JSSProps<typeof styles> {}

class ToggleField extends PureComponent<Props> {
  id: string

  constructor(props: Props) {
    super(props)

    this.id = uniqueID()
  }

  render() {
    const {
      classes,
      className,
      labelClassName,
      labelRight,
      labelLeft,
      disabled,
      value,
      name,
      theme,
      onChange,
      ...props
    } = this.props

    return (
      <div className={classNames(classes.container, className)}>
        <input
          {...props}
          id={this.id}
          name={name}
          className={classes.input}
          style={{ display: 'none' }}
          type="checkbox"
          // @ts-ignore
          value={value}
          checked={value}
          onChange={e => {
            onChange({ ...e, target: { ...e.target, value: !value, name } })
          }}
          disabled={disabled}
        />
        <label htmlFor={this.id} className={classNames(classes.label, labelClassName)}>
          {labelLeft && <span className={classes.labelText}>{labelLeft}</span>}
          <span className={classes.checkbox}>
            <svg className={classes.checkboxIcon} viewBox="0 0 12 10">
              <polyline points="1.5 6 4.5 9 10.5 1" />
            </svg>
          </span>
          {labelRight && <span className={classes.labelText}>{labelRight}</span>}
        </label>
      </div>
    )
  }
}

export default injectStyles<OuterProps>(styles)(ToggleField)

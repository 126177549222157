import {
  CATALOG_ITEMS,
  CHARTS,
  CONSTRUCTION_OBJECTS,
  FORMS,
  INCIDENTS,
  INFO_REFERENCES,
  JOURNAL_LOGS,
  MESSAGES,
  PANELS,
  PUBLICATIONS,
  REPORTS,
  SCANNER_ENTITIES,
  WIDGETS
} from 'constants/resources'

export const DEV_DOMAIN = process.env.REACT_APP_BASE_URL

export const PROD_DOMAIN = window.location.origin

const isDev = process.env.NODE_ENV === 'development'

export const DOMAIN = isDev ? DEV_DOMAIN : PROD_DOMAIN

export const API_URL = `${DOMAIN}/api`
export const WS_API_URL = `${DOMAIN}/cable`

export enum METHOD {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete'
}

export const AUTH_AC_TYPES = {
  LOG_IN: 'LOG_IN',
  REFRESH_TOKEN: 'REFRESH_TOKEN'
}

export const ENDPOINT = {
  HIERARCHICAL_INDICATORS: '/hierarchical_indicators',
  CATALOG_ITEMS: '/catalog_items',
  APKBG: '/apkbg',
  ATTACHED_FILES: '/attached_files',
  SESSION: '/session',
  SESSION_REFRESH: '/session/refresh_token',
  ORGANISATIONS: '/organisations',
  ORDERS: '/orders',
  GRBS: '/grbs',
  USERS: '/users',
  MESSAGES: '/messages',
  MESSAGE_ATTACHEMENTS: '/message_attachments',
  ELEMENT_ATTACHEMENTS: '/element_attachments',
  PUBLICATIONS: '/publications',
  NOTIFICATIONS: '/notifications',
  PROFILE: '/profile',
  OLAP_SCHEMA: '/schemas',
  BRANCHES: '/branches',
  DIMENSIONS: '/dimensions',
  PANELS: '/panels',
  REPORTS: '/reports',
  FORMS: '/forms',
  CHARTS: '/elements',
  VEHICLES: '/vehicles',
  INFO_REFERENCES: '/references',
  INFO_SYSTEMS: '/info_systems',
  WIDGETS: '/widgets',
  INCIDENTS: '/incidents',
  INCIDENT_TOPICS: '/incident_topics',
  CONSTRUCTION_OBJECTS: '/aip_objects',
  ALL_YEARS: '/all_years',
  STATISTICS_NEW_MONTH: '/statistics_new_month',
  STATISTICS_NEW: '/statistics_new',
  SCANNERS: '/scanners',
  ZONES: '/districts',
  SUB_ZONES: '/districts',
  JOURNAL_LOGS: '/journal_logs',
  RESOURCES_COUNT: '/menu_counters',
  DATA_IMPORT: '/import_files',
  DB_SOURCE: '/db_sources',
  CUBES: '/cubes_v2',
  CUBES_MERGE: '/cubes_v2/merge_cubes',
  CUBE_CONNECTIONS: '/cube_connections',
  IMPORT_TABLE: '/imported_tables',
  NSI: '/nsi',
  DESKTOP: '/desktop_buttons',
  VIDEO_MAP: '/cameras',
  WEATHER: '/weather',
  EVENTS: '/events',
  VARIABLES: '/variables',
  STAFF: '/kadri',
  STAFF_FAVORITES: '/favindex',
  STAFF_ADD_FAV: '/addkadrtofav',
  STAFF_REMOVE_FAV: '/remkadrfromfav',
  UPDATE_SCHEDULE: '/upload_cals',
  CONVERTER: '/converter',
  FPD_LAYERS: '/layers',
  FORECASTING: '/forecasting',
  IMPORT_FILES: '/covid_import_files',
  AIP_OBJECTS: '/aip_objects',
  STATISTICS_DATA: '/statistics_statistics_data',
  STATISTICS_FEDERAL_CHART: '/statistics_federal_chart',
  STATISTICS_ACTUAL_STREAM: '/statistics_actual_stream',
  STATISTICS_YEARS: '/statistics_years',
  STATISTICS_EXAMINATION_STATUSES: '/statistics_examination_statuses',
  STATISTICS_CATEGORY: '/statistics_category',
  STATISTICS_GOSPROGRAM: '/statistics_gosprogram',
  MSP: '/cubes_v2/msp',
  CUBES_CZ: '/cubes_v2/cz'
}

export const RESOURCE_ENDPOINT = {
  [CATALOG_ITEMS.NAME]: ENDPOINT.CATALOG_ITEMS,
  [REPORTS.NAME]: ENDPOINT.REPORTS,
  [PUBLICATIONS.NAME]: ENDPOINT.PUBLICATIONS,
  [FORMS.NAME]: ENDPOINT.FORMS,
  [CHARTS.NAME]: ENDPOINT.CHARTS,
  [INFO_REFERENCES.NAME]: ENDPOINT.INFO_REFERENCES,
  [WIDGETS.NAME]: ENDPOINT.WIDGETS,
  [INCIDENTS.NAME]: ENDPOINT.INCIDENTS,
  [CONSTRUCTION_OBJECTS.NAME]: ENDPOINT.CONSTRUCTION_OBJECTS,
  [PANELS.NAME]: ENDPOINT.PANELS,
  [MESSAGES.NAME]: ENDPOINT.MESSAGES,
  [SCANNER_ENTITIES.NAME]: ENDPOINT.SCANNERS,
  [JOURNAL_LOGS.NAME]: ENDPOINT.JOURNAL_LOGS
}

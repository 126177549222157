// @ts-nocheck
import React, { PureComponent, useMemo } from 'react'
import Creatable from 'react-select'
import AsyncSelect from 'react-select'
import ReactAsyncSelectProps from 'react-select'
import ReactCreatableSelectProps from 'react-select'
import ReactAsyncCreatableSelectProps from 'react-select'
import ReactSelectProps from 'react-select'
import ArrowRendererProps from 'react-select'
import OptionType from 'react-select'
import OptionValues from 'react-select'
import LoadOptionsHandler from 'react-select'
import AutocompleteResult from 'react-select'
import Select, {
  Options,
} from 'react-select'
import injectStyles, { JSSProps } from 'react-jss'
import classNames from 'classnames'
import isEqual from 'lodash/isEqual'

import { Arrow, Option, Value } from 'components/Select'
import styles from './styles'
import CustomDiv from 'src/components/CustomDiv'

export interface OuterProps
  extends ReactSelectProps<OptionValues>,
  Partial<ReactAsyncCreatableSelectProps<OptionValues>> {
  id?: string
  label?: string
  options?: Options<OptionValues>
  height?: string
  backgroundColor?: string
  isSearch?: boolean
  multi?: boolean
  withAddingAllOptions?: boolean
  addingAllOptionsLabel?: string
  withBackground?: boolean
  isCreatable?: boolean
  isAsync?: boolean
}
export interface Props extends OuterProps, JSSProps<typeof styles> { }
interface State {
  options: Options<OptionValues>
  setInitialValue: boolean
}

const BaseStyles = {
  menu: (baseStyles) => ({
    ...baseStyles,
    zIndex: 9999,
  })
}

class Base extends PureComponent<Props, State> {
  static defaultProps = {
    options: [],
    withAddingAllOptions: false,
    withBackground: false,
    setInitialValue: false
  }

  setAllOption: OptionType<string> = {
    value: 'addAll',
    label: 'Добавить все элементы'
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      options: this.getOptions(props),
    }

    if (props.addingAllOptionsLabel) this.setAllOption.label = props.addingAllOptionsLabel
  }

  componentWillReceiveProps(nextProps: Props) {
    if (!isEqual(nextProps.options, this.props.options)) {
      this.setState({
        options: this.getOptions(nextProps),
      })
    }
  }

  // todo autoselect option by id for async select
  // componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
  //   if (
  //     this.props.isAsync &&
  //     this.props.options.length &&
  //     !this.props.setInitialValue &&
  //     typeof this.props.value === 'string' &&
  //     this.props.value.length > 0
  //   ) {
  //     const foundValue = this.props.options.find(
  //       item => String(item.id) === String(this.props.value)
  //     )
  //
  //     if (foundValue !== -1) this.props.onChange(this.props.name, foundValue)
  //
  //     this.setState({
  //       setInitialValue: true
  //     })
  //   }
  // }

  getOptions(props: Props) {
    if (props.withAddingAllOptions) {
      return [this.setAllOption].concat(props.options)
    }

    return props.options
  }

  get value() {
    if (this?.props?.value) return this?.state?.options?.find?.(item => item.value === this.props.value) || this.props.value
    return this.props.value
  }

  loadOptions: LoadOptionsHandler<string> = async (input, callback) => {
    const { loadOptions, withAddingAllOptions } = this.props
    const result = (await loadOptions(input, callback)) as AutocompleteResult<string>

    if (withAddingAllOptions && result.options.length) {
      result.options = [this.setAllOption].concat(result.options)
    }

    this.setState({ options: result.options })

    return result
  }

  renderArrow = (props: ArrowRendererProps) => <Arrow {...props} />

  renderValue = (option: OptionType) => {
    const { multi, valueKey, labelKey } = this.props

    return <Value option={option} valueKey={valueKey} labelKey={labelKey} multi={multi} />
  }

  onChange = (newValue: OptionType<string> | Options<string>) => {
    const { withAddingAllOptions, onChange, options: propsOptions, isAsync } = this.props
    const { options: stateOptions } = this.state
    const options = isAsync
      ? stateOptions.filter(option => option.value !== this.setAllOption.value)
      : propsOptions

    if (
      withAddingAllOptions &&
      (newValue as Options<string>).some(option => option.value === this.setAllOption.value) &&
      options.length
    ) {
      onChange(options)
    } else {
      if (newValue && (newValue as OptionType).disabled) return

      onChange(newValue)
    }
  }

  render() {
    const {
      classes,
      className,
      theme,
      name,
      label,
      options: propsOptions,
      isCreatable,
      isAsync,
      onChange,
      loadOptions,
      id,
      multi,
      styles = BaseStyles,
      ...props
    } = this.props
    const { options } = this.state
    const value = this.value;
    const instanceId = id || name

    let finalSelect = <Select
      key={instanceId}
      instanceId={instanceId}
      className={classNames(classes.selector, className)}
      options={options}
      styles={styles}
      optionClassName={classes.selectorOption}
      optionComponent={Option}
      arrowRenderer={this.renderArrow}
      valueRenderer={this.renderValue}
      onChange={this.onChange}
      noResultsText="Результатов не найдено"
      placeholder="Выберите опцию"
      isMulti={multi}
      {...props}
      value={value}
    />

    if (isAsync) {
      finalSelect = (
        // @ts-ignore
        <AsyncSelect
          key={instanceId}
          styles={styles}
          instanceId={instanceId}
          className={classNames(classes.selector, className)}
          optionClassName={classes.selectorOption}
          optionComponent={Option}
          loadingPlaceholder="Подождите..."
          searchPromptText="Напечатайте для поиска"
          placeholder="Напечатайте для поиска"
          loadOptions={this.loadOptions}
          arrowRenderer={this.renderArrow}
          valueRenderer={this.renderValue}
          onChange={this.onChange}
          isMulti={multi}
          noResultsText="Результатов не найдено"
          {...(props as ReactAsyncSelectProps)}
          value={value}
        />
      )
    } else if (isCreatable) {
      finalSelect = (
        <Creatable
          key={instanceId}
          instanceId={instanceId}
          className={classNames(classes.selector, className)}
          options={options}
          styles={styles}
          optionClassName={classes.selectorOption}
          optionComponent={Option}
          arrowRenderer={this.renderArrow}
          valueRenderer={this.renderValue}
          onChange={this.onChange}
          isMulti={multi}
          noResultsText="Результатов не найдено"
          {...(props as ReactCreatableSelectProps)}
          value={value}
        />
      )
    }

    return (
      <CustomDiv display='flex' flexDirection='column' width='100%'>
        {label && <label className={classes.label} htmlFor={instanceId}>{label}</label>}
        {finalSelect}
      </CustomDiv>
    )
  }
}

export default injectStyles<OuterProps>(styles)(Base)

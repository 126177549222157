import { takeEvery, put, select, all, call } from 'redux-saga/effects'
import { error } from 'react-notification-system-redux'

import fetchAPI from 'lib/services/fetchAPI'
import APIAction from 'lib/utils/APIAction'
import { tokenSelector } from 'lib/modules/user/selectors'
import {
  readResourcesCountRequest,
  readResourcesCountRequestSuccess,
  readResourcesCountRequestFailure,
  ReadResourcesCount
} from './actions'
import { ENDPOINT } from 'constants/api'
import TYPES from './types'

function* getResourcesCount(action: ReadResourcesCount) {
  const token = yield select(tokenSelector)

  yield APIAction({
    *request() {
      yield put(readResourcesCountRequest())

      return yield fetchAPI({
        token,
        endpoint: ENDPOINT.RESOURCES_COUNT
      })
    },
    *success(data) {
      yield put(readResourcesCountRequestSuccess(data))
    },
    *fail(errors) {
      yield put(readResourcesCountRequestFailure(errors))

      yield put(
        error({
          title: 'Не удалось получить данные.'
        })
      )
    }
  })
}

export function* watcher() {
  yield all([takeEvery(TYPES.GET_RESOURCES_COUNT, getResourcesCount)])
}

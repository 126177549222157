import axios from 'axios'
import { BASE_CHANNEL } from 'constants/actionCable'
import { API_URL, AUTH_AC_TYPES, METHOD } from 'constants/api'
import ActionCableService from 'lib/services/actionCable'
import { default as AuthPersistor, default as authPersistor } from 'lib/services/authPersistor'

interface Headers {
  [header: string]: string
}

export interface FetchOptions {
  method?: string
  domain?: string
  endpoint: string
  path?: string
  token?: string
  useToken?: boolean
  body?: any
  version?: string
  withoutVersion?: boolean
  getAbortController?: (source) => void
  onUploadProgress?: (progressEvent: ProgressEvent) => any
  onDownloadProgress?: (progressEvent: ProgressEvent) => any
  params?: { [key: string]: any }
  headers?: { [key: string]: string }
  responseType?: 'arraybuffer' | 'document' | 'json' | 'text' | 'stream' | 'blob'
}

const resetToken = error => {
  AuthPersistor.resetToken()
  window.location.href = '/login'
  throw error
}

function fetchAPI<T extends {}>({
  method = METHOD.GET,
  domain = API_URL,
  endpoint,
  path = '',
  token = '',
  useToken,
  body,
  params = {},
  headers = {},
  version = 'v1',
  withoutVersion = false,
  responseType,
  onUploadProgress,
  getAbortController,
  onDownloadProgress
}: FetchOptions) {
  const controller = new AbortController()
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()

  if (getAbortController) {
    getAbortController(source)
  }
  const withVersion = domain === API_URL && !withoutVersion
  const url = `${domain}${withVersion ? `/${version}` : ''}${endpoint}${path ? `/${path}` : ''}`

  const mainToken = useToken ? authPersistor.getToken() : token

  const reqHeaders: Headers = mainToken ? { 'X-User-Token': `${mainToken}`, ...headers } : { ...headers }

  if (process.env.NODE_ENV === 'development') {
    console.info('API CALL', method.toUpperCase(), url, reqHeaders, body)
  }

  return axios
    .request<T>({
      url,
      method,
      cancelToken: source.token,
      signal: controller.signal,
      data: body,
      headers: reqHeaders,
      params,
      responseType,
      onUploadProgress,
      onDownloadProgress
    })
    .catch(e => {
      if (
        // todo backend should send another error message for this request
        !url.includes('covid_import_files') &&
        e?.response?.status === 403 &&
        e?.response?.data?.error === 'not authenticate'
      ) {        
        try {
          ActionCableService.sendMessage<{ auth_token: string; token_ttl: number }>({
            channelName: BASE_CHANNEL,
            payload: {},
            type: AUTH_AC_TYPES.REFRESH_TOKEN
          })
            .then(({ payload: { auth_token } }) => {
              AuthPersistor.setToken(auth_token)
            })
            .catch(errorRefresh => {
              resetToken(errorRefresh)
            })
        } catch (errorRefresh) {
          resetToken(errorRefresh)
        }
      }

      throw e
    })
}

export default fetchAPI

import { TYPE } from './set'

export const SCANNER_SET_ID = {
  MEASURE: '*',
  DIMENSION: '#'
}
export enum FORM {
  // For measure
  SUM = 'sum',
  CORR = 'corr',
  AVG = 'avg',
  MEDIAN = 'median',
  VAR_SAMP = 'varSamp',
  VAR_POP = 'varPop',
  // For dimensions
  COUNT = 'count',
  UNIQ_EXACT = 'uniqExact',
  NONE = 'none'
}

export enum PLACE {
  COLUMN = 'column',
  ROW = 'row',
  // only sankey places
  SOURCE = 'source',
  TARGET = 'target',
  LINKS_MATRIX = 'linksMatrix'
}

export const OPERATORS = {
  PLUS: {
    sign: '+',
    name: 'Сложение'
  },
  MINUS: {
    sign: '-',
    name: 'Вычитание'
  },
  MULTIPLICATION: {
    sign: 'X',
    name: 'Умножение'
  },
  DIVISION: {
    sign: '/',
    name: 'Деление'
  }
}

export enum MATH_FUNCTION {
  EXPONENT = 'exponent',
  ABS = 'absolute',
  CUM = 'cumulative',
  GROWTH_RATE = 'growth_rate',
  INCREASE_RATE = 'increase_rate',
  MODE = 'mode',
  TREND = 'trend',
  NONE = 'none'
}

export enum SORTING {
  ASC = 'asc',
  DESC = 'desc',
  NONE = 'none'
}

export { TYPE }

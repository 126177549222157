import React, { FC } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import classNames from 'classnames'
import { Switch as MUISwitch } from '@mui/material'
import { WrappedFieldProps } from 'redux-form'

import styles from './styles'
import { SwitchProps } from '@mui/material'
interface OuterProps extends Omit<SwitchProps, 'classes'> {
  className?: string
  contentClassName?: string
  switchClassName?: string
  label?: string
}
export interface Props extends OuterProps, WrappedFieldProps, JSSProps<typeof styles> {}

const Switch: FC<Props> = ({
  classes,
  theme,
  switchClassName,
  className,
  contentClassName,
  label,
  input,
  color = 'primary',
  meta: { touched, error },
  ...props
}) => (
  <div className={classNames(classes.container, className)}>
    <div className={classNames(classes.content, contentClassName)}>
      {!!label && (
        <label className={classes.label} htmlFor={input.name}>
          {label}
        </label>
      )}
      <MUISwitch
        color={color}
        id={input.name}
        className={classNames(classes.switch, switchClassName)}
        checked={input.value}
        {...input}
        {...props}
      />
    </div>
    {touched && error && <span className={classes.error}>{error}</span>}
  </div>
)

export default injectStyles<OuterProps>(styles)(Switch)

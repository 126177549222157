import React, { PureComponent, ComponentType } from 'react'
import { OptionComponentProps, OptionValues } from 'react-select'
import injectStyles, { JSSProps } from 'react-jss'
import classNames from 'classnames'

import styles from './styles'

export interface Props extends OptionComponentProps<OptionValues>, JSSProps<typeof styles> {}

class Option extends PureComponent<Props> {
  onMouseDown = event => {
    event.preventDefault()
    event.stopPropagation()
    this.props.onSelect(this.props.option, event)
  }

  onMouseEnter = event => {
    this.props.onFocus(this.props.option, event)
  }

  onMouseMove = event => {
    if (this.props.isFocused) {
      return
    }

    this.props.onFocus(this.props.option, event)
  }

  render() {
    const {
      classes,
      className,
      children,
      option: { icon: Icon, title }
    } = this.props

    return (
      <div
        className={classNames(className, classes.container)}
        onMouseDown={this.onMouseDown}
        onMouseEnter={this.onMouseEnter}
        onMouseMove={this.onMouseMove}
        title={title}
      >
        {!!Icon && <Icon className={classes.icon} />}
        <span className={classes.text}>{children}</span>
      </div>
    )
  }
}

export default injectStyles<OptionComponentProps<OptionValues>>(styles)(Option)

import { Reducer } from 'redux'

import AuthPersistor from 'lib/services/authPersistor'
import { fromACLMask } from 'lib/utils/aclMask'
import types from './types'
import { ActionTypes } from './actions'
import { STATES } from 'constants/states'

export type State = Data.CurrentUser

const initialState: State = {
  id: null,
  token: AuthPersistor.getToken(),
  authorized: false,
  email: '',
  message_name: '',
  user_type: undefined,
  aclMask: 1,
  aclMap: fromACLMask(99999999999),
  status: STATES.IDLE,
  infoStatus: STATES.IDLE,
  layers_ids: []
}

export const reducer: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case types.AUTH_REQUEST:
      AuthPersistor.resetToken()
      return { ...state, token: undefined, authorized: false, status: STATES.LOADING }
    case types.AUTH_SUCCESS:
      AuthPersistor.setToken(action.payload.token)
      return { ...state, token: action.payload.token, authorized: true, status: STATES.SUCCEEDED }
    case types.AUTH_FAILURE:
      AuthPersistor.resetToken()
      return { ...state, token: undefined, authorized: false, status: STATES.FAILED }
    case types.AUTH_REFRESH_REQUEST:
      return { ...state, authorized: false, status: STATES.LOADING }
    case types.AUTH_REFRESH_SUCCESS: {
      AuthPersistor.setToken(action.payload.token)
      return { ...state, token: action.payload.token, authorized: true, status: STATES.SUCCEEDED }
    }
    case types.AUTH_REFRESH_FAILURE: {
      return { ...state, token: undefined, authorized: false, status: STATES.FAILED }
    }
    case types.AUTH_SET_TOKEN:
      AuthPersistor.setToken(action.payload.token)
      return { ...state, token: action.payload.token, authorized: false }
    case types.AUTH_RESET:
      AuthPersistor.resetToken()
      return { ...state, token: null, authorized: false }
    case types.GET_USER_INFO_REQUEST:
      return { ...state, infoStatus: STATES.LOADING }
    case types.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        id: action.payload.id,
        user_type: action.payload.user_type,
        email: action.payload.email,
        message_name: action.payload.message_name,
        aclMask: action.payload.acl_mask,
        auto_return: action.payload.auto_return,
        aclMap: fromACLMask(action.payload.acl_mask, action.payload.acl_mask_expanded),
        infoStatus: STATES.SUCCEEDED
      }
    case types.GET_USER_INFO_FAILURE:
      return { ...state, user_type: undefined, infoStatus: STATES.FAILED }
    case types.UPDATE_PROFILE_INFO_REQUEST:
      return { ...state, infoStatus: STATES.LOADING }
    case types.UPDATE_PROFILE_INFO_SUCCESS:
      return { ...state, infoStatus: STATES.SUCCEEDED }
    case types.UPDATE_PROFILE_INFO_FAILURE:
      return { ...state, infoStatus: STATES.FAILED }
    default:
      return state
  }
}

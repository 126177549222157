export enum Types {
  READ_MSP = 'READ_MSP',
  READ_MSP_LOADING = 'READ_MSP_LOADING',
  READ_MSP_SUCCESS = 'REACT_MSP_SUCCESS',
  READ_MSP_FAILURE = 'REACT_MSP_FAILURE',
  UPDATE_MSP = 'UPDATE_MSP',
  UPDATE_MSP_LOADING = 'UPDATE_MSP_LOADING',
  UPDATE_MSP_SUCCESS = 'UPDATE_MSP_SUCCESS',
  UPDATE_MSP_FAILURE = 'UPDATE_MSP_FAILURE'
}

import {
  ChartRatingType,
  TYPES as DATA_TYPES,
  VIEWS as DATA_VIEWS,
  EXPORT_SETTINGS
} from 'constants/dataView'
import { CONSTRUCTION_OBJECTS } from 'constants/resources'
import { ROLE } from 'constants/roles'
import { TableTheme } from 'src/components/DataView/components/Table/types'
import { DATE_FORMAT_TYPES } from '../utils/dateHelper'

const todayDate = new Date()

export const CHARTS_DATAS_FORM = {
  form: 'chart',
  destroyOnUnmount: false,
  __INITIAL_VALUES__: {
    name: '',
    description: '',
    stacked: false,
    settings: {
      emphasis: true
    },
    rating: ChartRatingType.NONE,
    smooth: false,
    linear_interpolation: true,
    geometric_interpolation: false,
    spline_smooth: false,
    show_only_positive: true,
    legend_position: 'bottom',
    show_abscissa_labels: true,
    show_ordinate_labels: true,
    values_font_size: 14,
    show_forecasting: false,
    forecasting_type: 'linear',
    forecasting_period: 1,
    forecasting_lags: 1,
    forecasting_model: {
      R2: undefined,
      relevant: undefined,
      significance_level: undefined,
      intercept: undefined,
      coefs: undefined
    },
    set_actual_date: false,
    columns: [],
    rows: [],
    source: [],
    target: [],
    linksMatrix: [],
    merge_cells: false,
    map_layers: ['Orto_LO_Map_vector'],
    map_tile_index: 0,
    active_scaling: false,
    styles: {
      table: {
        fontFamily: 'Open Sans, sans-serif',
        wrapText: false,
        orderNumRows: false,
        orderNumColumns: false,
        theme: TableTheme.allBlue,
        columnsWidth: {},
        headersTitle: {}
      },
      headers: {
        fontSize: 16,
        textAlign: 'center',
        color: '#FFFFFF'
      },
      values: {
        fontSize: 14,
        textAlign: 'center',
        paddingVertical: 16,
        paddingHorizontal: 16,
        color: '#000000'
      },
      lineChart: {
        linesWidth: 1,
        categoriesColor: {}
      },
      barChart: {
        barsWidthPercent: 30,
        categoriesColor: {}
      },
      pieChart: {
        categoriesColor: {}
      },
      scatterChart: {
        bubblesDiameter: undefined,
        categoriesColor: {}
      },
      chartAxes: {
        axisX: {
          title: '',
          color: '#404F5E',
          fontBold: true,
          fontItalic: false,
          fontUnderline: false,
          fontSize: 14,
          titleReverse: false,
          titleOffset: undefined,
          minNumValue: undefined,
          maxNumValue: undefined
        },
        axisY: {
          title: '',
          color: '#404F5E',
          fontBold: true,
          fontItalic: false,
          fontUnderline: false,
          fontSize: 14,
          titleReverse: false,
          titleOffset: undefined,
          minNumValue: undefined,
          maxNumValue: undefined
        }
      },
      sankeyChart: {
        linksColor: {},
        nodesColor: {}
      }
    } as Data.ChartStyles,
    dataView: {
      label: DATA_VIEWS[DATA_TYPES.LINE_CHART].label,
      icon: DATA_VIEWS[DATA_TYPES.LINE_CHART].icon,
      value: DATA_TYPES.LINE_CHART
    },
    filters: [],
    export_settings: {
      period: EXPORT_SETTINGS.PERIOD.NONE,
      sending: false
    }
  }
}

export const INFO_REFERENCE_FORM = {
  form: 'info_reference',
  destroyOnUnmount: false,
  initialValues: {
    name: '',
    import_file: null,
    export_path: null,
    info_element_ids: []
  }
}

export const INFO_SYSTEMS_FORM = {
  form: 'info_systems',
  destroyOnUnmount: false,
  initialValues: {
    items: []
  }
}

const WIDGET_FORM_INITIAL_VALUES = {
  title: '',
  top_block: {
    content: [{ text: '', insertions: [] }],
    font_size: '16px',
    color: '#ffffff',
    alignment: 'left'
  },
  bottom_block: {
    content: [{ text: '', insertions: [] }],
    font_size: '16px',
    color: '#ffffff',
    alignment: 'left'
  },
  top_middle_block: {
    content: [{ text: '', insertions: [] }],
    font_size: '16px',
    color: '#ffffff',
    alignment: 'left'
  },
  bottom_middle_block: {
    content: [{ text: '', insertions: [] }],
    font_size: '16px',
    color: '#ffffff',
    alignment: 'left'
  },
  insertions: [],
  background_image: ''
}

export const WIDGET_FORM = {
  form: 'widget',
  destroyOnUnmount: false,
  initialValues: WIDGET_FORM_INITIAL_VALUES
}

export const WIDGET_FORM_WITH_DESTROY = {
  form: 'widget',
  destroyOnUnmount: true,
  initialValues: WIDGET_FORM_INITIAL_VALUES
}

export const TECH_SUPPORT_MESSAGE_FORM = {
  form: 'tech_support_message',
  initialValues: {
    text: '',
    subject: ''
  }
}

export const INCIDENT_FORM = {
  form: 'incident',
  initialValues: {
    title: '',
    description: '',
    type_tag: '',
    user_ids: null,
    incident_topic: '',
    stage: 'moderation'
  }
}

export const CONSTRUCTION_OBJECT_FORM = {
  form: CONSTRUCTION_OBJECTS.NAME,
  // remove 's' ending
  formData: CONSTRUCTION_OBJECTS.NAME.slice(0, CONSTRUCTION_OBJECTS.NAME.length - 1),
  initialValues: {
    id: 0,
    paragraph: '',
    errands_outdated: [],
    place: '',
    errands_total: 0,
    installation_gk_cost: 0,
    additional_funding_cost: 0,
    gk_contract_sum: 0,
    execution_cost: 0,
    equipment_gk_cost: 0,
    gk_all_sum: 0,
    other_gk_cost: 0,
    image: null,
    examination_status: '',
    department: null,
    category: null,
    performer: null,
    district: null,
    gosprogram: null,
    customer: null,
    is_regional_financing: false,
    is_federal_budget: false,
    is_national_project: false,
    is_active: null,
    cofinancing: true,
    number: null,
    name: '',
    grow_percent: 0,
    completion: 0,
    need: 0,
    note: '',
    predicted_grow: 0,
    exploitationed_at: null,
    contract_sum: 0,
    aip_budgets: [],
    aip_docs: [],
    aip_images: [],
    aip_errands: [],
    aip_streams: [],
    aip_stages: [],
    videostream: []
  } as Data.ConstructionObject
}

export const INCIDENT_MESSAGE_FORM = {
  form: 'incident_message',
  initialValues: {
    attachments: [],
    message: '',
    send_mail: false
  }
}

export const INCIDENT_ACCESS_FORM = {
  form: 'incident_access',
  initialValues: {
    users: []
  }
}

export const REPORT_FORM = {
  form: 'report',
  initialValues: {
    name: '',
    description: '',
    dimensions: [],
    measures: [],
    conditions_array: [],
    period: null,
    // 09:00 hardcode
    time: 540,
    email: true
  }
}

const PANEL_FORM_INITIAL_VALUES = {
  name: '',
  accessByLink: false,
  accessInClosePart: false,
  link_key: '',
  published_in_open_part: false,
  on_main: false,
  is_archived: false,
  mobile_access: false,
  user_access_array: ['access_all'],
  last_updated: null,
  last_updated_format: DATE_FORMAT_TYPES.yearMonthDay,
  show_last_updated: false,
  auto_last_updated: true,
  settings: {},
  templates: {}
}

export const PANEL_FORM = {
  form: 'panel',
  initialValues: PANEL_FORM_INITIAL_VALUES
}

export const PANEL_FORM_WITHOUT_DESTROY = {
  form: 'panel',
  destroyOnUnmount: false,
  initialValues: PANEL_FORM_INITIAL_VALUES
}

export const SCANNER_EDITOR_FORM = {
  form: 'scanner',
  enableReinitialize: true,
  destroyOnUnmount: true,
  initialValues: {
    title: '',
    category: null,
    dimensions: [],
    measures: [],
    conditions_array: [],
    violation: false
  }
}

export const FORMS_EDITOR_FORM = {
  form: 'forms',
  enableReinitialize: true,
  destroyOnUnmount: true
}

export const MESSAGE_FORM = {
  form: 'message',
  enableReinitialize: true,
  destroyOnUnmount: true,
  initialValues: {
    text: '',
    subject: '',
    receiver_id: null,
    receiver_type: null,
    forms: [],
    attachments: [],
    info_panels: [],
    info_elements: []
  }
}

export const TABLE_FIELD_FORM = {
  form: 'table_field',
  enableReinitialize: true,
  destroyOnUnmount: true
}

export const REPORT_SETTINGS_FORM = {
  form: 'reportSettings',
  enableReinitialize: true,
  destroyOnUnmount: true,
  initialValues: {
    dateStart: todayDate,
    dateEnd: todayDate
  }
}

export const USER_SETTINGS_FORM = {
  form: 'userSettings',
  enableReinitialize: true,
  destroyOnUnmount: true,
  initialValues: {
    email: '',
    password: '',
    repeatPassword: '',
    customer: ''
  }
}

export const USER_FORM = {
  form: 'user',
  enableReinitialize: true,
  destroyOnUnmount: true,
  initialValues: {
    email: '',
    user_type: ROLE.USER,
    aclMap: {},
    auto_return: {},
    layers_ids: [],
    tech_support: false
  }
}

export const NEWS_FORM = {
  form: 'news',
  enableReinitialize: true,
  destroyOnUnmount: true,
  initialValues: {
    text: '',
    title: '',
    blocks: [],
    published: true
  }
}

export const CATALOG_ITEMS_FORM = {
  form: 'catalog_items',
  enableReinitialize: true,
  destroyOnUnmount: true,
  initialValues: {
    description: '',
    inn: '',
    name: '',
    hierarchies: []
  }
}

export const DESKTOP_FORM = {
  form: 'desktop',
  initialValues: {
    buttons: (function() {
      const result: Data.DesktopPanel['buttons'] = []

      for (let i = 0; i < 16; i += 1) {
        result.push({
          id: undefined,
          panel_ids: [],
          button_type: undefined,
          image: undefined,
          title: undefined,
          tag: undefined,
          files: [],
          links: [],
          position: undefined
        })
      }

      return result
    })(),
    element_id: undefined
  }
}

export const SCHEDULE_FORM = {
  form: 'schedule',
  initialValues: {
    cal1: '',
    cal2: ''
  }
}

export const SCDATA_ACCESS_FORM = {
  form: 'scdata_access',
  initialValues: {
    users: []
  }
}

export const SCDATA_SETTINGS_REFRESH_DATA_FORM = {
  form: 'scdata_settings_refresh_data',
  initialValues: {
    user_id: '',
    reporting_day: null,
    reporting_day_name: null,
    reporting_month: null,
    reporting_hour: null,
    frequency: null,
    notify_before: []
  }
}

export const SCDATA_CREATE_DATA_FORM = {
  form: 'scdata_create_data_form',
  initialValues: {
    name: '',
    description: '',
    nsi: false
  }
}

export const SCDATA_EDIT_COLUMN_FORM = {
  form: 'scdata_edit_column_form',
  initialValues: {
    name: '',
    pr_key: false,
    postfix: '',
    type: undefined,
    nsi: null
  }
}

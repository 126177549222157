const styles = (theme: OpenApp.Theme) => ({
  portal: {},
  container: {
    ...theme.utils.flexContainer({
      direction: 'row',
      justify: 'space-between',
      alignItems: 'flex-start'
    }),
    width: '40%',
    height: '100%',
    backgroundColor: 'transparent',
    outline: 'none'
  },
  overlay: {
    ...theme.utils.flexContainer({ justify: 'center', alignItems: 'flex-end' }),
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 100001,

    '& .ReactModal__Content': {
      transform: 'translateX(100%)',
      transition: 'transform 0.4s',

      '&--after-open': {
        transform: 'translateX(0)'
      }
    }
  },
  content: {
    display: 'flex',
    width: '90%',
    height: '100%',
    backgroundColor: '#FFFFFF',
    boxSizing: 'border-box',
    '-webkit-overflow-scrolling': 'touch'
  },
  info: {
    ...theme.utils.flexContainer({ direction: 'column' }),
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'scroll'
  },
  block: {
    width: '100%',
    padding: '0.5rem 1rem',
    boxSizing: 'border-box'
  },
  buttons: {
    ...theme.utils.flexContainer({ direction: 'column', alignItems: 'center' }),
    width: '10%',
    marginTop: '2.5rem',
    '& > svg:hover': {
      opacity: 0.8
    }
  },
  contacts: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    height: '100%',
    padding: '3rem 2.5rem',
    backgroundColor: '#FAFAFA',
    boxShadow: '0 3px 5px 0 rgba(0,0,0,0.10)',
    boxSizing: 'border-box',
    overflow: 'auto',
    '& > *': { flexShrink: 0 }
  },
  data: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    height: '100%',
    padding: '3rem 2.5rem',
    backgroundColor: '#F3F3F3',
    boxSizing: 'border-box',
    overflow: 'auto',
    '& > *': { flexShrink: 0 }
  },
  orgTitle: {
    margin: 0,
    fontFamily: 'Helvetica',
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#2C538C',
    margin: '0.5rem 0'
  },
  tagsRow: {
    ...theme.utils.flexContainer({ direction: 'row', wrap: 'wrap', alignItems: 'center' }),
    width: '100%',
    margin: '1rem 0'
  },
  tag: {
    display: 'inline',
    fontWeight: 'normal',
    fontSize: '0.75rem',
    marginRight: '0.5rem',
    padding: '0.25rem 0.5rem',
    backgroundColor: '#858E98',
    color: '#FFFFFF'
  },
  tagBranch: { color: '#000000' },
  tagIcon: {
    height: '0.75rem',
    width: '0.75rem',
    margin: '0 0.5rem 0 0'
  },
  controlledTitleWrapper: {
    ...theme.utils.flexContainer({
      direction: 'row',
      alignItems: 'center',
      justify: 'space-between'
    }),
    width: '100%',
    borderTop: `1px solid ${theme.colors.grey}`
  },
  controlledList: {
    flex: '1 0 auto',
    ...theme.utils.flexContainer({ direction: 'column', wrap: 'wrap' }),
    width: '100%',
    margin: 0,
    padding: 0,
    listStyle: 'none'
  },
  controlledListItem: {
    width: '100%',
    margin: '0.25rem 0',
    boxSizing: 'border-box'
  },
  button: {
    fontSize: '1rem',
    borderRadius: '0.5rem'
  },
  requisitesSection: {
    ...theme.utils.flexContainer({ direction: 'row', justify: 'space-between' }),
    width: '100%'
  },
  requisitesColumn: {
    ...theme.utils.flexContainer({ direction: 'column' }),
    width: '50%',
    flexBasis: '50%'
  },
  title: {
    width: '100%',
    padding: '0.5rem 1rem',
    margin: 0,
    fontFamily: 'Helvetica',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    color: '#000000',
    backgroundColor: '#F7F7F7',
    boxShadow: 'inset 0 -1px 0 0 #EFEFEF, inset 0 1px 0 0 #EFEFEF'
  }
  // info: {
  //   margin: '0.25rem 0',
  //   fontFamily: 'Helvetica',
  //   fontSize: '0.875rem',
  //   fontWeight: 'bold',
  //   color: '#000000'
  // }
})

export default styles

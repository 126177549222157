import { TYPE } from 'constants/set'
import { OuterProps } from './index'

const styles = (theme: App.Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.375rem 0.3125rem',
    backgroundColor: ({ type, background }: OuterProps) =>
      background
        ? background
        : type === TYPE.DIMENSION
        ? theme.colors.set.dimension
        : theme.colors.set.measure,
    borderRadius: '0.3125rem',
    transform: 'translate3d(0, 0, 0)'
  },
  icon: {
    flexShrink: 0,
    width: '1em',
    height: '1em',
    marginRight: '0.3125rem',
    fontSize: '0.75rem',
    fill: theme.colors.grey,
    stroke: theme.colors.grey
  },
  sets: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    fontSize: '0.75rem',
    lineHeight: 1.2,
    color: '#000'
  },
  set: {},
  cubeName: {
    fontWeight: 'bold'
  },
  sign: {
    margin: '0 0.3125rem'
  }
})

export default styles

import React, { Component, ReactNodeArray } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { compose } from 'recompose'

import Modal from 'react-modal'

import { branchesSelector } from 'lib/modules/branches/selectors'
import { getBranches } from 'lib/modules/branches/actions'
import { closeModal, openModal } from 'lib/modules/open/modals/actions'
import { getModalItemId, isModalOpen } from 'lib/modules/open/modals/selectors'
import fetchAPI from 'lib/services/fetchAPI'
import { METHOD, ENDPOINT } from 'constants/api'
import { iconById } from 'constants/branches'
import { MODALS_NAMES } from 'constants/modals'
import Loading from 'components/Loading'
import ButtonRounded from 'components/ButtonRounded'
import { ArrowRight, SvgCloseButton } from 'components/Icons'
import DataCell from 'components/DataCell'
import Tag from 'components/Tag'
import styles from './styles'

interface OuterProps {
  /* react-modal passed props */
  closeTimeoutMS?: number
  contentLabel?: string
}
interface StateProps {
  branches: Array<Data.Branch>
  isOpen: boolean
  itemId?: number
}
interface DispatchProps {
  readBranches: () => void
  closeModal: () => void
  openModal: (id: number) => void
}
interface Props extends JSSProps<typeof styles>, OuterProps, StateProps, DispatchProps {}
interface State {
  info?: Data.Organisation
  grbs?: Data.OrganisationShort
  ratingOrgs?: Data.Organisation[]
  controlledOrgs?: Data.Organisation[]
}

Modal.setAppElement(document.getElementById('root'))

class OrganisationModal extends Component<Props, State> {
  state: State = {
    info: null,
    ratingOrgs: [],
    controlledOrgs: []
  }

  onAfterOpen = () => {
    const { itemId } = this.props
    if (itemId) this.getInfo(itemId)
    this.fetchRatings()
  }

  componentDidMount() {
    this.props.readBranches()
  }

  componentDidUpdate(prevProps: Props) {
    const { itemId } = this.props

    if (prevProps.itemId !== itemId && itemId) {
      this.getInfo(itemId)
    }
  }

  async fetchRatings() {
    const { data } = await fetchAPI<Data.Organisation[]>({
      method: METHOD.GET,
      endpoint: ENDPOINT.ORGANISATIONS,
      params: { is_grbs: true, full_info: true }
    })

    this.setState({ ratingOrgs: data.filter(item => item.indices) })
  }

  async getInfo(id: number) {
    this.setState({ info: null })

    const { data } = await fetchAPI<Data.Organisation[]>({
      method: METHOD.GET,
      endpoint: ENDPOINT.ORGANISATIONS,
      params: { ids: [id], full_info: true }
    })

    if (data[0].organisation_id === data[0].grbs_id) {
      this.getControlledOrgs(id)
    } else {
      this.getGRBS(data[0].grbs_id)
    }

    this.setState({ info: data[0] })
  }

  async getControlledOrgs(id: number) {
    const { data } = await fetchAPI<Data.Organisation[]>({
      method: METHOD.GET,
      endpoint: ENDPOINT.ORGANISATIONS,
      params: { grbs_id: id, full_info: true }
    })

    this.setState({ controlledOrgs: data })
  }

  async getGRBS(id: number) {
    const { data } = await fetchAPI<Data.OrganisationShort[]>({
      method: METHOD.GET,
      endpoint: ENDPOINT.ORGANISATIONS,
      params: { ids: [id] }
    })

    this.setState({ grbs: data[0] })
  }

  get isLoading() {
    return !this.state.info
  }

  get hasRating() {
    const organisation = this.state.ratingOrgs.find(
      item => item.id === this.state.info.organisation_id
    )

    return !!(organisation && organisation.indices && organisation.indices.length)
  }

  get isGrbs() {
    return this.state.info.organisation_id === this.state.info.grbs_id
  }

  get grbs() {
    return this.state.ratingOrgs.find(item => item.id === this.state.info.organisation_id)
  }

  getButtonsForOrganisation(element: Data.OrganisationShort): ReactNodeArray {
    const { classes } = this.props

    return [
      <ButtonRounded
        key="show-button"
        className={classes.button}
        icon={ArrowRight}
        title="Просмотреть"
        squared
        onClick={this.openModal(element.id)}
      />
    ]
  }

  openModal = (itemId: number) => () => this.props.openModal(itemId)

  render() {
    const {
      classes,
      closeModal,
      branches,
      isOpen,
      contentLabel,
      closeTimeoutMS,
      theme
    } = this.props

    const { info, controlledOrgs, grbs } = this.state

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={() => closeModal()}
        onAfterOpen={this.onAfterOpen}
        contentLabel={contentLabel}
        closeTimeoutMS={closeTimeoutMS}
        portalClassName={classes.portal}
        className={classes.container}
        overlayClassName={classes.overlay}
      >
        <div className={classes.buttons}>
          <SvgCloseButton
            width={'28px'}
            height={'28px'}
            cursor="pointer"
            onClick={() => closeModal()}
          />
        </div>
        <div className={classes.content}>
          {this.isLoading ? (
            <Loading delay={0} />
          ) : (
            <div className={classes.info}>
              <div className={classes.block}>
                <Tag value={`ID ${info.organisation_id}`} className={classes.tag} />
                <h1 className={classes.orgTitle}>{info.full_name}</h1>
                {branches && branches.length > 0 ? (
                  <Tag
                    key={branches[0].id}
                    icon={() =>
                      iconById[branches[0].id] ? (
                        <svg className={classes.tagIcon}>
                          <use xlinkHref={`#${iconById[branches[0].id].id}`} />
                        </svg>
                      ) : null
                    }
                    iconPosition="left"
                    value={branches[0].title}
                    className={classes.tagBranch}
                  />
                ) : null}
              </div>
              <h2 className={classes.title}>Контактные данные</h2>
              <div className={classes.block}>
                <DataCell name="Телефон / Факс" value={info.phone} />
                <DataCell name="Юридический адрес" value={info.factual_address} />
                <DataCell name="E-mail" value={info.email} />
              </div>
              <h2 className={classes.title}>Реквизиты</h2>
              <div className={classes.block}>
                <DataCell name="ИНН" value={info.INN} />
                <DataCell name="ОКПО" value={info.OKPO} />
                <DataCell name="СПЗ" value={info.spz} />
                <DataCell name="Шифр" value={info.cipher} />
                <DataCell name="КПП" value={info.KPP} />
                <DataCell name="ОКПОФ" value={info.OKOPF} />
                <DataCell name="ОГРН" value={info.OGRN} />
                <DataCell name="Лицевой счёт" value={info.contragent_account} />
              </div>
            </div>
          )}
        </div>
      </Modal>
    )
  }
}

const mapStateToProps: MapStateToProps<StateProps, Props, OpenApp.State | App.State> = state => ({
  branches: branchesSelector(state),
  isOpen: isModalOpen(MODALS_NAMES.ORGANISATION)(state),
  itemId: getModalItemId(MODALS_NAMES.ORGANISATION)(state) as number
})
const mapDispatchToProps: MapDispatchToProps<DispatchProps, Props> = dispatch => ({
  readBranches: () => dispatch(getBranches()),
  closeModal: () => dispatch(closeModal(MODALS_NAMES.ORGANISATION)),
  openModal: id => dispatch(openModal(MODALS_NAMES.ORGANISATION, id))
})

export default compose<Props, OuterProps>(
  
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectStyles(styles)
)(OrganisationModal)

import React, { HTMLProps, ComponentType, ButtonHTMLAttributes } from 'react'
import { Link } from 'react-router-dom'
import injectStyles, { JSSProps } from 'react-jss'
import classNames from 'classnames'

import styles from './styles'

export interface OuterProps extends HTMLProps<HTMLElement> {
  pathname?: string
  url?: string
  className?: string
  title?: string
  filled?: boolean
  icon?: ComponentType<{ className: string }>
  view?: string
  squared?: boolean
}
export interface Props extends OuterProps, JSSProps<typeof styles> {}

const RoundedButton = React.forwardRef<HTMLButtonElement | Link | HTMLAnchorElement, Props>(
  (
    {
      classes,
      className,
      pathname,
      url,
      title = 'Кнопка',
      filled = false,
      icon: Icon,
      theme,
      type = 'button',
      view = 'default',
      squared,
      ...props
    },
    ref
  ) => {
    const classNameResult = classNames(
      classes.container,
      {
        [classes.default]: view === 'default',
        [classes.danger]: view === 'danger',
        [classes.filled]: filled
      },
      className
    )

    if (url) {
      return (
        <a
          // @ts-ignore
          ref={ref as React.Ref<HTMLAnchorElement>}
          className={classNameResult}
          title={title}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          {...props}
        >
          <Icon className={classes.icon} />
        </a>
      )
    } else if (pathname) {
      return (
        <Link
          // @ts-ignore
          ref={ref}
          className={classNameResult}
          title={title}
          to={pathname}
          {...props}
        >
          <Icon className={classes.icon} />
        </Link>
      )
    }

    return (
      <button
        // @ts-ignore
        ref={ref}
        type={type as ButtonHTMLAttributes<HTMLButtonElement>['type']}
        className={classNameResult}
        title={title}
        {...props}
      >
        <Icon className={classes.icon} />
      </button>
    )
  }
)

export default injectStyles<OuterProps>(styles)(RoundedButton)

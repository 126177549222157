enum ActionTypes {
  INITIALIZE_PANEL = 'INITIALIZE_PANEL',
  DESTROY_PANEL = 'DESTROY_PANEL',
  ADD_TEMPLATE = 'ADD_TEMPLATE',
  DELETE_TEMPLATE = 'DELETE_TEMPLATE',
  MOVE_TEMPLATE = 'MOVE_TEMPLATE',
  DELETE_ELEMENT = 'DELETE_ELEMENT',
  REPLACE_ELEMENT = 'REPLACE_ELEMENT',
  DELETE_CELL = 'DELETE_CELL',
  REPLACE_CELL = 'REPLACE_CELL',
  OPEN_ELEMENT_MODAL = 'OPEN_ELEMENT_MODAL',
  CLOSE_ELEMENT_MODAL = 'CLOSE_ELEMENT_MODAL',
  OPEN_PANEL_MODAL = 'OPEN_PANEL_MODAL',
  CLOSE_PANEL_MODAL = 'CLOSE_PANEL_MODAL',

  // POST_ELEMENT_ATTACHEMENT = 'POST_ELEMENT_ATTACHEMENT',
  // POST_ELEMENT_ATTACHEMENT_REQUEST = 'POST_ELEMENT_ATTACHEMENT_REQUEST',
  // POST_ELEMENT_ATTACHEMENT_SUCCESS = 'POST_ELEMENT_ATTACHEMENT_SUCCESS',
  // POST_ELEMENT_ATTACHEMENT_FAILURE = 'POST_ELEMENT_ATTACHEMENT_FAILURE',

  // DELETE_ELEMENT_ATTACHEMENT = 'DELETE_ELEMENT_ATTACHEMENT',
  // DELETE_ELEMENT_ATTACHEMENT_REQUEST = 'DELETE_ELEMENT_ATTACHEMENT_REQUEST',
  // DELETE_ELEMENT_ATTACHEMENT_SUCCESS = 'DELETE_ELEMENT_ATTACHEMENT_SUCCESS',
  // DELETE_ELEMENT_ATTACHEMENT_FAILURE = 'DELETE_ELEMENT_ATTACHEMENT_FAILURE',

  GET_ALL_PANELS_ELEMENTS = 'GET_ALL_PANEL_ELEMENTS',
  GET_ALL_PANELS_ELEMENTS_REQUEST = 'GET_ALL_PANEL_ELEMENTS_REQUEST',
  GET_ALL_PANELS_ELEMENTS_SUCCESS = 'GET_ALL_PANEL_ELEMENTS_SUCCESS',
  GET_ALL_PANELS_ELEMENTS_FAILURE = 'GET_ALL_PANEL_ELEMENTS_FAILURE',

  SEARCH_PANELS_ELEMENTS = 'SEARCH_PANEL_ELEMENTS',
  SEARCH_PANELS_ELEMENTS_REQUEST = 'SEARCH_PANEL_ELEMENTS_REQUEST',
  SEARCH_PANELS_ELEMENTS_SUCCESS = 'SEARCH_PANEL_ELEMENTS_SUCCESS',
  SEARCH_PANELS_ELEMENTS_FAILURE = 'SEARCH_PANEL_ELEMENTS_FAILURE',

  GET_WIDGETS = 'GET_WIDGETS',
  GET_WIDGETS_REQUEST = 'GET_WIDGETS_REQUEST',
  GET_WIDGETS_SUCESS = 'GET_WIDGETS_SUCCESS',
  GET_WIDGETS_FAILURE = 'GET_WIDGETS_FAILURE'
}

export default ActionTypes

import { takeEvery, put, select, all, call } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import { success, error } from 'react-notification-system-redux'

import fetchAPI from 'lib/services/fetchAPI'
import APIAction from 'lib/utils/APIAction'
import getResourceActions from 'lib/utils/getResourceActions'
import { tokenSelector } from 'lib/modules/user/selectors'
import {
  ReadIncidents,
  CreateIncident,
  UpdateIncident,
  DeleteIncident,
  CopyIncident
} from './actions'
import { METHOD, ENDPOINT } from 'constants/api'
import TYPES from './actionTypes'
import objectToFormData from 'src/lib/utils/objectToFormData'

function* readIncidents(action: ReadIncidents) {
  const { requestKey, resourceType, requestProperties, list } = action.payload
  const token = yield select(tokenSelector)
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'READ',
    resourceType,
    requestKey,
    requestProperties,
    list
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      const { data }: { data: Data.Incident[] } = yield call(fetchAPI, {
        endpoint: ENDPOINT.INCIDENTS,
        method: METHOD.GET,
        token,
        params: requestProperties
      })

      return { data }
    },
    *success(data) {
      yield put(onSuccessAction(data))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось получить данные.'
        })
      )
    }
  })
}

function* createIncident(action: CreateIncident) {
  const token = yield select(tokenSelector)

  const { requestKey, resourceType, requestProperties } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'CREATE',
    resourceType,
    requestKey,
    requestProperties
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())
      const { incident } = requestProperties

      const { data }: { data: Data.Incident } = yield call(fetchAPI, {
        endpoint: ENDPOINT.INCIDENTS,
        method: METHOD.POST,
        token,
        body: incident
      })

      return { data: [data] }
    },
    *success(data: Array<Data.Incident>) {
      yield put(onSuccessAction(data))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось создать инцидент.'
        })
      )
    }
  })
}

function* updateIncident(action: UpdateIncident) {
  const token = yield select(tokenSelector)

  const { requestKey, resourceType, requestProperties } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'UPDATE',
    resourceType,
    requestKey,
    requestProperties
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())
      const { incident } = requestProperties
      const body = {
        ...incident,
        // @ts-expect-error
        user_ids: incident.user_ids.map(item => item.value || item)
      }

      const { data }: { data: Data.Incident } = yield call(fetchAPI, {
        endpoint: ENDPOINT.INCIDENTS,
        method: METHOD.PUT,
        path: requestProperties.incident.id,
        token,
        body
      })

      return { data: [data] }
    },
    *success(data: Array<Data.Chart>) {
      yield put(onSuccessAction(data))

      yield put(
        success({
          title: 'Инцидент успешно обновлен.'
        })
      )
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось обновить инцидент.'
        })
      )
    }
  })
}

function* deleteIncident(action: DeleteIncident) {
  const token = yield select(tokenSelector)
  const {
    requestKey,
    resourceType,
    requestProperties: { id }
  } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'DELETE',
    resourceType,
    requestKey,
    ids: [id],
    requestProperties: {
      id
    }
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        endpoint: ENDPOINT.INCIDENTS,
        method: METHOD.DELETE,
        path: id.toString(),
        token
      })
    },
    *success(data) {
      yield put(onSuccessAction([id]))
      yield put(replace(`/incidents`))

      yield put(
        success({
          title: 'Инцидент успешно удален.'
        })
      )
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось удалить инцидент.'
        })
      )
    }
  })
}

function* copyIncident(action: CopyIncident) {
  const token = yield select(tokenSelector)

  const { requestKey, resourceType, requestProperties } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'CREATE',
    resourceType,
    requestKey,
    requestProperties
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      const { data }: { data: Data.Incident } = yield call(fetchAPI, {
        endpoint: ENDPOINT.INCIDENTS,
        method: METHOD.POST,
        token,
        body: {
          incident: requestProperties.incident
        }
      })

      return { data: [data] }
    },
    *success(data: Array<Data.Incident>) {
      yield put(onSuccessAction(data))
      yield put(replace(`/incidents/${data[0].id}`))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось скопировать инцидент.'
        })
      )
    }
  })
}

export function* watcher() {
  yield all([
    takeEvery(TYPES.READ_INCIDENTS, readIncidents),
    takeEvery(TYPES.CREATE_INCIDENT, createIncident),
    takeEvery(TYPES.UPDATE_INCIDENT, updateIncident),
    takeEvery(TYPES.COPY_INCIDENT, copyIncident),
    takeEvery(TYPES.DELETE_INCIDENT, deleteIncident)
  ])
}

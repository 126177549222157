import React, { StatelessComponent, TextareaHTMLAttributes } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import classNames from 'classnames'
import { WrappedFieldProps } from 'redux-form'

import styles from './styles'
interface OuterProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string
  contentClassName?: string
  inputClassName?: string
  placeholder?: string
  label?: string
  // Fix strange ts error
  component?: any
}
export interface Props extends OuterProps, WrappedFieldProps, JSSProps<typeof styles> {}

const TextArea: StatelessComponent<Props> = ({
  classes,
  theme,
  inputClassName,
  className,
  contentClassName,
  placeholder,
  label,
  input,
  meta: { touched, error },
  ...props
}) => (
  <div className={classNames(classes.container, className)}>
    <div className={classNames(classes.content, contentClassName)}>
      {!!label && (
        <label className={classes.label} htmlFor={input.name}>
          {label}
        </label>
      )}
      <textarea
        id={input.name}
        placeholder={placeholder}
        className={classNames(classes.input, inputClassName)}
        {...input}
      />
    </div>
    {touched && error && <span className={classes.error}>{error}</span>}
  </div>
)

export default injectStyles<OuterProps>(styles)(TextArea)

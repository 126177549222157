import { colors } from './colors'
import { sizes } from './sizes'
import { utils } from './utils'
import { common } from './common'
import { media } from './media'
import { muiTheme } from './muiTheme'

const theme = {
  colors,
  sizes,
  utils,
  common,
  media,
  ...muiTheme
}

export { theme, muiTheme }

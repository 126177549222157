import { createSelector } from 'reselect'
import { getResources } from 'redux-resource'

import { NOTIFICATIONS } from 'constants/resources'
import getResourceState from 'lib/utils/getResourceState'

export const getState = (state: App.State) => state.notifications

export const getNotificationsStatus = createSelector(
  [(state: App.State) => state],
  state =>
    getResourceState(
      state,
      `notifications.requests.${NOTIFICATIONS.REQUESTS.GET_NOTIFICATIONS}.status`
    )
)

export const getUnreadNotifications = createSelector(
  getState,
  state => {
    const notifications = getResources(state)

    return notifications.filter(item => item.status !== 'read')
  }
)

export const getAllNotifications = createSelector(
  getState,
  state => getResources(state)
)

enum ActionTypes {
  READ_INFO_SYSTEMS = 'READ_INFO_SYSTEMS',
  READ_INFO_SYSTEMS_REQUEST = 'READ_INFO_SYSTEMS_REQUEST',
  READ_INFO_SYSTEMS_SUCCESS = 'READ_INFO_SYSTEMS_SUCCESS',
  READ_INFO_SYSTEMS_FAILURE = 'READ_INFO_SYSTEMS_FAILURE',
  UPDATE_INFO_SYSTEMS = 'UPDATE_INFO_SYSTEMS',
  UPDATE_INFO_SYSTEMS_REQUEST = 'UPDATE_INFO_SYSTEMS_REQUEST',
  UPDATE_INFO_SYSTEMS_SUCCESS = 'UPDATE_INFO_SYSTEMS_SUCCESS',
  UPDATE_INFO_SYSTEMS_FAILURE = 'UPDATE_INFO_SYSTEMS_FAILURE',
  TOGGLE_MODAL = 'TOGGLE_MODAL',
  TOGGLE_PANEL = 'TOGGLE_PANEL',
  SET_SYSTEM = 'SET_SYSTEM'
}

export default ActionTypes

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body * {
  box-sizing: border-box;
}

.custom-cluster {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -15px;
  margin-top: -15px;
  border-radius: 20px;
  width: 30px;
  height: 30px;
  z-index: 258;
  opacity: 0.7;

  background: #1976d2;
  border: 1px solid white;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,iBAAiB;EACjB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,YAAY;;EAEZ,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;AACd","sourcesContent":["body * {\n  box-sizing: border-box;\n}\n\n.custom-cluster {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-left: -15px;\n  margin-top: -15px;\n  border-radius: 20px;\n  width: 30px;\n  height: 30px;\n  z-index: 258;\n  opacity: 0.7;\n\n  background: #1976d2;\n  border: 1px solid white;\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { Action, ActionCreator } from 'redux'

import types from './actionTypes'

export interface DesktopRequest extends Action<types.DESKTOP_REQUEST> {
  payload: number[] | null
}
export interface DesktopSuccess extends Action<types.DESKTOP_SUCCESS> {
  payload: Data.DesktopPanel
}
export interface DesktopFailure extends Action<types.DESKTOP_FAILURE> {
  error: {
    _error: string
  }
}

export interface DesktopUpdateRequest extends Action<types.DESKTOP_UPDATE_REQUEST> {
  payload: {
    mainId: number
    panelIds: number[]
  }
}
export interface DesktopUpdateSuccess extends Action<types.DESKTOP_UPDATE_SUCCESS> {
  payload: Data.DesktopPanel
}
export interface DesktopUpdateFailure extends Action<types.DESKTOP_UPDATE_FAILURE> {
  error: {
    _error: string
  }
}

export type Actions =
  | DesktopRequest
  | DesktopSuccess
  | DesktopFailure
  | DesktopUpdateRequest
  | DesktopUpdateSuccess
  | DesktopUpdateFailure

export const desktopRequest: ActionCreator<DesktopRequest> = payload => ({
  type: types.DESKTOP_REQUEST,
  payload
})
export const desktopSuccess: ActionCreator<DesktopSuccess> = panel => ({
  type: types.DESKTOP_SUCCESS,
  payload: panel
})
export const desktopFailure: ActionCreator<DesktopFailure> = error => ({
  type: types.DESKTOP_FAILURE,
  error
})

export const desktopUpdateRequest: ActionCreator<DesktopUpdateRequest> = payload => ({
  type: types.DESKTOP_UPDATE_REQUEST,
  payload
})
export const desktopUpdateSuccess: ActionCreator<DesktopUpdateSuccess> = panel => ({
  type: types.DESKTOP_UPDATE_SUCCESS,
  payload: panel
})
export const desktopUpdateFailure: ActionCreator<DesktopUpdateFailure> = error => ({
  type: types.DESKTOP_UPDATE_FAILURE,
  error
})

import { takeEvery, put, all, select, call } from 'redux-saga/effects'
import { actionTypes } from 'redux-resource'
import { error } from 'react-notification-system-redux'

import fetchAPI from 'lib/services/fetchAPI'
import APIAction from 'lib/utils/APIAction'
import getResourceActions from 'lib/utils/getResourceActions'
import objectToFormData from 'lib/utils/objectToFormData'
import { tokenSelector } from 'lib/modules/user/selectors'
import { GetBanches, UpdateBranch } from './actions'
import types from './actionTypes'
import { METHOD, ENDPOINT } from 'constants/api'

function* getBanches(action: GetBanches) {
  const { requestKey, resourceType, list } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'READ',
    resourceType,
    requestKey,
    list
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        endpoint: ENDPOINT.BRANCHES
      })
    },
    *success(data) {
      yield put(onSuccessAction(data))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось получить данные.'
        })
      )
    }
  })
}

function* updateBranch(action: UpdateBranch) {
  const token = yield select(tokenSelector)
  const { resourceType, requestKey, requestProperties, icon } = action.payload

  try {
    yield put({
      type: actionTypes.UPDATE_RESOURCES_PENDING,
      resourceType,
      requestKey,
      resources: [requestProperties.id]
    })

    const body = objectToFormData({ icon }, 'branch')

    const { data }: { data: Data.Branch } = yield call(fetchAPI, {
      method: METHOD.PUT,
      endpoint: ENDPOINT.BRANCHES,
      path: requestProperties.id.toString(),
      body,
      token
    })

    yield put({
      type: actionTypes.UPDATE_RESOURCES_SUCCEEDED,
      resourceType,
      requestKey,
      resources: [data]
    })
  } catch (err) {
    yield put({
      type: actionTypes.UPDATE_RESOURCES_FAILED,
      resourceType,
      requestKey,
      resources: [requestProperties.id],
      requestProperties: {
        error: err
      }
    })
  }
}

export function* watcher() {
  yield all([
    takeEvery(types.GET_BRANCHES_LIST, getBanches),
    takeEvery(types.UPDATE_BRANCH, updateBranch)
  ])
}

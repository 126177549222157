import { OuterProps as Props } from './index'

const styles = (theme: App.Theme) => ({
  container: {
    ...theme.utils.flexContainer({ direction: 'column' }),
    width: '22.5rem',
    minHeight: '10rem',
    maxHeight: '30rem',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.1)',
    pointerEvents: 'auto',
    borderRadius: '14px',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '1.25rem 1.87rem'
  },
  header: {
    flex: '0 0',
    ...theme.utils.flexContainer({ direction: 'row', alignItems: 'center' }),
    width: '100%',
    height: '2.5rem',
    boxSizing: 'border-box'
  },
  title: {
    fontFamily: 'Open Sans',
    fontSize: '1.25rem',
    fontWeight: '600',
    color: '#404F5E'
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: (props: Props) => (props.notifications.length === 0 ? 'center' : 'flex-start'),
    alignItems: (props: Props) => (props.notifications.length === 0 ? 'center' : 'flex-start'),
    width: '100%',
    padding: 0,
    margin: 0,
    listStyle: 'none',
    overflow: 'auto'
  },
  emptyPlaceholder: {
    fontFamily: 'Open Sans',
    fontSize: '1rem',
    fontWeight: '600',
    color: theme.colors.grey,
    textAlign: 'center'
  }
})

export default styles

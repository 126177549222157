import { getResources, State as ResourceState } from 'redux-resource'
import { createSelector } from 'reselect'

import { State } from './reducer'

export const getState = (state: App.State): State => state.resourceManager

export const getResourceState = (resourceName: string) => (state: App.State): ResourceState<any> =>
  state[resourceName]

export const getResourceManagerState = (resourceName: string) =>
  createSelector(
    getState,
    state => state[resourceName]
  )

export const getSortingState = (resourceName: string) =>
  createSelector(
    getResourceManagerState(resourceName),
    resourceManagerState => resourceManagerState && resourceManagerState.sorting
  )

export const getTabState = (resourceName: string) =>
  createSelector(
    getResourceManagerState(resourceName),
    resourceManagerState => resourceManagerState && resourceManagerState.tab
  )

export const getLoadingButtonState = (resourceName: string) =>
  createSelector(
    getResourceManagerState(resourceName),
    resourceManagerState => resourceManagerState && resourceManagerState.loadingButton
  )

export const getIsEnded = (resourceName: string) =>
  createSelector(
    getResourceManagerState(resourceName),
    resourceManagerState => resourceManagerState && resourceManagerState.isEnded
  )

export const getSearch = (resourceName: string) =>
  createSelector(
    getResourceManagerState(resourceName),
    resourceManagerState => resourceManagerState && resourceManagerState.search
  )

export const getListName = (resourceName: string) =>
  createSelector(
    getResourceManagerState(resourceName),
    resourceManagerState => resourceManagerState && resourceManagerState.list
  )

export const getItems = (resourceName: string) =>
  createSelector(
    [getListName(resourceName), getResourceState(resourceName)],
    (listName, resourceSlice) => getResources(resourceSlice, listName)
  )

export const getRequestState = (resourceName: string) =>
  createSelector(
    getResourceManagerState(resourceName),
    resourceManagerState => resourceManagerState && resourceManagerState.state
  )

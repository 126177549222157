import { Props } from './index'

const styles = (theme: App.Theme) => ({
  container: {
    position: 'relative',
    fontSize: '1rem'
  },
  content: {
    position: 'relative',
    width: '100%',
    padding: '0.875em 0.625em',
    fontSize: '1rem',
    boxSizing: 'border-box',
    border: `1px solid ${theme.colors.input.border}`,
    borderRadius: '0.5rem'
  },
  label: {
    position: 'relative',
    display: 'inline-block',
    color: theme.colors.text.signature,
    fontSize: '0.75rem',
    lineHeight: '1.3',
    letterSpacing: 0.5,
    cursor: 'text',
    transition: 'transform 0.15s ease-out'
  },
  input: {
    width: '100%',
    padding: 0,
    fontSize: '1em',
    lineHeight: 1.2,
    color: '#000',
    border: 'none',
    backgroundColor: 'transparent',
    outline: 'none',
    '&::placeholder': {
      color: theme.colors.grey
    }
  },
  error: {
    padding: `0.875em 0.625em`,
    color: theme.colors.red,
    fontSize: '0.75rem',
    marginTop: '1rem'
  }
})

export default styles

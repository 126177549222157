import { takeEvery, put, select, all, call } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import { success, error } from 'react-notification-system-redux'

import fetchAPI from 'lib/services/fetchAPI'
import APIAction from 'lib/utils/APIAction'
import getResourceActions from 'lib/utils/getResourceActions'
import { tokenSelector } from 'lib/modules/user/selectors'
import { ReadWidgets, CreateWidget, UpdateWidget, DeleteWidget, CopyWidget } from './actions'
import { METHOD, ENDPOINT } from 'constants/api'
import TYPES from './actionTypes'
import objectToFormData from 'src/lib/utils/objectToFormData'

function* readWidgets(action: ReadWidgets) {
  const { requestKey, resourceType, requestProperties, list } = action.payload
  const token = yield select(tokenSelector)
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'READ',
    resourceType,
    requestKey,
    requestProperties,
    list
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      const { data }: { data: Data.Widget[] } = yield call(fetchAPI, {
        endpoint: ENDPOINT.WIDGETS,
        method: METHOD.GET,
        token,
        params: requestProperties
      })

      return { data }
    },
    *success(data) {
      yield put(onSuccessAction(data))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось получить данные.'
        })
      )
    }
  })
}

function* createWidget(action: CreateWidget) {
  const token = yield select(tokenSelector)

  const { requestKey, resourceType, requestProperties } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'CREATE',
    resourceType,
    requestKey,
    requestProperties
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())
      const { widget } = requestProperties

      const { data }: { data: Data.Widget } = yield call(fetchAPI, {
        endpoint: ENDPOINT.WIDGETS,
        method: METHOD.POST,
        token,
        body: widget
      })

      return { data: [data] }
    },
    *success(data: Array<Data.Widget>) {
      yield put(onSuccessAction(data))
      yield put(replace(`/widgets/${data[0].id}`))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось создать виджет.'
        })
      )
    }
  })
}

function* updateWidget(action: UpdateWidget) {
  const token = yield select(tokenSelector)

  const { requestKey, resourceType, requestProperties } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'UPDATE',
    resourceType,
    requestKey,
    requestProperties
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())
      const { widget } = requestProperties

      const { data }: { data: Data.Widget } = yield call(fetchAPI, {
        endpoint: ENDPOINT.WIDGETS,
        method: METHOD.PUT,
        path: requestProperties.widget.id,
        token,
        body: widget
      })

      return { data: [data] }
    },
    *success(data: Array<Data.Chart>) {
      yield put(onSuccessAction(data))

      yield put(
        success({
          title: 'Виджет успешно обновлен.'
        })
      )
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось обновить виджет.'
        })
      )
    }
  })
}

function* deleteWidget(action: DeleteWidget) {
  const token = yield select(tokenSelector)
  const {
    requestKey,
    resourceType,
    requestProperties: { id }
  } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'DELETE',
    resourceType,
    requestKey,
    ids: [id],
    requestProperties: {
      id
    }
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        endpoint: ENDPOINT.WIDGETS,
        method: METHOD.DELETE,
        path: id.toString(),
        token
      })
    },
    *success(data) {
      yield put(onSuccessAction([id]))
      yield put(replace(`/widgets`))

      yield put(
        success({
          title: 'Виджет успешно удален.'
        })
      )
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось удалить виджет.'
        })
      )
    }
  })
}

function* copyWidget(action: CopyWidget) {
  const token = yield select(tokenSelector)

  const { requestKey, resourceType, requestProperties } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'CREATE',
    resourceType,
    requestKey,
    requestProperties
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      const { data }: { data: Data.Widget } = yield call(fetchAPI, {
        endpoint: ENDPOINT.WIDGETS,
        method: METHOD.POST,
        token,
        body: {
          widget: requestProperties.widget
        }
      })

      return { data: [data] }
    },
    *success(data: Array<Data.Widget>) {
      yield put(onSuccessAction(data))
      yield put(replace(`/widgets/${data[0].id}`))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось скопировать виджет.'
        })
      )
    }
  })
}

export function* watcher() {
  yield all([
    takeEvery(TYPES.READ_WIDGETS, readWidgets),
    takeEvery(TYPES.CREATE_WIDGET, createWidget),
    takeEvery(TYPES.UPDATE_WIDGET, updateWidget),
    takeEvery(TYPES.COPY_WIDGET, copyWidget),
    takeEvery(TYPES.DELETE_WIDGET, deleteWidget)
  ])
}

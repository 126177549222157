import { Action, ActionCreator } from 'redux'
import { actionTypes } from 'redux-resource'

import { PANELS } from 'constants/resources'
import TYPES from './actionTypes'

export interface AddFavorite extends Action<typeof TYPES.ADD_FAVORITE> {
  payload: {
    requestProperties: { id: Data.Panel['id'] }
    resourceType: string
    requestKey: string
  }
}
export interface RemoveFavorite extends Action<typeof TYPES.REMOVE_FAVORITE> {
  payload: {
    requestProperties: { id: Data.Panel['id'] }
    resourceType: string
    requestKey: string
  }
}
export interface CreatePanel extends Action<typeof TYPES.CREATE_PANEL> {
  payload: {
    resourceType: string
    requestKey: string
  }
}
export interface ReadPanels extends Action<typeof TYPES.READ_PANELS> {
  payload: {
    resourceType: string
    requestKey: string
    list?: string
    options: { ids?: Array<string>; on_main?: boolean }
  }
}
export interface UpdatePanel extends Action<typeof TYPES.UPDATE_PANEL> {
  payload: {
    resourceType: string
    requestKey: string
  }
}
export interface DeletePanel extends Action<typeof TYPES.DELETE_PANEL> {
  payload: {
    resourceType: string
    requestKey: string
    requestProperties: {
      id: Data.Panel['id']
    }
  }
}
export interface ClearPanelsList extends Action<typeof actionTypes.UPDATE_RESOURCES> {
  lists: {
    [key: string]: {
      [key: string]: string[]
    }
  }
}
export interface CopyPanel extends Action<TYPES.COPY_PANEL> {
  payload: {
    resourceType: string
    requestKey: string
    requestProperties: {
      id: number
    }
  }
}
export interface ReadPanelByKey extends Action<typeof TYPES.GET_PANEL_BY_LINK_KEY> {
  payload: {
    resourceType: string
    requestKey: string
    id: Data.Panel['id']
    key: Data.Panel['link_key']
  }
}

export type ActionTypes =
  | ReadPanels
  | CreatePanel
  | UpdatePanel
  | DeletePanel
  | ReadPanelByKey
  | CopyPanel
  | AddFavorite
  | RemoveFavorite

export const createPanel: ActionCreator<CreatePanel> = () => ({
  type: TYPES.CREATE_PANEL,
  payload: {
    resourceType: PANELS.NAME,
    requestKey: PANELS.REQUESTS.CREATE_PANEL
  }
})
export const readPanels: ActionCreator<ReadPanels> = ({
  list,
  ...options
}: { ids?: Array<string>; on_main?: boolean; list?: string } = {}) => ({
  type: TYPES.READ_PANELS,
  payload: {
    resourceType: PANELS.NAME,
    requestKey: PANELS.REQUESTS.GET_PANELS,
    list,
    options
  }
})
export const updatePanel: ActionCreator<UpdatePanel> = () => ({
  type: TYPES.UPDATE_PANEL,
  payload: {
    resourceType: PANELS.NAME,
    requestKey: PANELS.REQUESTS.UPDATE_PANEL
  }
})
export const deletePanel: ActionCreator<DeletePanel> = id => ({
  type: TYPES.DELETE_PANEL,
  payload: {
    resourceType: PANELS.NAME,
    requestKey: PANELS.REQUESTS.DELETE_PANEL,
    requestProperties: {
      id
    }
  }
})
export const clearList: ActionCreator<ClearPanelsList> = (list: string) => ({
  type: actionTypes.UPDATE_RESOURCES,
  lists: {
    [PANELS.NAME]: {
      [list]: []
    }
  }
})
export const copyPanel: ActionCreator<CopyPanel> = (id: number) => ({
  type: TYPES.COPY_PANEL,
  payload: {
    resourceType: PANELS.NAME,
    requestKey: PANELS.REQUESTS.CREATE_PANEL,
    requestProperties: {
      id
    }
  }
})
export const readPanelByKey: ActionCreator<ReadPanelByKey> = (id, key) => ({
  type: TYPES.GET_PANEL_BY_LINK_KEY,
  payload: {
    resourceType: PANELS.NAME,
    requestKey: PANELS.REQUESTS.GET_PANEL_BY_LINK_KEY,
    id,
    key
  }
})

export const addFavorite: (id: Data.Panel['id']) => AddFavorite = id => ({
  type: TYPES.ADD_FAVORITE,
  payload: {
    resourceType: PANELS.NAME,
    requestKey: PANELS.REQUESTS.ADD_FAVORITE,
    requestProperties: { id }
  }
})

export const removeFavorite: (id: Data.Panel['id']) => RemoveFavorite = id => ({
  type: TYPES.REMOVE_FAVORITE,
  payload: {
    resourceType: PANELS.NAME,
    requestKey: PANELS.REQUESTS.REMOVE_FAVORITE,
    requestProperties: { id }
  }
})

const styles = (theme: App.Theme) => ({
  selectTooltipButton: {
    flex: '1 1 44%',
    maxWidth: '50%'
  },
  fieldDateRange: {
    flex: '1 1 44%',
    maxWidth: '50%'
  }
})

export default styles

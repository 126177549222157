
const styles = (theme: App.Theme) => ({
  arrow: {
    height: 22,
    width: 10,
    transform: ({ isOpen }) => `rotate(${isOpen ? 180 : 0}deg)`,
    transition: 'transform 0.3s ease-out !important'
  }
})

export default styles

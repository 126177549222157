import { Reducer } from 'redux'

import { TYPES } from 'constants/dataView'
import { CELL_TYPE } from 'constants/panels'
import { STATES } from 'constants/states'
import { ActionTypes } from './actions'
import types from './types'

export interface State {
  panel: Partial<Data.Panel>
  elementsMeta: {
    status: STATES
    error?: string
  }
  widgetsMeta: {
    status: STATES
    error?: string
  }
  elementModal: {
    templateID?: Data.PanelTemplate['id']
    elementID?: Data.PanelElement['id']
    elementData?: Data.PanelElement['element']
    elementType?: Data.PanelElement['element_type']
    fileExt?: string
    isOpened: boolean
  }
  widgetModal: {
    templateID?: Data.Widget['id']
    elementID?: Data.Widget['id']
    isOpened: boolean
  }
  widgetsList: Data.Widget[]
  panelModal: {
    isOpened: boolean
  }
}

const initialState: State = {
  panel: {
    templates: []
  },
  elementsMeta: {
    status: STATES.IDLE
  },
  widgetsMeta: {
    status: STATES.IDLE
  },
  elementModal: {
    isOpened: false
  },
  widgetModal: {
    isOpened: false
  },
  widgetsList: [],
  panelModal: {
    isOpened: false
  }
}

export const reducer: Reducer<State, ActionTypes> = (state = initialState, action) => {
  let templates: Array<Data.PanelTemplate>
  let currentTemplateIndex
  let nextTemplateIndex
  const elements = []

  switch (action.type) {
    case types.INITIALIZE_PANEL:
      return {
        ...state,
        panel: action.payload
      }
    case types.DESTROY_PANEL:
      return { ...initialState }
    case types.ADD_TEMPLATE:
      return {
        ...state,
        panel: {
          ...state.panel,
          templates: [...state.panel.templates, action.payload]
        }
      }
    case types.DELETE_TEMPLATE:
      return {
        ...state,
        panel: {
          ...state.panel,
          templates: state.panel.templates.filter(template => template.id !== action.payload)
        }
      }
    case types.MOVE_TEMPLATE:
      currentTemplateIndex = state.panel.templates.findIndex(
        template => template.id === action.payload.templateID
      )
      nextTemplateIndex = currentTemplateIndex + (action.payload.direction === 'top' ? -1 : +1)
      templates = [...state.panel.templates]
      templates.splice(nextTemplateIndex, 0, templates.splice(currentTemplateIndex, 1)[0])

      return {
        ...state,
        panel: {
          ...state.panel,
          templates
        }
      }
    case types.DELETE_ELEMENT:
      return {
        ...state,
        panel: {
          ...state.panel,
          templates: state.panel.templates.map(template =>
            template.id === action.payload.templateID
              ? {
                  ...template,
                  cells: template.cells.map(cell => {
                    if (cell.elements.find(element => element.id === action.payload.elementID)) {
                      if (cell.elements.length > 1) {
                        return {
                          ...cell,
                          elements: cell.elements.filter(
                            element => element.id !== action.payload.elementID
                          )
                        }
                      } else {
                        return {
                          ...cell,
                          type: CELL_TYPE.STATIC,
                          is_ticker: false,
                          elements: [
                            {
                              id: cell.elements[0].id,
                              element_type: TYPES.EMPTY
                            }
                          ]
                        }
                      }
                    }

                    return cell
                  })
                }
              : template
          )
        }
      }
    case types.REPLACE_ELEMENT:
      return {
        ...state,
        panel: {
          ...state.panel,
          templates: state.panel.templates.map(template =>
            template.id === action.payload.templateID
              ? {
                  ...template,
                  cells: template.cells.map(cell => ({
                    ...cell,
                    elements: cell.elements.map(element =>
                      element.id === action.payload.prevElement.id
                        ? action.payload.nextElement
                        : element
                    )
                  }))
                }
              : template
          )
        }
      }
    case types.DELETE_CELL:
      return {
        ...state,
        panel: {
          ...state.panel,
          templates: state.panel.templates.map(template =>
            template.id === action.payload.templateID
              ? {
                  ...template,
                  cells: template.cells.map(cell =>
                    cell.id === action.payload.cellID
                      ? {
                          ...cell,
                          type: CELL_TYPE.STATIC,
                          is_ticker: false,
                          elements: [
                            {
                              id: cell.elements[0].id,
                              element_type: TYPES.EMPTY
                            }
                          ]
                        }
                      : cell
                  )
                }
              : template
          )
        }
      }
    case types.REPLACE_CELL:
      return {
        ...state,
        panel: {
          ...state.panel,
          templates: state.panel.templates.map(template =>
            template.id === action.payload.templateID
              ? {
                  ...template,
                  cells: template.cells.map(cell =>
                    cell.id === action.payload.cell.id ? action.payload.cell : cell
                  )
                }
              : template
          )
        }
      }
    case types.OPEN_ELEMENT_MODAL:
      return {
        ...state,
        elementModal: {
          ...action.payload,
          isOpened: true
        }
      }
    case types.CLOSE_ELEMENT_MODAL:
      return {
        ...state,
        elementModal: {
          isOpened: false
        }
      }
    case types.OPEN_PANEL_MODAL:
      return {
        ...state,
        panelModal: {
          isOpened: true
        }
      }
    case types.CLOSE_PANEL_MODAL:
      return {
        ...state,
        panelModal: {
          isOpened: false
        }
      }
    case types.GET_ALL_PANELS_ELEMENTS_REQUEST:
      return {
        ...state,
        elementsMeta: {
          status: STATES.LOADING
        }
      }
    case types.GET_ALL_PANELS_ELEMENTS_SUCCESS:
      return {
        ...state,
        elementsMeta: {
          status: STATES.SUCCEEDED
        }
      }
    case types.GET_ALL_PANELS_ELEMENTS_FAILURE:
      return {
        ...state,
        elementsMeta: {
          status: STATES.FAILED,
          error: action.payload.error
        }
      }
    case types.GET_WIDGETS_REQUEST:
      return {
        ...state,
        widgetsMeta: {
          status: STATES.LOADING
        }
      }
    case types.GET_WIDGETS_SUCESS:
      return {
        ...state,
        widgetsMeta: {
          status: STATES.SUCCEEDED
        },
        widgetsList: action.payload
      }
    case types.GET_WIDGETS_FAILURE:
      return {
        ...state,
        widgetsMeta: {
          status: STATES.FAILED,
          error: action.payload.error
        }
      }
    default:
      return state
  }
}

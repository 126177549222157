import React, { PureComponent, CSSProperties } from 'react'
import cn from 'classnames'
import { Spring, config } from 'react-spring'

export interface Props {
  theme?: {
    container?: string
    content?: string
  }
  style?: CSSProperties
  onRest?: () => void
  fixedHeight?: number
  isOpen: boolean
}

enum CollapseState {
  RESIZING = 'RESIZING',
  IDLING = 'IDLING'
}

interface State {
  currentState: CollapseState
  from: number
  to: number
}

class Collapse extends PureComponent<Props, State> {
  wrapperRef: HTMLDivElement
  contentRef: HTMLDivElement

  static defaultProps: Partial<Props> = {
    fixedHeight: -1,
    onRest: () => null,
    theme: {}
  }

  constructor(props: Props) {
    super(props)

    this.state = { currentState: CollapseState.IDLING, from: 0, to: 0 }
  }

  componentDidMount() {
    const { isOpen } = this.props

    if (isOpen) {
      const to = this.getTo()
      this.setState({ currentState: CollapseState.IDLING, from: 0, to })
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { isOpen: currentOpenState } = this.props
    const { isOpen: previousOpenState } = prevProps

    if (currentOpenState !== previousOpenState) {
      const from = this.wrapperRef.clientHeight
      const to = currentOpenState ? this.getTo() : 0

      if (from !== to) {
        this.setState({ currentState: CollapseState.RESIZING, from, to })
      }
    }
  }

  onRest = () => {
    this.setState({ currentState: CollapseState.IDLING })
  }

  bindWrapper = (element?: HTMLDivElement) => {
    if (!this.wrapperRef) {
      this.wrapperRef = element
    }
  }

  bindContent = (element?: HTMLDivElement) => {
    if (!this.contentRef) {
      this.contentRef = element
    }
  }

  getTo = () => {
    const { fixedHeight } = this.props
    return fixedHeight > -1 ? fixedHeight : this.contentRef.clientHeight
  }

  getWrapperStyle = (height: number) => {
    const { currentState, to, from } = this.state
    const { fixedHeight } = this.props

    if (currentState === CollapseState.IDLING && to) {
      if (fixedHeight > -1) {
        return { overflow: 'hidden', height: fixedHeight }
      }

      return { height: 'auto' }
    }

    return { overflow: 'hidden', height: Math.max(0, height) }
  }

  renderContent = (interpolated: { height: number }) => {
    const { theme, children, style, isOpen, onRest, fixedHeight, ...props } = this.props

    return (
      <div
        style={{ ...this.getWrapperStyle(interpolated.height), ...style }}
        className={cn(theme.container)}
        ref={this.bindWrapper}
        {...props}
      >
        <div ref={this.bindContent} className={cn(theme.content)}>
          {children}
        </div>
      </div>
    )
  }

  render() {
    const { isOpen, ...props } = this.props
    const { from, to } = this.state

    return (
      <Spring
        from={{ height: from }}
        to={{ height: to }}
        onRest={this.onRest}
        config={config.wobbly}
      >
        {this.renderContent}
      </Spring>
    )
  }
}

export default Collapse

import { Action } from 'redux'
import types from './types'

export interface ReadImportFiles extends Action<types.READ_IMPORT_FILES> {}
export interface ReadImportFilesLoading extends Action<types.READ_IMPORT_FILES_LOADING> {}
export interface ReadImportFilesFailure extends Action<types.READ_IMPORT_FILES_FAILURE> {}
export interface ReadImportFilesSuccess extends Action<types.READ_IMPORT_FILES_SUCCESS> {
  payload: Data.CovidImportFile[]
}
export interface UpdateImportFile extends Action<types.UPDATE_IMPORT_FILE> {
  payload: Data.CovidImportFile
}
export interface ReplaceImportFileData extends Action<types.REPLACE_IMPORT_FILE_DATA> {
  payload: Data.CovidImportFile
}
export interface ToggleShow extends Action<types.TOGGLE_SHOW> {}

export type ActionTypes =
  | ReadImportFiles
  | ReadImportFilesLoading
  | ReadImportFilesFailure
  | ReadImportFilesSuccess
  | UpdateImportFile
  | ReplaceImportFileData
  | ToggleShow

export const readImportFiles: () => ReadImportFiles = () => ({
  type: types.READ_IMPORT_FILES
})

export const readImportFilesLoading: () => ReadImportFilesLoading = () => ({
  type: types.READ_IMPORT_FILES_LOADING
})

export const readImportFilesFailure: () => ReadImportFilesFailure = () => ({
  type: types.READ_IMPORT_FILES_FAILURE
})

export const readImportFilesSuccess: (
  data: Data.CovidImportFile[]
) => ReadImportFilesSuccess = data => ({
  type: types.READ_IMPORT_FILES_SUCCESS,
  payload: data
})

export const updateImportFile: (data: Data.CovidImportFile) => UpdateImportFile = data => ({
  type: types.UPDATE_IMPORT_FILE,
  payload: data
})

export const replaceImportFileData: (
  data: Data.CovidImportFile
) => ReplaceImportFileData = data => ({
  type: types.REPLACE_IMPORT_FILE_DATA,
  payload: data
})

export const toggleShow: () => ToggleShow = () => ({
  type: types.TOGGLE_SHOW
})

import React, { Component } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'

import { compose } from 'recompose'
import { Info } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import OptionType from 'react-select'

import { readImportFiles, updateImportFile, toggleShow } from 'lib/modules/importSettings/actions'
import { STATES } from 'constants/states'
import { Base as Select } from 'components/Select'
import Button from 'components/Button'
import Modal from 'components/Modal/components/Base'
import { Cancel } from 'components/Icons'
import LoadingOverlay from 'components/LoadingOverlay'
import styles from './styles'
import {
  getImportFilesItems,
  getImportFilesLoadingStatus
} from 'src/lib/modules/importSettings/selectors'
import { importFilePeriodOptions } from 'lib/constants/options'

interface OuterProps {}
interface StateProps {
  importFiles: Data.CovidImportFile[]
  readStatus: STATES
  isOpen: boolean
}
interface DispatchProps {
  readImportFiles: () => void
  updateImportFile: (data: Data.CovidImportFile) => void
  toggleShow: () => void
}
interface Props extends JSSProps<typeof styles>, StateProps, DispatchProps, OuterProps {}

interface State {}

class ImportSettings extends Component<Props, State> {
  componentDidMount() {
    this.props.readImportFiles()
  }

  updatePeriod = (file: Data.CovidImportFile, option: OptionType) => {
    const importFile = { ...file }
    importFile.period = +option.value

    this.props.updateImportFile(importFile)
  }

  render() {
    const { classes, isOpen, importFiles, readStatus, toggleShow } = this.props

    return (
      <Modal isOpen={isOpen} onRequestClose={toggleShow}>
        <div className={classes.modal}>
          <LoadingOverlay show={readStatus === STATES.LOADING} />
          <div className={classes.modalHeader}>
            <h3 className={classes.modalTitle}>Настройки импорта</h3>
          </div>
          <div className={classes.modalContent}>
            {importFiles.map(importFile => {
              const lastFileNameParts = importFile.last_file_name
                ? importFile.last_file_name.split('/')
                : ''
              const lastFileName = lastFileNameParts[lastFileNameParts.length - 1] || ''
              return (
                <div key={importFile.id} className={classes.modalFieldRow}>
                  <span className={classes.fileName}>{importFile.name}</span>
                  <Select
                    className={classes.select}
                    value={
                      importFilePeriodOptions.find(el => +el.value === importFile.period) ||
                      importFilePeriodOptions[0]
                    }
                    options={importFilePeriodOptions}
                    placeholder="Не выбрано"
                    onChange={value => this.updatePeriod(importFile, value)}
                  />
                  <Tooltip
                    title={lastFileName}
                    placement="top"
                    classes={{ popper: classes.tooltip }}
                  >
                    <Info fontSize="small" />
                  </Tooltip>
                </div>
              )
            })}
          </div>
          <div className={classes.modalFooter}>
            <Button icon={<Cancel />} onClick={toggleShow} className={classes.modalFooterButton}>
              Закрыть
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps: MapStateToProps<StateProps, Props, App.State> = (state, props) => ({
  importFiles: getImportFilesItems(state),
  readStatus: getImportFilesLoadingStatus(state),
  isOpen: state.importFiles.showModal
})
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OuterProps> = dispatch => ({
  updateImportFile: (data: Data.CovidImportFile) => dispatch(updateImportFile(data)),
  readImportFiles: () => dispatch(readImportFiles()),
  toggleShow: () => dispatch(toggleShow())
})

export default compose<Props, OuterProps>(
  
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectStyles(styles)
)(ImportSettings)

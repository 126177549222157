import React, { FC, useRef } from 'react'

import { compose } from 'recompose'
import injectStyles, { JSSProps } from 'react-jss'
import { Field } from 'redux-form'
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd'
import { XYCoord } from 'dnd-core'
import Dropzone from 'react-dropzone'

import styles from './styles'
import ButtonRounded from 'components/ButtonRounded'
import { Burger, Delete } from 'components/Icons'
import { TextInput } from 'src/components/FormFields'

export interface InfoSystemsFormValues {
  items: Data.InfoSystem[]
}

interface OuterProps {
  index: number
  system: Data.InfoSystem
  member: string
  change: (key: string, value: any) => void
  remove: () => void
  move: (dragIndex: number, hoverIndex: number) => void
}

export interface Props extends JSSProps<typeof styles>, OuterProps {}

interface DragItem {
  index: number
  id: string
  type: string
}

const ItemTypes = {
  ROW: 'row'
}

const DraggableRow: FC<Props> = ({ index, classes, system, member, change, remove, move }) => {
  const ref = useRef<HTMLDivElement>(null)

  const [, drop] = useDrop({
    accept: ItemTypes.ROW,
    drop(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      move(dragIndex, hoverIndex)

      item.index = hoverIndex
    }
  })

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.ROW, id: member, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <div ref={ref} className={classes.container} style={{ opacity }}>
      <div className={classes.grip}>
        <Burger className={classes.gripIcon} />
      </div>
      <Dropzone
        onDrop={acceptedFiles => {
          if (!!acceptedFiles[0]) {
            const reader = new FileReader()
            reader.onloadend = () => {
              system.icon = acceptedFiles[0]
              system.iconPreview = reader.result as string
              change(member, system)
            }
            reader.readAsDataURL(acceptedFiles[0])
          }
        }}
      >
        {({ getRootProps, getInputProps }) => {
          return (
            <div {...getRootProps()} className={classes.iconField}>
              <input {...getInputProps()} accept="image/*" />
              {!!system.icon && (
                <img className={classes.icon} src={system.iconPreview || (system.icon as string)} />
              )}
            </div>
          )
        }}
      </Dropzone>
      <div className={classes.inputs}>
        <Field
          name={`${member}.title`}
          component={TextInput}
          placeholder="Название (до 16 символов)"
          className={classes.inputField}
          contentClassName={classes.inputContent}
          inputClassName={classes.input}
        />
        <Field
          name={`${member}.url`}
          component={TextInput}
          placeholder="URL"
          className={classes.inputField}
          contentClassName={classes.inputContent}
          inputClassName={classes.input}
        />
      </div>
      <div className={classes.systemButtons}>
        <ButtonRounded className={classes.systemButton} icon={Delete} squared onClick={remove} />
      </div>
    </div>
  )
}

export default compose<Props, OuterProps>(
  
  injectStyles(styles)
)(DraggableRow)

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'
import { reducer as notifications } from 'react-notification-system-redux'
import { resourceReducer as resource } from 'redux-resource'
import { reset } from 'redux-resource-plugins'
import history from './history'

import resourceManager from 'lib/modules/resourceManager/reducer'
import { reducer as aggregations } from 'lib/modules/aggregations/reducer'
import { reducer as sorting } from 'lib/modules/sorting/reducer'
import { reducer as sidemenu } from 'lib/modules/sidemenu/reducer'
import { reducer as user } from 'lib/modules/user/reducer'
import { reducer as panelEditor } from 'lib/modules/panelEditor/reducer'
import { reducer as modals } from 'lib/modules/open/modals/reducer'
import { reducer as viewport } from 'lib/modules/viewport/reducer'
import { reducer as incident } from 'src/lib/modules/incident/reducer'
import { reducer as data_import } from 'lib/modules/data/reducer'
import { reducer as desktop } from 'lib/modules/desktop/reducer'
import { reducer as pdfViewer } from 'lib/modules/pdfViewer/reducer'
import { reducer as schedule } from 'lib/modules/schedule/reducer'
import { reducer as backgrounds } from 'lib/modules/widgetBackgrounds/reducer'
import { reducer as info_systems } from 'lib/modules/infoSystems/reducer'
import { reducer as fpd } from 'lib/modules/fpd/reducer'
import { reducer as vehicles } from 'lib/modules/vehicles/reducer'
import { reducer as apkbgs } from 'lib/modules/apkbgs/reducer'
import { reducer as importFiles } from 'lib/modules/importSettings/reducer'
import { reducer as exportSettings } from 'lib/modules/exportSettings/reducer'
import { reducer as msp } from 'lib/modules/msp/reducer'
import {
  DESKTOP_BUTTONS,
  USERS,
  CONSTRUCTION_OBJECTS,
  ORGANISATIONS,
  PUBLICATIONS,
  CATALOG_ITEMS,
  FORMS,
  CHART_CATEGORIES,
  CHARTS,
  INFO_REFERENCES,
  WIDGETS,
  INCIDENTS,
  REPORT_CATEGORIES,
  PANELS,
  NOTIFICATIONS,
  JOURNAL_LOGS,
  BRANCHES,
  MESSAGES,
  MESSAGES_RECEIVERS,
  SCANNER_ENTITIES,
  SCANNER_CATEGORIES,
  ZONES,
  SUB_ZONES,
  INCIDENT_TOPICS
} from 'lib/constants/resources'

const rootReducer = combineReducers<App.State, App.AllActions>({
  router: connectRouter(history),
  form: formReducer,
  notificationSystem: notifications,
  resourceManager,
  aggregations,
  sorting,
  sidemenu,
  user,
  data_import,
  panelEditor,
  modals,
  viewport,
  incident,
  desktop,
  pdfViewer,
  schedule,
  backgrounds,
  info_systems,
  fpd,
  msp,
  vehicles,
  apkbgs,
  importFiles,
  exportSettings,
  desktopButtons: resource(DESKTOP_BUTTONS.NAME, { plugins: [reset] }),
  users: resource(USERS.NAME, { plugins: [reset] }),
  organisations: resource(ORGANISATIONS.NAME, { plugins: [reset] }),
  chartCategories: resource(CHART_CATEGORIES.NAME, { plugins: [reset] }),
  reportCategories: resource(REPORT_CATEGORIES.NAME, { plugins: [reset] }),
  publications: resource(PUBLICATIONS.NAME, { plugins: [reset] }),
  catalog_items: resource(CATALOG_ITEMS.NAME, { plugins: [reset] }),
  [CONSTRUCTION_OBJECTS.NAME]: resource(CONSTRUCTION_OBJECTS.NAME, { plugins: [reset] }),
  charts: resource(CHARTS.NAME, { plugins: [reset] }),
  info_references: resource(INFO_REFERENCES.NAME, { plugins: [reset] }),
  widgets: resource(WIDGETS.NAME, { plugins: [reset] }),
  incidents: resource(INCIDENTS.NAME, { plugins: [reset] }),
  incidentTopics: resource(INCIDENT_TOPICS.NAME, { plugins: [reset] }),
  panels: resource(PANELS.NAME, { plugins: [reset] }),
  forms: resource(FORMS.NAME, { plugins: [reset] }),
  notifications: resource(NOTIFICATIONS.NAME, { plugins: [reset] }),
  journalLogs: resource(JOURNAL_LOGS.NAME, { plugins: [reset] }),
  branches: resource(BRANCHES.NAME, { plugins: [reset] }),
  messages: resource(MESSAGES.NAME, { plugins: [reset] }),
  messagesReceivers: resource(MESSAGES_RECEIVERS.NAME, { plugins: [reset] }),
  scanner: resource(SCANNER_ENTITIES.NAME, { plugins: [reset] }),
  scannerCategories: resource(SCANNER_CATEGORIES.NAME, { plugins: [reset] }),
  zones: resource(ZONES.NAME, { plugins: [reset] }),
  subZones: resource(SUB_ZONES.NAME, { plugins: [reset] })
})

export { rootReducer as default }

import { Action, ActionCreator } from 'redux'

import types from './actionTypes'

export interface OpenPdf extends Action<types.OPEN_PDF> {
  payload: string
}

export interface ClosePdf extends Action<types.CLOSE_PDF> {}

export type Actions = OpenPdf | ClosePdf

export const openPdf: ActionCreator<OpenPdf> = pdfUrl => ({
  type: types.OPEN_PDF,
  payload: pdfUrl
})

export const closePdf: ActionCreator<ClosePdf> = () => ({
  type: types.CLOSE_PDF
})

enum ActionTypes {
  SIDEMENU_ITEM_SET_NOTIFICATION = 'SIDEMENU_ITEM_SET_NOTIFICATION',
  SIDEMENU_ITEM_REMOVE_NOTIFICATION = 'SIDEMENU_ITEM_REMOVE_NOTIFICATION',

  GET_RESOURCES_COUNT = 'GET_RESOURCES_COUNT',
  CHANGE_BREADCRUMBS = 'CHANGE_BREADCRUMBS',
  GET_RESOURCES_COUNT_REQUEST = 'GET_RESOURCES_COUNT_REQUEST',
  GET_RESOURCES_COUNT_SUCCESS = 'GET_RESOURCES_COUNT_SUCCESS',
  GET_RESOURCES_COUNT_FAILURE = 'GET_RESOURCES_COUNT_FAILURE'
}

export default ActionTypes

export const ROLE = {
  USER: 'user', // Контролирующий орган
  ORGANISATION: 'org',
  GRBS: 'grbs',
  SUPERADMIN: 'superadmin', // Совокупность ролей администратора, разработчика и супер-пользователя
  DEVELOPER: 'developer', // То же, что и супер-админ
  SPECIAL: 'special', // Пользователь с настраевымими правами
  HEAD: 'head' // руководитель
}

export const ROLE_NAME = {
  [ROLE.SUPERADMIN]: 'Администратор',
  [ROLE.DEVELOPER]: 'Разработчик',
  [ROLE.SPECIAL]: 'Пользователь с настраиваемыми правами',
  [ROLE.HEAD]: 'Руководитель',
  [ROLE.USER]: 'Контролирующий орган',
  [ROLE.GRBS]: 'ГРБС'
}

export type RoleType =
  | typeof ROLE.SUPERADMIN
  | typeof ROLE.DEVELOPER
  | typeof ROLE.GRBS
  | typeof ROLE.ORGANISATION
  | typeof ROLE.USER
  | typeof ROLE.SPECIAL
  | typeof ROLE.HEAD

export default ROLE

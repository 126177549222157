import React, { PureComponent, ComponentClass, Fragment } from 'react'
import { compose } from 'recompose'
import { Field, FieldArrayFieldsProps, WrappedFieldProps } from 'redux-form'
import injectStyles, { JSSProps } from 'react-jss'

import { correctDateField } from 'lib/utils/validations'
import Value from './components/Value'
import CompareComponent from '../Compare'
import { OPTION_TYPES } from 'constants/filter'
import styles from './styles'

interface HOCOuterProps {
  name: string
  index: number
  fields: FieldArrayFieldsProps<Filter.Expression>
  selectedOption?: Filter.Option
  options: Filter.Option[]
  withDateSelect: boolean
}
interface OuterProps extends JSSProps<typeof styles> {
  index: number
  fields: FieldArrayFieldsProps<Filter.Expression>
  selectedOption?: Filter.Option
  withDateSelect: boolean
  options: Filter.Option[]
  // Fix strange ts error
  component?: any
}
interface HOCProps extends JSSProps<typeof styles>, HOCOuterProps {}
interface Props extends OuterProps, WrappedFieldProps {}

const DateResultHOC = ({ name, ...props }: HOCProps) => (
  <Field {...props} name={name} component={DateResult as ComponentClass<OuterProps>} />
)

class DateResult extends PureComponent<Props> {
  get dateSelectOptions() {
    const { selectedOption, options } = this.props

    return selectedOption
      ? options.filter(
          option => option.filter_type === OPTION_TYPES.TIME && option.id !== selectedOption.id
        )
      : options
  }

  changeDateRegExp = (date_reg_exp?: string) => {
    const {
      input: { onChange, value }
    } = this.props

    onChange({ ...value, field1: { ...value.field1, date_reg_exp } })
  }

  render() {
    const {
      classes,
      input: { name, value, onChange },
      fields,
      index,
      selectedOption,
      withDateSelect
    } = this.props

    return (
      <Fragment>
        {!!selectedOption && (
          <CompareComponent
            name={name}
            index={index}
            fields={fields}
            optionsCount={1}
            selectedOption={selectedOption}
            withInitialValue
          />
        )}
        <Field
          name={`${name}.field2`}
          id={`${index}-date-value`}
          field={value}
          dateSelectOptions={this.dateSelectOptions}
          withDateSelect={withDateSelect}
          changeDateRegExp={this.changeDateRegExp}
          change={onChange}
          containerProps={{ className: classes.selectTooltipButton }}
          validate={correctDateField}
          component={Value}
        />
      </Fragment>
    )
  }
}

export default compose<HOCProps, HOCOuterProps>(injectStyles(styles))(DateResultHOC)

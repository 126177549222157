import React, { FC, ButtonHTMLAttributes, ReactNode, ReactChild } from 'react'
import { Link } from 'react-router-dom'
import { Location } from 'history'
import classnames from 'classnames'
import injectStyles, { JSSProps } from 'react-jss'

import styles from './styles'
import Loading from 'components/Loading'

export interface ButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type'> {
  type?: string // 'default' | 'primary' | 'danger' | 'transparent'
  icon?: ReactNode | null
  // 'left' | 'right'
  iconPosition?: string
  disabled?: boolean
  textClassName?: string
  iconClassName?: string
  className?: string
  children?: ReactChild
  htmlType?: ButtonHTMLAttributes<HTMLButtonElement>['type']
  href?: string | Partial<Location>
  isLoading?: boolean
  target?: string
  isLink?: boolean
}
interface Props extends ButtonProps, JSSProps<typeof styles> { }
interface HOCProps {
  className: string
  children: any
  htmlType?: ButtonHTMLAttributes<HTMLButtonElement>['type']
}

const ButtonHOC: FC<HOCProps> = ({ className, children, htmlType, ...props }) => (
  <button type={htmlType} className={className} {...props}>
    {children}
  </button>
)

const components = {
  button: ButtonHOC,
  link: Link
}

const HtmlHref = ({ href, children, target, className }) => <a className={className} target={target} href={href}>{children}</a>

const Button: FC<Props> = ({
  type,
  icon,
  iconPosition = 'left',
  iconClassName,
  textClassName,
  className,
  classes,
  children,
  htmlType,
  isLink,
  theme,
  isLoading,
  disabled,
  ...props
}) => {
  const Component = isLink ? components.link : props.href ? HtmlHref : components.button
  const ButtonProps = {
    htmlType,
    disabled: isLoading || disabled
  }
  const LinkProps = {
    to: props.href
  }

  return (
    // @ts-ignore
    <Component
      className={classnames(classes.button, className, {
        [classes.defaultType]: type === 'default',
        [classes.secondaryType]: type === 'secondary',
        [classes.primaryType]: type === 'primary',
        [classes.dangerType]: type === 'danger',
        [classes.transparentType]: type === 'transparent'
      })}
      {...(isLink ? LinkProps : ButtonProps)}
      {...props}
    >
      <div className={classes.container}>
        {((icon && iconPosition === 'left') || isLoading) && (
          <div className={classnames(classes.icon, iconClassName)}>
            {isLoading ? <Loading className={classes.buttonLoading} /> : icon}
          </div>
        )}
        {children && <span className={classnames(classes.text, textClassName)}>{children}</span>}
        {icon && iconPosition === 'right' && (
          <div className={classnames(classes.icon, iconClassName)}>{icon}</div>
        )}
      </div>
    </Component>
  )
}

Button.defaultProps = {
  type: 'default',
  iconPosition: 'left',
  isLink: false
}

export default injectStyles<ButtonProps>(styles)(Button)

import RobotoBold from 'assets/fonts/Roboto-Bold.ttf'
import RobotoBoldWoff from 'assets/fonts/Roboto-Bold.woff'
import RobotoRegular from 'assets/fonts/Roboto-Regular.ttf'
import RobotoRegularWoff from 'assets/fonts/Roboto-Regular.woff'

import HelveticaBold from 'assets/fonts/HelveticaBold.ttf'
import HelveticaBoldWoff from 'assets/fonts/HelveticaBold.woff'
import HelveticaRegular from 'assets/fonts/HelveticaRegular.ttf'
import HelveticaRegularWoff from 'assets/fonts/HelveticaRegular.woff'

import GeorgiaBold from 'assets/fonts/Georgia-Bold.ttf'
import GeorgiaBoldWoff from 'assets/fonts/Georgia-Bold.woff'
import GeorgiaRegular from 'assets/fonts/Georgia-Regular.ttf'
import GeorgiaRegularWoff from 'assets/fonts/Georgia-Regular.woff'

import OpenSansRegular from 'assets/fonts/OpenSans/OpenSans-Regular.ttf'

const styles = (theme: App.Theme) => ({
  '@font-face': [
    {
      fontFamily: 'Open Sans',
      src: `url("${OpenSansRegular}")`,
      fontDisplay: 'swap',
      fontWeight: '400'
    },
    {
      fontFamily: 'Roboto',
      src: `url("${RobotoRegular}") format('truetype'), url("${RobotoRegularWoff}") formal('woff')`,
      fontDisplay: 'swap',
      fontWeight: '400'
    },
    {
      fontFamily: 'Roboto',
      src: `url("${RobotoBold}") format('truetype'), url("${RobotoBoldWoff}") formal('woff')`,
      fontDisplay: 'swap',
      fontWeight: '700'
    },
    {
      fontFamily: 'Helvetica',
      src: `url("${HelveticaRegular}") format('truetype'), url("${HelveticaRegularWoff}") formal('woff')`,
      fontDisplay: 'swap',
      fontWeight: '400'
    },
    {
      fontFamily: 'Helvetica',
      src: `url("${HelveticaBold}") format('truetype'), url("${HelveticaBoldWoff}") formal('woff')`,
      fontDisplay: 'swap',
      fontWeight: '700'
    },
    {
      fontFamily: 'Georgia',
      src: `url("${GeorgiaRegular}") format('truetype'), url("${GeorgiaRegularWoff}") formal('woff')`,
      fontDisplay: 'swap',
      fontWeight: '400'
    },
    {
      fontFamily: 'Georgia',
      src: `url("${GeorgiaBold}") format('truetype'), url("${GeorgiaBoldWoff}") formal('woff')`,
      fontDisplay: 'swap',
      fontWeight: '700'
    }
  ],
})

export default styles

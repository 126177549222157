const styles = (theme: App.Theme) => ({
  input: {
    marginTop: 0,
    padding: 0,
    fontWeight: 'normal',
    fontSize: '1rem',
    lineHeight: 1.2,
    color: '#000',
    border: '1px solid #EBEBEB',
    borderRadius: '0.5rem',
    outline: 'none',
    backgroundColor: 'transparent',
    '&::placeholder': {
      color: theme.colors.grey
    }
  },
  error: {
    border: `1px solid ${theme.colors.red}`
  }
})

export default styles

import { createSelector } from 'reselect'

import { STATES } from 'constants/states'
import AuthPersistor from 'lib/services/authPersistor'

const stateSelector = (state: App.State) =>
  state.user || {
    token: undefined,
    authorized: false,
    status: STATES.IDLE,
    message_name: null
  }

export const usernameSelector = createSelector(
  stateSelector,
  state => state.message_name
)
export const tokenSelector = createSelector(
  stateSelector,
  () => AuthPersistor.getToken()
)
export const authorizedSelector = createSelector(
  stateSelector,
  authState => authState.authorized
)

import cn from 'classnames'
import React, { FC, useEffect } from 'react'

import injectStyles, { JSSProps } from 'react-jss'
import { MapDispatchToProps, MapStateToProps, connect } from 'react-redux'
import { compose } from 'recompose'

import { useLocation } from 'react-router'
import {
  readInfoSystems,
  setSystem,
  toggleModal,
  togglePanel
} from 'src/lib/modules/infoSystems/actions'
import { authorizedSelector } from 'src/lib/modules/user/selectors'
import { ACL, ACLMap } from 'src/lib/utils/aclMask'
import styles from './styles'

interface OuterProps {}
interface StateProps {
  isAuthorized: boolean
  isHead: boolean
  aclMap: ACLMap
  infoSystems: Data.InfoSystem[]
  panelOpened: boolean
  activeSystem: Data.InfoSystem
}
interface DispatchProps {
  setSystem: (system: Data.InfoSystem) => void
}
export interface Props extends StateProps, DispatchProps, JSSProps<typeof styles>, OuterProps {}

export const InfoSystemFrame: FC<Props> = ({
  classes,
  aclMap,
  isHead,
  activeSystem,
  panelOpened,
  setSystem
}) => {
  const canManage = isHead || aclMap[ACL.WIDGETS_WRITE]
  const location = useLocation()

  useEffect(() => () => setSystem({}), [location.pathname])

  if (!canManage) return null

  return activeSystem.id ? (
    <div className={cn(classes.container, { [classes.containerWide]: !panelOpened })}>
      <iframe className={classes.iframe} src={activeSystem.url} />
    </div>
  ) : null
}

const mapStateToProps: MapStateToProps<StateProps, Props, App.State> = state => ({
  isAuthorized: authorizedSelector(state),
  aclMap: state.user.aclMap,
  infoSystems: state.info_systems.list,
  panelOpened: state.info_systems.panelOpened,
  isHead: state.user.user_type === 'head',
  activeSystem: state.info_systems.activeSystem
})
const mapDispatchToProps: MapDispatchToProps<DispatchProps, Props> = dispatch => ({
  readInfoSystems: () => dispatch(readInfoSystems()),
  togglePanel: () => dispatch(togglePanel()),
  toggleModal: () => dispatch(toggleModal()),
  setSystem: (system: Data.InfoSystem) => dispatch(setSystem(system))
})

export default compose<Props, OuterProps>(
  
  injectStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(InfoSystemFrame)

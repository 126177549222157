import { Action, ActionCreator } from 'redux'
import { CATALOG_ITEMS } from 'constants/resources'
import TYPES from './actionTypes'

export interface CreateCatalogOption {
  optionForm?: any
  redirect?: string
  item_type?: string
  endpoint?: string
}

export interface ReadCatalogItems extends Action<typeof TYPES.READ_CATALOG_ITEMS> {
  payload: {
    resourceType: string
    requestKey: string
    options: ReadCatalogOptions
  }
}
export interface CreateCatalogItem extends Action<typeof TYPES.CREATE_CATALOG_ITEM> {
  payload: {
    resourceType: string
    requestKey: string
    options: CreateCatalogOption
  }
}
export interface UpdateCatalogItem extends Action<typeof TYPES.UPDATE_CATALOG_ITEM> {
  payload: {
    resourceType: string
    requestKey: string
    options: {
      ID: number
      redirect?: string
      endpoint?: string
      optionForm?: any
    }
  }
}
export interface DeleteCatalogItem extends Action<typeof TYPES.DELETE_CATALOG_ITEM> {
  payload: {
    resourceType: string
    requestKey: string
    id: number
  }
}

export type ActionTypes =
  | ReadCatalogItems
  | CreateCatalogItem
  | UpdateCatalogItem
  | DeleteCatalogItem

export interface ReadCatalogOptions {
  ids: Array<string>
}

export const readCatalogItems: ActionCreator<ReadCatalogItems> = (options: ReadCatalogOptions) => ({
  type: TYPES.READ_CATALOG_ITEMS,
  payload: {
    resourceType: CATALOG_ITEMS.NAME,
    requestKey: CATALOG_ITEMS.REQUESTS.GET_CATALOG_ITEMS,
    options
  }
})
export const createCatalogItem: ActionCreator<CreateCatalogItem> = (options: {
  item_type?: string
  redirect?: string
  endpoint?: string
}) => ({
  type: TYPES.CREATE_CATALOG_ITEM,
  payload: {
    resourceType: CATALOG_ITEMS.NAME,
    requestKey: CATALOG_ITEMS.REQUESTS.CREATE_CATALOG_ITEM,
    options
  }
})
export const updateCatalogItem: ActionCreator<UpdateCatalogItem> = (options: {
  ID: number
  redirect?: string
  endpoint?: string
}) => ({
  type: TYPES.UPDATE_CATALOG_ITEM,
  payload: {
    resourceType: CATALOG_ITEMS.NAME,
    requestKey: CATALOG_ITEMS.REQUESTS.UPDATE_CATALOG_ITEM,
    options
  }
})
export const deleteCatalogItem: ActionCreator<DeleteCatalogItem> = (id: number) => ({
  type: TYPES.DELETE_CATALOG_ITEM,
  payload: {
    resourceType: CATALOG_ITEMS.NAME,
    requestKey: CATALOG_ITEMS.REQUESTS.DELETE_CATALOG_ITEM,
    id
  }
})

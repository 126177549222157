import React, { Component } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import { compose } from 'recompose'
import {Menu} from '@mui/material'
import {MenuItem} from '@mui/material'
import {List} from '@mui/material'
import {ListItem} from '@mui/material'
import {ListItemText} from '@mui/material'
import {ListItemIcon} from '@mui/material'
import {Tooltip} from '@mui/material'

import AggregationService from 'lib/services/aggregation'
import { Aggregation as AggregationIcon } from 'components/Icons'
import { FORM } from 'constants/aggregation'
import styles from './styles'

interface OuterProps {
  anchorEl?: HTMLDivElement
  target?: Filter.MainField
  targetIndex?: number
  onChangeTarget?: (newValues: Filter.MainField, index: number) => void
  closeMenu: () => void
}
interface Props extends JSSProps<typeof styles>, OuterProps {}

interface State {}

class FiltersMenu extends Component<Props, State> {
  componentWillUnmount() {
    const { anchorEl, closeMenu } = this.props

    if (anchorEl) closeMenu()
  }

  get renderAggregationMenuItem() {
    const { target, classes } = this.props

    if (!target) {
      return null
    }

    return (
      <Tooltip
        classes={{ tooltip: classes.tooltipContainer }}
        PopperProps={{
          style: {
            opacity: 1
          }
        }}
        placement="right-start"
        title={this.renderAggregationSubMenu}
        disableFocusListener
        disableTouchListener
        interactive
      >
        <MenuItem className={classes.menuItem}>
          <ListItemIcon>
            <AggregationIcon className={classes.menuItemIcon} />
          </ListItemIcon>
          Агрегация&nbsp;
          <b>({AggregationService.getAggregationSymbol(target.agg as FORM)})</b>
        </MenuItem>
      </Tooltip>
    )
  }

  get renderAggregationSubMenu() {
    const { target, classes } = this.props

    if (!target) {
      return null
    }

    let types = []

    types = AggregationService.withMeasureTypes.filter(form => form !== FORM.CORR)

    types.push(FORM.NONE)

    return (
      <List className={classes.container}>
        {types.map(type => (
          <ListItem
            key={type}
            className={classes.menuItem}
            classes={{
              gutters: classes.menuItemGutters
            }}
            button
            onClick={this.onSelectAggregation(type)}
          >
            <ListItemText classes={{ primary: classes.tooltipItemText }}>
              {AggregationService.getAggregationName(type)}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    )
  }

  onSelectAggregation = (type: FORM) => () => {
    const { target, onChangeTarget, targetIndex, closeMenu } = this.props

    onChangeTarget(
      { ...target, type: type === FORM.NONE ? 'where' : 'having', agg: type },
      targetIndex
    )

    closeMenu()
  }

  render() {
    const { classes, closeMenu, anchorEl } = this.props
    const isOpen = !!anchorEl

    return (
      <Menu
        MenuListProps={{
          className: classes.container
        }}
        anchorEl={isOpen ? anchorEl : undefined}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={isOpen}
        getContentAnchorEl={undefined}
        onClose={closeMenu}
      >
        {this.renderAggregationMenuItem}
      </Menu>
    )
  }
}

export default compose<Props, OuterProps>(injectStyles(styles))(FiltersMenu)

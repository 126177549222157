import { actionTypes as RESOURCE_TYPES } from 'redux-resource'

export type RESPONSE_DATA_TYPE = Array<{}> | Object

const getResourceActions = ({
  actionType,
  requestKey,
  resourceType,
  ids,
  requestProperties,
  list,
  responseData = {},
  mergeResources = true,
  mergeListIds = true,
  mergeMeta = true,
  merge = true
}: {
  actionType: 'CREATE' | 'READ' | 'UPDATE' | 'DELETE'
  requestKey: string
  resourceType: string
  ids?: Array<string | number>
  requestProperties?: Object
  responseData?: RESPONSE_DATA_TYPE
  list?: string
  mergeResources?: boolean
  mergeListIds?: boolean
  mergeMeta?: boolean
  merge?: boolean
}) => ({
  onRequestAction: () => ({
    type: RESOURCE_TYPES[`${actionType}_RESOURCES_PENDING`],
    requestKey,
    resourceType,
    requestProperties,
    resources: ids,
    responseData,
    list
  }),
  onSuccessAction: (resources: Array<Object>, responseDataProp?: RESPONSE_DATA_TYPE) => ({
    type: RESOURCE_TYPES[`${actionType}_RESOURCES_SUCCEEDED`],
    requestKey,
    resourceType,
    resources,
    responseData: responseDataProp || responseData,
    list,
    mergeResources: !merge ? merge : mergeResources,
    mergeListIds: !merge ? merge : mergeListIds,
    mergeMeta: !merge ? merge : mergeMeta
  }),
  onFailAction: (errors: Object) => ({
    type: RESOURCE_TYPES[`${actionType}_RESOURCES_FAILED`],
    requestKey,
    resourceType,
    resources: ids,
    responseData,
    list,
    requestProperties: {
      ...(requestProperties = {}),
      errors
    }
  })
})

export default getResourceActions

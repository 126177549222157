import { Reducer } from 'redux'

import types from './types'
import { ActionTypes } from './actions'
import { STATES } from 'constants/states'

export interface State {
  readStatus: STATES
  updateStatus: STATES
  modalOpened: boolean
  panelOpened: boolean
  activeSystem: Data.InfoSystem
  list: Data.InfoSystem[]
}

const initialState: State = {
  readStatus: STATES.IDLE,
  updateStatus: STATES.IDLE,
  modalOpened: false,
  panelOpened: true,
  activeSystem: {},
  list: []
}

export const reducer: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case types.READ_INFO_SYSTEMS_REQUEST:
      return { ...state, readStatus: STATES.LOADING }
    case types.READ_INFO_SYSTEMS_SUCCESS:
      return { ...state, readStatus: STATES.SUCCEEDED, list: action.payload.list }
    case types.READ_INFO_SYSTEMS_FAILURE:
      return { ...state, readStatus: STATES.FAILED }
    case types.UPDATE_INFO_SYSTEMS_REQUEST:
      return { ...state, updateStatus: STATES.LOADING }
    case types.UPDATE_INFO_SYSTEMS_SUCCESS:
      return { ...state, updateStatus: STATES.SUCCEEDED, list: action.payload.list }
    case types.UPDATE_INFO_SYSTEMS_FAILURE:
      return { ...state, updateStatus: STATES.FAILED }
    case types.TOGGLE_MODAL:
      return { ...state, modalOpened: !state.modalOpened }
    case types.TOGGLE_PANEL:
      return { ...state, panelOpened: !state.panelOpened }
    case types.SET_SYSTEM:
      return { ...state, activeSystem: action.payload.system }
    default:
      return state
  }
}

// @ts-nocheck
import React, { useLayoutEffect, useState } from 'react'
import { connect, MapStateToProps } from 'react-redux'
import { compose } from 'recompose'
import injectStyles, { JSSProps } from 'react-jss'
import { useLocation, useNavigate, Outlet } from 'react-router-dom'

import ActionCableService from 'lib/services/actionCable'
import SideMenu from 'components/SideMenu'
import SideMenuForHead from 'components/SideMenuForHead'
import RoleContentHOC from 'components/RoleContentHoc'
import PdfViewer from 'components/PdfViewer'
import UserSettings from 'containers/UserSettings'
import ImportSettings from 'containers/ImportSettings'
import Notifications from 'containers/Notifications'
import OrganisationModal from 'containers/OrganisationModal'
import OrderModal from 'containers/OrderModal'
import { BASE_CHANNEL } from 'constants/actionCable'
import styles from './styles'
import isMobileApp from 'lib/utils/isMobileApp'
import NavigationMenu from 'components/NavigationMenu'
import Frame from 'src/pages/closed/OtherServices/components/Frame'
import Modal from 'pages/closed/OtherServices/components/Modal'

interface StateProps {
  isAuthorized: boolean
  user_type: App.State['user']['user_type'] | undefined
  panelOpened: boolean
}

interface OuterProps { }

export interface Props extends StateProps, DispatchProps, OuterProps, JSSProps<typeof styles> { }

const Layout = ({ user_type, classes, isAuthorized }) => {
  const [state, setState] = useState({ isSettingsOpen: false, isConnectedEarlier: false })
  const { pathname, search } = useLocation()
  
  const navigate = useNavigate()

  useLayoutEffect(() => {
    ActionCableService.createChannel(BASE_CHANNEL, {
      connected: () => {
        if (!isAuthorized && pathname === '/') {
          // If a user wasn't authorized, but going to main page
          navigate({ pathname: '/login' })
        }

        setState(prev => ({ ...prev, isConnectedEarlier: true }))
      }
    })
  }, [])



  const isSPK = pathname === '/spk_building'
  const isUserAdmin = user_type === 'superadmin'

  const openSettings = () => setState(prev => ({ ...prev, isSettingsOpen: true }))
  const closeSettings = () => setState(prev => ({ ...prev, isSettingsOpen: false }))

  return (
    <React.Suspense fallback={<p>Загрузка страницы...</p>}>
      <Notifications />
      {/* <Viewport /> */}
      <div className={classes.layoutContainer}>
        {isAuthorized && <NavigationMenu />}
        <main className={classes.layout}>
          {isAuthorized &&
            !/without_header=true/.test(search) &&
            !isSPK && (
              <RoleContentHOC
                roles={[{ user_type: 'head', Component: SideMenuForHead }]}
                DefaultComponent={SideMenu}
                passThroughProps={{
                  openSettings: openSettings,
                }}
              />
            )}
          <div className={classes.contentWrapper}>
            <Outlet />
          </div>
        </main>
      </div>
      <UserSettings isOpen={state.isSettingsOpen} closeModal={closeSettings} />
      {isUserAdmin && <ImportSettings />}
      <OrganisationModal />
      <OrderModal />
      <PdfViewer />
      {!isMobileApp() && (
        <>
          <Frame />
          <Modal />
        </>
      )}
    </React.Suspense>
  )
}

const mapStateToProps: MapStateToProps<StateProps, Props, App.State> = state => ({
  isAuthorized: state.user.authorized,
  panelOpened: state.info_systems.panelOpened,
  user_type: state.user.user_type
})

export default compose<Props, OuterProps>(
  connect(mapStateToProps),
  injectStyles<OuterProps>(styles)
)(Layout)

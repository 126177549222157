import { createSelector } from 'reselect'
import { getResources, ResourceRequest } from 'redux-resource'

import { CHART_CATEGORIES, STATES } from 'constants/resources'

const getChartCategoriesSlice = (state: App.State): App.State['chartCategories'] =>
  state.chartCategories
const getChartCategoriesRequest = (state: App.State): ResourceRequest =>
  state.chartCategories.requests[CHART_CATEGORIES.REQUESTS.GET_CHART_CATEGORIES]

export const getChartCategories = createSelector(
  [getChartCategoriesSlice],
  chartCategoriesSlice => getResources(chartCategoriesSlice)
)

export const getChartNSICategories = createSelector(
  [getChartCategories],
  chartCategories => chartCategories.filter(category => category.nsi)
)

export const getChartCategoriesOptions = createSelector(
  [getChartCategories],
  function(chartCategories) {
    return chartCategories.map(dimension => ({
      value: dimension.id,
      label: dimension.name
    }))
  }
)

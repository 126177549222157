import { Props } from './index'

const styles = (theme: App.Theme) => ({
  '@global': {
    '.input-range__slider': {
      width: '1em',
      height: '1em',
      marginLeft: '-0.5em',
      marginTop: '-0.7em',
      fontSize: '0.625rem',
      backgroundColor: '#fff',
      border: `2px solid ${theme.colors.blue}`,
      borderRadius: '50%',
      outline: 'none',
      cursor: 'pointer',
      appearance: 'none',
      transition: 'transform 0.3s ease-out, box-shadow 0.3s ease-out',
      '&:active': {
        transform: 'scale(1.3)'
      }
    },
    '.input-range__slider-container': {
      transition: 'left 0.3s ease-out'
    },
    '.input-range__label': {
      fontFamily: 'inherit',
      fontSize: '0.8rem',
      color: 'rgb(155, 155, 155)',
      transform: 'translateZ(0)',
      whiteSpace: 'nowrap'
    },
    '.input-range__label--min, .input-range__label--max': {
      display: 'none'
    },
    '.input-range__label--value': {
      position: 'absolute',
      top: '-1.5rem'
    },
    '.input-range__label-container': {
      position: 'relative',
      left: '-50%'
    },
    '.input-range__track': {
      position: 'relative',
      display: 'block',
      height: '0.3rem',
      background: 'rgb(155, 155, 155)',
      borderRadius: '0.3rem',
      cursor: 'pointer',
      transition: 'left 0.3s ease-out, width 0.3s ease-out'
    },
    '.input-range__track--background': {
      position: 'absolute',
      top: '50%',
      right: 0,
      left: 0,
      marginTop: '-0.15rem'
    },
    '.input-range__track--active': {
      background: theme.colors.blue
    },
    '.input-range': {
      position: 'relative',
      width: 'calc(100% - 0.625rem)',
      height: '1rem',
      margin: '0 0.5rem'
    }
  },
  container: {
    position: 'relative',
    display: 'flex',
    flex: '1 1 44%',
    alignItems: 'center',
    alignSelf: 'center',
    maxWidth: '50%'
  },
  input: {
    width: '5.5rem',
    fontSize: '1rem',
    lineHeight: 1,
    textAlign: 'center',
    backgroundColor: 'transparent',
    border: 'none'
  }
})

export default styles

import React, { PureComponent, HTMLAttributes } from 'react'

import { WrappedFieldProps } from 'redux-form'
import { compose } from 'recompose'
import { InputRangeProps } from 'react-input-range'
import injectStyles, { JSSProps } from 'react-jss'

import DateRange from 'components/DateRange'
import styles from './styles'

interface OuterProps extends Partial<InputRangeProps> {
  id: string
  containerProps?: Partial<HTMLAttributes<HTMLDivElement>>
  // Fix strange ts error
  component?: any
}
export interface Props extends OuterProps, JSSProps<typeof styles>, WrappedFieldProps {}
interface State {}

class Range extends PureComponent<Props, State> {
  render() {
    const {
      classes,
      theme,
      input: { value: inputValue, onChange },
      ...props
    } = this.props

    return <DateRange step={3600 * 1000 * 24} value={inputValue} onChange={onChange} {...props} />
  }
}

export default compose<Props, OuterProps>(
  
  injectStyles<OuterProps>(styles)
)(Range)

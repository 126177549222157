const styles = (theme: App.Theme) => {
  return ({
    menuWrap: {
      ...theme.utils.flexContainer({
        direction: 'column',
        alignItems: 'flex-end',
        justify: 'flex-end'
      }),
      width: '100%'
    },
    menuContainer: {
      ...theme.utils.flexContainer({ direction: 'row', alignItems: 'center' }),
      width: '100%',
      padding: '0 1.25rem',
      height: theme.sizes.sidemenu.height,
      boxSizing: 'border-box',
      flexGrow: 0,
      flexShrink: 0
    },
    menuHeader: {
      ...theme.utils.flexContainer({ direction: 'row', alignItems: 'center', justify: 'flex-start' }),
      boxSizing: 'border-box',
      flex: `0 0 calc(${theme.sizes.header.height} + ${theme.sizes.sidemenu.itemIcon} + 20px * 2)`,
      paddingTop: '1.25rem'
    },
    menuBody: {
      ...theme.utils.flexContainer({ direction: 'row', alignItems: 'center', justify: 'flex-start' }),
      flex: '2 0 auto',
      gap: '14px',
      height: '100%'
    },
    menuLabel: {
      fontWeight: 600,
      fontSize: '1.25rem',
      lineHeight: '1.6875rem',
      color: theme.colors.text.header,
    },
    clickableLabel: {
      cursor: 'pointer',
    },
    breadcrumbs: {
      display: 'flex',
      alignItems: 'center',
      gap: '30px'
    },
    breadcrumbLink: {
      fontSize: '1.25rem',
      color: theme.colors.text.signature,
      textDecoration: 'none'
    },
    menuFooter: {
      ...theme.utils.flexContainer({ direction: 'row', alignItems: 'center', justify: 'center' }),
      flex: '0 0 auto',
      boxSizing: 'border-box'
    },
    item: {
      width: '2rem',
      height: '2rem',
      fontSize: 14,
      boxSizing: 'border-box'
    },
    itemLink: {
      extend: 'item',
      ...theme.utils.flexContainer({
        direction: 'row',
        alignItems: 'center'
      }),
      textDecoration: 'none',
      color: '#000000',
      '&:visited, &:hovered': {
        textDecoration: 'none',
        color: '#000000'
      }
    },
    itemButton: {
      extend: 'item',
      padding: 0,
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer'
    },
    itemLabel: {},
    itemIcon: {
      flex: '1 0 auto'
    },
    logoutIcon: {
      extend: 'itemIcon'
    },
    notificationsIcon: {
      composes: '$item',
      lineHeight: 0,
      cursor: 'pointer'
    },
    placeholder: {
      display: 'flex',
      flexFlow: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '2rem',
      height: '2rem',
      background: 'transparent',
      boxSizing: 'border-box',
      '& > div': {
        content: '""',
        width: theme.sizes.sidemenu.headerPlaceholderSize,
        height: theme.sizes.sidemenu.headerPlaceholderSize,
        backgroundColor: 'transparent',
        border: 'solid 7px #2f61d5',
        borderRadius: '100%',
        boxSizing: 'border-box'
      }
    },
    tooltip: {
      // !important needed for overwrite default styles
      fontFamily: 'Helvetica !important',
      fontSize: '1rem !important',
      fontWeight: 'bold !important',
      opacity: '1 !important',
      boxShadow: `${theme.common.page.contentBoxShadow} !important`
    },
    '@global': {
      '#notifications-tooltip': {
        '& .MuiTooltip-tooltip': {
          background: 'transparent !important',
        }
      },
    },
    divider: {
      height: '1.5rem',
      width: 1
    },
    menu: {
      width: '40%',
      maxWidth: '37.5rem',
      boxShadow: '0 1px 0 0 #EEEEEE, 0 5px 20px 0 rgba(0,0,0,0.10)'
    },
    menuButtonLabel: {
      fontWeight: 'bold',
      textTransform: 'none'
    },
    menuButtonCloseLabel: {
      fontWeight: 'bold',
      textTransform: 'none',
      color: '#858E98'
    },
    menuButtonIcon: {
      marginLeft: theme.spacing.unit,
      fontSize: '1rem'
    },
    sideButtons: {
      width: 62,
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      zIndex: 1000,
      '& svg': {
        width: '18px',
        height: '18px',

        '& .active-route': {
          fill: 'blue'
        }
      }
    },
    buttonPosition: {
      margin: '1.25rem'
    },
    menuListHeader: {
      lineHeight: theme.sizes.sidemenu.height
    },
    menuItem: {
      justifyContent: 'space-between',
      cursor: 'default',
      '&:hover, &:focus': {
        backgroundColor: 'transparent',
        '& $menuItemDivider': {
          backgroundColor: theme.colors.red
        }
      }
    },
    menuItemDivider: {
      position: 'absolute',
      bottom: 0,
      right: theme.spacing.unit * 2,
      left: theme.spacing.unit * 2,
      backgroundColor: '#F7F7F7',
      transition: 'background-color 0.3s ease-out'
    },
    menuItemButton: {
      minWidth: 0,
      paddingLeft: 0,
      paddingRight: 0,
      transition: 'color 0.3s ease-out',
      '&:hover, &:focus': {
        color: theme.colors.blue,
        backgroundColor: 'transparent'
      }
    },
    menuItemButtonCount: {
      marginLeft: '0.5rem',
      color: '#858E98'
    },
    menuItemButtonNotifications: {
      extend: 'menuItemButtonCount',
      marginLeft: '1rem'
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      width: 'calc(100% - 2rem)',
      margin: '1rem',
      padding: '1rem',
      backgroundColor: theme.colors.page.contentBackground,
      borderRadius: 3
    },
    cardHeader: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    cardEmail: {
      fontSize: '0.625rem',
      fontWeight: 'bold',
      color: theme.colors.grey
    },
    cardContent: {},
    cardName: {
      margin: 0,
      marginTop: '1rem',
      fontSize: '1.125rem',
      fontWeight: 'bold',
      color: '#000'
    },
    cardFooter: {
      extend: 'cardHeader',
      marginTop: '0.5rem'
    },
    buttons: {
      display: 'flex'
    },
    button: {
      '&:not(:last-child)': {
        marginRight: '5px'
      }
    },
    userSettingsButton: {
      extend: 'button',
      margin: -theme.spacing.unit * 1.5
    },
    notificationsButtonLabel: {
      extend: 'menuButtonLabel',
      width: '1.625rem',
      height: '1.625rem'
    },
    notificationsButtonBadge: {
      top: '9px',
      right: '22px',
      backgroundColor: theme.colors.redBadge
    },
    buttonLogOut: {
      fontSize: '12px',
      fontWeight: 'bold',
      textTransform: 'none'
    },
    buttonUserName: {
      extend: 'buttonLogOut'
    },
    referenceRow: {
      ...theme.utils.flexContainer({
        direction: 'row',
        alignItems: 'center',
        justify: 'space-between'
      }),
      marginTop: '0.5rem',
      width: '100%',
      padding: '1rem',
      boxSizing: 'border-box'
    },
    reference: {
      color: theme.colors.red,
      fontSize: '0.65rem',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      textDecoration: 'none'
    },
    settingsNavbar: {
      margin: 'auto'
    },
    centerButton: {
      margin: 'auto'
    },
    icon: {
      fill: '#277EE4',
      backgroundColor: theme.colors.white,
      borderRadius: '.3rem'
    },
    changeRoutes: {
      marginLeft: '1.25rem',
      extend: 'menuButtonLabel',
      marginRight: 20
    }
  })
}

export default styles

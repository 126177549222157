import { createSelector } from 'reselect'

const getAggregationsMenuState = (state: App.State): App.State['aggregations']['menu'] =>
  state.aggregations.menu

export const getTargetAggregationID = createSelector(
  [getAggregationsMenuState],
  function(menu) {
    if (menu && menu.targetAggregation) {
      return menu.targetAggregation.id
    }

    return undefined
  }
)

export const getTargetGroupID = createSelector(
  [getAggregationsMenuState],
  function(menu) {
    if (menu && menu.targetGroup) {
      return menu.targetGroup.id
    }

    return undefined
  }
)

import { Reducer } from 'redux'

import { Types } from './actionTypes'
import { ActionTypes } from './actions'
import { STATES } from 'constants/states'

export interface State {
  infoStatus: STATES
  items: Data.MspCubeItem[]
  cubeId: number | null
}

const initialState: State = {
  infoStatus: STATES.IDLE,
  items: [],
  cubeId: null
}

export const reducer: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case Types.READ_MSP:
      return { ...state, infoStatus: STATES.IDLE }
    case Types.READ_MSP_LOADING:
      return { ...state, infoStatus: STATES.LOADING }
    case Types.READ_MSP_FAILURE:
      return { ...state, infoStatus: STATES.FAILED }
    case Types.READ_MSP_SUCCESS:
      return {
        ...state,
        items: action.payload.data,
        infoStatus: STATES.SUCCEEDED,
        cubeId: action.payload.cube_id
      }
    default:
      return state
  }
}

export interface ACLMap {
  [key: string]: boolean
}
export const ACL = {
  SCHEMA_WRITE: 'schema_write',
  JOURNAL_READ: 'journal_read',
  USERS_WRITE: 'users_write',
  PANELS_READ: 'panels_read',
  PANELS_WRITE: 'panels_write',
  ELEMENTS_READ: 'elements_read',
  ELEMENTS_WRITE: 'elements_write',
  FORMS_READ: 'forms_read',
  FORMS_WRITE: 'forms_write',
  MESSAGES_READ: 'messages_read',
  MESSAGES_WRITE: 'messages_write',
  NEWS_READ: 'news_read',
  NEWS_WRITE: 'news_write',
  SEARCH_READ: 'search_read',
  ADMINISTRATION_WRITE: 'administration_write',
  CATALOG_ITEMS_READ: 'catalog_items_read',
  CATALOG_ITEMS_WRITE: 'catalog_items_write',
  DESKTOP_READ: 'desktop_read',
  DESKTOP_WRITE: 'desktop_write',
  MAP_READ: 'map_read',
  EVENTS_READ: 'events_read',
  EVENTS_WRITE: 'events_write',
  KADRI_READ: 'kadri_read',
  CAMERAS_READ: 'cameras_read',
  INFO_REFERENCES_READ: 'references_read',
  INFO_REFERENCES_WRITE: 'references_write',
  OTHER_SERVICES_READ: 'other_services_read',
  OTHER_SERVICES_WRITE: 'other_services_write',
  WIDGETS_READ: 'widgets_read',
  WIDGETS_WRITE: 'widgets_write',
  INFO_SYSTEMS_READ: 'info_systems_read',
  INFO_SYSTEMS_WRITE: 'info_systems_write',
  DESKTOP_ELEMENT_READ: 'desktop_element_read',
  INCIDENTS_RESPONSIBILITY: 'incidents_responsibility',
  INCIDENTS_READ: 'incidents_read',
  INCIDENTS_WRITE: 'incidents_write',
  CUBES_CREATE: 'cubes_create',
  CUBES_UPDATE: 'cubes_update',
  CUBES_REWRITE: 'cubes_rewrite',
  CUBES_HAND_UPDATE: 'cubes_hand_update',
  CUBES_DELETE: 'cubes_delete',
  INCIDENTS_MODER: 'incidents_moder'
}
export const ACL_NAME = {
  [ACL.SCHEMA_WRITE]: 'Редактирование схемы данных, в т.ч. Импорт показателей из файлов',
  [ACL.JOURNAL_READ]: 'Просмотр системного журнала',
  [ACL.USERS_WRITE]: 'Создание и редактирование учетных записей пользователей',
  [ACL.PANELS_READ]: 'Просмотр панелей',
  [ACL.PANELS_WRITE]: 'Создание и редактирование АП',
  [ACL.ELEMENTS_READ]: 'Просмотр элементов',
  [ACL.ELEMENTS_WRITE]: 'Создание и редактирование АЭ',
  [ACL.FORMS_READ]: 'Просмотр форм',
  [ACL.FORMS_WRITE]: 'Создание и редактирование форм сбора сведений',
  [ACL.MESSAGES_READ]: 'Просмотр сообщений',
  [ACL.MESSAGES_WRITE]: 'Отправка сообщений',
  [ACL.NEWS_READ]: 'Просмотр и публикация новостей',
  [ACL.NEWS_WRITE]: 'Создание новостей',
  [ACL.SEARCH_READ]: 'Поиск',
  [ACL.ADMINISTRATION_WRITE]: 'Изменение настроек',
  [ACL.CATALOG_ITEMS_READ]: 'Создание справочников',
  [ACL.CATALOG_ITEMS_WRITE]: 'Просмотр справочников',
  [ACL.DESKTOP_READ]: 'Просмотр рабочего стола',
  [ACL.DESKTOP_WRITE]: 'Редактирование рабочего стола',
  [ACL.MAP_READ]: 'Просмотр карты',
  [ACL.EVENTS_READ]: 'Расписание Губернатора',
  [ACL.EVENTS_WRITE]: 'Изменение расписания',
  [ACL.KADRI_READ]: 'Кадры',
  [ACL.CAMERAS_READ]: 'Видеонаблюдение',
  [ACL.INFO_REFERENCES_READ]: 'Просмотр справок',
  [ACL.INFO_REFERENCES_WRITE]: 'Создание и редактирование справки',
  [ACL.OTHER_SERVICES_READ]: 'Просмотр других сервисов',
  [ACL.OTHER_SERVICES_WRITE]: 'Создание других сервисов',
  [ACL.WIDGETS_READ]: 'Просмотр виджетов',
  [ACL.WIDGETS_WRITE]: 'Создание и редактирование виджетов',
  [ACL.INFO_SYSTEMS_READ]: 'Просмотр информационных систем',
  [ACL.INFO_SYSTEMS_WRITE]: 'Создание и редактирование информационных систем',
  [ACL.DESKTOP_ELEMENT_READ]: 'Просмотр элемента рабочего стола',
  [ACL.INCIDENTS_RESPONSIBILITY]: 'Ответственный',
  [ACL.INCIDENTS_READ]: 'Просмотр инцидентов',
  [ACL.INCIDENTS_WRITE]: 'Создание инцидентов',
  [ACL.CUBES_CREATE]: 'Создание кубов',
  [ACL.CUBES_UPDATE]: 'Обновление кубов',
  [ACL.CUBES_REWRITE]: 'Перезапись кубов',
  [ACL.CUBES_HAND_UPDATE]: 'Ручное обновление кубов',
  [ACL.CUBES_DELETE]: 'Удаление кубов',
  [ACL.INCIDENTS_MODER]: 'Модератор'
}

export const ACL_BY_SECTION: { [key: string]: { label: string; read?: string; write?: string } } = {
  DESKTOP: {
    label: 'Рабочий стол',
    read: ACL.DESKTOP_READ,
    write: ACL.DESKTOP_WRITE
  },
  PANELS: {
    label: 'Панели',
    read: ACL.PANELS_READ,
    write: ACL.PANELS_WRITE
  },
  ELEMENTS: {
    label: 'Элементы',
    read: ACL.ELEMENTS_READ,
    write: ACL.ELEMENTS_WRITE
  },
  INFO_REFERENCES: {
    label: 'Справки',
    read: ACL.INFO_REFERENCES_READ,
    write: ACL.INFO_REFERENCES_WRITE
  },
  WIDGETS: {
    label: 'Виджеты',
    read: ACL.WIDGETS_READ,
    write: ACL.WIDGETS_WRITE
  },
  FORMS: {
    label: 'Формы сбора данных',
    read: ACL.FORMS_READ,
    write: ACL.FORMS_WRITE
  },
  MESSAGES: {
    label: 'Сообщения',
    read: ACL.MESSAGES_READ,
    write: ACL.MESSAGES_WRITE
  },
  INCIDENTS: {
    label: 'Инциденты',
    read: ACL.INCIDENTS_READ,
    write: ACL.INCIDENTS_WRITE
  },
  NEWS: {
    label: 'Новости',
    read: ACL.NEWS_READ,
    write: ACL.NEWS_WRITE
  },
  CATALOG_ITEMS: {
    label: 'Справочники',
    read: ACL.CATALOG_ITEMS_READ,
    write: ACL.CATALOG_ITEMS_WRITE
  },
  OTHER_SERVICES: {
    label: 'Другие сервисы',
    read: ACL.OTHER_SERVICES_READ,
    write: ACL.OTHER_SERVICES_WRITE
  }
}
const sortedToSectionsAcl = Object.values(ACL_BY_SECTION)
  .map(item => [item.read, item.write])
  .reduce((accum, value) => [...accum, ...value], [])

export const ACL_NO_SECTION = Object.keys(ACL_NAME).filter(
  item => !sortedToSectionsAcl.includes(item)
)

interface TranslatedAclMask {
  [key: string]: boolean
}

export function fromACLMask(mask: number, aclTranslatedMask?: TranslatedAclMask): ACLMap {
  const acl: ACLMap = {}

  if (aclTranslatedMask) {
    Object.values(ACL).forEach(section => {
      acl[section] = aclTranslatedMask[section]
    })
  } else {
    Object.values(ACL).forEach((section, i) => {
      acl[section] = (mask & (1 << i)) === 1 << i
    })
  }

  return acl
}

export function toACLMask(acl: ACLMap): string {
  return JSON.stringify(acl)
}

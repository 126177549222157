import { createSelector } from 'reselect'

export const getImportFiles = (state: App.State) => state.importFiles.importFiles
export const getReadStatus = (state: App.State) => state.importFiles.readStatus

export const getImportFilesItems = createSelector(
  [getImportFiles],
  items => items
)

export const getImportFilesLoadingStatus = createSelector(
  [getReadStatus],
  status => status
)

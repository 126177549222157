import { Props } from './index'

const styles = (theme: App.Theme) => ({
  fileViewerWrapper: {
    position: 'fixed',
    top: `calc(${theme.sizes.sidemenu.height} + 4rem)`,
    right: '2.5rem',
    bottom: '1.25rem',
    left: '2.5rem',
    // height: `calc(${theme.utils.calculatePageHeight({
    //   viewportHeight: theme.utils.viewportCssValues.vh(100)
    // })} - 5rem)`,
    padding: '1.25rem',
    background: '#fff',
    boxShadow: '0 3px 5px 0 rgba(0,0,0,0.10)',
    zIndex: 10
  },
  fileViewer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    overflow: 'auto'
  },
  document: {
    maxWidth: '100%',
    '& *': {
      maxWidth: '100%'
    }
  },
  closeFile: {
    position: 'absolute',
    top: '1.25rem',
    right: '1.25rem',
    background: '#fff',
    zIndex: 10
  }
})

export default styles

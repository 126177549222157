import { step, unit, keys, values, Breakpoint, BreakpointValues } from 'constants/breakpoints'

function media(limit: string, dimension: string, key: Breakpoint | number | string): string {
  switch (typeof key) {
    case 'number':
      return `@media (${limit}-${dimension}: ${key}${unit})`
    case 'string':
      // @ts-ignore
      if (values[key]) {
        // @ts-ignore
        return `@media (${limit}-${dimension}: ${values[key]}${unit})`
      }

      return `@media (${limit}-${dimension}: ${key})`
    default:
      console.error(`Wrong up value type (${typeof key})`)
      return ''
  }
}

function up(key: Breakpoint | number | string): string {
  return media('min', 'width', key)
}

function down(key: Breakpoint | number) {
  return media('max', 'width', key)
}

function between(start: Breakpoint, end: Breakpoint) {
  return `${up(start)} and ${down(end)}`
}

function only(key: Breakpoint) {
  return between(key, key)
}

function width(key: Breakpoint) {
  return values[key]
}

const breakpoints = {
  keys,
  values,
  unit,
  step,
  up,
  down,
  between,
  only,
  width
}

export { breakpoints as media }
export default breakpoints

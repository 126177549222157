enum TYPES {
  ADD_FAVORITE = 'ADD_FAVORITE',
  REMOVE_FAVORITE = 'REMOVE_FAVORITE',
  READ_PANELS = 'READ_PANELS',
  CREATE_PANEL = 'CREATE_PANEL',
  UPDATE_PANEL = 'UPDATE_PANEL',
  DELETE_PANEL = 'DELETE_PANEL',
  COPY_PANEL = 'COPY_PANEL',
  GET_PANEL_BY_LINK_KEY = 'GET_PANEL_BY_LINK_KEY'
}

export default TYPES

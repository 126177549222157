import { takeEvery, put, select, all } from 'redux-saga/effects'
import { actionTypes } from 'redux-resource'

import { tokenSelector } from 'lib/modules/user/selectors'
import ActionCableService from 'lib/services/actionCable'
import { BASE_CHANNEL } from 'constants/actionCable'
import { GetScannerCategories } from './actions'
import types from './actionTypes'

function* getScannerCategories(action: GetScannerCategories) {
  const { requestKey, resourceType } = action.payload
  const token = yield select(tokenSelector)

  try {
    yield put({
      type: actionTypes.READ_RESOURCES_PENDING,
      requestKey,
      resourceType
    })

    const {
      payload
    }: { payload: Array<Data.ScannerCategory> } = yield ActionCableService.sendMessage({
      channelName: BASE_CHANNEL,
      token,
      type: requestKey,
      payload: {}
    })

    yield put({
      type: actionTypes.READ_RESOURCES_SUCCEEDED,
      requestKey,
      resourceType,
      resources: payload
    })
  } catch (err) {
    yield put({
      type: actionTypes.READ_RESOURCES_FAILED,
      requestKey,
      resourceType,
      requestProperties: {
        error: err
      }
    })
  }
}

export function* watcher() {
  yield all([takeEvery(types.GET_SCANNER_CATEGORIES, getScannerCategories)])
}

import { takeEvery, put, select } from 'redux-saga/effects'
import { error } from 'react-notification-system-redux'

import ActionCableService from 'lib/services/actionCable'
import APIAction from 'lib/utils/APIAction'
import getResourceActions from 'lib/utils/getResourceActions'
import { tokenSelector } from 'lib/modules/user/selectors'
import { ReadReportCategories } from './actions'
import { BASE_CHANNEL } from 'constants/actionCable'
import TYPES from './actionTypes'

function* readReportCategories(action: ReadReportCategories) {
  const {
    payload: { resourceType, requestKey }
  } = action
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'READ',
    resourceType,
    requestKey
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      const {
        payload
      }: { payload: Array<Data.ReportCategory> } = yield ActionCableService.sendMessage({
        channelName: BASE_CHANNEL,
        type: requestKey,
        payload: {}
      })

      return { data: payload }
    },
    *success(data) {
      yield put(onSuccessAction(data))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось получить категории для отчёта.'
        })
      )
    }
  })
}

export function* watcher() {
  yield takeEvery(TYPES.READ_REPORT_CATEGORIES, readReportCategories)
}

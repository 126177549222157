import { Reducer } from 'redux'

import { Actions } from './actions'
import { Types, SortIndex, SortField, SORT_DIRECTION } from './'

export interface State {
  [key: string]: SortIndex
}

const initialState: State = {}

export const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case Types.INIT_INDEX: {
      const { payload, meta } = action
      const parsedFields = payload.fields.filter(
        item =>
          item.directions[SORT_DIRECTION.ASCENDING] || item.directions[SORT_DIRECTION.DESCENDING]
      )
      const defaultCurrentField = parsedFields[0]
      const defaultCurrentDirection = defaultCurrentField.directions[SORT_DIRECTION.ASCENDING]
        ? SORT_DIRECTION.ASCENDING
        : SORT_DIRECTION.DESCENDING

      return {
        ...state,
        [action.meta.name]: {
          fields: parsedFields,
          currentDirection: defaultCurrentDirection,
          currentField: defaultCurrentField,
          search: {
            enabled: payload.searchEnabled,
            value: payload.searchEnabled ? '' : undefined,
            fields: payload.searchEnabled ? payload.searchFields : undefined
          }
        }
      }
    }
    case Types.DESTROY_INDEX: {
      return { ...state, [action.meta.name]: undefined }
    }
    case Types.CHANGE_SORT_FIELD: {
      const {
        payload: { field, direction },
        meta: { name }
      } = action
      return {
        ...state,
        [name]: { ...state[name], currentField: field, currentDirection: direction }
      }
    }
    case Types.CHANGE_SEARCH_VALUE: {
      const {
        payload: { value },
        meta: { name }
      } = action

      return {
        ...state,
        [name]: { ...state[name], search: { ...state[name].search, value } }
      }
    }
    default:
      return state
  }
}

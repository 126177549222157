const styles = (theme: App.Theme) => ({
  container: {
    flex: '0 0 auto',
    ...theme.utils.flexContainer({
      direction: 'row',
      alignItems: 'center',
      justify: 'flex-start'
    }),
    width: '100%',
    boxSizing: 'border-box'
    // '& + & > $fields, &:first-child': {
    // borderTop: 'none'
    // }
  },
  setContainer: {
    paddingRight: '0.5rem'
  },
  fields: {
    flex: '1 1 auto',
    ...theme.utils.flexContainer({
      direction: 'row',
      alignItems: 'flex-start',
      justify: 'flex-start'
    }),
    width: '100%',
    padding: '0.5rem',
    backgroundColor: '#FFFFFF',
    borderBottom: '1px solid #EBEBEB',
    boxSizing: 'border-box',

    '& > *:nth-last-child(n+2)': {
      marginRight: '0.5rem'
    }
  },
  fieldSelect: {
    flex: '1 1 44%',
    maxWidth: '50%'
  },
  fieldDateRange: {
    flex: '1 1 44%',
    maxWidth: '50%'
  },
  deleteButton: {
    display: 'inline-flex',
    flexShrink: 0,
    backgroundColor: 'rgba(255, 59, 48, 0.1) !important',
    borderColor: 'rgba(255, 59, 48, 0.1) !important',
    '&:hover': {
      backgroundColor: 'rgba(255, 59, 48, 0.1)',
      borderColor: 'rgba(255, 59, 48, 0.1)',
      '& > svg': {
        stroke: `${theme.colors.red} !important`
      }
    },
    '& > svg': {
      stroke: theme.colors.red
    }
  }
})

export default styles

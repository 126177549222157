import { COMPARE_OPERATORS, FIELD_TYPES } from 'constants/filter'

export const DELIMITER = ':'

export function fromPanelsToBase(
  filters: Array<PanelConfig.TemplateFilter.Expression>
): Array<PanelConfig.TemplateFilter.Entity> {
  return filters.map(filter => {
    const { cube_id, field, type, values, set_actual_date = false } = filter
    const fieldValue = `${cube_id}${DELIMITER}${field}`

    return {
      condition: COMPARE_OPERATORS.EQUAL,
      field1: {
        type: FIELD_TYPES.FIELD,
        value: fieldValue
      },
      field2: {
        type,
        value: values,
        set_actual_date
      }
    } as Partial<PanelConfig.TemplateFilter.Entity>
  })
}

export function fromBaseToPanels({
  filters,
  categories
}: {
  filters: Array<PanelConfig.TemplateFilter.Entity>
  categories: Array<Data.ChartCategory>
}): Array<PanelConfig.TemplateFilter.Expression> {
  return filters.map(filter => {
    const { field1, field2 } = filter
    const [cubeID, dimensionID] = (field1.value as string).split(DELIMITER)
    const category = categories.find(category => category.id === cubeID)

    if (!category) {
      throw Error(`Unexpected filter category (${cubeID}) or is absent dimension`)
    }

    const dimension = category.dimensions.find(item => item.id === dimensionID)

    if (!dimension) {
      throw Error(`Unexpected filter type (${dimensionID}) or is absent dimension`)
    }

    return {
      cube_id: cubeID,
      field: dimensionID,
      field_name: dimension.name,
      type: dimension.filter_type,
      values: field2.value as Array<string | number | boolean>,
      set_actual_date: field2.set_actual_date
    }
  })
}

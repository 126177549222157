import { createSelector } from 'reselect'
import { getResources, ResourceRequest } from 'redux-resource'

import getResourceState from 'lib/utils/getResourceState'
import { PANELS } from 'constants/resources'

export const getPanelsResources = (
  state: App.State | OpenApp.State
): { [id: string]: Data.Panel } => state.panels.resources

const getPanelByKeyRequest = (state: App.State | OpenApp.State): ResourceRequest =>
  state.panels.requests[PANELS.REQUESTS.GET_PANEL_BY_LINK_KEY]

export const getPanelByKey = createSelector(
  [getPanelsResources, getPanelByKeyRequest],
  function(panels, request) {
    if (request && request.ids) {
      return panels[request.ids[0]]
    }

    return undefined
  }
)

export const deletePanelStatusSelector = (state: App.State) =>
  getResourceState(state, `${PANELS.NAME}.requests.${PANELS.REQUESTS.DELETE_PANEL}.status`)

export const getDesktopPanels = (state: App.State) =>
  getResources(state.panels, PANELS.LISTS.DESKTOP)

export const getAllPanels = createSelector(
  [getPanelsResources],
  function(panels) {
    return Object.values(panels)
  }
)

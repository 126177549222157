import { takeEvery, takeLatest, put, select, all, call } from 'redux-saga/effects'
import { actionTypes, getResources } from 'redux-resource'

import ActionCableService from 'lib/services/actionCable'
import fetchAPI, { FetchOptions } from 'lib/services/fetchAPI'
import APIAction from 'lib/utils/APIAction'
import getResourceActions from 'lib/utils/getResourceActions'
import { GetGrbsOrganisations, SearchOrganisations, GetOrganisations } from './actions'
import { tokenSelector } from 'lib/modules/user/selectors'
import { grbsSelector } from './selectors'
import { BASE_CHANNEL } from 'constants/actionCable'
import { METHOD, ENDPOINT } from 'constants/api'
import { ORGANISATIONS } from 'constants/resources'
import { Types } from './actionTypes'

function* getGrbsOrganisations(action: GetGrbsOrganisations) {
  const { requestKey, resourceType, requestProperties } = action.payload
  const list = `grbs_${requestProperties.grbs_id.toString()}`

  try {
    yield put({
      type: actionTypes.READ_RESOURCES_PENDING,
      requestKey,
      resourceType,
      requestProperties,
      list
    })

    const { payload } = yield ActionCableService.sendMessage<Data.Organisation[]>({
      channelName: BASE_CHANNEL,
      type: ORGANISATIONS.REQUESTS.GET_GRBS_ORGANISATIONS,
      payload: { grbs_id: requestProperties.grbs_id }
    })

    yield put({
      type: actionTypes.READ_RESOURCES_SUCCEEDED,
      requestKey,
      resourceType,
      requestProperties,
      list,
      resources: payload.map(item => ({ id: item.organisation_id, ...item }))
    })
  } catch (err) {
    yield put({
      type: actionTypes.READ_RESOURCES_FAILED,
      requestKey,
      resourceType,
      list,
      requestProperties: { ...requestProperties, error: err }
    })
  }
}

function* searchOrganisations(action: SearchOrganisations) {
  const grbs: Data.OrganisationShort[] = yield select(grbsSelector)

  const {
    requestKey,
    requestProperties: { search },
    resourceType,
    list
  } = action.payload

  try {
    yield put({
      type: actionTypes.READ_RESOURCES_PENDING,
      resourceType,
      requestKey,
      list
    })

    const {
      payload
    }: {
      payload: {
        [key: string]: Data.Organisation[]
      }
    } = yield ActionCableService.sendMessage({
      channelName: BASE_CHANNEL,
      type: requestKey,
      payload: { search },
      rejectWithSameType: true
    })

    const meta = { grbs: {}, organisations: {} }
    Object.keys(payload).forEach(key => {
      const grb = grbs.find(i => i.title === key)
      if (!grb) return

      meta.grbs[grb.id] = { searchChildrenIds: payload[key].map(i => i.organisation_id) }
      payload[key].forEach(org => (meta[org.organisation_id] = { searchParentId: grb.id }))
    })

    yield put({
      type: actionTypes.UPDATE_RESOURCES,
      meta,
      mergeMeta: { grbs: false, organisations: false }
    })

    const res = []
    Object.keys(payload).forEach(key => {
      payload[key].forEach(item =>
        res.push(!item.id ? { ...item, id: item.organisation_id } : item)
      )
    })

    yield put({
      type: actionTypes.READ_RESOURCES_SUCCEEDED,
      resourceType,
      requestKey,
      list,
      resources: res,
      mergeListIds: false
    })
  } catch (err) {
    yield put({
      type: actionTypes.READ_RESOURCES_FAILED,
      resourceType,
      requestKey,
      list,
      requestProperties: { search, error: err }
    })
  }
}

function* getOrganisations(action: GetOrganisations) {
  const { requestKey, requestProperties = {}, resourceType, list } = action.payload
  const token = yield select(tokenSelector)
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'READ',
    resourceType,
    requestKey,
    requestProperties,
    list
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        method: METHOD.GET,
        endpoint: ENDPOINT.ORGANISATIONS,
        token,
        params: { ...requestProperties }
      })
    },
    *success(data) {
      yield put(onSuccessAction(data))
    },
    *fail(errors) {
      yield put(onFailAction(errors))
    }
  })
}

export function* watcher() {
  yield all([
    takeEvery(Types.GET_GRBS_ORGANISATIONS, getGrbsOrganisations),
    takeLatest(Types.SEARCH_ORGANISATIONS, searchOrganisations),
    takeEvery(Types.GET_ORGANISATIONS, getOrganisations)
  ])
}

import { Action } from 'redux'
import types from './types'

export interface ReadFpd extends Action<types.READ_FPD> {
  payload: {
    loadAll?: boolean
  }
}
export interface ReadFpdLoading extends Action<types.READ_FPD_LOADING> {
  payload: {
    loadAll?: boolean
  }
}
export interface ReadFpdFailure extends Action<types.READ_FPD_FAILURE> {
  payload: {
    loadAll?: boolean
  }
}
export interface ReadFpdSuccess extends Action<types.READ_FPD_SUCCESS> {
  payload: {
    data: Data.FPDLayer[]
    isList: boolean
  }
}

export type ActionTypes = ReadFpd | ReadFpdLoading | ReadFpdFailure | ReadFpdSuccess

export const readFpd: (loadAll?: boolean) => ReadFpd = loadAll => ({
  type: types.READ_FPD,
  payload: {
    loadAll
  }
})

export const readFpdLoading: (loadAll?: boolean) => ReadFpdLoading = loadAll => ({
  type: types.READ_FPD_LOADING,
  payload: {
    loadAll
  }
})

export const readFpdFailure: (loadAll?: boolean) => ReadFpdFailure = loadAll => ({
  type: types.READ_FPD_FAILURE,
  payload: {
    loadAll
  }
})

export const readFpdSuccess: (layersData: {
  data: Data.FPDLayer[]
  isList: boolean
}) => ReadFpdSuccess = layersData => ({
  type: types.READ_FPD_SUCCESS,
  payload: layersData
})

import React, { Component, ChangeEventHandler } from 'react'
import { compose } from 'recompose'
import injectStyles, { JSSProps } from 'react-jss'
import { Dialog } from '@mui/material'
import { DialogTitle } from '@mui/material'

import styles from './styles'

interface OuterProps {
  open: boolean
  roundingValue: number
  onChange: (value: number) => void
  onClose: () => void
}

interface Props extends OuterProps, JSSProps<typeof styles> {}
interface State {
  value: number
}

class RoundingModal extends Component<Props, State> {
  timer: NodeJS.Timeout

  constructor(props: Props) {
    super(props)

    this.state = {
      value: props.roundingValue
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { roundingValue } = this.props

    if (roundingValue !== prevProps.roundingValue && roundingValue !== this.state.value) {
      this.setState({
        value: roundingValue
      })
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer)
      delete this.timer
    }
  }

  onChange: ChangeEventHandler<HTMLInputElement> = event => {
    const { onChange } = this.props

    if (this.timer) {
      clearTimeout(this.timer)
      delete this.timer
    }

    event.persist()

    const value = Number(event.target.value)

    this.setState({
      value
    })
    this.timer = setTimeout(() => onChange(value), 400)
  }

  render() {
    const { classes, open, onClose } = this.props

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle style={{ padding: '0.625rem' }}>Количество знаков после запятой</DialogTitle>
        <div className={classes.container}>
          <input
            className={classes.input}
            type="number"
            value={this.state.value}
            onChange={this.onChange}
            min={0}
          />
        </div>
      </Dialog>
    )
  }
}

export default compose<Props, OuterProps>(injectStyles(styles))(RoundingModal)

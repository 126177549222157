import React, { SFC } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import { formatDistance } from 'date-fns'
import ruLocale from 'date-fns/locale/ru'

import styles from './styles'

export interface OuterProps {
  notification: Data.Notification
  push: (path: string) => void
  readNotification: (id: number) => void
}
interface Props extends JSSProps<typeof styles>, OuterProps {}

const Notification: SFC<Props> = ({ classes, notification, push, readNotification }) => (
  <li className={classes.container}>
    <Link
      className={classes.link}
      to={notification.link}
      onClick={
        notification.link
          ? () => {
              if (notification.status !== 'read') {
                readNotification(notification.id)
              }
            }
          : undefined
      }
    >
      <span className={classes.date}>
        {formatDistance(Date.now(), notification.created_at * 1000, {
          locale: ruLocale,
          includeSeconds: true,
          addSuffix: false
        })}
      </span>
      <span className={classes.text}>{notification.text}</span>
    </Link>
  </li>
)

export default compose<Props, OuterProps>(injectStyles(styles))(Notification)

import { Reducer } from 'redux'

import types from './types'
import { ActionTypes } from './actions'
import { STATES } from 'constants/states'
import { cloneDeep } from 'lodash'

export interface State {
  readStatus: STATES
  showModal: boolean
  importFiles: Data.CovidImportFile[]
}

const initialState: State = {
  readStatus: STATES.IDLE,
  showModal: false,
  importFiles: []
}

export const reducer: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case types.READ_IMPORT_FILES:
      return { ...state, readStatus: STATES.IDLE }
    case types.READ_IMPORT_FILES_LOADING:
      return { ...state, readStatus: STATES.LOADING }
    case types.READ_IMPORT_FILES_FAILURE:
      return { ...state, readStatus: STATES.FAILED }
    case types.READ_IMPORT_FILES_SUCCESS:
      return { ...state, importFiles: action.payload, readStatus: STATES.SUCCEEDED }
    case types.UPDATE_IMPORT_FILE:
      return { ...state }
    case types.REPLACE_IMPORT_FILE_DATA:
      const importFiles = cloneDeep(state.importFiles)
      importFiles[importFiles.findIndex(el => el.id === action.payload.id)] = action.payload
      return { ...state, importFiles }
    case types.TOGGLE_SHOW:
      return { ...state, showModal: !state.showModal }
    default:
      return state
  }
}

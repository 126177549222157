import { createSelector } from 'reselect'

export const getState = (state: App.State) => state.sorting

export const getSortingIndex = (name: string) =>
  createSelector(
    getState,
    state => state[name]
  )
export const getCurrentSortingField = (name: string) =>
  createSelector(
    getState,
    state => state[name].currentField
  )
export const getCurrentSortingDirection = (name: string) =>
  createSelector(
    getState,
    state => state[name].currentDirection
  )
export const getSortingFields = (name: string) =>
  createSelector(
    getState,
    state => state[name].fields
  )
export const getSearchValue = (name: string) =>
  createSelector(
    getState,
    state => state[name].search.value
  )
export const getSearchFields = (name: string) =>
  createSelector(
    getState,
    state => state[name].search.fields
  )
export const isSearchEnabled = (name: string) =>
  createSelector(
    getState,
    state => state[name].search.enabled
  )

import { getStatus, RequestStatuses } from 'redux-resource'
import { STATES } from 'constants/states'

function getResourceState(
  state: AllAppsState,
  statusLocation: string,
  treatIdleAsPending?: boolean
): STATES {
  const resourceState = getStatus(state, statusLocation, treatIdleAsPending)

  if (resourceState.pending) {
    return STATES.LOADING
  } else if (resourceState.failed) {
    return STATES.FAILED
  } else if (resourceState.succeeded) {
    return STATES.SUCCEEDED
  }

  return STATES.IDLE
}

export default getResourceState

const styles = (theme: App.Theme) => ({
  modal: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '48rem',
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.15)',
    borderRadius: '.875rem'
  },
  modalHeader: {
    display: 'flex',
    padding: '1.25rem',
    alignItems: 'center'
  },
  modalTitle: {
    margin: '0 18px',
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: 1.2
  },
  modalContent: {
    padding: '1.25rem'
  },
  modalField: {
    '& + &': {
      marginTop: '1.25rem'
    }
  },
  modalFieldRow: {
    composes: '$modalField',
    ...theme.utils.flexContainer({
      direction: 'row',
      alignItems: 'center',
      justify: 'space-between'
    })
  },
  signatureTextarea: {
    marginTop: '0.5rem',
    '& textarea': {
      height: '30vh',
      resize: 'none'
    }
  },
  modalFieldLabel: {
    fontSize: '0.875rem',
    lineHeight: 1.2
  },
  modalFieldInput: {
    width: '100%',
    marginTop: '0.625rem'
  },
  modalFooter: {
    ...theme.utils.flexContainer({ direction: 'row', justify: 'flex-start', alignItems: 'center' }),
    padding: '1.25rem',
    '& > *:nth-last-child(n+2)': {
      marginRight: '0.625rem'
    }
  },
  modalFooterButton: {
    '&:not(:last-child)': {
      marginRight: '0.5rem'
    }
  }
})

export default styles

import { takeEvery, put, select, call, all } from 'redux-saga/effects'
import { success, error } from 'react-notification-system-redux'
import { startSubmit, stopSubmit } from 'redux-form'
import { AxiosError } from 'axios'

import fetchAPI from 'lib/services/fetchAPI'
import { tokenSelector } from 'lib/modules/user/selectors'
import { ENDPOINT, METHOD } from 'constants/api'
import { DESKTOP_FORM } from 'constants/forms'
import {
  desktopSuccess,
  desktopFailure,
  desktopUpdateSuccess,
  desktopUpdateFailure,
  DesktopRequest,
  DesktopUpdateRequest
} from './actions'
import types from './actionTypes'

function* getDesktopPanelSaga(action: DesktopRequest) {
  const token = yield select(tokenSelector)

  const requestParams = {
    token,
    endpoint: ENDPOINT.DESKTOP
  }

  if (action.payload) {
    // @ts-expect-error
    requestParams.params = {
      user_ids: action.payload
    }
  }

  try {
    const { data: buttons } = yield call(fetchAPI, requestParams)

    yield put(desktopSuccess(buttons))
  } catch (exception) {
    const errors = {
      _error: exception
    }

    if (exception.response) {
      errors._error = (exception as AxiosError).response.data.error
    }

    yield put(desktopFailure(errors))

    yield put(
      error({
        title: 'Не удалось получить данные.'
      })
    )
  }
}

function* updateDesktopPanelSaga(action: DesktopUpdateRequest) {
  const token = yield select(tokenSelector)

  const { panelIds, mainId } = action.payload

  yield put(startSubmit(DESKTOP_FORM.form))

  try {
    const { data: panel } = yield call(fetchAPI, {
      token,
      method: METHOD.PATCH,
      endpoint: `${ENDPOINT.DESKTOP}/${mainId}`,
      body: {
        panel_ids: panelIds
      }
    })

    yield put(desktopUpdateSuccess(panel))
    yield put(stopSubmit(DESKTOP_FORM.form))

    yield put(
      success({
        title: 'Рабочий стол успешно обновлен.'
      })
    )
  } catch (exception) {
    const errors = {
      _error: exception
    }

    if (exception.response) {
      errors._error = (exception as AxiosError).response.data.error
    }

    yield put(desktopUpdateFailure(errors))
    yield put(stopSubmit(DESKTOP_FORM.form, errors))

    yield put(
      error({
        title: 'Не удалось обновить рабочий стол.'
      })
    )
  }
}

export function* watcher() {
  yield all([
    takeEvery(types.DESKTOP_REQUEST, getDesktopPanelSaga),
    takeEvery(types.DESKTOP_UPDATE_REQUEST, updateDesktopPanelSaga)
  ])
}

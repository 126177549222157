import { Reducer } from 'redux'

import { COMMON_MANAGMENT } from 'constants/resources'
import { STATES } from 'constants/states'
import TYPES from './actionTypes'
import { Actions } from './actions'

export interface Info {
  offset?: number
  limit: number
  isEnded: boolean
  search?: string
  searchName?: string
  additionalParams?: any
  list: string
  responseData: Array<{}> | Object
  filterName?: string
  getParams?: (params) => Object
  sorting?: {
    field: string
    name: string
    order: 'desc' | 'asc'
  }
  loadingButton: {
    text: string
    state: STATES
  }
  tab?: {
    list: string | boolean
    name: string
  }
  state: STATES
}

export interface State {
  [resourceName: string]: Info
}

export const initialManagerState: Info = {
  limit: 20,
  isEnded: true,
  list: COMMON_MANAGMENT.LISTS.ALL,
  responseData: {},
  loadingButton: {
    text: 'Загрузить еще',
    state: STATES.IDLE
  },
  state: STATES.IDLE
}
const initialState: State = {}

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.INIT_MANAGER_STATE: {
      const { resourceName, initialState = {} } = action.payload

      return {
        ...state,
        [resourceName]: {
          ...initialManagerState,
          ...initialState
        }
      }
    }
    case TYPES.CHANGE_MANAGER_STATE: {
      const { resourceName, state: mergeState = {} } = action.payload

      return {
        ...state,
        [resourceName]: {
          ...state[resourceName],
          ...mergeState
        }
      }
    }
    case TYPES.READ_MORE_RESOURCES: {
      const { resourceName } = action.payload
      const { offset = 0, limit } = state[resourceName]

      return {
        ...state,
        [resourceName]: {
          ...state[resourceName],
          offset: offset + limit
        }
      }
    }
    case TYPES.CHANGE_REQUEST_STATE: {
      const { resourceName, state: requestState, isEnded, responseData } = action.payload

      return {
        ...state,
        [resourceName]: {
          ...state[resourceName],
          isEnded,
          state: requestState,
          responseData
        }
      }
    }
    case TYPES.CHANGE_LOADING_BUTTON: {
      const { resourceName, loadingButton } = action.payload

      return {
        ...state,
        [resourceName]: {
          ...state[resourceName],
          loadingButton
        }
      }
    }
    case TYPES.CHANGE_SORTING: {
      const { resourceName, sorting } = action.payload

      return {
        ...state,
        [resourceName]: {
          ...state[resourceName],
          offset: 0,
          sorting
        }
      }
    }
    case TYPES.CHANGE_FILTER: {
      const { resourceName, tab } = action.payload

      return {
        ...state,
        [resourceName]: {
          ...state[resourceName],
          offset: 0,
          tab
        }
      }
    }
    case TYPES.CHANGE_SEARCH: {
      const { resourceName, search } = action.payload
      const { tab, ...resourceState } = state[resourceName]

      let list = COMMON_MANAGMENT.LISTS.ALL

      if (search) {
        list = COMMON_MANAGMENT.LISTS.SEARCH
      } else if (tab) {
        list = tab.list
      }

      return {
        ...state,
        [resourceName]: {
          ...resourceState,
          offset: 0,
          list,
          tab,
          search: search ? search : undefined
        }
      }
    }
    case TYPES.DESTROY_MANAGER_STATE: {
      const { resourceName } = action.payload

      return Object.keys(state).reduce((newState, resourceManagerName) => {
        if (resourceManagerName !== resourceName) {
          newState[resourceManagerName] = state[resourceManagerName]
        }

        return newState
      }, {})
    }
    default:
      return state
  }
}

export default reducer

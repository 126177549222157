// @ts-nocheck
import React, { HTMLAttributes, useEffect, useState } from 'react'
import injectStyles, { JSSProps, withTheme } from 'react-jss'
import {
  useDrop,
} from 'react-dnd'
import classNames from 'classnames'

import uniqueID from 'lib/utils/uniqueID'
import {
  PLACE,
  FORM as AGGREGATION_FORM,
  TYPE as AGGREGATION_TYPE,
  MATH_FUNCTION,
  SCANNER_SET_ID
} from 'constants/aggregation'
import { TYPE as SET_TYPE, TYPE } from 'constants/set'
import { OPTION_TYPES } from 'constants/filter'
import { CHART_TYPES } from 'constants/dataView'
import AggregationItem from '../Item'
import AggregationGroup from '../Group'
import styles from './styles'
import { DragAndDropTypes } from '../../dndTypes'
import { compose } from 'recompose'

enum ITEM_TYPE {
  AGGREGATION,
  GROUP
}
export interface OuterProps extends HTMLAttributes<HTMLDivElement> {
  aggregations: Array<Data.AggregationEntity>
  groups: Array<Data.AggregationGroup>
  selectedGroupsIDs: Array<Data.AggregationGroup['id']>
  fieldName: string
  place: PLACE
  getCubeNameById?: (cube_id: string) => string
  aggregationType: AGGREGATION_TYPE | undefined
  // Exclude certain types of fields
  excludedTypes?: Array<OPTION_TYPES>
  // Limit amount of items in this zone
  limitItems?: number
  elementType: Data.Chart['element_type'] | undefined
  onAddAggregation: (fieldName: string, aggregation: Data.AggregationEntity) => void
  onRemoveAggregation: (aggregation: Data.AggregationEntity) => void
  onRemoveGroup: (group: Data.AggregationGroup) => void
  onToggleGroup: (groupID: Data.AggregationGroup['id']) => void
}
interface DragCollectProps {
  isOver: boolean
  isOverOnZone: boolean
  canDrop: Props
}
interface Props extends JSSProps<typeof styles>, DragCollectProps, OuterProps { }
interface State {
  items: Array<
    | Data.AggregationEntity & { itemType: ITEM_TYPE }
    | Data.AggregationGroup & { index: number; itemType: ITEM_TYPE }
  >
}

const DROP_TARGET: {
  name: string
  source: Props
  collect: DragCollectProps & Props
} = {
  name: DragAndDropTypes.ElementIndicatorItem,
  canDrop(props: Props, item: { set: Data.Dimension | Data.Measure, type: keyof typeof TYPE }) {
    const { aggregations, aggregationType, elementType, excludedTypes = [], limitItems } = props
    
    const isSetAlreadyUsed = aggregations.some(aggregation =>
      aggregation.args.some(
        aggregationSet =>
          aggregationSet.id === item.set.id && aggregationSet.cube_id === item.set.cube_id
      )
    )

    if (elementType && elementType === CHART_TYPES.SCANNER) {
      return (
        !aggregations.length &&
        ((aggregationType === AGGREGATION_TYPE.DIMENSION &&
          item.set.id === SCANNER_SET_ID.DIMENSION) ||
          (aggregationType === AGGREGATION_TYPE.MEASURE &&
            item.set.id === SCANNER_SET_ID.MEASURE))
      )
    }

    if (
      (!aggregationType ||
        (item.type === SET_TYPE.DIMENSION &&
          // We determine that we do not already have the same dimension
          (!isSetAlreadyUsed ||
            // Or element type is the table (we could put the dimension in any zone)
            (elementType && elementType === CHART_TYPES.TABLE))) ||
        (item.type === SET_TYPE.MEASURE && aggregationType === AGGREGATION_TYPE.MEASURE)) &&
      (!excludedTypes.length || !excludedTypes.includes(item.set.filter_type)) &&
      (!limitItems || aggregations.length <= limitItems)
    ) {
      return true
    }

    return false
  },
  drop(props: Props, item, monitor) {
    const { fieldName, place, aggregationType, elementType, onAddAggregation } = props
    const isOverOnZone = monitor.isOver({ shallow: true })

    if (isOverOnZone) {
      const set: Data.AggregationMeasure | Data.AggregationDimension = {
        ...item.set,
        date_reg_exp: item.set.filter_type === OPTION_TYPES.TIME ? '%Y-%m' : undefined
      }

      const aggregation: Data.AggregationEntity | undefined = {
        id: uniqueID('aggregation-'),
        agg: AGGREGATION_FORM.NONE,
        place,
        args: [set]
      }

      switch (aggregationType) {
        case AGGREGATION_TYPE.MEASURE: {
          if (item.type === SET_TYPE.DIMENSION) {
            aggregation.agg =
              elementType && elementType === CHART_TYPES.TABLE
                ? AGGREGATION_FORM.NONE
                : AGGREGATION_FORM.COUNT
          } else {
            aggregation.agg = AGGREGATION_FORM.SUM
          }
          break
        }
        case AGGREGATION_TYPE.DIMENSION: {
          aggregation.agg = AGGREGATION_FORM.NONE
          break
        }
        default:
          aggregation.agg =
            item.type === SET_TYPE.DIMENSION ? AGGREGATION_FORM.NONE : AGGREGATION_FORM.SUM
      }

      if (aggregation.agg !== AGGREGATION_FORM.NONE) {
        aggregation.math_func = MATH_FUNCTION.NONE
        aggregation.normalize = false
        aggregation.rounding = 2
      }

      if (aggregation) {
        if (aggregation.args[0].filter_type === 'geo_poly') {
          aggregation.as_title = true
        }
        onAddAggregation(fieldName, aggregation)
      }
    }

    return props
  },
  collect(monitor) {
    return {
      isOver: monitor.isOver(),
      isOverOnZone: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop()
    }
  }
}

const DropZone = (props) => {
  const [dropZoneItems, setDropZoneItems] = useState([])

  useEffect(() => {
    const { aggregations = [], groups } = props
    const items = []

    let groupIndex = 0

    aggregations.forEach(aggregation => {
      const groupWithAggregation = groups.find(group =>
        group.aggregations.some(groupAggregation => groupAggregation.id === aggregation.id)
      )

      if (groupWithAggregation) {
        if (groupWithAggregation.aggregations[0].id === aggregation.id) {
          items.push({
            ...groupWithAggregation,
            index: groupIndex,
            itemType: ITEM_TYPE.GROUP
          })

          groupIndex += 1
        }
      } else {
        items.push({
          ...aggregation,
          itemType: ITEM_TYPE.AGGREGATION
        })
      }
    })

    setDropZoneItems(items)

  }, [props.aggregations, props.groups])

  const {
    classes,
    className,
    selectedGroupsIDs,
    elementType,
    theme,
    onRemoveAggregation,
    onRemoveGroup,
    onToggleGroup,
    getCubeNameById
  } = props

  const [{ isOver, isOverOnZone, canDrop }, drop] = useDrop(
    () => ({
      accept: DragAndDropTypes.ElementIndicatorItem,
      collect: DROP_TARGET.collect,
      canDrop: (item) => DROP_TARGET.canDrop(props, item),
      drop: (item, monitor) => DROP_TARGET.drop(props, item, monitor)
    }), [props])

  return <div
    ref={drop}
    className={classNames(classes.container, className)}
    style={{
      borderColor:
        canDrop && (!isOver || (isOver && isOverOnZone)) ? theme.colors.green : '#E0E6EB'
    }}
  >
    {dropZoneItems.length ? (
      dropZoneItems.map(item => {
        switch (item.itemType) {
          case ITEM_TYPE.AGGREGATION: {
            const { itemType, ...aggregation } = item as Data.AggregationEntity & {
              itemType: ITEM_TYPE
            }

            return (
              <AggregationItem
                key={aggregation.id}
                getCubeNameById={getCubeNameById}
                aggregation={aggregation}
                elementType={elementType}
                onRemove={onRemoveAggregation}
              />
            )
          }
          case ITEM_TYPE.GROUP: {
            const { itemType, index, ...group } = item as Data.AggregationGroup & {
              index: number
              itemType: ITEM_TYPE
            }

            return (
              <AggregationGroup
                key={group.id}
                group={group}
                index={index}
                isSelected={selectedGroupsIDs.includes(group.id)}
                onRemove={onRemoveGroup}
                onToggleGroup={onToggleGroup}
              />
            )
          }
          default:
            return null
        }
      })
    ) : (
      <div className={classes.placeholder}>
        <span className={classes.placeholderText}>
          Перенесите показатели, по которым необходимо построить визуализацию
        </span>
      </div>
    )}
  </div>
}

export default compose<Props, OuterProps>(
  withTheme,
  injectStyles(styles),
)(DropZone)

import { replace } from 'connected-react-router'
import { error, success } from 'react-notification-system-redux'
import {
  getFormValues,
  setSubmitFailed,
  setSubmitSucceeded,
  startSubmit,
  stopSubmit
} from 'redux-form'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { ENDPOINT, METHOD } from 'constants/api'
import { PANEL_FORM } from 'constants/forms'
import { getChartCategories } from 'lib/modules/chartCategories/selectors'
import { getPanel } from 'lib/modules/panelEditor/selectors'
import { getAllPanels, getPanelsResources } from 'lib/modules/panels/selectors'
import { tokenSelector } from 'lib/modules/user/selectors'
import fetchAPI from 'lib/services/fetchAPI'
import APIAction from 'lib/utils/APIAction'
import { fromBaseToPanels } from 'lib/utils/fomatFilters'
import getResourceActions from 'lib/utils/getResourceActions'
import { FormValues } from 'pages/closed/PanelEditor'
import TYPES from './actionTypes'
import {
  AddFavorite,
  CopyPanel,
  CreatePanel,
  DeletePanel,
  ReadPanelByKey,
  ReadPanels,
  RemoveFavorite,
  UpdatePanel
} from './actions'

function* readPanels(action: ReadPanels) {
  const token = yield select(tokenSelector)
  const { resourceType, requestKey, options, list } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'READ',
    resourceType,
    requestKey,
    list
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      const { data } = yield call(fetchAPI, {
        token,
        method: METHOD.GET,
        endpoint: ENDPOINT.PANELS,
        params: options
      })

      return { data }
    },
    *success(data) {
      yield put(onSuccessAction(data))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось получить данные.'
        })
      )
    }
  })
}

function* createPanel(action: CreatePanel) {
  const token = yield select(tokenSelector)
  const { creator_name, created_at, ...panel }: Data.Panel = yield select(getPanel)
  const categories: Array<Data.ChartCategory> = yield select(getChartCategories)

  const { requestKey, resourceType } = action.payload
  const {
    name: title,
    accessInClosePart,
    published_in_open_part,
    on_main,
    is_archived,
    mobile_access,
    user_access_array,
    last_updated,
    last_updated_format,
    show_last_updated,
    auto_last_updated,
    settings,
    templates
  }: FormValues = yield select(getFormValues(PANEL_FORM.form))
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'CREATE',
    resourceType,
    requestKey
  })

  yield APIAction({
    *request() {
      yield put(startSubmit(PANEL_FORM.form))
      yield put(onRequestAction())

      const { data }: { data: Data.Panel } = yield call(fetchAPI, {
        token,
        method: METHOD.POST,
        endpoint: ENDPOINT.PANELS,
        body: {
          info_panel: {
            ...panel,
            is_archived,
            settings,
            published_in_open_part: !is_archived && published_in_open_part,
            on_main,
            access_all: accessInClosePart && user_access_array.includes('access_all'),
            mobile_access,
            last_updated,
            last_updated_format,
            show_last_updated,
            auto_last_updated,
            user_access_array:
              (accessInClosePart && user_access_array.filter(id => id !== 'access_all')) || [],
            link_key: '',
            title,
            templates: panel.templates.map((template, index) => {
              const templateField = templates[template.id]
              const filters =
                templateField && templateField.conditions
                  ? fromBaseToPanels({ filters: templates[template.id].conditions, categories })
                  : []

              return {
                ...template,
                cells: template.cells.map(cell => ({
                  ...cell,
                  elements: cell.elements.map(element => {
                    const elementSettings =
                      templateField && templateField.elements && templateField.elements[element.id]

                    if (elementSettings) {
                      return {
                        ...element,
                        ...elementSettings
                      }
                    }

                    return element
                  })
                })),
                filters
              }
            })
          }
        }
      })

      return { data: [data] }
    },
    *success(data: Array<Data.Panel>) {
      yield put(onSuccessAction(data))
      yield put(stopSubmit(PANEL_FORM.form))
      yield put(setSubmitSucceeded(PANEL_FORM.form))
      yield put(replace(`/panels/${data[0].id}`))
    },
    *fail(errors) {
      yield put(onFailAction(errors))
      yield put(stopSubmit(PANEL_FORM.form, errors))
      yield put(setSubmitFailed(PANEL_FORM.form))

      yield put(
        error({
          title: 'Не удалось создать панель.'
        })
      )
    }
  })
}

function* updatePanel(action: UpdatePanel) {
  const token = yield select(tokenSelector)
  const { creator_name, created_at, ...panel }: Data.Panel = yield select(getPanel)
  const categories: Array<Data.ChartCategory> = yield select(getChartCategories)

  const { requestKey, resourceType } = action.payload
  const {
    name: title,
    accessInClosePart,
    is_archived,
    published_in_open_part,
    on_main,
    mobile_access,
    last_updated,
    last_updated_format,
    show_last_updated,
    auto_last_updated,
    user_access_array,
    settings,
    templates
  }: FormValues = yield select(getFormValues(PANEL_FORM.form))

  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'UPDATE',
    resourceType,
    requestKey
  })

  yield APIAction({
    *request() {
      yield put(startSubmit(PANEL_FORM.form))
      yield put(onRequestAction())

      const { data }: { data: Data.Panel } = yield call(fetchAPI, {
        token,
        method: METHOD.PUT,
        endpoint: ENDPOINT.PANELS,
        path: panel.id.toString(),
        body: {
          info_panel: {
            ...panel,
            settings,
            is_archived,
            published_in_open_part: !is_archived && published_in_open_part,
            on_main,
            access_all: accessInClosePart && user_access_array.includes('access_all'),
            mobile_access,
            last_updated,
            last_updated_format,
            show_last_updated,
            auto_last_updated,
            user_access_array:
              (accessInClosePart && user_access_array.filter(id => id !== 'access_all')) || [],
            link_key: '',
            title,
            templates: panel.templates.map((template, index) => {
              const templateField = templates[template.id]
              const filters =
                templateField && templateField.conditions
                  ? fromBaseToPanels({ filters: templates[template.id].conditions, categories })
                  : []

              return {
                ...template,
                cells: template.cells.map(cell => ({
                  ...cell,
                  elements: cell.elements.map(element => {
                    const elementSettings =
                      templateField && templateField.elements && templateField.elements[element.id]

                    if (elementSettings) {
                      return {
                        ...element,
                        ...elementSettings
                      }
                    }

                    return element
                  })
                })),
                filters
              }
            })
          }
        }
      })

      return { data: [data] }
    },
    *success(data: Array<Data.Panel>) {
      yield put(onSuccessAction(data))
      yield put(stopSubmit(PANEL_FORM.form))
      yield put(setSubmitSucceeded(PANEL_FORM.form))

      yield put(
        success({
          title: 'Панель успешно обновлена.'
        })
      )
    },
    *fail(errors) {
      yield put(onFailAction(errors))
      yield put(stopSubmit(PANEL_FORM.form, errors))
      yield put(setSubmitFailed(PANEL_FORM.form))

      yield put(
        error({
          title: 'Не удалось обновить панель.'
        })
      )
    }
  })
}

function* deletePanel(action: DeletePanel) {
  const token = yield select(tokenSelector)

  const { requestKey, resourceType, requestProperties } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'DELETE',
    resourceType,
    requestKey,
    ids: [requestProperties.id],
    requestProperties
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        token,
        method: METHOD.DELETE,
        endpoint: ENDPOINT.PANELS,
        path: requestProperties.id
      })
    },
    *success(data) {
      yield put(onSuccessAction([requestProperties.id]))

      yield put(
        success({
          title: 'Панель успешно удалена.'
        })
      )
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось удалить панель.'
        })
      )
    }
  })
}

function* copyPanel(action: CopyPanel) {
  const token = yield select(tokenSelector)
  const {
    requestKey,
    resourceType,
    requestProperties: { id }
  } = action.payload

  const panels: Data.Panel[] = yield select(getAllPanels)
  const targetPanel = panels.find(item => +item.id === id)

  if (targetPanel) {
    const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
      actionType: 'CREATE',
      resourceType,
      requestKey,
      requestProperties: { id }
    })

    yield APIAction({
      *request() {
        yield put(onRequestAction())

        const { data }: { data: Data.Panel } = yield call(fetchAPI, {
          token,
          method: METHOD.POST,
          endpoint: ENDPOINT.PANELS,
          body: {
            info_panel: {
              ...targetPanel,
              title: `${targetPanel.title} копия`,
              creator_name: undefined,
              id: undefined
            }
          }
        })

        return { data: [data] }
      },
      *success(data: Array<Data.Panel>) {
        yield put(onSuccessAction(data))
        yield put(replace(`/panels/${data[0].id}/view`))
      },
      *fail(errors) {
        yield put(onFailAction(errors))

        yield put(
          error({
            title: 'Не удалось скопировать панель.'
          })
        )
      }
    })
  }
}

function* readPanelByKey(action: ReadPanelByKey) {
  const { resourceType, requestKey, id, key } = action.payload
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'READ',
    resourceType,
    requestKey
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        endpoint: ENDPOINT.PANELS,
        params: {
          ids: [id],
          link_key: key
        }
      })
    },
    *success(data) {
      yield put(onSuccessAction(data))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось получить данные.'
        })
      )
    }
  })
}

function* addFavorite(action: AddFavorite) {
  const token = yield select(tokenSelector)
  const { requestKey, resourceType, requestProperties } = action.payload
  const allPanels = yield select(getPanelsResources)
  const targetPanel = allPanels[requestProperties.id]

  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'UPDATE',
    resourceType,
    requestKey,
    requestProperties
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        method: METHOD.POST,
        endpoint: ENDPOINT.PANELS,
        path: `${requestProperties.id}/add_to_favorite`,
        token
      })
    },
    *success(data) {
      yield put(onSuccessAction([{ ...targetPanel, favorite: true }]))

      yield put(
        success({
          title: 'Добавлено в избранное'
        })
      )
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось добавить в избранное'
        })
      )
    }
  })
}

function* removeFavorite(action: RemoveFavorite) {
  const token = yield select(tokenSelector)
  const { requestKey, resourceType, requestProperties } = action.payload
  const allPanels = yield select(getPanelsResources)
  const targetPanel = allPanels[requestProperties.id]

  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'UPDATE',
    resourceType,
    requestKey,
    requestProperties
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        method: METHOD.POST,
        endpoint: ENDPOINT.PANELS,
        path: `${requestProperties.id}/remove_from_favorite`,
        token
      })
    },
    *success(data) {
      yield put(onSuccessAction([{ ...targetPanel, favorite: false }]))

      yield put(
        success({
          title: 'Удалено из избранного'
        })
      )
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось удалить из избранного'
        })
      )
    }
  })
}

export function* watcher() {
  yield all([
    takeEvery(TYPES.READ_PANELS, readPanels),
    takeEvery(TYPES.CREATE_PANEL, createPanel),
    takeEvery(TYPES.UPDATE_PANEL, updatePanel),
    takeEvery(TYPES.DELETE_PANEL, deletePanel),
    takeEvery(TYPES.COPY_PANEL, copyPanel),
    takeEvery(TYPES.GET_PANEL_BY_LINK_KEY, readPanelByKey),
    takeEvery(TYPES.ADD_FAVORITE, addFavorite),
    takeEvery(TYPES.REMOVE_FAVORITE, removeFavorite)
  ])
}

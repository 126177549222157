import React from 'react'

import { Option } from 'components/FormFields/Filters/components/Compare/components/Value'
import {
  AggregationPresetDay,
  AggregationPresetYear,
  AggregationPresetMonth
} from 'components/Icons'
import formatFilterDateByType from 'lib/utils/formatFilterDateByType'
import Eq from 'assets/images/sprite/operators/eq.svg'
import NotEq from 'assets/images/sprite/operators/not_eq.svg'
import More from 'assets/images/sprite/operators/more.svg'
import MoreOrEq from 'assets/images/sprite/operators/more_or_eq.svg'
import Less from 'assets/images/sprite/operators/less.svg'
import LessOrEq from 'assets/images/sprite/operators/less_or_eq.svg'
// import Empty from 'assets/images/sprite/operators/empty.svg'
// import NotEmpty from 'assets/images/sprite/operators/not_empty.svg'
import Include from 'assets/images/sprite/operators/include.svg'
import NotInclude from 'assets/images/sprite/operators/not_include.svg'

export const FILTERS_LINE_HEIGHT = '1.5rem'

export enum OPTION_TYPES {
  STRING = 'string',
  NUMBER = 'number',
  TIME = 'date',
  BOOLEAN = 'boolean',
  GEO = 'geo',
  GEO_LINE = 'geo_line',
  GEO_POLY = 'geo_poly',
  GEO_TITLE = 'geo_title',
  MULTI = 'multi',
  SCANNER = 'scanner'
}

const FIELD_TYPES = {
  FIELD: 'field',
  ENTER: 'user_enter',
  MULTI: 'multi'
}

const DATE_FIELD_TYPES = {
  FIELD: FIELD_TYPES.FIELD,
  DYNAMIC: 'dynamic',
  DATE: 'date',
  MULTI: FIELD_TYPES.MULTI
}

const CONDITION_SEPARATOR = {
  AND: 'AND',
  OR: 'OR'
}

const COMPARE_OPERATORS = {
  EQUAL: '=',
  NOT_EQUAL: '!=',
  MORE: '>',
  MORE_OR_EQUAL: '>=',
  LESS: '<',
  LESS_OR_EQUAL: '<=',
  INCLUDE: '~',
  NOT_INCLUDE: '!~'
}

const COMPARE_NUMBER_OPTIONS: Array<Option> = [
  {
    value: COMPARE_OPERATORS.MORE,
    label: 'Больше',
    icon: (
      <svg>
        <use xlinkHref={`#${More.id}`} />
      </svg>
    )
  },
  {
    value: COMPARE_OPERATORS.MORE_OR_EQUAL,
    label: 'Больше или равно',
    icon: (
      <svg>
        <use xlinkHref={`#${MoreOrEq.id}`} />
      </svg>
    )
  },
  {
    value: COMPARE_OPERATORS.EQUAL,
    label: 'Равно',
    icon: (
      <svg>
        <use xlinkHref={`#${Eq.id}`} />
      </svg>
    )
  },
  {
    value: COMPARE_OPERATORS.LESS_OR_EQUAL,
    label: 'Меньше или равно',
    icon: (
      <svg>
        <use xlinkHref={`#${LessOrEq.id}`} />
      </svg>
    )
  },
  {
    value: COMPARE_OPERATORS.LESS,
    label: 'Меньше',
    icon: (
      <svg>
        <use xlinkHref={`#${Less.id}`} />
      </svg>
    )
  }
]

const COMPARE_STRING_OPTIONS: Array<Option> = [
  {
    value: COMPARE_OPERATORS.EQUAL,
    label: 'Равно (Мультиселект)',
    icon: (
      <svg>
        <use xlinkHref={`#${Eq.id}`} />
      </svg>
    )
  },
  {
    value: COMPARE_OPERATORS.NOT_EQUAL,
    label: 'Не равно (Мультиселект)',
    icon: (
      <svg>
        <use xlinkHref={`#${NotEq.id}`} />
      </svg>
    )
  },
  {
    value: COMPARE_OPERATORS.INCLUDE,
    label: 'Содержит',
    icon: (
      <svg>
        <use xlinkHref={`#${Include.id}`} />
      </svg>
    )
  },
  {
    value: COMPARE_OPERATORS.NOT_INCLUDE,
    label: 'Не содержит',
    icon: (
      <svg>
        <use xlinkHref={`#${NotInclude.id}`} />
      </svg>
    )
  }
]

const LOGIC_OPTIONS = [
  { value: CONDITION_SEPARATOR.AND, label: 'И', icon: 'И' },
  { value: CONDITION_SEPARATOR.OR, label: 'ИЛИ', icon: 'ИЛИ' }
]

const BOOLEAN_OPTIONS = [{ value: 'true', label: 'Да' }, { value: 'false', label: 'Нет' }]

const now = new Date()
const min = new Date(2010, 0)
const max = new Date(now.getFullYear(), now.getMonth(), now.getDate())

const DATE_RANGE = {
  min: min.getTime(),
  max: max.getTime()
}

const DEFAULT_VALUES = {
  [OPTION_TYPES.TIME]: {
    value: formatFilterDateByType({
      type: DATE_FIELD_TYPES.DYNAMIC
    }),
    type: DATE_FIELD_TYPES.DYNAMIC,
    field_modification: {
      modification_sym: '+',
      modification_period: 'days',
      modification_val: '0',
      modification_calendar_days: 'true'
    }
  }
}

export const DATE_PRESETS: Array<{ label: string; value: string; icon: React.ReactNode }> = [
  { label: 'Год', value: '%Y', icon: <AggregationPresetYear /> },
  { label: 'Месяц', value: '%Y-%m', icon: <AggregationPresetMonth /> },
  { label: 'День', value: '%Y-%m-%d', icon: <AggregationPresetDay /> }
]

export {
  FIELD_TYPES,
  DATE_FIELD_TYPES,
  CONDITION_SEPARATOR,
  COMPARE_OPERATORS,
  COMPARE_NUMBER_OPTIONS,
  COMPARE_STRING_OPTIONS,
  LOGIC_OPTIONS,
  BOOLEAN_OPTIONS,
  DATE_RANGE,
  DEFAULT_VALUES
}

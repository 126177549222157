export const CLOSED_APP_BASENAME = '/'
export const EMBED_APP_BASENAME = '/embed'
export const SHARED_APP_BASENAME = '/shared'
export const DESKTOP_ROUTE = '/desktop'
export const DASHBOARD_ROUTE = '/dashboard'
export const DASHBOARD_URBAN_BLOCK_ROUTE = `${DESKTOP_ROUTE}${DASHBOARD_ROUTE}-urban-block`
export const URBAN_BLOCK_ROUTE = `${DESKTOP_ROUTE}/urban-block`
export const URBAN_BLOCK_BUILDING_ROUTE = `${DESKTOP_ROUTE}/urban-block-building`
export const URBAN_BLOCK_COMMITTEE_ROUTE = `${DESKTOP_ROUTE}/urban-block-committee`
export const URBAN_BLOCK_CONSTRUCTION_ROUTE = `${DESKTOP_ROUTE}/urban-block-construction`
export const OTHER_SERVICES_ROUTE = '/other_services'
export const SPK_BUILDING_ROUTE = '/spk_building'
export const ZHKH_BUILDING_ROUTE = '/zhkh_building'

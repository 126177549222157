import ActionCable, { Consumer, Subscription } from 'actioncable'
import isEqual from 'lodash/isEqual'

import uniqueID from 'lib/utils/uniqueID'
import { WS_API_URL } from 'constants/api'
import { STATUS } from 'constants/actionCable'
import AuthPersistor from 'lib/services/authPersistor'

interface Channel {
  name: string
  identifier: string
  subscription: Subscription
}
interface MessageType {
  id: string
  type: string
  token: string
  payload: any
  rejectWithSameType: boolean
  rejectWithSamePayload: boolean
  sendingTimestamp?: ReturnType<Date['getTime']>
  received: (message: any) => void
  rejected: (message: any) => void
}

class ActionCableService {
  url: string
  device_id: string
  status: { [channelName: string]: STATUS }
  channels: Array<Channel>
  bulkMessages: {
    [channelName: string]: Array<MessageType>
  }
  messages: {
    [channelName: string]: Array<MessageType>
  }
  consumer: Consumer

  constructor() {
    this.url = WS_API_URL
    this.device_id =
      Math.random()
        .toString(36)
        .slice(2) +
      Math.random()
        .toString(36)
        .slice(2)
    this.channels = []
    this.bulkMessages = {}
    this.messages = {}
    this.status = {}

    this.createConnection()
  }

  setStatus({ channelName, status }: { channelName: string; status: STATUS }): void {
    this.status[channelName] = status
  }

  getSubscription(channelName: string): Subscription | null {
    const neededChannel = this.channels.find(channel => channel.name === channelName)

    if (neededChannel) {
      return neededChannel.subscription
    }

    return null
  }

  createConnection(): Object {
    this.consumer = ActionCable.createConsumer(this.url)

    return this.consumer
  }

  createChannel(
    name: string,
    handlers: {
      connected?: () => void
      disconnected?: () => void
      rejected?: () => void
      received?: (data: any) => void
    },
    createOptions: { [key: string]: any } = {}
  ): Subscription | null {
    const identifier = JSON.stringify({
      channel: name,
      device_id: this.device_id,
      ...createOptions
    })
    let channel = this.getSubscription(name)

    if (channel) {
      return channel
    }

    this.bulkMessages[name] = []
    this.messages[name] = []

    this.setStatus({ channelName: name, status: STATUS.CONNECTING })
    channel = this.consumer.subscriptions.create(
      { channel: name, device_id: this.device_id, ...createOptions },
      {
        connected: () => {
          this.setStatus({ channelName: name, status: STATUS.CONNECTED })
          this.sendBulkMessages(name)

          if (handlers.connected) {
            handlers.connected()
          }
        },
        disconnected: () => {
          this.setStatus({ channelName: name, status: STATUS.DISCONNECTED })
          this.deleteBulkMessages(name)

          if (handlers.disconnected) {
            handlers.disconnected()
          }
        },
        rejected: () => {
          this.setStatus({ channelName: name, status: STATUS.DISCONNECTED })
          this.deleteBulkMessages(name)

          if (handlers.rejected) {
            handlers.rejected()
          }
        },
        received: (data: any) => {
          this.receiveMessage(name, data)

          if (handlers.received) {
            handlers.received(data)
          }
        }
      }
    )

    this.channels.push({
      name,
      identifier,
      subscription: channel
    })

    return channel
  }

  deleteChannel(channelName: string): void {
    const subscription = this.getSubscription(channelName)

    if (subscription) {
      this.setStatus({ channelName, status: STATUS.DISCONNECTING })
      subscription.unsubscribe()
      this.channels = this.channels.filter(channel => channel.name !== channelName)
      this.setStatus({ channelName, status: STATUS.DISCONNECTED })
    }
  }

  connectActionCable(): void {
    this.channels.forEach(channel => (this.status[channel.name] = STATUS.CONNECTING))
    this.consumer.connection.open()
  }

  disconnectActionCable(allowReconnect?: boolean): void {
    this.channels.forEach(channel => (this.status[channel.name] = STATUS.DISCONNECTING))
    this.consumer.connection.close({ allowReconnect: allowReconnect ? allowReconnect : true })
  }

  deleteBulkMessages(channelName: string) {
    const channel = this.getSubscription(channelName)

    if (!channel) {
      console.error(`Channel with name '${channelName}' is doesn't exist`)
    } else {
      this.sendBulkMessages(channelName)
    }
  }

  sendBulkMessages(channelName: string) {
    const channel = this.getSubscription(channelName)

    if (!channel) {
      console.error(`Channel with name '${channelName}' is doesn't exist`)
    } else if (this.bulkMessages[channelName].length) {
      this.bulkMessages[channelName].forEach(message => {
        const { id, type, token, payload } = message

        message.sendingTimestamp = new Date().getTime()

        this.bulkMessages[channelName] = this.bulkMessages[channelName].filter(
          bulkMessage => bulkMessage.id !== id
        )
        this.messages[channelName].push(message)

        const data: {
          id: string | number
          payload: any
          type: string
          token?: string
        } = {
          id,
          type,
          payload
        }

        if (token) {
          data.token = token
        }

        channel.send(data)
      })
    }
  }

  sendMessage<T>({
    channelName,
    payload,
    type,
    rejectWithSameType = false,
    rejectWithSamePayload = true
  }: {
    channelName: string
    payload: any
    type: string
    rejectWithSameType?: boolean
    rejectWithSamePayload?: boolean
  }) {
    return new Promise<{ payload: T }>((resolve, reject) => {
      const channel = this.getSubscription(channelName)

      if (!channel) {
        reject({ message: new Error(`Channel with name '${channelName}' is doesn't exist`) })
      }

      const id = uniqueID()

      const token = AuthPersistor.getToken()

      const message: MessageType = {
        id,
        type,
        token,
        payload,
        rejectWithSameType,
        rejectWithSamePayload,
        received: resolve,
        rejected: reject
      }
      if (this.status[channelName] !== STATUS.CONNECTED) {
        this.bulkMessages[channelName].push(message)
      } else {
        message.sendingTimestamp = new Date().getTime()
        this.messages[channelName].push(message)

        const data: {
          id: string | number
          payload: any
          type: string
          token?: string
        } = {
          id,
          type,
          payload
        }

        if (token) {
          data.token = token
        }

        channel.send(data)
      }
    })
  }

  receiveMessage(
    channelName: string,
    incomingMessage: { [key: string]: any } & {
      id: string
      type: string
    }
  ) {
    const sendedMessage = this.messages[channelName].find(
      message => message.id === incomingMessage.id
    )

    if (sendedMessage) {
      const {
        id,
        type,
        payload,
        sendingTimestamp,
        rejectWithSameType,
        rejectWithSamePayload,
        received,
        rejected
      } = sendedMessage

      switch (incomingMessage.type) {
        case 'OLD':
        case 'ERROR': {
          rejected(incomingMessage)
          break
        }
        default: {
          // We must to abort previous messages with same action type
          // if this message is not parallel or we will found the message
          // with same payload
          const previousMessages = this.messages[channelName].filter(
            message =>
              message.id !== id &&
              message.type === type &&
              sendingTimestamp >= message.sendingTimestamp &&
              (rejectWithSameType || (rejectWithSamePayload && isEqual(payload, message.payload)))
          )

          previousMessages.forEach(({ id }) =>
            this.receiveMessage(channelName, { id, type: 'OLD' })
          )

          if (rejectWithSameType) {
            // Drop current message if it's old
            const isNextMessageExisted = this.messages[channelName].some(
              message =>
                message.id !== id &&
                message.type === type &&
                sendingTimestamp < message.sendingTimestamp
            )

            if (isNextMessageExisted) {
              this.receiveMessage(channelName, { id, type: 'OLD' })
              return
            }
          }

          received(incomingMessage)
        }
      }

      this.messages[channelName] = this.messages[channelName].filter(
        message => message.id !== incomingMessage.id
      )
    }
  }
}

export default new ActionCableService()

import React from 'react'

import { Provider } from 'react-redux'
import createMainStore from 'lib/store/closed'

import { ThemeProvider as JssThemeProvider } from 'react-jss'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'

import MainRouter from 'components/Router/closed'
import { theme, muiTheme } from 'lib/theme'
import './App.css'
import 'react-tooltip/dist/react-tooltip.css'

const store = createMainStore()

function App() {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={muiTheme}>
        <JssThemeProvider theme={theme}>
          <MainRouter />
        </JssThemeProvider>
      </MuiThemeProvider>
    </Provider>
  );
}

export default App;

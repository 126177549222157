// @ts-nocheck
import React, { HTMLAttributes, useMemo } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import { DropTargetMonitor } from 'react-dnd'
import { compose } from 'recompose'
import classNames from 'classnames'

import { TYPE as SET_TYPE } from 'constants/set'
import { OPTION_TYPES } from 'constants/filter'
import AggregationSet, { ItemProps as SetItemProps } from '../Set'
import styles from './styles'
import { DragAndDropTypes } from '../../dndTypes'

export type SetWithType = Data.Dimension & { type: SET_TYPE } | Data.Measure & { type: SET_TYPE }

export interface OuterProps extends HTMLAttributes<HTMLDivElement> {
  sets: Array<SetWithType>
  allSets: Array<SetWithType>
  getCubeNameById: (cube_id: string) => string
  onAddAggregation: (set: Filter.MainField) => void
  onRemoveFromPlace: (set: Filter.MainField) => void
  onSelectFilter: () => void
}
interface DragCollectProps {
  isOver: boolean
  isOverOnZone: boolean
  canDrop: boolean
}
interface Props extends JSSProps<typeof styles>, DragCollectProps, OuterProps { }
interface State { }

const DROP_TARGET = {
  name: DragAndDropTypes.ElementIndicatorItem,
  source: {
    canDrop({ sets }: Props, monitor: DropTargetMonitor) {
      const item = monitor.getItem() as SetItemProps
      const itemInSets = sets.find(
        set => `${item.set.cube_id}_${item.set.id}` === `${set.cube_id}_${set.id}`
      )

      return (
        ![
          OPTION_TYPES.GEO,
          OPTION_TYPES.GEO_LINE,
          OPTION_TYPES.GEO_POLY,
          OPTION_TYPES.GEO_TITLE,
          OPTION_TYPES.SCANNER
        ].includes(item.set.filter_type) &&
        (!itemInSets || item.type === SET_TYPE.MEASURE)
      )
    },
    drop(props: Props, monitor: DropTargetMonitor) {
      const { allSets, onAddAggregation } = props
      const isOverOnZone = monitor.isOver({ shallow: true })
      const item = monitor.getItem() as SetItemProps

      if (isOverOnZone) {
        let set: Filter.MainField | undefined = undefined
        const itemInSets = allSets.find(
          set => set.id === item.set.id && set.cube_id === item.set.cube_id
        )

        if (itemInSets)
          set = {
            id: item.set.id,
            name: item.set.name,
            cube_id: item.set.cube_id,
            filter_type: item.set.filter_type,
            type: itemInSets.type === SET_TYPE.MEASURE ? 'having' : 'where',
            agg: itemInSets.type === SET_TYPE.MEASURE ? 'sum' : undefined,
            entity_type: itemInSets.type === SET_TYPE.DIMENSION ? 'dimensions' : 'measures',
            date_reg_exp: item.set.filter_type === OPTION_TYPES.TIME ? '%Y-%m-%d %H:%M' : null
          }

        if (set) {
          onAddAggregation(set)
        }
      }

      return props
    }
  },
  collect(monitor) {
    return {
      isOver: monitor.isOver(),
      isOverOnZone: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop()
    }
  }
}

const FiltersDropZone = (props) => {
  const convertSetToField = (item: SetWithType): Filter.MainField => {
    const { allSets } = props

    const itemInSets = allSets.find(set => set.id === item.id && set.cube_id === item.cube_id)

    return {
      id: item.id,
      name: item.name,
      cube_id: item.cube_id,
      filter_type: item.filter_type,
      type: itemInSets.type === SET_TYPE.MEASURE ? 'where' : undefined,
      agg: undefined,
      entity_type: itemInSets.type === SET_TYPE.DIMENSION ? 'dimensions' : 'measures',
      date_reg_exp: item.filter_type === OPTION_TYPES.TIME ? '%Y-%m-%d %H:%M' : null
    }
  }

  const {
    classes,
    className,
    sets,
    canDrop,
    isOver,
    isOverOnZone,
    theme,
    onSelectFilter,
    onRemoveFromPlace,
    getCubeNameById
  } = props

  const renderItem = useMemo(() => (
    <div
      className={classNames(classes.container, className)}
      style={{
        borderColor:
          canDrop && (!isOver || (isOver && isOverOnZone)) ? theme.colors.green : '#E0E6EB'
      }}
    >
      {sets.length ? (
        sets.map(({ type, ...set }, index) => (
          <AggregationSet
            key={`${set.cube_id}-${set.id}-${index}`}
            set={set}
            type={type}
            getCubeNameById={getCubeNameById}
            containerProps={{
              className: classNames(classes.set),
              onDoubleClick: () => onRemoveFromPlace(convertSetToField({ ...set, type })),
              onClick: () => onSelectFilter()
            }}
          />
        ))
      ) : (
        <div className={classes.placeholder}>
          <span className={classes.placeholderText}>
            Перенесите показатели, по которым необходимо применить фильтрацию
          </span>
        </div>
      )}
    </div>
  ), [])


  return renderItem
}

export default compose<Props, OuterProps>(
  injectStyles(styles),
)(FiltersDropZone)

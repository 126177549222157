const styles = (theme: App.Theme) => ({
  container: {
    position: 'relative',
    padding: 0
  },
  menuItem: {
    height: 'auto',
    fontSize: '0.875rem',
    lineHeight: 1.2,
    '& + &': {
      marginTop: '1px solid #F7F7F7'
    }
  },
  menuItemGutters: {
    paddingRight: '0.625rem',
    paddingLeft: '0.625rem'
  },
  menuItemIcon: {
    width: '1em',
    height: '1em',
    fontSize: '0.625rem',
    fill: theme.colors.text.secondary,
    stroke: theme.colors.text.secondary
  },
  menuColorItemIcon: {
    extend: 'menuItemIcon',
    borderRadius: '50%'
  },
  tooltipContainer: {
    padding: 0,
    fontSize: 'inherit',
    lineHeight: 'inherit',
    color: '#000',
    backgroundColor: '#fff',
    boxShadow: theme.shadows[8],
    overflow: 'hidden'
  },
  tooltipItemText: {
    fontSize: '0.875rem',
    lineHeight: 1.2
  },
  colorPickerTooltip: {
    padding: '0.625rem',
    '& .sketch-picker': {
      padding: '0 !important',
      boxShadow: 'none !important'
    }
  },
  colorPickerRow: {
    '& + &': {
      marginTop: '0.625rem'
    }
  },
  colorPickerButton: {
    width: '100%',
    padding: '0.5rem',
    fontSize: '0.875rem',
    color: theme.colors.text.default,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.colors.border}`,
    borderRadius: '0.3125rem',
    outline: 'none',
    boxSizing: 'border-box',
    cursor: 'pointer',
    transition: 'color 0.3s ease, border 0.3s ease',
    '&:hover': {
      color: theme.colors.text.primary,
      borderColor: theme.colors.text.primary
    }
  }
})

export default styles

import { transparentize } from 'polished'
import { sizes } from './sizes'

interface FlexContainerOpts {
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse' | 'initial'
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'initial'
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch' | 'initial'
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse' | 'initial'
}

const viewportCssValues = {
  vh: (v: number) =>
    (v * Math.max(document.documentElement.clientHeight, window.innerHeight || 0)) / 100,
  vw: (w: number) =>
    (w * Math.max(document.documentElement.clientWidth, window.innerWidth || 0)) / 100
}

export const utils = {
  flexContainer: ({
    direction = 'column',
    justify = 'flex-start',
    alignItems = 'flex-start',
    wrap = 'nowrap'
  }: FlexContainerOpts) => ({
    display: 'flex',
    flexDirection: direction,
    flexWrap: wrap,
    justifyContent: justify,
    alignItems
  }),
  stripUnit: (val: string) => parseFloat(val),
  remToPx: (val: string) =>
    `${Math.round(
      parseFloat(val) * parseFloat(getComputedStyle(document.documentElement).fontSize)
    )}px`,
  opacify: (color: string, opacity: number) => transparentize(1 - opacity, color.toLowerCase()),
  calculatePageHeight: ({ viewportHeight }: { viewportHeight: number }) =>
    `calc(${viewportHeight}px - ${sizes.header.height})`,
  viewportCssValues
}

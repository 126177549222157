enum Types {
  DESKTOP_REQUEST = 'DESKTOP_REQUEST',
  DESKTOP_SUCCESS = 'DESKTOP_SUCCESS',
  DESKTOP_FAILURE = 'DESKTOP_FAILURE',
  DESKTOP_UPDATE_REQUEST = 'DESKTOP_UPDATE_REQUEST',
  DESKTOP_UPDATE_SUCCESS = 'DESKTOP_UPDATE_SUCCESS',
  DESKTOP_UPDATE_FAILURE = 'DESKTOP_UPDATE_FAILURE'
}

export default Types

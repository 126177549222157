import {
  BarChart,
  FileDownload,
  LineChart,
  MapChart,
  PieChart,
  SankeyChart,
  Scanner,
  ScatterChart,
  Table,
  Text,
  Value
} from '../../components/Icons'
import { colors } from '../theme/colors'
import { common } from '../theme/common'

const CHART_TYPES = {
  LINE_CHART: 'line_chart',
  FUNNEL_CHART: 'funnel',
  BAR_CHART: 'bar_chart',
  PIE_CHART: 'pie_chart',
  SCATTER_CHART: 'SCATTER_CHART',
  SHARE_DATASET: 'SHARE_DATASET',
  MAP_CHART: 'map_chart',
  TABLE: 'table',
  SCANNER: 'scanner',
  VALUE: 'value',
  SANKEY: 'sankey'
} as const
export type ChartTypeValue = typeof CHART_TYPES[keyof typeof CHART_TYPES]

const ZOOM_DEFAULT = {
  start_x: 0,
  end_x: 100,
  start_y: 0,
  end_y: 100
}

const CUSTOMER_ID_DIMENSION_NAME = 'Customers.customer_id'
const ZONE_ID_DIMENSION_NAME = 'DegDim.zone_id'
const MAX_ABSCISSA_LABELS_COUNT = 100
const MAX_ORDINATE_LABELS_COUNT = (MAX_ABSCISSA_LABELS_COUNT / 16) * 9

const TYPES = {
  ...CHART_TYPES,
  PUBLICATION: 'publication',
  TEXT: 'text',
  FILE: 'file',
  EMBED: 'embed',
  EMPTY: 'empty',
  WIDGET: 'widget'
}

const VIEWS = {
  [TYPES.LINE_CHART]: {
    label: 'Линейный график',
    icon: LineChart
  },
  [TYPES.BAR_CHART]: {
    label: 'Столбчатый график',
    icon: BarChart
  },
  [TYPES.SCATTER_CHART]: {
    label: 'Пузырьковая диаграмма',
    icon: ScatterChart
  },
  [TYPES.FUNNEL_CHART]: {
    label: 'Воронка',
    icon: ScatterChart
  },
  [TYPES.SHARE_DATASET]: {
    label: 'Комбинированный график',
    icon: PieChart,
    secondIcon: LineChart
  },
  [TYPES.PIE_CHART]: {
    label: 'Круговая диаграмма',
    icon: PieChart
  },
  [TYPES.MAP_CHART]: {
    label: 'Карта',
    icon: MapChart
  },
  [TYPES.VALUE]: {
    label: 'Число/Значение',
    icon: Value
  },
  [TYPES.TABLE]: {
    label: 'Таблица',
    icon: Table
  },
  [TYPES.SCANNER]: {
    label: 'Сканер',
    icon: Scanner
  },
  [TYPES.TEXT]: {
    label: 'Текст',
    icon: Text
  },
  [TYPES.FILE]: {
    label: 'Файл',
    icon: FileDownload
  },
  [TYPES.EMBED]: {
    label: 'Динамическая вставка',
    icon: Text
  },
  [TYPES.PUBLICATION]: {
    label: 'Новость',
    icon: Text
  },
  [TYPES.WIDGET]: {
    label: 'Виджет',
    icon: Text
  },
  [TYPES.SANKEY]: {
    label: 'Санкей',
    icon: SankeyChart
  }
}

enum ChartRatingType {
  ASC = 'ASC',
  DESC = 'DESC',
  NONE = 'NONE'
}

const CHART_RATING: { [K in keyof typeof ChartRatingType]: { name: string } } = {
  [ChartRatingType.NONE]: {
    name: 'Без рейтинга'
  },
  [ChartRatingType.ASC]: {
    name: 'По возрастанию'
  },
  [ChartRatingType.DESC]: {
    name: 'По убыванию'
  }
}

enum ForecastingType {
  LINEAR = 'LINEAR',
  LOG = 'LOG',
  EXP = 'EXP',
  POLY = 'POLY'
}

const FORECASTING: { [K in keyof typeof ForecastingType]: { name: string } } = {
  [ForecastingType.LINEAR]: {
    name: 'Линейный'
  },
  [ForecastingType.LOG]: {
    name: 'Логарифмический'
  },
  [ForecastingType.EXP]: {
    name: 'Экспоненциальный'
  },
  [ForecastingType.POLY]: {
    name: 'Полиномиальный'
  }
}

const PANEL_EDITOR_ELEMENT_FILTERS: Array<{
  type: string
  label: string
  color: string
}> = [
  ...Object.keys(CHART_TYPES).map(key => ({
    type: CHART_TYPES[key],
    label: VIEWS[CHART_TYPES[key]].label,
    color: colors.blue
  }))
  // {
  //   type: TYPES.PUBLICATION,
  //   label: VIEWS[TYPES.PUBLICATION].label,
  //   color: colors.green
  // }
]

export const makeSizes = (step: number, min: number, max: number): number[] => {
  const sizes: number[] = []
  for (let value = min; value <= max; value += step) {
    sizes.push(value)
  }
  return sizes
}

const VALUES_FONT_SIZES = makeSizes(1, 8, 32)

const WIDGET_FONT_SIZES = makeSizes(2, 10, 56)

const TABLE_STYLES = {
  fontSizes: makeSizes(2, 12, 60),
  paddings: makeSizes(2, 2, 20)
}

export const POSITIONS = {
  TOP: 'top',
  RIGHT: 'right',
  BOTTOM: 'bottom',
  LEFT: 'left',
  INSIDE_LEFT: 'insideLeft',
  INSIDE: 'inside'
}

const EXPORT_SETTINGS = {
  PERIOD: {
    NONE: 'disabled',
    DAILY: 'daily',
    WEEKLY: 'weekly',
    MONTHLY: 'monthly'
  }
}

const CONFIG = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      axis: 'x'
    },
    padding: 10,
    textStyle: {
      color: '#000',
      fontFamily: common.fontFamily
    },
    confine: true,
    backgroundColor: '#fff'
    // extraCssText: 'border-radius: 0; box-shadow: 0 0 3px 0 rgba(0,0,0,0.15)'
  },
  legend: {
    type: 'scroll',
    itemWidth: 8,
    bottom: 0,
    padding: 0,
    textStyle: {
      fontSize: 10,
      fontFamily: common.fontFamily
    }
  },
  toolbox: {
    show: false,
    feature: {
      dataView: { readOnly: false },
      saveAsImage: {}
    }
  },
  xAxis: {
    axisLine: {
      show: false
    },
    axisLabel: {
      fontWeight: 'bold',
      fontSize: 10,
      showMinLabel: true,
      showMaxLabel: true
    },
    axisTick: {
      show: false
    },
    splitLine: {
      show: false,
      lineStyle: {
        type: 'dashed',
        color: ['#F8F8F8']
      }
    },
    alignWithLabel: true
  },
  yAxis: {
    axisLine: {
      show: false
    },
    axisLabel: {
      inside: false,
      margin: 0,
      fontWeight: 'bold',
      fontSize: 10
      // padding: [2, 5, 2, 5],
      // backgroundColor: 'rgba(255, 255, 255, 0.8)',
      // borderColor: '#000',
      // borderWidth: 1,
      // borderRadius: 2
      // textBorderColor: '#fff',
      // textBorderWidth: 5
    },
    axisTick: {
      show: false
    },
    splitLine: {
      show: false,
      lineStyle: {
        type: 'dashed',
        color: ['#F8F8F8']
      }
    },
    nameTextStyle: {
      align: 'right'
    },
    nameLocation: 'end',
  },
  color: colors.charts.palette,
  grid: {
    top: 16,
    left: 10,
    right: 10,
    bottom: 16 * 2,
    containLabel: true
  }
}

enum FILE_VIEW_TYPE {
  PREVIEW = 'preview',
  LINK = 'link'
}

enum FILE_VIEW_SCROLL_DIRECTION {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal'
}

const FILE_VIEW_TYPES: { [key in FILE_VIEW_TYPE]: string } = {
  [FILE_VIEW_TYPE.PREVIEW]: 'Просмотрщик',
  [FILE_VIEW_TYPE.LINK]: 'Сcылка'
}

const FILE_VIEW_SCROLL_DIRECTIONS: { [key in FILE_VIEW_SCROLL_DIRECTION]: string } = {
  [FILE_VIEW_SCROLL_DIRECTION.VERTICAL]: 'Вертикальное',
  [FILE_VIEW_SCROLL_DIRECTION.HORIZONTAL]: 'Горизонтальное'
}

export {
  CHART_RATING, CHART_TYPES, CONFIG, CUSTOMER_ID_DIMENSION_NAME, ChartRatingType, EXPORT_SETTINGS, FILE_VIEW_SCROLL_DIRECTION,
  FILE_VIEW_SCROLL_DIRECTIONS, FILE_VIEW_TYPE,
  FILE_VIEW_TYPES, FORECASTING, ForecastingType, MAX_ABSCISSA_LABELS_COUNT,
  MAX_ORDINATE_LABELS_COUNT, PANEL_EDITOR_ELEMENT_FILTERS, TABLE_STYLES, TYPES, VALUES_FONT_SIZES, VIEWS, WIDGET_FONT_SIZES, ZONE_ID_DIMENSION_NAME, ZOOM_DEFAULT
}


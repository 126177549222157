const styles = (theme: App.Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0.625rem'
  },
  input: {
    width: '100%',
    padding: '0.315rem',
    fontSize: '1rem',
    lineHeight: 1,
    color: theme.colors.text.primary,
    textAlign: 'center',
    backgroundColor: 'transparent',
    border: `1px solid ${theme.colors.border}`
  },
  line: {
    width: '100%',
    height: 1,
    margin: '0.625rem 0',
    backgroundColor: theme.colors.border
  },
  link: {
    display: 'inline-block',
    fontSize: '0.875rem',
    color: theme.colors.text.secondary,
    textDecoration: 'underline',
    textAlign: 'center',
    transition: 'color 0.3s ease',
    '&:hover': {
      color: theme.colors.text.primary
    }
  },
  presetsTitle: {
    margin: '0.625rem 0',
    fontSize: '1rem'
  },
  presets: {
    '& > *:not(:last-child)': {
      marginRight: '0.5rem'
    },
    ...theme.utils.flexContainer({
      direction: 'row',
      alignItems: 'center',
      justify: 'space-between'
    })
  }
})

export default styles

import { isEqual, isObject, isEmpty, isNumber as isNum, isDate } from 'lodash'

export function notEmpty(value) {
  const error = 'Поле не должно быть пустым'

  if (isDate(value)) {
    return undefined
  }

  if (isObject(value)) {
    return isEmpty(value) ? error : undefined
  }

  if (typeof value === 'string') {
    return value.replace(/\s/g, '') ? undefined : error
  }

  if (Array.isArray(value) && !value.length) {
    return error
  }

  return value ? undefined : error
}

export function isNumber(value) {
  const error = 'Требуется цифра'

  if (!isEmpty(value) && isNum(+value) && !isNaN(+value)) return undefined
  else return error
}

export function notEmptyFilter(value: Filter.Field) {
  return value.value ? undefined : 'Необходимо выбрать поле для фильтрации'
}

export function correctDateModification(
  field_modification: Filter.DateField['field_modification']
) {
  const error = 'Некорретно заполненное поле'

  if (
    field_modification &&
    field_modification.modification_sym &&
    field_modification.modification_val &&
    field_modification.modification_period &&
    field_modification.modification_calendar_days
  ) {
    return undefined
  }

  return error
}

export function correctDateField(value: Filter.DateField) {
  const error = 'Некорретно заполненное поле'

  if (
    value &&
    ((value.field_modification &&
      value.field_modification.modification_sym &&
      value.field_modification.modification_val &&
      value.field_modification.modification_period &&
      value.field_modification.modification_calendar_days) ||
      value.format)
  ) {
    return undefined
  }

  return error
}

export function onlyOneOfArray<T>(value: Array<T>) {
  const error = 'Нужно выбать выбрать только одну опцию'

  if (Array.isArray(value)) {
    if (value.length > 1) return error
    else return undefined
  } else {
    console.error('onlyOneOfArray validation used on non-array value')
    return error
  }
}

export function email(value) {
  const error = 'Неправильный формат Email'
  const re = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )

  if (typeof value === 'string') {
    return re.test(value.toLowerCase()) ? undefined : error
  } else return error
}

export function minLength(len: number) {
  const error = `Требуется не менее ${len} символов`

  return function(value) {
    return value?.length >= len ? undefined : error
  }
}

export function maxLength(len: number) {
  const error = `Требуется не более ${len} символов`

  return function(value) {
    return value.length <= len ? undefined : error
  }
}

export const validateRepeat = (fieldName: string, errorText: string) => (
  value: string | undefined,
  allValues: any
) => {
  const compareValue = allValues[fieldName]

  if (!value || (compareValue && !isEqual(value, compareValue))) {
    return errorText
  }

  return undefined
}

export function notEmptyFile(value) {
  const error = 'Поле не должно быть пустым'

  return !!value ? undefined : error
}

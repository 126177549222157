import React, { useEffect } from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { HTML5Backend } from 'react-dnd-html5-backend'

import Layout from 'components/Layout'
import { RouterProvider } from 'react-router-dom'

import AuthPersistor from 'lib/services/authPersistor'
import { authSuccess, readUserInfo } from 'lib/modules/user/actions'
import { useDispatch } from 'react-redux'
import { DndProvider } from 'react-dnd'
import routes from 'src/routes'
import { compose } from 'recompose'
import injectStyles from 'react-jss'
import styles from './styles'

interface Props { }
interface OuterProps { }

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: routes
  },
])

const MainRouter = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const token = AuthPersistor.getToken()

    if (token) {
      (async () => {
        try {
          await dispatch(readUserInfo(token))
          await dispatch(authSuccess({ token }))
        } catch (e) {
          console.error(e);
        }
      })()
    }
  }, [])

  return (
    <DndProvider backend={HTML5Backend}>
      <RouterProvider router={router} />
    </DndProvider >
  )
}

export default compose<Props, OuterProps>(
  injectStyles(styles)
)(MainRouter)

import { fork, all } from 'redux-saga/effects'

import { watcher as cubes } from './cubes/sagas'
import { watcher as sources } from './sources/sagas'
import { watcher as preview } from './preview/sagas'
import { watcher as making } from './making/sagas'

export const sagas = [cubes, sources]

export function* watcher() {
  yield all([fork(cubes), fork(sources), fork(preview), fork(making)])
}

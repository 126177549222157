import { takeEvery, put, select, all, call } from 'redux-saga/effects'
import { actionTypes } from 'redux-resource'
import {
  getFormValues,
  startSubmit,
  setSubmitSucceeded,
  setSubmitFailed,
  stopSubmit
} from 'redux-form'
import { push } from 'connected-react-router'

import ActionCableService from 'lib/services/actionCable'
import { tokenSelector } from 'lib/modules/user/selectors'
import { GetReceivedMessages, GetSentMessages, GetMessage, SendMessages } from './actions'
import { MESSAGE_FORM } from 'constants/forms'
import { BASE_CHANNEL } from 'constants/actionCable'
import { MESSAGES } from 'constants/resources'
import { FormFields as MessageFormValues } from 'pages/closed/MessageEditor'
import TYPES from './actionTypes'

function* getReceivedMessagesSaga(action: GetReceivedMessages) {
  const token = yield select(tokenSelector)
  try {
    yield put({
      type: actionTypes.READ_RESOURCES_PENDING,
      resourceType: action.payload.resourceType,
      requestKey: action.payload.requestKey
    })

    const { payload } = yield ActionCableService.sendMessage({
      channelName: BASE_CHANNEL,
      token,
      type: action.payload.requestKey,
      payload: {}
    })

    yield put({
      type: actionTypes.READ_RESOURCES_SUCCEEDED,
      resourceType: action.payload.resourceType,
      requestKey: action.payload.requestKey,
      list: MESSAGES.LISTS.RECEIVED,
      resources: payload
    })
  } catch (err) {
    yield put({
      type: actionTypes.READ_RESOURCES_FAILED,
      resourceType: action.payload.resourceType,
      requestKey: action.payload.requestKey
    })
  }
}

function* getSentMessagesSaga(action: GetSentMessages) {
  const token = yield select(tokenSelector)
  try {
    yield put({
      type: actionTypes.READ_RESOURCES_PENDING,
      resourceType: action.payload.resourceType,
      requestKey: action.payload.requestKey
    })

    const { payload } = yield ActionCableService.sendMessage({
      channelName: BASE_CHANNEL,
      token,
      type: action.payload.requestKey,
      payload: {}
    })

    yield put({
      type: actionTypes.READ_RESOURCES_SUCCEEDED,
      resourceType: action.payload.resourceType,
      requestKey: action.payload.requestKey,
      list: MESSAGES.LISTS.SENT,
      resources: payload
    })
  } catch (err) {
    yield put({
      type: actionTypes.READ_RESOURCES_FAILED,
      resourceType: action.payload.resourceType,
      requestKey: action.payload.requestKey
    })
  }
}

function* getMessageSaga(action: GetMessage) {
  const token = yield select(tokenSelector)
  const { id } = action.payload.requestProperties

  try {
    yield put({
      type: actionTypes.READ_RESOURCES_PENDING,
      resourceType: action.payload.resourceType,
      requestKey: action.payload.requestKey,
      requestProperties: action.payload.requestProperties
    })

    const { payload } = yield ActionCableService.sendMessage({
      channelName: BASE_CHANNEL,
      token,
      type: action.payload.requestKey,
      payload: action.payload.requestProperties
    })

    yield put({
      type: actionTypes.READ_RESOURCES_SUCCEEDED,
      resourceType: action.payload.resourceType,
      requestKey: action.payload.requestKey,
      resources: [payload]
    })
  } catch (err) {
    yield put({
      type: actionTypes.READ_RESOURCES_FAILED,
      resourceType: action.payload.resourceType,
      requestKey: action.payload.requestKey
    })
  }
}

function* sendMessages(action: SendMessages) {
  const {
    requestKey,
    resourceType,
    list,
    requestProperties: { redirect }
  } = action.payload
  const values: MessageFormValues = yield select(getFormValues(MESSAGE_FORM.form))
  const token = yield select(tokenSelector)

  try {
    yield put(startSubmit(MESSAGE_FORM.form))
    yield put({
      type: actionTypes.CREATE_RESOURCES_PENDING,
      requestKey,
      resourceType,
      list
    })

    /* transforming form values */
    const messages: Data.MessageForm[] = []
    values.receivers.forEach(receiver => {
      const { text, subject, forms, info_panels, info_elements, attachments } = values

      messages.push({
        text,
        subject,
        forms,
        attachments: attachments.map(item => item.id),
        info_panels,
        info_elements,
        receiver_id: Number(receiver.value)
      })
    })

    const { payload } = yield ActionCableService.sendMessage<{
      success_messages: Data.Message[]
      fail_messages: Data.Message[]
      errors: any[]
    }>({
      channelName: BASE_CHANNEL,
      token,
      type: requestKey,
      payload: messages
    })

    yield put({
      type: actionTypes.CREATE_RESOURCES_SUCCEEDED,
      requestKey,
      resourceType,
      list,
      resources: payload.success_messages
    })
    yield put(stopSubmit(MESSAGE_FORM.form))
    yield put(setSubmitSucceeded(MESSAGE_FORM.form))

    if (redirect) yield put(push({ pathname: redirect }))
  } catch (err) {
    yield put({
      type: actionTypes.CREATE_RESOURCES_FAILED,
      requestKey,
      resourceType,
      list,
      requestProperties: {
        error: err
      }
    })

    yield put(stopSubmit(MESSAGE_FORM.form, { _error: err }))
    yield put(setSubmitFailed(MESSAGE_FORM.form))
  }
}

export function* watcher() {
  yield all([
    takeEvery(TYPES.GET_RECEIVED_MESSAGES, getReceivedMessagesSaga),
    takeEvery(TYPES.GET_SENT_MESSAGES, getSentMessagesSaga),
    takeEvery(TYPES.GET_MESSAGE, getMessageSaga),
    takeEvery(TYPES.SEND_MESSAGES, sendMessages)
  ])
}

const styles = (theme: App.Theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '40%'
  },
  container: {
    width: 'auto'
  }
})

export default styles

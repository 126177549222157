import { Props } from './index'

const styles = (theme: App.Theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    fontSize: '1.25rem',
    opacity: ({ disabled }: Props) => (disabled ? 0.5 : 1),
    transition: 'opacity 0.3s ease-out'
  },
  content: {
    position: 'relative',
    minWidth: '17.5rem',
    width: '100%',
    fontSize: '1rem',
    lineHeight: 0,
    boxSizing: 'border-box',
    borderBottom: `1px dashed transparent`,
    borderBottomColor: ({ meta: { error, touched, active } }: Props) => {
      if (active) {
        return theme.colors.blue
      } else if (error && touched) {
        return theme.colors.red
      }

      return 'transparent'
    },
    transition: 'border-color 0.3s ease-out'
  },
  input: {
    width: '100%',
    padding: 0,
    fontWeight: 'normal',
    fontSize: '1em',
    lineHeight: 1.2,
    color: theme.colors.text.primary,
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    '&::placeholder': {
      color: theme.colors.grey
    }
  },
  error: {
    position: 'absolute',
    display: 'inline-block',
    top: '185%',
    left: '-1%',
    width: '100%',
    padding: 5,
    color: theme.colors.red,
    fontSize: '0.75rem'
  }
})

export default styles

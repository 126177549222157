import React, { Component, createRef, RefObject } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import cn from 'classnames'
import { WrappedFieldProps } from 'redux-form'
import { Tooltip } from 'react-tooltip'
import { SketchPicker, ColorChangeHandler } from 'react-color'

import styles from './styles'
interface OuterProps {
  className?: string
  // Fix strange ts error
  component?: any
}
export interface Props extends OuterProps, WrappedFieldProps, JSSProps<typeof styles> { }

class ColorPicker extends Component<Props> {
  picker: RefObject<HTMLDivElement>

  constructor(props: Props) {
    super(props)

    this.picker = createRef<HTMLDivElement>()
  }

  componentDidMount() {
    const {
      input: { value, onChange }
    } = this.props

    if (!value) onChange('#FFFFF')
  }

  handleChange: ColorChangeHandler = color => {
    const {
      input: { onChange }
    } = this.props

    onChange(color.hex)
  }

  render() {
    const {
      classes,
      className,
      input: { value, name },
    } = this.props
    const id = `color-picker-${name}`

    return (
      <div className={cn(classes.container, className)}>
        <div
          ref={this.picker}
          className={classes.value}
          style={{ backgroundColor: value }}
          data-tooltip-id={id}
          data-tooltip-place="top"
        />
        <Tooltip
          id={id}
          className={classes.tooltip}
          place='top'
          effect="solid"
          events={['click']}
          isCapture
          scrollHide={false}
        >
          <div>
            <SketchPicker color={value} onChangeComplete={this.handleChange} />
          </div>
        </Tooltip>
      </div>
    )
  }
}

export default injectStyles<OuterProps>(styles)(ColorPicker)

import { Reducer } from 'redux'

import { MODALS_NAMES } from 'constants/modals'
import { Actions } from './actions'
import { Types } from 'lib/modules/open/modals/actionTypes'

interface ModalState {
  isOpen: boolean
  itemId?: number | string
}
export interface State {
  [key: string]: ModalState
}

const initialState: State = {
  [MODALS_NAMES.NEWS]: {
    isOpen: false,
    itemId: null
  },
  [MODALS_NAMES.ORGANISATION]: {
    isOpen: false,
    itemId: null
  },
  [MODALS_NAMES.ORDER]: {
    isOpen: false,
    itemId: null
  }
}

export const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case Types.OPEN_MODAL:
      return { ...state, [action.meta.name]: { isOpen: true, itemId: action.payload.itemId } }
    case Types.CLOSE_MODAL:
      return { ...state, [action.meta.name]: { isOpen: false, itemId: null } }
    default:
      return state
  }
}

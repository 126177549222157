import { takeEvery, put, select, call, all } from 'redux-saga/effects'

import { error } from 'react-notification-system-redux'
import { AxiosError } from 'axios'

import fetchAPI from 'lib/services/fetchAPI'
import { tokenSelector } from 'lib/modules/user/selectors'
import { METHOD, ENDPOINT } from 'constants/api'
import {
  ReadBackgrounds,
  readBackgroundsRequest,
  readBackgroundsSuccess,
  readBackgroundsFailure
} from './actions'
import types from './types'

function* readBackgrounds(action: ReadBackgrounds) {
  const token = yield select(tokenSelector)

  try {
    yield put(readBackgroundsRequest())
    const { data } = yield call(fetchAPI, {
      token,
      method: METHOD.GET,
      endpoint: `${ENDPOINT.WIDGETS}/backgrounds`
    })

    yield put(readBackgroundsSuccess(data))
  } catch (exception) {
    let errors = {
      _error: exception
    }

    if (exception.response) {
      errors = (exception as AxiosError).response.data.errors
    }

    yield put(readBackgroundsFailure())
    yield put(
      error({
        title: 'Не удалось получить фоновые изображения виджета'
      })
    )
  }
}

export function* watcher() {
  yield all([takeEvery(types.READ_BACKGROUNDS, readBackgrounds)])
}

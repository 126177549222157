import { Action } from 'redux'

import { NOTIFICATIONS } from 'constants/resources'
import { Types } from './actionTypes'

export interface GetNotifications extends Action<Types.GET_NOTIFICATIONS> {
  payload: {
    resourceType: string
    requestKey: string
  }
}
export interface ReadNotification extends Action<Types.READ_NOTIFICATIONS> {
  payload: {
    resourceType: string
    requestKey: string
    requestProperties: {
      id: number
    }
  }
}
export interface StartListeningNotifications extends Action<Types.START_LISTENING_NOTIFICATIONS> {}
export interface StopListeningNotifications extends Action<Types.STOP_LISTENING_NOTIFICATIONS> {}

export type Actions =
  | GetNotifications
  | ReadNotification
  | StartListeningNotifications
  | StopListeningNotifications

export const getNotifications: () => GetNotifications = () => ({
  type: Types.GET_NOTIFICATIONS,
  payload: {
    resourceType: NOTIFICATIONS.NAME,
    requestKey: NOTIFICATIONS.REQUESTS.GET_NOTIFICATIONS
  }
})
export const readNotification: (id: number) => ReadNotification = id => ({
  type: Types.READ_NOTIFICATIONS,
  payload: {
    resourceType: NOTIFICATIONS.NAME,
    requestKey: NOTIFICATIONS.REQUESTS.READ_NOTIFICATION,
    requestProperties: { id }
  }
})

export const startListeningNotifications: () => StartListeningNotifications = () => ({
  type: Types.START_LISTENING_NOTIFICATIONS
})
export const stopListeningNotifications: () => StopListeningNotifications = () => ({
  type: Types.STOP_LISTENING_NOTIFICATIONS
})

import { error } from 'react-notification-system-redux'
import { put, takeEvery } from 'redux-saga/effects'

import { BASE_CHANNEL } from 'constants/actionCable'
import ActionCableService from 'lib/services/actionCable'
import APIAction from 'lib/utils/APIAction'
import getResourceActions from 'lib/utils/getResourceActions'
import TYPES from './actionTypes'
import { ReadChartCategories } from './actions'

function* readChartCategories(action: ReadChartCategories) {
  const {
    payload: { resourceType, requestKey }
  } = action
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'READ',
    resourceType,
    requestKey
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      const {
        payload
      }: { payload: Array<Data.ChartCategory> } = yield ActionCableService.sendMessage({
        channelName: BASE_CHANNEL,
        type: requestKey,
        payload: {}
      })

      return { data: payload }
    },
    *success(data) {
      yield put(onSuccessAction(data))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось получить категории для графика.'
        })
      )
    }
  })
}

export function* watcher() {
  yield takeEvery(TYPES.READ_CHART_CATEGORIES, readChartCategories)
}

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux'
import { Field, FieldArrayFieldsProps } from 'redux-form'
import { Options, Option, LoadOptionsHandler } from 'react-select'
import injectStyles, { JSSProps } from 'react-jss'

import ActionCableService from 'lib/services/actionCable'
import { tokenSelector } from 'lib/modules/user/selectors'
import { BASE_CHANNEL } from 'constants/actionCable'
import { notEmpty } from 'lib/utils/validations'
import uniqueID from 'lib/utils/uniqueID'
import { Select, TextInput } from 'components/FormFields'
import {
  OPTION_TYPES,
  FIELD_TYPES,
  BOOLEAN_OPTIONS,
  COMPARE_OPERATORS,
  FILTERS_LINE_HEIGHT
} from 'constants/filter'

import styles from './styles'

interface OuterProps {
  name: string
  index: number
  fields: FieldArrayFieldsProps<Filter.Expression>
  selectedOption?: Filter.Option
  condition?: string
}
interface StateProps {
  token: string
}
interface DispatchProps {}
interface Props extends JSSProps<typeof styles>, StateProps, DispatchProps, OuterProps {}

interface State {}

class Result extends Component<Props, State> {
  private id: string

  constructor(props: Props) {
    super(props)

    this.id = uniqueID()
  }

  get key() {
    const { selectedOption, index } = this.props

    let key = this.id

    if (selectedOption) {
      const { cube_id, id } = selectedOption

      key = `${cube_id}_${id}_${index}`
    }

    return key
  }

  loadFilterValues: LoadOptionsHandler<string> = async query => {
    const { token, selectedOption } = this.props

    if (!selectedOption) {
      return { options: [] }
    }

    const { cube_id, id: filter, date_reg_exp } = selectedOption

    try {
      const { payload }: { payload: string[] } = await ActionCableService.sendMessage({
        channelName: BASE_CHANNEL,
        payload: { cube_id, filter, date_reg_exp, query, limit: 200 },
        type: 'GET_FILTER_VALUES',
        token
      })

      return {
        options: payload.map(item => ({ value: item, label: item }))
      }
    } catch (err) {
      return {
        options: []
      }
    }
  }

  formatUserEnterField = (value: Filter.UserEnterField | undefined) => (value ? value.value : '')

  parseUserEnterField = (value: string | undefined) =>
    value ? { value, type: FIELD_TYPES.ENTER } : ''

  formatBooleanField = (value: Filter.BooleanField | undefined) =>
    value ? value.value[0].toString() : null

  parseBooleanField = (value: string | Option<string> | undefined) => {
    if (value) {
      if (typeof value === 'string') {
        // OnBlur event with parsed value from store
        return { type: FIELD_TYPES.MULTI, value: [value.toLowerCase() === 'true'] }
      } else {
        // OnChange event with field
        return { type: FIELD_TYPES.MULTI, value: [value.value.toLowerCase() === 'true'] }
      }
    }

    return undefined
  }

  formatMultiField = (value: Filter.MultiField | undefined) =>
    (value ? value.value : []).map(label => ({ value: label, label }))

  parseMultiField = (value: Array<string> | Options<string> | undefined) => {
    if (value && value.length) {
      if (typeof value[0] === 'string') {
        // OnBlur event with parsed value from store
        return { type: FIELD_TYPES.MULTI, value }
      } else {
        // OnChange event with field
        return {
          type: FIELD_TYPES.MULTI,
          value: (value as Options<string>).map(item => item.value)
        }
      }
    }

    return null
  }

  render() {
    const { classes, name, selectedOption, condition, index } = this.props

    switch (selectedOption.filter_type) {
      case OPTION_TYPES.STRING: {
        return condition === COMPARE_OPERATORS.INCLUDE ||
          condition === COMPARE_OPERATORS.NOT_INCLUDE ? (
          <Field
            name={`${name}.field2`}
            parse={this.parseUserEnterField}
            format={this.formatUserEnterField}
            validate={[notEmpty]}
            className={classes.fieldInput}
            scale="small"
            component={TextInput}
          />
        ) : (
          <Field
            key={this.key}
            id={this.key}
            name={`${name}.field2`}
            parse={this.parseMultiField}
            format={this.formatMultiField}
            loadOptions={this.loadFilterValues}
            placeholder="Выберите значение"
            filterOptions={false}
            withAddingAllOptions
            multi
            autoBlur
            searchable
            isAsync
            validate={[notEmpty]}
            className={classes.fieldSelect}
            height={`calc(${FILTERS_LINE_HEIGHT} + 2px)`}
            component={Select}
          />
        )
      }
      case OPTION_TYPES.NUMBER:
        return (
          <Field
            name={`${name}.field2`}
            parse={this.parseUserEnterField}
            format={this.formatUserEnterField}
            validate={[notEmpty]}
            className={classes.fieldInput}
            scale="small"
            component={TextInput}
          />
        )
      case OPTION_TYPES.BOOLEAN:
        return (
          <Field
            name={`${name}.field2`}
            parse={this.parseBooleanField}
            format={this.formatBooleanField}
            options={BOOLEAN_OPTIONS}
            placeholder="Выберите значение"
            autoBlur
            searchable
            clearable={false}
            validate={[notEmpty]}
            className={classes.fieldSelect}
            height={`calc(${FILTERS_LINE_HEIGHT} + 2px)`}
            component={Select}
          />
        )
      case OPTION_TYPES.MULTI:
        return (
          <Field
            key={this.key}
            id={this.key}
            name={`${name}.field2`}
            parse={this.parseMultiField}
            format={this.formatMultiField}
            loadOptions={this.loadFilterValues}
            placeholder="Выберите значение"
            filterOptions={false}
            withAddingAllOptions
            multi
            autoBlur
            searchable
            isAsync
            validate={[notEmpty]}
            className={classes.fieldSelect}
            height={`calc(${FILTERS_LINE_HEIGHT} + 2px)`}
            component={Select}
          />
        )
      default:
        return undefined
    }
  }
}

const mapStateToProps: MapStateToProps<StateProps, Props, App.State> = (state, props) => ({
  token: tokenSelector(state)
})

export default compose<Props, OuterProps>(
  connect(
    mapStateToProps,
    null
  ),
  injectStyles(styles)
)(Result)

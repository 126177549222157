import { takeEvery, put, select, all, call } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { success, error } from 'react-notification-system-redux'
import { Options, Option } from 'react-select'
import {
  startSubmit,
  setSubmitSucceeded,
  setSubmitFailed,
  stopSubmit,
  getFormValues
} from 'redux-form'

import fetchAPI from 'lib/services/fetchAPI'
import APIAction from 'lib/utils/APIAction'
import getResourceActions from 'lib/utils/getResourceActions'
import { tokenSelector } from 'lib/modules/user/selectors'
import { SCANNER_EDITOR_FORM } from 'constants/forms'
import { METHOD, ENDPOINT } from 'constants/api'
import { CreateScanner, UpdateScanner, DeleteScanner, GetAllScanners } from './actions'
import types from './actionTypes'

function* getAllScanners(action: GetAllScanners) {
  const { requestKey, resourceType } = action.payload
  const token = yield select(tokenSelector)
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'READ',
    resourceType,
    requestKey
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        token,
        endpoint: ENDPOINT.SCANNERS
      })
    },
    *success(data) {
      yield put(onSuccessAction(data))
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось получить данные.'
        })
      )
    }
  })
}

function* createScanner(action: CreateScanner) {
  // TODO: uncomment
  // const { requestKey, resourceType, redirect } = action.payload
  // const allDimensions: Array<Data.Dimension> = yield select(getDimensions)
  // const token = yield select(tokenSelector)
  // const {
  //   title,
  //   category,
  //   conditions_array,
  //   dimensions,
  //   measures,
  //   violation
  // }: {
  //   title?: string
  //   category?: Option<string>
  //   conditions_array?: Array<Filter.Expression>
  //   dimensions?: Options<string>
  //   measures?: Options<string>
  //   violation: boolean
  // } = yield select(getFormValues(SCANNER_EDITOR_FORM.form))
  // const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
  //   actionType: 'CREATE',
  //   resourceType,
  //   requestKey
  // })
  // yield APIAction({
  //   *request() {
  //     yield put(startSubmit(SCANNER_EDITOR_FORM.form))
  //     yield put(onRequestAction())
  //     const dimensionList = dimensions.map(dimensions => ({
  //       id: dimensions.value,
  //       index: 0
  //     }))
  //     const dimensionFilters = []
  //     const measureFilters = []
  //     conditions_array.map(filter => {
  //       const { field1, field2, condition } = filter
  //       if (field1 && field1.value && condition && field2 && field2.value) {
  //         if (allDimensions.some(dimension => dimension.id === field1.value)) {
  //           dimensionFilters.push(filter)
  //         } else {
  //           measureFilters.push(filter)
  //         }
  //       }
  //     })
  //     const { data }: { data: Data.ScanerEntity } = yield call(fetchAPI, {
  //       token,
  //       method: METHOD.POST,
  //       endpoint: ENDPOINT.SCANNERS,
  //       body: {
  //         scanner: {
  //           title,
  //           conditions_array,
  //           category_id: category.value,
  //           category_name: category.label,
  //           chart_data: {
  //             category_id: category.value,
  //             measures: {
  //               filters: measureFilters,
  //               list: measures.map(measure => measure.value)
  //             },
  //             dimensions: {
  //               filters: dimensionFilters,
  //               list: dimensionList
  //             }
  //           },
  //           violation
  //         }
  //       }
  //     })
  //     return { data: [data] }
  //   },
  //   *success(data) {
  //     yield put(onSuccessAction(data))
  //     yield put(stopSubmit(SCANNER_EDITOR_FORM.form))
  //     yield put(setSubmitSucceeded(SCANNER_EDITOR_FORM.form))
  //     if (redirect) {
  //       yield put(push(redirect))
  //     }
  //   },
  //   *fail(errors) {
  //     yield put(onFailAction(errors))
  //     yield put(stopSubmit(SCANNER_EDITOR_FORM.form, errors))
  //     yield put(setSubmitFailed(SCANNER_EDITOR_FORM.form))
  //     yield put(
  //       error({
  //         title: 'Не удалось создать сканер.'
  //       })
  //     )
  //   }
  // })
}

function* updateScanner(action: UpdateScanner) {
  // TODO: uncomment
  // const { requestKey, resourceType, requestProperties } = action.payload
  // const allDimensions: Array<Data.Dimension> = yield select(getDimensions)
  // const token = yield select(tokenSelector)
  // const {
  //   title,
  //   category,
  //   conditions_array,
  //   dimensions,
  //   measures,
  //   violation
  // }: {
  //   title?: string
  //   category?: Option<string>
  //   conditions_array?: Array<Filter.Expression>
  //   dimensions?: Options<string>
  //   measures?: Options<string>
  //   violation: boolean
  // } = yield select(getFormValues(SCANNER_EDITOR_FORM.form))
  // const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
  //   actionType: 'UPDATE',
  //   resourceType,
  //   requestKey,
  //   ids: [requestProperties.id]
  // })
  // yield APIAction({
  //   *request() {
  //     yield put(startSubmit(SCANNER_EDITOR_FORM.form))
  //     yield put(onRequestAction())
  //     const dimensionList = dimensions.map(dimensions => ({
  //       id: dimensions.value,
  //       index: 0
  //     }))
  //     const dimensionFilters = []
  //     const measureFilters = []
  //     conditions_array.map(filter => {
  //       const { field1, field2, condition } = filter
  //       if (field1 && field1.value && condition && field2 && field2.value) {
  //         if (allDimensions.some(dimension => dimension.id === field1.value)) {
  //           dimensionFilters.push(filter)
  //         } else {
  //           measureFilters.push(filter)
  //         }
  //       }
  //     })
  //     const { data }: { data: Data.ScanerEntity } = yield call(fetchAPI, {
  //       token,
  //       method: METHOD.PUT,
  //       endpoint: ENDPOINT.SCANNERS,
  //       path: requestProperties.id,
  //       body: {
  //         scanner: {
  //           id: requestProperties.id,
  //           title,
  //           conditions_array,
  //           category_id: category.value,
  //           category_name: category.label,
  //           chart_data: {
  //             category_id: category.value,
  //             measures: {
  //               filters: measureFilters,
  //               list: measures.map(measure => measure.value)
  //             },
  //             dimensions: {
  //               filters: dimensionFilters,
  //               list: dimensionList
  //             }
  //           },
  //           violation
  //         }
  //       }
  //     })
  //     return { data: [data] }
  //   },
  //   *success(data) {
  //     yield put(onSuccessAction(data))
  //     yield put(stopSubmit(SCANNER_EDITOR_FORM.form))
  //     yield put(setSubmitSucceeded(SCANNER_EDITOR_FORM.form))
  //   },
  //   *fail(errors) {
  //     yield put(onFailAction(errors))
  //     yield put(stopSubmit(SCANNER_EDITOR_FORM.form, errors))
  //     yield put(setSubmitFailed(SCANNER_EDITOR_FORM.form))
  //     yield put(
  //       error({
  //         title: 'Не удалось обновить сканер.'
  //       })
  //     )
  //   }
  // })
}

function* deleteScanner(action: DeleteScanner) {
  const { requestKey, resourceType, requestProperties } = action.payload
  const token = yield select(tokenSelector)
  const { onRequestAction, onSuccessAction, onFailAction } = getResourceActions({
    actionType: 'DELETE',
    resourceType,
    requestKey,
    ids: [requestProperties.id],
    requestProperties
  })

  yield APIAction({
    *request() {
      yield put(onRequestAction())

      return yield call(fetchAPI, {
        token,
        method: METHOD.DELETE,
        endpoint: ENDPOINT.SCANNERS,
        path: requestProperties.id.toString()
      })
    },
    *success(data) {
      yield put(onSuccessAction([requestProperties.id]))

      yield put(
        success({
          title: 'Сканер успешно удален.'
        })
      )
    },
    *fail(errors) {
      yield put(onFailAction(errors))

      yield put(
        error({
          title: 'Не удалось удалить сканер.'
        })
      )
    }
  })
}

export function* watcher() {
  yield all([
    takeEvery(types.GET_ALL_SCANNERS, getAllScanners),
    takeEvery(types.CREATE_SCANNER, createScanner),
    takeEvery(types.UPDATE_SCANNER, updateScanner),
    takeEvery(types.DELETE_SCANNER, deleteScanner)
  ])
}

import { createSelector } from 'reselect'
import { getResources } from 'redux-resource'
import isEmpty from 'lodash/isEmpty'

import getResourceState from 'lib/utils/getResourceState'
import { ZONES, BRANCHES, GRBS } from 'constants/resources'
import { RATINGS, getIndex } from 'constants/map'
import { getState as getZonesState } from 'lib/modules/open/zones/selectors'
import { getState as getGrbsState } from 'lib/modules/open/grbs/selectors'
import { getState as getBranchesState } from 'lib/modules/branches/selectors'
import { ORGANISATIONS } from 'constants/resources'
import { STATES } from 'constants/states'

export type GrbsWithOrganisations = Data.Organisation & { children: Data.Organisation[] }

export const getState = (state): App.State['organisations'] => state.organisations

export const grbsSelector = createSelector(
  [getState],
  state => {
    const ids = state.lists[ORGANISATIONS.LISTS.ALL_GRBS]
    const orgs = getResources(state)
    if (ids) return orgs.filter(i => ids.includes(i.id))
    else return []
  }
)

export const grbsStatusSelector = createSelector(
  [(state: App.State) => state],
  state =>
    getResourceState(
      state,
      `${ORGANISATIONS.NAME}.requests.${ORGANISATIONS.REQUESTS.GET_ALL_GRBS}.status`
    )
)

export const organisationsByGrbsSelector = createSelector(
  [getState],
  state => {
    const res: { [key: string]: Data.Organisation[] } = {}
    const grbs_lists_keys = Object.keys(state.lists).filter(item => item.includes('grbs_'))

    grbs_lists_keys.forEach(
      key => (res[key.replace('grbs_', '')] = state.lists[key].map(id => state.resources[id]))
    )

    return res
  }
)

export const organisationsByGrbsIdStatusSelector = createSelector(
  [getState],
  state => {
    const { requests } = state
    const res: { [key: string]: STATES } = {}
    const requests_keys = Object.keys(requests).filter(item =>
      item.includes(ORGANISATIONS.REQUESTS.GET_GRBS_ORGANISATIONS)
    )

    requests_keys.forEach(key => {
      let status = STATES.MISSING

      if (requests[key]) {
        switch (requests[key].status) {
          case 'PENDING':
            status = STATES.LOADING
            break
          default:
            status = STATES.MISSING
        }
      }
      res[key.replace(`${ORGANISATIONS.REQUESTS.GET_GRBS_ORGANISATIONS}_`, '')] = status
    })

    return res
  }
)

export const organisationsSearchedSelector = createSelector(
  [getState],
  state =>
    state.lists[ORGANISATIONS.LISTS.SEARCH]
      ? state.lists[ORGANISATIONS.LISTS.SEARCH].map(i => state.resources[i])
      : null
)

export const organisationsSearchStatusSelector = createSelector(
  [(state: App.State) => state],
  state =>
    getResourceState(
      state,
      `${ORGANISATIONS.NAME}.requests.${ORGANISATIONS.REQUESTS.SEARCH_ORGANISATIONS}.status`
    )
)

export const grbsWithSearchedOrganisationsSelector = createSelector(
  [getState, grbsSelector],
  (state, allGrbs) => {
    const result: GrbsWithOrganisations[] = []

    const ids = state.lists[ORGANISATIONS.LISTS.SEARCH]
    if (!ids) return []

    const allGrbsIds = allGrbs.map(item => item.id)
    const grbsIds = ids.filter(i => allGrbsIds.includes(Number(i)))

    const organisations = getResources(state)
    const searchedOrgs = organisations.filter(item => ids.includes(Number(item.id)))

    const searchedByGrbs: { [key: string]: Data.Organisation[] } = {}
    searchedOrgs.forEach(org => {
      searchedByGrbs[org.grbs_id] = searchedByGrbs[org.grbs_id]
        ? [...searchedByGrbs[org.grbs_id], org]
        : [org]
    })

    Object.keys(searchedByGrbs).forEach(grbsKey => {
      result.push({
        ...organisations.find(item => item.id === Number(grbsKey)),
        children: searchedOrgs.filter(
          item => item.id !== item.grbs_id && item.grbs_id === Number(grbsKey)
        )
      })
    })

    return result
  }
)

export const organisationsByZone = createSelector(
  [getState, getZonesState],
  (orgState, zonesState) => {
    const result = {}
    const zoneIdsList = zonesState.lists[ZONES.LISTS.ZONES_LIST]

    if (!isEmpty(zoneIdsList)) {
      zoneIdsList.forEach(filterId => {
        const orgIds = orgState.lists[`zones_${filterId}`]
        const orgsList = []

        if (!isEmpty(orgIds)) orgIds.forEach(id => orgsList.push(orgState.resources[id]))

        result[filterId] = orgsList
      })
    }

    return result
  }
)
export const organisationsByBranch = createSelector(
  [getState, getBranchesState],
  (orgState, branchesState) => {
    const result = {}
    const branchesIdsList = branchesState.lists[BRANCHES.LISTS.ALL_LIST]

    if (!isEmpty(branchesIdsList)) {
      branchesIdsList.forEach(filterId => {
        const orgIds = orgState.lists[`branches_${filterId}`]
        const orgsList = []

        if (!isEmpty(orgIds)) orgIds.forEach(id => orgsList.push(orgState.resources[id]))

        result[filterId] = orgsList
      })
    }

    return result
  }
)

export const organisationsByGrbs = createSelector(
  [getState, getGrbsState],
  (orgState, grbsState) => {
    const result = {
      all: Object.values(grbsState.resources)
    }
    const IdsList = grbsState.lists[GRBS.LISTS.ALL_LIST]

    if (!isEmpty(IdsList)) {
      IdsList.forEach(filterId => {
        const orgIds = orgState.lists[`grbs_${filterId}`]
        const orgsList = []

        if (!isEmpty(orgIds)) orgIds.forEach(id => orgsList.push(orgState.resources[id]))

        result[filterId] = orgsList
      })
    }

    return result
  }
)

export const organisationsByRating = createSelector(
  [getGrbsState],
  grbsState => {
    const grbs_list = getResources<Data.Organisation>(grbsState, GRBS.LISTS.ALL_LIST)
    const res: { [key: string]: Data.Organisation[] } = {}

    RATINGS.forEach(rating => {
      res[rating.value] = [...grbs_list.filter(i => rating.test(i.indices))]
    })

    return res
  }
)

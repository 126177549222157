import React from 'react'
import { takeEvery, put, all, select, call, take, takeLatest } from 'redux-saga/effects'
import { error, success } from 'react-notification-system-redux'
import { AxiosError } from 'axios'

import fetchAPI from 'lib/services/fetchAPI'
import APIAction from 'lib/utils/APIAction'
import { objectToFormData } from 'lib/utils/objectToFormData'
import { tokenSelector } from 'lib/modules/user/selectors'
import {
  ReadPreview,
  ReadPreviewFile,
  ReadPreviewCube,
  AddData,
  addDataSuccess,
  readPreviewSuccess,
  readPreviewFailure
} from './actions'
import { Types } from './types'
import { PreviewSource } from 'constants/dataImport'
import { METHOD, ENDPOINT } from 'constants/api'
import { createCube } from 'lib/modules/data/cubes/actions'

function* readPreview(action: ReadPreview) {
  const token = yield select(tokenSelector)
  const {
    payload: { source_id, table }
  } = action

  try {
    const {
      data: { data, params }
    }: { data: { data: string[][]; params: Data.ColumnParams[] } } = yield call(fetchAPI, {
      method: METHOD.GET,
      endpoint: ENDPOINT.DB_SOURCE,
      path: `${source_id}/table_info`,
      params: { table },
      token
    })

    yield put(readPreviewSuccess({ headers: params, rows: data }))
  } catch (err) {
    yield put(readPreviewFailure(err))
  }
}

function* readPreviewFile(action: ReadPreviewFile) {
  const token = yield select(tokenSelector)
  const {
    payload: { file }
  } = action
  const cubeName = file.name ? file.name.split('.')[0] : 'Куб'

  try {
    const {
      data: { data, params, id }
    }: { data: { data: string[][]; params: Data.ColumnParams[]; id: number } } = yield call(
      fetchAPI,
      {
        method: METHOD.POST,
        endpoint: ENDPOINT.DATA_IMPORT,
        body: objectToFormData({ file }, 'import_file'),
        token
      }
    )
    yield put(readPreviewSuccess({ headers: params, rows: data, source_id: id }))

    yield put(createCube({ name: cubeName, nsi: false, columns_formats: [] }))

    yield put(success({ title: 'Данные из файла успешно импортированы' }))
  } catch (exception) {
    let errorEntity = exception
    const errorTitle = 'Не удалось импортировать данные'

    let errorText = ''

    if (exception.response) {
      errorEntity = (exception as AxiosError).response.data.error

      if (typeof errorEntity === 'string') {
        errorText = errorEntity
      }
    }

    yield put(readPreviewFailure({ error }))

    yield put(
      error({
        title: errorTitle,
        children: errorText ? <div dangerouslySetInnerHTML={{ __html: errorText }} /> : undefined,
        autoDismiss: errorText ? 10 : 2
      })
    )
  }
}

function* readPreviewCube(action: ReadPreviewCube) {
  const token = yield select(tokenSelector)
  const {
    payload: { id }
  } = action

  try {
    const {
      data: { data, params }
    }: { data: { data: string[][]; params: Data.ColumnParams[] } } = yield call(fetchAPI, {
      method: METHOD.GET,
      endpoint: ENDPOINT.CUBES,
      path: `${id}`,
      token
    })

    yield put(readPreviewSuccess({ headers: params, rows: data }))
  } catch (err) {
    yield put(readPreviewFailure(err))
  }
}

function* addData(action: AddData) {
  const token = yield select(tokenSelector)
  const {
    payload: { id, data }
  } = action

  try {
    yield call(fetchAPI, {
      method: METHOD.POST,
      endpoint: ENDPOINT.CUBES,
      path: `${id}/add_data`,
      body: objectToFormData({ data }, 'cube'),
      token
    })

    // @ts-ignore
    yield put(addDataSuccess(data))

    yield put(success({ title: 'Данные успешно добавлены' }))
  } catch (err) {
    yield put(error({ title: 'Не удалось добавить строку' }))
  }
}

export function* watcher() {
  yield all([
    takeLatest(Types.READ_PREVIEW, readPreview),
    takeLatest(Types.READ_PREVIEW_CUBE, readPreviewCube),
    takeLatest(Types.READ_PREVIEW_FILE, readPreviewFile),
    takeLatest(Types.ADD_DATA, addData)
  ])
}

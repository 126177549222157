import { Action } from 'redux'
import { Types } from './actionTypes'

export interface ReadMsp extends Action<Types.READ_MSP> {}
export interface ReadMspLoading extends Action<Types.READ_MSP_LOADING> {}
export interface ReadMspFailure extends Action<Types.READ_MSP_FAILURE> {}
export interface ReadMspSuccess extends Action<Types.READ_MSP_SUCCESS> {
  payload: {
    data: Data.MspCubeItem[]
    cube_id: number
  }
}
export interface UpdateMsp extends Action<Types.UPDATE_MSP> {
  payload: File
}
export interface UpdateMspLoading extends Action<Types.UPDATE_MSP_LOADING> {}
export interface UpdateMspFailure extends Action<Types.UPDATE_MSP_FAILURE> {}
export interface UpdateMspSuccess extends Action<Types.UPDATE_MSP_SUCCESS> {
  payload: Data.MspCubeItem[]
}

export type ActionTypes =
  | ReadMsp
  | ReadMspLoading
  | ReadMspFailure
  | ReadMspSuccess
  | UpdateMsp
  | UpdateMspLoading
  | UpdateMspSuccess
  | UpdateMspFailure

export const readMsp: () => ReadMsp = () => ({
  type: Types.READ_MSP
})

export const readMspLoading: () => ReadMspLoading = () => ({
  type: Types.READ_MSP_LOADING
})

export const readMspFailure: () => ReadMspFailure = () => ({
  type: Types.READ_MSP_FAILURE
})

export const readMspSuccess: (data: {
  data: Data.MspCubeItem[]
  cube_id: number
}) => ReadMspSuccess = data => ({
  type: Types.READ_MSP_SUCCESS,
  payload: data
})

export const updateMsp: (file: File) => UpdateMsp = file => ({
  type: Types.UPDATE_MSP,
  payload: file
})

export const updateMspLoading: () => UpdateMspLoading = () => ({
  type: Types.UPDATE_MSP_LOADING
})

export const updateMspFailure: () => UpdateMspFailure = () => ({
  type: Types.UPDATE_MSP_FAILURE
})

export const updateMspSuccess: (data: Data.MspCubeItem[]) => UpdateMspSuccess = data => ({
  type: Types.UPDATE_MSP_SUCCESS,
  payload: data
})

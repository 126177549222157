import { Reducer } from 'redux'

import types from './types'
import { ActionTypes } from './actions'
import { STATES } from 'constants/states'

export interface State {
  status: STATES
  list: { [key: string]: string }
}

const initialState: State = {
  status: STATES.IDLE,
  list: {}
}

export const reducer: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case types.READ_BACKGROUNDS_REQUEST:
      return { ...state, status: STATES.LOADING }
    case types.READ_BACKGROUNDS_SUCCESS:
      return { ...state, status: STATES.SUCCEEDED, list: action.payload.list }
    case types.READ_BACKGROUNDS_FAILURE:
      return { ...state, status: STATES.FAILED }
    default:
      return state
  }
}

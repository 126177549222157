enum Types {
  GET_FORM = 'GET_FORM',
  GET_FORMS = 'GET_FORMS',
  CREATE_FORM = 'CREATE_FORM',
  UPDATE_FORM = 'UPDATE_FORM',
  DELETE_FORM = 'DELETE_FORM',
  ANSWER_FORM = 'ANSWER_FORM'
}

export default Types

import { Reducer } from 'redux'

import { sidemenuRoutes } from 'src/routes'
import { STATES } from 'constants/states'
import types from './types'
import { ActionTypes } from './actions'

export interface SidemenuState {
  items: Array<{ id: string; notification: boolean; notificationsCount?: number }>
  resourcesCount: {
    [key: string]: number
  }
  resourcesCountStatus: keyof typeof STATES
  breadcrumbs: Array<Data.Breadcrumbs>
}

const initialState = {
  items: [
    { id: 'notifications', notification: false },
    ...sidemenuRoutes.map(item => ({
      id: item.path.match(/^(?:\/)(\w+)\/?/)[1],
      notification: false
    }))
  ],
  resourcesCount: {},
  breadcrumbs: [],
  resourcesCountStatus: STATES.IDLE
}

export const reducer: Reducer<SidemenuState, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case types.SIDEMENU_ITEM_SET_NOTIFICATION: {
      const { id, count } = action.payload
      if (state.items.find(i => i.id === id)) {
        return {
          ...state,
          items: [
            ...state.items.filter(i => i.id !== id),
            { id, notification: true, notificationsCount: count }
          ]
        }
      } else {
        return state
      }
    }
    case types.SIDEMENU_ITEM_REMOVE_NOTIFICATION: {
      const { id } = action.payload
      if (state.items.find(i => i.id === id)) {
        return {
          ...state,
          items: [...state.items.filter(i => i.id !== id), { id, notification: false }]
        }
      } else {
        return state
      }
    }
    case types.GET_RESOURCES_COUNT_REQUEST:
      return { ...state, resourcesCountStatus: STATES.LOADING }
    case types.CHANGE_BREADCRUMBS:
      return { ...state, breadcrumbs: action.payload }
    case types.GET_RESOURCES_COUNT_SUCCESS:
      return {
        ...state,
        resourcesCount: action.payload,
        resourcesCountStatus: STATES.SUCCEEDED
      }
    case types.GET_RESOURCES_COUNT_FAILURE:
      return { ...state, resourcesCountStatus: STATES.FAILED }
    default:
      return state
  }
}

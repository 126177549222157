import colorConverter from 'color-convert'

import { Props } from './index'

const styles = (theme: App.Theme) => ({
  container: {
    position: 'relative',
    display: 'block',
    width: '2em',
    height: '2em',
    fontSize: '1rem',
    padding: '0 0.5em',
    borderStyle: 'solid',
    borderWidth: ({ squared }: Props) => (squared ? '2px' : '1px'),
    borderRadius: ({ squared }: Props) => (squared ? '0.5em' : '50%'),
    boxSizing: 'border-box',
    transition: 'background-color 0.3s ease, border-color 0.3s ease, opacity 0.15s linear',
    cursor: 'pointer',
    outline: 'none',
    '&:hover $icon': {
      stroke: '#fff',
      fill: '#fff'
    },
    '&:disabled': {
      opacity: 0.8,
      cursor: 'default'
    }
  },
  icon: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    transition: 'fill 0.3s ease, stroke 0.3s ease',
    '& svg': {
      stroke: theme.colors.blue,
      width: '14px',
      height: '14px',
    }
  },
  default: {
    backgroundColor: theme.colors.button,
    borderColor: ({ squared }: Props) => {
      if (squared) {
        const color = colorConverter.hex.rgb(theme.colors.grey)

        return `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.1)`
      } else {
        return theme.colors.icon.border
      }
    },
    '&:hover': {
      backgroundColor: theme.colors.blue,
      borderColor: theme.colors.blue
    },
    '&:focus': {
      borderColor: theme.colors.blue
    }
  },
  danger: {
    backgroundColor: '#FFEBEA',
    borderColor: '#FFEBEA',
    '&:hover': {
      backgroundColor: theme.colors.button.danger.color,
      borderColor: theme.colors.button.danger.color
    },
    '&:focus': {
      borderColor: theme.colors.button.danger.color
    },
    '&$filled': {
      backgroundColor: theme.colors.button.danger.color,
      borderColor: theme.colors.button.danger.color
    }
  },
  filled: {
    backgroundColor: theme.colors.blue,
    borderColor: theme.colors.blue,
    '& $icon': {
      stroke: '#fff',
      fill: '#fff'
    }
  }
})

export { styles as default }

export const sizes = {
  header: {
    height: '56px'
  },
  infoSystems: {
    width: '125px',
    widthClosed: '40px'
  },
  sidemenu: {
    headerPlaceholderSize: '32px',
    height: '56px',
    itemIcon: '32px'
  },
  users: {
    // !! rowHeight must be rem
    rowHeight: '3.75rem',
    placeholderHeight: '1.2rem',
    listMaxHeight: '40vh',
    listLeftMargin: '1.25rem'
  },
  material: { table: { head: { rowHeight: 56 } } }
}

import { Action, ActionCreator } from 'redux'

import { BRANCHES } from 'constants/resources'
import types from './actionTypes'

export interface GetBanches extends Action<types.GET_BRANCHES_LIST> {
  payload: {
    resourceType: string
    requestKey: string
    list: string
  }
}
export interface UpdateBranch extends Action<types.UPDATE_BRANCH> {
  payload: {
    resourceType: string
    requestKey: string
    requestProperties: {
      id: Data.Branch['id']
    }
    icon: File
  }
}

export type Actions = GetBanches

export const getBranches: ActionCreator<GetBanches> = () => ({
  type: types.GET_BRANCHES_LIST,
  payload: {
    resourceType: BRANCHES.NAME,
    requestKey: BRANCHES.REQUESTS.GET_BRANCHES_LIST,
    list: BRANCHES.LISTS.ALL_LIST
  }
})
export const updateBranch: ActionCreator<UpdateBranch> = (
  branchID: Data.Branch['id'],
  icon: File
) => ({
  type: types.UPDATE_BRANCH,
  payload: {
    resourceType: BRANCHES.NAME,
    requestKey: BRANCHES.REQUESTS.UPDATE_BRANCH,
    requestProperties: {
      id: branchID
    },
    icon
  }
})

import cn from 'classnames'
import { colors } from 'lib/theme/colors'
import React, { MouseEventHandler, Ref } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import styles from './styles'

type BackgroundKeys = keyof typeof colors.background | string

export interface OuterProps {
  onClick?: MouseEventHandler
  color?: string
  children?: any

  hide?: boolean

  fontWeight?: string
  fontSize?: string

  whiteSpace?: string

  margin?: string
  marginTop?: string
  marginBottom?: string
  marginLeft?: string
  marginRight?: string

  width?: string
  minWidth?: string
  maxWidth?: string
  height?: string
  maxHeight?: string
  minHeight?: string

  boxSizing?: 'border-box' | 'initial'
  padding?: string
  paddingTop?: string
  paddingBottom?: string
  paddingLeft?: string
  paddingRight?: string

  position?: string
  left?: string
  right?: string
  bottom?: string
  top?: string
  zIndex?: number

  className?: string

  overflow?: string
  overflowX?: string
  overflowY?: string

  display?: string
  flexDirection?: string
  flexWrap?: string
  flexBasis?: string
  flex?: string
  gap?: string
  justifyContent?: string
  alignItems?: string

  background?: BackgroundKeys
  backgroundColor?: string

  border?: string
  borderRight?: string
  borderLeft?: string
  borderBottom?: string
  borderTop?: string
  borderRadius?: string

  cursor?: string
  forwardRef?: Ref<any>
}
export interface Props extends OuterProps, JSSProps<typeof styles> {}

const CustomDiv = ({ children, className, classes, onClick, hide, forwardRef }) => {
  if (hide) return null

  return (
    <div ref={forwardRef} onClick={onClick} className={cn(classes.container, className)}>
      {children}
    </div>
  )
}

export default injectStyles<OuterProps>(styles)(CustomDiv)

const styles = (theme: App.Theme) => ({
  fieldInput: {
    flex: '1 1 44%',
    maxWidth: '50%',
    '& > div': {
      padding: 'calc(0.334em + 1px) 0.45em',
      fontSize: '0.75rem'
    }
  },
  fieldSelect: {
    flex: '1 1 44%',
    maxWidth: '50%',
    '& .Select-value': {
      marginTop: '0 !important',
      marginBottom: '0 !important'
    }
  },
  selectTooltipButton: {
    flex: '1 1 44%',
    maxWidth: '50%'
  }
})

export default styles

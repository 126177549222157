import { Reducer } from 'redux'

import types from './actionTypes'
import { Actions } from './actions'
import { STATES } from 'constants/states'

export interface State {
  url: string
}

const intialState: State = {
  url: undefined
}

export const reducer: Reducer<State, Actions> = (state = intialState, action) => {
  switch (action.type) {
    case types.OPEN_PDF:
      return { ...state, url: action.payload, state: STATES.LOADING }
    case types.CLOSE_PDF:
      return { ...state, url: null, state: STATES.SUCCEEDED }
    default:
      return state
  }
}

import React, { Component, ReactInstance } from 'react'
import { findDOMNode } from 'react-dom'
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux'
import { DropTargetMonitor } from 'react-dnd'
import injectStyles, { JSSProps, withTheme } from 'react-jss'
import { compose } from 'recompose'
import { ExpandMore, ExpandLess } from '@mui/icons-material'

import AggregationService from 'lib/services/aggregation'
import { getTargetGroupID } from 'lib/modules/aggregations/selectors'
import { openMenu } from 'lib/modules/aggregations/actions'
import {
  Grouping as GroupingIcon,
  Settings as SettingsIcon,
  Cancel as CancelIcon
} from 'components/Icons'
import styles from './styles'
import { AggregationItemProps } from '../Item'
import { DragAndDropTypes } from '../../dndTypes'

interface OuterProps {
  group: Data.AggregationGroup
  index: number
  isSelected: boolean
  onToggleGroup: (groupID: Data.AggregationGroup['id']) => void
  onRemove: (group: Data.AggregationGroup) => void
}
interface StateProps {
  isMenuTarget: boolean
  isMenuOpened: boolean
  menu: App.State['aggregations']['menu']
}
interface DispatchProps {
  openMenu: (menu: {
    anchor: HTMLDivElement
    targetGroup: Data.AggregationGroup
    sourceItem?: Data.AggregationEntity | Data.Dimension | Data.Measure
    sourceItemIsAggregation?: boolean
  }) => void
}
interface DragCollectProps {
  isOver: boolean
  canDrop: boolean
}
export interface Props
  extends JSSProps<typeof styles>,
  StateProps,
  DispatchProps,
  DragCollectProps,
  OuterProps { }

interface State { }

const DROP_TARGET = {
  name: [DragAndDropTypes.AggregationItem],
  source: {
    canDrop(props: Props, monitor: DropTargetMonitor) {
      const item = monitor.getItem() as AggregationItemProps
      const targetIsDimension = AggregationService.isDimension(item.aggregation.agg)

      return !targetIsDimension
    },
    drop(props: Props, monitor: DropTargetMonitor, component: ReactInstance) {
      const { group, openMenu } = props
      const item = monitor.getItem() as AggregationItemProps
      const sourceItem = item.aggregation
      const element = findDOMNode(component) as HTMLDivElement

      openMenu({
        anchor: element,
        targetGroup: group,
        sourceItem,
        sourceItemIsAggregation: true
      })

      return props
    }
  },
  collect(monitor) {
    return {
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }
  }
}

class AggregationsGroup extends Component<Props, State> {
  get backgroundColor() {
    const { isSelected, canDrop, theme } = this.props

    return canDrop || isSelected
      ? theme.colors.aggregation.groupActive
      : theme.colors.aggregation.group
  }

  get title() {
    return this.props.group.aggregations.reduce((result, aggregation, index) => {
      if (index) {
        result += ', '
      }

      return (result += aggregation.name)
    }, 'Группа: ')
  }

  onDoubleClick = () => {
    const { group, onRemove } = this.props

    onRemove(group)
  }

  onOpenMenu = () => {
    const { group, openMenu } = this.props
    const anchor = findDOMNode(this) as HTMLDivElement

    openMenu({
      anchor,
      targetGroup: group
    })
  }

  onToggleGroup = () => {
    const { group, onToggleGroup } = this.props

    onToggleGroup(group.id)
  }

  render() {
    const {
      classes,
      index,
      theme,
      isSelected,
      isOver,
      canDrop,
      isMenuTarget,
      isMenuOpened,
      onRemove,
      onToggleGroup,
      openMenu,
      menu,
      ...props
    } = this.props

    return (
      <div
        className={classes.container}
        title={this.title}
        style={{ backgroundColor: this.backgroundColor }}
        {...props}
      >
        <span className={classes.iconContainer}>
          <GroupingIcon className={classes.icon} />
        </span>
        <div className={classes.sets} onDoubleClick={this.onDoubleClick}>
          {(this.props.group && this.props.group.title) || `Группа ${index + 1}`}
        </div>
        <button className={classes.settingsButton} type="button" onClick={this.onToggleGroup}>
          {isSelected ? (
            <CancelIcon className={classes.settingsIcon} />
          ) : (
            <SettingsIcon className={classes.settingsIcon} />
          )}
        </button>
        <button className={classes.arrowButton} type="button" onClick={this.onOpenMenu}>
          {isMenuTarget && isMenuOpened ? (
            <ExpandLess className={classes.arrowIcon} />
          ) : (
            <ExpandMore className={classes.arrowIcon} />
          )}
        </button>
      </div>
    )
  }
}

const mapStateToProps: MapStateToProps<StateProps, Props, App.State> = (state, props) => ({
  isMenuTarget: getTargetGroupID(state) === props.group.id,
  isMenuOpened: !!state.aggregations.menu.anchor,
  menu: state.aggregations.menu
})

const mapDispatchToProps: MapDispatchToProps<DispatchProps, Props> = dispatch => ({
  openMenu: options => dispatch(openMenu(options))
})

export default compose<Props, OuterProps>(
  withTheme,
  injectStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(AggregationsGroup)

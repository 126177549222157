const styles = (theme: App.Theme) => ({
  container: {
    position: 'fixed',
    top: theme.sizes.header.height,
    left: theme.sizes.sidemenu.height,
    bottom: 0,
    right: 0,
    display: 'flex',
    background: theme.colors.white,
    borderRight: '1px solid rgba(44, 83, 140, 0.1)',
    zIndex: 1000
  },
  containerWide: {
    right: theme.sizes.infoSystems.widthClosed
  },
  iframe: {
    display: 'flex',
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    border: 'none'
  }
})

export { styles as default }

import { Reducer } from 'redux'

import types from './actionTypes'
import { Actions } from './actions'
import { STATES } from 'constants/states'

export interface State {
  entity: Data.DesktopPanel | undefined
  state: STATES
}

const intialState: State = {
  entity: undefined,
  state: STATES.IDLE
}

export const reducer: Reducer<State, Actions> = (state = intialState, action) => {
  switch (action.type) {
    case types.DESKTOP_REQUEST:
      return { ...state, state: STATES.LOADING }
    case types.DESKTOP_SUCCESS:
      return { ...state, entity: action.payload, state: STATES.SUCCEEDED }
    case types.DESKTOP_FAILURE:
      return { ...state, state: STATES.FAILED }
    default:
      return state
  }
}

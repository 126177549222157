export enum Types {
  INIT_MANAGER_STATE = '@RESOURCE_MANAGER/INIT_MANAGER_STATE',
  DESTROY_MANAGER_STATE = '@RESOURCE_MANAGER/DESTROY_MANAGER_STATE',
  CHANGE_MANAGER_STATE = '@RESOURCE_MANAGER/CHANGE_MANAGER_STATE',
  READ_RESOURCES = '@RESOURCE_MANAGER/READ_RESOURCES',
  READ_MORE_RESOURCES = '@RESOURCE_MANAGER/READ_MORE_RESOURCES',
  CHANGE_REQUEST_STATE = '@RESOURCE_MANAGER/CHANGE_REQUEST_STATE',
  CHANGE_LOADING_BUTTON = '@RESOURCE_MANAGER/CHANGE_LOADING_BUTTON',
  CHANGE_SORTING = '@RESOURCE_MANAGER/CHANGE_SORTING',
  CHANGE_FILTER = '@RESOURCE_MANAGER/CHANGE_FILTER',
  CHANGE_SEARCH = '@RESOURCE_MANAGER/CHANGE_SEARCH'
}

export default Types

import { takeEvery, put, select, call, all } from 'redux-saga/effects'
import { AxiosError } from 'axios'

import fetchAPI from 'lib/services/fetchAPI'
import { tokenSelector } from 'lib/modules/user/selectors'
import { METHOD, ENDPOINT } from 'constants/api'
import {
  ReadVehicles,
  readVehiclesLoading,
  readVehiclesFailure,
  readVehiclesSuccess
} from './actions'
import types from './types'

function* readVehicles(action: ReadVehicles) {
  const token = yield select(tokenSelector)

  try {
    yield put(readVehiclesLoading())
    const { data }: { data: Data.Vehicle[] } = yield call(fetchAPI, {
      // token,
      method: METHOD.GET,
      endpoint: ENDPOINT.VEHICLES
    })
    yield put(readVehiclesSuccess(data))
  } catch (error) {
    console.error(error)

    yield put(readVehiclesFailure())
  }
}

export function* watcher() {
  yield all([takeEvery(types.READ_VEHICLES, readVehicles)])
}

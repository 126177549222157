import { createStore, applyMiddleware, Store } from 'redux'
import { composeWithDevTools } from '@redux-devtools/extension';

import rootReducer from './reducer'
import sagas from './sagas'
import middlewares, { sagaMiddleware } from './middleware'

const composeEnhancers = composeWithDevTools({
  // Specify here name, actionsBlacklist, actionsCreators and other options
});

function mainStore(initialState: App.State): Store<App.State, App.AllActions> {
  const store: Store<App.State, App.AllActions> = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  )

  sagas.forEach(saga => sagaMiddleware.run(saga))

  return store
}

export default mainStore

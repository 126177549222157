export enum STATUS {
  CONNECTED = 'CONNECTED',
  CONNECTING = 'CONNECTING',
  DISCONNECTED = 'DISCONNECTED',
  DISCONNECTING = 'DISCONNECTING'
}

export const BASE_CHANNEL = 'ChatChannel'
export const NOTIFICATIONS_CHANNEL = 'NotificationChannel'
export const INCIDENTS_CHANNEL = 'IncidentChatChannel'

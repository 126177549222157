enum ActionTypes {
  READ_IMPORT_FILES = 'READ_IMPORT_FILES',
  READ_IMPORT_FILES_LOADING = 'READ_IMPORT_FILES_LOADING',
  READ_IMPORT_FILES_SUCCESS = 'READ_IMPORT_FILES_SUCCESS',
  READ_IMPORT_FILES_FAILURE = 'READ_IMPORT_FILES_FAILURE',
  UPDATE_IMPORT_FILE = 'UPDATE_IMPORT_FILES',
  REPLACE_IMPORT_FILE_DATA = 'REPLACE_IMPORT_FILE_DATA',
  TOGGLE_SHOW = 'TOGGLE_SHOW'
}

export default ActionTypes

enum ActionTypes {
  AUTH_REQUEST = 'AUTH_REQUEST',
  AUTH_SUCCESS = 'AUTH_SUCCESS',
  AUTH_FAILURE = 'AUTH_FAILURE',
  AUTH_REFRESH_REQUEST = 'AUTH_REFRESH_REQUEST',
  AUTH_REFRESH_SUCCESS = 'AUTH_REFRESH_SUCCESS',
  AUTH_REFRESH_FAILURE = 'AUTH_REFRESH_FAILURE',
  AUTH_RESET = 'AUTH_RESET',
  AUTH_RESET_REQUEST = 'AUTH_RESET_REQUEST',
  AUTH_SET_TOKEN = 'AUTH_SET_TOKEN',

  GET_USER_INFO = 'GET_USER_INFO',
  GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST',
  GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS',
  GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE',

  UPDATE_PROFILE_INFO = 'UPDATE_PROFILE_INFO',
  UPDATE_PROFILE_INFO_REQUEST = 'UPDATE_PROFILE_INFO_REQUEST',
  UPDATE_PROFILE_INFO_SUCCESS = 'UPDATE_PROFILE_INFO_SUCCESS',
  UPDATE_PROFILE_INFO_FAILURE = 'UPDATE_PROFILE_INFO_FAILURE'
}

export default ActionTypes

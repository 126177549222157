import { Reducer } from 'redux'

import types from './actionTypes'
import { Actions } from './actions'

export interface State {
  width: number
  height: number
}

const intialState: State = {
  width: 0,
  height: 0
}

export const reducer: Reducer<State, Actions> = (state = intialState, action) => {
  switch (action.type) {
    case types.CHANGE_SIZE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

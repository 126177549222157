import { HEIGHT, PADDING_HORIZONTAL } from 'components/Select/styles'
import { Props } from './index'

const styles = (theme: App.Theme) => ({
  label: {
    marginBottom: '8px',
  },
  selector: {
    fontSize: '1rem',
    lineHeight: 1.2,
    width: '100%',
    '& .Select-control': {
      display: 'flex',
      alignItems: 'center',
      width: `calc(100% - 2px)`,
      height: 'auto',
      backgroundColor: ({ isSearch, withBackground, backgroundColor }: Props) =>
        isSearch || withBackground ? backgroundColor || '#fff' : 'transparent',
      borderColor: theme.colors.input.border,
      borderRadius: 8,
      border: `1px solid ${theme.colors.input.border}`,
      color: '#000',
      minHeight: ({ height }: Props) => HEIGHT,
      boxSizing: 'content-box',
      '& .Select-input:focus': {
        backgroundColor: ({ isSearch, withBackground, backgroundColor }: Props) =>
          isSearch || withBackground ? backgroundColor || '#fff' : 'transparent'
      }
    },
    '& .Select-placeholder, & .Select-value': {
      paddingLeft: `${PADDING_HORIZONTAL} !important`,
      paddingRight: `${PADDING_HORIZONTAL} !important`,
      lineHeight: ({ height }: Props) => `${height || HEIGHT} !important`
    },
    '& .Select-placeholder': {
      color: theme.colors.grey,
      lineHeight: ({ height }: Props) => height || HEIGHT
    },
    '& .Select-arrow-zone': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '1rem',
      paddingRight: `${PADDING_HORIZONTAL} !important`,
      paddingLeft: `${PADDING_HORIZONTAL} !important`,
      boxSizing: 'content-box'
    },
    '& .Select-menu-outer': {
      borderBottomRightRadius: 3,
      borderBottomLeftRadius: 3,
      borderColor: theme.colors.input.border,
      boxShadow: 'none',
      zIndex: 5
    },
    '& .Select-input': {
      height: ({ height }: Props) => height || HEIGHT,
      '& > input': {
        padding: 0,
        lineHeight: ({ height }: Props) => height || HEIGHT
      }
    },
    '&.Select.is-focused .Select-control, &.Select.is-focused:not(.is-open) .Select-control': {
      backgroundColor: ({ isSearch, withBackground, backgroundColor }: Props) =>
        isSearch || withBackground ? backgroundColor || '#fff' : 'transparent'
    },
    '& .Select-multi-value-wrapper': {
      position: 'relative',
      flexGrow: 1
    },
    // Multi choice
    '&.Select--multi .Select-multi-value-wrapper': {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      maxHeight: '16rem',
      overflowY: 'auto'
    },
    '&.Select--multi .Select-value': {
      display: 'flex',
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
      lineHeight: '1.2rem !important',
      marginBottom: 5
    },
    '&.no-border': {
      '& .Select-control': {
        border: 'none !important',
        boxShadow: 'none !important'
      }
    },
    '&.no-background': {
      '& .Select-control': {
        background: 'none !important'
      }
    }
  },
  selectorVirtualized: {
    '& .VirtualizedSelectOption': {
      display: 'inline-block',
      lineHeight: '35px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    }
  },
  selectorOption: {
    paddingLeft: PADDING_HORIZONTAL,
    paddingRight: PADDING_HORIZONTAL
  }
})

export default styles

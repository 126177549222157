import React from 'react'
import JssProvider from 'react-jss'
import { create } from 'jss'
import jssDefaultPreset from 'jss-preset-default'

const jss = create({
  ...jssDefaultPreset(),
  // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
  insertionPoint: 'jss-insertion-point'
})

const StylesProvider = ({ children }) => children

export default StylesProvider

import React, { FC, useEffect } from 'react'
import injectStyles, { JSSProps } from 'react-jss'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import {
  Field,
  reduxForm,
  getFormValues,
  getFormSyncErrors,
  isValid,
  InjectedFormProps
} from 'redux-form'

import { compose } from 'recompose'

import { updateProfileInfo } from 'lib/modules/user/actions'
import { validateRepeat } from 'lib/utils/validations'
import { minLength } from 'lib/utils/validations'
import { STATES } from 'constants/states'
import { USER_SETTINGS_FORM } from 'constants/forms'
import { TextInput } from 'components/FormFields'
import Button from 'components/Button'
import Modal from 'components/Modal/components/Base'
import { Save, Settings, SvgCloseButton } from 'components/Icons'
import LoadingOverlay from 'components/LoadingOverlay'
import styles from './styles'

export interface FormValues {
  email?: string
  password?: string
  customer?: string
}
interface OuterProps {
  isOpen: boolean
  closeModal: () => void
}
interface StateProps {
  userEmail: string
  userName: string
  loading: STATES
  values?: FormValues
  errors: any
  isValid: boolean
}
interface DispatchProps {
  updateProfileInfo: () => void
}
interface Props
  extends InjectedFormProps<FormValues, Props>,
  JSSProps<typeof styles>,
  StateProps,
  DispatchProps,
  OuterProps { }

const UserSettings: FC<Props> =  (props) => {
  useEffect(() => {
    if (props.values?.email !== props.userEmail) updateForm(props)
  }, [props])

  const updateForm = (props: Props) => {
    const { userEmail, userName, change } = props

    change('email', userEmail)
    change('customer', userName)
  }

  const validateRepeatPassword = validateRepeat('password', 'Пароли не совпадают')
  const validateLengthPassword = minLength(6)

  const onSubmit = () => {
    const { errors, invalid, touch, updateProfileInfo } = props

    if (!invalid) {
      updateProfileInfo()
    } else {
      Object.keys(errors).forEach(field => touch(field))
    }
  }

  const { classes, values = {}, submitting, isOpen, closeModal, loading } = props

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <form action="/" className={classes.modal}>
        <LoadingOverlay show={loading === STATES.LOADING || submitting} />
        <div className={classes.modalHeader}>
          <Settings style={{ width: '28px', height: '28px' }} />
          <h3 className={classes.modalTitle}>Настройки аккаунта</h3>
          <SvgCloseButton width={'28px'} height={'28px'} cursor="pointer" onClick={closeModal} />
        </div>
        <div className={classes.modalContent}>
          <div className={classes.modalField}>
            <label className={classes.modalFieldLabel} htmlFor="email">
              E-mail
            </label>
            <Field
              id="email"
              className={classes.modalFieldInput}
              name="email"
              component={TextInput}
              scale="small"
              disabled
            />
          </div>
          <div className={classes.modalField}>
            <label className={classes.modalFieldLabel} htmlFor="password">
              Новый пароль
            </label>
            <Field
              className={classes.modalFieldInput}
              name="password"
              type="password"
              component={TextInput}
              scale="small"
              defaultValue=""
              autoComplete="new-password"
              validate={validateLengthPassword}
              withoutDelay
            />
          </div>
          {!!values.password && (
            <div className={classes.modalField}>
              <label className={classes.modalFieldLabel} htmlFor="repeatPassword">
                Повторите новый пароль
              </label>
              <Field
                className={classes.modalFieldInput}
                name="repeatPassword"
                type="password"
                component={TextInput}
                scale="small"
                autoComplete="new-password"
                validate={validateRepeatPassword}
                withoutDelay
              />
            </div>
          )}
          <div className={classes.modalField}>
            <label className={classes.modalFieldLabel} htmlFor="customer">
              Текущий пользователь
            </label>
            <Field
              id="customer"
              className={classes.modalFieldInput}
              name="customer"
              component={TextInput}
              scale="small"
              disabled
            />
          </div>
        </div>
        <div className={classes.modalFooter}>
          <Button
            type="primary"
            icon={<Save />}
            onClick={onSubmit}
            className={classes.modalFooterButton}
          >
            Сохранить
          </Button>
        </div>
      </form>
    </Modal>
  )
}

const mapStateToProps: MapStateToProps<StateProps, Props, App.State> = (state, props) => ({
  userEmail: state.user.email,
  userName: state.user.message_name,
  loading: state.user.infoStatus,
  values: getFormValues(USER_SETTINGS_FORM.form)(state),
  errors: getFormSyncErrors(USER_SETTINGS_FORM.form)(state),
  isValid: isValid(USER_SETTINGS_FORM.form)(state)
})

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OuterProps> = dispatch => ({
  updateProfileInfo: () => dispatch(updateProfileInfo())
})

export default compose<Props, OuterProps>(
  reduxForm(USER_SETTINGS_FORM),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectStyles(styles)
)(UserSettings)

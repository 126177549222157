import { Action } from 'redux'

import { Types } from './types'
import { RESOURCES } from 'constants/dataImport'

export interface ReadCubes extends Action<Types.READ> {
  payload: {
    resourceType: string
    requestKey: string
    limit?: number
    query?: string
  }
}
export interface CreateCubeOptions {
  name?: string
  nsi?: boolean
  connections?: Data.CubeParams['connections']
  period?: Data.CubeParams['period']
  anomaly_condition?: string
  input_error_condition?: string
  columns_formats: Data.CubeParams['columns_formats']
}
export interface CreateCube extends Action<Types.CREATE> {
  payload: {
    resourceType: string
    requestKey: string
    requestProperties: CreateCubeOptions
  }
}

export interface MergeCubeOptions {
  cube_id: number
  column: string
  merged_cube_id: number
  merged_cube_column: string
}
export interface MergeCube extends Action<Types.MERGE> {
  payload: {
    resourceType: string
    requestKey: string
    requestProperties: MergeCubeOptions
  }
}

export interface UpdateCubeOptions extends Partial<CreateCubeOptions> {
  id: number
}
export interface UpdateCube extends Action<Types.UPDATE> {
  payload: {
    resourceType: string
    requestKey: string
    requestProperties: UpdateCubeOptions
  }
}
export interface DeleteCube extends Action<Types.DELETE> {
  payload: {
    resourceType: string
    requestKey: string
    requestProperties: { id: number }
  }
}

export type Actions = ReadCubes | CreateCube | UpdateCube | DeleteCube | MergeCube

export { Actions as CubesActions }

export const readCubes: ({ limit, query }) => ReadCubes = ({ limit, query }) => ({
  type: Types.READ,
  payload: {
    resourceType: RESOURCES.CUBES.NAME,
    requestKey: RESOURCES.CUBES.REQUESTS.READ,
    limit,
    query
  }
})

export const createCube: (opts: CreateCubeOptions) => CreateCube = opts => ({
  type: Types.CREATE,
  payload: {
    resourceType: RESOURCES.CUBES.NAME,
    requestKey: RESOURCES.CUBES.REQUESTS.CREATE,
    requestProperties: opts
  }
})

export const mergeCube: (opts: MergeCubeOptions) => MergeCube = opts => ({
  type: Types.MERGE,
  payload: {
    resourceType: RESOURCES.CUBES.NAME,
    requestKey: RESOURCES.CUBES.REQUESTS.MERGE,
    requestProperties: opts
  }
})

export const updateCube: (opts: UpdateCubeOptions) => UpdateCube = opts => ({
  type: Types.UPDATE,
  payload: {
    resourceType: RESOURCES.CUBES.NAME,
    requestKey: RESOURCES.CUBES.REQUESTS.UPDATE,
    requestProperties: opts
  }
})

export const deleteCube: (id: number) => DeleteCube = id => ({
  type: Types.DELETE,
  payload: {
    resourceType: RESOURCES.CUBES.NAME,
    requestKey: RESOURCES.CUBES.REQUESTS.DELETE,
    requestProperties: { id }
  }
})
